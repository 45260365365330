import React from "react";
import GraficoCancelamentoPorFilial from "./GraficoCancelamentoPorFilial.js";
import GraficoCancelamentosMes from "./GraficoCancelamentoPorMes.js";
import { formatarDinheiro } from "../../../../misc/formatarDinheiro.js";

import IoContext from "contextos/IoContext.js";
import { Card, Container, Col, Row, Button, Offcanvas, Form } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import { toast } from "react-toastify";
import SelectMes from "componentes/SelectMes.js";
import SelectFiliais from "paginas/cadastros/administrativo/filiais/SelectFiliais.js";

export default function PowerBICancelamentos() {
  const [show, setShow] = React.useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [FilialId, setFilialId] = React.useState(0);
  const [mes, setMes] = React.useState(new Date().getMonth() + 1);
  const [ano, setAno] = React.useState(new Date().getFullYear());
  const { socket } = React.useContext(IoContext);
  const [totalTicketMedio, setTotalTicketMedio] = React.useState(0);
  const [totalPerdaReceita, setTotalPerdaReceita] = React.useState(0);
  const [totalCancelamentosPorDia, setTotalCancelamentosPorDia] = React.useState({});
  const [totalCancelamentosPorFilial, setTotalCancelamentosPorFilial] = React.useState({});
  const [dini, setDini] = React.useState("");
  const [dend, setDend] = React.useState("");

  React.useEffect(() => {
    const totalDias = new Date(ano, mes, 0).getDate();
    const dini = new Date(ano, mes - 1, 1).toISOString().split("T")[0];
    const dend = new Date(ano, mes - 1, totalDias).toISOString().split("T")[0];

    setDini(dini);
    setDend(dend);

    socket.emit(
      "HistoricoServicoContratado.findAll",
      {
        attributes: ["id", "createdAt"],
        where: {
          createdAt_between: [dini, dend],
          situacao: "CANCELADO",
        },
        withServicoContratado: {
          required: true,
          attributes: ["situacao", "valor"],
          withContrato: {
            attributes: ["FilialId"],
            where: FilialId ? { FilialId } : undefined,
            withFilial: {
              attributes: ["nome"],
            },
          },
        },
      },
      (error, resp) => {
        if (error) {
          return toast.error(typeof error === "string" ? error : error?.message);
        }

        let tempTotalPerdaReceita = 0;
        let tempTotalPorFilial = {};
        let tempTotalPorDia = Array.from({ length: totalDias }, (_, idx) => idx + 1).reduce(
          (acc, value) => ({ ...acc, [value]: 0 }),
          {}
        );

        (resp || []).forEach((hist) => {
          const [, , histDataDia] = hist.createdAt.split("T")[0].split("-");
          const filial = hist.ServicoContratado?.Contrato?.Filial?.nome;

          tempTotalPorDia[parseInt(histDataDia)]++;
          tempTotalPerdaReceita = tempTotalPerdaReceita += hist.ServicoContratado.valor;
          tempTotalPorFilial[filial] = (tempTotalPorFilial[filial] ?? 0) + 1;
        });

        setTotalTicketMedio(resp.length === 0 ? 0 : tempTotalPerdaReceita / resp.length);
        setTotalPerdaReceita(tempTotalPerdaReceita);
        setTotalCancelamentosPorDia(tempTotalPorDia);
        setTotalCancelamentosPorFilial(tempTotalPorFilial);
      }
    );
  }, [socket, FilialId, mes, ano]);

  return (
    <Container fluid>
      <Card className="shadow">
        <Card.Header className="bg-primary text-light">
          <h1 className="float-start">
            Relatório de Cancelamentos Geral
            <span className="ms-5">
              {dini.split("-").reverse().join("/")} - {dend.split("-").reverse().join("/")}
            </span>
          </h1>
          <Button variant="secondary" className="float-end mt-2" onClick={handleShow}>
            <FaFilter />
          </Button>
          <Offcanvas show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Filtros:</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Form.Text>Período de Cancelamento:</Form.Text>
              <Row>
                <Col>
                  <SelectMes value={mes} onChange={(e) => setMes(e.target.value)} />
                </Col>
                <Col>
                  <Form.Control type="number" value={ano} onChange={(e) => setAno(+e.target.value)} />
                </Col>
              </Row>
              <Form.Text>Filial:</Form.Text>
              <SelectFiliais value={FilialId} required={true} onChange={(e) => setFilialId(+e.target.value)} />
            </Offcanvas.Body>
          </Offcanvas>
        </Card.Header>
      </Card>
      <Row>
        <Col className="mt-1">
          <Card className="shadow rounded">
            <Card.Body>
              <GraficoCancelamentosMes totalCancelamentosPorDia={totalCancelamentosPorDia} />
            </Card.Body>
          </Card>
        </Col>
        <Col className="text-center mt-1">
          <Row className="mb-2">
            <Col>
              <Card>
                <Card.Title className="mt-2">Cancelamentos</Card.Title>
                <Card.Body style={{ fontSize: "50px", marginTop: "-20px" }}>
                  {Object.values(totalCancelamentosPorFilial).reduce((acc, total) => acc + total, 0)}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Card>
                <Card.Title className="mt-2">Perda de Receita</Card.Title>
                <Card.Body style={{ fontSize: "50px", marginTop: "-20px" }}>
                  {formatarDinheiro(totalPerdaReceita)}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Card.Title className="mt-2">Ticket Médio</Card.Title>
                <Card.Body style={{ fontSize: "50px", marginTop: "-20px" }}>
                  {formatarDinheiro(totalTicketMedio)}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
        <GraficoCancelamentoPorFilial totalCancelamentosPorFilial={totalCancelamentosPorFilial} />
      </Row>
    </Container>
  );
}
