import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import IoContext from "../../../contextos/IoContext";
import MultiFiliais from "../../cadastros/administrativo/filiais/MultiFiliais";
import { toast } from "react-toastify";
import { Container } from "react-bootstrap";

import CardCrescimentoChurn from "./CardCrescimentoChurn";
import CardProjecaoFinanceira from "./CardProjecaoFinanceira";
import BotaoProcessando from "componentes/BotaoProcessando";

const meses = Array.from(Array(12).keys()).map((k) => k + 1);

export default function RelProjecoesRecebimentos() {
  const { socket } = React.useContext(IoContext);
  const [ano, setAno] = React.useState(new Date().getFullYear());
  const [contador, setContador] = React.useState(0);
  const [filiais, setFiliais] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [consolidar, setConsolidar] = React.useState(false);
  const [projecoes, setProjecoes] = React.useState({});

  React.useEffect(() => {
    getInfoClientes({
      filiais,
      socket,
      ano,
      consolidar,
      setConsolidar,
      setLoading,
      setProjecoes,
    });
  }, [socket, ano, contador, filiais, consolidar]);

  return (
    <Container fluid>
      <div className="m-2">
        <Card className="shadow">
          <Card.Header className="bg-primary text-light">
            <h3 className="float-left">Relatório de Projeções e Recebimentos</h3>
          </Card.Header>

          <Card.Body className="d-print-none">
            <Row>
              <Col sm={2}>
                <Form.Group>
                  <Form.Label>Ano</Form.Label>
                  <Form.Control
                    type="number"
                    value={ano}
                    disabled={loading}
                    onChange={(e) => setAno(+e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col sm={4}>
                <Form.Group>
                  <Form.Label>Filiais</Form.Label>
                  <div>
                    <MultiFiliais filiais={filiais} setFiliais={setFiliais} />
                  </div>
                </Form.Group>
              </Col>

              <Col sm={4}>
                <Form.Group>
                  <Form.Label>Ações</Form.Label>
                  <div>
                    <Button
                      variant="secondary"
                      title="Listar"
                      className="me-2"
                      disabled={loading}
                      onClick={() => setContador(contador + 1)}
                    >
                      Atualizar
                    </Button>

                    <Button
                      variant="warning"
                      title="Consolidar"
                      className="me-2"
                      disabled={loading}
                      onClick={() => {
                        setConsolidar(true);
                        setContador(contador + 1);
                      }}
                    >
                      Consolidar
                    </Button>

                    <Button title="Imprimir" className="me-2" disabled={loading} onClick={() => window.print()}>
                      Imprimir
                    </Button>

                    <BotaoProcessando loading={loading} />
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {/* <Card className="mt-2">
                <GraficoClientesCrescimento 
                    assinantesInicoMes={assinantesInicoMes}
                    assinantesAdicoes={assinantesAdicoes} assinantesCancelamentos={assinantesCancelamentos} assinantesSuspensoes={assinantesSuspensoes}
                    assinantesChurn={assinantesChurn} meses={meses} />
            </Card> */}

        <CardProjecaoFinanceira meses={meses} projecoes={projecoes} ano={ano} />

        <CardCrescimentoChurn
          titulo="Assinantes"
          projecoes={projecoes}
          meses={meses}
          ano={ano}
          campoInicioMes="assinantesInicioDoMes"
          campoAdicoesAoMes="assinantesAdicoes"
          campoCancelamentosAoMes="assinantesCancelamentos"
          campoSuspensoesAoMes="assinantesSuspensoes"
        />

        <CardCrescimentoChurn
          titulo="Serviços"
          projecoes={projecoes}
          meses={meses}
          ano={ano}
          campoInicioMes="servicosInicioDoMes"
          campoAdicoesAoMes="servicosAdicoes"
          campoCancelamentosAoMes="servicosCancelamentos"
          campoSuspensoesAoMes="servicosSuspensoes"
        />
      </div>
    </Container>
  );
}

function getInfoClientes({ filiais, socket, ano, consolidar, setConsolidar, setLoading, setProjecoes }) {
  let projecoes = {};
  setProjecoes({});

  return meses.reduce((prevPromise, mes) => {
    return prevPromise.then(() => {
      setLoading(true);

      let FilialId = [];
      (filiais || []).forEach((f) => {
        FilialId.push(f.id);
      });

      if (FilialId.length <= 0) FilialId = null;

      return new Promise((resolve, reject) => {
        socket.emit(
          "ProjecoesRecebimento.findByMesAno",
          {
            mes,
            ano,
            FilialId,
            consolidar,
          },
          (error, resp) => {
            setLoading(false);

            if (error) {
              toast.error("" + error);
              console.log("## ERROR: ", mes, ano, error);
              return reject(error);
            }

            projecoes[mes] = { ...resp };
            projecoes[mes].total = {};

            Object.keys(resp).forEach((FilialId) => {
              let p = resp[FilialId];
              Object.keys(p).forEach((k) => {
                if (projecoes[mes].total[k] === undefined) {
                  projecoes[mes].total[k] = 0;
                }

                projecoes[mes].total[k] += +p[k];
              });

              projecoes[mes].total.SaldoReceitaDespesasPrevisto =
                projecoes[mes].total.valorReceberPrevisto - projecoes[mes].total.valorAPagar;
              projecoes[mes].total.SaldoReceitaDespesasEfetivo =
                projecoes[mes].total.valorRecebidoTotal - projecoes[mes].total.valorAPagar;
            });

            setProjecoes({ ...projecoes });
            setConsolidar(false);
            resolve(resp);
          }
        );
      });
    });
  }, Promise.resolve());
}
