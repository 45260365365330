import IoContext from "contextos/IoContext";
import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

export default function ModalConfirmarDDD({ ContratoVoip, show, onHide, disabled, callback = (_e) => {} }) {
  const { socket } = React.useContext(IoContext);
  const [confirmado, setConfirmado] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmar DDD</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h1>
          DDD: <span className="text-info">{ContratoVoip?.ddd}</span>
        </h1>
        <h3 className="text-danger">
          Ao confirmar o DDD, será alocado um número de telefone, e não será possível desfazer a operação !!!
        </h3>

        <Form.Group className="mt-2">
          <Form.Check checked={confirmado} onChange={() => setConfirmado(!confirmado)} label="Confirmar ?" />
        </Form.Group>
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="btn-warning"
          disabled={disabled || loading}
          onClick={() => {
            onHide(false);
          }}
        >
          Cancelar
        </Button>

        <Button
          className="btn-primary"
          disabled={!confirmado || disabled || loading}
          onClick={(e) => {
            setLoading(true);
            socket.emit(
              "ContratoVoip.confirmarDDD",
              {
                ContratoVoipId: ContratoVoip.id,
                ddd: ContratoVoip.ddd,
              },
              (error, resp) => {
                setLoading(false);
                if (error) {
                  console.error("ContratoVoip.confirmarDDD:", error);
                  return toast.error("" + error);
                }

                toast.info("DDD Confirmado com Sucesso !");
                onHide(false);
                if (callback) {
                  callback(resp);
                }
              }
            );
          }}
        >
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
