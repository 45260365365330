import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import IoContext from "contextos/IoContext";
import { toast } from "react-toastify";
import {
  TiposOrdemServico,
  SituacoesOrdensServico,
  OS_ABERTA,
  CONCLUIDA_COM_SUCESSO,
  CONCLUIDA_SEM_SUCESSO,
} from "datatypes/OrdemServicoTypes";
import { getFinalMes, getInicioMes } from "misc/lhdatas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function RelatorioTopNOrdensServico() {
  const { socket } = React.useContext(IoContext);
  const navigate = useNavigate();
  const [exibirFiltros, setExibirFiltros] = React.useState(false);
  const [contador, setContador] = React.useState(0);
  const [dini, setDini] = React.useState(getInicioMes().toISOString().split("T")[0]);
  const [dend, setDend] = React.useState(getFinalMes().toISOString().split("T")[0]);
  const [loading, setLoading] = React.useState(false);
  const [ordensServico, setOrdensServico] = React.useState([]);
  const [limit, setLimit] = React.useState(10);
  const [tipos, setTipos] = React.useState(TiposOrdemServico);
  const [situacao, setSituacao] = React.useState(`${OS_ABERTA}`);
  const [exibirComSucesso, setExibirComSucesso] = React.useState(true);
  const [exibirSemSucesso, setExibirSemSucesso] = React.useState(true);

  React.useEffect(() => {
    setOrdensServico([]);
    setLoading(true);

    let situacao_conclusao_in = [];
    if (exibirComSucesso) {
      situacao_conclusao_in.push(CONCLUIDA_COM_SUCESSO);
    }

    if (exibirSemSucesso) {
      situacao_conclusao_in.push(CONCLUIDA_SEM_SUCESSO);
    }

    socket.emit(
      "OrdemServico.findAll",
      {
        attributes: ["id", "data_agendamento", "fn_count(OrdemServico.id) as nOrdensServico"],
        group: ["ServicoContratado.CLIENTE_ID"],
        order: [["col_nOrdensServico", "desc"]],
        where: {
          situacao,
          data_agendamento_between: [dini, dend],
          tipo_in: Object.keys(tipos).filter((t) => tipos[t]),
          or: [
            {
              situacao_conclusao_in,
            },
            {
              situacao_conclusao: null,
            },
          ],
        },
        withServicoContratado: {
          attributes: ["id"],
          withContrato: {
            attributes: ["id", "contrato"],
            withPessoa: {
              attributes: ["nome"],
            },
            withFilial: {
              attributes: ["nome"],
            },
            required: true,
          },
          required: true,
        },
        limit: +limit,
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          return toast.error(error);
        }
        setOrdensServico(resp);
      }
    );
  }, [socket, limit, dini, dend, contador, tipos, situacao, exibirComSucesso, exibirSemSucesso]);

  return (
    <Container fluid>
      <Card className="shadow">
        <Card.Header className="bg-primary text-light">
          <h3 className="float-start">Relatório Top {limit} de Ordens de Serviço</h3>
        </Card.Header>

        <Card.Body className="d-print-none">
          <Row>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>Período</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="date"
                    value={dini}
                    placeholder="Inicial"
                    disabled={loading}
                    onChange={(e) => setDini(e.target.value)}
                  />
                  <Form.Control
                    type="date"
                    value={dend}
                    placeholder="Final"
                    disabled={loading}
                    onChange={(e) => setDend(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Máximo de Registros</Form.Label>
                <Form.Control
                  type="number"
                  min={1}
                  step={1}
                  value={limit}
                  onChange={(e) => setLimit(+e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={6}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    variant="info"
                    title="Listar"
                    className="me-2"
                    onClick={() => setContador(contador + 1)}
                    disabled={loading}
                  >
                    Atualizar
                  </Button>

                  <Button title="Listar" className="me-2" disabled={loading} onClick={() => window.print()}>
                    Imprimir
                  </Button>

                  <Button
                    title="Aplicar Filtros"
                    className="me-1"
                    variant="secondary"
                    onClick={() => {
                      setExibirFiltros(!exibirFiltros);
                    }}
                  >
                    <FontAwesomeIcon icon={faFilter} /> Filtros
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Row>
        <Col sm={12} className="mt-2">
          {/* <CardGraficoTopAtendimentos dini={dini} dend={dend} contratos={(atendimentos || []).slice(0,10).map( att => att.ContratoId )} /> */}
        </Col>

        <Col sm={12} className="mt-2">
          <Card className="shadow rounded">
            <Table bordered responsive>
              <thead>
                <tr>
                  <th>Filial</th>
                  <th>Contrato</th>
                  <th>Cliente</th>
                  <th>Qtd Ordens de Serviço</th>
                </tr>
              </thead>
              <tbody>
                {(ordensServico || []).map((os, key) => {
                  const serc = os.ServicoContratado || {};
                  const contrato = serc.Contrato || {};
                  const filial = contrato.Filial || {};
                  const pessoa = contrato.Pessoa || {};

                  return (
                    <tr key={key}>
                      <td>{filial.nome}</td>
                      <td>
                        <a
                          href={`/contratos/${contrato.id}`}
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/contratos/${contrato.id}`);
                          }}
                        >
                          {contrato.contrato}
                        </a>
                      </td>
                      <td>{pessoa.nome}</td>
                      <td>{os.nOrdensServico}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>

      <Modal show={exibirFiltros} onHide={() => setExibirFiltros(false)} dialogClassName="modalFiltros">
        <Modal.Header closeButton>Filtros</Modal.Header>

        <Modal.Body>
          <Form.Group>
            <Form.Label>Sitaução</Form.Label>
            <Form.Control
              as="select"
              value={situacao}
              onChange={(e) => {
                setSituacao(e.target.value);
              }}
            >
              <option value="">Todos</option>
              {Object.keys(SituacoesOrdensServico).map((s) => {
                return (
                  <option value={s} key={s}>
                    {SituacoesOrdensServico[s]}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>Tipos de OS</Form.Label>
            <div>
              {Object.keys(TiposOrdemServico).map((tipo) => {
                return (
                  <Form.Check
                    key={tipo}
                    checked={tipos[tipo]}
                    label={TiposOrdemServico[tipo]}
                    disabled={loading}
                    onChange={() => {
                      let tipos2 = { ...tipos };
                      tipos2[tipo] = !tipos2[tipo];
                      setTipos(tipos2);
                    }}
                  />
                );
              })}
            </div>
          </Form.Group>

          <Form.Group>
            <Form.Label>Situação da Conclusão</Form.Label>
            <div>
              <Form.Check
                label="Com Sucesso"
                checked={exibirComSucesso}
                disabled={loading}
                onChange={() => setExibirComSucesso(!exibirComSucesso)}
              />
              <Form.Check
                value="2"
                label="Sem Sucesso"
                checked={exibirSemSucesso}
                disabled={loading}
                onChange={() => setExibirSemSucesso(!exibirSemSucesso)}
              />
            </div>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </Container>
  );
}
