import { faDollarSign, faList, faList12 } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';
import ModalAdicionarRecargaVoip from './ModalAdicionarRecargaVoip';
import ModalHistoricoLigacoes from './ModalHistoricoLigacoes';
import ModalHistoricoRecargas from './ModalHistoricoRecargas';

export default function AcoesContratoVoipMeucci({ ContratoVoip, disabled, callback }) {
    const [showHistoridoLigacoes, setShowHistoricoLigacoes] = React.useState(false);
    const [showHistoricoRecargas, setShowHistoricoRecargas] = React.useState(false);
    const [showAdicionarRecarga, setShowAdicionarRecarga] = React.useState(false);

    return <React.Fragment>
        <Button className="btn-sm me-2" variant="primary" disabled={disabled} title="Histórico de Ligações"
            onClick={()=>{
                setShowHistoricoLigacoes(true);
            }}><FontAwesomeIcon icon={faList} />
        </Button>

        <Button className="btn-sm me-2" variant="info" disabled={disabled} title="Histórico de Recargas"
            onClick={()=>{
                setShowHistoricoRecargas(true);
            }}><FontAwesomeIcon icon={faList12} />
        </Button>

        <Button className="btn-sm me-2" variant="success" disabled={disabled} title="Adicionar Crédito Manualmente"
            onClick={()=>{
                setShowAdicionarRecarga(true);
            }}><FontAwesomeIcon icon={faDollarSign} />
        </Button>

        { showHistoridoLigacoes && 
        <ModalHistoricoLigacoes ContratoVoipId={ContratoVoip?.id} disabled={disabled} 
            show={showHistoridoLigacoes} onHide={setShowHistoricoLigacoes}
            /> }

        { showHistoricoRecargas && 
        <ModalHistoricoRecargas ContratoVoipId={ContratoVoip?.id} disabled={disabled} 
            show={showHistoricoRecargas} onHide={setShowHistoricoRecargas}
            /> }

        { showAdicionarRecarga && <ModalAdicionarRecargaVoip
            ContratoVoipId={ContratoVoip?.id} disabled={disabled} 
            show={showAdicionarRecarga} onHide={setShowAdicionarRecarga} callback={callback}
            /> }
    </React.Fragment>
}