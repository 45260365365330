import React from "react";
import Form from "react-bootstrap/Form";
import { CONTRATO_SITUACOES } from "datatypes/ContratoTypes";

export default function SelectSituacaoContrato(props) {
  const { id, value, onChange, disabled } = props;

  return (
    <Form.Control as="select" id={id || "situacao"} onChange={onChange} value={value} disabled={disabled} {...props}>
      <option value="">Todos</option>
      {Object.keys(CONTRATO_SITUACOES).map((k) => {
        return (
          <option key={k} value={k}>
            {CONTRATO_SITUACOES[k]}
          </option>
        );
      })}
    </Form.Control>
  );
}
