import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Despesas from './Despesas';
import Despesa from './Despesa';


export default function IndexDespesas(){
    const [texto, setTexto] = React.useState('');
    const [pagina, setPagina] = React.useState(1);
    const [filialId, setFilialId] = React.useState(0);
    const [fornecedorId, setFornecedorId] = React.useState(0);
    const [CentroCustoId, setCentroCustoId] = React.useState('');

    return <Routes>
        <Route path={`/:id`} element={<Despesa />} />
        <Route path={`*`} element={<Despesas
                texto={texto} setTexto={setTexto} 
                pagina={pagina} setPagina={setPagina}
                filialId={filialId} setFilialId={setFilialId}
                fornecedorId={fornecedorId} setFornecedorId={setFornecedorId}
                CentroCustoId={CentroCustoId} setCentroCustoId={setCentroCustoId}
                />
            } />
    </Routes>
}