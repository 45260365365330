import { 
    SERVIDOR_VOIP_TIPO_A2BILLING,
    SERVIDOR_VOIP_TIPO_ALO_FONE, 
    SERVIDOR_VOIP_TIPO_MEGATELECOM, 
    SERVIDOR_VOIP_TIPO_MEUCCI, 
} from 'datatypes/ServidorVoipTypes';
import React from 'react';
import DetalhesRamalVoipA2Billing from './DetalhesRamalVoipA2Billing';
import DetalhesRamalVoipAloFone from './DetalhesRamalVoipAloFone';
import DetalhesRamalVoipMeucci from './DetalhesRamalVoipMeucci';

export default function DetalhesRamalVoip({ tipo, dados, setDados, ContratoId, loading, setLoading }) {
    if(!tipo) return <React.Fragment />;

    switch(tipo) {
        case SERVIDOR_VOIP_TIPO_MEUCCI: 
            return <DetalhesRamalVoipMeucci dados={dados} setDados={setDados} loading={loading} />
        case SERVIDOR_VOIP_TIPO_MEGATELECOM: 
            return <div>Mega Telecom Não Implementado</div>;
        case SERVIDOR_VOIP_TIPO_ALO_FONE: 
            return <DetalhesRamalVoipAloFone dados={dados} setDados={setDados} loading={loading} setLoading={setLoading}
                ContratoId={ContratoId} />
        case SERVIDOR_VOIP_TIPO_A2BILLING:
            return <DetalhesRamalVoipA2Billing dados={dados} setDados={setDados} loading={loading} />
        default:
            return <div>Servidor Voip Não Implementado [{tipo}]</div>
    }
}