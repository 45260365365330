import React from "react";
import Form from "react-bootstrap/Form";
import { NOTAFISCAL_TIPOS } from "datatypes/NotaFiscalTypes";

export default function SelectTipoNotaFiscal(props) {
  const { id, value, onChange, disabled } = props;

  return (
    <Form.Control as="select" id={id || "situacao"} onChange={onChange} value={value} disabled={disabled} {...props}>
      <option value="">Todas</option>
      {Object.keys(NOTAFISCAL_TIPOS).map((k) => {
        return (
          <option key={k} value={k}>
            {NOTAFISCAL_TIPOS[k]}
          </option>
        );
      })}
    </Form.Control>
  );
}
