import React from 'react';
import { Routes, Route } from 'react-router-dom';
import TarefasListagem from './TarefasListagem';

export default function TarefasIndex(params) {
    const [texto, setTexto] = React.useState('');
    const [pagina, setPagina] = React.useState(1);

    return <Routes>
        <Route path={`*`} element={<TarefasListagem
            texto={texto} setTexto={setTexto}
            pagina={pagina} setPagina={setPagina}
        />} />
    </Routes>
}