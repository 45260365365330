import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import IoContext from "contextos/IoContext";
import SelectFiliais from "paginas/cadastros/administrativo/filiais/SelectFiliais";
import { toast } from "react-toastify";

export default function RelVoip() {
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(false);
  const [filialId, setFilialId] = React.useState("");
  const [dados, setDados] = React.useState(null);

  React.useEffect(() => {
    if (!filialId) return;

    const reqData = {
      attributes: ["usuario"],
      withContrato: {
        attributes: ["numero_contrato"],
        where: {
          FilialId: filialId,
        },
        withPessoa: {
          attributes: ["nome"],
        },
      },
    };

    setLoading(true);
    socket.emit("ContratoVoip.findAll", reqData, (error, resp) => {
      setLoading(false);
      if (error) {
        console.log("error >", error);
        toast.error("" + error);
      }

      setDados(resp);
    });
  }, [socket, filialId]);

  return (
    <Container fluid className="m-2">
      <Card>
        <Card.Header className="d-print-none">
          <h3>Relatório de Clientes com Números VoIP</h3>
        </Card.Header>
        <Card.Body>
          <Row className="d-print-none mb-2">
            <Col>
              <Form.Group>
                <Form.Label>Filial</Form.Label>
                <SelectFiliais disabled={loading} value={filialId} onChange={(e) => setFilialId(e.target.value)} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    title=""
                    onClick={() => {
                      window.print();
                    }}
                  >
                    Imprimir
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row className="p-2">
            <Table className={dados ? "" : "d-none"}>
              <thead>
                <tr>
                  <th>Contrato</th>
                  <th>Cliente</th>
                  <th>Número</th>
                </tr>
              </thead>
              <tbody>
                {(dados || []).map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{item.Contrato?.numero_contrato || ""}</td>
                      <td>{item.Contrato?.Pessoa?.nome || ""}</td>
                      <td>{item.usuario || ""}</td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr className="text-end">
                  <td colSpan={3}>{dados?.length} Registros</td>
                </tr>
              </tfoot>
            </Table>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}
