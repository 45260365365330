import React from "react";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function CardModulo({ texto, icon, onClick, className }) {
  return (
    <Card onClick={onClick} className={`text-center p-2 ${className}`}>
      <FontAwesomeIcon icon={icon} size="6x" className="align-self-center" />
      <h1>{texto} </h1>
    </Card>
  );
}
