import React from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import IoContext from '../../../contextos/IoContext';

export default function ModalConfirmarRecebimentoRemessaMaterial({ remessa_material, show, onHide, callback }){
    const { socket } = React.useContext(IoContext);
    const [loading, setLoading] = React.useState(false);
    return <Modal  show={show} onHide={onHide} size="lg">
        <Form onSubmit={ e => {
            e.preventDefault();
            setLoading(true);
            socket.emit("RemessaMaterial.confirmar", { 
                id: remessa_material.id
            }, (error, resp) =>{
                setLoading(false);
                if(error){
                    toast.error(''+error);
                } else {
                    toast.success("Remessa confirmada com Sucesso !");
                    callback(resp);
                }
            });
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Confirmar Recebimento de Remessa de Material</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                Confirma estar recebendo as quantidades de material listadas na Remessa ?
            </Modal.Body>

            <Modal.Footer>
                <Button className="btn-warning" disabled={loading} onClick={()=>{
                    onHide(false);
                }}>Cancelar</Button>

                <Button className="btn-primary" disabled={loading} type="submit">Confirmar</Button>
            </Modal.Footer>
        </Form>
    </Modal>
}