import React from 'react';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import Listagem from './Listagem';

const cores = ['primary', 'secondary', 'info', 'success', 'danger', 'warning', 'dark'];

export default function MultiItemSelect({
    itens = [], setItens, removerItem = null, coresAleatorias = true, loading,
    itensPesquisa, onSelect, campoTexto = 'texto',
    renderItem, onSearch, titulos
}) {
    let corIndex = 0;
    const [show, setShow] = React.useState(false);
    const [texto, setTexto] = React.useState('');

    return <div>
        <Row>
            {itens.map(item => {
                if (corIndex >= cores.length) corIndex = 0;
                let cor = item.cor ? item.cor : (coresAleatorias ? cores[corIndex++] : 'primary');
                return <Button variant={cor} size="sm"
                    className="me-1 mb-1" key={item.id}>
                    {item[campoTexto]}
                    <Badge variant={!cor || cor !== 'danger' ? 'danger' : 'light'}
                        title="Remover Item ?"
                        className='ms-1' onClick={() => {
                            if (removerItem) {
                                removerItem(item);
                            } else {
                                const itens2 = [...itens];
                                itens2.splice(itens2.indexOf(item), 1);
                                setItens(itens2);
                            }
                        }}>X</Badge>
                </Button>
            })}
        </Row>

        <div className="text-right">
            <Button className="" size="sm" title="Adicionar Item" onClick={() => setShow(true)}>
                <FontAwesomeIcon icon={faPlus} />
            </Button>
        </div>

        <Modal show={show} onHide={setShow} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Procurar</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    onSearch(texto);
                }}
                    className="mb-2">
                    <InputGroup>
                        <Form.Control autoFocus value={texto} onChange={e => setTexto(e.target.value)} />
                        <Button type='submit' disabled={loading} variant="primary">
                            <FontAwesomeIcon icon={faSearch} />
                        </Button>
                    </InputGroup>
                </Form>

                <Listagem
                    titulos={titulos}
                    itens={itensPesquisa || []}
                    renderItem={renderItem}
                    onClick={item => {
                        setShow(false);
                        onSelect(item);
                    }}
                />
            </Modal.Body>

            <Modal.Footer>
                {(itensPesquisa || []).length} Registros
            </Modal.Footer>
        </Modal>
    </div>
}