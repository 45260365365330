import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import IoContext from "contextos/IoContext";
import MultiFiliais from "paginas/cadastros/administrativo/filiais/MultiFiliais";
import { toast } from "react-toastify";
import TableSinteticoContasPagar from "./TableSinteticoContasPagar";
import { getFinalMes, getInicioMes, formatarDataHoraDbs } from "misc/lhdatas";

export default function RelContasPagar() {
  const { socket } = React.useContext(IoContext);
  const [filiais, setFiliais] = React.useState([]);
  const [dataIni, setDataIni] = React.useState(getInicioMes().toISOString().split("T")[0]);
  const [dataEnd, setDataEnd] = React.useState(getFinalMes().toISOString().split("T")[0]);
  const [contador, setContador] = React.useState(0);
  const [detCategoria, setDetCategoria] = React.useState({});
  const [detCentroCusto, setDetCentroCusto] = React.useState({});
  const [total, setTotal] = React.useState(0);
  const [detAnaliticoCategoria, setDetAnaliticoCategoria] = React.useState({});
  const [detAnaliticoCentroCusto, setDetAnaliticoCentroCusto] = React.useState({});
  const [exibirDetCategorias, setExibirDetCategorias] = React.useState(true);
  const [exibirDetCentroCusto, setExibirDetCentroCusto] = React.useState(true);
  const [exibirAnalitico, setExibirAnalitico] = React.useState(false);

  React.useEffect(() => {
    setDetCategoria({});
    setDetCentroCusto({});
    setDetAnaliticoCategoria({});
    setDetAnaliticoCentroCusto({});

    let where = {
      dataVencimento_between: [
        formatarDataHoraDbs(new Date(`${dataIni} 00:00:00`)),
        formatarDataHoraDbs(new Date(`${dataEnd} 23:59:59`)),
      ],
    };
    console.log("WHERE:", where);
    if (filiais && filiais.length > 0) {
      where.FilialId_in = filiais.map((f) => f.id);
    }

    if (exibirDetCategorias)
      socket.emit(
        "ContaPagar.findAll",
        {
          attributes: [
            "ContaPagar.CategoriaFinanceiraId",
            "ContaPagar.ItemCategoriaFinanceiraId",
            "fn_sum(valor) as total",
            "fn_count(ContaPagar.id) as quantidade",
          ],
          where,
          withCategoriaFinanceira: {
            attributes: ["id", "nome"],
            required: false,
          },
          withItemCategoriaFinanceira: {
            attributes: ["id", "nome"],
            required: false,
          },
          group: ["CategoriaFinanceira.id", "ItemCategoriaFinanceira.id"],
        },
        (error, resp) => {
          if (error) return toast.error("" + error);

          const { det, total } = normalizarResposta({
            resp,
            txt: "CATEGORIA",
            campoCategoria: "CategoriaFinanceira",
            campoItem: "ItemCategoriaFinanceira",
          });
          setDetCategoria(det);
          setTotal(total);
        }
      );

    if (exibirDetCentroCusto)
      socket.emit(
        "ContaPagar.findAll",
        {
          attributes: ["fn_sum(valor) as total", "fn_count(ContaPagar.id) as quantidade"],
          where,
          withCentroCusto: {
            attributes: ["id", "nome"],
            required: false,
          },
          withItemCentroCusto: {
            attributes: ["id", "nome"],
            required: false,
          },
          group: ["CentroCusto.Id", "ItemCentroCusto.Id"],
        },
        (error, resp) => {
          if (error) return toast.error("" + error);

          const { det, total } = normalizarResposta({
            resp,
            txt: "CENTRO DE CUSTO",
            campoCategoria: "CentroCusto",
            campoItem: "ItemCentroCusto",
          });
          setDetCentroCusto(det);
          setTotal(total);
        }
      );

    if (exibirAnalitico)
      socket.emit(
        "ContaPagar.findAll",
        {
          // attributes: [],
          where,
          withFilial: {
            attributes: ["nome"],
          },
          withFornecedor: {
            attributes: ["nome"],
          },
          withCentroCusto: {
            attributes: ["id", "nome"],
            required: false,
          },
          withItemCentroCusto: {
            attributes: ["id", "nome"],
            required: false,
          },
          withCategoriaFinanceira: {
            attributes: ["id", "nome"],
            required: false,
          },
          withItemCategoriaFinanceira: {
            attributes: ["id", "nome"],
            required: false,
          },
        },
        (error, resp) => {
          if (error) return toast.error("" + error);

          let retCategoria = {};
          let retCentroCusto = {};

          resp.forEach((cp) => {
            const CentroCusto = cp.CentroCusto ? cp.CentroCusto.nome : "SEM CENTRO DE CUSTO";
            const ItemCentroCusto = cp.ItemCentroCusto ? cp.ItemCentroCusto.nome : "";
            const CategoriaFinanceira = cp.CategoriaFinanceira ? cp.CategoriaFinanceira.nome : "SEM CATEGORIA";
            const ItemCategoriaFinanceira = cp.ItemCategoriaFinanceira ? cp.ItemCategoriaFinanceira.nome : "";

            if (!retCentroCusto[CentroCusto]) retCentroCusto[CentroCusto] = {};

            if (!retCentroCusto[CentroCusto][ItemCentroCusto]) retCentroCusto[CentroCusto][ItemCentroCusto] = [];

            retCentroCusto[CentroCusto][ItemCentroCusto].push(cp);
            if (!retCategoria[CategoriaFinanceira]) retCategoria[CategoriaFinanceira] = {};

            if (!retCategoria[CategoriaFinanceira][ItemCategoriaFinanceira])
              retCategoria[CategoriaFinanceira][ItemCategoriaFinanceira] = [];

            retCategoria[CategoriaFinanceira][ItemCategoriaFinanceira].push(cp);
          });

          setDetAnaliticoCategoria(retCategoria);
          setDetAnaliticoCentroCusto(retCentroCusto);
        }
      );
  }, [socket, filiais, dataIni, dataEnd, contador, exibirDetCategorias, exibirDetCentroCusto, exibirAnalitico]);

  return (
    <Container fluid>
      <Card className="shadow">
        <Card.Header className="bg-primary text-light">
          <h3 className="float-start">Relatório de Contas a Pagar</h3>
        </Card.Header>

        <Card.Body className="d-print-none">
          <Row>
            <Col sm={2}>
              <Form.Label>Filial</Form.Label>
              <MultiFiliais filiais={filiais} setFiliais={setFiliais} />
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Data Inicial</Form.Label>
                <Form.Control type="date" value={dataIni} onChange={(e) => setDataIni(e.target.value)} />
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Data Final</Form.Label>
                <Form.Control type="date" value={dataEnd} onChange={(e) => setDataEnd(e.target.value)} />
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Exibir</Form.Label>
                <div>
                  <Form.Check
                    checked={exibirDetCategorias}
                    label="Categorias"
                    onClick={() => {
                      setExibirDetCategorias(!exibirDetCategorias);
                    }}
                  />

                  <Form.Check
                    checked={exibirDetCentroCusto}
                    label="Centros de Custo"
                    onClick={() => {
                      setExibirDetCentroCusto(!exibirDetCentroCusto);
                    }}
                  />

                  <Form.Check
                    checked={exibirAnalitico}
                    label="Visão Anatílitca"
                    onClick={() => {
                      setExibirAnalitico(!exibirAnalitico);
                    }}
                  />
                </div>
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button variant="secondary" title="Listar" className="me-2" onClick={() => setContador(contador + 1)}>
                    Atualizar
                  </Button>

                  <Button title="Listar" className="me-2" onClick={() => window.print()}>
                    Imprimir
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>

        <Row className="mt-t">
          <Col
            sm={exibirAnalitico || !exibirDetCentroCusto ? 12 : 6}
            className={`${exibirDetCategorias ? "" : "d-none"}`}
          >
            <TableSinteticoContasPagar
              titulo="Categoria"
              detalhamento={detCategoria}
              contas={detAnaliticoCategoria}
              exibirAnalitico={exibirAnalitico}
              total={total}
            />
          </Col>
          <Col
            sm={exibirAnalitico || !exibirDetCategorias ? 12 : 6}
            className={`${exibirDetCentroCusto ? "" : "d-none"}`}
          >
            <TableSinteticoContasPagar
              titulo="Centro de Custo"
              detalhamento={detCentroCusto}
              contas={detAnaliticoCentroCusto}
              exibirAnalitico={exibirAnalitico}
              total={total}
            />
          </Col>
        </Row>
      </Card>
    </Container>
  );
}

function normalizarResposta({ resp, txt, campoCategoria, campoItem }) {
  let det = {};
  let total = 0;
  resp.forEach((i) => {
    const id = i[campoCategoria] ? i[campoCategoria].id : null;
    const categoria = i[campoCategoria] ? i[campoCategoria].nome : `SEM ${txt}`;
    const item = i[campoItem] ? i[campoItem].nome : "SEM ITEM";
    const itemid = i[campoItem] ? i[campoItem].id : null;

    if (!det[categoria]) {
      det[categoria] = {
        total: 0,
        quantidade: 0,
        itens: {},
      };
    }

    if (!det[categoria].itens[item]) {
      det[categoria].itens[item] = {
        total: 0,
        quantidade: 0,
      };
    }

    det[categoria].id = id;
    det[categoria].total += +i.total;
    det[categoria].quantidade += +i.quantidade;
    det[categoria].itens[item].id = itemid;
    det[categoria].itens[item].total += +i.total;
    det[categoria].itens[item].quantidade += +i.quantidade;
    total += +i.total;
  });

  return { det, total };
}
