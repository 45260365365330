import React from 'react';
import Form from 'react-bootstrap/Form';

export default function ModalSelecionarItemPag3({ tipo, item, quantidade, setQuantidade }){
    return <React.Fragment>
        <Form.Group>
            <Form.Label>{tipo==='PRODUTO'? 'Produto' : 'Categoria'}</Form.Label>
            <Form.Control 
                value={item ? item.nome : ''}
                readOnly
                />
        </Form.Group>

        <Form.Group>
            <Form.Label>Quantidade</Form.Label>
            <Form.Control 
                type="number"
                autoFocus
                value={quantidade}
                onChange={ e => setQuantidade(e.target.value) }
                />
        </Form.Group>
    </React.Fragment>;
}