import React from 'react';
import Form from 'react-bootstrap/Form';
import IoContext from 'contextos/IoContext';
import { toast } from 'react-toastify';

export default function SelectLocalAlmoxarifado(props){
    const { id, AlmoxarifadoId, value, onChange, disabled } = props; 
    const { socket } = React.useContext(IoContext);
    const [locais, setLocais] = React.useState([]);

    React.useEffect(()=>{
        setLocais([]);
        if(!AlmoxarifadoId) return;
        
        socket.emit("LocalAlmoxarifado.findAll", {
            where: {
                AlmoxarifadoId
            }
        }, (error,  resp) => {
            if(error) {
                return toast.error(''+error);
            }

            setLocais(resp);
        });
    }, [socket, AlmoxarifadoId]);

    const props2 = {...props};
    delete(props2.AlmoxarifadoId);

    return <Form.Control as="select" 
        id={id || "LocalAlmoxarifado"} 
        onChange={onChange}
        value={value}
        disabled={disabled}
        {...props2}
    >
        <option value=""></option>
        { (locais || []).map( c => {
            return <option key={c.id} value={c.id}>{c.descricao}</option>
        })}
    </Form.Control>;
}