import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Pagina1 from './ModalSelecionarItemPag1';
import Pagina2 from './ModalSelecionarItemPag2';
import Pagina3 from './ModalSelecionarItemPag3';

export default function ModalSelecionarItem({ show, onHide, size="lg", onItemSelecionado }){
    const [pagina, setPagina] = React.useState(1);
    const [tipo, setTipo] = React.useState(null);
    const [item, setItem] = React.useState(null);
    const [quantidade, setQuantidade] = React.useState(1);

    React.useEffect(()=>{
        if(show) {
            setPagina(1);
        }
    }, [setPagina, show]);

    return <Modal  show={show} onHide={onHide} size={size}>
        <Modal.Header closeButton>
            <Modal.Title>Selecionar Item</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            { pagina === 1 ? <Pagina1 setPagina={setPagina} setTipo={setTipo} /> : false }
            { pagina === 2 ? <Pagina2 setPagina={setPagina} pagina={pagina} tipo={tipo} setItem={setItem} /> : false }
            { pagina === 3 ? <Pagina3 setPagina={setPagina} item={item} setItem={setItem} quantidade={quantidade} setQuantidade={setQuantidade} /> : false }
        </Modal.Body>

        <Modal.Footer>
            { renderizarBotoes[pagina] ? renderizarBotoes[pagina]({ 
                pagina , tipo, setPagina, quantidade, onHide, onItemSelecionado, item
            }) : false }
        </Modal.Footer>
    </Modal>
}

let renderizarBotoes = {
    2: function(opt){
        return botaoVoltar(opt);
    },
    3: function({ item, tipo, quantidade, onItemSelecionado, onHide }){
        return <Button className="btn-primary" 
            onClick={()=>{
                const i = {
                    tipo, quantidade
                };

                if(tipo==='PRODUTO'){
                    i.produto = item;
                    i.ProdutoId = item.id;
                } else {
                    i.categoria = item;
                    i.CategoriaId = item.id;
                }

                onItemSelecionado(i);
                onHide(false);
            }}
        >Adicionar</Button>
    }
}

const botaoVoltar = function({ loading, pagina, setPagina }) {
    return <Button className="btn-secondary" 
            disabled={loading}
            onClick={()=>{ setPagina(pagina-1) }}
        >Voltar</Button>
}
