import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MaxiTvUpdate from './MaxiTvUpdate';
import MaxiTvUpdates from './MaxiTvUpdates';

export default function MaxiTvUpdatesIndex(){
    const [texto, setTexto] = React.useState('');
    const [pagina, setPagina] = React.useState(1);

    return <Routes>
        <Route path={`/:id`} element={<MaxiTvUpdate />} />
        <Route path={`*`} element={<MaxiTvUpdates
                texto={texto} setTexto={setTexto} 
                pagina={pagina} setPagina={setPagina}
                />
            } />
    </Routes>
}