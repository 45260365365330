import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import { toast } from "react-toastify";
import BotaoApagar from "../../../componentes/BotaoApagar";
import CabecalhoCadastro from "../../../componentes/CabecalhoCadastro";
import IoContext from "../../../contextos/IoContext";
import { useParams, useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Table } from "react-bootstrap";

export default function TemplateEmail() {
  const params = useParams();
  const navigate = useNavigate();
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(false);
  const id = parseInt(params.id);
  const [nome, setNome] = React.useState("");
  const [template, setTemplate] = React.useState("");

  React.useEffect(() => {
    if (isNaN(id)) return;

    setLoading(true);
    socket.emit(
      "TemplateEmail.findOne",
      {
        where: { id },
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          toast.error(error);
        } else {
          setNome(resp.nome);
          setTemplate(resp.template);
        }
      }
    );
  }, [id, socket, setLoading, setNome, setTemplate]);
  return (
    <Container fluid className="pt-3">
      <Card>
        <CabecalhoCadastro
          id={id}
          disabled={loading}
          titulo="TemplateEmail"
          salvar={(e) => {
            e.preventDefault();
            setLoading(true);
            socket.emit(
              "TemplateEmail.salvar",
              {
                id,
                nome,
                template,
              },
              (error, resp) => {
                setLoading(false);
                if (error) {
                  toast.error(error);
                } else {
                  toast.success("TemplateEmail salvo com Sucesso !");
                  navigate(-1);
                }
              }
            );
          }}
        />

        <Card.Body>
          <Row>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Id</Form.Label>
                <Form.Control value={isNaN(id) ? "" : id} readOnly />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  disabled={loading}
                  value={nome}
                  required
                  autoFocus
                  maxLength={50}
                  onChange={(e) => setNome(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={12}>
              <Form.Group>
                <Form.Label>Template</Form.Label>
                <ReactQuill
                  theme="snow"
                  value={template}
                  onChange={setTemplate}
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"], // toggled buttons
                      ["blockquote", "code-block"],
                      ["link", "image", "video", "formula"],

                      [{ header: 1 }, { header: 2 }], // custom button values
                      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                      [{ script: "sub" }, { script: "super" }], // superscript/subscript
                      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                      [{ direction: "rtl" }], // text direction

                      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],

                      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                      [{ font: [] }],
                      [{ align: [] }],
                    ],
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>

        <Card.Footer>
          {isNaN(id) ? (
            false
          ) : (
            <BotaoApagar
              disabled={loading}
              apagar={(e) => {
                setLoading(true);
                socket.emit("TemplateEmail.apagar", { id }, (error, resp) => {
                  setLoading(false);
                  if (error) {
                    toast.error(error);
                  } else {
                    toast.success("TemplateEmail removido com Sucesso !");
                    navigate(-1);
                  }
                });
              }}
            />
          )}
        </Card.Footer>

        <Col sm={12} className="p-2">
          <Form.Group>
            <Form.Label>Campo Dinâmicos</Form.Label>
            <div>
              <h5>Utilize os códigos abaixo no template, para que o sistema preencha com a informação devida</h5>
              <Table
                className="table-bordered"
                style={{
                  maxHeight: "200px",
                  overflowY: "auto",
                }}
              >
                <tr>
                  <th>Código</th>
                  <th>Descrição</th>
                </tr>
                <tr>
                  <td>#Empresa#</td>
                  <td>Nome da Empresa</td>
                </tr>
                <tr>
                  <td>#Telefone#</td>
                  <td>Nome da Empresa</td>
                </tr>
                <tr>
                  <td>#Email#</td>
                  <td>Email da Empresa</td>
                </tr>
                <tr>
                  <td>#Website#</td>
                  <td>Website da Empresa</td>
                </tr>
                <tr>
                  <td>#Cliente#</td>
                  <td>Nome do Cliente</td>
                </tr>
                <tr>
                  <td>#Mensagem#</td>
                  <td>Mensagem</td>
                </tr>
              </Table>
            </div>
          </Form.Group>
        </Col>
      </Card>
    </Container>
  );
}
