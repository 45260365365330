import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import IoContext from "contextos/IoContext";
import CampoData from "componentes/CampoData";
import InputGroup from "react-bootstrap/InputGroup";

import { toast } from "react-toastify";
import { getInicioMes, getFinalMes, formatarDataDbs } from "misc/lhdatas";
import { formatarVelocidade } from "misc/utils";

export default function RelUsoDeBanda() {
  const { socket } = React.useContext(IoContext);
  const [contador, setContador] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [soFiscal] = React.useState(true);
  const [nTop] = React.useState(10);
  const [dini, setDini] = React.useState(formatarDataDbs(getInicioMes()));
  const [dend, setDend] = React.useState(formatarDataDbs(getFinalMes()));
  const [dados, setDados] = React.useState({});

  React.useEffect(() => {
    setDados({});
    setLoading(true);

    socket.emit("RelUsoBanda.getTopN", { nTop, dini, dend }, (error, dados) => {
      setLoading(false);
      if (error) {
        console.log("ERROR:", error);
        return toast.error("" + error);
      }

      console.log("DADOS:", dados);
      setDados(dados);
    });
  }, [socket, soFiscal, contador, setLoading, dini, dend, nTop]);

  return (
    <div className="m-2">
      <Card className="shadow">
        <Card.Header className="bg-primary text-light">
          <h3 className="float-start">Relatório de Uso de Banda</h3>
        </Card.Header>

        <Card.Body className="d-print-none">
          <Row>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>Período</Form.Label>
                <InputGroup>
                  <CampoData
                    value={dini}
                    onChange={(e) => {
                      setDini(e.target.value);
                    }}
                  />
                  <CampoData
                    value={dend}
                    onChange={(e) => {
                      setDend(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    variant="secondary"
                    title="Listar"
                    className="me-2"
                    onClick={() => setContador(contador + 1)}
                    disabled={loading}
                  >
                    Atualizar
                  </Button>

                  <Button title="Listar" className="me-2" disabled={loading} onClick={() => window.print()}>
                    Imprimir
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-2">
        <Table>
          <legend>
            Sumário [{dados?.sumario?.dini} à {dados?.sumario?.dend}]
          </legend>
          <tbody>
            <tr>
              <th>Total de Conexões no Período:</th>
              <td>{dados?.sumario?.n_conexoes}</td>
            </tr>
            <tr>
              <th>Total de Consumo de Upload:</th>
              <td>{formatarVelocidade(dados?.sumario?.total_bytes_in)}</td>
            </tr>
            <tr>
              <th>Total de Consumo de Download:</th>
              <td>{formatarVelocidade(dados?.sumario?.total_bytes_out)}</td>
            </tr>
          </tbody>
        </Table>
      </Card>

      <Card className="mt-2">
        <Row>
          <Col sm={6}>
            <Table>
              <thead>
                <tr>
                  <th>Cotrato</th>
                  <th>Cliente</th>
                  <th>Consumo Upload</th>
                  <th>Consumo Download</th>
                </tr>
              </thead>
              <tbody>
                {(dados?.bilhetagem || []).map((b) => {
                  const Acesso = b.Acesso;
                  const Contrato = Acesso?.Contrato;
                  const Pessoa = Contrato?.Pessoa;
                  return (
                    <tr>
                      <td>{Contrato?.numero_contrato}</td>
                      <td>{Pessoa?.nome}</td>
                      <td>{formatarVelocidade(b.total_bytes_in)}</td>
                      <td>{formatarVelocidade(b.total_bytes_out)}</td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={3}>{dados?.bilhetagem?.length} Registros</td>
                </tr>
              </tfoot>
            </Table>
          </Col>

          <Col sm={6}>
            <Table>
              <thead>
                <tr>
                  <th>Cotrato</th>
                  <th>Cliente</th>
                  <th>Número de Conexões</th>
                </tr>
              </thead>
              <tbody>
                {(dados?.conexoes || []).map((b) => {
                  const Acesso = b.Acesso;
                  const Contrato = Acesso?.Contrato;
                  const Pessoa = Contrato?.Pessoa;
                  return (
                    <tr>
                      <td>{Contrato?.numero_contrato}</td>
                      <td>{Pessoa?.nome}</td>
                      <td>{b.n_conexoes}</td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={3}>{dados?.conexoes?.length} Registros</td>
                </tr>
              </tfoot>
            </Table>
          </Col>
        </Row>
      </Card>
    </div>
  );
}
