import React from "react";
import Form from "react-bootstrap/Form";
import FormularioListagem from "../../../componentes/FormularioListagem";
import { toast } from "react-toastify";
import IoContext from "../../../contextos/IoContext";

import { useNavigate } from "react-router-dom";
import { RemessaDeMaterialSituacoes } from "../../../datatypes/RemessaDeMaterialTypes";
import SelectAlmoxarifados from "../SelectAlmoxarifados";
import { DateTime } from "luxon";

export default function RemessasDeMaterial({
  almoxarifadoId,
  setAlmoxarifadoId,
  almoxarifadoOrigemId,
  setAlmoxarifadoOrigemId,
  dataIni,
  setDataIni,
  dataEnd,
  setDataEnd,
  pagina,
  setPagina,
  texto,
  setTexto,
  situacao,
  setSituacao,
  direcaoOrdem,
  setDirecaoOrdem,
}) {
  const { socket } = React.useContext(IoContext);
  const [remessas, setRemessas] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const navigate = useNavigate();

  React.useEffect(() => {
    let where = {};
    let withAlmoxarifado = {
      where: {},
    };
    let withAlmoxarifadoOrigem = {
      where: {},
    };

    if (texto) withAlmoxarifadoOrigem.where.nome_like = `%${texto}%`;
    if (almoxarifadoOrigemId) withAlmoxarifadoOrigem.where.id = +almoxarifadoOrigemId;
    if (situacao) where.situacao = +situacao;
    if (dataIni && dataEnd) where.createdAt_between = [dataIni, dataEnd];
    if (almoxarifadoId) where.AlmoxarifadoId = +almoxarifadoId;

    console.log("ALM ID:", almoxarifadoId);

    socket.emit(
      "RemessaMaterial.findAndCountAll",
      {
        where,
        limit: 10,
        offset: 10 * (pagina - 1),
        order: [["createdAt", direcaoOrdem]],
        withAlmoxarifado,
        withAlmoxarifadoOrigem,
      },
      (error, resp) => {
        if (error) {
          toast.error(error);
        } else {
          setRemessas(resp.rows);
          setTotal(resp.count);
        }
      }
    );
  }, [socket, texto, pagina, situacao, dataIni, dataEnd, almoxarifadoId, almoxarifadoOrigemId, direcaoOrdem]);

  return (
    <FormularioListagem
      titulo={`Remessas de Material`}
      itens={remessas}
      qtdItens={total}
      setTexto={setTexto}
      pagina={pagina}
      setPagina={setPagina}
      titulos={["Id", "Almoxarifado de Origem", "Almoxarifado de Destino", "Data", "Situação"]}
      onClick={(e) => {
        navigate(`${e.id}`);
      }}
      renderItem={(titulo, item) => {
        switch (titulo) {
          case "Id":
            return item.id;
          case "Almoxarifado de Origem":
            const almOrg = item.AlmoxarifadoOrigem || {};
            return almOrg.nome;
          case "Almoxarifado de Destino":
            const alm = item.Almoxarifado || {};
            return alm.nome;
          case "Data":
            return DateTime.fromISO(item.createdAt).toLocaleString(DateTime.DATE_SHORT);
          case "Situação":
            return RemessaDeMaterialSituacoes[item.situacao];
          default:
            return titulo;
        }
      }}
      renderFiltros={() => {
        return (
          <React.Fragment>
            <Form.Group>
              <Form.Label>Almoxarifado de Origem</Form.Label>
              <SelectAlmoxarifados
                value={almoxarifadoOrigemId}
                onChange={(e) => {
                  setAlmoxarifadoOrigemId(e.target.value);
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Almoxarifado de Destino</Form.Label>
              <SelectAlmoxarifados
                value={almoxarifadoId}
                onChange={(e) => {
                  setAlmoxarifadoId(e.target.value);
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Situação</Form.Label>
              <Form.Control
                as="select"
                value={situacao}
                onChange={(e) => {
                  setSituacao(e.target.value);
                }}
              >
                <option value="">Todas</option>
                {Object.keys(RemessaDeMaterialSituacoes).map((key) => {
                  return (
                    <option key={key} value={key}>
                      {RemessaDeMaterialSituacoes[key]}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Data Inicial</Form.Label>
              <Form.Control type="date" value={dataIni} onChange={(e) => setDataIni(e.target.value)} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Data Final</Form.Label>
              <Form.Control type="date" value={dataEnd} onChange={(e) => setDataEnd(e.target.value)} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Direção da Ordenação</Form.Label>
              <Form.Control as="select" value={direcaoOrdem} onChange={(e) => setDirecaoOrdem(e.target.value)}>
                <option value="asc">Mais Antigos Primeiro</option>
                <option value="desc">Mais Recentes Primeiro</option>
              </Form.Control>
            </Form.Group>
          </React.Fragment>
        );
      }}
    />
  );
}
