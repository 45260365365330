import React from "react";
import { Routes, Route } from "react-router-dom";
import TemplatesEmail from "./TemplatesEmail";
import TemplateEmail from "./TemplateEmail";

export default function TemplateEmailIndex() {
  const [pagina, setPagina] = React.useState(1);
  const [texto, setTexto] = React.useState("");

  return (
    <Routes>
      <Route path={`/:id`} element={<TemplateEmail />} />
      <Route
        path={`/`}
        element={<TemplatesEmail pagina={pagina} setPagina={setPagina} texto={texto} setTexto={setTexto} />}
      />
    </Routes>
  );
}
