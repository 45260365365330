import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import IoContext from "../../../contextos/IoContext";
import Table from "react-bootstrap/Table";
import SelectAlmoxarifados from "../SelectAlmoxarifados";
import CampoProcurarProdutoOnModal from "../CampoProcurarProdutoOnModal";
import { toast } from "react-toastify";

export default function ModalLancarProdutosNFC({ show, onHide, id, itens }) {
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(false);
  const [produtos, setProdutos] = React.useState([]);
  const [AlmoxarifadoId, setAlmoxarifadoId] = React.useState(0);

  React.useEffect(() => {
    let produtos = [];

    (itens || []).forEach((item) => {
      produtos.push({
        ...item,
        ProdutoId: null,
        produto: "",
        quantidadeImportar: item.quantidade,
        unidadeImportar: item.unidade,
      });
    });

    setProdutos(produtos);
  }, [id, itens]);

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>Lançar Produtos</Modal.Header>

      <Modal.Body>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Almoxarifado</Form.Label>
              <SelectAlmoxarifados
                value={AlmoxarifadoId}
                onChange={(e) => {
                  setAlmoxarifadoId(e.target.value);
                }}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label>Ações</Form.Label>
              <div>
                <Button
                  disabled={loading}
                  onClick={() => {
                    lancarProdutos({
                      socket,
                      setLoading,
                      onHide,
                      id,
                      AlmoxarifadoId,
                      produtos,
                    });
                  }}
                >
                  Lançar Produtos
                </Button>
              </div>
            </Form.Group>
          </Col>
        </Row>

        <Table>
          <tbody>
            <tr>
              <th>Id</th>
              <th>Nome</th>
              <th>Unidade</th>
              <th>Quantidade</th>
              <th>Produto</th>
              <th>Unidade</th>
              <th>Quantidade</th>
            </tr>
            {(produtos || []).map((item, key) => {
              return (
                <tr key={key}>
                  <td>{item.id}</td>
                  <td>{item.nome}</td>
                  <td>{item.unidade}</td>
                  <td>{parseFloat(item.quantidade)}</td>
                  <td>
                    <CampoProcurarProdutoOnModal
                      value={item.produto}
                      disabled={loading}
                      onSelect={(produto) => {
                        const produtos2 = [...produtos];
                        const p = produtos2[key];
                        p.produto = produto;
                        if (produto) {
                          p.ProdutoId = produto.id;
                          p.unidadeImportar2 = p.unidadeImportar;
                          p.unidadeImportar = produto.unidade;
                        } else {
                          p.ProdutoId = null;
                          p.unidadeImportar = p.unidadeImportar2;
                        }
                        setProdutos(produtos2);
                      }}
                    />
                    {/* <input type='text' value={item.produto || ""} maxLength={50} readOnly disabled={loading} /> */}
                  </td>
                  <td>
                    <input
                      type="text"
                      value={item.unidadeImportar}
                      maxLength={5}
                      size={5}
                      disabled={loading}
                      onChange={(e) => {
                        const produtos2 = [...produtos];
                        const p = produtos2[key];
                        p.unidadeImportar = e.target.value;
                        setProdutos(produtos2);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      step={1}
                      min={1}
                      size={5}
                      disabled={loading}
                      value={parseFloat(item.quantidadeImportar)}
                      onChange={(e) => {
                        const produtos2 = [...produtos];
                        const p = produtos2[key];
                        p.quantidadeImportar = e.target.value;
                        setProdutos(produtos2);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
}

function lancarProdutos({
  socket,
  setLoading,
  onHide,
  id,
  AlmoxarifadoId,
  produtos,
}) {
  setLoading(true);

  let produtos2 = [];
  produtos.forEach((p) => {
    produtos2.push();
  });

  socket.emit(
    "NotaFiscalCompra.LancarProdutos",
    {
      id,
      AlmoxarifadoId,
      produtos: produtos.map((p) => {
        return {
          id: p.id,
          ProdutoId: p.ProdutoId,
          nome: p.nome,
          unidade: p.unidadeImportar,
          quantidade: p.quantidadeImportar,
          preco: p.valorUnitario,
          desconto: p.valorDesconto,
        };
      }),
    },
    (error, resp) => {
      setLoading(false);
      if (error) return toast.error("" + error);
      setLoading(false);
      onHide(false);
    }
  );
}
