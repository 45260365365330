import React from "react";
import { Form, Table } from "react-bootstrap";
import { formatarValor } from "lhmask";

export default function TableMapeamentoCampos({ campos, dadosConsulta, mapeamento, setMapeamento, view = false }) {
  return (
    <Table>
      <thead>
        <tr>
          <th>Campo</th>
          <th>Importar de</th>
          <th className={`${view ? "d-none" : ""}`}>Formato</th>
          <th>Config. do Formato</th>
          <th>
            Valor Original
            <br />
            Valor Formatado
          </th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(campos || {}).map((campo, idx) => {
          const map = (mapeamento || {})[campo];
          let valor = map ? (dadosConsulta ? dadosConsulta[map.campoDestino] : "----") : "----";
          let valorFormatado = getValorFormatado({ map, dados: dadosConsulta });

          return (
            <tr key={idx}>
              <td>{campo}</td>
              <td>
                {view ? (
                  (mapeamento || {})[campo].campoDestino
                ) : (
                  <Form.Control
                    as="select"
                    value={mapeamento && mapeamento[campo] ? mapeamento[campo].campoDestino : ""}
                    onChange={(e) => {
                      let _mapa = { ...mapeamento };
                      _mapa[campo] = _mapa[campo] || {};
                      _mapa[campo].campoDestino = e.target.value;
                      setMapeamento(_mapa);
                    }}
                  >
                    <option></option>
                    {Object.keys(dadosConsulta || {}).map((campoDest, idx) => {
                      return (
                        <option value={campoDest} key={idx}>
                          {campoDest}
                        </option>
                      );
                    })}
                  </Form.Control>
                )}
              </td>
              <td className={`${view ? "d-none" : ""}`}>
                <Form.Control
                  as="select"
                  value={mapeamento && mapeamento[campo] ? mapeamento[campo].formato : ""}
                  onChange={(e) => {
                    let _mapa = { ...mapeamento };
                    _mapa[campo] = _mapa[campo] || {};
                    _mapa[campo].formato = e.target.value;
                    setMapeamento(_mapa);
                  }}
                >
                  <option></option>
                  <option value="cep">CEP</option>
                  <option value="cpfcnpj">CPF / CNPJ</option>
                  <option value="cpf">CPF</option>
                  <option value="cnpj">CNPJ</option>
                  <option value="telefone">Telefone</option>
                  <option value="int">Número Inteiro</option>
                  <option value="float">Número Float</option>
                  <option value="map">Mapa</option>
                  <option value="field_or">Campos Ou</option>
                  <option value="databr">Data (DD/MM/AAAA)</option>
                  <option value="dataMDA">Data (MM/DD/AAAA)</option>
                  <option value="valorfixo">Valor Fixo</option>
                  <option value="split">Split</option>
                  <option value="concat">Concat</option>
                </Form.Control>
              </td>
              <td>
                <Form.Control
                  value={mapeamento && mapeamento[campo] ? mapeamento[campo].formatoData : ""}
                  onChange={(e) => {
                    let _mapa = { ...mapeamento };
                    _mapa[campo] = _mapa[campo] || {};
                    _mapa[campo].formatoData = e.target.value;
                    setMapeamento(_mapa);
                  }}
                />
              </td>
              <td>
                {valor}
                <br />
                {valorFormatado}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

function getValorFormatado({ map, dados }) {
  let valorFormatado;
  try {
    if (map && map.padStart) {
      dados[map.campoDestino] = `${dados[map.campoDestino]}`.padStart(map.padStart, map.padStartChar || "0");
    }

    if (map && map.padEnd) {
      dados[map.campoDestino] = `${dados[map.campoDestino]}`.padEnd(map.padEnd, map.padEndChar || "0");
    }

    switch (map.formato) {
      case "concat":
        dados[map.campoDestino] = "";
        const camposConcat = JSON.parse(map.formatoData).campos;
        camposConcat.forEach((c) => {
          dados[map.campoDestino] += dados[c];
        });

        valorFormatado = getValorFormatado({
          map: {
            campoDestino: map.campoDestino,
            ...JSON.parse(map.formatoData),
          },
          dados,
        });
        break;
      case "int":
        valorFormatado = isNaN(parseInt(dados[map.campoDestino])) ? "" : parseInt(dados[map.campoDestino]);
        break;
      case "float":
        valorFormatado = isNaN(parseFloat(dados[map.campoDestino])) ? "" : parseFloat(dados[map.campoDestino]);
        break;
      case "valorfixo":
        valorFormatado = map.formatoData;
        break;
      case "map":
        const mapaCampo = JSON.parse(map.formatoData);
        valorFormatado = mapaCampo[dados[map.campoDestino]];
        break;
      case "mapSize":
        const mapaSize = JSON.parse(map.formatoData);
        const valorSize = `${dados[mapaSize.campo]}`.length;
        for (let i = 0; i < (mapaSize.tamanhos || []).length; i++) {
          if (valorSize === mapaSize.tamanhos[i].tamanho) {
            valorFormatado = formatarValor(mapaSize.tamanhos[i].valor, mapaSize.tamanhos[i].formato);
            break;
          }
        }
        if (!valorFormatado) {
          valorFormatado = formatarValor(mapaSize.valorPadrao, mapaSize.formatoPadrao);
        }
        break;
      case "field_or":
        const mapaCamposOr = JSON.parse(map.formatoData);
        for (let i = 0; i < mapaCamposOr.length; i++) {
          const campoDestinoOr = mapaCamposOr[i];
          if (dados[campoDestinoOr.campo]) {
            valorFormatado = formatarValor(dados[campoDestinoOr.campo], campoDestinoOr.formato);
            break;
          }
        }
        break;
      case "dataMDA":
        if (dados[map.campoDestino]) {
          valorFormatado = dados[map.campoDestino].split("/");
          valorFormatado =
            valorFormatado[2] + "-" + valorFormatado[0].padStart(2, "0") + "-" + valorFormatado[1].padStart(2, "0");
        } else {
          valorFormatado = undefined;
        }
        break;
      case "databr":
        valorFormatado = dados[map.campoDestino]
          ? dados[map.campoDestino]
              .split("/")
              .reverse()
              .map((d) => `${d}`.padStart(2, "0"))
              .join("-")
          : undefined;
        break;
      case "split":
        const dadosSplit = JSON.parse(map.formatoData) || { por: " ", idx: 0 };
        valorFormatado = dados[map.campoDestino]
          ? `${dados[map.campoDestino]}`.split(dadosSplit.por)[dadosSplit.idx]
          : undefined;
        break;
      default:
        valorFormatado = map ? formatarValor(dados[map.campoDestino], map.formato) : undefined;
    }
  } catch (E) {
    valorFormatado = "ERROR:" + E;
  }

  return valorFormatado;
}
