import FormularioListagem from "componentes/FormularioListagem";
import IoContext from "contextos/IoContext";
import { CUPOM_PARCEIROS } from "paginas/cadastros/marketing/cupons/SelectParceiroCupom";
import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

export default function CupomIndex() {
  const { PessoaId } = useParams();
  const { socket } = React.useContext(IoContext);
  const [cupons, setCupons] = React.useState([]);
  const [pagina, setPagina] = React.useState(1);
  const [total, setTotal] = React.useState(0);
  const [texto, setTexto] = React.useState("");
  const [pessoa, setPessoa] = React.useState({ nome: "" });

  React.useEffect(() => {
    socket.emit(
      "Pessoa.findOne",
      {
        attributes: ["nome"],
        where: {
          id: PessoaId,
        },
      },
      (error, resp) => {
        if (error) {
          toast.error("" + error);
        } else {
          setPessoa(resp);
        }
      }
    );
  }, [socket, PessoaId]);

  React.useEffect(() => {
    setCupons([]);

    const where = { PessoaId };
    if (texto) where.cupom_like = `%${texto}%`;

    socket.emit(
      "Cupom.findAndCountAll",
      {
        where,
        limit: 10,
        offset: 10 * (pagina - 1),
      },
      (error, resp) => {
        if (error) {
          toast.error("" + error);
        } else {
          setCupons(resp.rows);
          setTotal(resp.count);
        }
      }
    );
  }, [socket, pagina, PessoaId, texto]);

  return (
    <FormularioListagem
      titulo={`Cupoms: ${pessoa?.nome}`}
      itens={cupons}
      qtdItens={total}
      pagina={pagina}
      setTexto={setTexto}
      setPagina={setPagina}
      titulos={["Id", "Parceiro", "Cupom"]}
      tamanhos={[{ sm: 1 }, { sm: 4 }, { sm: 7 }]}
      onClick={({ id }) => {
        if (id === "add") {
          toast.info("Adicionar cupom não implementado. ");
        }
      }}
      renderItem={(titulo, item) => {
        switch (titulo) {
          case "Id":
            return item.id;
          case "Cupom":
            return item.cupom;
          case "Parceiro":
            return CUPOM_PARCEIROS[item.parceiro];
          default:
            return titulo;
        }
      }}
    />
  );
}
