import IoContext from "contextos/IoContext";
import React from "react";
import { Card, Form, Row, Col, Button, Container } from "react-bootstrap";
import { toast } from "react-toastify";

export default function IntegracaoCobrancaTerceirizada() {
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(true);
  const [tokenCobrancaTerceirizada, setTokenCobrancaTerceirizada] = React.useState("");
  const [isAtivo, setAtivo] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    setTokenCobrancaTerceirizada("");
    socket.emit("Empresa.GetTokenCobrancaTerceirizada", {}, (error, resp) => {
      setLoading(false);
      if (error) {
        return toast.error("" + error);
      }
      setTokenCobrancaTerceirizada(resp || "");
      setAtivo(Boolean(resp));
    });
  }, [socket]);

  return (
    <Container fluid>
      <Card>
        <Card.Header className="bg-primary">
          <Card.Title className="text-light">Cobrança Terceirizada</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>Token</Form.Label>
                <Form.Control
                  disabled={loading}
                  value={tokenCobrancaTerceirizada}
                  onChange={(e) => setTokenCobrancaTerceirizada(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group>
                <Form.Label>Ativo</Form.Label>
                <Form.Check
                  checked={isAtivo}
                  onChange={() => {
                    setAtivo(!isAtivo);
                  }}
                  disabled={loading}
                />
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    disabled={loading}
                    onClick={(e) => {
                      e.preventDefault();
                      setLoading(true);
                      socket.emit(
                        "Empresa.SaveTokenCobrancaTerceirizada",
                        {
                          tokenCobrancaTerceirizada: isAtivo ? tokenCobrancaTerceirizada : "desativado",
                        },
                        (error, resp) => {
                          setLoading(false);
                          if (error) {
                            return toast.error("" + error);
                          }

                          setTokenCobrancaTerceirizada(resp || "");
                          toast.success("Configurações Atualizadas com Sucesso!");
                        }
                      );
                    }}
                  >
                    Salvar
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}
