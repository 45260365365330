import AuthContext from "contextos/AuthContext";
import React from "react";
import { useParams } from "react-router-dom";

export default function ContratoTabLegacy({ count, url, iframeRef, params = "", height = 100 }) {
  const { usuario } = React.useContext(AuthContext);
  const { contrato_id } = useParams();

  return (
    <iframe
      title="ContratoTabLegacy"
      className={`w-100 h-${height} p-0 m-0 border-0`}
      ref={iframeRef}
      src={`${
        process.env.REACT_APP_LHISP_BACKEND || ""
      }/${url}?ID=${contrato_id}&count=${count}&legacy=1${params}&token=${usuario.token}`}
    >
      Seu navegador não suporta Frames.
    </iframe>
  );
}
