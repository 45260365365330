import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import LoginEmpresa from "./LoginEmpresa";
import LoginUsuarioSenha from "./LoginUsuarioSenha";
import AuthContext from "../../contextos/AuthContext";
import logolhisp from "../../images/lhprovedor.png";
import axios from "axios";
const key_logomarca = `${window.location.hostname}_logomarca`;
let store_logomarca = null;

try {
  store_logomarca = JSON.parse(localStorage.getItem(key_logomarca));
} catch (E) {}

export default function Login() {
  const { empresa, setLogomarca } = React.useContext(AuthContext);
  const [logomarca1, setLogomarca1] = React.useState(store_logomarca);

  React.useEffect(() => {
    if (empresa) {
      axios
        .get("/api/Empresa.getLogo")
        .then((resp) => {
          setLogomarca(resp.data);
          setLogomarca1(resp.data);
          localStorage.setItem(key_logomarca, JSON.stringify(resp.data));
        })
        .catch((error) => {
          console.log("Logo Error:", error);
        });
    }
  }, [empresa, setLogomarca, setLogomarca1]);

  return (
    <Container className="d-flex justify-content-center">
      <Col sm={12} md={6} lg={3}>
        <Row className="pt-4 ms-2 me-2 pt-md-5">
          <Card className="shadow mb-2">
            <Card.Body>
              <Card.Img
                className="mb-3"
                src={
                  logomarca1
                    ? logomarca1?.startsWith("data:")
                      ? logomarca1
                      : `data:image/png;base64, ${logomarca1}`
                    : logolhisp
                }
              />

              {empresa ? <LoginUsuarioSenha /> : <LoginEmpresa />}
            </Card.Body>
          </Card>
        </Row>

        <Row className="text-center">
          Qualquer Dúvida, entre em contato conosco pelo suporte: (88)99715-3040 ( Telefone ou Telegram ).
        </Row>

        <Row className="text-center">
          <div className="text-muted small">LHISP © Leandro Costa 2021</div>
        </Row>
      </Col>
    </Container>
  );
}
