import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import SelectLocalAlmoxarifado from './SelectLocalAlmoxarifado';
import BotaoVoltar from 'componentes/BotaoVoltar';

export default function MovimentacaoAvulsaPag3({
    AlmoxarifadoId, LocalAlmoxarifadoId, setLocalAlmoxarifadoId,
    descricao, setDescricao, quantidade, setQuantidade,
    produto, tipo, loading, pagina, setPagina, adicionarMaterial
}) {
    return <Form onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();

        if (produto && produto.temControlePatrimonial) {
            setPagina(pagina + 1)
        } else {
            adicionarMaterial();
        }
    }}>
        <Card.Body>
            <Row>
                <Col sm={8}>
                    <Form.Group>
                        <Form.Label>Produto</Form.Label>
                        <Alert variant="primary">
                            {produto ? produto.nome : ""}
                        </Alert>
                    </Form.Group>
                </Col>

                <Col sm={4}>
                    <Form.Group>
                        <Form.Label>Operação</Form.Label>
                        <Alert variant={tipo === 'E' ? "success" : "danger"}>
                            {tipo === 'E' ? "Entrada" : "Saída"}
                        </Alert>
                    </Form.Group>
                </Col>

                <Col sm={12}>
                    <Form.Group>
                        <Form.Label>Local</Form.Label>
                        <SelectLocalAlmoxarifado required
                            AlmoxarifadoId={AlmoxarifadoId} value={LocalAlmoxarifadoId}
                            onChange={e => setLocalAlmoxarifadoId(e.target.value)} disabled={loading} />
                    </Form.Group>
                </Col>

                <Col sm={9}>
                    <Form.Group>
                        <Form.Label>Descrição</Form.Label>
                        <Form.Control value={descricao} onChange={e => setDescricao(e.target.value)}
                            required maxLength={100} disabled={loading} />
                    </Form.Group>
                </Col>

                <Col sm={3}>
                    <Form.Group>
                        <Form.Label>Quantidade</Form.Label>
                        <Form.Control type="number" min={1} step={1} required
                            disabled={loading || (produto && produto.temControlePatrimonial)}
                            value={quantidade} onChange={e => setQuantidade(+e.target.value)} />
                    </Form.Group>
                </Col>
            </Row>
        </Card.Body>

        <Modal.Footer>
            <BotaoVoltar onClick={() => { setPagina(pagina - 1) }} texto="Voltar" />

            <Button type="submit" disabled={loading}
                className={`${produto && produto.temControlePatrimonial ? '' : 'd-none'}`}>
                Avançar
            </Button>

            <Button type="submit" variant="primary" disabled={loading}
                className={`${produto && produto.temControlePatrimonial ? 'd-none' : ''}`}>
                {tipo === 'E' ? 'Adicionar Material' : 'Remover Material'}
            </Button>
        </Modal.Footer>
    </Form>
}