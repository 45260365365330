import React from "react";
import IoContext from "../../contextos/IoContext";
import { formatarTelefone } from "misc/utils";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { useParams, useNavigate } from "react-router-dom";

export default function ContratoPorTelefone() {
  const params = useParams();
  const navigate = useNavigate();
  const { socket } = React.useContext(IoContext);
  const [count, setCount] = React.useState(1);
  const [txt, setTxt] = React.useState("");
  const [contratos, setContratos] = React.useState([]);
  const telefone = formatarTelefone(params.telefone);

  React.useEffect(() => {
    if (!telefone) return;

    setTxt(`Aguarde, procurando pelo Telefone: ${telefone}`);
    setContratos([]);

    socket.emit(
      "Contrato.findAll",
      {
        attributes: ["id", "numero_contrato"],
        withPessoa: {
          attributes: ["nome"],
          where: {
            or: [
              {
                telefone1: telefone,
              },
              {
                telefone2: telefone,
              },
              {
                telefone3: telefone,
              },
              {
                telefone4: telefone,
              },
            ],
          },
        },
        limit: 25,
      },
      (error, resp) => {
        if (error) {
          return setTxt("ERROR:" + error);
        }

        if (resp.length === 1) {
          setTxt(`Contrato encontrado: ${resp[0].numero_contrato} !`);
          navigate(`/contratos/${resp[0].id}`);
        } else {
          setTxt(`${resp.length} Contratos encontrados.`);
          setContratos(resp);
        }
      }
    );
  }, [navigate, socket, telefone, count]);

  return (
    <Container className="mt-3">
      <Card>
        <Card.Header className="bg-primary text-light">
          <Card.Title>
            {telefone} - {txt}
          </Card.Title>
        </Card.Header>

        <Card.Body>
          <Table hover>
            <thead>
              <tr>
                <th>Contrato</th>
                <th>Cliente</th>
              </tr>
            </thead>
            <tbody>
              {(contratos || []).map((c) => {
                const pessoa = c.Pessoa || {};
                return (
                  <tr
                    onClick={() => {
                      navigate(`/contratos/${c.id}`);
                    }}
                  >
                    <td>{c.numero_contrato}</td>
                    <td>{pessoa.nome}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Card.Footer className="text-right">
        <Button variant="primary" onClick={() => setCount(count + 1)}>
          Atualizar
        </Button>
      </Card.Footer>
    </Container>
  );
}
