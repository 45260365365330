import React from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import SelectAlmoxarifados from '../SelectAlmoxarifados';
import { toast } from 'react-toastify';
import IoContext from '../../../contextos/IoContext';

export default function ModalAutorizarSolicitacaoMaterial({ solicitacao_material, show, onHide, callback, pular }){
    const { socket } = React.useContext(IoContext);
    const [almoxarifadoId, setAlmoxarifadoId] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    return <Modal  show={show} onHide={onHide} size="lg">
        <Form onSubmit={ e => {
            e.preventDefault();

            setLoading(true);
            socket.emit("SolicitacaoMaterial.autorizar", {
                id: +solicitacao_material.id,
                AlmoxarifadoId: almoxarifadoId
            }, (error, resp) => { 
                setLoading(false);
                if(error){
                    toast.error(""+error);
                } else {
                    toast.success("Solicitação Autorizada com Sucesso !");
                    if(callback){
                        callback(resp);
                    } else {
                        onHide(false)
                    }
                }
            })
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Autorizar Solicitação de Material</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form.Group>
                    <label>Almoxarifado de Destino</label>
                    <SelectAlmoxarifados
                        required
                        disabled={loading}
                        value={almoxarifadoId}
                        pular={pular}
                        onChange={ e => {
                            setAlmoxarifadoId(e.target.value);
                        }} />
                </Form.Group>
                <p>
                    Ao Autorizar a Solicitação de Material, será gerada uma 
                    <strong> Ordem de Separação </strong> no Almoxarifado selecionado,
                    para que o Material em questão possa ser devidamente separado e 
                    encaminhado ao solicitante.
                </p>
            </Modal.Body>

            <Modal.Footer>
                <Button className="btn-warning" disabled={loading} onClick={()=>{
                    onHide(false);
                }}>Cancelar</Button>

                <Button className="btn-primary" disabled={loading} type="submit">Autorizar</Button>
            </Modal.Footer>
        </Form>
    </Modal>
}