import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Produto from './Produto';
import Produtos from './Produtos';

export default function CadastroProdutosIndex() {
    const [texto, setTexto] = React.useState('');
    const [pagina, setPagina] = React.useState(1);

    return <Routes>
        <Route path={`/:id`} element={<Produto />} />
        <Route path={`*`} element={<Produtos
            texto={texto} setTexto={setTexto}
            pagina={pagina} setPagina={setPagina}
        />
        } />
    </Routes>
}