import React from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import Almoxarifados from './Almoxarifados';
import HistoricoEstoque from './HistoricoEstoque';
import PatrimonioAlmoxarifado from './PatrimonioAlmoxarifado';

export default function CadastrosIndex() {
    const params = useParams();
    const id = isNaN(parseInt(params.id)) ? "" : +params.id;
    const [AlmoxarifadoId, setAlmoxarifadoId] = React.useState(id);
    const [CategoriaId, setCategoriaId] = React.useState('');
    const [exibirCategorias, setExbirCategorias] = React.useState(true);
    const [produto, setProduto] = React.useState('');
    const [exibirEstoqueZero, setExibirEstoqueZero] = React.useState(false);

    return <Routes>
        <Route path={`:AlmoxarifadoId/:ProdutoId/historico`} element={<HistoricoEstoque />} />
        <Route path={`:AlmoxarifadoId/:ProdutoId/patrimonio`} element={<PatrimonioAlmoxarifado />} />
        <Route path={`*`} element={<Almoxarifados
            AlmoxarifadoId={AlmoxarifadoId} setAlmoxarifadoId={setAlmoxarifadoId}
            CategoriaId={CategoriaId} setCategoriaId={setCategoriaId}
            exibirCategorias={exibirCategorias} setExbirCategorias={setExbirCategorias}
            produto={produto} setProduto={setProduto}
            exibirEstoqueZero={exibirEstoqueZero} setExibirEstoqueZero={setExibirEstoqueZero}
        />} />
    </Routes>
}