import React from "react";
import { Table } from "react-bootstrap";

export default function TableListagem({
  titulos = [],
  tamanhos = [],
  itens = [],
  onClick = null,
  renderItem,
  renderSummary = null,
  exibirBordas = false,
  getClasseLinha = null,
}) {
  return (
    <Table bordered={exibirBordas} responsive>
      <thead>
        <tr>
          {titulos.map((titulo, idxTitulo) => {
            return <th>{titulo}</th>;
          })}
        </tr>
      </thead>

      <tbody>
        {(itens || []).map((item, idxItem) => {
          return (
            <tr>
              {titulos.map((titulo, idxTitulo) => {
                return <td>{renderItem(titulo, item, idxItem)}</td>;
              })}
            </tr>
          );
        })}
      </tbody>

      {renderSummary && <tfoot>{renderSummary()}</tfoot>}
    </Table>
  );
}
