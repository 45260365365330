import CampoData from "componentes/CampoData";
import SelectSituacaoContaReceber from "componentes/SelectSituacaoContaReceber";
import { CONTARECEBER_EM_ABERTO } from "datatypes/ContaReceberTypes";
import React from "react";
import { Form, Row, Col, Card } from "react-bootstrap";
import ContratoTabLegacy from "./ContratoTabLegacy";

export default function ContratoTabFinanceiro({ count }) {
  const [dini, setDini] = React.useState("");
  const [dend, setDend] = React.useState("");
  const [exibirApagadas, setExibirApagadas] = React.useState(false);
  const iframeRef = React.useRef(null);
  const [situacao, setSituacao] = React.useState(CONTARECEBER_EM_ABERTO);

  return (
    <Card.Body className="h-100">
      <Row>
        <Col sm={2}>
          <Form.Group>
            <Form.Label>Data Inicial</Form.Label>
            <CampoData value={dini} onChange={(e) => setDini(e.target.value)} />
          </Form.Group>
        </Col>
        <Col sm={2}>
          <Form.Group>
            <Form.Label>Data Final</Form.Label>
            <CampoData value={dend} onChange={(e) => setDend(e.target.value)} />
          </Form.Group>
        </Col>

        <Col sm={4}>
          <Form.Group>
            <Form.Label>Situação</Form.Label>
            <SelectSituacaoContaReceber value={situacao} onChange={(e) => setSituacao(e.target.value)} />
          </Form.Group>
        </Col>

        <Col sm={4}>
          <Form.Group>
            <Form.Label>Opções</Form.Label>
            <div>
              <Form.Check
                type="checkbox"
                label="Exibir Contas Apagadas ?"
                checked={exibirApagadas}
                onChange={() => setExibirApagadas(!exibirApagadas)}
              />
            </div>
          </Form.Group>
        </Col>
      </Row>

      <ContratoTabLegacy
        count={count}
        height={90}
        iframeRef={iframeRef}
        url="form/contratos/tab_financeiro.php"
        params={`&DINI=${dini}&DEND=${dend}&STATUS=${situacao}&exibirApagadas=${exibirApagadas ? "1" : "0"}`}
      />
    </Card.Body>
  );
}
