import React from 'react';
import { Routes, Route } from 'react-router-dom';

import SolicitacaoDeMaterial from './solicitaboes_de_material/SolicitacaoDeMaterial';
import SolicitacoesDeMaterial from './solicitaboes_de_material/SolicitacoesDeMaterial';
import RemessasDeMaterial from './remessas_de_material/index';
import EstoquePorFuncionario from './estoque_por_funcionario/EstoquePorFuncionario';
import DevolucaoMaterial from './estoque_por_funcionario/DevolucaoMaterial';
import HistoricoMaterialFuncionario from './estoque_por_funcionario/HistoricoMaterialFuncionario'
import NotaFiscalCompra from './nota_fiscal_compra';
import OrdensSeparacao from './ordem_separacao';

export default function EstoqueIndex(){
    return <Routes>
        <Route path={`/solicitacoes_de_material/:id`} element={<SolicitacaoDeMaterial />} />
        <Route path={`/solicitacoes_de_material`} element={<SolicitacoesDeMaterial />} />
        <Route path={`/remessas_de_materiais/*`} element={<RemessasDeMaterial />} />
        <Route path={`/por_funcionario/:id/devolucao`} element={<DevolucaoMaterial />} />
        <Route path={`/por_funcionario/:id/historico`} element={<HistoricoMaterialFuncionario />} />
        <Route path={`/por_funcionario/:id`} element={<EstoquePorFuncionario />} />
        <Route path={`/por_funcionario`} element={<EstoquePorFuncionario />} />
        <Route path={`/notas_fiscais_compra/*`} element={<NotaFiscalCompra />} />
        <Route path={`/ordem_separacao/*`} element={<OrdensSeparacao />} />
    </Routes>
}