import React from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import AuthContext from "../../contextos/AuthContext";
import { toast } from "react-toastify";

export default function LoginEmpresa() {
  const [txt, setTxt] = React.useState("");
  const [mensagem, setMensagem] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { empresa, setEmpresa, setSysname } = React.useContext(AuthContext);

  React.useEffect(() => {
    let where = {
      ativo: true,
    };

    if (empresa) {
      if (empresa.vhost === window.location.hostname) {
        where.id = empresa.id;
      } else {
        console.log("Empresa Armazenada no storage é de outro vhost:", empresa.vhost, window.location.hostname);
        where.vhost = window.location.hostname;
        setEmpresa(null);
      }
    } else {
      where.vhost = window.location.hostname;
    }

    setLoading(true);
    axios
      .post("/api/Empresa.findOne", {
        where,
      })
      .then((resp) => {
        setLoading(false);
        if (resp.data?.status === "ok") {
          setEmpresa(resp.data?.data);
        } else {
          console.log("## EMPRESA POR VHOST ERROR:", resp.data.error);
          setMensagem(resp.data?.message);
        }
      })
      .catch((error) => {
        console.log("## EMPRESA FIND ONE ERROR:", error);
        setLoading(false);
        setEmpresa(null);
        switch (+error.response?.status) {
          case 404: // Empresa Não Encontrada para o Vhost.
            break;
          case 403: // Empresa não habilitada ainda.
            toast.error("" + error.response?.data);
            break;
          default:
            toast.error(`Erro ao Consultar Empresa:<br/> [${error.response?.status}] ${error.response?.data}`);
        }
      });
  }, [empresa, setEmpresa]);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();

        setLoading(true);
        axios
          .post("/api/Empresa.findOne", {
            where: { sysname: txt },
          })
          .then((resp) => {
            setLoading(false);
            if (resp.data.status === "ok") {
              setEmpresa(resp.data);
              setSysname(resp.data.sysname);
            }
          })
          .catch((error) => {
            setLoading(false);
            if (error.response) {
              toast.error("ERROR 1:" + error.response.data);
            } else {
              toast.error("ERROR 2:" + error);
            }
          });
      }}
    >
      <Form.Group>
        <Form.Label>Empresa</Form.Label>
        <Form.Control autoFocus disabled={loading} value={txt} onChange={(e) => setTxt(e.target.value)} />
      </Form.Group>

      <Form.Group>
        <div className="text-danger">{mensagem}</div>
      </Form.Group>

      <Button variant="primary" type="submit" disabled={loading} className="float-end">
        Próximo
      </Button>
    </Form>
  );
}
