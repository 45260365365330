import React from 'react';
import Form from 'react-bootstrap/Form';
import IoContext from '../../../contextos/IoContext';

export default function SelectVendedor(props){
    const { socket } = React.useContext(IoContext);
    const { value, onChange, disabled } = props; 
    const [vendedores, setVendedores] = React.useState([]);

    React.useEffect(()=>{
        setVendedores([]);
        
        socket.emit('Vendedor.findAll', { 
            where: {
                is_vendedor: true
            },
            attributes: ['id','nome'],
            order: [['nome', 'asc']]
        }, (error, vendedores) => {
            if(error) return console.log("Vendedores.findAll:", error);
            
            setVendedores(vendedores || []);
        })
    }, [socket]);

    return <Form.Control as="select" 
        onChange={onChange}
        value={value}
        disabled={disabled}
        {...props}
    >
        <option value=""></option>
        { (vendedores || []).map( c => {
            return <option key={c.id} value={c.id}>{c.nome}</option>
        })}
    </Form.Control>;
}