import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import { toast } from 'react-toastify';
import IoContext from '../../../../contextos/IoContext';
import BotaoApagar from '../../../../componentes/BotaoApagar';
import CabecalhoCadastro from '../../../../componentes/CabecalhoCadastro';
import { useNavigate, useParams } from 'react-router';

const dias = [];
for(let i=1; i <= 31; i++) dias.push(i);

export default function Feriado(){
    const params = useParams();
    const navigate = useNavigate();
    const { socket } = React.useContext(IoContext);
    const [loading, setLoading] = React.useState(false);
    const id = parseInt(params.id);
    const [data_feriado, setDataFeriado] = React.useState((new Date()).toISOString().split("T")[0]);
    const [descricao, setDescricao] = React.useState("");

    React.useEffect(()=>{
        setLoading(true);
        socket.emit("Feriado.findOne", { 
            where: { id } 
        }, (error, resp) => {
            setLoading(false);
            if(error) {
                toast.error(error);
            } else {
                if(resp){
                    setDataFeriado(resp.data_feriado);
                    setDescricao(resp.descricao);
                }
            }
        });
    }, [id, socket]);

    return <Container fluid className="pt-3">
        <Card>
            <CabecalhoCadastro 
                id={id} disabled={loading} titulo='Feriado'
                salvar={ e => {
                    if(!descricao) return toast.info("Digite a Descrição da Feriado");
                    if(!data_feriado) return toast.info("Selecione a Data do Feriado");

                    setLoading(true);
                    socket.emit("Feriado.salvar", {
                        id, data_feriado, descricao
                    }, (error, resp) => {
                        setLoading(false);
                        if(error) {
                            toast.error(error);
                        } else {
                            toast.success("Feriado salva com sucesso !");
                            navigate(-1);
                        }
                    });
                }}
                />

            <Card.Body>
                <Row>   
                    <Col sm={3}>
                        <Form.Group>
                            <Form.Label>Id</Form.Label>
                            <Form.Control  value={isNaN(id) ? "" : id} readOnly />
                        </Form.Group>
                    </Col>

                    <Col sm={3}>
                        <Form.Group>
                            <Form.Label>Data</Form.Label>
                            <Form.Control type='date'
                                value={data_feriado}
                                disabled={loading} required={true}
                                onChange={ e => setDataFeriado(e.target.value) } 
                                />
                        </Form.Group>
                    </Col>

                    <Col sm={12}>
                        <Form.Group>
                            <Form.Label>Descrição</Form.Label>
                            <Form.Control 
                                disabled={loading}
                                value={descricao} 
                                onChange={ e => setDescricao(e.target.value) }
                                />
                        </Form.Group>
                    </Col>
                </Row>
            </Card.Body>

            <Card.Footer>
                { isNaN(id) ? false : <BotaoApagar 
                    disabled={loading}
                    apagar={ () => {
                        setLoading(true);
                        socket.emit("Feriado.apagar", { id }, (error, resp) => {
                            setLoading(false);

                            if(error) {
                                toast.error(''+error);
                            } else {
                                toast.success("Feriado salva com sucesso !");
                                navigate(-1);
                            }
                        });
                    }}
                    /> }
            </Card.Footer>
        </Card>
    </Container>;
}