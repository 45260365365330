import React from 'react';
import Button from 'react-bootstrap/Button';
import IoContext from 'contextos/IoContext';
import FormularioListagem from 'componentes/FormularioListagem';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import ModalTransferirItemCentroCusto from './ModalTransferirItemCentroCusto';

export default function CentrosDeCusto() {
    const navigate = useNavigate();
    const { socket } = React.useContext(IoContext);
    const [loading, setLoading] = React.useState(false);
    const [pagina, setPagina] = React.useState(1);
    const [texto, setTexto] = React.useState("");
    const [centroCusto, setCentrosCusto] = React.useState([]);
    const [total, setTotal] = React.useState(0);
    const [showModal, setShowModal] = React.useState(false);
    const [count, setCount] = React.useState(0);

    React.useEffect(() => {
        let where = {};
        if (texto) where.nome_like = `%${texto}%`;

        setLoading(true);
        setCentrosCusto([]);
        socket.emit("CentroCusto.findAndCountAll", {
            where, limit: 10, offset: 10 * (pagina - 1)
        }, (error, resp) => {
            setLoading(false);
            if (error) {
                toast.error('' + error);
            } else {
                setTotal(resp.count);
                setCentrosCusto(resp.rows);
            }
        });
    }, [socket, texto, pagina]);

    return <React.Fragment>
        <FormularioListagem
            titulo={`Centros de Custo`}
            itens={centroCusto} qtdItens={total} loading={loading}
            pagina={pagina} setTexto={setTexto} setPagina={setPagina}
            titulos={["Id", "Centro de Custo"]}
            onClick={(e) => {
                navigate(`${e.id}`);
            }}

            renderItem={(titulo, item) => {
                switch (titulo) {
                    case 'Id': return item.id;
                    case 'Centro de Custo': return item.nome;
                    default: return titulo;
                }
            }}

            renderBotoesCustom={() => {
                return <React.Fragment>
                    <Button className="ms-2" title="Transferir Item de Centro de Custo" onClick={() => {
                        setCount(count + 1);
                        setShowModal(true);
                    }}>
                        <FontAwesomeIcon icon={faExchangeAlt} />
                    </Button>
                </React.Fragment>
            }}
        />

        <ModalTransferirItemCentroCusto show={showModal} onHide={setShowModal} count={count} />
    </React.Fragment>
}