export const ORDEM_SEPARACAO_TIPO_PARA_TECNICO = 1;
export const ORDEM_SEPARACAO_TIPO_TRANSF_ALMOXARIFADO = 2;
export const ORDEM_SEPARACAO_TIPO_INFRAESTRUTURA = 3;

export const OrdemSeparacaoTipos = {
    1: 'PARA O TÉCNICO',
    2: 'TRANSFERÊNCIA DE ALMOXARIFADO',
    // 3: 'INFRAESTRUTURA',
};

export const ORDEM_SEPARACAO_EM_ABERTO          = 1;
export const ORDEM_SEPARACAO_AGUARDANDO_ENTREGA = 2;
export const ORDEM_SEPARACAO_MATERIAL_SEPARADO  = 3;
export const ORDEM_SEPARACAO_EM_CONCLUIDA       = 10;

export const OrdemSeparacaoSituacoes = {
    1 : "EM ABERTO",
    2 : "AGUARDANDO ENTREGA MATERIAL",
    10: "CONCLUIDA",
}