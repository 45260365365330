import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate, useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Listagem from 'componentes/Listagem';
import LoadingContext from 'contextos/LoadingContext';
import { toast } from 'react-toastify';
import IoContext from 'contextos/IoContext';
import TituloContext from 'contextos/TituloContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSync, faArrowLeft, faPrint } from '@fortawesome/free-solid-svg-icons';
import ModalAdicionarPatrimonio from 'paginas/patrimonio/ModalAdicionarPatrimonio';

export default function PatrimonioAlmoxarifado() {
    const params = useParams();
    const navigate = useNavigate();
    const { setTitulo } = React.useContext(TituloContext);
    const { ProdutoId, AlmoxarifadoId } = params;
    const { socket } = React.useContext(IoContext);
    const { loading } = React.useContext(LoadingContext);
    const [produto, setProduto] = React.useState(null);
    const [almoxarifado, setAlmoxarifado] = React.useState(null);
    const [itens, setItens] = React.useState([]);
    const [count, setCount] = React.useState(0);
    const [showModal, setShowModal] = React.useState(false);

    React.useEffect(() => {
        setTitulo(`Patrimônio do Almoxarifado`);
    }, [setTitulo]);

    React.useEffect(() => {
        socket.emit("Produto.findOne", {
            attributes: ['nome', 'exigirNumeroSerie', 'exigirPatrimonio', 'exigirMac'],
            where: {
                id: ProdutoId
            }
        }, (error, resp) => {
            if (error) {
                toast.error('' + error);
            } else {
                setProduto(resp);
            }
        })
    }, [socket, ProdutoId]);

    React.useEffect(() => {
        socket.emit("Almoxarifado.findOne", {
            attributes: ['nome'],
            where: {
                id: AlmoxarifadoId
            }
        }, (error, resp) => {
            if (error) {
                toast.error('' + error);
            } else {
                setAlmoxarifado(resp);
            }
        })
    }, [socket, AlmoxarifadoId]);

    React.useEffect(function () {
        setItens([]);
        socket.emit("Patrimonio.findAll", {
            attributes: ['id', 'numeroSerie', 'numeroPatrimonio', 'enderecoMac', 'quantidade'],
            where: {
                AlmoxarifadoId,
                ProdutoId,
            },
            order: [['createdAt', 'asc']]
        }, (error, resp) => {
            if (error) {
                toast.error('' + error);
            } else {
                setItens(resp);
            }
        });
    }, [socket, ProdutoId, AlmoxarifadoId, count]);

    return <Container fluid className="pt-3">
        <Card>
            <Card.Body>
                <Form onSubmit={e => {
                    setCount(count + 1);
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                }}>
                    <Row>
                        <Col sm={4}>
                            <Form.Group>
                                <Form.Label>Almoxarifado</Form.Label>
                                <Form.Control value={almoxarifado ? almoxarifado.nome : '----'} readOnly />
                            </Form.Group>
                        </Col>

                        <Col sm={4}>
                            <Form.Group>
                                <Form.Label>Produto</Form.Label>
                                <Form.Control value={produto ? produto.nome : '----'} readOnly />
                            </Form.Group>
                        </Col>

                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Ações</Form.Label>
                                <div>
                                    <Button
                                        className='me-2' variant='primary' title="Voltar" onClick={() => navigate(-1)}>
                                        <FontAwesomeIcon icon={faArrowLeft} />
                                    </Button>

                                    <Button
                                        type="submit"
                                        className='me-2' variant='info'
                                        title="Atualizar Listagem"
                                        disabled={loading}>
                                        <FontAwesomeIcon icon={faSync} />
                                    </Button>

                                    <Button
                                        className='me-2' variant='secondary' title="Imprimir" onClick={() => window.print()}>
                                        <FontAwesomeIcon icon={faPrint} />
                                    </Button>

                                    <Button
                                        className='me-2' variant='success' title="Adicionar" onClick={() => setShowModal(true)}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>

        <Card className="mt-3">
            <Card.Body>
                <Listagem
                    titulos={['Id', 'Número de Série', 'Número de Patrimônio', 'Endereço MAC', 'Quantidade']}
                    itens={itens || []}
                    tamanhos={[
                        { sm: 12, md: 1 },
                        { sm: 12, md: 2 },
                        { sm: 12, md: 2 },
                        { sm: 12, md: 2 },
                        { sm: 12, md: 3 },
                        { sm: 12, md: 2 },
                    ]}
                    renderItem={(titulo, item) => {
                        switch (titulo) {
                            case 'Id': return item.id;
                            case 'Data': return (new Date(item.createdAt)).toLocaleString();
                            case 'Número de Série': return item.numeroSerie;
                            case 'Número de Patrimônio': return item.numeroPatrimonio;
                            case 'Endereço MAC': return item.enderecoMac;
                            case 'Quantidade': return `${parseInt(item.quantidade)}`;
                            default: return titulo
                        }
                    }}
                />
            </Card.Body>

            <Card.Footer>
            </Card.Footer>
        </Card>

        <ModalAdicionarPatrimonio show={showModal} onHide={() => {
            setShowModal(false);
            setCount(count + 1);
        }}
            AlmoxarifadoId={AlmoxarifadoId} ProdutoId={ProdutoId}
            exigirNumeroSerie={produto ? produto.exigirNumeroSerie : false}
            exigirPatrimonio={produto ? produto.exigirPatrimonio : false}
            exigirMac={produto ? produto.exigirMac : false}
        />
    </Container>
}