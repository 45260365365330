import React from "react";
import CampoProcurar from "../../componentes/CampoProcurar";
import IoContext from "../../contextos/IoContext";

export default function CampoProcurarProdutoOnModal({
  titulo = "Selecionar Produto",
  value,
  onSelect,
  disabled,
}) {
  const { socket } = React.useContext(IoContext);
  const [produtos, setProdutos] = React.useState([]);
  const loading = null;

  const getProdutos = React.useCallback(
    (texto) => {
      socket.emit(
        "Produto.findAll",
        {
          where: {
            nome_like: `%${texto}%`,
          },
          limit: 25,
        },
        (error, resp) => {
          if (error) {
            console.log("Produto.findAll Error:", error);
          } else {
            setProdutos(resp);
          }
        }
      );
    },
    [socket]
  );

  return (
    <CampoProcurar
      titulo={"Selecionar Produto" || titulo}
      disabled={disabled || loading}
      value={typeof value === "object" && value ? value.nome : "----"}
      onSelect={onSelect}
      titulos={["Id", "Produto"]}
      itens={produtos}
      renderItem={(titulo, item, idx) => {
        switch (titulo) {
          case "Id":
            return item.id;
          case "Produto":
            return item.nome;
          default:
            return titulo;
        }
      }}
      onSearch={(txt) => {
        getProdutos(txt);
      }}
    />
  );
}
