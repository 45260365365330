import React from "react";
import Table from "react-bootstrap/Table";
import { CONTA_PAGAR_SITUACAO_COR, CONTA_PAGAR_SITUACAO } from "datatypes/ContasPagarTypes";
import { DateTime } from "luxon";

export default function TableSinteticoContasPagar({ titulo, detalhamento, contas, exibirAnalitico, total }) {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th>{titulo}</th>
          <th className="text-right">Quantidade</th>
          <th className="text-right" colSpan={2}>
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(detalhamento || {}).map((_item) => {
          return (
            <React.Fragment>
              <tr>
                <td>{_item}</td>
                <td className="text-right">{detalhamento[_item].quantidade}</td>
                <td className="text-right">
                  {detalhamento[_item].total.toLocaleString(window.navigator.language, {
                    style: "currency",
                    currency: "BRL",
                  })}
                </td>
                <td className="text-right">{Math.round((detalhamento[_item].total / total) * 100)}%</td>
              </tr>

              {/* Exibe os sub-itens se tenhos algum e se o primeiro tem label. Evitando os "SEM ITEM" */}
              {((Object.keys(detalhamento[_item].itens).length === 1 &&
                Object.keys(detalhamento[_item].itens)[0] !== "SEM ITEM") ||
                Object.keys(detalhamento[_item].itens).length !== 1 ||
                exibirAnalitico) &&
                Object.keys(detalhamento[_item].itens).map((_subitem) => {
                  const subitem = detalhamento[_item].itens[_subitem];
                  const contasSubitem = (contas[_item] || {})[_subitem];
                  return (
                    <React.Fragment>
                      <tr className="small">
                        <td className="ps-5">{_subitem}</td>
                        <td className="text-right">{subitem.quantidade}</td>
                        <td className="text-right">
                          {subitem.total.toLocaleString(window.navigator.language, {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </td>
                        <td className="text-right">
                          {Math.round((subitem.total / detalhamento[_item].total) * 100)} %
                        </td>
                      </tr>

                      {(contasSubitem || []).length > 0 && exibirAnalitico ? (
                        <React.Fragment>
                          <tr>
                            <td colSpan={4} className="m-0 p-0 ps-5">
                              <Table responsive size="sm">
                                <thead>
                                  <tr>
                                    <th>Filial</th>
                                    <th>Fornecedor</th>
                                    <th>Descrição</th>
                                    <th>Data de Vencimento</th>
                                    <th>Data de Pagamento</th>
                                    <th>Valor</th>
                                    <th>Valor Pago</th>
                                    <th>Situação</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {contasSubitem?.map((conta) => {
                                    return (
                                      <tr className={`bg-${CONTA_PAGAR_SITUACAO_COR[conta.situacao]}`}>
                                        <td>{(conta.Filial || {}).nome}</td>
                                        <td>{(conta.Fornecedor || {}).nome}</td>
                                        <td>{conta.descricao || "SEM DESCRICAO"}</td>
                                        <td>
                                          {DateTime.fromISO(conta.dataVencimento).toLocaleString(DateTime.DATE_SHORT)}
                                        </td>
                                        <td>
                                          {conta.dataPagamento
                                            ? DateTime.fromISO(conta.dataPagamento).toLocaleString(DateTime.DATE_SHORT)
                                            : ""}
                                        </td>
                                        <td>
                                          {parseFloat(conta.valor).toLocaleString(window.navigator.language, {
                                            style: "currency",
                                            currency: "BRL",
                                          })}
                                        </td>
                                        <td>
                                          {parseFloat(conta.valorPago).toLocaleString(window.navigator.language, {
                                            style: "currency",
                                            currency: "BRL",
                                          })}
                                        </td>
                                        <td>{CONTA_PAGAR_SITUACAO[conta.situacao]}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </td>
                          </tr>
                        </React.Fragment>
                      ) : (
                        false
                      )}
                    </React.Fragment>
                  );
                })}
            </React.Fragment>
          );
        })}
        <tr>
          <td>Total</td>
          <td className="text-right"></td>
          <td className="text-right">
            {total?.toLocaleString(window.navigator.language, {
              style: "currency",
              currency: "BRL",
            })}
          </td>
          <td className="text-right"></td>
        </tr>
      </tbody>
    </Table>
  );
}
