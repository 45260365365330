import React from 'react';
import Form from 'react-bootstrap/Form';
import IoContext from '../../../contextos/IoContext';

export default function SelectCategorias(props){
    const { socket } = React.useContext(IoContext);
    const { id, value, onChange, disabled, pular } = props; 
    const [contasBancarias, setContasBancarias] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(()=>{
        setLoading(true);
        socket.emit("Categoria.findAll", {
            attributes: ['id','nome'],
            order: ['nome']
        }, (error, resp) => {
            setLoading(false);
            if(error) {
                console.log("Categoria.findAll Error:", error);
            } else {
                setContasBancarias(resp);
            }
        });
    }, [socket]);

    return <Form.Control as="select" 
        {...props}
        id={id || "CategoriaId"} 
        onChange={ e => {
            let cb = null;
            for(const i in contasBancarias) {
                const c = contasBancarias[i];
                if(c.id === e.target.value) {
                    cb = c;
                    break;
                }
            }
            onChange(e, cb);
        }}
        value={value}
        disabled={loading || disabled}
    >
        <option value=""></option>
        { (contasBancarias || []).map( c => {
            if(pular) {
                if(Array.isArray(pular)) {
                    if(pular.indexOf(c.id)){
                        return false;
                    }
                } else {
                    if(+pular === +c.id) {
                        return false;
                    }
                }
            }
            return <option key={c.id} value={c.id}>{c.nome}</option>
        })}
    </Form.Control>;
}