import BotaoAtualizar from "componentes/BotaoAtualizar";
import BotaoImprimir from "componentes/BotaoImprimir";
import SelectMes from "componentes/SelectMes";
import IoContext from "contextos/IoContext";
import { formatarDataHoraDbs, getFinalMes, getInicioMes } from "misc/lhdatas";
import { formatarDinheiro } from "misc/formatarDinheiro";
import React from "react";
import { Col, Form, Modal, Row, Table } from "react-bootstrap";
import { toast } from "react-toastify";

export default function ModalHistoricoLigacoes({ ContratoVoipId, show, onHide, disabled }) {
  const { socket } = React.useContext(IoContext);
  const [historico, setHistorico] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const tableElm = React.useRef(null);
  const [mes, setMes] = React.useState(new Date().getMonth() + 1);
  const [ano, setAno] = React.useState(new Date().getFullYear());

  React.useEffect(() => {
    setHistorico([]);
    if (!ContratoVoipId) return;

    setLoading(true);
    socket.emit(
      "RegistroChamadaVoip.findAll",
      {
        attributes: ["id", "createdAt", "numeroOrigem", "numeroDestino", "situacao", "duracao", "custo"],
        where: {
          ContratoVoipId,
          createdAt_between: [
            formatarDataHoraDbs(getInicioMes({ ano, mes })),
            formatarDataHoraDbs(getFinalMes({ ano, mes })),
          ],
        },
      },
      (error, historico) => {
        setLoading(false);
        if (error) return toast.error("" + error);
        setHistorico(historico);
      }
    );
  }, [ContratoVoipId, ano, mes, count, socket]);

  let total = 0;
  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Histórico de Ligações</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col sm={4}>
            <Form.Group>
              <Form.Label>Mês</Form.Label>
              <SelectMes value={mes} onChange={(e) => setMes(+e.target.value)} disabled={loading} />
            </Form.Group>
          </Col>

          <Col sm={4}>
            <Form.Group>
              <Form.Label>Ano</Form.Label>
              <Form.Control
                type="number"
                min={2000}
                step={1}
                value={ano}
                onChange={(e) => setAno(+e.target.value)}
                disabled={loading}
              />
            </Form.Group>
          </Col>

          <Col sm={4}>
            <Form.Group>
              <Form.Label>Ações</Form.Label>
              <div>
                <BotaoImprimir className="me-2" disabled={disabled || loading} printElement={tableElm} />
                <BotaoAtualizar disabled={disabled || loading} onClick={() => setCount(count + 1)} />
              </div>
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>

      <Table ref={tableElm}>
        <thead>
          <tr>
            <th>Data/Hora</th>
            <th>Origem</th>
            <th>Destino</th>
            <th>Status</th>
            <th>Duração</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          {historico.map((h) => {
            total += h.custo;

            return (
              <tr key={h.id}>
                <td>{new Date(h.createdAt).toLocaleString()}</td>
                <td>{h.numeroOrigem}</td>
                <td>{h.numeroDestino}</td>
                <td>{h.situacao}</td>
                <td>{h.duracao}</td>
                <td>{formatarDinheiro(h.custo / 100)}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={6}>Total={formatarDinheiro(total / 100)}</td>
          </tr>
        </tfoot>
      </Table>
    </Modal>
  );
}
