import React from 'react';
import { Routes, Route } from 'react-router-dom';
import TicketSuporte from './TicketSuporte';
import TicketsSuporte from './TicketsSuporte';

export default function TicketsIndex(){
    const [texto, setTexto] = React.useState('');
    const [pagina, setPagina] = React.useState(1);

    return <Routes>
        <Route path={`/:id`} element={<TicketSuporte />} />
        <Route path={`*`} element={<TicketsSuporte
                texto={texto} setTexto={setTexto} 
                pagina={pagina} setPagina={setPagina}
                />
            } />
    </Routes>
}