import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import { toast } from 'react-toastify';
import BotaoApagar from 'componentes/BotaoApagar';
import CabecalhoCadastro from 'componentes/CabecalhoCadastro';
import IoContext from 'contextos/IoContext';
import { useNavigate, useParams } from 'react-router';

export default function TicketSuporte(){
    const params = useParams();
    const navigate = useNavigate();
    const { socket } = React.useContext(IoContext);
    const [loading, setLoading] = React.useState(false);
    const id = parseInt(params.id);
    const [nome, setNome] = React.useState('');

    return <Container fluid className="pt-3">
        <Card>
            <CabecalhoCadastro 
                id={id} disabled={loading} cabecalho='Ticket de Suporte' titulo="Tickes de Suporte"
                salvar={ e => {
                    e.preventDefault();
                    setLoading(true);
                    socket.emit("Ticket.salvar", { 
                    }, (error, resp) => {
                        setLoading(false);
                        if(error) {
                            toast.error(error);
                        } else {
                            toast.success("Caixa salvo com Sucesso !");
                            navigate(-1);
                        }
                    });
                }}
                />

            <Card.Body>
                <Row>   
                    <Col sm={3}>
                        <Form.Group>
                            <Form.Label>Id</Form.Label>
                            <Form.Control  value={isNaN(id) ? "" : id} readOnly />
                        </Form.Group>
                    </Col>
                    
                    <Col sm={6}>
                        <Form.Group>
                            <Form.Label>Caixa</Form.Label>
                            <Form.Control 
                                disabled={loading}
                                value={nome} 
                                autoFocus maxLength={50}
                                onChange={ e => setNome(e.target.value) }
                                />
                        </Form.Group>
                    </Col>
                </Row>
            </Card.Body>

            <Card.Footer>
                { isNaN(id) ? false : <BotaoApagar 
                    disabled={loading}
                    apagar={ e => {
                        setLoading(true);
                        socket.emit("Caixa.apagar", { id }, (error,resp) => {
                            setLoading(false);
                            if(error) {
                                toast.error(error);
                            } else {
                                toast.success("Caixa removido com Sucesso !");
                                navigate(-1);
                            }
                        });
                    }}
                    /> }
            </Card.Footer>
        </Card>
    </Container>;
}