import React from "react";
import { Routes, Route } from "react-router-dom";
import GponOLTs from "./gponolt/GponOLTs";
import ProjetosFtth from "./projetos_ftth";
import ServidoresSemBackup from "./servidores/ServidoresSemBackup";
import AlertasRedeInfra from "./alertas/AlertasRedeInfra";
import BloqueiosJudiciais from "./dns/BloqueiosJudiciais";
import BloqueioJudicial from "./dns/BloqueioJudicial";
import BloqueioJudicialCsvImport from "./dns/BloqueioJudicialCsvImport";
import TcIRR from "./tcirr/TcIRR";

export default function RedeInfraIndex() {
  return (
    <Routes>
      <Route path={`/gponolt`} element={<GponOLTs />} />
      <Route path={`/projetos_ftth`} element={<ProjetosFtth />} />
      <Route path={`/servidores/sem_backup`} element={<ServidoresSemBackup />} />
      <Route path={`/alertas`} element={<AlertasRedeInfra />} />
      <Route path={`/dns/bloqueio_judicial/importar_csv`} element={<BloqueioJudicialCsvImport />} />
      <Route path={`/dns/bloqueio_judicial/:id`} element={<BloqueioJudicial />} />
      <Route path={`/dns/bloqueio_judicial`} element={<BloqueiosJudiciais />} />
      <Route path={`/tcirr`} element={<TcIRR />} />
    </Routes>
  );
}
