import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import CardModulo from "./CardModulo";
import { useNavigate } from "react-router";
import {
  faUser,
  faRoute,
  faFileContract,
  faPiggyBank,
  faFileInvoice,
  faNetworkWired,
  faDollarSign,
  faAddressCard,
  faCartShopping,
} from "@fortawesome/free-solid-svg-icons";
import ImportacaoContext from "contextos/ImportacaoContext";

export default function ImportacaoModulos() {
  const navigate = useNavigate();
  const { modulosImportados } = React.useContext(ImportacaoContext);

  return (
    <Card>
      <Card.Header className="bg-primary text-light">
        <Card.Title>Selecione e Configure os Módulos de Importação</Card.Title>
      </Card.Header>

      <Card.Body>
        <Row className="justify-content-md-center">
          {modulos.map((m) => {
            return (
              <Col key={m.id}>
                <CardModulo
                  texto={m.texto}
                  icon={m.icon}
                  className={modulosImportados[`${m.id}`.toLowerCase()] ? "bg-success" : "bg-light"}
                  onClick={() => {
                    navigate(`${m.id}`.toLowerCase());
                  }}
                />
              </Col>
            );
          })}
        </Row>

        <Col sm={12} className="mt-2 d-flex justify-content-end">
          <Button
            className="me-2"
            variant="warning"
            onClick={() => {
              navigate("..");
            }}
          >
            Voltar
          </Button>
        </Col>
      </Card.Body>
    </Card>
  );
}

const modulos = [
  {
    id: "Enderecos",
    texto: "Endereços",
    icon: faRoute,
  },
  {
    id: "usuarios",
    texto: "Usuários",
    icon: faRoute,
  },
  {
    id: "Pessoas",
    texto: "Pessoas",
    icon: faUser,
  },
  {
    id: "Filiais",
    texto: "Filiais",
    icon: faFileInvoice,
  },
  {
    id: "ContasBancarias",
    texto: "Contas Bancárias",
    icon: faPiggyBank,
  },
  {
    id: "Planos",
    texto: "Planos",
    icon: faFileInvoice,
  },
  {
    id: "Contratos",
    texto: "Contratos",
    icon: faFileContract,
  },
  // {
  //   id: "Anexos_Contratos",
  //   texto: "Anexos dos Contratos",
  //   icon: faFileContract,
  // },
  {
    id: "observacoes_contratos",
    texto: "Observações dos Contratos",
    icon: faFileContract,
  },
  {
    id: "servicos_contratados",
    texto: "Serviços Contratados",
    icon: faFileContract,
  },
  {
    id: "historico_servicos_contratados",
    texto: "Histórico Serviços Contratados",
    icon: faFileContract,
  },
  {
    id: "grupo_atendimento",
    texto: "Grupos de Atendimentos",
    icon: faAddressCard,
  },
  {
    id: "atendimentos",
    texto: "Atendimentos",
    icon: faAddressCard,
  },
  {
    id: "ordem_servico",
    texto: "Ordens de Serviço",
    icon: faAddressCard,
  },
  {
    id: "historico_atendimentos",
    texto: "Histórico dos Atendimentos",
    icon: faAddressCard,
  },
  {
    id: "servidores",
    texto: "Servidores",
    icon: faNetworkWired,
  },
  {
    id: "gponolt",
    texto: "Gpon Olt",
    icon: faNetworkWired,
  },
  {
    id: "redes",
    texto: "Redes",
    icon: faNetworkWired,
  },
  {
    id: "prefixoIPv4",
    texto: "PrefixosIPv4",
    icon: faNetworkWired,
  },
  {
    id: "acessos",
    texto: "Acessos",
    icon: faNetworkWired,
  },
  {
    id: "historico_acessos",
    texto: "Histórico Acessos",
    icon: faNetworkWired,
  },
  {
    id: "contas_receber",
    texto: "Contas a Receber",
    icon: faDollarSign,
  },
  {
    id: "notas_fiscais",
    texto: "Notas Fiscais",
    icon: faDollarSign,
  },
  {
    id: "notas_fiscais_items",
    texto: "Itens Notas Fiscais",
    icon: faDollarSign,
  },
  {
    id: "notas_fiscais_servicos",
    texto: "Serviços Notas Fiscais",
    icon: faDollarSign,
  },
  {
    id: "categorias",
    texto: "Categorias",
    icon: faCartShopping,
  },
  {
    id: "produtos",
    texto: "Produtos",
    icon: faCartShopping,
  },
  {
    id: "locacoes",
    texto: "Locações",
    icon: faCartShopping,
  },
];
