import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import CardAlarmesDosContratos from "./CardAlarmesDosContratos";
import TituloConext from "../../contextos/TituloContext";

import CardOrdensDeServico from "./CardOrdensDeServico";
import CardAtendimentos from "./CardAtendimentos";
import CardServidoresOffline from "./CardServidoresOffline";
import CardStatusLHISP from "./CardStatusLHISP.js";

export default function Dashboard() {
  const { setTitulo } = React.useContext(TituloConext);

  React.useEffect(() => {
    setTitulo("Dashboard");
  }, [setTitulo]);

  return (
    <Container fluid className="p-3 pb-0">
      <Row className="mb-2 mb-xl-3">
        <div className="col-auto d-none d-sm-block">
          <h3>Bem vindo ao LHISP !</h3>
        </div>

        <div className="col-auto ms-auto text-right mt-n1"></div>
      </Row>

      <Row>
        <Col xs={12} sm={4}>
          <CardOrdensDeServico />
        </Col>

        <Col xs={12} sm={4} className="mt-2 mt-sm-0">
          <CardServidoresOffline />
          <CardStatusLHISP />
        </Col>

        <Col xs={12} sm={4} className="mt-2 mt-sm-0">
          <CardAtendimentos />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <CardAlarmesDosContratos />
        </Col>
      </Row>
    </Container>
  );
}
