import React from "react";
import Listagem from "componentes/Listagem";
import IoContext from "contextos/IoContext";
import TituloContext from "contextos/TituloContext";

import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import { toast } from "react-toastify";
import { Button, Col, Row } from "react-bootstrap";
import ButtonLimparAlertas from "./ButtonLimparAlertas";

const TIMEOUT_INTERVAL = 10;
let timer;

export default function AlertasRedeInfra() {
  const { socket } = React.useContext(IoContext);
  const { setTitulo } = React.useContext(TituloContext);
  const [graficos, setGraficos] = React.useState([]);
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    setTitulo("Central de Alertas");
  }, [setTitulo]);

  React.useEffect(() => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      if (count <= 0) {
        setCount(TIMEOUT_INTERVAL);
        getGraficosComAlerta({ socket, setGraficos });
      } else {
        setCount(count - 1);
      }
    }, 1000);
  }, [socket, count]);

  return (
    <Container fluid className="mt-2">
      <Card>
        <Card.Header className="bg-primary text-light">
          <Row className="w-100">
            <Col sm={10}>
              <Card.Title>Gráfico com Alerta [{count}s]</Card.Title>
            </Col>

            <Col sm={2}>
              <Button
                variant="info"
                onClick={() => {
                  setCount(0);
                }}
              >
                Atualizar
              </Button>
              <ButtonLimparAlertas onClean={() => setCount(0)} />
            </Col>
          </Row>
        </Card.Header>

        <Card.Body>
          <Listagem
            titulos={["id", "Servidor", "Gráfico", "Hora", "Alerta"]}
            itens={graficos}
            renderItem={(titulo, item) => {
              switch (titulo) {
                case "id":
                  return item.id;
                case "Servidor":
                  return (item.Servidor || {}).nome;
                case "Gráfico":
                  return item.descricao;
                case "Hora":
                  return new Date(item.createdAt).toLocaleTimeString();
                case "Alerta":
                  return item.textoAlerta;
                default:
                  return titulo;
              }
            }}
          />
        </Card.Body>
      </Card>
    </Container>
  );
}

function getGraficosComAlerta({ socket, setGraficos }) {
  setGraficos([]);
  socket.emit(
    "ServidorGrafico.findAll",
    {
      attributes: ["id", "createdAt", "descricao", "statusAlerta", "textoAlerta"],
      where: {
        statusAlerta_gt: 1,
      },
      withServidor: {
        attributes: ["id", "nome"],
        required: true,
      },
    },
    (error, resp) => {
      if (error) return toast.error("" + error);
      setGraficos(resp);
    }
  );
}
