import React from "react";
import BotaoVoltar from "../../../componentes/BotaoVoltar";
import CampoCnpj from "../../../componentes/CampoCnpj";
import SelectFiliais from "../../cadastros/administrativo/filiais/SelectFiliais";
import IoContext from "../../../contextos/IoContext";
import { formatarDoc1, formatarCep } from "../../../misc/utils";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import ModalLancarProdutosNFC from "./ModalLancarProdutosNFC";
import { DateTime } from "luxon";

export default function NotasFiscaisCompra() {
  const params = useParams();
  const navigate = useNavigate();
  const id = params.id;
  const { socket } = React.useContext(IoContext);
  const [showXmlBtn, setShowXmlBtn] = React.useState(isNaN(parseInt(id)));
  const [FilialId, setFilialId] = React.useState(0);
  const [fornecedor, setFornecedor] = React.useState("");
  const [cnpj, setCnpj] = React.useState("");
  const [telefone, setTelefone] = React.useState("");
  const [inscricaoEstadual, setInscricaoEstadual] = React.useState("");
  const [numeroSerie, setNumeroSerie] = React.useState("");
  const [dataEmissao, setDataEmissao] = React.useState("");
  const [operacao, setOperacao] = React.useState("");
  const [itens, setItens] = React.useState([]);
  const [valorTotal, setValorTotal] = React.useState(0);
  const [conteudoXml, setConteudoXml] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [isMaterialLancado, setMaterialLancado] = React.useState(false);
  const [showModalProdutos, setShowModalProdutos] = React.useState(false);
  const [contador, setContador] = React.useState(0);

  const [logradouro, setLogradouro] = React.useState(null);
  const [bairro, setBairro] = React.useState(null);
  const [cidade, setCidade] = React.useState(null);
  const [cidadeIbge, setCidadeIbge] = React.useState(null);
  const [uf, setUf] = React.useState(null);
  const [numero, setNumero] = React.useState("");
  const [cep, setCep] = React.useState(null);

  const parseXml = React.useCallback((text) => {
    return Promise.resolve()
      .then(() => {
        const xmlDoc = new DOMParser().parseFromString(text, "text/xml");
        if (xmlDoc.getElementsByTagName("nfeProc").length <= 0) {
          return toast.error("Nota Fiscal Inválida");
        }

        setLogradouro(xmlDoc.getElementsByTagName("xLgr")[0].innerHTML);
        setBairro(xmlDoc.getElementsByTagName("xBairro")[0].innerHTML);
        setCidade(xmlDoc.getElementsByTagName("xMun")[0].innerHTML);
        setCidadeIbge(xmlDoc.getElementsByTagName("cMun")[0].innerHTML);
        setUf(xmlDoc.getElementsByTagName("UF")[0].innerHTML);
        setNumero(formatarCep(xmlDoc.getElementsByTagName("nro")[0].innerHTML || ""));
        setCep(formatarCep(xmlDoc.getElementsByTagName("CEP")[0].innerHTML));

        setFornecedor(xmlDoc.getElementsByTagName("xNome")[0].innerHTML);
        setInscricaoEstadual(xmlDoc.getElementsByTagName("IE")[0].innerHTML);

        let fone = "" + xmlDoc.getElementsByTagName("fone")[0].innerHTML;
        if (!fone) fone = "(88)98888-8888";
        setTelefone(fone);
        setCnpj(formatarDoc1(xmlDoc.getElementsByTagName("CNPJ")[0].innerHTML));

        setDataEmissao(new Date(xmlDoc.getElementsByTagName("dhEmi")[0].innerHTML).toISOString().split("T")[0]);
        setNumeroSerie(xmlDoc.getElementsByTagName("cNF")[0].innerHTML);
        setOperacao(xmlDoc.getElementsByTagName("natOp")[0].innerHTML);

        let itens = [];
        let total = 0;
        const produtos = xmlDoc.getElementsByTagName("det");
        for (let i = 0; i < produtos.length; i++) {
          const produto = produtos[i];

          const quantidade = parseFloat((produto.getElementsByTagName("qCom")[0] || {}).innerHTML);
          const valorUnitario = parseFloat((produto.getElementsByTagName("vUnCom")[0] || {}).innerHTML);
          const valorDesconto = isNaN(parseFloat((produto.getElementsByTagName("vDesc")[0] || {}).innerHTML))
            ? 0
            : parseFloat((produto.getElementsByTagName("vDesc")[0] || {}).innerHTML);
          let valorTotal = isNaN(parseFloat((produto.getElementsByTagName("vProd")[0] || {}).innerHTML))
            ? 0
            : parseFloat((produto.getElementsByTagName("vProd")[0] || {}).innerHTML);

          if (!valorTotal) {
            valorTotal = (valorUnitario - valorDesconto) * quantidade;
          }

          total += valorTotal;
          itens.push({
            id: i,
            codigo: (produto.getElementsByTagName("cProd")[0] || {}).innerHTML,
            nome: (produto.getElementsByTagName("xProd")[0] || {}).innerHTML.replace(/\*/g, ""),
            codigoMercosul: (produto.getElementsByTagName("NCM")[0] || {}).innerHTML,
            codigoCest: (produto.getElementsByTagName("CEST")[0] || {}).innerHTML,
            cfop: (produto.getElementsByTagName("CFOP")[0] || {}).innerHTML,
            unidade: (produto.getElementsByTagName("uCom")[0] || {}).innerHTML,
            quantidade,
            valorUnitario,
            valorDesconto,
            valorTotal,
          });
        }

        setItens(itens);
        setValorTotal(total);
        setShowXmlBtn(false);
        setConteudoXml(text);
      })
      .catch((error) => {
        console.log("Erro ao ler XML:", error);
        toast.error("Erro ao Ler XML:" + error);
      });
  }, []);

  React.useEffect(() => {
    if (isNaN(parseInt(id))) return;

    socket.emit(
      "NotaFiscalCompra.findOne",
      {
        attributes: ["id", "FilialId", "operacao", "valorTotal", "dataEmissao", "isMaterialLancado", "numeroSerie"],
        where: { id },
        withFornecedor: {
          attributes: ["nome", "doc1", "doc2", "telefone1"],
        },
        withItens: {},
      },
      (error, resp) => {
        if (error) {
          toast.error("" + error);
        } else {
          if (!resp) return toast.warning(`Nota Fiscal de Compra Não Encontrada [${id}]`);

          const fornecedor = resp.Fornecedor || {};
          setFilialId(resp.FilialId || 0);
          setOperacao(resp.operacao || "");
          setFornecedor(fornecedor.nome || "");
          setCnpj(fornecedor.doc1 || "");
          setTelefone(fornecedor.telefone1 || "");
          setInscricaoEstadual(fornecedor.doc2 || "");
          setItens(resp.ItensNotasFiscaisCompra);
          setDataEmissao(resp.dataEmissao || "");
          setValorTotal(resp.valorTotal || 0);
          setNumeroSerie(resp.numeroSerie || 0);
          setMaterialLancado(resp.isMaterialLancado);
        }
      }
    );
  }, [id, contador, socket]);

  return (
    <Card className="mt-2">
      <input
        type="file"
        accept="text/xml"
        className="d-none"
        id="arquivo"
        onChange={(evt) => {
          evt.stopPropagation();
          evt.preventDefault();

          var file = evt.target.files[0];
          if (!file) return;

          var reader = new FileReader();
          reader.onload = function (e) {
            parseXml(reader.result);
          };
          reader.readAsText(file);
        }}
      />
      <Card.Header className="bg-primary text-light">
        <Card.Title className="float-start">Nota Fiscal de Compra [{id}]</Card.Title>

        <BotaoVoltar disabled={loading} />
      </Card.Header>

      <Card.Body>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            importarNotaFiscal({
              setLoading,
              socket,
              navigate,
              logradouro,
              bairro,
              cidade,
              cidadeIbge,
              uf,
              cep,
              fornecedor,
              cnpj,
              inscricaoEstadual,
              telefone,
              numero,
              valorTotal,
              dataEmissao,
              FilialId,
              operacao,
              numeroSerie,
              itens,
              conteudoXml,
            });
          }}
        >
          <Row>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>Filial</Form.Label>
                <SelectFiliais
                  disabled={loading || +id > 0}
                  required
                  value={FilialId}
                  onChange={(e) => setFilialId(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={8}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <React.Fragment>
                    <Button
                      disabled={loading}
                      className={`${showXmlBtn ? "" : "d-none"}`}
                      onClick={(e) => {
                        document.getElementById("arquivo").click();
                      }}
                    >
                      Enviar XML
                    </Button>

                    <Button
                      disabled={loading}
                      className={`${isMaterialLancado || isNaN(parseInt(id)) ? "d-none" : ""}`}
                      onClick={(e) => {
                        setShowModalProdutos(true);
                      }}
                    >
                      Lançar Material
                    </Button>

                    <Button
                      disabled={loading}
                      type="submit"
                      variant="success"
                      className={`${isNaN(parseInt(id)) && conteudoXml ? "" : "d-none"}`}
                    >
                      Importar XML
                    </Button>
                  </React.Fragment>
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>Fornecedor</Form.Label>
                <Form.Control value={fornecedor} readOnly />
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group>
                <Form.Label>CNPJ</Form.Label>
                <CampoCnpj value={cnpj} readOnly />
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Inscrição Estadual</Form.Label>
                <Form.Control value={inscricaoEstadual} readOnly />
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group>
                <Form.Label>Telefone</Form.Label>
                <Form.Control value={telefone} readOnly />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Data de Emissão</Form.Label>
                <Form.Control value={DateTime.fromISO(dataEmissao).toLocaleString(DateTime.DATE_SHORT)} readOnly />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Número de Série</Form.Label>
                <Form.Control value={numeroSerie} readOnly />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Natura da Operação</Form.Label>
                <Form.Control value={operacao} readOnly />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Card.Body>

      <Table size="sm">
        <thead>
          <tr>
            <th>Código</th>
            <th>Descrição</th>
            <th>Un</th>
            <th>NCM</th>
            <th>Cest</th>
            <th>Qtd</th>
            <th>Valor Un.</th>
            <th>Desconto</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {(itens || []).map((item) => {
            return (
              <tr key={item.id}>
                <td>{item.codigo}</td>
                <td>{item.nome}</td>
                <td>{item.unidade}</td>
                <td>{item.codigoMercosul}</td>
                <td>{item.codigoCest}</td>
                <td>{item.quantidade}</td>
                <td className="text-right">
                  {(item.valorUnitario || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                </td>
                <td className="text-right">
                  {(item.valorDesconto || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                </td>
                <td className="text-right">
                  {(item.valorTotal || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={8}></td>
            <td colSpan={1} className="text-right">
              {(valorTotal || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
            </td>
          </tr>
        </tfoot>
      </Table>

      <ModalLancarProdutosNFC
        show={showModalProdutos}
        onHide={() => {
          setShowModalProdutos(false);
          setContador(contador + 1);
        }}
        itens={itens}
        id={id}
      />
    </Card>
  );
}

function getEndereco({ socket, logradouro, bairro, cidade, uf, cep, cidadeIbge }) {
  return new Promise((resolve, reject) => {
    socket.emit(
      "Endereco.findOne",
      {
        where: { logradouro, bairro, cidade, uf, cep },
      },
      (error, endereco) => {
        if (error) return reject(error);
        if (endereco) return resolve(endereco);

        socket.emit(
          "Endereco.salvar",
          {
            logradouro,
            bairro,
            cidade,
            uf,
            cep,
            codigoIbge: cidadeIbge,
          },
          (error, endereco) => {
            if (error) return reject(error);
            return resolve(endereco);
          }
        );
      }
    );
  });
}

function getFornecedor({ socket, EnderecoId, nome, doc1, doc2, telefone1, numero }) {
  return new Promise((resolve, reject) => {
    socket.emit(
      "Fornecedor.findOne",
      {
        attributes: ["id"],
        where: { doc1 },
      },
      (error, resp) => {
        if (error) return reject(error);
        if (resp) return resolve(resp);

        socket.emit(
          "Fornecedor.salvar",
          {
            EnderecoId,
            nome,
            doc1,
            doc2,
            telefone1,
            numero,
            tipo: "JURIDICA",
          },
          (error, resp) => {
            if (error) return reject(error);
            if (resp) return resolve(resp);

            return reject("Cadastro de Fornecedor não retornou dados");
          }
        );
      }
    );
  });
}

function importarNotaFiscal({
  setLoading,
  socket,
  navigate,
  logradouro,
  bairro,
  cidade,
  cidadeIbge,
  uf,
  cep,
  fornecedor,
  cnpj,
  inscricaoEstadual,
  telefone,
  numero,
  valorTotal,
  dataEmissao,
  FilialId,
  operacao,
  numeroSerie,
  itens,
  conteudoXml,
}) {
  setLoading(true);

  return Promise.resolve()
    .then(() => {
      return getEndereco({
        socket,
        logradouro,
        bairro,
        cidade,
        cidadeIbge,
        uf,
        cep,
      })
        .then((endereco) => {
          return getFornecedor({
            socket,
            EnderecoId: endereco.id,
            nome: fornecedor,
            doc1: cnpj,
            doc2: inscricaoEstadual,
            telefone1: telefone,
            numero,
          })
            .then((fornecedor) => {
              socket.emit(
                "NotaFiscalCompra.salvar",
                {
                  FornecedorId: fornecedor.id,
                  valorTotal,
                  dataEmissao: new Date(dataEmissao).toISOString(),
                  FilialId,
                  operacao,
                  numeroSerie,
                  itens,
                  conteudoXml,
                },
                (error, resp) => {
                  setLoading(false);
                  if (error) return toast.error("" + error);

                  toast.success("Nota Fiscal de Compra Importada com Sucesso !");
                  navigate(-1);
                }
              );
            })
            .catch((error) => {
              setLoading(false);
              toast.error("Falha ao Obter Fornecedor:" + error);
            });
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Falha ao Obter Endereço:" + error);
        });
    })
    .catch((error) => {
      setLoading(false);
      toast.error("Falha Geral ao Importar XML:" + error);
    });
}
