import React from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import { NOTAFISCAL_NORMAL, NOTAFISCAL_TIPOS } from "datatypes/NotaFiscalTypes";
import { CONTARECEBER_PAGA } from "datatypes/ContaReceberTypes";
import { toast } from "react-toastify";
import { getInicioMes, getFinalMes, formatarDataDbs } from "misc/lhdatas";
import IoContext from "contextos/IoContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function CardListNotasFiscais({ contador, mes, ano, tipo, FilialId, loading, setLoading }) {
  const { socket } = React.useContext(IoContext);
  const [notas, setNotas] = React.useState([]);

  let totalGeral = 0;
  React.useEffect(() => {
    setNotas([]);
    let where = {
      situacao: NOTAFISCAL_NORMAL,
      tipo,
    };

    if (FilialId) {
      where.FilialId = FilialId;
    }

    setLoading(true);
    socket.emit(
      "NotaFiscal.findAll",
      {
        attributes: [
          "id",
          "nrserie",
          "dataEmissao",
          "mes",
          "ano",
          "tipo",
          "valor",
          "totalScm",
          "totalSva",
          "totalLocacao",
        ],
        where,
        order: [["nrserie", "asc"]],
        // limit: 10,
        withContrato: {
          attributes: ["id", "numero_contrato"],
          required: false,
        },
        withPessoa: {
          attributes: ["id", "nome"],
          required: false,
        },
        withContaReceber: {
          attributes: ["id", "nrdoc", "data_pagamento", "valor_pago"],
          where: {
            situacao: CONTARECEBER_PAGA,
            data_pagamento_between: [
              formatarDataDbs(getInicioMes({ ano, mes })),
              formatarDataDbs(getFinalMes({ ano, mes })),
            ],
          },
          required: true,
        },
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          toast.error("CardListNotasFiscais:" + error);
        } else {
          setNotas(resp);
        }
      }
    );
  }, [socket, mes, ano, tipo, contador, FilialId, setLoading]);

  return (
    <Card className="shadow mt-2">
      <Card.Header className="bg-primary text-light">
        Notas Fiscais
        {loading && <FontAwesomeIcon icon={faSpinner} spin={true} className="float-end" />}
      </Card.Header>
      <Table size="sm" borderless>
        <thead>
          <tr>
            <th>Contrato</th>
            <th>Cliente</th>
            <th>Número de Série</th>
            <th>Data de Emissão</th>
            <th>Referência</th>
            <th>Valor</th>
            <th>Tipo</th>
            <th>Fatura</th>
            <th>Data de Pagamento</th>
          </tr>
        </thead>
        <tbody>
          {(notas || []).map((nf) => {
            const contrato = nf.Contrato || {};
            const pessoa = nf.Pessoa || {};
            const fatura = nf.ContaReceber || {};

            totalGeral += +nf.valor;

            const referencia = new Date(nf.ano, nf.mes - 1, 1);
            return (
              <tr key={nf.id}>
                <td>{contrato.numero_contrato || "----"}</td>
                <td>{pessoa.nome || "----"}</td>
                <td>{nf.nrserie}</td>
                <td>{`${nf.dataEmissao}`.split("-").reverse().join("/")}</td>
                <td>
                  {referencia.toLocaleString("default", { month: "long" }).toUpperCase()}/{nf.ano}
                </td>
                <td>
                  {(+nf.valor || 0).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </td>
                <td>{NOTAFISCAL_TIPOS[nf.tipo]}</td>
                <td>{fatura.nrdoc}</td>
                <td>{fatura.data_pagamento ? `${fatura.data_pagamento}`.split("-").reverse().join("/") : ""}</td>
              </tr>
            );
          })}
          <tr>
            <td colSpan={5}>{notas.length} Notas Fiscais</td>
            <td>
              {(+totalGeral || 0).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </td>
            <td colSpan={2}></td>
            <td></td>
          </tr>
        </tbody>
      </Table>
    </Card>
  );
}
