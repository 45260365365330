import React from 'react';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import IoContext from '../../../../contextos/IoContext';

export default function SelectCarteiraCobranca(props){
    const { socket } = React.useContext(IoContext);
    const { id, value, onChange, disabled, pular } = props; 
    const [carteirasDeCobranca, setCarteirasDeCobranca] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(()=>{
        setLoading(true);
        socket.emit("CarteiraCobranca.findAll", {
            where: { deletedAt: null },
            order: [["banco", "ASC"]]
        }, (error, resp) => {
            setLoading(false);
            if(error) {
                toast.error(error);
            } else {
                setCarteirasDeCobranca(resp);
            }
        })
    }, [socket]);

    return <Form.Control as="select" 
        id={id || "CarteiraCobrancaId"} 
        onChange={onChange}
        value={value}
        disabled={loading || disabled}
        {...props}
    >
        <option value=""></option>
        { (carteirasDeCobranca || []).map( c => {
            if(pular) {
                if(Array.isArray(pular)) {
                    if(pular.indexOf(c.id)){
                        return false;
                    }
                } else {
                    if(+pular === +c.id) {
                        return false;
                    }
                }
            }
            return <option 
                key={c.id} 
                value={c.id}
                data-banco={c.banco}
            >{c.descricao}</option>
        })}
    </Form.Control>;
}