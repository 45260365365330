import IoContext from "contextos/IoContext";
import TituloContext from "contextos/TituloContext";
import React from "react";
import { Card, Container, Form, Row, Button } from "react-bootstrap";
import { toast } from "react-toastify";

export default function ConfigArmazenamentoAnexos() {
  const { socket } = React.useContext(IoContext);
  const { setTitulo } = React.useContext(TituloContext);
  const [loading, setLoading] = React.useState(false);
  const [engine, setEngine] = React.useState("local");
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    setTitulo("Configurações de Notas Fiscais");
  }, [setTitulo]);

  React.useEffect(() => {
    setLoading(true);
    socket.emit(
      "Empresa.getConfiguracoes",
      {
        key: "ArmazenamentoAnexos",
      },
      (error, config) => {
        setLoading(false);

        if (error) {
          return toast.error("" + error);
        }

        setEngine(config.engine);
      }
    );
  }, [socket, count]);

  return (
    <Container fluid className="mt-2">
      <Card>
        <Card.Header className="bg-primary text-light">
          <Card.Title>Configurações de Armazenamento do Anexos</Card.Title>
        </Card.Header>

        <Card.Body>
          <Row>
            <Form.Group>
              <Form.Label>Tipo do Armazenamento:</Form.Label>
              <Form.Control as="select" value={engine} onChange={(e) => setEngine(e.target.value)}>
                <option value="local">Local</option>
                <option value="s3">Amazon S3</option>
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Ações</Form.Label>
              <div>
                <Button
                  disabled={loading}
                  onClick={(e) => {
                    e.preventDefault();
                    setLoading(true);
                    socket.emit(
                      "Empresa.AtualizarConfiguracoes",
                      {
                        ArmazenamentoAnexos: {
                          engine,
                        },
                      },
                      (error) => {
                        setLoading(false);
                        if (error) {
                          return toast.error("" + error);
                        }

                        setCount(count + 1);
                        toast.success("Configurações Atualizadas com Sucesso !");
                      }
                    );
                  }}
                >
                  Salvar
                </Button>
              </div>
            </Form.Group>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}
