import React from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import AuthContext from "../../contextos/AuthContext";
import LoadingContext from "../../contextos/LoadingContext";
import LoadingSpinner from "componentes/LoadingSpinner";

export default function LoginUsuarioSenha() {
  const { usuario, setUsuario, empresa, setEmpresa, setTokenValido } = React.useContext(AuthContext);
  const { loading, setLoading } = React.useContext(LoadingContext);
  const [login, setLogin] = React.useState("");
  const [senha, setSenha] = React.useState("");
  const [mensagem, setMensagem] = React.useState("");

  const empresaObtidaPorVhost = empresa && empresa.vhost === window.location.hostname;

  React.useEffect(() => {
    if (usuario) {
      // Tentando Validar o Usuário
      setLoading(true);
      axios
        .post("/api/ValidarToken", {
          EmpresaId: empresa.id,
          token: usuario.token,
        })
        .then((resp) => {
          setLoading(false);
          if (resp.data.status === "ok") {
            setUsuario(resp.data.data);
            setTokenValido(true);
          } else {
            setUsuario(null);
            switch (resp.data.message) {
              case "jwt expired":
                return setMensagem("Seu Login Expirou !");
              default:
                setMensagem(resp.data.message);
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          setUsuario(null);
          console.log("## VALIDAR TOKEN ERROR:", error);
        });
    }
  }, [usuario, setLoading, setTokenValido, setUsuario, empresa.id]);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();

        setSenha("");
        setMensagem("");
        setLoading(true);

        // Limpando Headers do Axios
        delete axios.defaults.headers.common["Authorization"];
        delete axios.defaults.headers.common["authorization"];

        axios
          .post("/api/EfetuarLogin", {
            EmpresaId: empresa.id,
            login,
            senha,
          })
          .then((resp) => {
            setLoading(false);
            if (resp.data.status === "ok") {
              setUsuario(resp.data.data);
              setTokenValido(true);
            } else {
              setMensagem(resp.data.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            console.log("## LOGIN ERROR:", error);
          });
      }}
    >
      <Form.Group>
        <Form.Label>Usuário</Form.Label>
        <Form.Control autoFocus value={login} disabled={loading} required onChange={(e) => setLogin(e.target.value)} />
      </Form.Group>

      <Form.Group>
        <Form.Label>Senha</Form.Label>
        <Form.Control
          type="password"
          value={senha}
          disabled={loading}
          required
          onChange={(e) => setSenha(e.target.value)}
        />
      </Form.Group>

      <Form.Group>
        <div className="text-danger">{mensagem}</div>
      </Form.Group>

      <Form.Group>
        <Form.Label> </Form.Label>
        <div>
          {empresaObtidaPorVhost ? (
            false
          ) : loading ? (
            false
          ) : (
            <Button
              variant="secondary"
              className="me-2 d-none"
              disabled={loading}
              onClick={() => {
                setEmpresa(null);
              }}
            >
              Trocar Empresa
            </Button>
          )}

          <Button variant="primary" type="submit" className="float-end" disabled={loading}>
            {loading ? <LoadingSpinner loading={loading} /> : "Entrar"}
          </Button>
        </div>
      </Form.Group>
    </Form>
  );
}
