import IoContext from "contextos/IoContext"
import React from "react"
import { Card, Form, Row, Col, Button, Container } from "react-bootstrap";
import { toast } from "react-toastify";

const emptyConfig = {
    url: '', login: '', senha: ''
};

export default function EiTV(){
    const {socket} = React.useContext(IoContext);
    const [loading, setLoading] = React.useState(true);
    const [isAtivo, setAtivo] = React.useState(true);
    const [config, setConfig] = React.useState(emptyConfig);

    React.useEffect(() =>{
        setLoading(true);
        setConfig(emptyConfig);

        socket.emit("Integracao.findOne",{
            where: {
                modulo: "EiTV",
            },
        }, (error, resp) =>{
            setLoading(false);
            if (error){
                return toast.error("" + error);
            }
            const config = JSON.parse(resp.configuracoes || {})
            setConfig(config);
        })
    },[socket]);

    return <Container fluid>
            <Card>
                <Card.Header className='bg-primary'>
                    <Card.Title className="text-light">EiTV - Configuração da API</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col sm={4}>
                            <Form.Group>
                                <Form.Label>Instância</Form.Label>
                                <Form.Control disabled={loading} value={config?.url || ""}
                                    onChange={e => setConfig({...config, url: e.target.value })}/>
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Login</Form.Label>
                                <Form.Control disabled={loading} value={config?.login || ""}
                                    onChange={e => setConfig({...config, login: e.target.value })}/>
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Senha</Form.Label>
                                <Form.Control disabled={loading} value={config?.senha || ""} type="password"
                                    onChange={e => setConfig({...config, senha: e.target.value })}/>
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Ativo</Form.Label>
                                <Form.Check checked={isAtivo} onChange={() => {setAtivo(!isAtivo)}} disabled={loading}/>
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Ações</Form.Label>
                                <div>
                                    <Button disabled={loading} onClick={e => {
                                        e.preventDefault();
                                        setLoading(true);
                                        socket.emit("Integracao.Salvar", {
                                            modulo: 'EiTV',
                                            configuracoes: config,
                                            isAtivo,
                                        }, (error) =>{
                                            setLoading(false);
                                            if (error){
                                                return toast.error("" + error);
                                            }

                                            toast.success("Configurações Atualizadas com Sucesso!");
                                        })
                                    }}>Salvar</Button>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Container>
}