import React from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import { NOTAFISCAL_NORMAL } from "../../../datatypes/NotaFiscalTypes";
import { toast } from "react-toastify";
import IoContext from "../../../contextos/IoContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function CardNotasFiscaisEmitidas({
  contador,
  mes,
  ano,
  tipo,
  FilialId,
  loading1,
  loading2,
  setLoading1,
  setLoading2,
}) {
  const { socket } = React.useContext(IoContext);
  const [quantidadeEmitidas, setQuantidadeEmitidas] = React.useState(0);
  const [totalEmitidas, setTotalEmitidas] = React.useState(0);

  React.useEffect(() => {
    setQuantidadeEmitidas(0);
    setTotalEmitidas(0);

    let where = {
      mes: +mes,
      ano,
      situacao: NOTAFISCAL_NORMAL,
      tipo,
    };
    if (FilialId) where.FilialId = FilialId;

    setLoading1(true);
    socket.emit(
      "NotaFiscal.count",
      {
        where,
      },
      (error, resp) => {
        setLoading1(false);
        if (error) {
          toast.error("CardNotasFiscaisEmitidas:NotaFiscal.count:" + error);
        } else {
          setQuantidadeEmitidas(resp);
        }
      }
    );

    setLoading2(true);
    socket.emit(
      "NotaFiscal.sum",
      {
        campo: "valor",
        where,
      },
      (error, resp) => {
        setLoading2(false);
        if (error) {
          toast.error("CardNotasFiscaisEmitidas\nNotaFiscal.sum:" + error);
        } else {
          setTotalEmitidas(resp);
        }
      }
    );
  }, [socket, mes, ano, tipo, contador, FilialId, setLoading1, setLoading2]);

  return (
    <Card className="shadow">
      <Card.Header className="bg-primary text-light">
        Notas Fiscais - Emitidas no Mês{" "}
        {(loading1 || loading2) && <FontAwesomeIcon icon={faSpinner} spin={true} className="float-end" />}
      </Card.Header>
      <Table size="sm" borderless>
        <tbody>
          <tr>
            <th>Quantidade:</th>
            <td>{quantidadeEmitidas}</td>
          </tr>
          <tr>
            <th>Valor:</th>
            <td>
              {(totalEmitidas || 0).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
  );
}
