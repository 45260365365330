import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import { toast } from 'react-toastify';
import BotaoApagar from '../../../../componentes/BotaoApagar';
import CabecalhoCadastro from '../../../../componentes/CabecalhoCadastro';
import IoContext from '../../../../contextos/IoContext';
import { useParams, useNavigate } from 'react-router-dom';

export default function Caixa(){
    const params = useParams();
    const navigate = useNavigate();
    const { socket } = React.useContext(IoContext);
    const [loading, setLoading] = React.useState(false);
    const id = parseInt(params.id);
    const [nome, setNome] = React.useState('');
    const [aceitaDinheiro, setAceitaDinheiro] = React.useState(true);
    const [aceitaCartao, setAceitaCartao] = React.useState(true);
    const [aceitaCheque, setAceitaCheque] = React.useState(true);
    const [aceitaOutros, setAceitaOutros] = React.useState(true);

    React.useEffect(()=>{
        if(isNaN(id)) return;

        setLoading(true);
        socket.emit("Caixa.findOne", { 
            where: { id } 
        }, (error, resp) => {
            setLoading(false);
            if(error) {
                toast.error(error);
            } else {
                setNome(resp.nome);
                setAceitaDinheiro(resp.aceitaDinheiro);
                setAceitaCartao(resp.aceitaCartao);
                setAceitaCheque(resp.aceitaCheque);
                setAceitaOutros(resp.aceitaOutros);
            }
        });
    }, [id, socket, setLoading, setNome, setAceitaCartao, setAceitaDinheiro, setAceitaCheque]);
    return <Container fluid className="pt-3">
        <Card>
            <CabecalhoCadastro 
                id={id} disabled={loading} titulo='Caixa'
                salvar={ e => {
                    e.preventDefault();
                    setLoading(true);
                    socket.emit("Caixa.salvar", { 
                        id, nome, aceitaDinheiro, aceitaCartao, 
                        aceitaCheque, aceitaOutros,
                    }, (error, resp) => {
                        setLoading(false);
                        if(error) {
                            toast.error(error);
                        } else {
                            toast.success("Caixa salvo com Sucesso !");
                            navigate(-1);
                        }
                    });
                }}
                />

            <Card.Body>
                <Row>   
                    <Col sm={3}>
                        <Form.Group>
                            <Form.Label>Id</Form.Label>
                            <Form.Control  value={isNaN(id) ? "" : id} readOnly />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col sm={6}>
                        <Form.Group>
                            <Form.Label>Caixa</Form.Label>
                            <Form.Control 
                                disabled={loading}
                                value={nome} required
                                autoFocus maxLength={50}
                                onChange={ e => setNome(e.target.value) }
                                />
                        </Form.Group>
                    </Col>

                    <Col sm={6}>
                        <Form.Group>
                            <Form.Label>Espécies Aceitas</Form.Label>
                            <div>
                            <Form.Check inline id="dinheiro" checked={aceitaDinheiro}  label="Dinheiro" onChange={ () => setAceitaDinheiro(!aceitaDinheiro) }  />
                            <Form.Check inline id="cartao" checked={aceitaCartao}  label="Cartão" onChange={ () => setAceitaCartao(!aceitaCartao) } />
                            <Form.Check inline id="cheque" checked={aceitaCheque}  label="Cheque" onChange={ () => setAceitaCheque(!aceitaCheque) } />
                            <Form.Check inline id="outros" checked={aceitaOutros}  label="Outros" onChange={ () => setAceitaOutros(!aceitaOutros) } />
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
            </Card.Body>

            <Card.Footer>
                { isNaN(id) ? false : <BotaoApagar 
                    disabled={loading}
                    apagar={ e => {
                        setLoading(true);
                        socket.emit("Caixa.apagar", { id }, (error,resp) => {
                            setLoading(false);
                            if(error) {
                                toast.error(error);
                            } else {
                                toast.success("Caixa removido com Sucesso !");
                                navigate(-1);
                            }
                        });
                    }}
                    /> }
            </Card.Footer>
        </Card>
    </Container>;
}