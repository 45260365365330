import React from 'react';
import FormularioListagem from 'componentes/FormularioListagem';
import { toast } from 'react-toastify';
import IoContext from 'contextos/IoContext';
import { useNavigate } from 'react-router';

export default function Filiais ({ 
    texto, setTexto, pagina, setPagina,
}){
    const navigate = useNavigate();
    const { socket } = React.useContext(IoContext);
    const [loading, setLoading] = React.useState(false);
    const [Filiais, setFiliais] = React.useState([]);
    const [total, setTotal] = React.useState(0);

    React.useEffect(()=>{
        let where = {};
        if(texto) where.nome_like = `%${texto}%`;
        
        setLoading(true);
        socket.emit("Filial.findAndCountAll", {
            attributes: ['id','nome','cnpj','telefone1','telefone2'],
            where, limit: 10, offset: 10 * (pagina-1),
        }, (error,resp)  => {
            setLoading(false);
            if(error) {
                toast.error(error);
            } else {
                setFiliais(resp.rows);
                setTotal(resp.count);
            }
        })
    }, [pagina, texto, socket]);
    
    return <FormularioListagem
        titulo={`Filiais`}
        itens={Filiais} qtdItens={total} loading={loading}
        pagina={pagina} setTexto={setTexto} texto={texto} setPagina={setPagina}
        titulos={["Id", "Nome", "Cnpj", "Telefones"]}
        onClick={(e)=>{
            navigate(`${e.id}`);
        }}
        
        renderItem={ (titulo, item) => {
            switch(titulo) {
                case 'Id': return item.id;
                case 'Nome': return item.nome;
                case 'Cnpj': return item.cnpj || "----";
                case 'Telefones': return `${item.telefone1} ${item.telefone2}`;
                default:
                    return titulo;
            }
        }}
    />
}