import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Paginacao from 'componentes/Paginacao';

export default function TableErros({
    campos, dados
}) {
    const [pagina, setPagina] = React.useState(1);
    const total = (dados || []).length;

    const item = dados[pagina - 1];

    return <Card className="mt-2 me-3">
        <Card.Header className="text-danger">Exibindo Item com Erro: {pagina} </Card.Header>

        <Card.Body>
            <Row>
                <Col sm={6}><pre className="b-all">{JSON.stringify((item || {}).dados, null, "\t")}</pre></Col>
                <Col sm={6}><pre className="b-all">{JSON.stringify((item || {}).error, null, "\t")}</pre></Col>
            </Row>
        </Card.Body>

        <Card.Footer>
            <Paginacao pagina={pagina} setPagina={setPagina} total={total} quantidaePorPagina={1} />
        </Card.Footer>
    </Card>;
}