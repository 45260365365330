import React from 'react';
import Form from 'react-bootstrap/Form';
import LoadingContext from '../../../contextos/LoadingContext';
import { toast } from 'react-toastify';
import IoContext from '../../../contextos/IoContext';

export default function SelectRedes(props){
    const { socket } = React.useContext(IoContext);
    const { loading } = React.useContext(LoadingContext);
    const { id, value, onChange, disabled, pular } = props; 
    const [redees, setRedes] = React.useState([]);
    const [loading2, setLoading2] = React.useState(true);

    React.useEffect(()=>{
        setLoading2(true);
        socket.emit("Rede.findAll", {
            order: [["nome", "ASC"]]
        }, (error, resp) => {
            setLoading2(false);
            if(error) {
                toast.error(error);
            } else {
                setRedes(resp);
            }
        });
    }, [socket]);

    return <Form.Control as="select" 
        id={id || "RedeId"} 
        onChange={onChange}
        value={value}
        disabled={loading || loading2 || disabled}
        {...props}
    >
        <option value=""></option>
        { (redees || []).map( c => {
            if(pular) {
                if(Array.isArray(pular)) {
                    if(pular.indexOf(c.id)){
                        return false;
                    }
                } else {
                    if(+pular === +c.id) {
                        return false;
                    }
                }
            }
            return <option key={c.id} value={c.id}>{c.nome}</option>
        })}
    </Form.Control>;
}