import React from 'react';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import IoContext from '../../../../contextos/IoContext';

export default function SelectItensCentroCusto(props){
    const { socket } = React.useContext(IoContext);
    const { id, value, onChange, disabled, pular, CentroCustoId } = props; 
    const [loading, setLoading] = React.useState(false);
    const [itensCentrosDeCusto, setItensCentrosDeCusto] = React.useState([]);
    
    React.useEffect(()=>{
        setLoading(true);
        setItensCentrosDeCusto([]);
        if(!CentroCustoId) return; 
        socket.emit("ItemCentroCusto.findAll", {
            where: { CentroCustoId },
            order: [['nome']]
        }, (error, resp) => {
            setLoading(false);
            if(error) {
                toast.error(error);
            } else {
                setItensCentrosDeCusto(resp);
            }
        });
    }, [socket, CentroCustoId]);

    let props2 = {...props};
    delete(props2.CentroCustoId);

    return <Form.Control as="select" 
        id={id || "CentroCustoId"} 
        onChange={onChange} value={value}
        disabled={loading || disabled}
        {...props2}
    >
        <option value=""></option>
        { (itensCentrosDeCusto || []).map( filial => {
            if(pular) {
                if(Array.isArray(pular)) {
                    if(pular.indexOf(filial.id)){
                        return false;
                    }
                } else {
                    if(+pular === +filial.id) {
                        return false;
                    }
                }
            }
            return <option key={filial.id} value={filial.id}>{filial.nome}</option>
        })}
    </Form.Control>;
}