import CampoMoeda from "componentes/CampoMoeda";
import IoContext from "contextos/IoContext";
import TituloContext from "contextos/TituloContext";
import React from "react";
import { Card, Container, Form, Row, Button } from "react-bootstrap";
import { toast } from "react-toastify";

export default function ConfiguracoesNotasFiscais() {
  const { socket } = React.useContext(IoContext);
  const { setTitulo } = React.useContext(TituloContext);
  const [loading, setLoading] = React.useState(false);
  const [emitirNotasParaServicosMarcados, setEmitirNotasParaServicosMarcados] = React.useState(false);
  const [emitirNotasPorBoletosPagos, setEmitirNotasPorBoletosPagos] = React.useState(false);
  const [emitirNotasDeBoletosComBaixaManual, setEmitirNotasDeBoletosComBaixaManual] = React.useState(false);
  const [valorMinimo, setValorMinimo] = React.useState(0);
  const [valorMaximo, setValorMaximo] = React.useState(0);
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    setTitulo("Configurações de Notas Fiscais");
  }, [setTitulo]);

  React.useEffect(() => {
    setLoading(true);
    socket.emit(
      "Empresa.getConfiguracoes",
      {
        key: "emissaoNotasFiscais",
      },
      (error, config) => {
        setLoading(false);

        if (error) {
          return toast.error("" + error);
        }

        setEmitirNotasParaServicosMarcados(config.emitirNotasParaServicosMarcados);
        setEmitirNotasPorBoletosPagos(config.emitirNotasPorBoletosPagos);
        setEmitirNotasDeBoletosComBaixaManual(config.emitirNotasDeBoletosComBaixaManual);
        setValorMinimo(config.valorMinimo);
        setValorMaximo(config.valorMaximo);
      }
    );
  }, [socket, count]);

  return (
    <Container fluid className="mt-2">
      <Card>
        <Card.Header className="bg-primary text-light">
          <Card.Title>Configurações de Emissão das Notas Fiscais</Card.Title>
        </Card.Header>

        <Card.Body>
          <Row>
            <Form.Group>
              <Form.Label>Valor Mínimo para Considerar o Mês Anterior Fechado</Form.Label>
              <CampoMoeda disabled={loading} value={valorMinimo} onChange={(e) => setValorMinimo(e.target.value)} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Valor Máximo para o Mês Atual</Form.Label>
              <CampoMoeda disabled={loading} value={valorMaximo} onChange={(e) => setValorMaximo(e.target.value)} />
            </Form.Group>

            <Form.Group>
              <Form.Label></Form.Label>
              <Form.Check
                label="Gerar Notas Automaticamente com Base nos Serviços Contratados Marcados ?"
                checked={emitirNotasParaServicosMarcados}
                onChange={() => {
                  setEmitirNotasParaServicosMarcados(!emitirNotasParaServicosMarcados);
                }}
                disabled={loading}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label></Form.Label>
              <Form.Check
                label="Gerar Notas Automaticamente com Base nos Boletos Pagos ?"
                checked={emitirNotasPorBoletosPagos}
                onChange={() => {
                  setEmitirNotasPorBoletosPagos(!emitirNotasPorBoletosPagos);
                }}
                disabled={loading}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label></Form.Label>
              <Form.Check
                label="Gerar Notas de Boletos com Baixa Manual ?"
                checked={emitirNotasDeBoletosComBaixaManual}
                onChange={() => {
                  setEmitirNotasDeBoletosComBaixaManual(!emitirNotasDeBoletosComBaixaManual);
                }}
                disabled={loading}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Ações</Form.Label>
              <div>
                <Button
                  disabled={loading}
                  onClick={(e) => {
                    e.preventDefault();
                    setLoading(true);
                    socket.emit(
                      "Empresa.AtualizarConfiguracoes",
                      {
                        emissaoNotasFiscais: {
                          emitirNotasPorBoletosPagos,
                          emitirNotasParaServicosMarcados,
                          emitirNotasDeBoletosComBaixaManual,
                          valorMinimo,
                          valorMaximo,
                        },
                      },
                      (error) => {
                        setLoading(false);
                        if (error) {
                          return toast.error("" + error);
                        }

                        setCount(count + 1);
                        toast.success("Configurações Atualizadas com Sucesso !");
                      }
                    );
                  }}
                >
                  Salvar
                </Button>
              </div>
            </Form.Group>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}
