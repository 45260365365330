import React from "react";
import FormularioListagem from "../../../../componentes/FormularioListagem";
import IoContext from "../../../../contextos/IoContext";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import SelectUf from "./SelectUf";
import SelectCidade from "./SelectCidade";
import { useNavigate } from "react-router";

export default function Enderecos({ pagina, setPagina, texto, setTexto, uf, setUf, cidade, setCidade }) {
  const navigate = useNavigate();
  const { socket } = React.useContext(IoContext);
  const [loading, setloading] = React.useState(false);
  const [total, setTotal] = React.useState(0);
  const [enderecos, setEnderecos] = React.useState([]);

  React.useEffect(() => {
    let where = {};
    if (texto) where.logradouro_like = `%${texto}%`;
    if (uf) where.uf = uf;
    if (cidade) where.cidade = cidade;

    setloading(true);
    socket.emit(
      "Endereco.findAndCountAll",
      {
        where,
        limit: 10,
        offset: 10 * (pagina - 1),
      },
      (error, resp) => {
        setloading(false);
        if (error) {
          return toast.error("" + error);
        } else {
          setEnderecos(resp.rows);
          setTotal(resp.count);
        }
      }
    );
  }, [socket, texto, pagina, uf, cidade]);

  return (
    <FormularioListagem
      titulo={`Endereços`}
      itens={enderecos || []}
      qtdItens={total}
      pagina={pagina}
      loading={loading}
      tamanhos={[1, 1, 2, 2, 2, 2]}
      setTexto={setTexto}
      setPagina={setPagina}
      titulos={["Id", "Uf", "Cidade", "Bairro", "Logradouro", "Cep"]}
      onClick={(e) => {
        navigate(`${e.id}`);
      }}
      renderItem={(titulo, item) => {
        switch (titulo) {
          case "Id":
            return item.id;
          case "Uf":
            return item.uf;
          case "Cidade":
            return item.cidade;
          case "Bairro":
            return item.bairro;
          case "Logradouro":
            return item.logradouro;
          case "Cep":
            return item.cep;
          default:
            return titulo;
        }
      }}
      renderFiltros={() => {
        return (
          <React.Fragment>
            <Form.Group>
              <Form.Label>UF</Form.Label>
              <SelectUf
                value={uf}
                onChange={(e) => {
                  setUf(e.target.value);
                  setCidade("");
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Cidade</Form.Label>
              <SelectCidade
                value={cidade}
                uf={uf}
                onChange={(e) => {
                  setCidade(e.target.value);
                }}
              />
            </Form.Group>
          </React.Fragment>
        );
      }}
    />
  );
}
