import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import axios from "axios";

export default function FonteDadosDb({ setFonteDados, fonteDados }) {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [dbtype, setDbtype] = React.useState(fonteDados?.dbtype || "");
  const [dbhost, setDbhost] = React.useState(fonteDados?.dbhost || "");
  const [dbport, setDbport] = React.useState(fonteDados?.dbport || 0);
  const [dbname, setDbname] = React.useState(fonteDados?.dbname || "");
  const [dbuser, setDbuser] = React.useState(fonteDados?.dbuser || "");
  const [dbpass, setDbpass] = React.useState(fonteDados?.dbpass || "");

  return (
    <Card>
      <Card.Header className="bg-primary text-light">
        <Card.Title>Detalhes da Fonte de Dados [Banco de Dados]</Card.Title>
      </Card.Header>

      <Card.Body>
        <Form
          onSubmit={(e) => {
            e.preventDefault();

            let fonteDados = {
              tipo: "database",
              dbtype,
              dbhost,
              dbport,
              dbname,
              dbuser,
              dbpass,
            };

            setLoading(true);
            axios
              .post("/importacoes/testar_conexao_db", fonteDados)
              .then((resp) => {
                setLoading(false);
                toast.success("Conexão com o Banco de Dados Bem Sucedida !");
                setFonteDados(fonteDados);
                navigate("../modulos");
              })
              .catch((error) => {
                setLoading(false);
                toast.error("" + error);
              });
          }}
        >
          <Row>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>Tipo de Banco de Dados</Form.Label>
                <Form.Control
                  as="select"
                  value={dbtype}
                  required
                  disabled={loading}
                  onChange={(e) => {
                    setDbtype(e.target.value);
                    switch (e.target.value) {
                      case "mysql":
                        setDbport(3306);
                        setDbuser("root");
                        setDbpass("root");
                        setDbhost("mysql");
                        setDbname("importacao");
                        break;
                      case "postgres":
                        setDbport(5432);
                        setDbuser("postgres");
                        setDbhost("postgres");
                        setDbpass("postgres");
                        setDbname("postgres");
                        break;
                      default:
                        setDbport(1);
                    }
                  }}
                >
                  <option></option>
                  <option value="mysql">MySQL</option>
                  <option value="postgres">Postgres</option>
                </Form.Control>
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Endereço do Servidor</Form.Label>
                <Form.Control
                  value={dbhost}
                  onChange={(e) => setDbhost(e.target.value)}
                  maxLength={100}
                  disabled={loading}
                  required
                />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Porta do Servidor</Form.Label>
                <Form.Control
                  type="number"
                  value={dbport}
                  onChange={(e) => setDbport(+e.target.value)}
                  min={1}
                  max={65535}
                  disabled={loading}
                />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Nome do Banco de Dados</Form.Label>
                <Form.Control
                  value={dbname}
                  onChange={(e) => setDbname(e.target.value)}
                  maxLength={100}
                  required
                  disabled={loading}
                />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Usuário</Form.Label>
                <Form.Control
                  value={dbuser}
                  onChange={(e) => setDbuser(e.target.value)}
                  maxLength={100}
                  required
                  disabled={loading}
                />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Senha</Form.Label>
                <Form.Control
                  type="password"
                  value={dbpass}
                  onChange={(e) => setDbpass(e.target.value)}
                  maxLength={100}
                  required
                  disabled={loading}
                />
              </Form.Group>
            </Col>
          </Row>

          <Col sm={12} className="mt-2 d-flex justify-content-end">
            <Button
              className="me-2"
              variant="warning"
              disabled={loading}
              onClick={() => {
                setFonteDados(null);
                navigate(-1);
              }}
            >
              Voltar
            </Button>

            <Button type="submit" disabled={loading}>
              Testar Conexão
            </Button>
          </Col>
        </Form>
      </Card.Body>
    </Card>
  );
}
