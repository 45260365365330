import React from "react";
import FormularioListagem from "../../../componentes/FormularioListagem";
import SelectMes from "../../../componentes/SelectMes";
import { toast } from "react-toastify";
import IoContext from "../../../contextos/IoContext";
import { useNavigate } from "react-router";
import { Form } from "react-bootstrap";
import { DateTime } from "luxon";

export default function NotasFiscais() {
  const { socket } = React.useContext(IoContext);
  const [mes, setMes] = React.useState(new Date().getMonth() + 1);
  const [ano, setAno] = React.useState(new Date().getFullYear());
  const [pagina, setPagina] = React.useState(1);
  const [texto, setTexto] = React.useState("");
  const [remessas, setRemessas] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const navigate = useNavigate();

  React.useEffect(() => {
    let where = { mes, ano };
    socket.emit(
      "NotaFiscal.findAndCountAll",
      {
        attributes: ["id", "nrserie", "dataEmissao", "valor"],
        where,
        limit: 10,
        offset: 10 * (pagina - 1),
        withContrato: {
          attributes: ["contrato"],
        },
        withPessoa: {
          attributes: ["nome"],
        },
        order: [["id"]],
      },
      (error, resp) => {
        if (error) {
          toast.error(error);
        } else {
          setRemessas(resp.rows);
          setTotal(resp.count);
        }
      }
    );
  }, [socket, texto, pagina, mes, ano]);

  return (
    <React.Fragment>
      <FormularioListagem
        titulo={`Notas Fiscais`}
        itens={remessas}
        qtdItens={total}
        setTexto={setTexto}
        pagina={pagina}
        setPagina={setPagina}
        titulos={["Contrato", "Cliente", "Número", "Emissão", "Valor", "Fatura"]}
        onClick={(e) => {
          navigate(`${e.id}`);
        }}
        renderItem={(titulo, item) => {
          switch (titulo) {
            case "Id":
              return item.id;
            case "Contrato":
              return (item.Contrato || {}).contrato;
            case "Cliente":
              return (item.Pessoa || {}).nome;
            case "Número":
              return item.nrserie;
            case "Emissão":
              return DateTime.fromISO(item.dataEmissao).toLocaleString(DateTime.DATE_SHORT);
            case "Situação":
              return item.situacao;
            case "Valor":
              return (
                <div>
                  {`${item.valor}`}
                  <br />
                  [SCM: 0,00] [SVA: 0,00] [Loc.: 0,00]
                </div>
              );
            case "Fatura":
              return item.fatura || "";
            default:
              return titulo;
          }
        }}
        renderFiltros={() => {
          return (
            <React.Fragment>
              <Form.Group>
                <Form.Label>Ano</Form.Label>
                <Form.Control type="number" step={1} value={ano} onChange={(e) => setAno(+e.target.value)} />
              </Form.Group>

              <Form.Group>
                <Form.Label>Mês</Form.Label>
                <SelectMes
                  value={mes}
                  onChange={(e) => {
                    setMes(+e.target.value);
                  }}
                />
              </Form.Group>
            </React.Fragment>
          );
        }}
      />
    </React.Fragment>
  );
}
