import React from 'react';
import Form from 'react-bootstrap/Form';

export default function CampoCep(props) {
    const { onChange } = props;
    return <Form.Group>
        <Form.Label>{props.label || "Cep"}</Form.Label>
        <Form.Control
            {...props}
            maxLength={10}
            onChange={ e => {
                const soNumeros = e.target.value.replace(/[^\d]/g, '')
                if(!soNumeros) onChange(soNumeros);

                let cep = '';
                // 62.800-000
                cep += soNumeros.slice(0,2);
                if(soNumeros.length > 2)
                    cep += '.' + soNumeros.slice(2,5);
        
                if(soNumeros.length > 5)
                    cep += '-' + soNumeros.slice(5,8);
                onChange(cep);
            }}
        />
    </Form.Group>
}