import React from "react";
import Container from "react-bootstrap/Container";
import TituloContext from "contextos/TituloContext";
import CardCampSoftConfigApi from "./CardCampSoftConfigApi";
import IntegracaoHistoricoEventos from "../IntegracaoHistoricoEventos";

export default function CampSoft() {
  const { setTitulo } = React.useContext(TituloContext);

  React.useEffect(() => {
    setTitulo("CampSoft");
  });

  return (
    <Container fluid className="mt-2">
      <CardCampSoftConfigApi />
      <IntegracaoHistoricoEventos modulo="Campsoft" />
    </Container>
  );
}
