import React from 'react';
import CampoProcurar from '../../../componentes/CampoProcurar';
import IoContext from '../../../contextos/IoContext';

export default function CampoProcurarVendedor({ titulo='Selecionar Vendedor', value, onSelect, disabled }){
    const { socket } = React.useContext(IoContext);
    const [vendedores, setVendedores] = React.useState([]);
    const loading = null;

    const getVendedores = React.useCallback((texto)=>{
        socket.emit("Pessoa.findAll", {
            where: {
                nome_like: `${texto}%`,
                is_vendedor: true
            },
            limit: 25
        }, (error, resp) => {
            if(error) {
                console.log("Vendedor.findAll Error:", error);
            } else {
                setVendedores(resp);
            }
        })
    }, [socket]);

    return <CampoProcurar 
        titulo={'Selecionar Vendedor' || titulo}
        disabled={disabled || loading}
        value={value}
        onSelect={onSelect}
        titulos={['Id','Vendedor']}
        itens={vendedores}
        renderItem={ (titulo, item, idx) => {
            switch(titulo) {
                case 'Id': return item.id;
                case 'Vendedor': return `${item.nome}`;
                default:
                    return titulo
            }
        }}
        onSearch={ txt => {
            getVendedores(txt)
        }}
        />
}