import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import IoContext from "contextos/IoContext";
import { toast } from "react-toastify";
import SelectFiliais from "paginas/cadastros/administrativo/filiais/SelectFiliais";
import CampoData from "componentes/CampoData";
import { getFinalMes, formatarDataDbs, getInicioMes } from "misc/lhdatas";
import { isNumber } from "lodash";

export default function RelRetencaoClientes() {
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(false);
  const [filialId, setFilialId] = React.useState("");
  const [periodoIni, setPeriodoIni] = React.useState(formatarDataDbs(getInicioMes()));
  const [periodoFim, setPeriodoFim] = React.useState(formatarDataDbs(getFinalMes()));
  const [dados, setDados] = React.useState([]);
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    setDados([]);
    setLoading(true);

    let dateCancelamentoMap = new Map();
    let tempAtivacoes = [];
    let tempCancelados = [];
    let tempOutros = [];
    let mesesNoPeriodo = Math.ceil(calcularDiferencaDeDias(new Date(periodoIni), new Date(periodoFim)) / 30);
    let countCancelamentoPorMes = new Array(mesesNoPeriodo).fill(0);

    let reqData = {
      order: [["createdAt", "desc"]],
      attributes: ["id", "ContratoId", "createdAt", "situacao"],
      withContrato: {
        where: {},
      },
      where: {
        createdAt_between: [periodoIni, periodoFim],
      },
    };

    if (filialId) {
      reqData.withContrato.where.FilialId = parseInt(filialId);
    }

    socket.emit("HistoricoContrato.findAll", reqData, (error, resp) => {
      if (error) {
        toast.error("" + error);
        setLoading(false);
      }

      (resp || []).forEach((hist) => {
        if (tempCancelados.find((id) => +id === +hist.ContratoId) && hist.situacao === 1) {
          dateCancelamentoMap.get(hist.ContratoId).ativoAt = hist.createdAt;
          return;
        } else if (
          tempAtivacoes.find((id) => +id === +hist.ContratoId) ||
          tempCancelados.find((id) => +id === +hist.ContratoId) ||
          tempOutros.find((id) => +id === +hist.ContratoId)
        ) {
          return;
        }
        switch (hist.situacao) {
          case 0:
            tempOutros.push(hist.ContratoId);
            break;
          case 1:
            tempAtivacoes.push(hist.ContratoId);
            break;
          case 2:
            tempCancelados.push(hist.ContratoId);
            dateCancelamentoMap.set(hist.ContratoId, { canceladoAt: hist.createdAt });
            break;
          case 3:
            tempOutros.push(hist.ContratoId);
            break;
          case 4:
            tempOutros.push(hist.ContratoId);
            break;
          default:
            break;
        }
      });

      dateCancelamentoMap.forEach((time, key) => {
        if (!time.ativoAt) {
          dateCancelamentoMap.delete(key);
          return;
        }

        let tempoEmDias = calcularDiferencaDeDias(new Date(time.ativoAt), new Date(time.canceladoAt));

        let tempoEmMeses = Math.ceil(tempoEmDias / 30);

        if (isNumber(countCancelamentoPorMes[tempoEmMeses - 1])) {
          countCancelamentoPorMes[tempoEmMeses - 1]++;
        }
      });

      let indicadores = [];
      let total = tempAtivacoes.length + dateCancelamentoMap.size;
      let retencao = calcularRetencaoPercentual(total, dateCancelamentoMap.size).toFixed(2);

      indicadores.push({
        nome: "Total de Ativações no Período",
        valor: total,
      });
      indicadores.push({
        nome: "Total de Ativações que Permaneceram do Período",
        valor: tempAtivacoes.length,
      });
      indicadores.push({
        nome: "Total de Cancelamentos no Período",
        valor: dateCancelamentoMap.size,
      });
      indicadores.push({
        nome: "Taxa de Retenção",
        valor: retencao + " %",
      });

      countCancelamentoPorMes.forEach((val, idx) => {
        indicadores.push({
          nome: `Número de Cancelamentos com até ${idx + 1} ${idx === 0 ? "mês" : "meses"}`,
          valor: val,
        });
      });

      setDados(indicadores);
      setLoading(false);
    });
  }, [socket, periodoIni, periodoFim, filialId, count]);

  return (
    <Container className="m-2" fluid>
      <Card>
        <Card.Header className="d-print-none bg-primary text-light">
          <h3>Relatório de Retenção de Clientes</h3>
        </Card.Header>
        <Card.Body>
          <Row className="mb-4 d-print-none">
            <Col md={4} sm={12}>
              <Form.Group>
                <Form.Label>Filial</Form.Label>
                <SelectFiliais disabled={loading} value={filialId} onChange={(e) => setFilialId(e.target.value)} />
              </Form.Group>
            </Col>
            <Col md={4} sm={12}>
              <Form.Group>
                <Form.Label>Período</Form.Label>
                <InputGroup>
                  <CampoData
                    disabled={loading}
                    className="me-2"
                    value={periodoIni}
                    onChange={(e) => setPeriodoIni(e.target.value)}
                  />
                  <CampoData disabled={loading} value={periodoFim} onChange={(e) => setPeriodoFim(e.target.value)} />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={4} sm={12}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <InputGroup>
                  <Button title="Listar" className="me-2" disabled={loading} onClick={() => window.print()}>
                    Imprimir
                  </Button>
                  <Button title="Listar" className="me-2" disabled={loading} onClick={() => setCount(count + 1)}>
                    Atualizar
                  </Button>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Row className={dados.length === 0 ? "d-none" : "m-1"}>
            <Table>
              <thead>
                <tr>
                  <th>Indicador</th>
                  <th>Valor</th>
                </tr>
              </thead>
              <tbody>
                {(dados || []).map((item) => {
                  return (
                    <tr key={item.nome}>
                      <td>{item.nome}</td>
                      <td className="text-justify">{item.valor}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}

function calcularDiferencaDeDias(startDate, endDate) {
  const diaEmMilissegundos = 86400000;
  return (endDate.getTime() - startDate.getTime()) / diaEmMilissegundos;
}

const calcularRetencaoPercentual = (total, cancelados) => {
  let retencao;

  if (total === 0 && cancelados === 0) {
    return 0;
  } else if (cancelados === 0) {
    retencao = total;
  } else {
    retencao = total - Math.abs(cancelados);
  }

  return (retencao / total) * 100;
};
