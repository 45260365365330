import React from 'react';
import AuthProvider from '../contextos/AuthProvider';
import LoadingProvider from '../contextos/LoadingProvider';
import TituloProvider from '../contextos/TituloProvider';
import MenuProvider from '../contextos/MenuProvider';

export default function AppProvider({ children }) {
    return (
        <AuthProvider>
            <TituloProvider titulo='LHISP'>
                <LoadingProvider>
                    <MenuProvider>
                        { children }
                    </MenuProvider>
                </LoadingProvider>
            </TituloProvider>
        </AuthProvider>
    );
}