import React from 'react';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import IoContext from '../../contextos/IoContext';

export default function SelectAlmoxarifdos(props) {
    const { socket } = React.useContext(IoContext);
    const { id, value, onChange, disabled, pular } = props;
    const { FilialId, ...domProps } = props;
    const [almoxarifados, setAlmoxarifados] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        setAlmoxarifados([]);

        socket.emit("Almoxarifado.findAll", {
            where: {
                FilialId
            },
            attributes: ['id', 'nome'],
            order: [["nome", "ASC"]]
        }, (error, resp) => {
            setLoading(false);
            if (error) {
                return toast.error(''+error);
            }

            setAlmoxarifados(resp);
        });
    }, [socket, FilialId]);

    return <Form.Control as="select"
        id={id || "AlmoxarifadoId"}
        onChange={onChange} value={value}
        disabled={loading || disabled}
        {...domProps}
    >
        <option value=""></option>
        {(almoxarifados || []).map(alm => {
            if (pular) {
                if (Array.isArray(pular)) {
                    if (pular.indexOf(alm.id)) {
                        return false;
                    }
                } else {
                    if (+pular === +alm.id) {
                        return false;
                    }
                }
            }
            return <option key={alm.id} value={alm.id}>{alm.nome}</option>
        })}
    </Form.Control>;
}
