import React from "react";
import Form from "react-bootstrap/Form";

export default function CardInputFonteDados({ fonteDados, txt, setTxt, setArquivo }) {
  if (!fonteDados) return <div>Fonte de Dados Inválida !!!</div>;

  switch (fonteDados.tipo) {
    case "csv":
      return (
        <Form.Group>
          <Form.Label>Arquivo CSV:</Form.Label>
          <Form.Control
            type="file"
            accept=".csv"
            required
            onChange={(e) => {
              if (!e.target.files[0]) {
                console.log("No File Selected");
                return;
              }

              var reader = new FileReader();
              var fileName = e.target.files[0].name;

              reader.onload = function () {
                setArquivo({
                  name: fileName,
                  data: reader.result,
                });
              };
              reader.readAsText(e.target.files[0]);
            }}
          />
        </Form.Group>
      );
    case "database":
      return (
        <Form.Group>
          <Form.Label>Consulta SQL:</Form.Label>
          <Form.Control
            as="textarea"
            style={{ height: "100px" }}
            autoFocus
            value={txt}
            onChange={(e) => setTxt(e.target.value)}
          />
        </Form.Group>
      );
    case "api":
      return <div>API</div>;
    default:
      return <div>Fonte de Dados Não Implementada: {fonteDados.tipo}</div>;
  }
}
