import React from "react";
import IoContext from "contextos/IoContext";
import { Card, Col, Container, Form, Row, Button, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";

export default function Owen() {
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(true);
  const [token, setToken] = React.useState("");
  const [isAtivo, setAtivo] = React.useState(false);

  React.useEffect(() => {
    socket.emit(
      "Integracao.findOne",
      {
        where: {
          modulo: "Owen",
        },
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          return toast.error(error);
        }
        const retorno = JSON.parse(resp.configuracoes || {});
        setToken(retorno.token);
        setAtivo(resp.isAtivo);
      }
    );
  }, [socket]);

  return (
    <Container fluid>
      <Card>
        <Card.Header className="bg-primary">
          <Card.Title>Owen - Configuração da API</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col sm={9}>
              <Form.Group>
                <Form.Label>Token:</Form.Label>
                <InputGroup>
                  <InputGroup.Checkbox
                    type="checkbox"
                    disabled={loading}
                    title={isAtivo ? "Ativo" : "Desativado"}
                    checked={isAtivo}
                    onChange={(e) => setAtivo(!isAtivo)}
                  />
                  <Form.Control disabled={loading} value={token} onChange={(e) => setToken(e.target.value)} />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    disabled={loading}
                    onClick={(e) => {
                      e.preventDefault();
                      setLoading(true);
                      socket.emit(
                        "Integracao.Salvar",
                        {
                          modulo: "Owen",
                          configuracoes: {
                            token,
                          },
                          isAtivo,
                        },
                        (error) => {
                          setLoading(false);
                          if (error) {
                            return toast.error(error);
                          }
                          toast.success("Configurações salvas com sucesso");
                        }
                      );
                    }}
                  >
                    Salvar
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}
