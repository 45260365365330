import React from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import FormularioListagem from 'componentes/FormularioListagem';
import IoContext from 'contextos/IoContext';

export default function GponOLTs(){
    const navigate = useNavigate();
    const { socket } = React.useContext(IoContext);
    const [pagina, setPagina] = React.useState(1);
    const [texto, setTexto] = React.useState("");
    const [total, setTotal] = React.useState(0);
    const [olts, setOlts] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(()=>{
        let where = {};
        if(texto) where.descricao_like = `%${texto}%`;

        // setLoading(true);
        socket.emit("GponOlt.findAndCountAll", {
            where, limit: 10, offset: 10 * (pagina-1)
        }, (error, resp) => {
            setLoading(false);
            if(error) {
                toast.error(error);
            } else {
                setTotal(resp.count);
                setOlts(resp.rows);
            }
        });
    }, [socket, texto, pagina]);

    return <FormularioListagem
        titulo={`OLTs GPON`}
        itens={olts || []} qtdItens={total} pagina={pagina} loading={loading}
        setTexto={setTexto} setPagina={setPagina}
        titulos={["Id", "Descrição", "Fabricante"]}
        onClick={(e)=>{
            navigate(`${e.id}`);
        }}
        
        renderItem={ (titulo, item) => {
            switch(titulo) {
                case 'Id': return item.id;
                case 'Descrição': return item.descricao;
                case 'Fabricante': return item.fabricante;
                default: return titulo;
            }
        }}
        />
}