import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';

export default function BotaoEditar(props) {
    const { onClick, texto } = props;
    return <Button
        variant='info'
        className={props.className}
        title="Editar"
        style={{}}
        onClick={onClick}>
            <FontAwesomeIcon icon={faPencilAlt} className={`${texto ? 'me-2' : ''}`} />
        {texto}
    </Button>
}