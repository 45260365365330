import React from "react";
import Container from "react-bootstrap/Container";
import FormularioListagem from "componentes/FormularioListagem";
import IoContext from "contextos/IoContext";
import { toast } from "react-toastify";
import { DateTime } from "luxon";

export default function TarefasListagem({ texto, setTexto, pagina, setPagina }) {
  const { socket } = React.useContext(IoContext);
  const [total, setTotal] = React.useState(0);
  const [tarefas, setTarefas] = React.useState([]);

  React.useEffect(() => {
    let where = {};
    if (texto) where.nome_like = `%${texto}%`;

    socket.emit(
      "TarefaSistema.findAndCountAll",
      {
        where,
        limit: 10,
        offset: 10 * (pagina - 1),
        logging: true,
      },
      (error, resp) => {
        console.log("## ERROR:", error);
        if (error) {
          console.debug("ERROR:", error);
          toast.error("" + error);
        } else {
          setTarefas(resp.rows);
          setTotal(resp.count);
        }
      }
    );
  }, [texto, pagina, socket]);

  return (
    <Container fluid className="mt-3">
      <FormularioListagem
        titulo={`Tarefas do Sistema`}
        texto={texto}
        setTexto={setTexto}
        pagina={pagina}
        setPagina={setPagina}
        titulos={["id", "Criada Em", "Módulo", "Módulo Id", "Situação", "Retorno"]}
        tamanhos={[]}
        itens={tarefas}
        qtdItens={total}
        renderItem={(titulo, item) => {
          switch (titulo) {
            case "Id":
              return item.id;
            case "Criada Em":
              return DateTime.fromISO(item.createdAt).toLocaleString(DateTime.DATE_SHORT);
            case "Módulo":
              return item.modulo;
            case "Módulo Id":
              return item.id;
            case "Situação":
              return item.situacao;
            case "Retorno":
              return item.retorno;
            default:
              return titulo;
          }
        }}
        onClick={(item) => {
          console.log("## ONCLICK :", item);
        }}
      />
    </Container>
  );
}
