import React from 'react';
import Form from 'react-bootstrap/Form';
import IoContext from '../../../../contextos/IoContext';

export default function SelectMoeda(props){
    const { socket } = React.useContext(IoContext);
    const { id, value, onChange, disabled, pular } = props; 
    const [moedas, setMoedas] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(()=>{
        setLoading(true);
        socket.emit("Moeda.findAll", {
            order: [["NAME", "ASC"]]
        }, (error, resp) => {
            setLoading(false);
            if(error) {
                console.log("Moeda.findAll Error:", error);
            } else {
                setMoedas(resp);
            }
        })
    }, [socket]);

    return <Form.Control as="select" 
        id={id || "MoedaId"} 
        onChange={onChange}
        value={value}
        disabled={loading || disabled}
        {...props}
    >
        <option value=""></option>
        { (moedas || []).map( c => {
            if(pular) {
                if(Array.isArray(pular)) {
                    if(pular.indexOf(c.id)){
                        return false;
                    }
                } else {
                    if(+pular === +c.id) {
                        return false;
                    }
                }
            }
            return <option key={c.id} value={c.id}>{c.nome}</option>
        })}
    </Form.Control>;
}