import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import FormProcurarEstoque from "../FormProcurarEstoque";

export default function ModalAdicionarItemOrdemSeparacao({
  show,
  onHide,
  AlmoxarifadoId,
  adicionarItem,
}) {
  const [temControlePatrimonial, setTemControlePatrimonial] =
    React.useState(null);
  const [item, setItem] = React.useState(null);

  return (
    <Modal
      show={show}
      onHide={() => {
        setItem(null);
        setTemControlePatrimonial(false);
        onHide();
      }}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Adicionar Item</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {!item?.EstoqueId && (
          <FormProcurarEstoque
            AlmoxarifadoId={AlmoxarifadoId}
            onSelect={(estoque) => {
              setItem({
                ProdutoId: estoque.ProdutoId,
                EstoqueId: estoque.id,
                quantidade_solicitada: 1,
                Estoque: {
                  id: estoque.id,
                  Produto: estoque.Produto,
                  quantidade: estoque.quantidade,
                },
              });
              setTemControlePatrimonial(
                estoque.Produto?.temControlePatrimonial
              );
            }}
          />
        )}

        {item?.EstoqueId && !temControlePatrimonial && (
          <Row>
            <Col sm={10}>
              <Form.Group>
                <Form.Label>Quantidade</Form.Label>
                <Form.Control
                  type="number"
                  min={1}
                  max={item?.Estoque?.quantidade}
                  step={1}
                  disabled={temControlePatrimonial}
                  value={item?.quantidade_solicitada || 1}
                  onChange={(e) => {
                    setItem({
                      ...item,
                      quantidade_solicitada: e.target.value,
                    });
                  }}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    variant="primary"
                    onClick={() => {
                      adicionarItem(item);
                      setItem(null);
                      setTemControlePatrimonial(false);
                      onHide();
                    }}
                  >
                    Adicionar
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        )}
      </Modal.Body>
    </Modal>
  );
}
