import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive, faBook } from '@fortawesome/free-solid-svg-icons';

export default function ModalSelecionarItemPag1({ setPagina, setTipo  }) {
    return <Form.Group>
        <Form.Label>Tipo</Form.Label>
        <React.Fragment>
            {cardTipo({
                titulo: "Produto",
                icon: faArchive,
                onClick: ()=>{
                    setTipo('PRODUTO');
                    setPagina(2);
                }
            })}

            {cardTipo({
                titulo: "Categoria",
                icon: faBook,
                onClick: ()=>{
                    setTipo('CATEGORIA');
                    setPagina(2);
                }
            })}
        </React.Fragment>
    </Form.Group>
}


const cardTipo = function({ titulo, icon, onClick }) {
    return <Card className="mb-2 p-2" onClick={onClick} style={{
        cursor: 'pointer'
    }}>
        <Row>
            <Col className='align-self-center text-center text-md-left'>
                <h4>{titulo}</h4>
            </Col>
            <Col className='align-self-center text-center text-md-right'>
                <FontAwesomeIcon icon={icon} size="6x" />
            </Col>
        </Row>
    </Card>
}
