import FormularioListagem from "componentes/FormularioListagem";
import IoContext from "contextos/IoContext";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function TemplatesEmail({ pagina, setPagina, texto, setTexto }) {
  const navigate = useNavigate();
  const { socket } = React.useContext(IoContext);
  const [templates, setTemplates] = React.useState([]);
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    let where = {};
    if (texto) where.cupom_like = `%${texto}%`;

    socket.emit(
      "TemplateEmail.findAndCountAll",
      {
        where,
        limit: 10,
        offset: 10 * (pagina - 1),
      },
      (error, resp) => {
        if (error) {
          toast.error("" + error);
        } else {
          setTemplates(resp.rows);
          setTotal(resp.count);
        }
      }
    );
  }, [socket, pagina, texto]);

  return (
    <FormularioListagem
      titulo={`Templates de Email`}
      itens={templates}
      qtdItens={total}
      pagina={pagina}
      setTexto={setTexto}
      setPagina={setPagina}
      titulos={["Id", "Nome"]}
      tamanhos={[{ sm: 1 }, { sm: 2 }, { sm: 3 }]}
      onClick={(e) => {
        navigate(`${e.id}`);
      }}
      renderItem={(titulo, item) => {
        switch (titulo) {
          case "Id":
            return item.id;
          case "Nome":
            return item.nome;
          default:
            return titulo;
        }
      }}
    />
  );
}
