import React from "react";
import IoContext from "contextos/IoContext";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CardTarefasServidor from "./CardTarefasServidor";
import BotaoVoltar from "componentes/BotaoVoltar";

export default function AdmServidorLhisp(props) {
  const params = useParams();
  const id = parseInt(params.id);
  const { socket } = React.useContext(IoContext);
  const [servidor, setServidor] = React.useState(null);

  React.useEffect(() => {
    setServidor(null);
    socket.emit(
      "Servidor.findOne",
      {
        where: { id },
      },
      (error, servidor) => {
        if (error) return toast.error("ERROR:" + error);

        setServidor(servidor);
      }
    );
  }, [socket, id]);

  return (
    <Container fluid className="mt-2">
      <Card className="mb-2">
        <Card.Header className="bg-primary text-light">
          <Card.Title>
            [{id}] Servidor: {servidor ? servidor.nome : ""}
            <BotaoVoltar texto="Voltar" />
          </Card.Title>
        </Card.Header>
      </Card>

      <CardTarefasServidor ServidorId={id} />
    </Container>
  );
}
