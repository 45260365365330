import React from "react";
import { toast } from "react-toastify";
import IoContext from "../../../contextos/IoContext";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import BotaoVoltar from "componentes/BotaoVoltar";
import SelectAlmoxarifados from "../SelectAlmoxarifados";
import {
  OrdemSeparacaoSituacoes,
  OrdemSeparacaoTipos,
  ORDEM_SEPARACAO_AGUARDANDO_ENTREGA,
  ORDEM_SEPARACAO_TIPO_PARA_TECNICO,
  ORDEM_SEPARACAO_TIPO_TRANSF_ALMOXARIFADO,
} from "datatypes/OrdemSeparacaoTypes";
import Listagem from "componentes/Listagem";
import SelectFuncionario from "paginas/cadastros/administrativo/functionaios/SelectFuncionario";
import ModalAdicionarItemOrdemSeparacao from "./ModalAdicionarItemOrdemSeparacao";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import ModalConfirmar from "componentes/ModalConfirmar";
import TituloContext from "contextos/TituloContext";

export default function OrdemSeparacao() {
  const navigate = useNavigate();
  const id = Number.parseInt(useParams()?.id);
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(false);
  const [ordemSeparacao, setOrdemSeparacao] = React.useState(null);
  const [alterado, setAlterado] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [showAddItens, setShowAddItens] = React.useState(false);
  const [showRemoverItem, setShowRemoverItem] = React.useState(false);
  const [showConfirmarMaterial, setShowConfirmarMaterial] = React.useState(false);
  const [itemRemover, setItemRemover] = React.useState(null);
  const { setTitulo } = React.useContext(TituloContext);
  const [idxItemRemover, setIdxItemRemover] = React.useState(null);

  React.useEffect(() => {
    setTitulo(`Ordem de Separação [${id}]`);
  }, [setTitulo, id]);

  React.useEffect(() => {
    if (isNaN(id)) return;

    setLoading(true);
    socket.emit(
      "OrdemSeparacao.findOne",
      {
        where: { id },
        withItensOrdemSeparacao: {
          withEstoque: {},
          withPatrimonio: {},
        },
      },
      (error, resp) => {
        setLoading(false);

        if (error) return toast.error("" + error);

        setOrdemSeparacao(resp);
        setAlterado(false);
      }
    );
  }, [socket, count, id]);

  const disabled = !isNaN(id) || loading || !!ordemSeparacao?.itens?.length;

  return (
    <Container fluid className="pt-3">
      <Card>
        <Card.Header className="bg-primary text-light">
          <Card.Title className="text-light d-block float-start">
            {isNaN(id) ? "Nova" : id} - Ordem de Separação [{OrdemSeparacaoSituacoes[ordemSeparacao?.situacao]}]
          </Card.Title>
          <BotaoVoltar />
        </Card.Header>

        <Card.Body>
          <Row>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Almoxarifado</Form.Label>
                <SelectAlmoxarifados
                  value={ordemSeparacao?.AlmoxarifadoId}
                  onChange={(e) => {
                    setOrdemSeparacao({
                      ...ordemSeparacao,
                      AlmoxarifadoId: e.target.value,
                    });
                    setAlterado(true);
                  }}
                  required
                  disabled={disabled || (!ordemSeparacao?.ItensOrdemSeparacao?.length && !isNaN(id))}
                />
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group>
                <Form.Label>Tipo</Form.Label>
                <Form.Control
                  as="select"
                  value={ordemSeparacao?.tipo || ""}
                  onChange={(e) => {
                    setOrdemSeparacao({
                      ...ordemSeparacao,
                      tipo: e.target.value,
                    });
                    setAlterado(true);
                  }}
                  required
                  disabled={disabled}
                >
                  <option></option>
                  {Object.keys(OrdemSeparacaoTipos).map((key) => {
                    return (
                      <option value={key} key={key}>
                        {OrdemSeparacaoTipos[key]}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>

            {+ordemSeparacao?.tipo === ORDEM_SEPARACAO_TIPO_PARA_TECNICO && (
              <Col sm={2}>
                <Form.Group>
                  <Form.Label>Técnico Responsável</Form.Label>
                  <SelectFuncionario
                    value={ordemSeparacao?.ResponsavelId || ""}
                    disabled={disabled}
                    required
                    onChange={(e) => {
                      setOrdemSeparacao({
                        ...ordemSeparacao,
                        ResponsavelId: e.target.value,
                      });
                      setAlterado(true);
                    }}
                  />
                </Form.Group>
              </Col>
            )}

            {+ordemSeparacao?.tipo === ORDEM_SEPARACAO_TIPO_TRANSF_ALMOXARIFADO && (
              <Col sm={2}>
                <Form.Group>
                  <Form.Label>Almoxarifado de Destino</Form.Label>
                  <SelectAlmoxarifados
                    value={ordemSeparacao?.AlmoxarifadoDestinoId}
                    pular={ordemSeparacao?.AlmoxarifadoId}
                    onChange={(e) => {
                      setOrdemSeparacao({
                        ...ordemSeparacao,
                        AlmoxarifadoDestinoId: e.target.value,
                      });
                      setAlterado(true);
                    }}
                    disabled={disabled}
                  />
                </Form.Group>
              </Col>
            )}

            <Col sm={3}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  {!alterado && ordemSeparacao?.situacao === ORDEM_SEPARACAO_AGUARDANDO_ENTREGA && (
                    <Button
                      className="btn-success me-2"
                      disabled={loading}
                      onClick={(e) => {
                        setShowConfirmarMaterial(true);
                      }}
                    >
                      Confirmar Entrega
                    </Button>
                  )}

                  {alterado && ordemSeparacao?.ItensOrdemSeparacao?.length && (
                    <React.Fragment>
                      <Button
                        className="btn-warning me-2"
                        disabled={loading}
                        onClick={() => {
                          setCount(count + 1);
                        }}
                      >
                        Cancelar
                      </Button>

                      <Button
                        className="btn-success me-2"
                        disabled={loading}
                        onClick={(e) => {
                          setLoading(true);
                          socket.emit("OrdemSeparacao.salvar", ordemSeparacao, (error, resp) => {
                            setLoading(false);
                            setShowConfirmarMaterial(false);

                            if (error) return toast.error("" + error);
                            toast.info("Saída do Material Confirmada com Sucesso !");
                          });
                        }}
                      >
                        Salvar
                      </Button>
                    </React.Fragment>
                  )}

                  {(!ordemSeparacao?.situacao || // Novos Itens
                    ordemSeparacao?.situacao === ORDEM_SEPARACAO_AGUARDANDO_ENTREGA) && (
                    <Button
                      className="btn-primary me-2"
                      disabled={loading || !ordemSeparacao?.AlmoxarifadoId}
                      onClick={(e) => setShowAddItens(true)}
                    >
                      Adicionar Item
                    </Button>
                  )}
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Body>
          <Listagem
            titulos={["Ações", "Descrição", "Número de Série", "Qtd. Solicitada", "Qtd. Entregue"]}
            itens={ordemSeparacao?.ItensOrdemSeparacao || []}
            tamanhos={[{ sm: 1 }, { sm: 5 }, { sm: 2 }, { sm: 2 }, { sm: 2 }]}
            renderItem={(titulo, item, idx) => {
              switch (titulo) {
                case "Ações":
                  return (
                    <React.Fragment>
                      <Button
                        size="sm"
                        variant="danger"
                        disabled={loading}
                        title="Remover Item"
                        onClick={(e) => {
                          setItemRemover(item);
                          setIdxItemRemover(idx);
                          setShowRemoverItem(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </React.Fragment>
                  );
                case "Descrição":
                  return item.Estoque?.Produto?.nome;
                case "Número de Série":
                  return item.Patrimonio?.numeroSerie;
                case "Qtd. Solicitada":
                  return item.quantidade_solicitada;
                case "Qtd. Entregue":
                  return (
                    <Form.Control
                      value={item.quantidade_despachada || item.quantidade_solicitada}
                      type="number"
                      min={0}
                      step={1}
                      disabled={loading}
                      onChange={(e) => {
                        let n = { ...ordemSeparacao };
                        n.ItensOrdemSeparacao[idx].quantidade_despachada = e.target.value;
                        setOrdemSeparacao(n);
                        setAlterado(true);
                      }}
                    />
                  );
                default:
                  return titulo;
              }
            }}
          />
        </Card.Body>
      </Card>

      <ModalAdicionarItemOrdemSeparacao
        show={showAddItens}
        onHide={setShowAddItens}
        AlmoxarifadoId={ordemSeparacao?.AlmoxarifadoId}
        adicionarItem={(item) => {
          let n = { ...ordemSeparacao };
          n.ItensOrdemSeparacao = n.ItensOrdemSeparacao || [];

          let idx = n.ItensOrdemSeparacao?.findIndex((i) => i.EstoqueId === item.EstoqueId);
          if (idx >= 0) {
            n.ItensOrdemSeparacao[idx].quantidade_solicitada =
              +n.ItensOrdemSeparacao[idx].quantidade_solicitada + +item.quantidade_solicitada;
          } else {
            n.ItensOrdemSeparacao.push(item);
          }
          setOrdemSeparacao(n);
          setAlterado(true);
        }}
      />

      <ModalConfirmar
        size="md"
        show={showConfirmarMaterial}
        onHide={setShowConfirmarMaterial}
        titulo="Confirmar Entrega do Material ?"
        texto={`Dejesa confirmar a entraga do material ?`}
        callback={(e) => {
          setLoading(true);
          socket.emit("OrdemSeparacao.ConfirmarMaterial", { id }, (error) => {
            setLoading(false);
            setShowConfirmarMaterial(false);

            if (error) {
              console.log("OrdemSeparacao.ConfirmarMaterial:", error);
              return toast.error("" + error);
            }
            toast.info("Saída do Material Confirmada com Sucesso !");
            navigate(-1);
          });
        }}
      />

      <ModalConfirmar
        size="md"
        show={showRemoverItem}
        onHide={setShowRemoverItem}
        titulo="Remover Item ?"
        texto={`${itemRemover?.Estoque?.Produto?.nome} - Quantidade: ${itemRemover?.quantidade_solicitada}`}
        callback={(e) => {
          const n = { ...ordemSeparacao };
          n.ItensOrdemSeparacao.splice(idxItemRemover, 1);
          setAlterado(true);
          setOrdemSeparacao(n);
          setShowRemoverItem(false);
          setItemRemover(null);
          setIdxItemRemover(null);
        }}
      />
    </Container>
  );
}
