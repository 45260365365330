import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ImportarContasReceber from './importar_contas_receber/ImportarContasReceber';
import NotasFiscais from './notafiscal/NotasFiscais';
import GerenciaFinanceira from './gerencia';
import ReajusteServicosContratados from './reajuste_servicos_contratados/ReajusteServicosContratados';

export default function FinanceiroIndex(){
    return <Routes>
        <Route path={`/importar_contas_recebrer`} element={<ImportarContasReceber />} />
        <Route path={`/notafiscal`} element={<NotasFiscais />} />
        <Route path={`/reajuste_servicos`} element={<ReajusteServicosContratados />} />
        <Route path={`*`} element={<GerenciaFinanceira />} />
    </Routes>
}