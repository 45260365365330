import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import IoContext from "../../contextos/IoContext";
import { SituacoesAtendimento } from "../../datatypes/AtendiemntosTypes";

export default function ModalDetalhesAtendimento(props) {
  const { id } = props;
  const { socket } = React.useContext(IoContext);
  const [atendimento, setAtendimento] = React.useState(null);

  React.useEffect(() => {
    setAtendimento(null);

    socket.emit(
      "Atendimento.findOne",
      {
        attributes: ["id"],
        where: { id },
        withGrupoAtendimento: {
          attributes: ["nome"],
        },
        withOrdensServico: {},
        withObservacoes: {
          withUsuario: {
            attributes: ["nome"],
          },
        },
      },
      (error, resp) => {
        if (error) {
          return toast.error(error);
        }

        setAtendimento(resp);
      }
    );
  }, [socket, id]);

  return (
    <Modal show={props.show} onHide={props.onHide} size="lg">
      <Modal.Header closeButton>
        <h4>Detalhes do Atendimento [{props.id}]</h4>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col sm={6}>
            <Form.Group>
              <Form.Label>Aberto Por</Form.Label>
              <Form.Control value={"----"} readOnly />
            </Form.Group>
          </Col>

          <Col sm={6}>
            <Form.Group>
              <Form.Label>Aberto Em</Form.Label>
              <Form.Control value={"----"} readOnly />
            </Form.Group>
          </Col>

          <Col sm={6}>
            <Form.Group>
              <Form.Label>Fechado Por</Form.Label>
              <Form.Control value={"----"} readOnly />
            </Form.Group>
          </Col>

          <Col sm={6}>
            <Form.Group>
              <Form.Label>Fechado Em</Form.Label>
              <Form.Control value={"----"} readOnly />
            </Form.Group>
          </Col>

          <Col sm={6}>
            <Form.Group>
              <Form.Label>Situação</Form.Label>
              <Form.Control value={atendimento ? SituacoesAtendimento[atendimento.situacao] : "----"} readOnly />
            </Form.Group>
          </Col>

          <Col sm={6}>
            <Form.Group>
              <Form.Label>Grupo</Form.Label>
              <Form.Control value={atendimento ? SituacoesAtendimento[atendimento.situacao] : "----"} readOnly />
            </Form.Group>
          </Col>
        </Row>

        <Table size="sm">
          <caption style={{ captionSide: "top" }}>Observações</caption>
          <tbody>
            <tr>
              <th>Ações</th>
              <th>Usuário</th>
              <th>Técnico</th>
              <th>Data/Hora</th>
              <th>Observação</th>
            </tr>
            {((atendimento || {}).Observacoes || []).map((obs) => {
              return (
                <tr key={obs.id}>
                  <td></td>
                  <td>{(obs.Usuario || {}).nome}</td>
                  <td></td>
                  <td>{new Date(obs.createdAt).toLocaleString()}</td>
                  <td>{obs.descricao}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
}
