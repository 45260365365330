import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import IoContext from "../../../../contextos/IoContext";
import { toast } from "react-toastify";
import { getFinalMes, getInicioMes } from "misc/lhdatas";
import { Container, Table } from "react-bootstrap";
import SelectFuncionario from "paginas/cadastros/administrativo/functionaios/SelectFuncionario";
import { OS_CONCLUIDA, TiposOrdemServico } from "datatypes/OrdemServicoTypes";
import CampoMoeda from "componentes/CampoMoeda";
import { useNavigate } from "react-router-dom";

let store_valores_por_tipo_os = Object.keys(TiposOrdemServico).reduce((acc, tipo) => {
  acc[tipo] = 0;
  return acc;
}, {});

try {
  store_valores_por_tipo_os = JSON.parse(localStorage.getItem("store_valores_por_tipo_os"));
} catch (error) {}

export default function RelComissaoTecnicoPorOs() {
  const navigate = useNavigate();
  const { socket } = React.useContext(IoContext);
  const [contador, setContador] = React.useState(0);
  const [dini, setDini] = React.useState(getInicioMes().toISOString().split("T")[0]);
  const [dend, setDend] = React.useState(getFinalMes().toISOString().split("T")[0]);
  const [loading, setLoading] = React.useState(false);
  const [ordensServico, setOrdensServico] = React.useState([]);
  const [TecnicoId, setTecnicoId] = React.useState("");
  const [valorPorTipoOs, setValorPorTipoOs] = React.useState(store_valores_por_tipo_os);

  React.useEffect(() => {
    if (valorPorTipoOs) {
      localStorage.setItem("store_valores_por_tipo_os", JSON.stringify(valorPorTipoOs));
    }
  }, [JSON.stringify(valorPorTipoOs)]);

  React.useEffect(() => {
    setOrdensServico([]);
    setLoading(true);
    socket.emit(
      "OrdemServico.findAll",
      {
        attributes: ["id", "tipo", "data_execucao"],
        where: {
          data_execucao_between: [dini, dend],
          situacao: OS_CONCLUIDA,
        },
        withServicoContratado: {
          attributes: ["ContratoId"],
          withContrato: {
            attributes: ["contrato"],
            withPessoa: {
              attributes: ["nome"],
            },
          },
        },
        withTecnico: {
          attributes: ["nome"],
          where: {
            id: TecnicoId,
          },
        },
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          return toast.error(error);
        }

        setOrdensServico(resp);
      }
    );
  }, [socket, dini, dend, TecnicoId, contador]);

  const porTipo = Object.keys(TiposOrdemServico).reduce((acc, tipo) => {
    acc[tipo] = 0;
    return acc;
  }, {});
  const porContrato = {};
  let total = 0;

  return (
    <Container fluid>
      <Card className="shadow">
        <Card.Header className="bg-primary text-light">
          <h3 className="float-start">Relatório Comissão dos Técnicos por Ordem de Serviço</h3>
        </Card.Header>

        <Card.Body className="d-print-none">
          <Row>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>Período</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="date"
                    value={dini}
                    placeholder="Inicial"
                    disabled={loading}
                    onChange={(e) => setDini(e.target.value)}
                  />
                  <Form.Control
                    type="date"
                    value={dend}
                    placeholder="Final"
                    disabled={loading}
                    onChange={(e) => setDend(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Técnico</Form.Label>
                <SelectFuncionario
                  value={TecnicoId}
                  disabled={loading}
                  onChange={(e) => {
                    setTecnicoId(e.target.value);
                  }}
                />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Opções</Form.Label>
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    variant="secondary"
                    title="Listar"
                    className="me-2"
                    onClick={() => setContador(contador + 1)}
                    disabled={loading}
                  >
                    Atualizar
                  </Button>

                  <Button title="Listar" className="me-2" disabled={loading} onClick={() => window.print()}>
                    Imprimir
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Col sm={12} className="mt-2">
        <Card className="shadow rounded">
          <Table bordered responsive>
            <thead>
              <tr>
                <th>Contrato</th>
                <th>Cliente</th>
                <th>Tipo da OS</th>
                <th>Data de Execução</th>
              </tr>
            </thead>
            <tbody>
              {(ordensServico || []).map((os, key) => {
                const contratoKey = `_${os.ServicoContratado?.ContratoId}`;
                let nova = !porContrato[contratoKey];
                if (nova) {
                  porContrato[contratoKey] = true;
                  porTipo[os.tipo] += 1;
                }

                return (
                  <tr key={key} className={`${nova ? "" : "bg-warning"}`}>
                    <td className={`bg-transparent`}>
                      <a
                        href={`/contratos/${os.ServicoContratado?.ContratoId}`}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(`/contratos/${os.ServicoContratado?.ContratoId}`);
                        }}
                      >
                        {os.ServicoContratado?.Contrato?.contrato}
                      </a>
                    </td>
                    <td className={`bg-transparent`}>{os.ServicoContratado?.Contrato?.Pessoa?.nome}</td>
                    <td className={`bg-transparent`}>{TiposOrdemServico[os.tipo]}</td>
                    <td className={`bg-transparent`}>{new Date(os.data_execucao).toLocaleDateString()}</td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={4} className="text-end">
                  {ordensServico?.length} Ordens de Serviço
                </td>
              </tr>
            </tfoot>
          </Table>
        </Card>
      </Col>

      <Col sm={12} className="mt-2">
        <Card className="shadow rounded">
          <Table bordered responsive>
            <thead>
              <tr>
                <th>Tipo da OS</th>
                <th>Quantidade</th>
                <th>Valor</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(porTipo).map((tipo) => {
                const quantidade = porTipo[tipo];
                const valor = valorPorTipoOs?.[tipo] ? valorPorTipoOs?.[tipo] : 0;
                const totalPorTipo = valor * quantidade;
                total += totalPorTipo;
                if (!quantidade) return false;

                return (
                  <tr key={tipo}>
                    <td>{TiposOrdemServico[tipo]}</td>
                    <td>{quantidade}</td>
                    <td>
                      <CampoMoeda
                        value={valorPorTipoOs?.[tipo] || 0}
                        onChange={(e) => {
                          const valores = { ...valorPorTipoOs };
                          valores[tipo] = e.target.value;
                          setValorPorTipoOs(valores);
                        }}
                      />
                    </td>
                    <td className="text-end">
                      {Number(totalPorTipo).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={4} className="text-end">
                  {Number(total).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </td>
              </tr>
            </tfoot>
          </Table>
        </Card>
      </Col>
    </Container>
  );
}
