import React from "react";
import ImportacaoContext from "./ImportacaoContext";

export default function ImportacaoProvider(props) {
  const [modulosImportados, setModulosImportados] = React.useState({});

  return (
    <ImportacaoContext.Provider
      value={{
        modulosImportados,
        setModulosImportados,
      }}
    >
      {props.children}
    </ImportacaoContext.Provider>
  );
}
