import React from 'react';
import LoadingContext from './LoadingContext';

export default function LoadingProvider(props){
    const [loading, setLoading] = React.useState(false);

    return <LoadingContext.Provider value={{
        loading, setLoading
      }}>
          {props.children}
    </LoadingContext.Provider>;
}