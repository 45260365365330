import React from 'react';
import IoContext from 'contextos/IoContext';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import SelectLocalAlmoxarifado from './SelectLocalAlmoxarifado';
import { toast } from 'react-toastify';

export default function ModalTransfererirLocal({ show, onHide, estoque, count }) {
    const { socket } = React.useContext(IoContext);
    const [quantidade, setQuantidade] = React.useState(0);
    const [LocalAlmoxarifadoId, setLocalAlmoxarifadoId] = React.useState(0);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setQuantidade(0);
        setLocalAlmoxarifadoId(0);
    }, [count]);

    return <Modal show={show} onHide={onHide} size="lg">
        <Form onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();

            setLoading(true);
            socket.emit("Almoxarifado.TransferirEstoqueLocal", {
                id: estoque.id,
                LocalAlmoxarifadoId,
                quantidade
            }, (error) => {
                setLoading(false);

                if (error) return toast.error("" + error);
                onHide(true);
            })
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Nova Movimentação</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row>
                    <Col sm={12}>
                        <Form.Group>
                            <Form.Label>Produto</Form.Label>
                            <Form.Control value={estoque && estoque.Produto ? estoque.Produto.nome : "----"} disabled />
                        </Form.Group>
                    </Col>

                    <Col sm={12}>
                        <Form.Group>
                            <Form.Label>Local de Origem</Form.Label>
                            <SelectLocalAlmoxarifado AlmoxarifadoId={estoque ? estoque.AlmoxarifadoId : 0}
                                value={estoque ? estoque.LocalAlmoxarifadoId : 0}
                                disabled />
                        </Form.Group>
                    </Col>

                    <Col sm={12}>
                        <Form.Group>
                            <Form.Label>Local de Destino</Form.Label>
                            <SelectLocalAlmoxarifado AlmoxarifadoId={estoque ? estoque.AlmoxarifadoId : 0} value={LocalAlmoxarifadoId}
                                onChange={e => setLocalAlmoxarifadoId(e.target.value)}
                            />
                        </Form.Group>
                    </Col>

                    <Col sm={6}>
                        <Form.Group>
                            <Form.Label>Quantidade</Form.Label>
                            <Form.Control type="number" min={1} step={1} required disabled={loading}
                                value={quantidade} onChange={e => setQuantidade(+e.target.value)} />
                        </Form.Group>
                    </Col>

                    <Col sm={6}>
                        <Form.Group>
                            <Form.Label>Ações</Form.Label>
                            <div className="text-right">
                                <Button type="submit" variant="primary">Transferir</Button>
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
        </Form>
    </Modal>
}