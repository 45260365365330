import React from "react";

export default function RenderAnatelTrimestralDet({ exibirDetalhes, txt, dados, formatter }) {
  return (
    exibirDetalhes &&
    Object.keys(dados?.detalhado || {}).map((t) => {
      return (
        <React.Fragment key={t}>
          <tr className="small">
            <td className="ps-5">
              {txt} [{t}]:
            </td>
            <td>{formatter(dados.detalhado[t])}</td>
          </tr>
        </React.Fragment>
      );
    })
  );
}
