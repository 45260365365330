import React from 'react';
import CampoProcurar from '../../../../componentes/CampoProcurar';
import IoContext from '../../../../contextos/IoContext';

export default function CampoProcurarPlano({ titulo='Selecionar Plano', required, FilialId, value, onSelect, disabled }){
    const { socket } = React.useContext(IoContext);
    const [planos, setPlanos] = React.useState([]);
    const loading = null;

    React.useEffect(()=>{
        setPlanos([]);
    },[FilialId])

    const getPlanos = React.useCallback((texto, FilialId)=>{
        let whereFilial = {};
        if(FilialId) whereFilial.id = FilialId;

        socket.emit("Plano.findAll", {
            attributes: ['id','nome'],
            where: {
                nome_like: `%${texto}%`,
                ativo: true
            },
            withFiliais: {
                attributes: [],
                where: whereFilial
            },
            limit: 25
        }, (error, resp) => {
            if(error) {
                console.log("Plano.findAll Error:", error);
            } else {
                setPlanos(resp);
            }
        })
    }, [socket]);

    return <CampoProcurar 
        titulo={'Selecionar Plano' || titulo}
        disabled={disabled || loading}
        value={value} required={required}
        onSelect={onSelect}
        titulos={['Id','Plano']}
        itens={planos}
        renderItem={ (titulo, item, idx) => {
            switch(titulo) {
                case 'Id': return item.id;
                case 'Plano': return `${item.nome}`;
                default:
                    return titulo
            }
        }}
        onSearch={ txt => {
            getPlanos(txt, FilialId);
        }}
        />
}