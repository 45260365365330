import React , {
  useState,
  useContext
} from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { toast } from 'react-toastify'

import IoContext from 'contextos/IoContext'
import CampoEnderecoIPv4 from 'componentes/CampoEnderecoIPv4'
import { InputGroup } from 'react-bootstrap'

export default function RelVoip() {
  const { socket } = useContext(IoContext)
  const [loading, setLoading] = useState(false)
  const [ipIni, setIpIni]     = useState("")
  const [ipFim, setIpFim]     = useState("")
  const [dados, setDados]     = useState(null)


  const listarIps = () => {
    const reqData = {
      ipInicial: ipIni,
      ipFinal: ipFim
    }

    setLoading(true)

    socket.emit("Relatorios.listarIpsAtivos", reqData, 
    (error, resp) => {
      if (error) {
        toast.error('' + error)
        setLoading(false)
      }
      
      setDados(resp)
      setLoading(false)
    });
  }

  return (
    <Container fluid className='m-2'>
      <Card>
        <Card.Header className="d-print-none pt-3 bg-primary text-light">
          <h3>
            Endereços de IPs Ativos
          </h3>
        </Card.Header>
        <Card.Body>
          <Row className='d-print-none mb-2'>
            <Col sm={12} md={'auto'}>
              <Form.Group>
                <Form.Label>
                  Faixa de IP:
                </Form.Label>
                <InputGroup>
                  <CampoEnderecoIPv4 
                    disabled={loading}
                    value={ipIni}
                    onChange={(txt) => {setIpIni(txt)}}
                    />
                  <CampoEnderecoIPv4 
                    className="ms-2"
                    disabled={loading}
                    value={ipFim}
                    onChange={(txt) => {setIpFim(txt)}}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>
                  Ações
                </Form.Label>
                <div>
                  <Button className="me-2" onClick={() => {listarIps()}}>
                    Pesquisar
                  </Button>
                  <Button onClick={() => { window.print() }}>
                    Imprimir
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row className='p-2'>
            <Table className={dados ? '' : 'd-none'}>
              <thead>
                <tr>
                  <th>
                    Contrato
                  </th>
                  <th>
                    Cliente
                  </th>
                  <th>
                    Endereço IP
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  (dados || []).map((item, idx) => {
                    return (
                      <tr key={idx}>
                        <td>
                          {item.Contrato?.numero_contrato || ""}
                        </td>
                        <td>
                          {item.Contrato?.Pessoa?.nome || ""}
                        </td>
                        <td>
                          {item.enderecoIpv4 || ""}
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
              <tfoot>
                <tr className='text-end'>
                  <td colSpan={3}>
                    {dados?.length} Registros
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  )
}