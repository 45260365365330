import SelectServicoContratadoVoip from "componentes/SelectServicoContratadoVoip";
import SelectServidorVoip from "componentes/SelectServidorVoip";
import IoContext from "contextos/IoContext";
import React from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import DetalhesRamalVoip from "./DetalhesRamalVoip";

export default function ModalAdicionarContratoVoip({
  ContratoVoipId,
  ContratoId,
  show,
  onHide,
  closeButton = true,
  callback = (e) => {
    return e;
  },
  loading,
  setLoading,
}) {
  const { socket } = React.useContext(IoContext);
  const [ServicoContratadoId, setServicoContratadoId] = React.useState("");
  const [ServidorVoipId, setServidorVoipId] = React.useState("");
  const [tipo, setTipo] = React.useState("");
  const [dados, setDados] = React.useState({});

  React.useEffect(() => {
    setServicoContratadoId("");
    setServidorVoipId("");
    setTipo("");

    if (!ContratoVoipId) return;

    setLoading(true);
    socket.emit(
      "ContratoVoip.findOne",
      {
        where: {
          id: ContratoVoipId,
        },
        withServidorVoip: {
          attributes: ["tipo"],
        },
      },
      (error, ContratoVoip) => {
        setLoading(false);
        if (error) return toast.error("" + error);

        setServicoContratadoId(ContratoVoip.ServicoContratadoId);
        setServidorVoipId(ContratoVoip.ServidorVoipId);
        setTipo(ContratoVoip?.ServidorVoip?.tipo);
        setDados(ContratoVoip);

        if (ContratoVoip.iccId) {
          socket.emit(
            "Patrimonio.findOne",
            {
              attributes: ["id"],
              where: {
                numeroSerie: ContratoVoip.iccId,
              },
            },
            (error, patrimonio) => {
              if (error) return toast.info("" + error);
              if (!patrimonio)
                throw new Error(
                  `Patrimônio Não Encontrado para o IccId: ${ContratoVoip.iccId}`
                );
              setDados({
                ...ContratoVoip,
                PatrimonioId: patrimonio.id,
              });
            }
          );
        }
      }
    );
  }, [socket, setLoading, ContratoVoipId]);

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();

          if (!ServicoContratadoId)
            return toast.info("Por favor, Selecione o Serviço Contratado");
          if (!ServidorVoipId)
            return toast.info("Por favor, Selecione o Servidor Voip");

          setLoading(true);
          socket.emit(
            "ContratoVoip.salvar",
            {
              ContratoId,
              ServicoContratadoId,
              ServidorVoipId,
              ...dados,
            },
            (error) => {
              setLoading(false);
              if (error) return toast.error("" + error);

              toast.info("Cadastro Efetuado com Sucesso !");
              onHide(false);
              if (callback) {
                callback(e);
              }
            }
          );
        }}
      >
        <Modal.Header closeButton={closeButton}>
          <Modal.Title>Adicionar Ramal Telefônico</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Form.Group>
              <Form.Label>Serviço Contratado</Form.Label>
              <SelectServicoContratadoVoip
                disabled={loading}
                ContratoId={ContratoId}
                required
                ServicoContratadoId={ServicoContratadoId}
                value={ServicoContratadoId}
                onChange={(e) => setServicoContratadoId(e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Servidor Voip</Form.Label>
              <SelectServidorVoip
                disabled={loading}
                value={ServidorVoipId}
                required
                onChange={(e) => setServidorVoipId(e.target.value)}
                onSelect={(ServidorVoip) => {
                  setTipo(ServidorVoip?.tipo);
                }}
              />
            </Form.Group>

            <DetalhesRamalVoip
              tipo={tipo}
              dados={dados}
              setDados={setDados}
              ContratoId={ContratoId}
              loading={loading}
              setLoading={setLoading}
            />
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="btn-warning"
            disabled={loading}
            onClick={() => {
              onHide(false);
            }}
          >
            Cancelar
          </Button>

          <Button type="submit" className="btn-primary" disabled={loading}>
            Salvar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
