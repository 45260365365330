import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProjetoFtth from './ProjetoFtth';
import ProjetosFtth from './ProjetosFtth';

export default function ProjetosFtthIndex(){
    const [texto, setTexto] = React.useState('');
    const [pagina, setPagina] = React.useState(1);
    
    return <Routes>
        <Route path={`/:id`} element={<ProjetoFtth />} />
        <Route path={`*`} element={<ProjetosFtth
                texto={texto} setTexto={setTexto} 
                pagina={pagina} setPagina={setPagina}
                />
            } />
    </Routes>
}