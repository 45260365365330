import React from 'react';
import Form from 'react-bootstrap/Form';

export default function CampoEnderecoMac(props) {
    const { onChange } = props;
    return <Form.Control
        {...props}
        maxLength={17}
        style={{
            font: "monospace"
        }}
        onChange={e => {
            const hexDigits = e.target.value.replace(/[^A-Fa-f0-9]/g, '')
            if (!hexDigits) onChange(hexDigits);

            let mac = '';
            mac += hexDigits.slice(0, 2)

            if (hexDigits.length > 2)
                mac += ':' + hexDigits.slice(2, 4);

            if (hexDigits.length > 4)
                mac += ':' + hexDigits.slice(4, 6);

            if (hexDigits.length > 6)
                mac += ':' + hexDigits.slice(6, 8);

            if (hexDigits.length > 8)
                mac += ':' + hexDigits.slice(8, 10);

            if (hexDigits.length > 10)
                mac += ':' + hexDigits.slice(10, 12);

            onChange(mac);
        }}
    />
}