import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

export default function Paginacao({ 
    pagina, setPagina, total,
    quantidaePorPagina=10
}) {
    const nPaginas = Math.floor((isNaN(total) ? 0 : total) / quantidaePorPagina)+1;
    const paginas = [];
    let paginaInicial = 1;
    let paginaFinal = nPaginas;

    if(nPaginas > 10) {
        if(pagina > 3) {
            paginaInicial = pagina-2;
            paginaFinal = pagina+2;
        } else {
            paginaFinal = 6;
        }

        if(paginaFinal > nPaginas) {
            paginaFinal = nPaginas;
        }
    }
    
    for(let i=paginaInicial; i<= paginaFinal; i++) {
        paginas.push(i);
    }
    
    return <Pagination>
        <Pagination.First onClick={() => setPagina(1) } />
        { paginaInicial > 1 ? <Pagination.Ellipsis /> : false } 
        
        { (paginas || []).map ( (idx) => {
            return <Pagination.Item key={idx} active={idx===pagina} onClick={()=>{
                setPagina(idx);
            }}>
                {idx}
            </Pagination.Item>
        })}
        { paginaFinal < nPaginas ? <Pagination.Ellipsis /> : false } 
        <Pagination.Last onClick={() => setPagina(nPaginas) } />
    </Pagination>
}