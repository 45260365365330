import React from 'react';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import IoContext from '../../../../contextos/IoContext';

export default function SelectFiliais(props){
    const { socket } = React.useContext(IoContext);
    const { id, value, onChange, disabled, pular } = props; 
    const [filiais, setFiliais] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(()=>{
        setLoading(true);
        socket.emit("Filial.findAll", { 
            order: [["NOME", "ASC"]],
        }, (error, resp) => {
            setLoading(false);
            if(error){
                toast.error(error);
            } else {
                setFiliais(resp);
            }
        })
    }, [socket]);

    let conf = { ...props };
    delete(conf.query);
    delete(conf.pular);

    return <Form.Control as="select" 
        id={id || "FilialId"} 
        onChange={onChange} value={value}
        disabled={loading || disabled}
        {...props}
    >
        <option value=""></option>
        { (filiais || []).map( filial => {
            if(pular) {
                if(Array.isArray(pular)) {
                    if(pular.indexOf(filial.id)){
                        return false;
                    }
                } else {
                    if(+pular === +filial.id) {
                        return false;
                    }
                }
            }
            return <option key={filial.id} value={filial.id}>{filial.nome}</option>
        })}
    </Form.Control>;
}