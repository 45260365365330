export const SituacoesAtendimento = {
  1: "ABERTO",
  2: "OS AGENDADA",
  3: "EM ATENDIMENTO",
  6: "POS VENDA",
  8: "CONCLUÍDO",
  9: "CANCELADO",
};

export const ATENDIMENTO_EM_ABERTO = 1;
export const ATENDIMENTO_AGUARDANDO_OS = 2;
export const ATENDIMENTO_EM_ATENDIMENTO = 3;
export const ATENDIMENTO_POS_VENDA = 6;
export const ATENDIMENTO_CONCLUIDO = 8;
export const ATENDIMENTO_CANCELADO = 9;

export const CANAIS_DE_ATENDIMENTO = {
  0: "Não Específicado",
  1: "Presencial",
  2: "Telefone",
  3: "WebSite",
  4: "Rede Social",
  5: "Email",
  6: "Aplicativo",
  99: "Integração",
};

export const SUBCANAIS_DE_ATENDIMENTO = {
  0: {
    0: "Não Específicado",
  },
  1: {
    1: "Loja Física",
  },
  2: {
    1: "Ligação",
    2: "Sms",
  },
  3: {
    1: "Site da Empresa",
  },
  4: {
    1: "Whatsapp",
    2: "Telegram",
    3: "Signal",
    4: "Facebook",
    5: "Instagram",
  },
  5: {
    1: "Email Corporativo",
  },
  6: {
    1: "LHSAC",
  },
  99: {
    1: "Megazap",
    2: "Newave / Fluctus",
  },
};
