import React from "react";
import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import AuthContext from "contextos/AuthContext";
import logo from "images/lhprovedor.png";
import { DateTime } from "luxon";

const CardCabecalhoDados = ({ nomeCliente, dini, dend }) => {
  const { empresa } = React.useContext(AuthContext);
  return (
    <Card.Body className="d-none d-print-table">
      <Row>
        <Col className={""}>
          <Card.Img
            className="img-fluid w-50"
            src={empresa && empresa.logomarca ? `data:image/png;base64, ${empresa.logomarca}` : logo}
          />
        </Col>
        <Col className={"w-70 align-self-start"}>
          <Row className={"text-left"}>
            <h3>{empresa.nome}</h3>
            <p>{empresa.telefone1}</p>
            <p>{empresa.email}</p>
          </Row>
        </Col>
      </Row>
      <Row className={"text-center"}>
        <h3>Relatorio de Quitação de Débitos</h3>
      </Row>
      <Row>
        <Col className={"text-center"}>Cliente: {nomeCliente}</Col>
        <Col className={"align-self-start text-right"}>
          <p>
            Período: {DateTime.fromISO(dini).toLocaleString(DateTime.DATE_SHORT)} à
            {DateTime.fromISO(dend).toLocaleString(DateTime.DATE_SHORT)}
          </p>
        </Col>
      </Row>
    </Card.Body>
  );
};

export default CardCabecalhoDados;
