import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import IoContext from "../../../../contextos/IoContext";
import SelectFiliais from "../../../cadastros/administrativo/filiais/SelectFiliais";
import { Container } from "react-bootstrap";

export default function RelCobrancaTerceirizada() {
  const { socket } = React.useContext(IoContext);
  const [contador, setContador] = React.useState(0);
  const [dini, setDini] = React.useState("");
  const [dend, setDend] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [FilialId, setFilialId] = React.useState(0);
  const [contratos, setContratos] = React.useState([]);

  React.useEffect(() => {
    setLoading(true);
    setContratos([]);

    let where = {
      dataEnvioCobrancaTeceirizada_ne: null,
    };

    if (dini && dend) where.dataEnvioCobrancaTeceirizada_between = [dini, dend];
    if (FilialId) where.FilialId = FilialId;

    socket.emit(
      "Contrato.findAll",
      {
        attributes: ["numero_contrato", "dataEnvioCobrancaTeceirizada"],
        where,
        withPessoa: {
          attributes: ["nome"],
        },
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          return console.log("## ERROR:", error);
        }
        setContratos(resp);
      }
    );
  }, [socket, dini, dend, FilialId, contador]);

  return (
    <Container className="m-2" fluid>
      <Card className="shadow">
        <Card.Header className="bg-primary text-light">
          <h3 className="float-start">Relatório de Clientes em Cobrança Terceirizada</h3>
        </Card.Header>

        <Card.Body className="d-print-none">
          <Row>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>Período</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="date"
                    value={dini}
                    placeholder="Inicial"
                    disabled={loading}
                    onChange={(e) => setDini(e.target.value)}
                  />
                  <Form.Control
                    type="date"
                    value={dend}
                    placeholder="Final"
                    disabled={loading}
                    onChange={(e) => setDend(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Filial:</Form.Label>
                <SelectFiliais
                  value={FilialId}
                  onChange={(e) => {
                    setFilialId(e.target.value);
                  }}
                  disabled={loading}
                />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    variant="secondary"
                    title="Listar"
                    className="me-2"
                    onClick={() => setContador(contador + 1)}
                    disabled={loading}
                  >
                    Atualizar
                  </Button>

                  <Button title="Listar" className="me-2" disabled={loading} onClick={() => window.print()}>
                    Imprimir
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-2">
        <Table>
          <thead>
            <tr>
              <th>Contrato</th>
              <th>Cliente</th>
              <th>Data de Envio para Cobrança Terceirizada</th>
            </tr>
          </thead>
          <tbody>
            {(contratos || []).map((contrato) => {
              return (
                <tr>
                  <td>{contrato.numero_contrato}</td>
                  <td>{contrato.Pessoa?.nome}</td>
                  <td>{contrato.dataEnvioCobrancaTeceirizada.split("-").reverse().join("/")}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
    </Container>
  );
}
