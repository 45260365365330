import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function ModalConfirmar({
  titulo = "Confirmar",
  texto,
  disabled = false,
  size = "md",
  show,
  onHide,
  callback,
  closeButton = true,
}) {
  return (
    <Modal show={show} onHide={onHide} size={size}>
      <Modal.Header closeButton={closeButton}>
        <Modal.Title>{titulo}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{texto}</Modal.Body>

      <Modal.Footer>
        <Button
          className="btn-warning"
          disabled={disabled}
          onClick={() => {
            onHide(false);
          }}
        >
          Cancelar
        </Button>

        <Button
          className="btn-primary"
          disabled={disabled}
          onClick={(e) => {
            if (callback) {
              callback(e);
            }
          }}
        >
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
