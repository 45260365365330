import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import CardGraficoTopAtendimentos from "./CardGraficoTopAtendimentos";
import CardListaTopAtendimentos from "./CardListaTopAtendimentos";
import IoContext from "../../../../contextos/IoContext";
import { toast } from "react-toastify";
import { getFinalMes, getInicioMes } from "misc/lhdatas";
import { Container } from "react-bootstrap";

export default function RelatorioTopNAtendimentos() {
  const { socket } = React.useContext(IoContext);
  const [contador, setContador] = React.useState(0);
  const [dini, setDini] = React.useState(getInicioMes().toISOString().split("T")[0]);
  const [dend, setDend] = React.useState(getFinalMes().toISOString().split("T")[0]);
  const [loading, setLoading] = React.useState(false);
  const [atendimentos, setAtendimentos] = React.useState([]);
  const [nOrdensServico, setNOrdensServico] = React.useState({});
  const [limit, setLimit] = React.useState(10);

  React.useEffect(() => {
    setAtendimentos([]);
    setNOrdensServico({});
    setLoading(true);
    socket.emit(
      "Atendimento.findAll",
      {
        attributes: ["fn_count(Atendimento.id) as nAtendimentos", "id", "ContratoId"],
        group: ["ContratoId"],
        order: [["col_nAtendimentos", "desc"]],
        where: {
          data_abertura_between: [dini, dend],
        },
        withContrato: {
          attributes: ["contrato"],
          withPessoa: {
            attributes: ["nome"],
          },
          withFilial: {
            attributes: ["nome"],
          },
        },
        limit: +limit,
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          return toast.error(error);
        }

        setAtendimentos(resp);
        socket.emit(
          "OrdemServico.count",
          {
            where: {
              createdAt_between: [dini, dend],
            },
            withServicoContratado: {
              where: {
                ContratoId_in: resp.map((att) => att.ContratoId),
              },
            },
            group: "ServicoContratado.CLIENTE_ID",
          },
          (error, resp) => {
            if (error) {
              return toast.error(error);
            }

            let nOs = {};
            (resp || []).forEach((i) => {
              nOs[i.CLIENTE_ID] = i.count;
            });
            setNOrdensServico(nOs);
          }
        );
      }
    );
  }, [socket, limit, dini, dend, contador]);

  return (
    <Container fluid>
      <Card className="shadow">
        <Card.Header className="bg-primary text-light">
          <h3 className="float-start">Relatório Top {limit} Atendimentos</h3>
        </Card.Header>

        <Card.Body className="d-print-none">
          <Row>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>Data do Atendimento</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="date"
                    value={dini}
                    placeholder="Inicial"
                    disabled={loading}
                    onChange={(e) => setDini(e.target.value)}
                  />
                  <Form.Control
                    type="date"
                    value={dend}
                    placeholder="Final"
                    disabled={loading}
                    onChange={(e) => setDend(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Máximo de Registros</Form.Label>
                <Form.Control
                  type="number"
                  min={1}
                  step={1}
                  value={limit}
                  onChange={(e) => setLimit(+e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    variant="secondary"
                    title="Listar"
                    className="me-2"
                    onClick={() => setContador(contador + 1)}
                    disabled={loading}
                  >
                    Atualizar
                  </Button>

                  <Button title="Listar" className="me-2" disabled={loading} onClick={() => window.print()}>
                    Imprimir
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Row>
        <Col sm={12} className="mt-2">
          <CardGraficoTopAtendimentos
            dini={dini}
            dend={dend}
            contratos={(atendimentos || []).slice(0, 10).map((att) => att.ContratoId)}
          />
        </Col>

        <Col sm={12} className="mt-2">
          <CardListaTopAtendimentos atendimentos={atendimentos || []} nOrdensServico={nOrdensServico || {}} />
        </Col>
      </Row>
    </Container>
  );
}
