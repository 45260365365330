import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import CampoData from "../../../../componentes/CampoData";
import SelectUsuario from "../../../sistema/SelectUsuario";

export default function RelatorioFiscalRegimeCaixa() {
  // const [UsuarioId, setUsuarioId] = React.useState(0);
  const [dataIni, setDataIni] = React.useState(new Date().toISOString().split("T")[0]);
  const [dataEnd, setDataEnd] = React.useState(new Date().toISOString().split("T")[0]);
  const [contador, setContador] = React.useState(0);

  return (
    <div className="m-2">
      <Card className="shadow">
        <Card.Header className="bg-primary text-light">
          <h3 className="float-start">Relatório de Movimentações Financeiras por Usuário</h3>
        </Card.Header>

        <Card.Body className="d-print-none">
          <Row>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>Usuário</Form.Label>
                <SelectUsuario />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Período</Form.Label>
                <InputGroup>
                  <CampoData value={dataIni} onChange={(e) => setDataIni(e.target.value)} />
                  <CampoData value={dataEnd} onChange={(e) => setDataEnd(e.target.value)} />
                </InputGroup>
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button variant="secondary" title="Listar" className="me-2" onClick={() => setContador(contador + 1)}>
                    Atualizar
                  </Button>

                  <Button title="Listar" className="me-2" onClick={() => window.print()}>
                    Imprimir
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}
