import React from 'react';
import FormularioListagem from 'componentes/FormularioListagem';
import IoContext from 'contextos/IoContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

export default function CategoriasFinanceira({ texto, setTexto, pagina, setPagina }){
    const navigate = useNavigate();
    const { socket } = React.useContext(IoContext);
    const [loading, setLoading] = React.useState(false);
    const [categorias, setCategorias] = React.useState([]);
    const [total, setTotal] = React.useState(0);

    React.useEffect(()=>{
        let where = {};
        if(texto) where.nome_like =  `%${texto}%`;

        setLoading(true);
        socket.emit("CategoriaFinanceira.findAndCountAll", { 
            where, limit: 10, offset: 10 * (pagina-1) 
        }, (error, resp) => {
            setLoading(false);
            if(error) {
                toast.error(''+error);
            } else {
                setTotal(resp.count);
                setCategorias(resp.rows);
            }
        });
    }, [socket, texto, pagina]);
    
    return <FormularioListagem
        titulo={`Categorias`}
        itens={categorias} qtdItens={ total } loading={loading}
        pagina={pagina} setTexto={setTexto} setPagina={setPagina}
        titulos={["Id", "Categoria"]}
        onClick={(e)=>{
            navigate(`${e.id}`);
        }}
        
        renderItem={ (titulo, item) => {
            switch(titulo) {
                case 'Id': return item.id;
                case 'Categoria': return item.nome;
                default: return titulo;
            }
        }}
        />
}