import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import { downloadCsv } from "../../../misc/downloadCsv";

export default function CardProjecaoFinanceira({ meses, projecoes, ano }) {
  return (
    <Card className="mt-3">
      <Card.Header className="bg-primary text-light">
        <h5 className="d-flex justify-content-between align-items-center">
          Projeção Financeira
          <Button
            title="Baixar Planilha"
            variant="success"
            size="sm"
            className="d-print-none float-right"
            onClick={(e) => downloadCsv(geraArrayCsv(meses, projecoes), `projecao_financeira_${ano}`)}
          >
            <FontAwesomeIcon icon={faFileCsv} />
          </Button>
        </h5>
      </Card.Header>

      <Table size="sm">
        <tbody>
          <tr>
            <th></th>
            {meses.map((c) => {
              const d = new Date();
              d.setMonth(c - 1);
              return (
                <th key={c} className="text-right">
                  {d.toLocaleString("default", { month: "long" }).toUpperCase()}
                </th>
              );
            })}
          </tr>

          {renderLinha({
            meses,
            projecoes,
            campo: "valorReceberPrevisto",
            titulo: "Receitas Previstas",
            analiseHorizontal: true,
          })}
          {renderLinha({
            meses,
            projecoes,
            campo: "valorReceberPrevistoMensalidade",
            titulo: "Receitas (Mensalidade)",
            paddingLeft: "4 small",
          })}
          {renderLinha({
            meses,
            projecoes,
            campo: "valorReceberPrevistoServicos",
            titulo: "Receitas (Serviço)",
            paddingLeft: "4 small",
          })}
          {renderLinha({
            meses,
            projecoes,
            campo: "valorReceberPrevistoVendas",
            titulo: "Receitas (Vendas)",
            paddingLeft: "4 small",
          })}
          {renderLinha({
            meses,
            projecoes,
            campo: "valorReceberPrevistoInstalacaoes",
            titulo: "Receitas (Instalação)",
            paddingLeft: "4 small",
          })}
          {renderLinha({
            meses,
            projecoes,
            campo: "valorReceberPrevistoOutros",
            titulo: "Receitas (Outros)",
            paddingLeft: "4 small",
          })}
          {renderLinha({
            meses,
            projecoes,
            campo: "valorRecebidoTotal",
            titulo: "Receitas Recebidas",
            analiseHorizontal: true,
          })}
          {renderLinha({
            meses,
            projecoes,
            campo: "valorRecebidoAntecipado",
            titulo: "Antecipado",
            paddingLeft: "4 small",
          })}
          {renderLinha({
            meses,
            projecoes,
            campo: "valorRecebidoNoVencimento",
            titulo: "No Vencimento",
            paddingLeft: "4 small",
          })}
          {renderLinha({
            meses,
            projecoes,
            campo: "valorRecebidoNosMesesSeguintes",
            titulo: "Atrasados",
            paddingLeft: "4 small",
          })}
          {renderLinha({
            meses,
            projecoes,
            campo: "valorAPagar",
            titulo: "Despesas",
            analiseHorizontal: true,
            coresH: ["text-danger", "text-success"],
          })}
          {renderLinha({
            meses,
            projecoes,
            campo: "SaldoReceitaDespesasPrevisto",
            titulo: "Balanço Previsto",
            colorirValores: true,
          })}
          {renderLinha({
            meses,
            projecoes,
            campo: "SaldoReceitaDespesasEfetivo",
            titulo: "Balanço Efetivo",
            colorirValores: true,
          })}
        </tbody>
      </Table>
    </Card>
  );
}

function renderLinha({
  meses,
  projecoes,
  campo,
  titulo,
  paddingLeft = "2",
  analiseHorizontal = false,
  colorirValores = false,
  coresH = ["text-success", "text-danger"],
}) {
  return (
    <tr>
      <th className={`ps-${paddingLeft}`}>{titulo}</th>
      {meses.map((mes, idx) => {
        let variacaoHorizontal = 0;
        if (analiseHorizontal && idx) {
          let v1 = getValor({ projecoes, mes: meses[idx - 1], campo });
          let v2 = getValor({ projecoes, mes, campo });
          variacaoHorizontal = ((v2 - v1) / v1) * 100;

          if (isNaN(variacaoHorizontal)) {
            variacaoHorizontal = 0;
          }
        }

        const valor = getValor({ projecoes, mes, campo });
        let corTexto = "";
        if (colorirValores) {
          corTexto = valor > 0 ? coresH[0] : coresH[1];
        }
        return (
          <td key={mes} className={`small text-right ${corTexto}`}>
            {valor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}

            {analiseHorizontal && idx ? (
              <div className={`small ${variacaoHorizontal > 0 ? coresH[0] : coresH[1]}`}>
                {variacaoHorizontal.toFixed(2)} %
              </div>
            ) : (
              false
            )}
          </td>
        );
      })}
    </tr>
  );
}

function getValor({ projecoes, mes, campo, FilialId = "total", valorPadrao = 0 }) {
  if (projecoes[mes] && projecoes[mes][FilialId]) {
    if (isNaN(+projecoes[mes][FilialId][campo])) return valorPadrao;
    return projecoes[mes][FilialId][campo];
  }

  return valorPadrao;
}

function geraArrayCsv(meses, projecoes) {
  const camposLinhasCsv = [
    "valorReceberPrevisto",
    "valorReceberPrevistoMensalidade",
    "valorReceberPrevistoServicos",
    "valorReceberPrevistoVendas",
    "valorReceberPrevistoInstalacaoes",
    "valorReceberPrevistoOutros",
    "valorRecebidoTotal",
    "valorRecebidoAntecipado",
    "valorRecebidoNoVencimento",
    "valorRecebidoNosMesesSeguintes",
    "valorAPagar",
    "SaldoReceitaDespesasPrevisto",
    "SaldoReceitaDespesasEfetivo",
  ];

  const indicadoresCsv = [
    "Receitas Previstas",
    "Receitas (Mensalidade)",
    "Receitas (Serviço)",
    "Receitas (Vendas)",
    "Receitas (Instalação)",
    "Receitas (Outros)",
    "Receitas Recebidas",
    "Antecipado",
    "No Vencimento",
    "Atrasados",
    "Despesas",
    "Balanço Previsto",
    "Balanço Efetivo",
  ];

  const arrayCsv = [];

  function retornarLinhaCabecalho(meses) {
    const array = [];
    array.push("");
    meses.map((mes) => {
      const d = new Date();
      d.setMonth(mes - 1);
      return array.push(d.toLocaleString("default", { month: "long" }).toUpperCase());
    });
    return array;
  }

  function geraLinhaCsv(projecoes, meses, indicador, campo) {
    const tmpLinhaDadosCsv = [];
    tmpLinhaDadosCsv.push(indicador);
    meses.forEach((mes) => {
      const valor = getValor({ projecoes, mes, campo });
      tmpLinhaDadosCsv.push(valor.toFixed(2));
    });
    return tmpLinhaDadosCsv;
  }

  arrayCsv.push(retornarLinhaCabecalho(meses));
  for (var i = 0; i < 13; i++) {
    arrayCsv.push(geraLinhaCsv(projecoes, meses, indicadoresCsv[i], camposLinhasCsv[i]));
  }
  return arrayCsv;
}
