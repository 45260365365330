import React from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import TituloContext from "../../../contextos/TituloContext";
import Listagem from "../../../componentes/Listagem";
import BotaoVoltar from "../../../componentes/BotaoVoltar";
import { toast } from "react-toastify";
import IoContext from "../../../contextos/IoContext";

import SelectAlmoxarifados from "../SelectAlmoxarifados";
import ModalConfirmarRecebimentoRemessaMaterial from "./ModalConfirmarRecebimentoRemessaMaterial";

import { RemessaDeMaterialSituacoes, REMESSA_MATERIAL_EM_ABERTO } from "../../../datatypes/RemessaDeMaterialTypes";
import { useParams } from "react-router";
import { DateTime } from "luxon";

export default function RemessaDeMaterial() {
  const params = useParams();
  const { socket } = React.useContext(IoContext);
  const id = isNaN(parseInt(params.id)) ? "" : +params.id;
  const [alterado, setAlterado] = React.useState(false);
  const [remessa_material, setRemessaMaterial] = React.useState(null);
  const [itens, setItens] = React.useState([]);
  const [AlmoxarifadoId, setAlmoxarifadoId] = React.useState("");
  const [exibirModalConfirmar, setExibirModalConfirmar] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { setTitulo } = React.useContext(TituloContext);

  React.useEffect(() => {
    setTitulo(`Remessa de Material [${id}]`);
  }, [setTitulo, id]);

  React.useEffect(() => {
    obterRemessa({ socket, id, setLoading, setAlmoxarifadoId, setRemessaMaterial, setItens });
  }, [socket, id, setLoading, setAlmoxarifadoId, setRemessaMaterial, setItens]);

  return (
    <Container fluid className="pt-3">
      <Card>
        <Card.Header className="bg-primary text-light">
          <Card.Title className="text-light d-block float-start">Remessa de Material</Card.Title>
          <BotaoVoltar />
        </Card.Header>

        <Card.Body>
          <Row>
            <Col sm={1}>
              <Form.Group>
                <Form.Label>Id</Form.Label>
                <Form.Control value={remessa_material ? remessa_material.id : ""} readOnly />
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Data</Form.Label>
                <Form.Control
                  value={
                    remessa_material
                      ? DateTime.fromISO(remessa_material.createdAt).toLocaleString(DateTime.DATE_SHORT)
                      : ""
                  }
                  readOnly
                />
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group>
                <Form.Label>Almoxarifado</Form.Label>
                <SelectAlmoxarifados
                  value={AlmoxarifadoId}
                  required
                  disabled={remessa_material && remessa_material.id}
                  onChange={(e) => {
                    setAlterado(true);
                    setAlmoxarifadoId(e.target.value);
                  }}
                />
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group>
                <Form.Label>Situação</Form.Label>
                <Form.Control
                  as="select"
                  value={remessa_material ? remessa_material.situacao : ""}
                  onChange={() => {}}
                  disabled
                >
                  {Object.keys(RemessaDeMaterialSituacoes).map((idx) => {
                    return (
                      <option key={idx} value={idx}>
                        {RemessaDeMaterialSituacoes[idx]}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  {remessa_material && remessa_material.situacao === REMESSA_MATERIAL_EM_ABERTO && !alterado ? (
                    <Button
                      className="btn-success"
                      disabled={loading}
                      onClick={(e) => {
                        setExibirModalConfirmar(true);
                      }}
                    >
                      Confirmar Recebimento
                    </Button>
                  ) : (
                    false
                  )}
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Body>
          <Listagem
            titulos={["Id", "Produto", "Item", "Quantidade"]}
            itens={itens || []}
            renderItem={(titulo, item, idx) => {
              switch (titulo) {
                case "Id":
                  return item.id ? item.id : `p${item.ProdutoId}`;
                case "Produto":
                  return item.Produto ? item.Produto.nome : "----";
                case "Item":
                  return item.Produto ? item.Produto.nome : item.Categoria.nome;
                case "Quantidade":
                  return item.quantidade;
                default:
                  return titulo;
              }
            }}
          />
        </Card.Body>
      </Card>

      <ModalConfirmarRecebimentoRemessaMaterial
        remessa_material={remessa_material}
        show={exibirModalConfirmar}
        onHide={setExibirModalConfirmar}
        callback={() => {
          setExibirModalConfirmar(false);
          obterRemessa({ socket, id, setLoading, setAlmoxarifadoId, setRemessaMaterial, setItens });
        }}
      />
    </Container>
  );
}

function obterRemessa({ socket, id, setLoading, setAlmoxarifadoId, setRemessaMaterial, setItens }) {
  setLoading(true);
  socket.emit(
    "RemessaMaterial.findOne",
    {
      where: { id },
      withItens: {},
      withAlmoxarifadoOrigem: {},
    },
    (error, resp) => {
      setLoading(false);

      if (error) {
        toast.error(error);
      } else {
        const remessa = resp || {};
        setRemessaMaterial(remessa);
        setAlmoxarifadoId(remessa.AlmoxarifadoId);

        const i = [];
        (remessa.RemessaMaterialItems || []).forEach((item) => {
          i.push({ ...item });
        });
        setItens(i);
      }
    }
  );
}
