import React from 'react';
import FormularioListagem from 'componentes/FormularioListagem';
import IoContext from 'contextos/IoContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

export default function FormasDePagamento (){
    const navigate = useNavigate();
    const { socket } = React.useContext(IoContext);
    const [loading, setLoading] = React.useState(false);
    const [pagina, setPagina] = React.useState(1);
    const [texto, setTexto] = React.useState("");
    const [total, setTotal] = React.useState(0);
    const [formasDePagamento, setFormasDePagamento] = React.useState([]);
    
    React.useEffect(()=>{
        let where = {};
        if(texto) where.descricao_like = `%${texto}%`;

        setLoading(true);
        socket.emit("FormaPagamento.findAndCountAll", { 
            where, limit: 10, offset: 10 * (pagina-1),
        }, (error, resp) => {
            setLoading(false);
            if(error) {
                toast.error(error);
            } else {
                setFormasDePagamento(resp.rows);
                setTotal(resp.count);
            }
        });
    }, [socket, texto, pagina]);
    
    return <FormularioListagem
        titulo={`Formas de Pagamento`}
        itens={formasDePagamento} qtdItens={total} loading={loading} pagina={pagina}
        setTexto={setTexto} setPagina={setPagina}
        titulos={["Id", "Descrição", "Parcelas", "Entrada (%)", "Juros (%)"]}
        onClick={(e)=>{
            navigate(`${e.id}`);
        }}
        
        renderItem={ (titulo, item) => {
            switch(titulo) {
                case 'Id': return item.id;
                case 'Descrição': return item.descricao;
                case 'Parcelas': return item.parcelas;
                case 'Entrada (%)': return item.entrada;
                case 'Juros (%)': return item.juros;
                default:
                    return titulo;
            }
        }}
        />
}