import React from 'react';
import Table from 'react-bootstrap/Table';


export default function RegistroDeImportacao({ registros }) {
    let total = 0;
    let totalReceber = 0;
    let totalPago = 0;
    let qtdContas = 0;

    return <Table size="sm" responsive>
        <thead>
            <tr>
                <th className="text-right">Mes/Ano</th>
                <th className="text-right">Total</th>
                <th className="text-right">Total a Receber</th>
                <th className="text-right">Total Pago</th>
                <th className="text-right">Qtd. Contas</th>
            </tr>
        </thead>
        <tbody>
            { (Object.keys(registros || {}).map( (key, idx) => {
                const registro = registros[key];
                total += registro.total;
                totalReceber += registro.totalAReceber;
                totalPago    += registro.totalPago;
                qtdContas    += registro.quantidadeContas;

                return <tr key={idx}>
                    <td className="text-right">{registro.mes}/{registro.ano}</td>
                    <td className="text-right">{(registro.total || 0).toLocaleString(navigator.language, { style: 'currency' , currency:'BRL' }) }</td>
                    <td className="text-right">{(registro.totalAReceber || 0).toLocaleString(navigator.language, { style: 'currency' , currency:'BRL' }) }</td>
                    <td className="text-right">{(registro.totalPago || 0).toLocaleString(navigator.language, { style: 'currency' , currency:'BRL' }) }</td>
                    <td className="text-right">{(registro.quantidadeContas || 0).toFixed(0)}</td>
                </tr>
            }))}
        </tbody>
        <tfoot>
            <tr>
                <td className="text-right"></td>
                <td className="text-right">{(total || 0).toLocaleString(navigator.language, { style: 'currency' , currency:'BRL' })}</td>
                <td className="text-right">{(totalReceber || 0).toLocaleString(navigator.language, { style: 'currency' , currency:'BRL' })}</td>
                <td className="text-right">{(totalPago || 0).toLocaleString(navigator.language, { style: 'currency' , currency:'BRL' })}</td>
                <td className="text-right">{qtdContas}</td>
            </tr>
        </tfoot>
    </Table>;
}