import React from "react";
import Card from "react-bootstrap/Card";
import { CANAIS_DE_ATENDIMENTO, SUBCANAIS_DE_ATENDIMENTO } from "../../../../datatypes/AtendiemntosTypes";
import Chart from "react-apexcharts";

export default function CardGraficoAtendimentosPorCanal({ atendimentos }) {
  const [series, setSeries] = React.useState([]);
  const [labels, setLabels] = React.useState([]);

  React.useEffect(() => {
    const series = [];
    const labels = {};

    (atendimentos || []).forEach((att) => {
      const cat = CANAIS_DE_ATENDIMENTO[att.canalDeAtendimento] || att.canalDeAtendimento;
      const subCat =
        SUBCANAIS_DE_ATENDIMENTO[att.canalDeAtendimento][att.subCanalDeAtendimento] || att.subCanalDeAtendimento;

      labels[cat] = true;
      console.log("Labels Len:", Object.keys(labels).length, cat, subCat);

      series.push({
        name: `${cat} - ${subCat}`,
        data: [...Array(Object.keys(labels).length - 1).fill(0), att.nAtendimentos],
      });
    });

    setSeries(series);
    setLabels(Object.keys(labels));
  }, [atendimentos]);

  return (
    <Card className="shadow rounded p-1 mt-2">
      <Chart
        options={{
          title: {
            text: "Atendimentos",
            style: {
              fontSize: "18px",
              fontWeight: "bold",
              fontFamily: undefined,
              color: "#263238",
            },
          },
          legend: {
            position: "bottom",
          },
          labels,
          chart: {
            type: "bar",
            height: 350,
            stacked: true,
          },
        }}
        series={series}
        type="bar"
        height="400"
        width="100%"
      />
    </Card>
  );
}
