export function downloadCsv(dados, nomeArquivo){
    const tmpCsv = []
    dados.forEach(e=> tmpCsv.push(e.join(';')))
    var link = document.createElement('a');
    link.setAttribute('href', 'data:text/csv; charset=utf-8,'+ encodeURIComponent("\uFEFF"+(tmpCsv.join('\r\n'))) )
    //Encoding padrao do excel é ANSI, necessita Byte Order Mark para indicar que é UTF-8
    //https://stackoverflow.com/questions/42462764/javascript-export-csv-encoding-utf-8-issue
    link.setAttribute('download', `${nomeArquivo}.csv`)
    document.body.appendChild(link)
    link.click();
    link.remove();
    return null
}