import React from 'react';
import { toast } from 'react-toastify';
import IoContext from '../../../contextos/IoContext';
import FormularioListagem from '../../../componentes/FormularioListagem';
import { useNavigate } from "react-router-dom";

export default function ProjetosFtth({ texto, pagina, setTexto, setPagina }) {
    const navigate = useNavigate();
    const { socket } = React.useContext(IoContext);
    const [loading, setLoading] = React.useState(false);
    const [total, setTotal] = React.useState(0);
    const [projetos, setProjetos] = React.useState([]);

    React.useEffect(()=>{
        let where = {};
        if(texto) where.descricao_like = `%${texto}%`;

        // setLoading(true);
        socket.emit("ProjetoFtth.findAndCountAll", {
            where, limit: 10, offset: 10 * (pagina-1)
        }, (error, resp) => {
            setLoading(false);
            if(error) {
                toast.error(error);
            } else {
                setTotal(resp.count);
                setProjetos(resp.rows);
            }
        });
    }, [socket, texto, pagina]);

    return <FormularioListagem
        titulo={`Projetos FTTH`}
        itens={projetos || []} qtdItens={total} pagina={pagina} loading={loading}
        setTexto={setTexto} setPagina={setPagina}
        titulos={["Id", "Descrição"]}
        onClick={(e)=>{
            navigate(`${e.id}`);
        }}
        
        renderItem={ (titulo, item) => {
            switch(titulo) {
                case 'Id': return item.id;
                case 'Descrição': return item.descricao;
                default: return titulo;
            }
        }}
        />
}