import CampoData from "componentes/CampoData";
import SelectSituacaoAtendimento from "componentes/SelectSituacaoAtendimento";
import { CONTARECEBER_EM_ABERTO } from "datatypes/ContaReceberTypes";
import { getFinalAno, getInicioAno } from "misc/lhdatas";
import React from "react";
import { Form, Row, Col, Card } from "react-bootstrap";
import ContratoTabLegacy from "./ContratoTabLegacy";

const dataIni = getInicioAno();
dataIni.setFullYear(dataIni.getFullYear() - 1);

export default function ContratoTabAtendimento({ count }) {
  const [dini, setDini] = React.useState(dataIni.toISOString().split("T")[0]);
  const [dend, setDend] = React.useState(getFinalAno().toISOString().split("T")[0]);
  const [situacao, setSituacao] = React.useState(CONTARECEBER_EM_ABERTO);

  return (
    <Card.Body className="h-100">
      <Row>
        <Col sm={2}>
          <Form.Group>
            <Form.Label>Data Inicial</Form.Label>
            <CampoData value={dini} onChange={(e) => setDini(e.target.value)} />
          </Form.Group>
        </Col>
        <Col sm={2}>
          <Form.Group>
            <Form.Label>Data Final</Form.Label>
            <CampoData value={dend} onChange={(e) => setDend(e.target.value)} />
          </Form.Group>
        </Col>

        <Col sm={8}>
          <Form.Group>
            <SelectSituacaoAtendimento value={situacao} onChange={(e) => setSituacao(e.target.value)} />
          </Form.Group>
        </Col>
      </Row>

      <ContratoTabLegacy
        count={count}
        height={90}
        url="form/contratos/tab_atendimento.php"
        params={`&DINI=${dini}&DEND=${dend}&STATUS=${situacao}`}
      />
    </Card.Body>
  );
}
