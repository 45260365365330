import React from "react";
import Form from "react-bootstrap/Form";

export default function CampoDoc1(props) {
  const { onChange } = props;
  const [valido, setValido] = React.useState(false);

  React.useEffect(() => {
    if (props?.value?.length <= 14) {
      setValido(validarCpf(props?.value));
    } else {
      setValido(validarCnpj(props?.value));
    }
  }, [props.value, setValido]);

  return (
    <Form.Group>
      <Form.Label className={`${valido ? "" : "text-danger"}`}>
        {`${props.value}`.length < 14 ? "Cpf / Cnpj" : `${props.value}`.length === 14 ? "Cpf" : "Cnpj"}
      </Form.Label>
      <Form.Control
        {...props}
        className={`${valido ? "is-valid" : "is-invalid"}`}
        maxLength={18}
        onChange={(e) => {
          const soNumeros = e.target.value.replace(/[^\d]/g, "");
          if (!soNumeros) onChange(soNumeros);

          let doc1 = "";

          if (soNumeros.length <= 11) {
            doc1 += soNumeros.slice(0, 3);

            if (soNumeros.length > 3) doc1 += "." + soNumeros.slice(3, 6);

            if (soNumeros.length > 6) doc1 += "." + soNumeros.slice(6, 9);

            if (soNumeros.length > 9) doc1 += "-" + soNumeros.slice(9, 11);
          } else {
            doc1 += soNumeros.slice(0, 2);

            if (soNumeros.length > 2) doc1 += "." + soNumeros.slice(2, 5);

            if (soNumeros.length > 5) doc1 += "." + soNumeros.slice(5, 8);

            if (soNumeros.length > 8) doc1 += "/" + soNumeros.slice(8, 12);

            if (soNumeros.length > 12) doc1 += "-" + soNumeros.slice(12, 14);
          }

          onChange(doc1);
        }}
      />
    </Form.Group>
  );
}

// https://www.devmedia.com.br/validar-cpf-com-javascript/23916
const validarCpf = function (strCPF) {
  strCPF = strCPF.replace(/\D/g, "");
  let Soma = 0;
  let Resto = 0;

  if (strCPF === "00000000000") return false;

  for (let i = 1; i <= 9; i++) {
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  }

  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) {
    Resto = 0;
  }

  if (Resto !== parseInt(strCPF.substring(9, 10))) {
    return false;
  }

  Soma = 0;
  for (let i = 1; i <= 10; i++) {
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  }

  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
};

// https://www.geradorcnpj.com/javascript-validar-cnpj.htm
const validarCnpj = function (cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj === "") return false;

  if (cnpj.length !== 14) {
    return false;
  }

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  ) {
    return false;
  }

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== +digitos.charAt(0)) {
    return false;
  }

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }

  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== +digitos.charAt(1)) {
    return false;
  }

  return true;
};
