import React from "react";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { ATENDIMENTO_EM_ATENDIMENTO } from "../../datatypes/AtendiemntosTypes";
import { toast } from "react-toastify";
import IoContext from "../../contextos/IoContext";
import AuthContext from "../../contextos/AuthContext";
import TituloContext from "../../contextos/TituloContext";
import Listagem from "../../componentes/Listagem";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import BotaoProcessando from "componentes/BotaoProcessando";

export default function FilaAtendimento() {
  const navigate = useNavigate();
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(false);
  const { usuario } = React.useContext(AuthContext);
  const { setTitulo } = React.useContext(TituloContext);
  const [texto] = React.useState("");
  const [count, setCount] = React.useState(0);
  const [atendimentos, setAtendimentos] = React.useState(null);
  const AtendenteId = usuario ? usuario.id : null;

  const sairDaFila = React.useCallback(() => {
    setLoading(true);
    socket.emit("Atendimento.sairDaFila", {}, (error, resp) => {
      setLoading(false);
      if (error) {
        return toast.error("" + error);
      }

      navigate("/lgc/agenda_tecnica");
    });
  }, [navigate, socket]);

  React.useEffect(() => {
    setTitulo(`Fila de Atendimento`);
  }, [setTitulo]);

  const IniciarTratativa = React.useCallback(() => {
    setLoading(true);
    socket.emit("Atendimento.iniciarTratativa", {}, (error) => {
      setLoading(false);
      if (error) {
        return toast.error("" + error);
      }
      setCount(0);
    });
  }, [setLoading, setCount, socket]);

  React.useEffect(() => {
    if (count > 0) {
      setTimeout(() => {
        setCount(count - 1);
      }, 1000);
      return;
    }

    if (loading) return;
    setLoading(true);
    socket.emit(
      "Atendimento.findAll",
      {
        where: {
          situacao: ATENDIMENTO_EM_ATENDIMENTO,
          AtendenteId,
        },
        withContrato: {
          attributes: ["id", "contrato"],
          withPessoa: {
            attributes: ["id", "nome", "telefone1", "telefone2", "telefone3", "telefone4"],
          },
        },
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          return toast.error("" + error);
        }

        if (resp && resp.length > 0) {
          setAtendimentos(resp);
        } else {
          IniciarTratativa();
        }

        setCount(10);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, setLoading, setAtendimentos, AtendenteId, count, setCount, IniciarTratativa]);

  return (
    <Container fluid className="pt-3">
      <Card>
        <Card.Header className="bg-primary text-light">
          Fila de Atendimento - {usuario.nome} [{count}]
        </Card.Header>
        <Card.Body>
          <Row>
            {loading ? (
              <BotaoProcessando loading={loading} />
            ) : (
              <React.Fragment>
                <Form.Group>
                  <div>
                    <Button
                      className="me-2 ms-2"
                      variant="success"
                      disabled={loading}
                      onClick={() => {
                        setCount(0);
                      }}
                    >
                      Atualizar
                    </Button>

                    <Button
                      className="me-2"
                      variant="primary"
                      disabled={loading}
                      onClick={() => {
                        IniciarTratativa();
                      }}
                    >
                      Proximo Atendimento
                    </Button>

                    <Button
                      className="me-2"
                      variant="warning"
                      disabled={loading}
                      onClick={() => {
                        sairDaFila();
                      }}
                    >
                      Sair
                    </Button>
                  </div>
                </Form.Group>
              </React.Fragment>
            )}

            <p>{texto}</p>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Body>
          <Listagem
            titulos={["Protocolo", "Contrato", "Cliente", "Telefones"]}
            itens={atendimentos || []}
            renderItem={(titulo, item, idx) => {
              const contrato = item.Contrato || {};
              const pessoa = contrato.Pessoa || {};

              switch (titulo) {
                case "Protocolo":
                  return item.id;
                case "Contrato":
                  return (
                    <a href={`/contratos/${contrato.id}`} target={`contrato_${contrato.id}`} title="Ir para o Contrato">
                      {contrato.contrato}
                    </a>
                  );
                case "Cliente":
                  return pessoa.nome;
                case "Telefones":
                  let telefones = [];
                  if (pessoa.telefone1) telefones.push(pessoa.telefone1);
                  if (pessoa.telefone2) telefones.push(pessoa.telefone2);
                  if (pessoa.telefone3) telefones.push(pessoa.telefone3);
                  if (pessoa.telefone4) telefones.push(pessoa.telefone4);
                  return telefones.join(" ");
                default:
                  return titulo;
              }
            }}
          />
        </Card.Body>

        <Card.Footer></Card.Footer>
      </Card>
    </Container>
  );
}
