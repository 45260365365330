import React from "react";
import Card from "react-bootstrap/Card";
import Chart from "react-apexcharts";
import IoContext from "../../contextos/IoContext";
import { ATENDIMENTO_EM_ABERTO, ATENDIMENTO_CONCLUIDO } from "../../datatypes/AtendiemntosTypes";
import { getInicioDia, getFinalDia } from "misc/lhdatas";

export default function CardAtendimentos() {
  const { socket } = React.useContext(IoContext);
  const [emAberto, setEmAberto] = React.useState(0);
  const [concluidos, setConcluidos] = React.useState(0);
  const [atrasados, setAtrasados] = React.useState(0);

  React.useEffect(() => {
    const d1 = getInicioDia();
    const d2 = getFinalDia();
    socket.emit(
      "Atendimento.count",
      {
        where: {
          situacao: ATENDIMENTO_EM_ABERTO,
          data_abertura_between: [d1.toISOString(), d2.toISOString()],
        },
      },
      (error, resp) => {
        if (error) {
          console.log("Atendimentos Em Aberto:", error);
        } else {
          setEmAberto(resp);
        }
      }
    );

    socket.emit(
      "Atendimento.count",
      {
        where: {
          situacao: ATENDIMENTO_EM_ABERTO,
          data_abertura_lt: d1.toISOString(),
        },
      },
      (error, resp) => {
        if (error) {
          console.log("Atendimentos Em Atrasso:", error);
        } else {
          setAtrasados(resp);
        }
      }
    );

    socket.emit(
      "Atendimento.count",
      {
        where: {
          situacao: ATENDIMENTO_CONCLUIDO,
          data_abertura_between: [d1.toISOString(), d2.toISOString()],
        },
      },
      (error, resp) => {
        if (error) {
          console.log("Atendimentos Em Aberto:", error);
        } else {
          setConcluidos(resp);
        }
      }
    );
  }, [socket]);

  const options = {
    title: {
      text: "Atendimentos",
      style: {
        fontSize: "18px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: "#263238",
      },
    },
    legend: {
      position: "bottom",
    },
    labels: ["Em Aberto", "Concluídos", "Atrasados"],
  };
  const series = [emAberto, concluidos, atrasados];

  return (
    <Card className="shadow rounded p-1">
      <Chart options={options} series={series} type="pie" />
    </Card>
  );
}
