import React from "react";
import { Routes, Route } from "react-router-dom";
import Cupons from "./Cupons";
import Cupom from "./Cupom";

export default function CuponsIndex() {
  const [texto, setTexto] = React.useState("");
  const [pagina, setPagina] = React.useState(1);

  return (
    <Routes>
      <Route path={`/:id`} element={<Cupom />} />
      <Route path={`/`} element={<Cupons texto={texto} setTexto={setTexto} pagina={pagina} setPagina={setPagina} />} />
    </Routes>
  );
}
