import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import { toast } from 'react-toastify';
import BotaoApagar from 'componentes/BotaoApagar';
import CabecalhoCadastro from 'componentes/CabecalhoCadastro';
import IoContext from 'contextos/IoContext';
import { useNavigate, useParams } from 'react-router';

export default function MaxiTvUpdate(){
    const params = useParams();
    const navigate = useNavigate();
    const { socket } = React.useContext(IoContext);
    const [loading, setLoading] = React.useState(false);
    const id = parseInt(params.id);
    const [nome, setNome] = React.useState('');
    const [versao, setVersao] = React.useState('');
    const [caminho, setCaminho] = React.useState('');
    const [arquivo, setArquivo] = React.useState('');
    
    React.useEffect(()=>{
        setLoading(true);
        
        socket.emit("MaxiTvUpdate.findOne", { 
            attributes: ['id', 'nome', 'versao', 'caminho'],
            where: { id } 
        }, (error, resp) => {
            setLoading(false);
            if(error) {
                return toast.error(error);
            }
            
            if(resp) {
                setNome(resp.nome);
                setVersao(resp.versao);
                setCaminho(resp.caminho);
                setArquivo(resp.arquivo);
            }
        });
    }, [id, socket]);
    return <Container fluid className="pt-3">
        <Card>
            <CabecalhoCadastro 
                id={id} disabled={loading} titulo='MaxiTvUpdate'
                salvar={ e => {
                    e.preventDefault();
                    setLoading(true);

                    try {
                        socket.emit("MaxiTvUpdate.salvar", { 
                            id, nome, versao, caminho, arquivo
                        }, (error, resp) => {
                            setLoading(false);
                            if(error) {
                                toast.error(error);
                            } else {
                                toast.success("MaxiTvUpdate salvo com Sucesso !");
                                navigate(-1);
                            }
                        });
                    } catch ( E) {
                        console.log("## SALVAR ERROR:", E);
                    }
                }}
                />

            <Card.Body>
                <Row>   
                    <Col sm={3}>
                        <Form.Group>
                            <Form.Label>Id</Form.Label>
                            <Form.Control  value={isNaN(id) ? "" : id} readOnly />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <Form.Group>
                            <Form.Label>Nome</Form.Label>
                            <Form.Control 
                                disabled={loading}
                                value={nome} 
                                autoFocus maxLength={100}
                                onChange={ e => setNome(e.target.value) }
                                />
                        </Form.Group>
                    </Col>

                    <Col sm={12}>
                        <Form.Group>
                            <Form.Label>Versão</Form.Label>
                            <Form.Control 
                                disabled={loading} value={versao} maxLength={255}
                                onChange={ e => setVersao(e.target.value) }
                                />
                        </Form.Group>
                    </Col>

                    <Col sm={12}>
                        <Form.Group>
                            <Form.Label>Caminho</Form.Label>
                            <Form.Control 
                                disabled={loading} value={caminho} maxLength={255}
                                onChange={ e => setCaminho(e.target.value) }
                                />
                        </Form.Group>
                    </Col>

                    <Col sm={12}>
                        <Form.Group>
                            <Form.Label>Arquivo</Form.Label>
                            <Form.Control type="file"
                                disabled={loading}
                                onChange={ e => {
                                    var reader = new FileReader();
                                    reader.onload = function(){
                                        const arq = Buffer.from(reader.result).toString('base64');
                                        setArquivo(arq);
                                    };
                                    reader.readAsArrayBuffer(e.target.files[0]);
                                }}
                                />
                        </Form.Group>
                    </Col>
                </Row>
            </Card.Body>

            <Card.Footer>
                { isNaN(id) ? false : <BotaoApagar 
                    disabled={loading}
                    apagar={ e => {
                        setLoading(true);
                        socket.emit("MaxiTvUpdate.apagar", { id }, (error,resp) => {
                            setLoading(false);
                            if(error) {
                                toast.error(error);
                            } else {
                                toast.success("MaxiTvUpdate removido com Sucesso !");
                                navigate(-1);
                            }
                        });
                    }}
                    /> }
            </Card.Footer>
        </Card>
    </Container>;
}