export const CONTRATO_SITUACOES = {
  0: "PENDENTE",
  1: "ATIVO",
  2: "CANCELADO",
  3: "SUSPENSO",
  4: "DESATIVADO",
};

export const CONTRATO_SITUACOES_VARIANTES = {
  0: "bg-info",
  1: "bg-primary",
  2: "bg-danger",
  3: "bg-warning",
  4: "bg-warning",
};

export const CONTRATO_PENDENTE = 0;
export const CONTRATO_ATIVO = 1;
export const CONTRATO_CANCELADO = 2;
export const CONTRATO_SUSPENSO = 3;
export const CONTRATO_DESATIVADO = 4;

export const SERVICOS_CONTRATADOS_SITUACOES = {
  BLOQUEADO: "BLOQUEADO",
  ATIVO: "ATIVO",
  CANCELADO: "CANCELADO",
  PENDENTE: "PENDENTE",
  SUSPENSO: "SUSPENSO",
  DESATIVADO: "DESATIVADO",
};

export const SERVICO_BLOQUEADO = "BLOQUEADO";
export const SERVICO_ATIVO = "ATIVO";
export const SERVICO_CANCELADO = "CANCELADO";
export const SERVICO_PENDENTE = "PENDENTE";
export const SERVICO_SUSPENSO = "SUSPENSO";
export const SERVICO_DESATIVADO = "DESATIVADO";

export const CONTRATO_CAMPOS_PESQUISA = [
  "Cliente ou Contrato",
  "Email",
  "Apelido",
  "Cpf / Cnpj",
  "Website",
  "Telefones",
  "Mac",
  "IPv4",
  "IPv6",
  "Usuário",
  "Gpon ONU",
  "Epon ONU",
  "Ramal Telefônico",
  "Protocolo de Atendimento",
];

export const CONTRATO_CAMPO_PESQUISA_CLIENTE_CONTRATO = "Cliente ou Contrato";
export const CONTRATO_CAMPO_PESQUISA_EMAIL = "Email";
export const CONTRATO_CAMPO_PESQUISA_APELIDO = "Apelido";
export const CONTRATO_CAMPO_PESQUISA_CPF_CNPJ = "Cpf / Cnpj";
export const CONTRATO_CAMPO_PESQUISA_WEBSITE = "Website";
export const CONTRATO_CAMPO_PESQUISA_TELEFONES = "Telefones";
export const CONTRATO_CAMPO_PESQUISA_MAC = "Mac";
export const CONTRATO_CAMPO_PESQUISA_IPV4 = "IPv4";
export const CONTRATO_CAMPO_PESQUISA_IPV6 = "IPv6";
export const CONTRATO_CAMPO_PESQUISA_USUARIO = "Usuário";
export const CONTRATO_CAMPO_PESQUISA_GPON_ONU = "Gpon ONU";
export const CONTRATO_CAMPO_PESQUISA_EPON_ONU = "Epon ONU";
export const CONTRATO_CAMPO_PESQUISA_RAMAL = "Ramal Telefônico";
export const CONTRATO_CAMPO_PESQUISA_IMPORTACAO_ID = "Importação Id";
export const CONTRATO_CAMPO_PESQUISA_PROTOCOLO_ATENDIMENTO = "Protocolo de Atendimento";

export const CONTRATO_VOIP_SITUACOES = {
  0: "Aguardando Liberação",
  5: "Aguardando Alteração",
  6: "Aguardando Confirmar DDD",
  7: "Aguardando Pagamento",
  8: "Aguardando Solicitação de Portabilidade",
  9: "Aguardando Confirmação de Portabilidade",
  10: "Liberado",
  15: "DDD Confirmado",
  20: "Portabilidade Solicitada",
  49: "Error",
  50: "Aguardando Remoção",
};

export const CONTRATO_VOIP_SITUACAO_AGUARDANDO_LIBERACAO = 0;
export const CONTRATO_VOIP_SITUACAO_AGUARDANDO_ALTERACAO = 5;
export const CONTRATO_VOIP_SITUACAO_AGUARDANDO_CONFIRM_DDD = 6;
export const CONTRATO_VOIP_SITUACAO_AGUARDANDO_PAGAMENTO = 7;
export const CONTRATO_VOIP_SITUACAO_AGUARDANDO_SOLICITACAO_PORTABILIDADE = 8;
export const CONTRATO_VOIP_SITUACAO_AGUARDANDO_CONFIRMACAO_PORTABILIDADE = 9;
export const CONTRATO_VOIP_SITUACAO_LIBERADO = 10;
export const CONTRATO_VOIP_SITUACAO_DDD_CONFIRMADO = 15;
export const CONTRATO_VOIP_SITUACAO_PORTABILIDADE_SOLICITADA = 20;
export const CONTRATO_VOIP_SITUACAO_ERROR = 49;
export const CONTRATO_VOIP_SITUACAO_AGUARDANDO_REMOCAO = 50;
