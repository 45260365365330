import { CONTRATO_CAMPO_PESQUISA_CLIENTE_CONTRATO } from "datatypes/ContratoTypes";
import React from "react";
import { Routes, Route } from "react-router-dom";
import Contrato from "./Contrato";

import Contratos from "./Contratos";

export default function IndexContratos() {
  const [texto, setTexto] = React.useState("");
  const [campoPesquisa, setCampoPesquisa] = React.useState(CONTRATO_CAMPO_PESQUISA_CLIENTE_CONTRATO);
  const [pagina, setPagina] = React.useState(1);
  const [FilialId, setFilialId] = React.useState(0);
  const [situacao, setSituacao] = React.useState("");
  const [uf, setUf] = React.useState("");
  const [cidade, setCidade] = React.useState("");
  const [bairro, setBairro] = React.useState("");
  const [logradouro, setLograroudo] = React.useState("");

  return (
    <Routes>
      <Route
        path={`/`}
        element={
          <Contratos
            texto={texto}
            setTexto={setTexto}
            campoPesquisa={campoPesquisa}
            setCampoPesquisa={setCampoPesquisa}
            pagina={pagina}
            setPagina={setPagina}
            situacao={situacao}
            setSituacao={setSituacao}
            FilialId={FilialId}
            setFilialId={setFilialId}
            uf={uf}
            setUf={setUf}
            cidade={cidade}
            setCidade={setCidade}
            bairro={bairro}
            setBairro={setBairro}
            logradouro={logradouro}
            setLograroudo={setLograroudo}
          />
        }
      />
      <Route path={`/:contrato_id`} element={<Contrato />} />
    </Routes>
  );
}
