import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import IoContext from "contextos/IoContext";
import CabecalhoCadastro from "componentes/CabecalhoCadastro";
import Listagem from "componentes/Listagem";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function BloqueioJudicialCsvImport() {
  const navigate = useNavigate();
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(false);
  const [titulos, setTitulos] = React.useState([]);
  const [linhas, setLinhas] = React.useState([]);
  const [idxColuna, setIdxColuna] = React.useState(-1);

  return (
    <Container fluid className="pt-3">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setLoading(true);
          socket.emit(
            "DnsBloqueioJudicial.salvarBulk",
            {
              dominios: linhas.map((linha) => ({
                dominio: linha[idxColuna],
              })),
            },
            (error, resp) => {
              setLoading(false);
              if (error) {
                toast.error(error);
              } else {
                toast.success("Domínio salvo com Sucesso !");
                navigate(-1);
              }
            }
          );
        }}
      >
        <Card>
          <CabecalhoCadastro
            id={""}
            disabled={loading}
            titulo="Domínio para Bloqueio Judicial - Importação de Arquivo CSV"
          />

          <Card.Body>
            <Row>
              <Col sm={9}>
                <Form.Group>
                  <Form.Label>Selecione o Arquivo</Form.Label>
                  <Form.Control
                    type="file"
                    accept=".csv,.CSV"
                    disabled={loading}
                    onChange={(e) => {
                      setTitulos([]);
                      setLinhas([]);
                      var reader = new FileReader();
                      reader.onload = function () {
                        try {
                          const linhas = reader.result
                            .toString()
                            .trim()
                            .split("\n")
                            .filter((l) => !!l);
                          setTitulos(linhas[0].split(","));
                          setLinhas(linhas.splice(1).map((l) => l.split(",")));
                        } catch (error) {
                          toast.error("Falha ao Ler Arquivo CSV:" + error);
                        }
                      };
                      reader.readAsText(e.target.files[0]);
                    }}
                  />
                </Form.Group>
              </Col>

              <Col sm={3}>
                <Form.Label>Coluna Para Importar</Form.Label>
                <Form.Control
                  as="select"
                  disabled={loading}
                  required
                  value={idxColuna}
                  onChange={(e) => setIdxColuna(Number(e.target.value))}
                >
                  <option></option>
                  {(titulos || []).map((titulo, idx) => {
                    return <option value={idx}>{titulo}</option>;
                  })}
                </Form.Control>
              </Col>
            </Row>
          </Card.Body>

          <Listagem
            titulos={titulos}
            itens={linhas}
            tamanhos={[{ sm: 3 }]}
            renderItem={(titulo, item, idx) => {
              switch (titulo) {
                default:
                  return item[titulos.indexOf(titulo)];
              }
            }}
            onClick={(_item) => {}}
          />

          <Card.Footer></Card.Footer>
        </Card>
      </Form>
    </Container>
  );
}
