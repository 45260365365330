import React from 'react';
import Form from 'react-bootstrap/Form';
import { SituacoesAtendimento } from 'datatypes/AtendiemntosTypes';

export default function SelectSituacaoAtendimento(props){
    const { id, value, onChange, disabled, label } = props; 

    return <Form.Group>
        <Form.Label>{label || "Situação"}</Form.Label>
        <Form.Control as="select" 
            id={id || "situacao"} 
            onChange={onChange}
            value={value}
            disabled={disabled}
            {...props}
        >
            <option value="">Todos</option>
            { Object.keys(SituacoesAtendimento).map( k => {
                return <option key={k} value={k}>
                    {SituacoesAtendimento[k]}
                </option>
            })}
        </Form.Control>
    </Form.Group>
}

