export function getInicioDia({ ano, mes, dia } = {}) {
  const d1 = new Date();

  if (dia) d1.setDate(dia);
  if (mes) d1.setMonth(mes - 1);
  if (ano) d1.setFullYear(ano);

  d1.setHours(0);
  d1.setMinutes(0);
  d1.setSeconds(0);
  return d1;
}

export function getFinalDia({ ano, mes, dia } = {}) {
  const d1 = new Date();

  if (dia) d1.setDate(dia);
  if (mes) d1.setMonth(mes - 1);
  if (ano) d1.setFullYear(ano);

  d1.setHours(23);
  d1.setMinutes(59);
  d1.setSeconds(59);
  return d1;
}

export function getInicioMes({ ano, mes } = {}) {
  const d = new Date();

  d.setDate(1);
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);

  if (ano) d.setFullYear(ano);
  if (mes) d.setMonth(mes - 1);

  return d;
}

export function getFinalMes({ ano, mes } = {}) {
  const d1 = new Date();
  d1.setDate(1);
  if (ano) d1.setFullYear(ano);
  if (mes) d1.setMonth(+mes - 1);

  const d2 = new Date(d1.getFullYear(), d1.getMonth() + 1, 0);

  d2.setHours(23);
  d2.setMinutes(59);
  d2.setSeconds(59);

  return d2;
}

export function getInicioAno({ ano } = {}) {
  const d = new Date();

  if (ano) d.setFullYear(ano);

  d.setMonth(0);
  d.setDate(1);
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  return d;
}

export function getFinalAno({ ano } = {}) {
  const d = new Date();
  if (ano) d.setFullYear(ano);

  d.setMonth(11);
  d.setDate(31);
  d.setHours(23);
  d.setMinutes(59);
  d.setSeconds(59);
  return d;
}

export function formatarDataDbs(dt) {
  let txt = dt.getFullYear();
  txt += "-" + (dt.getMonth() + 1 + "").padStart(2, "0");
  txt += "-" + (dt.getDate() + "").padStart(2, "0");

  return txt;
}

export function formatarDataHoraDbs(dt) {
  let txt = dt.getFullYear();
  txt += "-" + (dt.getMonth() + 1 + "").padStart(2, "0");
  txt += "-" + (dt.getDate() + "").padStart(2, "0");

  txt += "T" + (dt.getHours() + "").padStart(2, "0");
  txt += ":" + (dt.getMinutes() + "").padStart(2, "0");
  txt += ":" + (dt.getSeconds() + "").padStart(2, "0");
  txt += "Z";

  return txt;
}

export function getNomeMes(mes) {
  let d = mes;

  if (!(mes instanceof Date)) {
    d = new Date();
    d.setMonth(mes);
  }

  return d.toLocaleString("default", { month: "long" }).toUpperCase();
}
