import React from "react";
import { useNavigate } from "react-router";

export default function FonteDadosCsv({ setFonteDados }) {
  const navigate = useNavigate();

  React.useEffect(() => {
    setFonteDados({ tipo: "csv" });
    navigate("../modulos");
  });

  return <div>Aguarde, carregando fonte de dados csv.</div>;
}
