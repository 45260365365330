import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Container from "react-bootstrap/Container";
import IoContext from "../../../../contextos/IoContext";
import SelectFiliais from "../../../cadastros/administrativo/filiais/SelectFiliais";
import Chart from "react-apexcharts";
import { getFinalMes, getInicioMes } from "misc/lhdatas";
import { toast } from "react-toastify";
import BigNumber from "bignumber.js";
import { DateTime } from "luxon";

export default function RelAquizicoes() {
  const { socket } = React.useContext(IoContext);
  const [contador, setContador] = React.useState(0);
  const [dini, setDini] = React.useState(getInicioMes().toISOString().split("T")[0]);
  const [dend, setDend] = React.useState(getFinalMes().toISOString().split("T")[0]);
  const [loading, setLoading] = React.useState(false);
  const [FilialId, setFilialId] = React.useState(0);
  const [itens, setItens] = React.useState([]);
  const [totalPendentes, setTotalPendentes] = React.useState(0);
  const [totalAtivos, setTotalAtivos] = React.useState(0);
  const [totalDesativados, setTotalDesativados] = React.useState(0);
  const [totalCancelados, setTotalCancelados] = React.useState(0);
  const [totalOutros, setTotalOutros] = React.useState(0);
  const [filiais, setFiliais] = React.useState([]);
  const [pendentes, setPendentes] = React.useState([]);
  const [convertidos, setConvertidos] = React.useState([]);
  const [desistencias, setDesistencias] = React.useState([]);
  const [cancelados, setCancelados] = React.useState([]);
  const [outros, setOutros] = React.useState([]);
  const [valorTotalAtivos, setValorTotalAtivos] = React.useState(BigNumber(0));
  const [valorTotalDesativados, setValorTotalDesativados] = React.useState(BigNumber(0));
  const [valorTotalCancelados, setValorTotalCancelados] = React.useState(BigNumber(0));
  const [valorTotalPendentes, setValorTotalPendentes] = React.useState(BigNumber(0));

  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  React.useEffect(() => {
    setItens([]);
    setTotalPendentes(0);
    setTotalAtivos(0);
    setTotalDesativados(0);
    setTotalCancelados(0);
    setTotalOutros(0);
    setLoading(true);

    let whereContrato = {};
    if (FilialId) whereContrato.FilialId = FilialId;

    socket.emit(
      "HistoricoServicoContratado.findAll",
      {
        where: {
          createdAt_between: [dini, dend],
          situacao: "CADASTRO",
        },
        withServicoContratado: {
          required: true,
          attributes: ["situacao", "valor"],
          withPlano: {
            attributes: ["nome"],
          },
          withContrato: {
            attributes: ["numero_contrato"],
            where: whereContrato,
            withPessoa: {
              attributes: ["nome", "telefone1"],
            },
            withFilial: {
              attributes: ["id", "nome"],
            },
          },
        },
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          toast.error("" + error);
          console.log("## ERROR:", error);
          return;
        }
        setItens(resp);

        let countPendentes = 0;
        let countAtivos = 0;
        let countDesativados = 0;
        let countCancelados = 0;
        let countOutros = 0;
        let filiais = {};
        let pendentes = {};
        let convertidos = {};
        let desistencias = {};
        let cancelados = {};
        let outros = {};
        let valorTotalAtivos = BigNumber(0);
        let valorTotalCancelados = BigNumber(0);
        let valorTotalDesativados = BigNumber(0);
        let valorTotalPendentes = BigNumber(0);

        (resp || []).forEach((hist) => {
          const serc = hist.ServicoContratado || {};
          const contrato = serc.Contrato || {};
          const filial = contrato.Filial || {};

          filiais[filial.id] = filial.nome;
          if (!pendentes[filial.id]) pendentes[filial.id] = 0;
          if (!convertidos[filial.id]) convertidos[filial.id] = 0;
          if (!desistencias[filial.id]) desistencias[filial.id] = 0;
          if (!cancelados[filial.id]) cancelados[filial.id] = 0;
          if (!outros[filial.id]) outros[filial.id] = 0;

          switch (serc.situacao) {
            case "PENDENTE":
              countPendentes++;
              pendentes[filial.id]++;
              valorTotalPendentes = valorTotalPendentes.plus(serc.valor);
              break;
            case "CANCELADO":
              countCancelados++;
              cancelados[filial.id]++;
              valorTotalCancelados = valorTotalCancelados.plus(serc.valor);
              break;
            case "DESATIVADO":
              countDesativados++;
              desistencias[filial.id]++;
              valorTotalDesativados = valorTotalDesativados.plus(serc.valor);
              break;
            case "ATIVO":
              countAtivos++;
              convertidos[filial.id]++;
              valorTotalAtivos = valorTotalAtivos.plus(serc.valor);
              break;
            default:
              countOutros++;
              outros[filial.id]++;
          }
        });

        setTotalPendentes(countPendentes);
        setTotalAtivos(countAtivos);
        setTotalDesativados(countDesativados);
        setTotalCancelados(countCancelados);
        setTotalOutros(countOutros);
        setFiliais(Object.values(filiais)); // Nomes das Filiais para o Gráfico stacked

        setValorTotalAtivos(valorTotalAtivos);
        setValorTotalCancelados(valorTotalCancelados);
        setValorTotalPendentes(valorTotalPendentes);
        setValorTotalDesativados(valorTotalDesativados);

        setPendentes(Object.values(pendentes));
        setConvertidos(Object.values(convertidos));
        setDesistencias(Object.values(desistencias));
        setCancelados(Object.values(cancelados));
        setOutros(Object.values(outros));
      }
    );
  }, [socket, dini, dend, FilialId, contador]);

  return (
    <Container fluid>
      <Card className="shadow">
        <Card.Header className="bg-primary text-light">
          <h3 className="float-start">Relatório de Aquisições</h3>
        </Card.Header>

        <Card.Body className="d-print-none">
          <Row>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>Período</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="date"
                    value={dini}
                    placeholder="Inicial"
                    disabled={loading}
                    onChange={(e) => setDini(e.target.value)}
                  />
                  <Form.Control
                    type="date"
                    value={dend}
                    placeholder="Final"
                    disabled={loading}
                    onChange={(e) => setDend(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Filial:</Form.Label>
                <SelectFiliais
                  value={FilialId}
                  onChange={(e) => {
                    setFilialId(e.target.value);
                  }}
                  disabled={loading}
                />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    variant="secondary"
                    title="Listar"
                    className="me-2"
                    onClick={() => setContador(contador + 1)}
                    disabled={loading}
                  >
                    Atualizar
                  </Button>

                  <Button title="Listar" className="me-2" disabled={loading} onClick={() => window.print()}>
                    Imprimir
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Row>
        <Col sm={12} className="mt-2">
          <Card className="shadow rounded p-1 mt-2">
            <Chart
              options={{
                title: {
                  text: `Aquisições [${(itens || []).length}]`,
                },
                colors: ["#33CEFF", "#0CAE0A", "#FFA533", "#FF3333", "#CDD7B6"],
                dataLabels: {
                  enable: true,
                  style: {
                    fontSize: "12px",
                  },
                  formatter: function (text, op) {
                    return [text, op.value];
                  },
                  offsetY: -4,
                },
                plotOptions: {
                  treemap: {
                    distributed: true,
                    enableShades: false,
                  },
                },
              }}
              series={[
                {
                  data: [
                    {
                      x: "Pendentes",
                      y: totalPendentes,
                    },
                    {
                      x: "Convertidos",
                      y: totalAtivos,
                    },
                    {
                      x: "Desistências",
                      y: totalDesativados,
                    },
                    {
                      x: "Cancelados",
                      y: totalCancelados,
                    },
                    {
                      x: "Outros",
                      y: totalOutros,
                    },
                  ],
                },
              ]}
              type="treemap"
              height="400"
            />
          </Card>
        </Col>

        <Col sm={12} className={`mt-2 ${FilialId ? "d-none" : ""}`}>
          <Card className="shadow rounded p-1 mt-2">
            <Chart
              type="bar"
              height="400"
              options={{
                title: {
                  text: `Aquisições por Filial [${(itens || []).length}]`,
                },
                chart: {
                  type: "bar",
                  stacked: true,
                },
                responsive: [
                  {
                    breakpoint: 480,
                    options: {
                      legend: {
                        position: "bottom",
                        offsetX: -10,
                        offsetY: 0,
                      },
                    },
                  },
                ],
                plotOptions: {
                  bar: {
                    horizontal: false,
                    borderRadius: 10,
                  },
                },
                xaxis: {
                  type: "string",
                  categories: filiais,
                },
                legend: {
                  position: "right",
                  offsetY: 40,
                },
                fill: {
                  opacity: 1,
                },
              }}
              series={[
                {
                  name: "Pendentes",
                  data: pendentes,
                },
                {
                  name: "Convertidos",
                  data: convertidos,
                },
                {
                  name: "Desistências",
                  data: desistencias,
                },
                {
                  name: "Cancelados",
                  data: cancelados,
                },
                {
                  name: "Outros",
                  data: outros,
                },
              ]}
            />
          </Card>
        </Col>
      </Row>

      <Card className="mt-2">
        <Table responsive size="sm">
          <tbody>
            <tr>
              <th>Filial</th>
              <th>Contrato</th>
              <th>Cliente</th>
              <th>Plano</th>
              <th>Telefone</th>
              <th>Data de Aquisição</th>
              <th>Situação Atual</th>
            </tr>
            {(itens || []).map((hist) => {
              const serc = hist.ServicoContratado || {};
              const contrato = serc.Contrato || {};
              const plano = serc.Plano || {};
              const filial = contrato.Filial || {};
              const pessoa = contrato.Pessoa || {};

              let cor = "";
              switch (serc.situacao) {
                case "PENDENTE":
                  cor = "";
                  break;
                case "CANCELADO":
                  cor = "bg-danger";
                  break;
                case "DESATIVADO":
                  cor = "bg-warning";
                  break;
                case "ATIVO":
                  cor = "bg-success";
                  break;
                default:
                  cor = "";
              }

              return (
                <tr className={`${cor}`} key={hist.id}>
                  <td>{filial.nome || "----"}</td>
                  <td>{contrato.numero_contrato || "----"}</td>
                  <td>{pessoa.nome || "----"}</td>
                  <td>{plano.nome || "----"}</td>
                  <td>{pessoa.telefone1 || "----"}</td>
                  <td>{DateTime.fromISO(hist.createdAt).toLocaleString(DateTime.DATE_SHORT)}</td>
                  <td>{serc.situacao}</td>
                </tr>
              );
            })}
          </tbody>

          <tfoot>
            <tr>
              <td colSpan={100} className="text-right">
                {totalPendentes} Aquisições Pendentes, Total: {formatter.format(valorTotalPendentes.toNumber())}, Média:{" "}
                {formatter.format(totalPendentes > 0 ? valorTotalPendentes.dividedBy(totalPendentes).toNumber() : 0)}
              </td>
            </tr>
            <tr>
              <td colSpan={100} className="text-right">
                {totalDesativados} Desistências, Total: {formatter.format(valorTotalDesativados.toNumber())}, Média:{" "}
                {formatter.format(
                  totalDesativados > 0 ? valorTotalDesativados.dividedBy(totalDesativados).toNumber() : 0
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={100} className="text-right">
                {totalCancelados} Cancelamentos, Total: {formatter.format(valorTotalCancelados.toNumber())}, Média:{" "}
                {formatter.format(totalCancelados > 0 ? valorTotalCancelados.dividedBy(totalCancelados).toNumber() : 0)}
              </td>
            </tr>
            <tr>
              <td colSpan={100} className="text-right">
                {totalAtivos} Aquisições Convertidas, Total: {formatter.format(valorTotalAtivos.toNumber())}, Média:{" "}
                {formatter.format(totalAtivos > 0 ? valorTotalAtivos.dividedBy(totalAtivos).toNumber() : 0)}
              </td>
            </tr>
            <tr>
              <td colSpan={100} className="text-right">
                {totalOutros} Outras Situações
              </td>
            </tr>
          </tfoot>
        </Table>
      </Card>
    </Container>
  );
}
