import React, { useMemo } from "react";
import Chart from "react-apexcharts";

export const GraficoPrincipal = (props) => {
  let { data, filtro, title } = props;

  let series = useMemo(() => GerarGrafico(data), [data]);
  let labels = useMemo(() => GerarLabels(data), [data]);

  if (!data) return <></>;

  const chartOptions = {
    title: {
      text: filtro ? `${title} : ${filtro}` : title,
    },
    chart: {
      toolbar: {
        show: false,
      },
      type: "bar",
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    legend: {
      position: "top",
      showForSingleSeries: true,
      showForNullSeries: true,
      showForZeroSeries: true,
      horizontalAlign: "left",
      offsetX: 40,
    },
  };

  return (
    <Chart
      options={{ ...chartOptions, labels: labels }}
      series={filtro ? [series.find((i) => i.name === filtro) || []] : series}
      type={"bar"}
      height={"80%"}
      width={"100%"}
    />
  );
};

const GerarLabels = (data) => {
  let ret = [];

  Object.entries(data || [])
    .sort((a, b) => {
      if (a[1].total === b[1].total) return 0;
      return a[1].total < b[1].total ? 1 : -1;
    })
    .forEach((plano) => {
      ret.push(plano[0]);
    });

  return ret;
};

const GerarGrafico = (data) => {
  let ret = [];

  Object.entries(data || [])
    // .sort((a, b) => {
    //   if (a[1].total === b[1].total) return 0;
    //   return a[1].total < b[1].total ? 1 : -1;
    // })
    .forEach((plano, indexPlano) => {
      if (!plano[1]) return;

      Object.entries(plano[1]).forEach((atendente) => {
        let found = ret.findIndex((item) => item.name === atendente[0]);
        /* 
        atendente[0] = nome do atendente.
        atendente[1] = contrato do atendente no plano atual.
      */
        if (found >= 0) {
          /*
         O Array Garante que o valor vai ser adicionado no lugar correto. 
         */
          let diff = new Array(indexPlano - ret[found].data.length).fill(0);
          ret[found].data.push(...diff);
          ret[found].data.push(atendente[1]);
        } else {
          /* 
            O Array no inicio Garante que o valor vai ser adicionado no lugar correto.
            Mesmo se o atendente não estiver presente nos planos anteriores.
        */
          ret.push({
            name: atendente[0],
            data: [...new Array(indexPlano).fill(0), atendente[1]],
          });
        }
      });
    });

  return ret;
};

export const GraficoSecundario = ({ data, filtro = null, title }) => {
  const series = useMemo(() => GerarSeriesSecundario(data), [data]);
  const labels = useMemo(() => GerarLabelsSecundario(data), [data]);

  if (!data) return <></>;

  const chartOptions = {
    title: {
      text: filtro ? `${title} : ${filtro}` : title,
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
  };

  if (!data[filtro] && filtro) return <>Atendente Não Encontrado</>;

  return (
    <Chart
      options={{
        ...chartOptions,
        labels: filtro ? [filtro] : labels,
      }}
      series={filtro && data[filtro] ? [{ data: [data[filtro]] }] : series}
      type={"bar"}
      height={"80%"}
      width={"100%"}
    />
  );
};

const GerarSeriesSecundario = (data) => {
  let series = [
    {
      data: [],
    },
  ];
  Object.entries(data || []).forEach((e) => {
    if (!e) return;
    series[0].data.push(e[1]);
  });

  return series.sort();
};

const GerarLabelsSecundario = (data) => {
  let labels = [];
  Object.entries(data || []).forEach((e) => {
    if (!e) return;
    labels.push(e[0]);
  });
  return labels;
};
