import React from "react";
import Container from "react-bootstrap/Container";
import TituloContext from "contextos/TituloContext";
import IoContext from "contextos/IoContext";
import { toast } from "react-toastify";
import { Card, Row, Form, Col, Table, Button } from "react-bootstrap";
import BotaoApagar from "componentes/BotaoApagar";

export const modulo = "TcIRR";

export default function TcIRR() {
  const { setTitulo } = React.useContext(TituloContext);
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(false);
  const [detalhes, setDetalhes] = React.useState("");
  const [asn, setAsn] = React.useState("");
  const [descricao, setDescricao] = React.useState("");
  const [linhas, setLinhas] = React.useState({});
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    setTitulo("TC IRR - bgp.net.br");
  });

  React.useEffect(() => {
    setLoading(true);
    setLinhas({});

    socket.emit("TcIRR.consultar", {}, (error, resp) => {
      setLoading(false);
      if (error) {
        console.log("ERROR:", error);
        return toast.error("" + error);
      }

      setDetalhes(`${resp.asn} - ${resp.asSet}`);
      setLinhas(resp.linhas);
      console.log("Resp: ", resp);
    });
  }, [socket, count]);

  return (
    <Container fluid className="mt-2">
      <Card>
        <Card.Header className="bg-primary text-light">
          <Card.Title>TC IRR - {detalhes}</Card.Title>
        </Card.Header>

        <Card.Body>
          <Row>
            <Col sm={2}>
              <Form.Group>
                <Form.Label>ASN</Form.Label>
                <Form.Control
                  disabled={loading}
                  value={asn}
                  onChange={(e) => setAsn(e.target.value)}
                  type="number"
                  step={1}
                  min={1}
                />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>Descrição</Form.Label>
                <Form.Control disabled={loading} value={descricao} onChange={(e) => setDescricao(e.target.value)} />
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    disabled={loading}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!asn || !descricao) {
                        return toast.error("Informe ASN e Descrição!");
                      }

                      setLoading(true);
                      setLinhas({});

                      socket.emit("TcIRR.adicionar", { asn: `AS${asn}`, descricao }, (error, resp) => {
                        setLoading(false);
                        if (error) {
                          toast.error("" + error);
                        } else {
                          toast.success("ASN Adicionado com sucesso! " + resp);
                        }

                        setCount(count + 1);
                      });
                    }}
                  >
                    Add
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Table className="mt-2" hover striped responsive>
            <thead>
              <tr>
                <th>ASN</th>
                <th>Descrição</th>
                <th>Ações</th>
              </tr>
              {Object.values(linhas).map((linha) => {
                return (
                  <tr>
                    <td>{linha.asn}</td>
                    <td>{linha.descricao}</td>
                    <td>
                      <BotaoApagar
                        disabled={loading}
                        apagar={() => {
                          setLoading(true);
                          setLinhas({});

                          socket.emit("TcIRR.apagar", { asn: linha.asn }, (error, resp) => {
                            setLoading(false);
                            if (error) {
                              toast.error("" + error);
                            } else {
                              toast.success("ASN removido com sucesso! " + resp);
                            }

                            setCount(count + 1);
                          });
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </thead>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  );
}
