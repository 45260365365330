import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import { toast } from 'react-toastify';
import BotaoApagar from '../../componentes/BotaoApagar';
import CabecalhoCadastro from '../../componentes/CabecalhoCadastro';
import IoContext from '../../contextos/IoContext';
import { useNavigate, useParams } from 'react-router';

export default function BannerMobile(){
    const params = useParams();
    const navigate = useNavigate();
    const { socket } = React.useContext(IoContext);
    const id = parseInt(params.id);
    const [descricao, setDescricao] = React.useState('');
    const [imagemUrl, setImagemUrl] = React.useState('');
    const [linkTo, setLinkTo] = React.useState('');
    const [validoAte, setValidoAte] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    
    React.useEffect(()=>{
        socket.emit("BannerMobile.findOne", {
            where: { id }
        }, (error, resp) => {
            if(error){
                toast.error(error);
            } else {
                setDescricao(resp.descricao);
                setImagemUrl(resp.imagemUrl);
                setLinkTo(resp.linkTo);
                setValidoAte((new Date(resp.validoAte)).toISOString().split("T")[0]);
            }
        });
    }, [socket, id]);

    return <Container fluid className="pt-3">
        <Card>
            <CabecalhoCadastro 
                id={id} disabled={loading} titulo='Banner Mobile'
                salvar={ e => {
                    e.preventDefault();

                    if(!descricao) return toast.warning('Descrição é obrigatória');
                    if(new Date(validoAte).toString() === 'Invalid Date') return toast.warning('Validade é obrigatória');

                    setLoading(true);
                    socket.emit("BannerMobile.salvar", {
                        id, descricao,imagemUrl,linkTo, validoAte
                    }, (error) => {
                        setLoading(false);
                        if(error){
                            toast.error(error);
                        } else {
                            toast.success("Endereço Salvo com Sucesso !");
                            navigate(-1);
                        }
                    });
                }}
                />

            <Card.Body>
                <Row>   
                    <Col sm={3}>
                        <Form.Group>
                            <Form.Label>Id</Form.Label>
                            <Form.Control  value={isNaN(id) ? "" : id} readOnly />
                        </Form.Group>
                    </Col>

                    <Col sm={3}>
                        <Form.Group>
                            <Form.Label>Válido Até</Form.Label>
                            <Form.Control 
                                type="date"
                                disabled={loading}
                                value={validoAte} 
                                autoFocus required
                                maxLength={100}
                                onChange={ e => {
                                    console.log("V:", e.target.value);
                                    setValidoAte(e.target.value) 
                                }}
                                />
                        </Form.Group>
                    </Col>

                    <Col sm={6}>
                        <Form.Group>
                            <Form.Label>Descrição</Form.Label>
                            <Form.Control 
                                disabled={loading}
                                value={descricao} 
                                autoFocus required
                                maxLength={100}
                                onChange={ e => setDescricao(e.target.value) }
                                />
                        </Form.Group>
                    </Col>

                    <Col sm={12}>
                        <Form.Group>
                            <Form.Label>Url da Imagem Promocional:</Form.Label>
                            <Form.Control 
                                disabled={loading}
                                value={imagemUrl}
                                maxLength={255}
                                onChange={ e => setImagemUrl(e.target.value) }
                                />
                        </Form.Group>
                    </Col>

                    <Col sm={12}>
                        <Form.Group>
                            <Form.Label>Link da Ação Promocional:</Form.Label>
                            <Form.Control 
                                disabled={loading}
                                value={linkTo}
                                onChange={ e => setLinkTo(e.target.value) }
                                />
                        </Form.Group>
                    </Col>
                </Row>
            </Card.Body>

            <Card.Footer>
                { isNaN(id) ? false : <BotaoApagar 
                    disabled={loading}
                    apagar={ e => {
                        setLoading(true);
                        socket.emit("BannerMobile.apagar", { id }, (error, resp) => {
                            setLoading(false);
                            if(error){
                                toast.error(error);
                            } else {
                                toast.success("Banner Removido com Sucesso !");
                                navigate(-1);
                            }
                        });
                    }}
                    /> }
            </Card.Footer>
        </Card>
    </Container>;
}