import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import SelectMes from "../../../componentes/SelectMes";
import CardNotasFiscaisEmitidas from "./CardNotasFiscaisEmitidas";
import CardNotasFiscaisPagas from "./CardNotasFiscaisPagas";
import CardListNotasFiscais from "./CardListNotasFiscais";
import SelectFiliais from "../../cadastros/administrativo/filiais/SelectFiliais";
import SelectTipoNotaFiscal from "paginas/financeiro/notafiscal/SelectTipoNotaFiscal";

export default function RelatorioFiscalRegimeCaixa() {
  const [FilialId, setFilialId] = React.useState("");
  const [mes, setMes] = React.useState(new Date().getMonth() + 1);
  const [ano, setAno] = React.useState(new Date().getFullYear());
  const [tipo, setTipo] = React.useState("");
  const [contador, setContador] = React.useState(0);
  const [loading1, setLoading1] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [loading3, setLoading3] = React.useState(false);
  const [loading4, setLoading4] = React.useState(false);
  const [loading5, setLoading5] = React.useState(false);

  const disabled = loading1 || loading2 || loading3 || loading4 || loading5;
  return (
    <Container fluid>
      <Card className="shadow">
        <Card.Header className="bg-primary">
          <Card.Title className="text-light">
            <h3 className="float-start">
              Relatório Fiscal por Regime de Caixa
            </h3>
          </Card.Title>
        </Card.Header>

        <Card.Body className="d-print-none">
          <Row>
            <Col sm={2}>
              <Form.Label>Filial</Form.Label>
              <SelectFiliais
                value={FilialId}
                disabled={disabled}
                onChange={(e) => setFilialId(e.target.value)}
              />
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Mês</Form.Label>
                <SelectMes
                  value={mes}
                  disabled={disabled}
                  onChange={(e) => setMes(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Ano</Form.Label>
                <Form.Control
                  type="number"
                  value={ano}
                  disabled={disabled}
                  onChange={(e) => setAno(+e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Tipo</Form.Label>
                <SelectTipoNotaFiscal
                  value={tipo}
                  disabled={disabled}
                  onChange={(e) => setTipo(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={4}></Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    variant="secondary"
                    title="Listar"
                    className="me-2"
                    disabled={disabled}
                    onClick={() => setContador(contador + 1)}
                  >
                    Atualizar
                  </Button>

                  <Button
                    title="Listar"
                    className="me-2"
                    disabled={disabled}
                    onClick={() => window.print()}
                  >
                    Imprimir
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Row className="mt-2">
        <Col>
          <CardNotasFiscaisEmitidas
            loading1={loading1}
            loading2={loading2}
            setLoading1={setLoading1}
            setLoading2={setLoading2}
            mes={mes}
            ano={ano}
            tipo={tipo}
            contador={contador}
            FilialId={FilialId}
          />
        </Col>
        <Col>
          <CardNotasFiscaisPagas
            loading1={loading3}
            loading2={loading4}
            setLoading1={setLoading3}
            setLoading2={setLoading4}
            mes={mes}
            ano={ano}
            tipo={tipo}
            contador={contador}
            FilialId={FilialId}
          />
        </Col>
      </Row>

      <CardListNotasFiscais
        loading={loading5}
        setLoading={setLoading5}
        mes={mes}
        ano={ano}
        tipo={tipo}
        contador={contador}
        FilialId={FilialId}
      />
    </Container>
  );
}
