import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import IoContext from "contextos/IoContext";
import CabecalhoCadastro from "componentes/CabecalhoCadastro";
import BotaoApagar from "componentes/BotaoApagar";

export default function BloqueioJudicial() {
  const params = useParams();
  const navigate = useNavigate();
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(false);
  const id = parseInt(params.id);
  const [dominio, setDominio] = React.useState("");

  React.useEffect(() => {
    if (isNaN(id)) return;

    setLoading(true);
    socket.emit(
      "DnsBloqueioJudicial.findOne",
      {
        where: { id },
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          toast.error(error);
        } else {
          setDominio(resp.dominio);
        }
      }
    );
  }, [id, socket]);
  return (
    <Container fluid className="pt-3">
      <Card>
        <CabecalhoCadastro
          id={id}
          disabled={loading}
          titulo="Domínio para Bloqueio Judicial"
          salvar={(e) => {
            e.preventDefault();
            setLoading(true);
            socket.emit(
              "DnsBloqueioJudicial.salvar",
              {
                id,
                dominio,
              },
              (error, resp) => {
                setLoading(false);
                if (error) {
                  toast.error(error);
                } else {
                  toast.success("Domínio salvo com Sucesso !");
                  navigate(-1);
                }
              }
            );
          }}
        />

        <Card.Body>
          <Row>
            <Col sm={2}>
              <Form.Group>
                <Form.Label>Id</Form.Label>
                <Form.Control value={isNaN(id) ? "" : id} readOnly />
              </Form.Group>
            </Col>

            <Col sm={10}>
              <Form.Group>
                <Form.Label>Domínio</Form.Label>
                <Form.Control
                  disabled={loading}
                  value={dominio}
                  required
                  autoFocus
                  maxLength={100}
                  onChange={(e) => setDominio(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>

        <Card.Footer>
          {isNaN(id) ? (
            false
          ) : (
            <BotaoApagar
              disabled={loading}
              apagar={(e) => {
                setLoading(true);
                socket.emit("DnsBloqueioJudicial.apagar", { id }, (error, resp) => {
                  setLoading(false);
                  if (error) {
                    toast.error(error);
                  } else {
                    toast.success("Domínio removido com Sucesso !");
                    navigate(-1);
                  }
                });
              }}
            />
          )}
        </Card.Footer>
      </Card>
    </Container>
  );
}
