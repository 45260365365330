import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Card from 'react-bootstrap/Card';
import TituloContext from '../../contextos/TituloContext';
import TabAgendaAtendimentos from './TabAgendaAtendimentos';

export default function AgendaTecnica(){
    const { setTitulo } = React.useContext(TituloContext);

    React.useEffect(()=>{
        setTitulo('Agenda Técnica');
    }, [setTitulo])

    return <Card>
        <Tabs className='m-2'>
            <Tab eventKey='att' title='Atendimentos' className='p-2'>
                <TabAgendaAtendimentos />
            </Tab>
            <Tab eventKey='os' title='Ordens de Serviço'  className='p-2'>
                Ordens de Serviço
            </Tab>
        </Tabs>
    </Card>
}
