import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import TituloContext from "contextos/TituloContext";
import IoContext from "contextos/IoContext";
import { toast } from "react-toastify";

export default function IntegracaoGoogleMaps() {
  const { setTitulo } = React.useContext(TituloContext);
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(false);
  const [token, setToken] = React.useState("");
  const [tokenBackend, setTokenBackend] = React.useState("");
  const [isAtivo, setAtivo] = React.useState(false);
  const [eventos, setEventos] = React.useState({});

  React.useEffect(() => {
    setTitulo("Google Maps");
  });

  React.useEffect(() => {
    setLoading(true);
    setToken("");
    setAtivo(false);
    socket.emit(
      "Integracao.findOne",
      {
        where: {
          modulo: "GoogleMaps",
        },
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          return toast.error("" + error);
        }

        const config =
          typeof resp.configuracoes === "object" ? resp.configuracoes : JSON.parse(resp.configuracoes || "{}");
        setToken(config.token);
        setTokenBackend(config.tokenBackend);
        setEventos(config.eventos || {});
        setAtivo(resp.isAtivo);
      }
    );
  }, [socket]);

  return (
    <Container fluid className="mt-2">
      <Card>
        <Card.Header className="bg-primary text-light">
          <Card.Title>GoogleMaps - Configuração da API</Card.Title>
        </Card.Header>

        <Card.Body>
          <Row>
            <Col sm={8}>
              <Form.Group>
                <Form.Label>
                  Chave da Api para o Monitor de Redes (<b>Ative</b> o Controle de Origem !!!)
                </Form.Label>
                <Form.Control disabled={loading} value={token} onChange={(e) => setToken(e.target.value)} />
              </Form.Group>
            </Col>

            <Col sm={8}>
              <Form.Group>
                <Form.Label>
                  Chave da Api para o Backend (<b>Sem</b> Controle de Origem !!!)
                </Form.Label>
                <Form.Control
                  disabled={loading}
                  value={tokenBackend}
                  onChange={(e) => setTokenBackend(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Ativo</Form.Label>
                <Form.Check
                  checked={isAtivo}
                  onChange={() => {
                    setAtivo(!isAtivo);
                  }}
                  disabled={loading}
                />
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    disabled={loading}
                    onClick={(e) => {
                      e.preventDefault();
                      setLoading(true);
                      socket.emit(
                        "Integracao.Salvar",
                        {
                          modulo: "GoogleMaps",
                          configuracoes: {
                            token,
                            tokenBackend,
                            eventos,
                          },
                          isAtivo,
                        },
                        (error) => {
                          setLoading(false);
                          if (error) {
                            return toast.error("" + error);
                          }

                          toast.success("Configurações Atualizadas com Sucesso !");
                        }
                      );
                    }}
                  >
                    Salvar
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}
