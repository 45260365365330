import React from "react";
import IoContext from "contextos/IoContext";
import { Card, Col, Container, Form, Row, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import CampoDoc1 from "componentes/CampoDoc1";
import CampoProcurarProdutoOnModal from "paginas/estoque/CampoProcurarProdutoOnModal";
import CampoMoeda from "componentes/CampoMoeda";
import SelectFormaDePagamento from "paginas/cadastros/financeiro/formas_pagamento/SelectFormaDePagamento";

export default function Alofone() {
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(true);
  const [login, setLogin] = React.useState("");
  const [senha, setSenha] = React.useState("");
  const [isAtivo, setAtivo] = React.useState(false);
  const [valor, setValor] = React.useState("");
  const [produto, setProduto] = React.useState("");
  const [produtoId, setProdutoId] = React.useState("");
  const [formaPagamentoId, setFormaPagamentoId] = React.useState("");
  const [hasControlePatrimonial, setHasControlePatrimonial] = React.useState(false);

  React.useEffect(() => {
    socket.emit(
      "Integracao.findOne",
      {
        where: {
          modulo: "AloFone",
        },
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          return toast.error(error);
        }

        try {
          const configuracoes = JSON.parse(resp.configuracoes || {});
          setLogin(configuracoes.login || "");
          setSenha(configuracoes.senha || "");
          setValor(configuracoes.valor || 0);
          setProdutoId(configuracoes.produtoId || "");
          setFormaPagamentoId(configuracoes.formaPagamentoId || "");
          setHasControlePatrimonial(configuracoes.hasControlePatrimonial || "");
          setAtivo(resp.isAtivo);

          if (configuracoes.produtoId) {
            socket.emit(
              "Produto.findOne",
              {
                where: {
                  id: configuracoes.produtoId,
                },
              },
              (error, produto) => {
                if (error) {
                  return toast.error(error);
                }
                setProduto(produto);
              }
            );
          }
        } catch (error) {
          console.error("AloFone Error nas Configurações:", error);
        }
      }
    );
  }, [socket]);

  return (
    <Container fluid>
      <Card>
        <Card.Header className="bg-primary text-light">
          <Card.Title>Alo Fone - Configuração da API</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col sm={4}>
              <CampoDoc1 value={login} onChange={(txt) => setLogin(txt)} txt="Cpf / Cnpj da Revenda" />
            </Col>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>Senha:</Form.Label>
                <Form.Control
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)}
                  type="password"
                  maxLength={100}
                />
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group>
                <Form.Label>Ativo</Form.Label>
                <Form.Check
                  checked={isAtivo}
                  onChange={() => {
                    setAtivo(!isAtivo);
                  }}
                  disabled={loading}
                />
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Controle Patrimônial ?</Form.Label>
                <Form.Check
                  disabled={loading}
                  checked={hasControlePatrimonial}
                  onChange={() => {
                    setHasControlePatrimonial(!hasControlePatrimonial);
                  }}
                />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Produto que Representa o Chip</Form.Label>
                <CampoProcurarProdutoOnModal
                  value={produto}
                  onSelect={(p) => {
                    setProduto(p || "");
                    setProdutoId(p?.id || "");
                  }}
                  disabled={loading}
                />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Forma de Pagamento</Form.Label>
                <SelectFormaDePagamento
                  disabled={loading}
                  value={formaPagamentoId}
                  onChange={(e) => setFormaPagamentoId(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Valor</Form.Label>
                <CampoMoeda value={valor} onChange={(e) => setValor(e.target.value)} disabled={loading} />
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    disabled={loading}
                    onClick={(e) => {
                      e.preventDefault();
                      setLoading(true);
                      socket.emit(
                        "Integracao.Salvar",
                        {
                          modulo: "AloFone",
                          configuracoes: {
                            login,
                            senha,
                            valor,
                            produtoId,
                            formaPagamentoId,
                            hasControlePatrimonial,
                          },
                          isAtivo,
                        },
                        (error) => {
                          setLoading(false);
                          if (error) {
                            return toast.error(error);
                          }
                          toast.success("Configurações salvas com sucesso");
                        }
                      );
                    }}
                  >
                    Salvar
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <a href="https://operadora.app.br/#/login" target="alofone">
            Painel do Parceiro
          </a>
        </Card.Footer>
      </Card>
    </Container>
  );
}
