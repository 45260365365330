import { getInicioAno } from "misc/lhdatas";
import React from "react";
import Form from "react-bootstrap/Form";

export default function SelectMes(props) {
  const { id, value, onChange, disabled } = props;

  const d = getInicioAno();
  return (
    <Form.Control as="select" id={id || "mes"} onChange={onChange} value={value} disabled={disabled} {...props}>
      <option value=""></option>
      {Array.from(Array(12).keys()).map((c) => {
        d.setMonth(c);
        return (
          <option key={c} value={+c + 1}>
            {d.toLocaleString("default", { month: "long" }).toUpperCase()}
          </option>
        );
      })}
    </Form.Control>
  );
}
