import CampoTelefone from "componentes/CampoTelefone";
import SelectOperadoraTelefonia from "componentes/SelectOperadoraTelefonia";
import IoContext from "contextos/IoContext";
import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

export default function ModalPortabilidadeAloFone({ ContratoVoip, show, onHide, disabled, callback = (_e) => {} }) {
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(false);
  const [telefone, setTelefone] = React.useState("");
  const [telefoneConsultar, setTelefoneConsultar] = React.useState("");
  const [codigoOperadora, setCodigoOperadora] = React.useState("");

  React.useEffect(() => {
    if (!telefoneConsultar) return;

    setLoading(true);
    setCodigoOperadora("");
    socket.emit(
      "PortabilidadeCelular.consultar",
      {
        telefone: telefoneConsultar,
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          console.error("PortabilidadeCelular.consultar:", error);
          return toast.error("" + error.message);
        }

        console.log("PortabilidadeCelular.consultar:", resp);
        if (resp && resp !== "0000") {
          setCodigoOperadora(resp);
        }
      }
    );
  }, [telefoneConsultar, socket]);

  return (
    <Modal show={show} onHide={onHide}>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();

          const novoDdd = Number(`${telefone}`.replace(/\D/g, "").slice(0, 2));
          const dddAtual = Number(ContratoVoip?.ddd);
          if (novoDdd !== dddAtual) {
            toast.warn(`Não é possível portar números de DDDs Diferentes [${novoDdd} != ${dddAtual}]!`);
            return;
          }

          setLoading(true);
          socket.emit(
            "Alofone.SolicitarPortabilidade",
            {
              ContratoVoipId: ContratoVoip.id,
              telefone,
              codigoOperadora,
            },
            (error, resp) => {
              setLoading(false);
              if (error) {
                console.error("Alofone.SolicitarPortabilidade:", error);
                return toast.error("" + error);
              }

              toast.info("Portabilidade Solicitada com Sucesso !");
              onHide(false);
              if (callback) {
                callback();
              }
            }
          );
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Solicitar Portabilidade</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group>
            <Form.Label>DDD:</Form.Label>
            <Form.Control readOnly={true} disabled={true} value={ContratoVoip?.ddd} />
          </Form.Group>

          <Form.Group>
            <Form.Label>Número que Deseja Portar:</Form.Label>
            <CampoTelefone
              required
              autoFocus
              disabled={loading}
              value={telefone}
              onChange={(e) => setTelefone(e.target.value)}
              onBlur={() => {
                setTelefoneConsultar(telefone);
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Código da Operadora</Form.Label>
            <SelectOperadoraTelefonia
              required
              disabled={loading}
              value={codigoOperadora}
              onChange={(e) => setCodigoOperadora(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="btn-warning"
            disabled={disabled || loading}
            onClick={() => {
              onHide(false);
            }}
          >
            Cancelar
          </Button>

          <Button className="btn-primary" type="submit" disabled={disabled || loading}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
