import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import TituloContext from "contextos/TituloContext";
import IoContext from "contextos/IoContext";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ModalFidelimaxAddEvento from "./ModalFidelimaxAddEvento";

export default function Fidelimax() {
  const { setTitulo } = React.useContext(TituloContext);
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(false);
  const [token, setToken] = React.useState("");
  const [isAtivo, setAtivo] = React.useState(false);
  const [eventos, setEventos] = React.useState({});
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    setTitulo("CampSoft");
  });

  React.useEffect(() => {
    setLoading(true);
    setToken("");
    setAtivo(false);
    socket.emit(
      "Integracao.findOne",
      {
        where: {
          modulo: "Fidelimax",
        },
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          return toast.error("" + error);
        }

        const config =
          typeof resp.configuracoes === "object" ? resp.configuracoes : JSON.parse(resp.configuracoes || "{}");
        setToken(config.token);
        setEventos(config.eventos || {});
        setAtivo(resp.isAtivo);
      }
    );
  }, [socket]);

  return (
    <Container fluid className="mt-2">
      <Card>
        <Card.Header className="bg-primary text-light">
          <Card.Title>Fidelimax - Configuração da API</Card.Title>
        </Card.Header>

        <Card.Body>
          <Row>
            <Col sm={8}>
              <Form.Group>
                <Form.Label>Token</Form.Label>
                <Form.Control disabled={loading} value={token} onChange={(e) => setToken(e.target.value)} />
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Ativo</Form.Label>
                <Form.Check
                  checked={isAtivo}
                  onChange={() => {
                    setAtivo(!isAtivo);
                  }}
                  disabled={loading}
                />
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    disabled={loading}
                    title="Adicionar Evento"
                    className="me-2"
                    variant="success"
                    onClick={(e) => {
                      setShow(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>

                  <Button
                    disabled={loading}
                    onClick={(e) => {
                      e.preventDefault();
                      setLoading(true);
                      socket.emit(
                        "Integracao.Salvar",
                        {
                          modulo: "Fidelimax",
                          configuracoes: {
                            token,
                            eventos,
                          },
                          isAtivo,
                        },
                        (error) => {
                          setLoading(false);
                          if (error) {
                            return toast.error("" + error);
                          }

                          toast.success("Configurações Atualizadas com Sucesso !");
                        }
                      );
                    }}
                  >
                    Salvar
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-2">
        <Table>
          <thead>
            <tr>
              <th>Evento</th>
              <th>Configurações</th>
            </tr>
          </thead>
          <tbody>
            {(Object.keys(eventos) || []).map((evtName) => {
              const evt = eventos[evtName] || {};
              return (
                <tr key={evtName}>
                  <td>{evt.nome}</td>
                  <td>{JSON.stringify(evt.configuracoes)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>

      <ModalFidelimaxAddEvento
        show={show}
        onHide={setShow}
        onSelect={(evt) => {
          const _eventos = { ...eventos };
          _eventos[evt.nome] = evt;
          setEventos(_eventos);
        }}
      />
    </Container>
  );
}
