import React from "react";
import Container from "react-bootstrap/Container";
import TituloContext from "contextos/TituloContext";
import IntegracaoHistoricoEventos from "../IntegracaoHistoricoEventos";
import IoContext from "contextos/IoContext";
import { toast } from "react-toastify";
import { Button, Card, Col, Form, Row } from "react-bootstrap";

export default function Megazap() {
  const { setTitulo } = React.useContext(TituloContext);
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(false);
  const [token, setToken] = React.useState("");
  const [isAtivo, setAtivo] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    setToken("");
    setAtivo(false);
    socket.emit(
      "Integracao.findOne",
      {
        where: {
          modulo: "Megazap",
        },
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          return toast.error("" + error);
        }

        const config =
          typeof resp.configuracoes === "object" ? resp.configuracoes : JSON.parse(resp.configuracoes || "{}");
        setToken(config.token);
        setAtivo(resp.isAtivo);
      }
    );
  }, [socket]);

  React.useEffect(() => {
    setTitulo("Megazap");
  });

  return (
    <Container fluid className="mt-2">
      <Card>
        <Card.Header className="bg-primary text-light">
          <Card.Title>Megazap - Configuração da API</Card.Title>
        </Card.Header>

        <Card.Body>
          <Row>
            <Col sm={8}>
              <Form.Group>
                <Form.Label>Token</Form.Label>
                <Form.Control disabled={loading} value={token} onChange={(e) => setToken(e.target.value)} />
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Ativo</Form.Label>
                <Form.Check
                  checked={isAtivo}
                  onChange={() => {
                    setAtivo(!isAtivo);
                  }}
                  disabled={loading}
                />
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    disabled={loading}
                    onClick={(e) => {
                      e.preventDefault();
                      setLoading(true);
                      socket.emit(
                        "Integracao.Salvar",
                        {
                          modulo: "Megazap",
                          configuracoes: {
                            token,
                          },
                          isAtivo,
                        },
                        (error) => {
                          setLoading(false);
                          if (error) {
                            return toast.error("" + error);
                          }

                          toast.success("Configurações Atualizadas com Sucesso !");
                        }
                      );
                    }}
                  >
                    Salvar
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <IntegracaoHistoricoEventos modulo="Megazap" />
    </Container>
  );
}
