import React from "react";
import FormularioListagem from "../../../../componentes/FormularioListagem";
import IoContext from "../../../../contextos/IoContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CUPOM_PARCEIROS } from "./SelectParceiroCupom";
import { Card, Form } from "react-bootstrap";

export default function Cupoms({ pagina, setPagina, texto, setTexto }) {
  const navigate = useNavigate();
  const { socket } = React.useContext(IoContext);
  const [cupons, setCupons] = React.useState([]);
  const [exibirUtilizados, setExibirUtilizados] = React.useState(false);
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    setCupons([]);
    setTotal(0);

    let where = {};
    if (texto) where.cupom_like = `%${texto}%`;
    if (!exibirUtilizados) where.PessoaId = null;

    socket.emit(
      "Cupom.findAndCountAll",
      {
        where,
        limit: 10,
        offset: 10 * (pagina - 1),
      },
      (error, resp) => {
        if (error) {
          toast.error("" + error);
        } else {
          setCupons(resp.rows);
          setTotal(resp.count);
        }
      }
    );
  }, [socket, pagina, texto, exibirUtilizados]);

  return (
    <FormularioListagem
      titulo={`Cupons`}
      itens={cupons}
      qtdItens={total}
      pagina={pagina}
      setTexto={setTexto}
      setPagina={setPagina}
      titulos={["Id", "Parceiro", "Cupom", "Pessoa", "Endereço"]}
      tamanhos={[{ sm: 1 }, { sm: 2 }, { sm: 3 }]}
      onClick={(e) => {
        navigate(`${e.id}`);
      }}
      renderItem={(titulo, item) => {
        switch (titulo) {
          case "Id":
            return item.id;
          case "Cupom":
            return item.cupom;
          case "Parceiro":
            return CUPOM_PARCEIROS[item.parceiro];
          case "Pessoa":
            return item.PessoaId || "----";
          case "Endereço":
            return [item.uf, item.cidade, item.bairro].filter(Boolean).join(" - ");
          default:
            return titulo;
        }
      }}
      renderFiltros={() => {
        return (
          <Card.Body>
            <Form.Group>
              <Form.Label>Situação</Form.Label>
              <Form.Switch
                label="Exibir Cupons Utilizados ?"
                checked={exibirUtilizados}
                onChange={() => setExibirUtilizados(!exibirUtilizados)}
              />
            </Form.Group>
          </Card.Body>
        );
      }}
    />
  );
}
