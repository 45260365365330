import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Caixas from './Caixas';
import Caixa from './Caixa';

export default function CaixasIndex(){
    const [texto, setTexto] = React.useState('');
    const [pagina, setPagina] = React.useState(1);

    return <Routes>
        <Route path={`/:id`} element={<Caixa />} />
        <Route path={`*`} element={ <Caixas
                texto={texto} setTexto={setTexto} 
                pagina={pagina} setPagina={setPagina}
                />
            } />
    </Routes>
}