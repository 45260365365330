import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { imprimirRef } from "misc/utils";
import React from "react";
import Button from "react-bootstrap/Button";

export default function BotaoImprimir({ disabled, className = "", printElement = null }) {
  return (
    <React.Fragment>
      <Button
        variant="primary"
        className={className}
        disabled={disabled}
        title="Imprimir"
        onClick={(e) => {
          if (printElement) {
            imprimirRef(printElement.current);
          } else {
            window.print();
          }
        }}
      >
        <FontAwesomeIcon icon={faPrint} />
      </Button>
    </React.Fragment>
  );
}
