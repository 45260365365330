import React from "react";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import IoContext from "contextos/IoContext";
import { toast } from "react-toastify";
import Listagem from "componentes/Listagem";
import BotaoVoltar from "componentes/BotaoVoltar";

export default function MovimentacaoAvulsaPag2({ onClick, pagina, setPagina }) {
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(false);
  const [txt, setTxt] = React.useState("");
  const [produtos, setProdutos] = React.useState([]);
  const [produtoTxt, setProdutoTxt] = React.useState("");

  React.useEffect(() => {
    setProdutos([]);
    setLoading(true);
    socket.emit(
      "Produto.findAll",
      {
        attributes: ["id", "nome", "temControlePatrimonial", "exigirNumeroSerie", "exigirPatrimonio", "exigirMac"],
        where: {
          nome_like: `%${txt}%`,
        },
        limit: 25,
      },
      (error, resp) => {
        setLoading(false);
        if (error) return toast.error("" + error);
        setProdutos(resp);
      }
    );
  }, [txt, socket]);

  return (
    <React.Fragment>
      <Card.Body>
        <Form
          className="mb-2"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();

            setTxt(produtoTxt);
          }}
        >
          <Col>
            <Form.Group>
              <Form.Label>Procurar por:</Form.Label>
              <Form.Control
                disabled={loading}
                autoFocus
                value={produtoTxt}
                onChange={(e) => {
                  setProdutoTxt(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
        </Form>

        <Listagem
          itens={produtos}
          titulos={["Id", "Produto", "Controle Patrimonial"]}
          tamanhos={[
            { sd: 12, md: 2 },
            { sd: 12, md: 6 },
            { sd: 12, md: 4 },
          ]}
          renderItem={(titulo, item) => {
            switch (titulo) {
              case "Id":
                return item.id;
              case "Produto":
                return item.nome;
              case "Controle Patrimonial":
                return item.temControlePatrimonial ? "SIM" : "NÃO";
              default:
                return titulo;
            }
          }}
          onClick={onClick}
        />
      </Card.Body>

      <Modal.Footer>
        <BotaoVoltar
          disabled={loading}
          onClick={() => {
            setPagina(pagina - 1);
          }}
          texto="Voltar"
        />
      </Modal.Footer>
    </React.Fragment>
  );
}
