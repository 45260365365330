import React from 'react';
import IoContext from 'contextos/IoContext';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CampoEnderecoMac from 'componentes/CampoEnderecoMac';
import { toast } from 'react-toastify';

export default function ModalAdicionarPatrimonio({
    show, onHide, AlmoxarifadoId, ProdutoId, exigirNumeroSerie, exigirPatrimonio, exigirMac
}) {
    const { socket } = React.useContext(IoContext);
    const [loading, setLoading] = React.useState(false);
    const [numeroSerie, setNumeroSerie] = React.useState('');
    const [numeroPatrimonio, setNumeroPatrimonio] = React.useState('');
    const [enderecoMac, setEnderecoMac] = React.useState('');
    const [quantidade, setQuantidade] = React.useState(1);

    return <Modal show={show} onHide={onHide} size="lg">
        <Form onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();

            if (!numeroSerie && !numeroPatrimonio && !enderecoMac) {
                return toast.warning("Por favor, insira pelo menos um indentificador do patrimônio !");
            }

            setLoading(true);
            socket.emit("Patrimonio.salvar", {
                AlmoxarifadoId, ProdutoId, numeroSerie, numeroPatrimonio, enderecoMac, quantidade
            }, (error) => {
                setLoading(false);

                if (error) return toast.error("" + error);
                setNumeroSerie('');
                setNumeroPatrimonio('');
                setEnderecoMac('');
                setQuantidade(1);
                onHide();
            })
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Patrimônio</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row>
                    <Col sm={12}>
                        <Form.Group>
                            <Form.Label>Número de Série</Form.Label>
                            <Form.Control value={numeroSerie} onChange={e => setNumeroSerie(e.target.value)}
                                required={exigirNumeroSerie} maxLength={50} disabled={loading} />
                        </Form.Group>
                    </Col>

                    <Col sm={12}>
                        <Form.Group>
                            <Form.Label>Número de Patrimônio</Form.Label>
                            <Form.Control value={numeroPatrimonio} onChange={e => setNumeroPatrimonio(e.target.value)}
                                required={exigirPatrimonio} maxLength={50} disabled={loading} />
                        </Form.Group>
                    </Col>

                    <Col sm={9}>
                        <Form.Group>
                            <Form.Label>Endereço MAC</Form.Label>
                            <CampoEnderecoMac value={enderecoMac} onChange={txt => setEnderecoMac(txt)}
                                required={exigirMac} maxLength={17} disabled={loading} />
                        </Form.Group>
                    </Col>

                    <Col sm={3}>
                        <Form.Group>
                            <Form.Label>Quantidade</Form.Label>
                            <Form.Control type="number" min={1} step={1} required disabled={true || loading}
                                value={quantidade} onChange={e => setQuantidade(+e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button type="submit" variant="primary">Salvar</Button>
            </Modal.Footer>
        </Form>
    </Modal>
}