import React from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import { NOTAFISCAL_NORMAL } from "datatypes/NotaFiscalTypes";
import { CONTARECEBER_PAGA } from "datatypes/ContaReceberTypes";
import { toast } from "react-toastify";
import IoContext from "contextos/IoContext";
import { getInicioMes, getFinalMes, formatarDataDbs } from "misc/lhdatas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function CardNotasFiscaisPagas({
  contador,
  mes,
  ano,
  tipo,
  FilialId,
  loading1,
  loading2,
  setLoading1,
  setLoading2,
}) {
  const { socket } = React.useContext(IoContext);
  const [quantidadePagas, setQuantidadePagas] = React.useState(0);
  const [totalPagas, setTotalPagas] = React.useState(0);
  React.useEffect(() => {
    setQuantidadePagas(0);
    setTotalPagas(0);

    let where = {
      situacao: NOTAFISCAL_NORMAL,
      tipo,
    };
    if (FilialId) where.FilialId = FilialId;

    setLoading1(true);
    socket.emit(
      "NotaFiscal.count",
      {
        where,
        withContaReceber: {
          where: {
            situacao: CONTARECEBER_PAGA,
            data_pagamento_between: [
              formatarDataDbs(getInicioMes({ ano, mes })),
              formatarDataDbs(getFinalMes({ ano, mes })),
            ],
          },
          required: true,
        },
      },
      (error, resp) => {
        setLoading1(false);
        if (error) {
          toast.error(error);
        } else {
          setQuantidadePagas(resp);
        }
      }
    );

    setLoading2(true);
    socket.emit(
      "NotaFiscal.sum",
      {
        campo: "NotaFiscal.valor",
        where,
        withContaReceber: {
          where: {
            situacao: CONTARECEBER_PAGA,
            data_pagamento_between: [
              formatarDataDbs(getInicioMes({ ano, mes })),
              formatarDataDbs(getFinalMes({ ano, mes })),
            ],
          },
          required: true,
        },
      },
      (error, resp) => {
        setLoading2(false);
        if (error) {
          toast.error("CardListNotasFiscais:" + error);
        } else {
          setTotalPagas(resp);
        }
      }
    );
  }, [socket, mes, ano, tipo, contador, FilialId, setLoading1, setLoading2]);

  return (
    <Card className="shadow">
      <Card.Header className="bg-primary text-light">
        Notas Fiscais - Pagas no Mês
        {(loading1 || loading2) && <FontAwesomeIcon icon={faSpinner} spin={true} className="float-end" />}
      </Card.Header>
      <Table size="sm" borderless>
        <tbody>
          <tr>
            <th>Quantidade:</th>
            <td>{quantidadePagas}</td>
          </tr>
          <tr>
            <th>Valor:</th>
            <td>
              {(totalPagas || 0).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
  );
}
