import React from "react";
import { Routes, Route } from "react-router-dom";
import AdmServidorLhisp from "./AdmServidorLhisp";
import ServidoresLhisp from "./ServidoresLhisp";

export default function ServidoresLhispIndex() {
  return (
    <Routes>
      <Route path={`/:id`} element={<AdmServidorLhisp />} />
      <Route path={`*`} element={<ServidoresLhisp />} />
    </Routes>
  );
}
