import React from 'react';
import Form from 'react-bootstrap/Form';
import IoContext from 'contextos/IoContext';

export default function SelectCidades(props){
    const { socket } = React.useContext(IoContext);
    const { id, value, onChange, disabled, uf } = props; 
    const [cidades, setCidades] = React.useState([]);

    React.useEffect(()=>{
        setCidades([]);
        if(!uf) return;

        socket.emit('Endereco.listarCidades', { 
            uf 
        }, cidades => {
            setCidades(cidades);
        })
    }, [socket, uf]);

    return <Form.Control as="select" 
        id={id || "uf"} 
        onChange={onChange}
        value={value}
        disabled={disabled}
        {...props}
    >
        <option value=""></option>
        { Object.keys(cidades || {}).map( k => {
            const c = cidades[k];
            return <option key={c.codigoIbge} value={c.nome}>{c.nome}</option>
        })}
    </Form.Control>;
}