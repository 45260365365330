import React from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FormularioListagem from '../../componentes/FormularioListagem';
import { toast } from 'react-toastify';
import { PreCadastrosSituacoes, PRE_CADASTRO_PENDENTE } from '../../datatypes/PreCadastroTypes';
import IoContext from '../../contextos/IoContext';
import SelectVendedor from '../financeiro/vendas/SelectVendedor';
import { useNavigate } from 'react-router';

export default function PreCadastros(){
    const navigate = useNavigate();
    const { socket } = React.useContext(IoContext);
    const [pagina, setPagina] = React.useState(1);
    const [texto, setTexto] = React.useState("");
    const [situacao, setSituacao] = React.useState(PRE_CADASTRO_PENDENTE);
    const [VendedorId, setVendedorId] = React.useState('');
    const [PreCadastross, setPreCadastross] = React.useState([]);
    const [total, setTotal] = React.useState(0);

    React.useEffect(()=>{
        let where = { situacao };
        if(texto) where.nome_like = `%${texto}%`;
        if(VendedorId) where.VendedorId = +VendedorId;

        socket.emit("PreCadastro.findAndCountAll", { 
            attributes: [
                'id', 'createdAt','nome', 'telefone1', 'telefone2', 'email', 'situacao'
            ], 
            where, limit: 10, offset: 10 * (pagina-1) 
        }, (error,resp)  => {
            if(error) {
                toast.error(error);
            } else {
                setPreCadastross(resp.rows);
                setTotal(resp.count);
            }
        });
    }, [pagina,texto, VendedorId, situacao, socket]);

    return <FormularioListagem
        titulo={`Pre Cadastros`}
        itens={PreCadastross} qtdItens={total} pagina={pagina}
        setTexto={setTexto} setPagina={setPagina}
        titulos={["Id", "Data/Hora","Nome", "Telefone", "Whatsapp", "E-Mail", "Situação"]}
        onClick={(e)=>{
            navigate(`${e.id}`);
        }}
        
        renderItem={ (titulo, item) => {
            switch(titulo) {
                case 'Id': return item.id;
                case "Data/Hora": return (new Date(item.createdAt)).toLocaleString();
                case 'Nome': return item.nome;
                case 'Telefone': 
                    let telefones = [item.telefone1];
                    if(item.telefone2) telefones.push(item.telefone2);
                    
                    return telefones.join("-");
                case 'Whatsapp': return item.whatsapp;
                case 'E-Mail': return item.email;
                case 'Situação': return PreCadastrosSituacoes[item.situacao];
                default: return titulo;
            }
        }}

        renderFiltros={()=>{
            return <Card.Body>
                <Form.Group>
                    <Form.Label>Situação</Form.Label>
                    <Form.Control as="select"
                        value={situacao}
                        onChange={ e => setSituacao(+e.target.value)}
                    >
                        { Object.keys(PreCadastrosSituacoes).map( k => {
                            return <option value={k} key={k}>{PreCadastrosSituacoes[k]}</option>;
                        })}
                    </Form.Control>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Vendedor</Form.Label>
                    <SelectVendedor value={VendedorId} onChange={ e => setVendedorId(e.target.value)} />
                </Form.Group>
            </Card.Body>
        }}
        />
}