import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Card from "react-bootstrap/Card";
import TituloContext from "contextos/TituloContext";
import TabControleCaixa from "./TabControleCaixa";

export default function GerenciaFinanceiraIndex() {
  const { setTitulo } = React.useContext(TituloContext);

  React.useEffect(() => {
    setTitulo("Gerência Financeira");
  }, [setTitulo]);

  return (
    <Card className="m-2">
      <Card.Header className="bg-primary text-light">
        <Card.Title>Gerência Financeira</Card.Title>
      </Card.Header>

      <Tabs defaultActiveKey="controle_de_caixa">
        <Tab eventKey="controle_de_caixa" title="Controle de Caixa">
          <TabControleCaixa />
        </Tab>

        <Tab eventKey="conta_receber" title="Contas a Receber"></Tab>
        <Tab eventKey="conta_pagar" title="Contas a Pagar"></Tab>
        <Tab eventKey="retorno_bancario" title="Retorno Bancário"></Tab>
      </Tabs>
    </Card>
  );
}
