import React from 'react';
import { Routes, Route } from 'react-router-dom';
import RemessasDeMaterial from './RemessasDeMaterial';
import RemessaDeMaterial from './RemessaDeMaterial';
import {
    REMESSA_MATERIAL_EM_ABERTO
} from '../../../datatypes/RemessaDeMaterialTypes';

export default function RemessaMaterialIndex(){
    const [almoxarifadoId, setAlmoxarifadoId] = React.useState("");
    const [almoxarifadoOrigemId, setAlmoxarifadoOrigemId] = React.useState("");
    const [dataIni, setDataIni] = React.useState("");
    const [dataEnd, setDataEnd] = React.useState("");
    const [pagina, setPagina] = React.useState(1);
    const [texto, setTexto] = React.useState("");
    const [situacao, setSituacao] = React.useState(REMESSA_MATERIAL_EM_ABERTO);
    const [direcaoOrdem, setDirecaoOrdem] = React.useState('desc');

    return <Routes>
        <Route path={`/:id`} element={<RemessaDeMaterial />} />
        <Route path={`/`} element={ <RemessasDeMaterial
                texto={texto} setTexto={setTexto} 
                pagina={pagina} setPagina={setPagina}
                almoxarifadoId={almoxarifadoId} setAlmoxarifadoId={setAlmoxarifadoId}
                almoxarifadoOrigemId={almoxarifadoOrigemId} setAlmoxarifadoOrigemId={setAlmoxarifadoOrigemId}
                dataIni={dataIni} setDataIni={setDataIni}
                dataEnd={dataEnd} setDataEnd={setDataEnd}
                situacao={situacao} setSituacao={setSituacao}
                direcaoOrdem={direcaoOrdem} setDirecaoOrdem={setDirecaoOrdem}
                />
            } />
    </Routes>
}