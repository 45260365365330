import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import IoContext from "contextos/IoContext";
import MultiFiliais from "paginas/cadastros/administrativo/filiais/MultiFiliais";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import TableListagem from "componentes/TableListagem";
import SelectSituacaoContrato from "componentes/SelectSituacaoContrato";
import { getFinalAno, getInicioAno } from "misc/lhdatas";
import CampoData from "componentes/CampoData";
import { DateTime } from "luxon";

export default function RelClientesSemEmail() {
  const { socket } = React.useContext(IoContext);
  const [contratos, setContratos] = React.useState([]);
  const [exibirFiltros, setExibirFiltros] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [filiais, setFiliais] = React.useState([]);
  const [contador, setContador] = React.useState(0);
  const [situacao, setSituacao] = React.useState("");
  const [dini, setDini] = React.useState(getInicioAno().toISOString().split("T")[0]);
  const [dend, setDend] = React.useState(getFinalAno().toISOString().split("T")[0]);

  React.useEffect(() => {
    setContratos([]);
    setLoading(true);

    socket.emit(
      "Contrato.findAll",
      {
        attributes: ["id", "numero_contrato", "createdAt"],
        where: {
          situacao: situacao ? situacao : undefined,
          createdAt_between: [dini, dend],
        },
        withFilial: {
          attributes: ["nome"],
          where: {
            ...(filiais?.length ? { id_in: filiais.map((f) => f.id) } : {}),
          },
        },
        withPessoa: {
          attributes: ["nome", "email"],
          where: {
            or: [
              {
                email: null,
              },
              {
                email: "",
              },
            ],
          },
        },
      },
      (error, resp) => {
        setLoading(false);

        if (error) return toast.error("" + error);

        setContratos(resp);
      }
    );
  }, [socket, filiais, situacao, contador, dini, dend]);

  return (
    <Container fluid className="m-2">
      <Card className="shadow">
        <Card.Header className="bg-primary text-light">
          <h3 className="float-start">Relatório de Clientes Sem Email</h3>
        </Card.Header>

        <Card.Body className="d-print-none">
          <Row>
            <Col sm={4}>
              <Form.Label>Filial</Form.Label>
              <MultiFiliais filiais={filiais} setFiliais={setFiliais} />
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Data Inicial</Form.Label>
                <CampoData value={dini} onChange={(e) => setDini(e.target.value)} />
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Data Final</Form.Label>
                <CampoData value={dend} onChange={(e) => setDend(e.target.value)} />
              </Form.Group>
            </Col>

            <Col sm={1}>
              <Form.Label>Situação</Form.Label>
              <SelectSituacaoContrato value={situacao} onChange={(e) => setSituacao(e.target.value)} />
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    variant="info"
                    title="Listar"
                    className="me-2"
                    disabled={loading}
                    onClick={() => setContador(contador + 1)}
                  >
                    Atualizar
                  </Button>

                  <Button title="Listar" className="me-2" disabled={loading} onClick={() => window.print()}>
                    Imprimir
                  </Button>

                  <Button
                    title="Aplicar Filtros"
                    className="me-1 d-none"
                    variant="secondary"
                    onClick={() => {
                      setExibirFiltros(!exibirFiltros);
                    }}
                  >
                    <FontAwesomeIcon icon={faFilter} /> Filtros
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>

        <TableListagem
          titulos={["Filial", "Criado Em", "Contrato", "Cliente", "Email"]}
          tamanhos={[
            { md: 2, sm: 12 },
            { md: 1, sm: 12 },
            { md: 3, sm: 12 },
            { md: 1, sm: 12 },
          ]}
          itens={contratos}
          renderItem={(titulo, item) => {
            switch (titulo) {
              case "Filial":
                return (item.Filial || {}).nome;
              case "Contrato":
                return item.numero_contrato;
              case "Cliente":
                return (item.Pessoa || {}).nome;
              case "Email":
                return (item.Pessoa || {}).email;
              case "Criado Em":
                return DateTime.fromISO(item.createdAt).toLocaleString(DateTime.DATE_SHORT);
              default:
                return titulo;
            }
          }}
        />
      </Card>

      <Modal show={exibirFiltros} onHide={() => setExibirFiltros(false)} dialogClassName="modalFiltros">
        <Modal.Header closeButton>Filtros</Modal.Header>

        <Modal.Body>
          <Form.Group></Form.Group>
        </Modal.Body>
      </Modal>
    </Container>
  );
}
