import React from 'react';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import IoContext from 'contextos/IoContext';

export default function SelectFormaDePagamento(props) {
    const { socket } = React.useContext(IoContext);
    const { id, value, onChange, disabled, pular } = props;
    const [FormaDePagamento, setFormaDePagamento] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        setFormaDePagamento([]);

        socket.emit("FormaPagamento.findAll", {
            attributes: ['id', 'descricao'],
            order: [["descricao", "ASC"]]
        }, (error, resp) => {
            setLoading(false);
            if (error) {
                toast.error(error);
            } else {
                setFormaDePagamento(resp);
            }
        });
    }, [socket]);

    return <Form.Control as="select"
        id={id || "FormaPagamentoId"}
        onChange={onChange} value={value}
        disabled={loading || disabled}
        {...props}
    >
        <option value=""></option>
        {(FormaDePagamento || []).map(formaPagamento => {
            if (pular) {
                if (Array.isArray(pular)) {
                    if (pular.indexOf(formaPagamento.id)) {
                        return false;
                    }
                } else {
                    if (+pular === +formaPagamento.id) {
                        return false;
                    }
                }
            }
            return <option key={formaPagamento.id} value={formaPagamento.id}>{formaPagamento.descricao}</option>
        })}
    </Form.Control>;
}
