import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

export default function GetCsvFile({
  titulo,
  titulos,
  nPaginas,
  pagina,
  setPagina,
  itens,
  renderItem,
  onFinish,
  loading,
}) {
  const [dados, setDados] = React.useState([]);

  // "data:text/csv;charset=utf-8," + titulos.join(",") + "\n"
  React.useEffect(() => {
    if (loading) return; // aguarda proxima iteracao

    // itens mudaram, adiciono no meu state local
    const tmpCsv = (itens || []).map((item) => {
      let linha = [];
      titulos.forEach((titulo) => {
        linha.push((renderItem(titulo, item) + "").replace(",", ""));
      });
      return linha.join(",");
    });

    const newDados = [...dados, ...tmpCsv];
    setDados(newDados);

    if (pagina < nPaginas) {
      setPagina(pagina + 1);
    } else {
      // Downlaod
      let txtCsv = "data:text/csv;charset=utf-8," + titulos.join(",") + "\n";
      txtCsv += newDados.join("\n");

      var encodedUri = encodeURI(txtCsv);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", titulo ? `${titulo}.csv` : "Arquivo" + new Date().getTime() + ".csv");
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file named "my_data.csv".
      link.remove();

      onFinish(false);
    }
  }, [loading]);
  console.log(pagina, nPaginas, pagina / nPaginas, (pagina / nPaginas) * 100, itens);

  return (
    <ProgressBar
      now={pagina}
      min={0}
      max={nPaginas}
      label={`Baixando Dados ${Math.floor((pagina / nPaginas) * 100)}%`}
      variant="info"
    />
  );
}
