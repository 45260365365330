import React from "react";
import { toast } from "react-toastify";
import IoContext from "../../../contextos/IoContext";
import FormularioListagem from "../../../componentes/FormularioListagem";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import SelectVendedor from "../../financeiro/vendas/SelectVendedor";
import { getFinalMes, getInicioMes } from "misc/lhdatas";
import { CONTARECEBER_SITUACOES } from "datatypes/ContaReceberTypes";
import { DateTime } from "luxon";

export default function VendasPorVendedor() {
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(false);
  const [pagina, setPagina] = React.useState(1);
  const [texto, setTexto] = React.useState("");
  const [total, setTotal] = React.useState(0);
  const [dataIni, setDataIni] = React.useState(getInicioMes().toISOString().split("T")[0]);
  const [dataEnd, setDataEnd] = React.useState(getFinalMes().toISOString().split("T")[0]);
  const [servicosContratados, setServicosContratados] = React.useState([]);
  const [VendedorId, setVenedorId] = React.useState(0);
  const porPagina = 10;

  React.useEffect(() => {
    let whereVendedor = {};
    if (VendedorId) whereVendedor.id = VendedorId;

    setServicosContratados([]);
    setLoading(true);
    socket.emit(
      "ServicoContratado.findAndCountAll",
      {
        attributes: ["id", "valor", "dataAtivacao"],
        where: {
          dataAtivacao_between: [dataIni, dataEnd],
        },
        limit: porPagina,
        offset: porPagina * (pagina - 1),
        withVendedor: {
          attributes: ["nome"],
          where: whereVendedor,
        },
        withPlano: {
          attributes: ["nome"],
        },
        withContrato: {
          attributes: ["numero_contrato"],
          withFilial: {
            attributes: ["nome"],
          },
          withPessoa: {
            attributes: ["nome"],
            where: {
              nome_like: `${texto}%`,
            },
          },
          required: true,
        },
        withContasReceber: {
          attributes: ["data_vencimento", "situacao"],
          limit: 1,
        },
      },
      (error, resp) => {
        setTimeout(() => {
          setLoading(false);
          if (error) return toast.error("" + error);
          setServicosContratados(resp.rows);
          setTotal(resp.count);
        }, 10);
      }
    );
  }, [texto, VendedorId, dataIni, dataEnd, pagina, socket]);

  return (
    <FormularioListagem
      titulo={`Vendas por Vendedor`}
      itens={servicosContratados || []}
      qtdItens={total}
      pagina={pagina}
      loading={loading}
      setTexto={setTexto}
      setPagina={setPagina}
      titulos={[
        "Vendedor",
        "Contrato",
        "Plano",
        "Cliente",
        "Data de Ativação",
        "Valor",
        "Vencimento",
        "Situação da Conta",
      ]}
      renderItem={(titulo, item) => {
        switch (titulo) {
          case "Filial":
            return ((item.Contrato || {}).Filial || {}).nome;
          case "Vendedor":
            return (item.Vendedor || {}).nome;
          case "Contrato":
            return (item.Contrato || {}).numero_contrato;
          case "Plano":
            return (item.Plano || {}).nome;
          case "Cliente":
            return ((item.Contrato || {}).Pessoa || {}).nome;
          case "Valor":
            return item.valor;
          case "Data de Ativação":
            return DateTime.fromISO(item.dataAtivacao).toLocaleString(DateTime.DATE_SHORT);
          case "Vencimento":
            return item?.ContasReceber?.[0]?.data_vencimento
              ? DateTime.fromISO(item?.ContasReceber?.[0]?.data_vencimento).toLocaleString(DateTime.DATE_SHORT)
              : "----";
          case "Situação da Conta":
            return CONTARECEBER_SITUACOES[item?.ContasReceber?.[0]?.situacao] || "----";
          default:
            return titulo;
        }
      }}
      renderFiltros={() => {
        return (
          <Card.Body>
            <Form.Group>
              <Form.Label>Data Inicial</Form.Label>
              <Form.Control type="date" value={dataIni} onChange={(e) => setDataIni(e.target.value)} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Data Final</Form.Label>
              <Form.Control type="date" value={dataEnd} onChange={(e) => setDataEnd(e.target.value)} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Vendedor</Form.Label>
              <SelectVendedor value={VendedorId} onChange={(e) => setVenedorId(e.target.value)} />
            </Form.Group>
          </Card.Body>
        );
      }}
    />
  );
}
