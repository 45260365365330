import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import CardInputFonteDados from "./CardInputFonteDados";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router";
import axios from "axios";
import { toast } from "react-toastify";
import TableMapeamentoCampos from "./TableMapeamentoCampos";
import ModalConfirmar from "componentes/ModalConfirmar";
import TableResultado from "./TableResultado";
import TableErros from "./TableErros";
import ImportacaoContext from "contextos/ImportacaoContext";

let dadosImportados = [];
let dadosComErro = [];
let importando = false;
export default function FormMapeamentoDb({
  fonteDados,
  titulo,
  modulo,
  sql,
  setSql,
  template,
  campos,
  setCampos,
  mapeamento,
  setMapeamento,
  campoOrdenacao,
  setCampoOrdenacao,
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [dadosConsulta, setDadosConsulta] = React.useState({});
  const [show, setShow] = React.useState(false);
  const [registrosImportados, setRegistrosImportados] = React.useState([]);
  const [registrosComErro, setRegistrosComErro] = React.useState([]);
  const [exibirErros, setExibirErros] = React.useState(false);
  const [arquivo, setArquivo] = React.useState(null);
  const [pagina, setPagina] = React.useState(1);
  const [tamahoPagina, setTamahoPagina] = React.useState(10);
  const { modulosImportados, setModulosImportados } = React.useContext(ImportacaoContext);

  return (
    <Card>
      <Card.Header className="bg-primary text-light">
        <Card.Title>{titulo}</Card.Title>
      </Card.Header>

      <Card.Body>
        <Row>
          <Col sm={8}>
            <CardInputFonteDados fonteDados={fonteDados} txt={sql} setTxt={setSql} setArquivo={setArquivo} />
          </Col>

          <Col sm={4}>
            <Form.Label>Ações</Form.Label>
            <div>
              <Button variant="warning" className="me-2" onClick={() => navigate(-1)}>
                Voltar
              </Button>

              <Button
                className="me-2"
                onClick={() => {
                  setLoading(true);
                  axios
                    .post("/importacoes/consultar_campos", {
                      fonteDados,
                      template,
                      modulo,
                      arquivoCsv: arquivo,
                      sql,
                    })
                    .then((resp) => {
                      setLoading(false);
                      setCampos(resp.data.campos);
                      setDadosConsulta(resp.data.dadosConsulta);

                      let _mapeamento = {};
                      Object.keys(resp.data.campos || {}).forEach((c) => {
                        // _mapeamento[c] = mapeamento[c] || resp.data.mapeamento[c] || {
                        _mapeamento[c] = resp.data.mapeamento[c] || {
                          ...resp.data.campos[c],
                          campoDestino: c,
                        };
                      });
                      setMapeamento(_mapeamento);
                    })
                    .catch((error) => {
                      setLoading(false);
                      console.log("## Visualizar Erro: ", error);
                      toast.error(error.response ? "" + error.response.data : "" + error);
                    });
                }}
              >
                Visualizar
              </Button>
            </div>
          </Col>
        </Row>
      </Card.Body>

      <TableMapeamentoCampos
        campos={campos}
        dadosConsulta={dadosConsulta}
        mapeamento={mapeamento}
        setMapeamento={setMapeamento}
      />

      <Card.Body className="d-flex justify-content-end">
        <Button
          variant="success"
          disabled={loading}
          className={`${mapeamento ? "d-inline-block" : "d-none"}`}
          onClick={() => {
            dadosImportados = [];
            dadosComErro = [];

            setRegistrosImportados([]);
            setRegistrosComErro([]);
            setShow(true);
          }}
        >
          Importar
        </Button>
      </Card.Body>

      <ModalConfirmar
        show={show}
        onHide={() => {
          if (!loading) {
            setShow(false);
          }
          setLoading(false);
          importando = false;
        }}
        size="xl"
        titulo={`${loading ? "Aguarde, Importando..." : "Importar Dados ?"}`}
        disabled={loading}
        closeButton={!loading}
        texto={
          <div
            className="h-25 d-flex flex-column"
            style={{
              maxHeight: "500px",
              overflow: "auto",
            }}
          >
            <div>
              <span className="me-2">
                <b>Módulo:</b> {modulo}
              </span>
              <span className="me-2">
                <b>Página: </b>
                <input
                  value={pagina}
                  readOnly={loading}
                  type="number"
                  min={1}
                  step={1}
                  onChange={(e) => setPagina(+e.target.value)}
                />
              </span>
              <span className="me-2">
                <b>Tamanho: </b>
                <input
                  value={tamahoPagina}
                  readOnly={loading}
                  type="number"
                  min={1}
                  step={1}
                  onChange={(e) => setTamahoPagina(+e.target.value)}
                />
              </span>
              <span className="me-2">
                <b onClick={() => setExibirErros(false)}>Registros Importados:</b> {(registrosImportados || []).length}
              </span>
              <span className="me-2">
                <b onClick={() => setExibirErros(true)}>Registros Com Erro:</b> {(registrosComErro || []).length}
              </span>
            </div>

            {loading || registrosImportados.length > 0 || registrosComErro.length > 0 ? (
              exibirErros ? (
                <TableErros campos={campos} dados={registrosComErro} />
              ) : (
                <TableResultado campos={campos} dados={registrosImportados} />
              )
            ) : (
              <TableMapeamentoCampos
                campos={campos}
                dadosConsulta={dadosConsulta}
                mapeamento={mapeamento}
                setMapeamento={setMapeamento}
                view={true}
              />
            )}
          </div>
        }
        callback={() => {
          setLoading(true);
          setRegistrosImportados([]);
          setRegistrosComErro([]);
          importando = true;
          return importar({
            fonteDados,
            mapeamento,
            modulo,
            sql,
            registrosImportados,
            campoOrdenacao,
            setLoading,
            setRegistrosImportados,
            setRegistrosComErro,
            pagina,
            tamahoPagina,
            setPagina,
            arquivoCsv: arquivo,
            modulosImportados,
            setModulosImportados,
          });
        }}
      />
    </Card>
  );
}

function importar({
  fonteDados,
  mapeamento,
  modulo,
  pagina,
  tamahoPagina,
  sql,
  registrosImportados,
  campoOrdenacao,
  setLoading,
  setRegistrosImportados,
  setRegistrosComErro,
  arquivoCsv,
  setPagina,
  modulosImportados,
  setModulosImportados,
}) {
  if (!importando) {
    console.log("Parando Importar.");
    setLoading(false);
    return Promise.resolve();
  }
  setLoading(true);

  return axios
    .post(`/importacoes/importar/${(modulo + "").toLowerCase()}`, {
      fonteDados,
      mapeamento,
      modulo,
      pagina,
      tamahoPagina,
      sql,
      campoOrdenacao,
      arquivoCsv,
    })
    .then(async (resp) => {
      try {
        dadosImportados = [...dadosImportados, ...resp.data.dadosImportados];
        dadosComErro = [...dadosComErro, ...resp.data.dadosComErro];
        setRegistrosImportados(dadosImportados);
        setRegistrosComErro(dadosComErro);

        // Se recebi algum registro, procura a proxima pagina.
        if (resp.data && (resp.data.dadosImportados.length > 0 || resp.data.dadosComErro.length > 0)) {
          setPagina(pagina + 1);
          importar({
            fonteDados,
            mapeamento,
            modulo,
            sql,
            registrosImportados,
            campoOrdenacao,
            setLoading,
            setRegistrosImportados,
            setRegistrosComErro,
            pagina: pagina + 1,
            tamahoPagina,
            arquivoCsv,
            setPagina,
            modulosImportados,
            setModulosImportados,
          });
        } else {
          setLoading(false);
          toast.success("Importação Realizada com Sucesso !");
          const c = { ...modulosImportados };
          c[modulo] = true;
          console.log("Set Modulos Importados:", c);
          setModulosImportados(c);
        }
      } catch (error) {
        console.log("Importar Exception:", error);
      }
    })
    .catch((error) => {
      setLoading(false);
      console.log("## IMPORTAR ERROR:", error);
      toast.error(error.response ? error.response.data : error);
    });
}
