import React from "react";
import IoContext from "contextos/IoContext";
import TituloContext from "contextos/TituloContext";
import { toast } from "react-toastify";
import FormularioListagem from "componentes/FormularioListagem";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

export default function BloqueiosJudiciais() {
  const navigate = useNavigate();
  const { socket } = React.useContext(IoContext);
  const { setTitulo } = React.useContext(TituloContext);
  const [texto, setTexto] = React.useState("");
  const [dominios, setDominios] = React.useState([]);
  const [pagina, setPagina] = React.useState(1);
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    setTitulo("Bloqueio Judicial de Domínios");
  }, [setTitulo]);

  React.useEffect(() => {
    if (!socket) return;

    setDominios([]);
    socket.emit(
      "DnsBloqueioJudicial.findAndCountAll",
      {
        where: {
          ...(texto ? { dominio_like: `${texto}%` } : {}),
        },
        limit: 10,
        offset: 10 * (pagina - 1),
      },
      (error, resp) => {
        if (error) {
          toast.error("" + error);
        } else {
          setDominios(resp.rows);
          setTotal(resp.count);
        }
      }
    );
  }, [socket, pagina, texto]);

  return (
    <FormularioListagem
      titulo="Domínios para Bloqueio Judicial"
      itens={dominios}
      qtdItens={total}
      pagina={pagina}
      setTexto={setTexto}
      setPagina={setPagina}
      titulos={["Id", "Adicionado Em", "Domínio"]}
      onClick={(e) => {
        navigate(`${e.id}`);
      }}
      renderItem={(titulo, item) => {
        switch (titulo) {
          case "Id":
            return item.id;
          case "Adicionado Em":
            return new Date(item.createdAt).toLocaleTimeString();
          case "Domínio":
            return item.dominio;
          default:
            return titulo;
        }
      }}
      renderBotoesCustom={() => {
        return (
          <React.Fragment>
            <Button
              title="Importar CSV"
              className="ms-1 me-1"
              onClick={(e) => {
                navigate(`importar_csv`);
              }}
            >
              <FontAwesomeIcon icon={faUpload} />
            </Button>
          </React.Fragment>
        );
      }}
    />
  );
}
