import React from "react";
import IoContext from "contextos/IoContext";
import { Button } from "react-bootstrap";
import ModalConfirmar from "componentes/ModalConfirmar";
import { toast } from "react-toastify";

export default function ButtonLimparAlertas({ onClean }) {
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { socket } = React.useContext(IoContext);

  return (
    <React.Fragment>
      <Button variant="warning" className="float-end" onClick={() => setShow(true)}>
        Limpar Alertas
      </Button>

      <ModalConfirmar
        show={show}
        onHide={setShow}
        disabled={loading}
        titulo="Limpar Alertas ?"
        texto="Deseja realmente limpar todos os alertas ?"
        callback={() => {
          setLoading(true);
          socket.emit("ServidorGrafico.limparAlertas", {}, (error, res) => {
            setLoading(false);
            setShow(false);

            if (error) {
              return toast.error(String(error));
            }

            toast.info("Alertas limpos com sucesso !");
            if (onClean) onClean();
          });
        }}
      />
    </React.Fragment>
  );
}
