import SelectAloFonePlanos from "componentes/SelectAloFonePlanos";
import IoContext from "contextos/IoContext";
import CampoProcurarChipAloFone from "paginas/estoque/CampoProcurarChipAloFone";
import SelectAlmoxarifdos from "paginas/estoque/SelectAlmoxarifados";
import SelectContaBancaria from "paginas/cadastros/financeiro/contas_bancarias/SelectContaBancaria";
import React from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";

export default function DetalhesRamalVoipAloFone({
  dados,
  setDados,
  ContratoId,
  loading,
  setLoading,
}) {
  const { socket } = React.useContext(IoContext);
  const [FilialId, setFilialId] = React.useState(0);
  const [ProdutoId, setProdutoId] = React.useState(0);
  const [hasControlePatrimonial, setHasControlePatrimonial] =
    React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    socket.emit(
      "Integracao.findOne",
      {
        attributes: ["configuracoes"],
        where: {
          modulo: "AloFone",
        },
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          return toast.error(error);
        }

        try {
          const configuracoes = JSON.parse(resp.configuracoes || {});
          setHasControlePatrimonial(configuracoes.hasControlePatrimonial);
          setProdutoId(configuracoes.produtoId);
        } catch (error) {
          toast.error("AloFone Error nas Configurações:", error);
        }
      }
    );
  }, [socket, setLoading]);

  React.useEffect(() => {
    setLoading(true);
    if (hasControlePatrimonial) {
      socket.emit(
        "Contrato.findOne",
        {
          attributes: ["FilialId"],
          where: {
            id: ContratoId,
          },
        },
        (error, resp) => {
          setLoading(false);
          if (error) {
            return toast.error(error);
          }

          setFilialId(resp.FilialId);
        }
      );
    }
  }, [socket, setLoading, ContratoId, hasControlePatrimonial]);

  return (
    <React.Fragment>
      <Form.Group>
        <Form.Label>DDD</Form.Label>
        <Form.Control
          as="select"
          disabled={loading || dados?.id}
          required
          value={dados?.ddd || ""}
          onChange={(e) => {
            setDados({
              ...dados,
              ddd: e.target.value,
            });
          }}
        >
          <option value=""></option>
          {Array.from(Array(89).keys()).map((i) => {
            return (
              <option key={i + 11} value={i + 11}>
                {i + 11}
              </option>
            );
          })}
        </Form.Control>
      </Form.Group>

      <Form.Group>
        <Form.Label>Plano ID</Form.Label>
        <SelectAloFonePlanos
          as="select"
          loading={loading || dados?.id}
          setLoading={setLoading}
          required
          value={dados?.planoId || ""}
          onChange={(e) => {
            setDados({
              ...dados,
              planoId: e.target.value,
            });
          }}
        />
      </Form.Group>

      {hasControlePatrimonial && (
        <React.Fragment>
          <Form.Group>
            <Form.Label>Conta Bancária</Form.Label>
            <SelectContaBancaria
              disabled={loading || dados?.id}
              required
              value={dados?.ContaBancariaId || ""}
              FilialId={FilialId}
              onChange={(e) => {
                setDados({
                  ...dados,
                  ContaBancariaId: e.target.value,
                });
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Almoxarifado</Form.Label>
            <SelectAlmoxarifdos
              loading={loading || dados?.id}
              setLoading={setLoading}
              required
              value={dados?.AlmoxarifadoId || ""}
              FilialId={FilialId}
              onChange={(e) => {
                setDados({
                  ...dados,
                  AlmoxarifadoId: e.target.value,
                });
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Chip</Form.Label>
            <CampoProcurarChipAloFone
              AlmoxarifadoId={dados?.AlmoxarifadoId || ""}
              ProdutoId={ProdutoId || ""}
              disabled={loading || dados?.id}
              value={dados?.iccId || ""}
              required
              onSelect={(e) => {
                setDados({
                  ...dados,
                  iccId: e?.numeroSerie || "",
                  PatrimonioId: e?.id,
                });
              }}
            />
          </Form.Group>
        </React.Fragment>
      )}

      <Form.Group>
        <Form.Label>ICC id</Form.Label>
        <Form.Control
          disabled={loading || dados?.id || hasControlePatrimonial}
          maxLength={22}
          required
          value={dados?.iccId || ""}
          onBlur={() => {
            setLoading(true);
            socket.emit(
              "AloFone.checarIccId",
              {
                iccId: dados?.iccId,
              },
              (error, resp) => {
                setLoading(false);
                if (error) {
                  setDados({ ...dados, iccId: "" });
                  return toast.error("" + error);
                }

                toast.success(resp);
              }
            );
          }}
          onChange={(e) => {
            setDados({
              ...dados,
              iccId: e.target.value,
            });
          }}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Descrição</Form.Label>
        <Form.Control
          disabled={loading}
          maxLength={100}
          value={dados?.descricao || ""}
          onChange={(e) => {
            setDados({
              ...dados,
              descricao: e.target.value,
            });
          }}
        />
      </Form.Group>
    </React.Fragment>
  );
}
