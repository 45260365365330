import React from "react";
import IoContext from "./IoContext";
import AuthContext from "./AuthContext";
import { toast } from "react-toastify";

const { io } = require("socket.io-client");
let ioUrl = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? ":" + window.location.port : ""
}`;
if (process.env.NODE_ENV === "development" && process.env.REACT_APP_LHISP_BACKEND) {
  ioUrl = process.env.REACT_APP_LHISP_BACKEND;
}
let socket = null;

export default function IoProvider(props) {
  const { usuario, setUsuario, empresa, setEmpresa, isTokenValido, setTokenValido } = React.useContext(AuthContext);
  const [online, setOnline] = React.useState(empresa ? true : false); // Inicia como online, caso já tenha empresa no localStorage
  const [revalidando, setRevalidando] = React.useState(false);

  React.useMemo(() => {
    socket = io(ioUrl, {
      transports: ["websocket"],
      query: {
        vhost: window.location.hostname,
      },
      auth: {
        token: usuario ? usuario.token : null,
      },
    });
  }, [usuario]);

  React.useEffect(() => {
    if (!socket) return;

    socket.on("connect", function () {
      setOnline(true);
      revalidarToken({ socket, usuario, isTokenValido, setUsuario, setRevalidando, setTokenValido });
    });

    socket.on("disconnect", function (reason) {
      console.log("## DESCONECAO:", reason);
      // toast.error("Desconectado do Servidor:" + reason);
      setRevalidando(false);
      setOnline(false);
    });

    socket.on("error", function (error) {
      // toast.success("ERROR do Servidor" + error);
      console.log("## ERROR:", error);
      setOnline(false);
    });

    socket.on("Error", (mensagem) => {
      // toast.error("Erro de Conexão com o Servidor:" + mensagem);
      console.error("Socket Error:", mensagem);
    });

    socket.on("Warning", (mensagem) => {
      // toast.warning("Aviso na Conexão com o Servidor:" + mensagem);
      console.log("Socket Warn:", mensagem);
    });

    socket.on("Empresa", (empresa) => {
      if (empresa) {
        setEmpresa(empresa);
      }
    });

    socket.on("Logout", (msg) => {
      if (msg) {
        toast.info("" + msg);
      }

      setUsuario(null);
      setRevalidando(false);
      setTokenValido(false);
    });
  }, [isTokenValido, usuario, setUsuario, empresa, setEmpresa, setTokenValido]);

  return (
    <IoContext.Provider
      value={{
        socket,
        online,
        revalidando,
      }}
    >
      {props.children}
    </IoContext.Provider>
  );
}

function revalidarToken({ socket, usuario, isTokenValido, setUsuario, setRevalidando, setTokenValido }) {
  // Tenta Revalidar o Login
  if (usuario) {
    const d1 = new Date(usuario.token_valido_ate);
    const d2 = new Date();

    if (usuario.token && d1.getTime() > d2.getTime()) {
      if (!isTokenValido) {
        setRevalidando(false);
        socket.emit("/api/ValidarToken", usuario, (usuario) => {
          setUsuario(usuario);
          setRevalidando(false);
          setTokenValido(true);
        });
      }
    } else {
      setUsuario(null);
    }
  }
}
