import React from 'react';
import IoContext from 'contextos/IoContext';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import MovimentacaoAvulsaPag1 from './MovimentacaoAvulsaPag1';
import MovimentacaoAvulsaPag2 from './MovimentacaoAvulsaPag2';
import MovimentacaoAvulsaPag3 from './MovimentacaoAvulsaPag3';
import MovimentacaoAvulsaPag4 from './MovimentacaoAvulsaPag4';
import { Card } from 'react-bootstrap';

export default function ModalMovimentacaoEstoqueAvulsa({ show, onHide, AlmoxarifadoId, count }) {
    const { socket } = React.useContext(IoContext);
    const [LocalAlmoxarifadoId, setLocalAlmoxarifadoId] = React.useState(0);
    const [produto, setProduto] = React.useState(null);
    const [ProdutoId, setProdutoId] = React.useState(0);
    const [descricao, setDescricao] = React.useState("");
    const [quantidade, setQuantidade] = React.useState(0);
    const [tipo, setTipo] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [pagina, setPagina] = React.useState(1);
    const [numeroSerie, setNumeroSerie] = React.useState('');
    const [numeroPatrimonio, setNumeroPatrimonio] = React.useState('');
    const [enderecoMac, setEnderecoMac] = React.useState('');

    React.useEffect(() => {
        setProdutoId(0);
        setDescricao("");
        setQuantidade(1);
        setTipo("");
        setPagina(1);
    }, [count]);

    const adicionarMaterial = function adicionarMaterial(callback) {
        setLoading(true);
        socket.emit("Almoxarifado.AdicionarMovimentacaoEstoque", {
            AlmoxarifadoId, ProdutoId, LocalAlmoxarifadoId, descricao, quantidade, tipo,
            numeroSerie, numeroPatrimonio, enderecoMac
        }, (error) => {
            setLoading(false);

            if (error) return toast.error("" + error);

            setNumeroSerie('');
            setNumeroPatrimonio('');
            setEnderecoMac('');
            toast.success("Movimentação Adicionada com Sucesso !");

            if(!produto.temControlePatrimonial) {
                setLocalAlmoxarifadoId(0);
                setProdutoId(0);
                setDescricao('');
                setTipo('');
                onHide(true);
            } 

            if(callback) callback();
        });
    };

    return <Modal show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton>
            <Modal.Title>Nova Movimentação</Modal.Title>
        </Modal.Header>

        <Card>
            {pagina === 1 ? <MovimentacaoAvulsaPag1 onClick={tipo => {
                setTipo(tipo);
                setPagina(2);
            }} /> : false}
            
            {pagina === 2 ? <MovimentacaoAvulsaPag2
                pagina={pagina} setPagina={setPagina}
                onClick={produto => {
                    if (produto.temControlePatrimonial && tipo === 'S') {
                        toast.warning("Não é Possível realizar Saída Avulsa de Produtos com Controle Patrimonial.")
                    } else {
                        setProduto(produto);
                        setProdutoId(produto.id);
                        setPagina(3);
                    }
                }} /> : false}

            {pagina === 3 ? <MovimentacaoAvulsaPag3
                AlmoxarifadoId={AlmoxarifadoId} LocalAlmoxarifadoId={LocalAlmoxarifadoId}
                setLocalAlmoxarifadoId={setLocalAlmoxarifadoId}
                descricao={descricao} setDescricao={setDescricao}
                quantidade={quantidade} setQuantidade={setQuantidade}
                produto={produto} tipo={tipo} loading={loading}
                pagina={pagina} setPagina={setPagina}
                adicionarMaterial={adicionarMaterial}
            /> : false}

            {pagina === 4 ? <MovimentacaoAvulsaPag4
                produto={produto} tipo={tipo} loading={loading}
                numeroSerie={numeroSerie} setNumeroSerie={setNumeroSerie}
                numeroPatrimonio={numeroPatrimonio} setNumeroPatrimonio={setNumeroPatrimonio}
                enderecoMac={enderecoMac} setEnderecoMac={setEnderecoMac}
                pagina={pagina} setPagina={setPagina}
                adicionarMaterial={adicionarMaterial}
            /> : false}
        </Card>
    </Modal>
}