import React from "react";
import IoContext from "contextos/IoContext";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import BotaoAdicionar from "componentes/BotaoAdicionar";
import BotaoAtualizar from "componentes/BotaoAtualizar";
import ModalAdicionarTarefa from "./ModalAdicionarTarefa";
import { Form } from "react-bootstrap";

export default function CardTarefasServidor({ ServidorId }) {
  const { socket } = React.useContext(IoContext);
  const [count, setCount] = React.useState(0);
  const [tarefa, setTarefa] = React.useState(null);
  const [tarefas, setTarefas] = React.useState([]);
  const [showModalAdicionarTarefas, setShowModalAdicionarTarefas] = React.useState(false);
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    setTarefas([]);
    socket.emit(
      "TarefaSistema.findAll",
      {
        where: {
          modulo: "LhAgentManager",
          ModuloId: ServidorId,
        },
        order: [["id", "desc"]],
        limit: 10,
      },
      (error, tarefas) => {
        if (error) return toast.error("ERROR:" + error);
        setTarefas(tarefas);
      }
    );
  }, [socket, ServidorId, count]);

  return (
    <Card>
      <Card.Header className="bg-primary text-light">
        <Card.Title>
          Tarefas
          <BotaoAdicionar
            onClick={() => {
              setShowModalAdicionarTarefas(true);
            }}
          />
          <BotaoAtualizar
            className="float-end me-2"
            onClick={() => {
              setCount(count + 1);
            }}
          />
        </Card.Title>
      </Card.Header>

      <Table>
        <thead>
          <tr>
            <th>Tarefa</th>
            <th>Situacao</th>
            <th>Retorno</th>
          </tr>
        </thead>
        <tbody>
          {(tarefas || []).map((tarefa) => {
            return (
              <tr
                key={tarefa.id}
                onClick={() => {
                  setTarefa(tarefa);
                  setShow(true);
                }}
              >
                <td>{tarefa.nome}</td>
                <td>{tarefa.situacao}</td>
                <td>{`${tarefa.retorno}`.substring(0, 512)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <ModalAdicionarTarefa
        show={showModalAdicionarTarefas}
        onHide={setShowModalAdicionarTarefas}
        ServidoresId={[ServidorId]}
      />

      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Tarefa</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group>
            <Form.Label>Tarefa</Form.Label>
            <Form.Control value={tarefa?.nome} readOnly />
          </Form.Group>

          <Form.Group>
            <Form.Label>Retorno</Form.Label>
            <Form.Control
              as="textarea"
              className="text-monospace"
              rows={15}
              readOnly
              value={JSON.stringify(JSON.parse(tarefa?.retorno || "{}"), null, 4)}
            />
          </Form.Group>
        </Modal.Body>
      </Modal>
    </Card>
  );
}
