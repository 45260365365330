import React from 'react';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import IoContext from 'contextos/IoContext';

export default function SelectCaixas(props) {
    const { socket } = React.useContext(IoContext);
    const { id, value, onChange, disabled, pular } = props;
    const [caixas, setCaixas] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        setCaixas([]);

        socket.emit("Caixa.findAll", {
            attributes: ['id', 'nome'],
            order: [["nome", "ASC"]]
        }, (error, resp) => {
            setLoading(false);
            if (error) {
                toast.error(error);
            } else {
                setCaixas(resp);
            }
        });
    }, [socket]);

    return <Form.Control as="select"
        id={id || "CaixaId"}
        onChange={onChange} value={value}
        disabled={loading || disabled}
        {...props}
    >
        <option value=""></option>
        {(caixas || []).map(alm => {
            if (pular) {
                if (Array.isArray(pular)) {
                    if (pular.indexOf(alm.id)) {
                        return false;
                    }
                } else {
                    if (+pular === +alm.id) {
                        return false;
                    }
                }
            }
            return <option key={alm.id} value={alm.id}>{alm.nome}</option>
        })}
    </Form.Control>;
}
