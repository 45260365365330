import React from "react";
import Form from "react-bootstrap/Form";

export default function SelectProdutosSpcPJ(props) {
  const { value, onChange, disabled } = props;

  return (
    <Form.Control as="select" onChange={onChange} value={value} disabled={disabled} {...props}>
      <option value=""></option>
      {Object.keys(produtosPJ).map((k) => {
        return (
          <option key={k} value={k}>
            {produtosPJ[k]}
          </option>
        );
      })}
    </Form.Control>
  );
}

const produtosPJ = {
  5: "SPC Top Jurídico (Completa)",
  12: "SPC",
  13: "SPC Mix (Spc + Cheque)",
  15: "SPCheque Analítica",
  16: "SPCheque Sintética",
  89: "SPC Busca",
  127: "SPC Mix Plus.",
  128: "SPC Plus",
  129: "SPC MAX",
  323: "Novo SPC Mix Mais",
  337: "SPC Relatório Completo",
};
