import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';

import BotaoApagar from 'componentes/BotaoApagar';
import CabecalhoCadastro from 'componentes/CabecalhoCadastro';
import SelectCategorias from 'paginas/estoque/categorias/SelectCategorias';
import IoContext from 'contextos/IoContext';

export default function Produto() {
    const params = useParams();
    const navigate = useNavigate();
    const { socket } = React.useContext(IoContext);
    const [loading, setLoading] = React.useState(false);
    const id = parseInt(params.id);
    const [nome, setNome] = React.useState('');
    const [preco, setPreco] = React.useState(0);
    const [CategoriaId, setCategoriaId] = React.useState(0);
    const [unidade, setUnidade] = React.useState('');
    const [temControlePatrimonial, setTemControlePatrimonial] = React.useState(false);
    const [exigirNumeroSerie, setExigirNumeroSerie] = React.useState(false);
    const [exigirPatrimonio, setExigirPatrimonio] = React.useState(false);
    const [exigirMac, setExigirMac] = React.useState(false);
    const [isRetornavel, setIsRetornavel] = React.useState(false);

    React.useEffect(() => {
        if (isNaN(id)) return;

        setLoading(true);
        socket.emit("Produto.findOne", {
            where: { id }
        }, (error, resp) => {
            setLoading(false);
            if (error) {
                toast.error(error);
            } else {
                setNome(resp.nome);
                setCategoriaId(resp.CategoriaId);
                setUnidade(resp.unidade);
                setTemControlePatrimonial(resp.temControlePatrimonial);
                setExigirNumeroSerie(resp.exigirNumeroSerie);
                setExigirPatrimonio(resp.exigirPatrimonio);
                setExigirMac(resp.exigirMac);
                setIsRetornavel(resp.isRetornavel);
                setPreco(resp.preco);
            }
        });
    }, [id, socket, setLoading, setNome]);
    return <Container fluid className="pt-3">
        <Card>
            <CabecalhoCadastro
                id={id} disabled={loading} titulo='Produto'
                salvar={e => {
                    e.preventDefault();
                    setLoading(true);
                    socket.emit("Produto.salvar", {
                        id, CategoriaId, nome, unidade, preco,
                        isRetornavel, temControlePatrimonial,
                        exigirNumeroSerie, exigirPatrimonio, exigirMac
                    }, (error, resp) => {
                        setLoading(false);
                        if (error) {
                            toast.error(error);
                        } else {
                            toast.success("Produto salvo com Sucesso !");
                            navigate(-1);
                        }
                    });
                }}
            />

            <Card.Body>
                <Row>
                    <Col sm={3}>
                        <Form.Group>
                            <Form.Label>Id</Form.Label>
                            <Form.Control value={isNaN(id) ? "" : id} readOnly />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col sm={6}>
                        <Form.Group>
                            <Form.Label>Produto</Form.Label>
                            <Form.Control
                                disabled={loading}
                                value={nome} required
                                autoFocus maxLength={50}
                                onChange={e => setNome(e.target.value)}
                            />
                        </Form.Group>
                    </Col>

                    <Col sm={2}>
                        <Form.Group>
                            <Form.Label>Categoria</Form.Label>
                            <SelectCategorias
                                disabled={loading}
                                value={CategoriaId} required
                                onChange={e => setCategoriaId(e.target.value)}
                            />
                        </Form.Group>
                    </Col>

                    <Col sm={2}>
                        <Form.Group>
                            <Form.Label>Unidade</Form.Label>
                            <Form.Control
                                disabled={loading}
                                value={unidade} required maxLength={2}
                                onChange={e => setUnidade(e.target.value)}
                            />
                        </Form.Group>
                    </Col>

                    <Col sm={2}>
                        <Form.Group>
                            <Form.Label>Preço</Form.Label>
                            <Form.Control
                                disabled={loading} type="number" min={0} step={0.01}
                                value={preco} required 
                                onChange={e => setPreco(+e.target.value)}
                            />
                        </Form.Group>
                    </Col>

                    <Col sm={6}>
                        <Form.Group>
                            <Form.Label>Opções</Form.Label>
                            <div>
                                <Form.Check label="Produto Retornável de Locação do Contrato ?"
                                    checked={isRetornavel}
                                    onChange={() => setIsRetornavel(!isRetornavel)} />

                                <Form.Check label="Habilitar Controle Patrimônial"
                                    checked={temControlePatrimonial}
                                    onChange={() => setTemControlePatrimonial(!temControlePatrimonial)} />

                                <Form.Check label="Exigir Número de Série"
                                    checked={exigirNumeroSerie}
                                    onChange={() => setExigirNumeroSerie(!exigirNumeroSerie)} />

                                <Form.Check label="Exigir Número de Patrimônio"
                                    checked={exigirPatrimonio}
                                    onChange={() => setExigirPatrimonio(!exigirPatrimonio)} />

                                <Form.Check label="Exigir Endereço Mac"
                                    checked={exigirMac}
                                    onChange={() => setExigirMac(!exigirMac)} />
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
            </Card.Body>

            <Card.Footer>
                {isNaN(id) ? false : <BotaoApagar
                    disabled={loading}
                    apagar={e => {
                        setLoading(true);
                        socket.emit("Produto.apagar", { id }, (error) => {
                            setLoading(false);
                            if (error) {
                                toast.error(error);
                            } else {
                                toast.success("Produto removido com Sucesso !");
                                navigate(-1);
                            }
                        });
                    }}
                />}
            </Card.Footer>
        </Card>
    </Container>;
}