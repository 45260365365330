import React from "react";
import { Card, Col, Container, Row, Button } from "react-bootstrap";
import useIntegracaoFindOne from "hooks/useIntegracaoFindOne";
import useSalvarIntegracao from "hooks/useSalvarIntegracao";
import OperadorSpcBrasil from "./OperadorSpcBrasil";

export default function SpcBrasil() {
  const [loading, setLoading] = React.useState(true);
  const [integracao, , setConfiguracao] = useIntegracaoFindOne({
    modulo: "SpcBrasil",
    setLoading,
    parseConfiguracoes,
  });
  const salvarIntegracao = useSalvarIntegracao();

  return (
    <Container fluid>
      <Card>
        <Card.Header className="bg-primary">
          <Card.Title className="text-light">Spc Brasil - Configuração da API</Card.Title>
        </Card.Header>
        <Card.Body>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              salvarIntegracao({ modulo: "SpcBrasil", setLoading, ...integracao });
            }}
          >
            {integracao?.configuracoes?.operadores?.map((operador, idx) => {
              return (
                <React.Fragment key={idx}>
                  <OperadorSpcBrasil
                    disabled={loading}
                    operador={operador}
                    setOperador={(campo, valor) => {
                      const operadores = [...integracao?.configuracoes?.operadores];
                      operadores[idx][campo] = valor;
                      setConfiguracao("operadores", operadores);
                    }}
                    removerOperador={() => {
                      const operadores = [...integracao?.configuracoes?.operadores];
                      operadores.splice(idx, 1);
                      setConfiguracao("operadores", operadores);
                    }}
                  />
                </React.Fragment>
              );
            })}
            <Row>
              <Col sm={12}>
                <div className="text-end mt-5">
                  <Button
                    disabled={loading}
                    variant="success"
                    className="me-2"
                    onClick={(e) => {
                      e.preventDefault();
                      const operadores = [...integracao?.configuracoes?.operadores];
                      operadores.push({});
                      setConfiguracao("operadores", operadores);
                    }}
                  >
                    Adicionar
                  </Button>

                  <Button disabled={loading} className="me-2" type="submit">
                    Salvar
                  </Button>
                </div>
              </Col>
            </Row>
          </form>
        </Card.Body>
      </Card>
    </Container>
  );
}

const parseConfiguracoes = (configuracoes) => {
  if (configuracoes?.operador && !configuracoes?.operadores) {
    return { operadores: [configuracoes] };
  }

  return configuracoes;
};
