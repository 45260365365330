import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import { toast } from "react-toastify";
import BotaoApagar from "../../../../componentes/BotaoApagar";
import CabecalhoCadastro from "../../../../componentes/CabecalhoCadastro";
import IoContext from "../../../../contextos/IoContext";
import { useParams, useNavigate } from "react-router-dom";
import SelectParceiroCupom from "./SelectParceiroCupom";
import CampoData from "componentes/CampoData";
import { formatarDataDbs, getInicioDia } from "misc/lhdatas";
import SelectUf from "componentes/SelectUf";
import SelectCidades from "paginas/cadastros/administrativo/enderecos/SelectCidade";

export default function Cupom() {
  const params = useParams();
  const navigate = useNavigate();
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(false);
  const id = parseInt(params.id);
  const [cupom, setCupom] = React.useState("");
  const [uf, setUf] = React.useState("");
  const [cidade, setCidade] = React.useState("");
  const [bairro, setBairro] = React.useState("");
  const [parceiro, setParceiro] = React.useState("");
  const [validadeInicial, setValidadeInicial] = React.useState(formatarDataDbs(getInicioDia()));
  const [validadeFinal, setValidadeFinal] = React.useState("");

  React.useEffect(() => {
    if (isNaN(id)) return;

    setLoading(true);
    socket.emit(
      "Cupom.findOne",
      {
        where: { id },
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          toast.error(error);
        } else {
          setCupom(resp.cupom);
          setParceiro(resp.parceiro);
          setValidadeInicial(resp.validadeInicial);
          setValidadeFinal(resp.validadeFinal);
          setUf(resp.uf);
          setCidade(resp.cidade);
          setBairro(resp.bairro);
        }
      }
    );
  }, [id, socket, setLoading, setCupom]);
  return (
    <Container fluid className="pt-3">
      <Card>
        <CabecalhoCadastro
          id={id}
          disabled={loading}
          titulo="Cupom"
          salvar={(e) => {
            e.preventDefault();
            setLoading(true);
            socket.emit(
              "Cupom.salvar",
              {
                id,
                cupom,
                parceiro,
                validadeInicial,
                validadeFinal,
                uf,
                cidade,
                bairro,
              },
              (error, resp) => {
                setLoading(false);
                if (error) {
                  toast.error(error);
                } else {
                  toast.success("Cupom salvo com Sucesso !");
                  navigate(-1);
                }
              }
            );
          }}
        />

        <Card.Body>
          <Row>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Id</Form.Label>
                <Form.Control value={isNaN(id) ? "" : id} readOnly />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Cupom</Form.Label>
                <Form.Control
                  disabled={loading}
                  value={cupom}
                  required
                  autoFocus
                  maxLength={255}
                  onChange={(e) => setCupom(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={6}>
              <Form.Group>
                <Form.Label>Parceiro</Form.Label>
                <SelectParceiroCupom
                  required
                  disabled={loading}
                  value={parceiro}
                  onChange={(e) => setParceiro(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={6}>
              <Form.Group>
                <Form.Label>Válido a partir de</Form.Label>
                <CampoData
                  value={validadeInicial}
                  onChange={(e) => setValidadeInicial(e.target.value)}
                  disabled={loading}
                />
              </Form.Group>
            </Col>

            <Col sm={6}>
              <Form.Group>
                <Form.Label>Válido até</Form.Label>
                <CampoData
                  value={validadeFinal}
                  onChange={(e) => setValidadeFinal(e.target.value)}
                  disabled={loading}
                />
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>UF</Form.Label>
                <SelectUf value={uf} onChange={(e) => setUf(e.target.value)} disabled={loading} />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Cidade</Form.Label>
                <SelectCidades uf={uf} value={cidade} onChange={(e) => setCidade(e.target.value)} disabled={loading} />
              </Form.Group>
            </Col>

            <Col sm={6}>
              <Form.Group>
                <Form.Label>Bairro</Form.Label>
                <Form.Control
                  disabled={loading}
                  value={bairro}
                  maxLength={100}
                  onChange={(e) => setBairro(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>

        <Card.Footer>
          {isNaN(id) ? (
            false
          ) : (
            <BotaoApagar
              disabled={loading}
              apagar={(e) => {
                setLoading(true);
                socket.emit("Cupom.apagar", { id }, (error, resp) => {
                  setLoading(false);
                  if (error) {
                    toast.error(error);
                  } else {
                    toast.success("Cupom removido com Sucesso !");
                    navigate(-1);
                  }
                });
              }}
            />
          )}
        </Card.Footer>
      </Card>
    </Container>
  );
}
