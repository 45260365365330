import React from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import SelectFiliais from "../../administrativo/filiais/SelectFiliais";
import SelectFornecedores from "../../administrativo/fornecedores/SelectFornecedores";
import FormularioListagem from "componentes/FormularioListagem";
import SelectCentrosDeCusto from "../centro_de_custo/SelectCentrosDeCusto";
import { toast } from "react-toastify";
import IoContext from "contextos/IoContext";
import { useNavigate } from "react-router";

const periodos = {
  M: "Mensal",
  A: "Anual",
};

export default function Despesas({
  texto,
  setTexto,
  pagina,
  setPagina,
  filialId,
  setFilialId,
  fornecedorId,
  setFornecedorId,
  CentroCustoId,
  setCentroCustoId,
}) {
  const navigate = useNavigate();
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(false);
  const [despesas, setDespesas] = React.useState([]);
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    let where = {};
    if (texto) where.descricao_like = `%${texto}%`;
    if (filialId) where.FilialId = +filialId;
    if (fornecedorId) where.FornecedorId = +fornecedorId;
    if (CentroCustoId) where.CentroCustoId = +CentroCustoId;

    setLoading(true);
    socket.emit(
      "Despesa.findAndCountAll",
      {
        where,
        withFilial: {},
        withFornecedor: {},
        limit: 10,
        offset: 10 * (pagina - 1),
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          toast.error(error);
        } else {
          setDespesas(resp.rows);
          setTotal(resp.count);
        }
      }
    );
  }, [pagina, texto, socket, filialId, fornecedorId, CentroCustoId]);

  return (
    <FormularioListagem
      titulo={`Despesas`}
      itens={despesas}
      qtdItens={total}
      loading={loading}
      pagina={pagina}
      setTexto={setTexto}
      texto={texto}
      setPagina={setPagina}
      titulos={[
        "Id",
        "Filial",
        "Fornecedor",
        "Descrição",
        "Vencimento",
        "Valor",
      ]}
      tamanhos={[{ sm: 1 }, { sm: 1 }, { sm: 2 }, { sm: 4 }]}
      onClick={(e) => {
        navigate(`${e.id}`);
      }}
      renderItem={(titulo, item) => {
        switch (titulo) {
          case "Id":
            return item.id;
          case "Filial":
            return item.Filial ? item.Filial.nome : "----";
          case "Fornecedor":
            return item.Fornecedor ? item.Fornecedor.nome : "----";
          case "Descrição":
            return item.descricao;
          case "Vencimento":
            return (
              (item.periodo === "A"
                ? `${item.diaVencimento}/${item.mesVencimento}`
                : item.diaVencimento) +
              " / " +
              periodos[item.periodo]
            );
          case "Valor":
            return item.valor.toLocaleString(window.navigator.language, {
              style: "currency",
              currency: "BRL",
            }); // return (item.valor || 0).toFixed(2);
          default:
            return titulo;
        }
      }}
      renderFiltros={() => {
        return (
          <Card.Body>
            <Form.Group>
              <Form.Label>Filial</Form.Label>
              <SelectFiliais
                value={filialId}
                onChange={(e) => setFilialId(e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Fornecedor</Form.Label>
              <SelectFornecedores
                value={fornecedorId}
                onChange={(e) => setFornecedorId(e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Centro de Custo</Form.Label>
              <SelectCentrosDeCusto
                value={CentroCustoId}
                onChange={(e) => setCentroCustoId(e.target.value)}
              />
            </Form.Group>
          </Card.Body>
        );
      }}
    />
  );
}
