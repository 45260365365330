import React from "react";
import Card from "react-bootstrap/Card";
import { toast } from "react-toastify";
import IoContext from "../../../../contextos/IoContext";
import {
  ATENDIMENTO_EM_ABERTO,
  ATENDIMENTO_AGUARDANDO_OS,
  ATENDIMENTO_EM_ATENDIMENTO,
  ATENDIMENTO_CONCLUIDO,
} from "../../../../datatypes/AtendiemntosTypes";
import Chart from "react-apexcharts";

export default function CardGraficoTopAtendimentos({ contratos, dini, dend }) {
  const { socket } = React.useContext(IoContext);
  const [series, setSeries] = React.useState([]);
  const [labels, setLabels] = React.useState([]);

  React.useEffect(() => {
    let series = [
      {
        name: "Atendimentos Em Aberto",
        data: [],
      },
      {
        name: "Atendimentos Concluídos",
        data: [],
      },
      {
        name: "Atendimentos Aguardando por Os",
        data: [],
      },
    ];
    let labels = {};
    let indices = {};

    setSeries([]);
    setLabels([]);
    socket.emit(
      "Atendimento.findAll",
      {
        attributes: ["ContratoId", "situacao"],
        where: {
          data_abertura_between: [dini, dend],
          ContratoId_in: contratos,
        },
        withContrato: {
          attributes: ["contrato"],
          withPessoa: {
            attributes: ["nome"],
          },
        },
      },
      (error, resp) => {
        if (error) {
          toast.error(error);
        } else {
          (resp || []).forEach((att) => {
            if (!labels[att.ContratoId]) {
              let contrato = att.Contrato || {};
              let pessoa = contrato.Pessoa || {};
              labels[att.ContratoId] = pessoa.nome ? pessoa.nome.split(" ")[0] : "----";
              indices[att.ContratoId] = Object.keys(labels).length - 1;

              series.forEach((s, idx) => {
                series[idx].data[indices[att.ContratoId]] = 0;
              });
            }

            let idx = indices[att.ContratoId];
            switch (att.situacao) {
              case ATENDIMENTO_EM_ATENDIMENTO:
              case ATENDIMENTO_EM_ABERTO:
                series[0].data[idx]++;
                break;
              case ATENDIMENTO_CONCLUIDO:
                series[1].data[idx]++;
                break;
              case ATENDIMENTO_AGUARDANDO_OS:
                series[2].data[idx]++;
                break;
              default:
              // Nada.
            }
          });

          setLabels(Object.values(labels));
          setSeries(series);
        }
      }
    );
  }, [socket, contratos, dini, dend]);

  return (
    <Card className="shadow rounded p-1 mt-2">
      <Chart
        options={{
          title: {
            text: "Atendimentos",
            style: {
              fontSize: "18px",
              fontWeight: "bold",
              fontFamily: undefined,
              color: "#263238",
            },
          },
          legend: {
            position: "bottom",
          },
          labels,
        }}
        series={series}
        type="bar"
        height="400"
        width="100%"
      />
    </Card>
  );
}
