import React from "react";
import { Card, Col, Container, Form, InputGroup, Row, Button } from "react-bootstrap";
import { getFinalMes, getInicioMes } from "misc/lhdatas";
import IoContext from "contextos/IoContext";
import { toast } from "react-toastify";
import CardListaEstoquePorFilial from "./CardListaEstoquePorFilial";
import SelectFiliais from "paginas/cadastros/administrativo/filiais/SelectFiliais";
import { downloadCsv } from "misc/downloadCsv";

//função para  formatar os dados para o CSV
function formataDados(historicoEstoque) {
  const dados = [];
  dados.push(["Data/Hora", "Contrato", "Cliente", "Produto", "Quantidade", "Preço", "Total"]);
  historicoEstoque.forEach((d) => {
    dados.push([
      new Date(d.dataLocacao).toLocaleString(),
      d.Contrato?.numero_contrato,
      d.Contrato?.Pessoa?.nome,
      d.Produto?.nome,
      parseInt(d.quantidade),
      Number(d.Produto?.preco),
      Number(d.quantidade) * Number(d.Produto?.preco) || 0,
    ]);
  });

  return dados;
}

export default function RelEstoquePorFilial() {
  const { socket } = React.useContext(IoContext);
  const [count, setCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [historicoEstoque, setHistoricoEstoque] = React.useState([]);
  const [dini, setDini] = React.useState(getInicioMes().toISOString().split("T")[0]);
  const [dend, setDend] = React.useState(getFinalMes().toISOString().split("T")[0]);
  const [FilialId, setFilialId] = React.useState("");

  React.useEffect(() => {
    setLoading(true);
    let where = {};
    if (!dini || !dend) {
      toast.error("Insira as datas inicial e final");
      setLoading(false);
      return;
    }
    where.dataLocacao_between = [dini, dend];
    socket.emit(
      "Locacao.findAll",
      {
        attributes: ["dataLocacao", "quantidade"],
        where,
        withContrato: {
          attributes: ["numero_contrato"],
          where: { FilialId },
          withPessoa: {
            attributes: ["nome"],
          },
        },
        withProduto: {
          attributes: ["nome", "preco"],
          paranoid: false,
        },
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          return toast.error(error);
        }
        setHistoricoEstoque(resp);
      }
    );
  }, [socket, dini, dend, count, FilialId]);

  return (
    <Container fluid>
      <Card className="shadow">
        <Card.Header className="bg-primary text-light">
          <h3 className="float-start">Relatório de Movimentção de Estoque por Filial</h3>
        </Card.Header>
        <Card.Body className="d-print-none">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setCount(count + 1);
              return false;
            }}
          >
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Filial</Form.Label>
                  <SelectFiliais disabled={loading} value={FilialId} onChange={(e) => setFilialId(e.target.value)} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Período</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="date"
                      value={dini}
                      placeholder="Inicial"
                      disabled={loading}
                      onChange={(e) => setDini(e.target.value)}
                    />
                    <Form.Control
                      type="date"
                      value={dend}
                      placeholder="Final"
                      disabled={loading}
                      onChange={(e) => setDend(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Ações</Form.Label>
                  <div>
                    <Button
                      variant="secondary"
                      title="Exportar CSV"
                      className="me-2"
                      disabled={loading}
                      onClick={() => downloadCsv(formataDados(historicoEstoque), "estoquefilial")}
                    >
                      Exportar CSV
                    </Button>

                    <Button title="Imprimir" className="me-2" disabled={loading} onClick={() => window.print()}>
                      Imprimir
                    </Button>
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <CardListaEstoquePorFilial historicoEstoque={historicoEstoque} />
    </Container>
  );
}
