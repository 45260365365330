import React from "react";
import Button from "react-bootstrap/Button";
import IoContext from "contextos/IoContext";
import FormularioListagem from "componentes/FormularioListagem";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import ModalAdicionarTarefa from "./ModalAdicionarTarefa";

export default function ServidoresLhisp() {
  const { socket } = React.useContext(IoContext);
  const navigate = useNavigate();
  const [count, setCount] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [pagina, setPagina] = React.useState(1);
  const [tamanhoPagina, setTamanhoPagina] = React.useState(10);
  const [texto, setTexto] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [status_ssh, setStatusSsh] = React.useState("ON");
  const [servidores, setServidores] = React.useState([]);
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    setTotal(0);
    setServidores([]);

    let where = {};
    if (texto) where.nome_like = `%${texto}%`;
    if (status !== "") where.status = status === "ON" ? true : false;
    if (status_ssh !== "") where.status_ssh = status_ssh === "ON" ? true : false;

    socket.emit(
      "Servidor.findAndCountAll",
      {
        attributes: ["id", "nome", "ip", "status", "status_ssh"],
        where,
        limit: tamanhoPagina,
        offset: tamanhoPagina * (pagina - 1),
      },
      (error, resp) => {
        if (error) {
          console.log("ERROR:", error);
          return toast.error("" + error);
        }

        setTotal(resp.count);
        setServidores(resp.rows);
      }
    );
  }, [socket, texto, status, status_ssh, tamanhoPagina, pagina, count]);

  return (
    <React.Fragment>
      <FormularioListagem
        titulo={`Servidores LHISP`}
        itens={servidores}
        qtdItens={total}
        pagina={pagina}
        setTexto={setTexto}
        setPagina={setPagina}
        titulos={["Id", "Nome", "Ip", "Status", "Status Agent"]}
        tamanhos={[{ md: 1 }, { md: 5 }, { md: 4 }, { md: 1 }, { md: 1 }]}
        onClick={(e) => {
          if (e.id === "add") {
            return setShow(true);
          }

          navigate(`${e.id}`);
        }}
        renderItem={(titulo, item) => {
          switch (titulo) {
            case "Id":
              return item.id;
            case "Nome":
              return item.nome;
            case "Ip":
              return item.ip;
            case "Status":
              return item.status ? (
                <span className="text-success">ON </span>
              ) : (
                <span className="text-danger">OFF </span>
              );
            case "Status Agent":
              return item.status_ssh ? (
                <span className="text-success">ON </span>
              ) : (
                <span className="text-danger">OFF </span>
              );
            default:
              return titulo;
          }
        }}
        renderBotoesCustom={() => {
          return (
            <React.Fragment>
              <Button className="ms-2">
                <FontAwesomeIcon
                  icon={faRedoAlt}
                  onClick={() => {
                    setCount(count + 1);
                  }}
                />
              </Button>
            </React.Fragment>
          );
        }}
        renderFiltros={() => {
          return (
            <React.Fragment>
              <Form.Group>
                <Form.Label>Registros por Página</Form.Label>
                <Form.Control
                  type="number"
                  min={5}
                  step={1}
                  value={tamanhoPagina}
                  onChange={(e) => {
                    setTamanhoPagina(+e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <option value=""></option>
                  <option value="ON">ON</option>
                  <option value="OFF">OFF</option>
                </Form.Control>
              </Form.Group>

              <Form.Group>
                <Form.Label>Status do LHAgent</Form.Label>
                <Form.Control
                  as="select"
                  value={status_ssh}
                  onChange={(e) => {
                    setStatusSsh(e.target.value);
                  }}
                >
                  <option value=""></option>
                  <option value="ON">ON</option>
                  <option value="OFF">OFF</option>
                </Form.Control>
              </Form.Group>
            </React.Fragment>
          );
        }}
      />

      <ModalAdicionarTarefa show={show} onHide={setShow} ServidoresId={(servidores || []).map((s) => s.id)} />
    </React.Fragment>
  );
}
