import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import IoContext from "contextos/IoContext";
import Listagem from "componentes/Listagem";
import { toast } from "react-toastify";
import { downloadCsv } from "misc/downloadCsv";
import { getFinalMes, getInicioMes } from "misc/lhdatas";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function RelatorioConsultasSpcBrasil() {
  const { socket } = React.useContext(IoContext);
  const [consultas, setConsultas] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [dataIni, setDataIni] = React.useState(getInicioMes().toISOString().split("T")[0]);
  const [dataEnd, setDataEnd] = React.useState(getFinalMes().toISOString().split("T")[0]);
  const [contador, setContador] = React.useState(0);

  React.useEffect(() => {
    setConsultas([]);
    setLoading(true);

    socket.emit(
      "ConsultasSpcBrasil.findAll",
      {
        where: {
          createdAt_between: [dataIni, dataEnd],
        },
        withUsuario: {
          attributes: ["nome"],
        },
        withPessoa: {
          attributes: ["nome"],
        },
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          return toast.error("" + error);
        }
        setConsultas(resp);
      }
    );
  }, [socket, dataIni, dataEnd, contador]);

  return (
    <Container fluid>
      <Card className="shadow">
        <Card.Header className="bg-primary text-light">
          <h3 className="float-start">Relatório de Consultar ao Spc Brasil</h3>
        </Card.Header>

        <Card.Body className="d-print-none">
          <Row>
            <Col sm={2}>
              <Form.Group>
                <Form.Label>Data Inicial</Form.Label>
                <Form.Control
                  type="date"
                  value={dataIni}
                  disabled={loading}
                  onChange={(e) => setDataIni(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Data Final</Form.Label>
                <Form.Control
                  type="date"
                  value={dataEnd}
                  disabled={loading}
                  onChange={(e) => setDataEnd(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    variant="info"
                    title="Listar"
                    className="me-2"
                    disabled={loading}
                    onClick={() => setContador(contador + 1)}
                  >
                    Atualizar
                  </Button>

                  <Button title="Listar" className="me-2" disabled={loading} onClick={() => window.print()}>
                    Imprimir
                  </Button>

                  <Button
                    title="Baixar Planilha"
                    variant="secondary"
                    className="ms-1"
                    onClick={() => {
                      downloadCsv(
                        [
                          ["Usuário", "Data/Hora", "Cliente"],
                          ...consultas.map((item) => {
                            return [item.Usuario?.nome, new Date(item.createdAt).toLocaleString(), item.Pessoa?.nome];
                          }),
                        ],
                        "RelatorioConsultasSpcBrasil"
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faFileCsv} className="me-1" /> Baixar Planilha
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>

        <Listagem
          titulos={["Usuário", "Data/Hora", "Cliente"]}
          tamanhos={[
            { md: 1, sm: 12 },
            { md: 2, sm: 12 },
            { md: 3, sm: 12 },
            { md: 1, sm: 12 },
            { md: 3, sm: 12 },
          ]}
          itens={consultas}
          renderItem={(titulo, item) => {
            switch (titulo) {
              case "Usuário":
                return item.Usuario?.nome;
              case "Data/Hora":
                return new Date(item.createdAt).toLocaleString();
              case "Cliente":
                return item.Pessoa?.nome;
              default:
                return titulo;
            }
          }}
          renderSummary={() => {
            return (
              <Col className="mt-2 text-end">
                {consultas?.length} Consulta{consultas?.length > 1 ? "s" : ""}
              </Col>
            );
          }}
        />
      </Card>
    </Container>
  );
}
