import React from "react";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import IoContext from "../../../../contextos/IoContext";

export default function SelectFuncionarioComVeiculo(props) {
  const { socket } = React.useContext(IoContext);
  const { id, value, disabled, pular } = props;
  const [funcionarios, setFuncionarios] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    socket.emit(
      "Funcionario.findAll",
      {
        attributes: ["id", "nome", "cobliDeviceId"],
        order: [["NOME", "ASC"]],
        where: {
          cobliDeviceId_ne: null,
        },
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          toast.error(error);
        } else {
          setFuncionarios(resp.filter((f) => !!f.cobliDeviceId));
        }
      }
    );
  }, [socket]);

  return (
    <Form.Control
      as="select"
      id={id || "FuncionarioId"}
      value={value}
      disabled={loading || disabled}
      {...props}
      onChange={(e) => {
        props.onSelect(funcionarios.find((f) => +f.id === +e.target.value));
      }}
    >
      <option value=""></option>
      {(funcionarios || []).map((funcionario) => {
        if (pular) {
          if (Array.isArray(pular)) {
            if (pular.indexOf(funcionario.id)) {
              return false;
            }
          } else {
            if (+pular === +funcionario.id) {
              return false;
            }
          }
        }
        return (
          <option key={funcionario.id} value={funcionario.id}>
            {funcionario.nome} [Veículo: {funcionario.cobliDeviceId}]
          </option>
        );
      })}
    </Form.Control>
  );
}
