import React from 'react';
import Form from 'react-bootstrap/Form';

export default function CampoCnpj(props) {
    const { onChange } = props;
    return <Form.Control
            {...props}
            maxLength={18}
            onChange={ e => {
                const soNumeros = e.target.value.replace(/[^\d]/g, '')
                if(!soNumeros) onChange(soNumeros);

                let cnpj = '';
                cnpj += soNumeros.slice(0,2)
        
                if(soNumeros.length > 2)
                    cnpj += '.' + soNumeros.slice(2,5)
        
                if(soNumeros.length > 5)
                    cnpj += '.' + soNumeros.slice(5,8)
        
                if(soNumeros.length > 8)
                    cnpj += '/' + soNumeros.slice(8,12)
        
                if(soNumeros.length > 12)
                    cnpj += '-' + soNumeros.slice(12,14)
        
                onChange(cnpj);
            }}
        />
}