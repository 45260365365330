import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import BotaoApagar from 'componentes/BotaoApagar';
import CabecalhoCadastro from 'componentes/CabecalhoCadastro';
import { toast } from 'react-toastify';
import IoContext from 'contextos/IoContext';
import { useNavigate, useParams } from 'react-router';

export default function FormaDePagamento(){
    const params = useParams();
    const navigate = useNavigate();
    const { socket } = React.useContext(IoContext);
    const [loading, setLoading] = React.useState(false);
    const id = parseInt(params.id);
    const [descricao, setDescricao] = React.useState("");
    const [parcelas, setParcelas] = React.useState(0);
    const [entrada, setEntrada] = React.useState(0);
    const [juros, setJuros] = React.useState(0);

    React.useEffect(()=>{
        setLoading(true);
        socket.emit("FormaPagamento.findOne", { 
            where: { id }
        }, (error, resp) => {
            setLoading(false);
            if(error) {
                toast.error(error);
            } else {
                const formaDePagamento = resp || {};
                setDescricao(formaDePagamento.descricao);
                setParcelas(formaDePagamento.parcelas);
                setEntrada(formaDePagamento.entrada);
                setJuros(formaDePagamento.juros);
            }
        });
    }, [id, socket]);

    return <Container fluid className="pt-3">
        <Card>
            <CabecalhoCadastro 
                id={id} disabled={loading} titulo='Forma de Pagamento'
                salvar={ e => {
                    e.preventDefault();
                    setLoading(true);
                    socket.emit("FormaPagamento.salvar", {
                        id, descricao, parcelas, entrada, juros
                    }, (error, resp) =>{
                        setLoading(false);
                        if(error) {
                            toast.error(''+error);
                        } else {
                            toast.success("Forma de Pagamento salva com Sucesso !");
                            navigate(-1);
                        }
                    });
                }}
                />

            <Card.Body>
                <Row>
                    <Col sm={3}>
                        <Form.Group>
                            <Form.Label>Id</Form.Label>
                            <Form.Control  value={isNaN(id) ? "" : id} readOnly />
                        </Form.Group>
                    </Col>

                    <Col sm={9}>
                        <Form.Group>
                            <Form.Label>Descrição</Form.Label>
                            <Form.Control 
                                disabled={loading}
                                value={descricao} 
                                onChange={ e => setDescricao(e.target.value) }
                                autoFocus
                                />
                        </Form.Group>
                    </Col>
                </Row>
                
                <Row>
                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Parcelas</Form.Label>
                            <Form.Control 
                                disabled={loading}
                                type='number'
                                min={0} max={99} step={1}
                                value={parcelas} 
                                onChange={ e => {
                                    setDescricao(sugerirDescricao({ entrada, parcelas: e.target.value, juros }));
                                    setParcelas(+e.target.value);
                                }}
                                autoFocus
                                />
                        </Form.Group>
                    </Col>

                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Entrada (%)</Form.Label>
                            <Form.Control 
                                disabled={loading}
                                type='number'
                                min={0} max={100} step={1}
                                value={entrada} 
                                onChange={ e => {
                                    setDescricao(sugerirDescricao({ entrada: e.target.value, parcelas, juros }));
                                    setEntrada(+e.target.value);
                                }}
                                autoFocus
                                />
                        </Form.Group>
                    </Col>

                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label>Juros (%)</Form.Label>
                            <Form.Control 
                                disabled={loading}
                                type='number'
                                min={0} max={100} step={1}
                                value={juros} 
                                onChange={ e => {
                                    setDescricao(sugerirDescricao({ entrada, parcelas, juros: e.target.value }));
                                    setJuros(+e.target.value);
                                }}
                                autoFocus
                                />
                        </Form.Group>
                    </Col>
                </Row>
            </Card.Body>

            <Card.Footer>
                { isNaN(id) ? false : <BotaoApagar 
                    disabled={loading}
                    apagar={ e => {
                        setLoading(true);
                        socket.emit("FormaPagamento.apagar", {
                            id 
                        }, (error, resp) =>{
                            setLoading(false);
                            if(error) {
                                toast.error(''+error);
                            } else {
                                toast.success("Forma de Pagamento removida com Sucesso !");
                                navigate(-1);
                            }
                        });
                    }}
                    /> }
            </Card.Footer>
        </Card>
    </Container>;
}

const sugerirDescricao = function ({ entrada, parcelas, juros }) {
    parcelas = isNaN(parcelas) ? 0 : +parcelas;

    if(+entrada===100) {
        return "A VISTA";
    }

    let sugestao = +entrada > 0 ? "ENTRADA + " : "";
    sugestao += +parcelas > 0 ? `${parcelas}X` : "";
    sugestao += +juros > 0 ? "" : " SEM JUROS";

    return sugestao;
}