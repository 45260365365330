import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Listagem from "./Listagem";

export default function FormProcurar({
  disabled,
  titulo,
  titulos,
  itens,
  renderItem,
  onSearch,
  onSelect,
  tamanhos = [],
  required = false,
}) {
  const [texto, setTexto] = React.useState("");
  const loading = false;

  return (
    <React.Fragment>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSearch(texto);
        }}
        className="mb-2"
      >
        <Form.Label>{titulo || "Procurar"}</Form.Label>
        <InputGroup>
          <Form.Control
            value={texto}
            required={required}
            autoFocus
            onChange={(e) => setTexto(e.target.value)}
          />

          <Button
            type="submit"
            disabled={disabled || loading}
            variant="primary"
          >
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </InputGroup>
      </Form>

      <Listagem
        titulos={titulos}
        itens={itens || []}
        tamanhos={tamanhos}
        renderItem={renderItem}
        onClick={(item) => {
          onSelect(item);
        }}
      />
    </React.Fragment>
  );
}
