import React from "react";
import CampoProcurar from "../../componentes/CampoProcurar";
import IoContext from "../../contextos/IoContext";

export default function CampoProcurarChipAloFone({
  titulo = "Selecionar Patrimônio",
  value,
  onSelect,
  disabled,
  required,
  AlmoxarifadoId,
  ProdutoId,
}) {
  const { socket } = React.useContext(IoContext);
  const [produtos, setPatrimonios] = React.useState([]);
  const loading = null;

  const getChips = React.useCallback(
    (texto) => {
      socket.emit(
        "Patrimonio.findAll",
        {
          where: {
            VendaId: null,
            ContratoId: null,
            numeroSerie_like: `${texto}%`,
          },
          withProduto: {},
          limit: 25,
        },
        (error, resp) => {
          if (error) {
            console.log("Patrimonio.findAll Error:", error);
          }

          setPatrimonios(resp);
        }
      );
    },
    [socket]
  );

  return (
    <CampoProcurar
      titulo={titulo}
      disabled={disabled || loading || !AlmoxarifadoId || !ProdutoId}
      value={value || ""}
      onSelect={onSelect}
      required={required}
      titulos={["Id", "ICC ID"]}
      itens={produtos}
      renderItem={(titulo, item) => {
        switch (titulo) {
          case "Id":
            return item.id;
          case "ICC ID":
            return item.numeroSerie;
          default:
            return titulo;
        }
      }}
      onSearch={getChips}
    />
  );
}
