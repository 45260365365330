import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import BotaoApagar from '../../componentes/BotaoApagar';
import CampoCep from '../../componentes/CampoCep';
import CampoDoc1 from '../../componentes/CampoDoc1';
import CampoTelefone from '../../componentes/CampoTelefone';
import CabecalhoCadastro from '../../componentes/CabecalhoCadastro';
import AuthContext from '../../contextos/AuthContext';
import IoContext from '../../contextos/IoContext';
import ModalConfirmarPreCadastro from './ModalConfirmarPreCadastro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router';

export default function FormaDePagamento() {
    const { socket } = React.useContext(IoContext);
    const [loading, setLoading] = React.useState(false);
    const { usuario } = React.useContext(AuthContext);

    const params = useParams();
    const navigate = useNavigate();
    const id = parseInt(params.id);
    const [preCadastro, setPreCadastro] = React.useState({});
    const [AnexoId, setAnexoId] = React.useState(0);
    const [showConfirmar, setShowConfirmar] = React.useState(false);

    React.useEffect(() => {
        if (!id || isNaN(id)) return;

        setLoading(true);
        socket.emit("PreCadastro.findOne", {
            where: { id },
            withVendedor: {
                attributes: ['id', 'nome']
            },
            withPlano: {},
            withAnexos: {}
        }, (error, preCadastro) => {
            setLoading(false);
            if (error) {
                toast.error(error);
            } if (!preCadastro) {
                toast.warning(`Pré-Cadastro não encontrado [${id}]`);
            } else {
                setPreCadastro(preCadastro);
            }
        });
    }, [id, setLoading, socket]);

    return <Container fluid className="pt-3">
        <Form onSubmit={e => {
            e.preventDefault();

            setLoading(true);
            socket.emit("PreCadastro.salvar", preCadastro, (error, resp) => {
                setLoading(false);
                if (error) {
                    toast.error(error);
                } else {
                    toast.success("Pré-cadastro salvo com Sucesso!");
                    navigate(-1);
                }
            });
        }}>
            <Card>
                <CabecalhoCadastro
                    id={id}
                    disabled={loading}
                    titulo='Pre Cadastro'
                    salvar={() => {

                    }}
                />

                <Card.Body>
                    <Row>
                        <Col sm={3}>
                            <Form.Group>
                                <Form.Label>Id</Form.Label>
                                <Form.Control value={isNaN(id) ? "" : id} readOnly />
                            </Form.Group>
                        </Col>

                        <Col sm={12}>
                            <Form.Group>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control required
                                    disabled={loading} autoFocus value={preCadastro.nome || ''}
                                    onChange={e => setPreCadastro({ ...preCadastro, nome: e.target.value })}
                                />
                            </Form.Group>
                        </Col>

                        <Col sm={6}>
                            <Form.Group>
                                <Form.Label>Conhecido Por</Form.Label>
                                <Form.Control
                                    disabled={loading} autoFocus value={preCadastro.conhecido_por || ''}
                                    onChange={e => setPreCadastro({ ...preCadastro, conhecido_por: e.target.value })}
                                />
                            </Form.Group>
                        </Col>

                        <Col sm={6}>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email"
                                    disabled={loading} value={preCadastro.email || ''}
                                    onChange={e => setPreCadastro({ ...preCadastro, email: e.target.value })}
                                />
                            </Form.Group>
                        </Col>

                        <Col sm={6}>
                            <Form.Group>
                                <Form.Label>Nome do Pai</Form.Label>
                                <Form.Control
                                    disabled={loading} autoFocus value={preCadastro.nome_pai || ''}
                                    onChange={e => setPreCadastro({ ...preCadastro, nome_pai: e.target.value })}
                                />
                            </Form.Group>
                        </Col>

                        <Col sm={6}>
                            <Form.Group>
                                <Form.Label>Nome da Mae</Form.Label>
                                <Form.Control
                                    disabled={loading} autoFocus value={preCadastro.nome_mae || ''}
                                    onChange={e => setPreCadastro({ ...preCadastro, nome_mae: e.target.value })}
                                />
                            </Form.Group>
                        </Col>

                        <Col sm={4}>
                            <CampoDoc1
                                disabled={loading} value={preCadastro.doc1 || ''}
                                onChange={txt => setPreCadastro({ ...preCadastro, doc1: txt })}
                            />
                        </Col>

                        <Col sm={4}>
                            <Form.Group>
                                <Form.Label>Rg / Inscrição Estadual</Form.Label>
                                <Form.Control
                                    disabled={loading} value={preCadastro.doc2 || ''}
                                    onChange={e => setPreCadastro({ ...preCadastro, doc2: e.target.value })}
                                    autoFocus
                                />
                            </Form.Group>
                        </Col>

                        <Col sm={4}>
                            <Form.Label>Data de Nascimento</Form.Label>
                            <Form.Control type="date"
                                disabled={loading} value={preCadastro.data_nascimento || ''}
                                onChange={e => setPreCadastro({ ...preCadastro, data_nascimento: e.target.value })}
                            />
                        </Col>

                        <Col sm={4}>
                            <CampoTelefone label="Telefone 1" required
                                disabled={loading} value={preCadastro.telefone1 || ''}
                                onChange={e => setPreCadastro({ ...preCadastro, telefone1: e.target.value })}
                            />
                        </Col>

                        <Col sm={4}>
                            <CampoTelefone label="Telefone 2"
                                disabled={loading} value={preCadastro.telefone2 || ''}
                                onChange={e => setPreCadastro({ ...preCadastro, telefone2: e.target.value })}
                            />
                        </Col>

                        <Col sm={4}>
                            <CampoTelefone label="Whatsapp"
                                disabled={loading} value={preCadastro.whatsapp || ''}
                                onChange={e => setPreCadastro({ ...preCadastro, whatsapp: e.target.value })}
                            />
                        </Col>

                        <Col sm={6}>
                            <Form.Group>
                                <Form.Label>Plano Desejado</Form.Label>
                                <Form.Control
                                    disabled={loading} value={preCadastro.planoDesejado || ''}
                                    onChange={e => setPreCadastro({ ...preCadastro, planoDesejado: e.target.value })}
                                />
                            </Form.Group>
                        </Col>

                        <Col sm={6}>
                            <Form.Group>
                                <Form.Label>Observacao</Form.Label>
                                <Form.Control
                                    disabled={loading} value={preCadastro.observacao || ''}
                                    onChange={e => setPreCadastro({ ...preCadastro, observacao: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Body>


                <Card.Header className="border-top">Endereço</Card.Header>
                <Card.Body>
                    <Row>
                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Uf</Form.Label>
                                <Form.Control required
                                    disabled={loading} value={preCadastro.uf || ''}
                                    onChange={e => setPreCadastro({ ...preCadastro, uf: e.target.value })}
                                />
                            </Form.Group>
                        </Col>

                        <Col sm={5}>
                            <Form.Group>
                                <Form.Label>Cidade</Form.Label>
                                <Form.Control required
                                    disabled={loading} value={preCadastro.cidade || ''}
                                    onChange={e => setPreCadastro({ ...preCadastro, cidade: e.target.value })}
                                />
                            </Form.Group>
                        </Col>

                        <Col sm={5}>
                            <Form.Group>
                                <Form.Label>Bairro</Form.Label>
                                <Form.Control required
                                    disabled={loading} value={preCadastro.bairro || ''}
                                    onChange={e => setPreCadastro({ ...preCadastro, bairro: e.target.value })}
                                />
                            </Form.Group>
                        </Col>

                        <Col sm={6}>
                            <Form.Group>
                                <Form.Label>Logradouro</Form.Label>
                                <Form.Control required
                                    disabled={loading} value={preCadastro.logradouro || ''}
                                    onChange={e => setPreCadastro({ ...preCadastro, logradouro: e.target.value })}
                                />
                            </Form.Group>
                        </Col>

                        <Col sm={2}>
                            <Form.Group>
                                <Form.Label>Número</Form.Label>
                                <Form.Control maxLength={10} required
                                    disabled={loading} value={preCadastro.numero || ''}
                                    onChange={e => setPreCadastro({ ...preCadastro, numero: e.target.value })}
                                />
                            </Form.Group>
                        </Col>

                        <Col sm={4}>
                            <CampoCep
                                disabled={loading} value={preCadastro.cep || ''}
                                onChange={txt => setPreCadastro({ ...preCadastro, cep: txt })}
                            />
                        </Col>
                    </Row>
                </Card.Body>

                <Card.Header className="border-top">Anexos</Card.Header>

                <Table hover responsive>
                    <tbody>
                        <tr>
                            <th>Id</th>
                            <th>Arquivo</th>
                            <th>Ações</th>
                        </tr>

                        {(preCadastro.PreCadastroAnexos || []).map(anexo => {
                            return <tr key={anexo.id}>
                                <td>{anexo.id}</td>
                                <td>{anexo.arquivo}</td>
                                <td>
                                    <Button size="sm"
                                        title="Visualizar Anexo"
                                        disabled={loading}
                                        onClick={() => {
                                            setAnexoId(anexo.id);
                                        }}>
                                        <FontAwesomeIcon icon={faImage} />
                                        <span className="ms-2 d-none d-sm-inline">Visualizar Anexo</span>
                                    </Button>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </Table>


                <Card.Footer>
                    {isNaN(id) ? false : <BotaoApagar
                        disabled={loading}
                        apagar={e => {
                            setLoading(true);
                            socket.emit("PreCadastro.apagar", { id }, (error, resp) => {
                                setLoading(false);
                                if (error) {
                                    toast.error('' + error);
                                } else {
                                    toast.success("Pré-Cadastro removida com Sucesso !");
                                    navigate(-1);
                                }
                            });
                        }}
                    />}

                    {preCadastro.situacao === 1 ?
                        <Button variant="success" className="float-end"
                            disabled={loading} onClick={() => setShowConfirmar(true)}
                        >Confirmar Cadastro</Button> : false}
                </Card.Footer>
            </Card>
        </Form>

        <Modal show={AnexoId > 0} onHide={() => setAnexoId(0)} size='lg'>
            <Modal.Header closeButton>Anexo [{AnexoId}]</Modal.Header>
            <Modal.Body>
                {AnexoId > 0 ?
                    <Image src={`/api/PreCadastroAnexo.Download/${AnexoId}?token=${usuario.token}`} fluid />
                    : false}
            </Modal.Body>
        </Modal>

        <ModalConfirmarPreCadastro
            preCadastro={preCadastro} setPreCadastro={setPreCadastro}
            loading={loading} setLoading={setLoading}
            show={showConfirmar} disabled={loading} onHide={setShowConfirmar}
        />
    </Container>;
}