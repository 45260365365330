import React from "react";
import IoContext from "contextos/IoContext";
import { Card, Col, Container, Form, Row, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

export default function PortabilidadeCelular() {
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(true);
  const [login, setLogin] = React.useState("");
  const [senha, setSenha] = React.useState("");
  const [isAtivo, setAtivo] = React.useState(false);

  React.useEffect(() => {
    socket.emit(
      "Integracao.findOne",
      {
        where: {
          modulo: "PortabilidadeCelular",
        },
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          return toast.error(error);
        }

        try {
          const configuracoes = JSON.parse(resp.configuracoes || {});
          setLogin(configuracoes.login || "");
          setSenha(configuracoes.senha || "");
          setAtivo(resp.isAtivo);
        } catch (error) {
          console.error("PortabilidadeCelular Error nas Configurações:", error);
        }
      }
    );
  }, [socket]);
  return (
    <Container fluid>
      <Card>
        <Card.Header className="bg-primary">
          <Card.Title className="text-light">
            Portabilidade de Celular - Configuração da API
            <a
              className="btn btn-info btn-sm ms-2 float-end"
              href="https://portabilidadecelular.com"
              title="Site Portabilide de Celular !"
              target="_portabilidade_de_celular"
            >
              <FontAwesomeIcon icon={faLink} />
              <span className="ms-2 d-sm-none d-md-inline">Site Portabilidade de Celular</span>
            </a>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>Login:</Form.Label>
                <Form.Control value={login} onChange={(e) => setLogin(e.target.value)} maxLength={50} />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>Senha:</Form.Label>
                <Form.Control value={senha} onChange={(e) => setSenha(e.target.value)} type="password" maxLength={50} />
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group>
                <Form.Label>Ativo</Form.Label>
                <Form.Check
                  checked={isAtivo}
                  onChange={() => {
                    setAtivo(!isAtivo);
                  }}
                  disabled={loading}
                />
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    disabled={loading}
                    onClick={(e) => {
                      e.preventDefault();
                      setLoading(true);
                      socket.emit(
                        "Integracao.Salvar",
                        {
                          modulo: "PortabilidadeCelular",
                          configuracoes: {
                            login,
                            senha,
                          },
                          isAtivo,
                        },
                        (error) => {
                          setLoading(false);
                          if (error) {
                            return toast.error(error);
                          }
                          toast.success("Configurações salvas com sucesso");
                        }
                      );
                    }}
                  >
                    Salvar
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}
