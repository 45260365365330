import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import IoContext from "contextos/IoContext";
import Listagem from "componentes/Listagem";
import { toast } from "react-toastify";
import { downloadCsv } from "misc/downloadCsv";
import { getFinalMes, getInicioMes } from "misc/lhdatas";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function RelContasReceber() {
  const { socket } = React.useContext(IoContext);
  const [contas, setContas] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [dataIni, setDataIni] = React.useState(getInicioMes().toISOString().split("T")[0]);
  const [dataEnd, setDataEnd] = React.useState(getFinalMes().toISOString().split("T")[0]);
  const [contador, setContador] = React.useState(0);

  React.useEffect(() => {
    setContas([]);
    setLoading(false);
    // if (contador <= 0) return;

    const tini = new Date().getTime();
    console.log("tini:", tini);
    socket.emit(
      "ContaReceber.findAll",
      {
        where: {
          createdAt_between: [dataIni, dataEnd],
        },
      },
      (error, resp) => {
        const tend = new Date().getTime();
        console.log("tend:", tend);
        console.log("Time:", tend - tini / 1000);

        setLoading(false);
        if (error) {
          return toast.error("" + error);
        }
        setContas(resp);
      }
    );
  }, [socket, dataIni, dataEnd, contador]);

  return (
    <Container fluid>
      <Card className="shadow">
        <Card.Header className="bg-primary text-light">
          <h3 className="float-start">Relatório de Contas a Receber</h3>
        </Card.Header>

        <Card.Body className="d-print-none">
          <Row>
            <Col sm={2}>
              <Form.Group>
                <Form.Label>Data de Vencimento</Form.Label>
                <Form.Control
                  type="date"
                  value={dataIni}
                  disabled={loading}
                  onChange={(e) => setDataIni(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Data de Vencimento</Form.Label>
                <Form.Control
                  type="date"
                  value={dataEnd}
                  disabled={loading}
                  onChange={(e) => setDataEnd(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    variant="info"
                    title="Listar"
                    className="me-2"
                    disabled={loading}
                    onClick={() => setContador(contador + 1)}
                  >
                    Atualizar
                  </Button>

                  <Button title="Listar" className="me-2" disabled={loading} onClick={() => window.print()}>
                    Imprimir
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>

        <Listagem
          titulos={[
            "Filial",
            "Contrato",
            "Cliente",
            "Descrição",
            "Nrdoc",
            "Vencimento",
            "Pagamento",
            "Tipo",
            "Valor",
            "Dsc",
            "Tarifa",
            "Valor",
            "Pago",
            "Tipo da Baixa",
          ]}
          tamanhos={[
            { md: 1, sm: 12 },
            { md: 2, sm: 12 },
            { md: 3, sm: 12 },
            { md: 1, sm: 12 },
            { md: 3, sm: 12 },
          ]}
          itens={contas}
          renderItem={(titulo, item) => {
            switch (titulo) {
              case "Usuário":
                return item.Usuario?.nome;
              case "Data/Hora":
                return new Date(item.createdAt).toLocaleString();
              case "Cliente":
                return item.Pessoa?.nome;
              default:
                return titulo;
            }
          }}
          renderSummary={() => {
            return (
              <Col className="mt-2 text-end">
                {contas?.length} Conta{contas?.length > 1 ? "s" : ""}
              </Col>
            );
          }}
        />
      </Card>
    </Container>
  );
}
