import React from 'react';
import { toast } from 'react-toastify';
import MultiItemSelect from '../../../../componentes/MultiItemSelect';
import IoContext from '../../../../contextos/IoContext';

export default function MultiFiliais({ filiais, setFiliais }) {
    const { socket } = React.useContext(IoContext);
    const [loading,  setLoading] = React.useState(false);
    const [itensPesquisa, setItensPesquisa] = React.useState([]);
    
    return <MultiItemSelect 
                itens={filiais} setItens={setFiliais} loading={loading}
                itensPesquisa={itensPesquisa} campoTexto='nome'
                titulos={['Id', 'Filial']}
                onSearch={ txt => {
                    setLoading(true);
                    setItensPesquisa([]);

                    socket.emit("Filial.findAll", {
                        where: {
                            nome_like: `%${txt}%`
                        }
                    }, (error, resp) => {
                        setLoading(false);
                        if(error) {
                            return toast.error(''+error);
                        }

                        setItensPesquisa(resp);
                    });
                }}

                renderItem={ (campo, item) => { 
                    switch(campo) {
                        case "Id": return item.id;
                        case "Filial": return item.nome;
                        default:
                            return campo;
                    }
                }}

                onSelect={ filial => {
                    const filiais2 = [...filiais];
                    let existe = false;
                    filiais2.forEach( f => {
                        if(f.id === filial.id) {
                            existe = true;
                        }
                    });

                    if(existe) return; // Registro Duplicado
                    
                    filiais2.push({
                        ...filial
                    });
                    setFiliais(filiais2);
                }}
            />
}