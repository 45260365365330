import React from "react";
import Container from "react-bootstrap/Container";
import { Routes, Route, useNavigate } from "react-router-dom";
import FonteDados from "./FonteDados";
import FonteDadosCsv from "./FonteDadosCsv";
import FonteDadosDb from "./FonteDadosDb";
import FonteDadosApi from "./FonteDadosApi";
import ImportacaoModulos from "./ImportacaoModulos";
import TituloContext from "contextos/TituloContext";
import ImportarModulo from "./ImportarModulo";
import ImportacaoProvider from "contextos/ImportacaoProvider";

export default function ImportacoesIndex() {
  const navigate = useNavigate();
  const { setTitulo } = React.useContext(TituloContext);
  const [fonteDados, setFonteDados] = React.useState(null);
  const [template, setTemplate] = React.useState(null);

  React.useEffect(() => {
    setTitulo("Importação de Dados");
  }, [setTitulo]);

  return (
    <ImportacaoProvider>
      <Container className="mt-2" fluid>
        <Routes>
          <Route path="/modulos" element={<ImportacaoModulos />} />

          <Route path="/modulos/:modulo" element={<ImportarModulo fonteDados={fonteDados} template={template} />} />

          <Route path="/fonte/csv" element={<FonteDadosCsv setFonteDados={setFonteDados} />} />

          <Route
            path="/fonte/database"
            element={<FonteDadosDb fonteDados={fonteDados} setFonteDados={setFonteDados} />}
          />

          <Route path="/fonte/api" element={<FonteDadosApi fonteDados={fonteDados} setFonteDados={setFonteDados} />} />

          <Route
            path="/"
            element={
              <FonteDados
                setTipoFonteDados={(tipo) => {
                  navigate(`fonte/${tipo}`);
                }}
                setTemplate={(template) => {
                  setTemplate(template);
                  setFonteDados(template.fonteDados);
                  navigate(`fonte/${template.fonteDados.tipo}`);
                }}
              />
            }
          />
        </Routes>
      </Container>
    </ImportacaoProvider>
  );
}
