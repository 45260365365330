import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import IoContext from 'contextos/IoContext';
import CabecalhoCadastro from 'componentes/CabecalhoCadastro';
import CampoCnpj from 'componentes/CampoCnpj';
import CampoMoeda from 'componentes/CampoMoeda';
import CampoTelefone from 'componentes/CampoTelefone';
import CampoProcurarEndereco from '../enderecos/CampoProcurarEndereco';
import BotaoApagar from '../../../../componentes/BotaoApagar'
import logo from 'images/lhprovedor.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import Container from 'react-bootstrap/Container';
import { useNavigate, useParams } from 'react-router';

export default function Filial(props) {
    const params = useParams();
    const navigate = useNavigate();
    const { socket } = React.useContext(IoContext);
    const id = parseInt(params.id);
    const [loading, setLoading] = React.useState(false);
    const [nome, setNome] = React.useState('');
    const [razao_social, setRazaoSocial] = React.useState('');
    const [telefone1, setTelefone1] = React.useState('');
    const [telefone2, setTelefone2] = React.useState('');
    const [endereco, setEndereco] = React.useState(null);
    const [EnderecoId, setEnderecoId] = React.useState(0);
    const [numero, setNumero] = React.useState('');
    const [ponto_de_referencia, setPontoReferencia] = React.useState('');
    const [cnpj, setCnpj] = React.useState('');
    const [inscricaoEstadual, setInscricaoEstadual] = React.useState('');
    const [inscricaoMunicipal, setInscricaoMunicipal] = React.useState('');
    const [logomarca, setLogomarca] = React.useState('');
    const [regimeTributario, setRegimeTributario] = React.useState(1);
    const [abatimentoBaseCalculo, setAbatimentoBaseCalculo] = React.useState(100);
    const [aliquotaIcms, setAliquotaIcms] = React.useState(0);
    const [aliquotaIss, setAliquotaIss] = React.useState(0);
    const [nfMensagem1, setNfMensagem1] = React.useState('');
    const [nfMensagem2, setNfMensagem2] = React.useState('');
    const [nfMensagem3, setNfMensagem3] = React.useState('');
    const [mensagemAbatimentoBc, setMensagemAbatimentoBc] = React.useState('');
    const [taxaMudancaEndereco, setTaxaMudancaEndereco] = React.useState(0);
    const fileRef = React.useRef(null);

    React.useEffect(function () {
        if (isNaN(id)) return;

        setLoading(true);
        socket.emit("Filial.findOne", {
            where: { id },
            withEndereco: {},
        }, (error, resp) => {
            setLoading(false);

            if (error) {
                toast.error('' + error);
            } else {
                setNome(resp.nome || "");
                setRazaoSocial(resp.razao_social || "");
                setTelefone1(resp.telefone1 || "");
                setTelefone2(resp.telefone2 || "");
                setEnderecoId(resp.EnderecoId || 0);
                setNumero(resp.numero || "");
                setPontoReferencia(resp.ponto_de_referencia || "");
                setCnpj(resp.cnpj || "");
                setInscricaoEstadual(resp.inscricaoEstadual || '');
                setInscricaoMunicipal(resp.inscricaoMunicipal || '');
                setRegimeTributario(resp.regimeTributario || "");
                setAbatimentoBaseCalculo(resp.abatimentoBaseCalculo || "");
                setAliquotaIcms(resp.aliquotaIcms || "");
                setAliquotaIss(resp.aliquotaIss || "");
                setNfMensagem1(resp.nfMensagem1 || "");
                setNfMensagem2(resp.nfMensagem2 || "");
                setNfMensagem3(resp.nfMensagem3 || "");
                setMensagemAbatimentoBc(resp.mensagemAbatimentoBc || "");
                setTaxaMudancaEndereco(resp.taxaMudancaEndereco || 0);
                setEndereco(resp.Endereco)

                socket.emit("Filial.GetLogomarca", {
                    id: resp.id
                }, (error, logo) => {
                    if (error) {
                        toast.error('' + error);
                    } else if (logo) {
                        if (logo && !logo.startsWith('data:image/')) {
                            logo = 'data:image/png;base64,' + logo;
                        }
                        setLogomarca(logo);
                    } else {
                        setLogomarca(null);
                    }
                })
            }
        });
    }, [id, socket]);

    return <Container fluid className="pt-3">
        <Card>
            <input type="file" className="d-none" ref={fileRef} onChange={e => {
                var reader = new FileReader();
                reader.onload = function () {
                    setLogomarca(reader.result + "");
                };
                reader.readAsDataURL(e.target.files[0]);
            }} />

            <CabecalhoCadastro
                id={id} disabled={loading} titulo='Filial'
                salvar={e => {
                    e.preventDefault();
                    setLoading(true);
                    socket.emit("Filial.salvar", {
                        id,
                        nome, razao_social,
                        telefone1, telefone2, logomarca,
                        EnderecoId, numero, ponto_de_referencia,
                        cnpj, inscricaoEstadual, inscricaoMunicipal,
                        regimeTributario: +regimeTributario,
                        abatimentoBaseCalculo: +abatimentoBaseCalculo,
                        aliquotaIcms: +aliquotaIcms,
                        aliquotaIss: +aliquotaIss,
                        nfMensagem1, nfMensagem2, nfMensagem3,
                        mensagemAbatimentoBc,
                        taxaMudancaEndereco: +taxaMudancaEndereco
                    }, (error, resp) => {
                        setLoading(false);
                        if (error) {
                            toast.error(error);
                        } else {
                            toast.success("Filial salva com Sucesso !");
                            navigate(-1);
                        }
                    });
                }}
            />

            <Card.Body>
                <Row>
                    <Col sm={9}>
                        <Row>
                            <Col sm={3}>
                                <Form.Group>
                                    <Form.Label>Id</Form.Label>
                                    <Form.Control value={isNaN(id) ? "" : id} readOnly />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control
                                        value={nome}
                                        required disabled={loading}
                                        onChange={e => setNome(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>

                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Razão Social</Form.Label>
                                    <Form.Control
                                        value={razao_social}
                                        required disabled={loading}
                                        onChange={e => setRazaoSocial(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>

                            <Col sm={4}>
                                <CampoTelefone
                                    label="Telefone 1"
                                    value={telefone1} disabled={loading}
                                    onChange={e => setTelefone1(e.target.value)}
                                />
                            </Col>

                            <Col sm={4}>
                                <CampoTelefone
                                    label="Telefone 2"
                                    value={telefone2} disabled={loading}
                                    onChange={e => setTelefone2(e.target.value)}
                                />
                            </Col>

                            <Col sm={4}>
                                <Form.Group>
                                    <Form.Label>Tarifa de Mudança de Endereço (R$)</Form.Label>
                                    <CampoMoeda
                                        value={taxaMudancaEndereco} disabled={loading}
                                        onChange={e => setTaxaMudancaEndereco(e.target.value)} />
                                </Form.Group>
                            </Col>

                            <Col sm={4}>
                                <Form.Group>
                                    <Form.Label>Cnpj</Form.Label>
                                    <CampoCnpj
                                        value={cnpj}
                                        required disabled={loading}
                                        onChange={e => setCnpj(e)}
                                    />
                                </Form.Group>
                            </Col>

                            <Col sm={4}>
                                <Form.Group>
                                    <Form.Label>Inscrição Estadual</Form.Label>
                                    <Form.Control
                                        value={inscricaoEstadual}
                                        required disabled={loading} maxLength={25}
                                        onChange={e => setInscricaoEstadual(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>

                            <Col sm={4}>
                                <Form.Group>
                                    <Form.Label>Inscrição Municipal</Form.Label>
                                    <Form.Control
                                        value={inscricaoMunicipal}
                                        required disabled={loading} maxLength={25}
                                        onChange={e => setInscricaoMunicipal(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>

                            <Col sm={3}>
                                <Form.Group>
                                    <Form.Label>Regime Fiscal</Form.Label>
                                    <Form.Control as="select"
                                        value={regimeTributario}
                                        required disabled={loading}
                                        onChange={e => setRegimeTributario(+e.target.value)}
                                    >
                                        <option value="1">1 - Simples Nacional</option>
                                        <option value="2">2 - Lucro Presumido</option>
                                        <option value="3">3 - Lucro Real</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>

                            <Col sm={3}>
                                <Form.Group>
                                    <Form.Label>Aliquota do ISS (%)</Form.Label>
                                    <Form.Control
                                        value={aliquotaIss} type="number" step={0.01} min={0} max={99}
                                        required disabled={loading}
                                        onChange={e => setAliquotaIss(parseFloat(e.target.value))}
                                    />
                                </Form.Group>
                            </Col>

                            <Col sm={3}>
                                <Form.Group>
                                    <Form.Label>Aliquota do ICMS (%)</Form.Label>
                                    <Form.Control
                                        value={aliquotaIcms} type="number" step={0.01} min={0} max={99}
                                        required disabled={loading}
                                        onChange={e => setAliquotaIcms(parseFloat(e.target.value))}
                                    />
                                </Form.Group>
                            </Col>

                            <Col sm={3}>
                                <Form.Group>
                                    <Form.Label>Abat. da B. de Cálc.(%)</Form.Label>
                                    <Form.Control
                                        value={abatimentoBaseCalculo} type="number" step={0.01} min={0} max={99}
                                        required disabled={loading}
                                        onChange={e => setAbatimentoBaseCalculo(parseFloat(e.target.value))}
                                    />
                                </Form.Group>
                            </Col>

                            <Col sm={12}>
                                <Form.Group>
                                    <Form.Label>Observação 1 da Nota Fiscal</Form.Label>
                                    <Form.Control
                                        value={nfMensagem1}
                                        required disabled={loading} maxLength={100}
                                        onChange={e => setNfMensagem1(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>

                            <Col sm={12}>
                                <Form.Group>
                                    <Form.Label>Observação 2 da Nota Fiscal</Form.Label>
                                    <Form.Control
                                        value={nfMensagem2}
                                        required disabled={loading} maxLength={100}
                                        onChange={e => setNfMensagem2(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>

                            <Col sm={12}>
                                <Form.Group>
                                    <Form.Label>Observação 3 da Nota Fiscal</Form.Label>
                                    <Form.Control
                                        value={nfMensagem3}
                                        required disabled={loading} maxLength={100}
                                        onChange={e => setNfMensagem3(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>


                            <Col sm={8}>
                                <Form.Group>
                                    <Form.Label>Endereço</Form.Label>
                                    <CampoProcurarEndereco
                                        value={endereco}
                                        disabled={loading}
                                        onSelect={e => {
                                            if (e) {
                                                setEndereco(e);
                                                setEnderecoId(e.id);
                                            } else {
                                                setEndereco(null);
                                                setEnderecoId(0);
                                            }
                                        }}
                                    />
                                </Form.Group>
                            </Col>

                            <Col sm={4}>
                                <Form.Group>
                                    <Form.Label>Número</Form.Label>
                                    <Form.Control
                                        disabled={loading}
                                        value={numero} onChange={e => setNumero(e.target.value)} />
                                </Form.Group>
                            </Col>

                            <Col sm={12}>
                                <Form.Group>
                                    <Form.Label>Ponto de Referência</Form.Label>
                                    <Form.Control
                                        disabled={loading}
                                        value={ponto_de_referencia} onChange={e => setPontoReferencia(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={3}>
                        <Form.Group>
                            <Form.Label>Logomarca</Form.Label>

                            <div>
                                <Image src={logomarca ? logomarca : logo} className="b-all"
                                    thumbnail fluid onClick={() => {
                                        fileRef.current.click();
                                    }} />

                                <Button className="mt-2 float-end" title="Selecionar Logomarca" disabled={loading} onClick={() => {
                                    fileRef.current.click();
                                }}>
                                    <FontAwesomeIcon icon={faSearch} />
                                </Button>
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer>
                {isNaN(id) ? false : <BotaoApagar
                    disabled={loading}
                    apagar={e => {
                        setLoading(true)
                        socket.emit("Filial.apagar", {
                            id
                        }, (error, resp) => {
                            setLoading(false)
                            if (error) {
                                toast.error('' + error)
                            } else {
                                toast.success("Filial Removida com Sucesso !")
                                navigate(-1)
                            }
                        })
                    }}
                />}
            </Card.Footer>
        </Card>
    </Container>
}
