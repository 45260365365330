import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { getInicioMes } from "misc/lhdatas";
import { downloadCsv } from "misc/downloadCsv";
import React from "react";
import { Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";

export default function CardCrescimentoChurn({
  projecoes,
  meses,
  ano,
  titulo = "",
  campoInicioMes,
  campoAdicoesAoMes,
  campoSuspensoesAoMes,
  campoCancelamentosAoMes,
}) {
  let crescimentoTotal = 0;
  let balancoTotal = 0;
  let churnTotal = 0;
  let adicioesTotal = 0;
  let totalCancelamentos = 0;
  let totalSuspensoes = 0;
  let nMesesChurn = 0;

  function geraArrayCsv(meses, projecoes, titulo) {
    var tmpLinhaDadosCsv = [];

    function retornaTitulosCsv(meses) {
      const array = [];
      array.push("Indicador");
      meses.map((c) => {
        const d = new Date();
        d.setMonth(c - 1);
        return array.push(d.toLocaleString("default", { month: "long" }).toUpperCase());
      });
      return array;
    }

    function geraLinhasCsv(projecoes, meses, titulo) {
      tmpLinhaDadosCsv.push(`${titulo} no Início do Mês`);
      meses.forEach((mes) => {
        tmpLinhaDadosCsv.push(getValor({ projecoes, mes, campo: campoInicioMes }));
      });
      arrayCsv.push(tmpLinhaDadosCsv);
      tmpLinhaDadosCsv = [];
      tmpLinhaDadosCsv.push(`Adições ao Longo do Mês`);
      meses.forEach((mes) => {
        tmpLinhaDadosCsv.push(getValor({ projecoes, mes, campo: campoAdicoesAoMes }));
      });
      arrayCsv.push(tmpLinhaDadosCsv);
      tmpLinhaDadosCsv = [];
      tmpLinhaDadosCsv.push("Cancelamentos Ao Longo do Mês");
      meses.forEach((mes) => {
        tmpLinhaDadosCsv.push(getValor({ projecoes, mes, campo: campoCancelamentosAoMes }));
      });
      arrayCsv.push(tmpLinhaDadosCsv);
      tmpLinhaDadosCsv = [];
      tmpLinhaDadosCsv.push("Suspenções ao Longo do Mês");
      meses.forEach((mes) => {
        tmpLinhaDadosCsv.push(getValor({ projecoes, mes, campo: campoSuspensoesAoMes }));
      });
      arrayCsv.push(tmpLinhaDadosCsv);
      tmpLinhaDadosCsv = [];
      tmpLinhaDadosCsv.push(`Balanço de ${titulo} no Mês`);
      meses.forEach((mes) => {
        const adicoesAoMes = getValor({ projecoes, mes, campo: campoAdicoesAoMes });
        const cancelamentosAoMes = getValor({ projecoes, mes, campo: campoCancelamentosAoMes });
        const suspensoesAoMes = getValor({ projecoes, mes, campo: campoSuspensoesAoMes });
        const balanco = adicoesAoMes - cancelamentosAoMes - suspensoesAoMes;
        tmpLinhaDadosCsv.push(balanco);
      });
      arrayCsv.push(tmpLinhaDadosCsv);
      tmpLinhaDadosCsv = [];
      tmpLinhaDadosCsv.push(`${titulo} ao Fim do Mês`);
      meses.forEach((mes) => {
        const inicioMes = getValor({ projecoes, mes, campo: campoInicioMes });
        const adicoesAoMes = getValor({ projecoes, mes, campo: campoAdicoesAoMes });
        const cancelamentosAoMes = getValor({ projecoes, mes, campo: campoCancelamentosAoMes });
        const suspensoesAoMes = getValor({ projecoes, mes, campo: campoSuspensoesAoMes });
        tmpLinhaDadosCsv.push(inicioMes + adicoesAoMes - cancelamentosAoMes - suspensoesAoMes);
      });
      arrayCsv.push(tmpLinhaDadosCsv);
      tmpLinhaDadosCsv = [];
      tmpLinhaDadosCsv.push("Taxa de Crescimento (%)");
      meses.forEach((mes) => {
        const inicioMes = getValor({ projecoes, mes, campo: campoInicioMes });
        const adicoesAoMes = getValor({ projecoes, mes, campo: campoAdicoesAoMes });
        const cancelamentosAoMes = getValor({ projecoes, mes, campo: campoCancelamentosAoMes });
        const suspensoesAoMes = getValor({ projecoes, mes, campo: campoSuspensoesAoMes });
        const balancoAoMes = adicoesAoMes - cancelamentosAoMes - suspensoesAoMes;
        const taxaCrescimento = inicioMes > 0 ? (balancoAoMes / inicioMes) * 100 : 0;
        tmpLinhaDadosCsv.push(isNaN(parseFloat("" + taxaCrescimento)) ? "" : taxaCrescimento.toFixed(2));
      });
      arrayCsv.push(tmpLinhaDadosCsv);
      tmpLinhaDadosCsv = [];
      tmpLinhaDadosCsv.push("Churn");
      meses.forEach((mes) => {
        const inicioMes = getValor({ projecoes, mes, campo: campoInicioMes });
        const cancelamentosAoMes = getValor({ projecoes, mes, campo: campoCancelamentosAoMes });
        const suspensoesAoMes = getValor({ projecoes, mes, campo: campoSuspensoesAoMes });
        const churn = inicioMes > 0 ? ((cancelamentosAoMes + suspensoesAoMes) / inicioMes) * 100 : 0;
        tmpLinhaDadosCsv.push(isNaN(parseFloat("" + churn)) ? "" : churn.toFixed(2));
      });
      arrayCsv.push(tmpLinhaDadosCsv);
    }

    const arrayCsv = [];
    arrayCsv.push(retornaTitulosCsv(meses));
    geraLinhasCsv(projecoes, meses, titulo);
    return arrayCsv;
  }

  return (
    <Card className="mt-3">
      <Card.Header className="bg-primary text-light">
        <h5 className="d-flex justify-content-between align-items-center">
          Dados dos {titulo}
          <Button
            title="Baixar Planilha"
            variant="success"
            size="sm"
            onClick={(e) => {
              downloadCsv(geraArrayCsv(meses, projecoes, titulo), `dados_dos_${titulo.toLowerCase()}_${ano}`);
            }}
          >
            <FontAwesomeIcon icon={faFileCsv} />
          </Button>
        </h5>
      </Card.Header>

      <Table size="sm">
        <tbody>
          <tr>
            <th>Indicador</th>
            {meses.map((c) => {
              const d = new Date();
              d.setMonth(c - 1);
              return <th key={c}>{d.toLocaleString("default", { month: "long" }).toUpperCase()}</th>;
            })}
            <th>Total</th>
          </tr>

          <tr>
            <th>{titulo} no Inicio do Mês</th>
            {meses.map((mes) => {
              return <td key={mes}>{getValor({ projecoes, mes, campo: campoInicioMes })}</td>;
            })}
            <th></th>
          </tr>
          <tr>
            <th className="text-success pl-4">Adições ao Longo do Mês</th>
            {meses.map((mes) => {
              const adicaoAoMes = getValor({ projecoes, mes, campo: campoAdicoesAoMes });
              adicioesTotal += +adicaoAoMes;
              return (
                <td key={mes} className="text-success">
                  {adicaoAoMes}
                </td>
              );
            })}
            <td className="text-success">{adicioesTotal ? adicioesTotal : ""}</td>
          </tr>
          <tr>
            <th className="text-danger pl-4">Cancelamentos ao Longo do Mês</th>
            {meses.map((mes) => {
              const cancelamentosAoMes = getValor({ projecoes, mes, campo: campoCancelamentosAoMes });
              totalCancelamentos += cancelamentosAoMes;
              return (
                <td key={mes} className="text-danger">
                  {cancelamentosAoMes}
                </td>
              );
            })}
            <td className="text-danger">{totalCancelamentos ? totalCancelamentos : ""}</td>
          </tr>
          <tr>
            <th className="text-warning pl-4">Suspensões ao Longo do Mês</th>
            {meses.map((mes) => {
              const suspensoesAoMes = getValor({ projecoes, mes, campo: campoSuspensoesAoMes });
              totalSuspensoes += suspensoesAoMes;
              return (
                <td key={mes} className="text-danger">
                  {suspensoesAoMes}
                </td>
              );
            })}
            <td className="text-danger">{isNaN(totalSuspensoes) ? "" : totalSuspensoes}</td>
          </tr>
          <tr>
            <th className="pl-4">Balanço de {titulo} no Mês</th>
            {meses.map((mes) => {
              const adicoesAoMes = getValor({ projecoes, mes, campo: campoAdicoesAoMes });
              const cancelamentosAoMes = getValor({ projecoes, mes, campo: campoCancelamentosAoMes });
              const suspensoesAoMes = getValor({ projecoes, mes, campo: campoSuspensoesAoMes });
              const balanco = adicoesAoMes - cancelamentosAoMes - suspensoesAoMes;
              balancoTotal += balanco;
              return (
                <td key={mes} className={balanco >= 0 ? "text-success" : "text-danger"}>
                  {balanco}
                </td>
              );
            })}
            <td className={balancoTotal > 0 ? "text-success" : "text-danger"}>{balancoTotal}</td>
          </tr>
          <tr>
            <th>{titulo} ao Fim do Mês</th>
            {meses.map((mes) => {
              const inicioMes = getValor({ projecoes, mes, campo: campoInicioMes });
              const adicoesAoMes = getValor({ projecoes, mes, campo: campoAdicoesAoMes });
              const cancelamentosAoMes = getValor({ projecoes, mes, campo: campoCancelamentosAoMes });
              const suspensoesAoMes = getValor({ projecoes, mes, campo: campoSuspensoesAoMes });
              return <td key={mes}>{inicioMes + adicoesAoMes - cancelamentosAoMes - suspensoesAoMes}</td>;
            })}
            <td></td>
          </tr>
          <tr>
            <th className="pl-4">Taxa de Crescimento</th>
            {meses.map((mes) => {
              const inicioMes = getValor({ projecoes, mes, campo: campoInicioMes });
              const adicoesAoMes = getValor({ projecoes, mes, campo: campoAdicoesAoMes });
              const cancelamentosAoMes = getValor({ projecoes, mes, campo: campoCancelamentosAoMes });
              const suspensoesAoMes = getValor({ projecoes, mes, campo: campoSuspensoesAoMes });
              const balancoAoMes = adicoesAoMes - cancelamentosAoMes - suspensoesAoMes;
              const taxaCrescimento = inicioMes > 0 ? (balancoAoMes / inicioMes) * 100 : 0;
              crescimentoTotal += +taxaCrescimento;
              return (
                <td key={mes} className={`${taxaCrescimento >= 0 ? "text-success" : "text-danger"}`}>
                  {isNaN(parseFloat("" + taxaCrescimento)) ? "" : taxaCrescimento.toFixed(2)}%
                </td>
              );
            })}
            <td className={`${crescimentoTotal > 0 ? "text-success" : "text-danger"}`}>
              {crescimentoTotal ? crescimentoTotal.toFixed(2) : ""}%
            </td>
          </tr>
          <tr>
            <th className="pl-4">Churn</th>
            {meses.map((mes) => {
              const inicioMes = getValor({ projecoes, mes, campo: campoInicioMes });
              const cancelamentosAoMes = getValor({ projecoes, mes, campo: campoCancelamentosAoMes });
              const suspensoesAoMes = getValor({ projecoes, mes, campo: campoSuspensoesAoMes });
              const churn = inicioMes > 0 ? ((cancelamentosAoMes + suspensoesAoMes) / inicioMes) * 100 : 0;
              churnTotal += +churn;
              if (new Date(`${ano}-${mes}-01`).getTime() <= getInicioMes().getTime()) {
                nMesesChurn++;
              }
              return (
                <td key={mes} className={`${churn <= 1 ? "" : "text-danger"}`}>
                  {isNaN(parseFloat("" + churn)) ? "" : churn.toFixed(2)}%
                </td>
              );
            })}
            <td className={`${churnTotal < crescimentoTotal ? "text-success" : "text-danger"}`}>
              {(churnTotal / nMesesChurn).toFixed(2)}%<div className="small">[Média]</div>
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
  );
}

function getValor({ projecoes, mes, campo, FilialId = "total", valorPadrao = 0 }) {
  if (projecoes[mes] && projecoes[mes][FilialId]) {
    if (isNaN(+projecoes[mes][FilialId][campo])) return valorPadrao;
    return projecoes[mes][FilialId][campo];
  }

  return valorPadrao;
}
