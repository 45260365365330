import React, { useMemo } from "react";
import Apexcharts from "react-apexcharts";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import IoContext from "contextos/IoContext";
import { toast } from "react-toastify";
import {
  TiposOrdemServico,
  SituacoesOrdensServico,
  CONCLUIDA_COM_SUCESSO,
  CONCLUIDA_SEM_SUCESSO,
} from "datatypes/OrdemServicoTypes";
import { getFinalMes, getInicioMes } from "misc/lhdatas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { Container, OverlayTrigger, Popover, Table } from "react-bootstrap";
import { dataMesAbbreviado } from "misc/datas";

const dateFormat = (ano, mes) => `${dataMesAbbreviado[mes]}/${ano}`;
const calcularDiferencaDeHorario = (init, end) => {
  const i = new Date(`0000-01-01T${init}Z`);
  const e = new Date(`0000-01-01T${end}Z`);
  if (i.toString() === "Invalid Date" || e.toString() === "Invalid Date") {
    return null;
  }
  //@ts-expect-error Datas
  return Math.abs(i - e) / 1000 / 60;
};
const criarSerie = () => ({ total: 0, ordensDeServico: 0 });
const gerarSeriePorTipos = (tipos) =>
  Object.values(tipos).reduce(
    (pv, tipo) =>
      tipo
        ? {
            ...pv,
            [tipo]: criarSerie(),
          }
        : pv,
    {}
  );

export default function DuracaoOrdensServico() {
  const { socket } = React.useContext(IoContext);

  const [exibirFiltros, setExibirFiltros] = React.useState(false);
  const [contador, setContador] = React.useState(0);
  const [dini, setDini] = React.useState(getInicioMes().toISOString().split("T")[0]);
  const [dend, setDend] = React.useState(getFinalMes().toISOString().split("T")[0]);
  const [loading, setLoading] = React.useState(false);
  const [ordensServico, setOrdensServico] = React.useState([]);
  const [filterTipos, setFilterTipos] = React.useState(TiposOrdemServico);
  const [situacao, setSituacao] = React.useState(undefined);
  const [exibirComSucesso, setExibirComSucesso] = React.useState(true);
  const [exibirSemSucesso, setExibirSemSucesso] = React.useState(true);

  React.useEffect(() => {
    setOrdensServico([]);
    setLoading(true);

    let situacao_conclusao_in = [];
    if (exibirComSucesso) {
      situacao_conclusao_in.push(CONCLUIDA_COM_SUCESSO);
    }
    if (exibirSemSucesso) {
      situacao_conclusao_in.push(CONCLUIDA_SEM_SUCESSO);
    }

    socket.emit(
      "OrdemServico.findAll",
      {
        attributes: [
          "id",
          "data_execucao",
          "tipo",
          "hora_execucao_ini",
          "hora_execucao_end",
          "situacao",
          "situacao_conclusao",
        ],
        where: {
          situacao: situacao || undefined,
          data_execucao_between: [dini, dend],
          hora_execucao_ini_ne: null,
          hora_execucao_end_ne: null,
          tipo_in: Object.keys(filterTipos).filter((tipoId) => filterTipos[tipoId]),
          or: [
            {
              situacao_conclusao_in,
            },
            {
              situacao_conclusao: null,
            },
          ],
        },
        withTecnico: {
          attributes: ["id", "nome"],
        },
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          return toast.error(error);
        }
        console.info(resp);
        setOrdensServico(resp);
      }
    );
  }, [socket, dini, dend, contador, filterTipos, situacao, exibirComSucesso, exibirSemSucesso]);

  const { tabelaMensal, tabelaGeral, tabelaTecnicos } = useMemo(() => {
    const [iniAno, mesIni] = dini.split("-").map((value) => Number.parseInt(value));
    const [anoFim, finalMes] = dend.split("-").map((value) => Number.parseInt(value));
    const qtdMeses = finalMes - mesIni + (anoFim - iniAno) * 12;

    const tiposSelecionados = Object.keys(filterTipos).filter((tipoId) => filterTipos[tipoId]);

    const serieBase = gerarSeriePorTipos(tiposSelecionados);
    const tabelaGeral = { TOTAL: criarSerie(), ...structuredClone(serieBase) };
    const tabelaTecnicos = {};

    const tabelaMensal = Array(qtdMeses + 1)
      .fill()
      .reduce(
        (acc, value, idx) => ({
          ...acc,
          [dateFormat(
            iniAno + Math.floor((mesIni - 1 + idx) / dataMesAbbreviado.length),
            (mesIni - 1 + idx) % dataMesAbbreviado.length
          )]: structuredClone(serieBase),
        }),
        {}
      );

    (ordensServico || []).forEach((os) => {
      const [osAno, osMes] = os.data_execucao.split("-").map((value) => Number.parseInt(value));
      const data_execucao = dateFormat(osAno, osMes - 1);

      const ini = os.hora_execucao_ini;
      const end = os.hora_execucao_end;
      const diff = calcularDiferencaDeHorario(ini, end);

      if (diff === null) {
        console.error("OS com data invalida");
        return;
      }

      const tempo = diff;
      const serieMensal = tabelaMensal[data_execucao]?.[os.tipo];

      if (!serieMensal) {
        console.error(`Serie mensal ${data_execucao}.${os.tipo} nao encontrada.`);
        return;
      }

      serieMensal.total += tempo;
      serieMensal.ordensDeServico++;

      tabelaGeral["TOTAL"].total += tempo;
      tabelaGeral["TOTAL"].ordensDeServico++;

      tabelaGeral[os.tipo].total += tempo;
      tabelaGeral[os.tipo].ordensDeServico++;

      let tecnico = tabelaTecnicos[os.Tecnico?.id]?.serie;
      if (!tecnico) {
        tabelaTecnicos[os.Tecnico?.id] = {
          nome: os.Tecnico?.nome,
          serie: { TOTAL: criarSerie(), ...structuredClone(serieBase) },
        };

        tecnico = tabelaTecnicos[os.Tecnico?.id].serie;
      }

      tecnico["TOTAL"].total += tempo;
      tecnico["TOTAL"].ordensDeServico++;

      tecnico[os.tipo].total += tempo;
      tecnico[os.tipo].ordensDeServico++;
    });

    return {
      tabelaGeral: Object.entries(tabelaGeral),
      tabelaTecnicos: Object.entries(tabelaTecnicos),
      tabelaMensal: Object.entries(tabelaMensal),
    };
  }, [ordensServico]);

  return (
    <Container fluid>
      <Card className="shadow">
        <Card.Header className="bg-primary text-light">
          <h3 className="float-start print pt-2">Relatório de duração da ordens de Serviço</h3>
        </Card.Header>

        <Card.Body className="d-print-none">
          <Row>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>Período</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="date"
                    value={dini}
                    placeholder="Inicial"
                    disabled={loading}
                    onChange={(e) => setDini(e.target.value)}
                  />
                  <Form.Control
                    type="date"
                    value={dend}
                    placeholder="Final"
                    disabled={loading}
                    onChange={(e) => setDend(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>

            <Col sm={6}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    variant="info"
                    title="Listar"
                    className="me-2 text-light"
                    onClick={() => setContador(contador + 1)}
                    disabled={loading}
                  >
                    Atualizar
                  </Button>

                  <Button title="Listar" className="me-2" disabled={loading} onClick={() => window.print()}>
                    Imprimir
                  </Button>

                  <Button
                    title="Aplicar Filtros"
                    className="me-2"
                    variant="secondary"
                    onClick={() => {
                      setExibirFiltros(!exibirFiltros);
                    }}
                  >
                    <FontAwesomeIcon icon={faFilter} /> Filtros
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="shadow rounded mt-4">
        <Card.Header className="bg-primary text-light">
          <h3 className="float-start print pt-2">Geral</h3>
        </Card.Header>
        <Card.Body>
          <Apexcharts
            type="bar"
            height={400}
            series={[
              {
                name: "Media",
                data: Array.from(tabelaGeral.map(([_, serie]) => serie.total / (serie.ordensDeServico || 1))),
              },
            ]}
            options={{
              plotOptions: {
                bar: {
                  horizontal: false,
                },
              },
              chart: {
                animations: { enabled: false },
              },
              dataLabels: { enabled: false },
              yaxis: {
                decimalsInFloat: 2,
                title: {
                  text: "Duração em Minutos",
                },
              },
              xaxis: {
                title: {
                  text: "Tipo de ordem de serviço",
                },
                categories: tabelaGeral.map(([tipo]) => filterTipos[tipo] || tipo),
              },
            }}
          />

          <Table className="mt-4" bordered striped responsive>
            <thead>
              <tr>
                <th></th>
                {tabelaGeral.map(([tipo, _]) => (
                  <th className="text-center" rowSpan={2} key={`th-${TiposOrdemServico[tipo]}`}>
                    {TiposOrdemServico[tipo] || tipo}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-2 text-center">Geral</td>
                {tabelaGeral.map(([tipoId, serie]) => (
                  <DuracaoTd
                    key={`td-geral-${TiposOrdemServico[tipoId]}`}
                    row={"Geral"}
                    col={TiposOrdemServico[tipoId] || tipoId}
                    serie={serie}
                  />
                ))}
              </tr>
              {tabelaTecnicos.map(([id, tecnico]) => (
                <tr key={`tr-${tecnico.nome}`}>
                  <td className="p-2">{tecnico.nome}</td>
                  {Object.entries(tecnico.serie).map(([tipoId, serie]) => (
                    <DuracaoTd
                      key={`td-${tecnico.nome}-${TiposOrdemServico[tipoId]}`}
                      row={tecnico.nome}
                      col={TiposOrdemServico[tipoId] || tipoId}
                      serie={serie}
                    />
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <Col className="mt-4 mb-4">
        <Card className="shadow rounded">
          <Card.Header className="bg-primary text-light">
            <h3 className="float-start print pt-2">Tempo Médio para Conclusão das Ordens de Serviço</h3>
          </Card.Header>
          <Table bordered striped responsive>
            <thead>
              <tr>
                <th>Mes/Ano</th>
                {Object.keys(filterTipos)
                  .filter((tipoId) => filterTipos[tipoId])
                  .map((tipoId) => (
                    <th className="text-center" rowSpan={2} key={`th-${TiposOrdemServico[tipoId]}`}>
                      {TiposOrdemServico[tipoId]}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {tabelaMensal.map(([mes, dados]) => (
                <tr key={`tr-${mes}`} className="py-2">
                  <td className="px-2">{mes}</td>
                  {Object.entries(dados).map(([tipoId, serie]) => (
                    <DuracaoTd
                      key={`td-${mes}-${tipoId}`}
                      row={mes}
                      col={TiposOrdemServico[tipoId] || tipoId}
                      serie={serie}
                    />
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>

      <Modal show={exibirFiltros} onHide={() => setExibirFiltros(false)} dialogClassName="modalFiltros">
        <Modal.Header closeButton>Filtros</Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Situação</Form.Label>
            <Form.Control
              as="select"
              value={situacao}
              onChange={(e) => {
                setSituacao(e.target.value);
              }}
            >
              <option value={""}>Todos</option>
              {Object.keys(SituacoesOrdensServico).map((s) => {
                return (
                  <option value={s} key={s}>
                    {SituacoesOrdensServico[s]}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>Situação da Conclusão</Form.Label>
            <div>
              <Form.Check
                label="Com Sucesso"
                checked={exibirComSucesso}
                disabled={loading}
                onChange={() => setExibirComSucesso(!exibirComSucesso)}
              />
              <Form.Check
                value="2"
                label="Sem Sucesso"
                checked={exibirSemSucesso}
                disabled={loading}
                onChange={() => setExibirSemSucesso(!exibirSemSucesso)}
              />
            </div>
          </Form.Group>

          <Form.Group>
            <Form.Label>Tipos de OS</Form.Label>
            <div>
              {Object.keys(TiposOrdemServico).map((tipo) => {
                return (
                  <Form.Check
                    key={tipo}
                    checked={filterTipos[tipo]}
                    label={TiposOrdemServico[tipo]}
                    disabled={loading}
                    onChange={() => {
                      let tipos2 = { ...filterTipos };
                      tipos2[tipo] = !tipos2[tipo];
                      setFilterTipos(tipos2);
                    }}
                  />
                );
              })}
            </div>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

function DuracaoTd({ row, col, serie, unidade = " min" }) {
  return (
    <OverlayTrigger
      trigger={["hover", "hover"]}
      placement="auto"
      overlay={
        <Popover>
          <Popover.Header as="h3">
            {col} em {row}
          </Popover.Header>
          <Popover.Body className="p-1">
            <Table className="m-0" size="sm" borderless responsive>
              <tbody>
                <tr>
                  <td>Ordens de servico:</td>
                  <td className="text-center">{serie.ordensDeServico}</td>
                </tr>
                <tr>
                  <td>Duracão Total:</td>
                  <td className="text-center">{serie.total.toFixed(0)} minutos</td>
                </tr>
              </tbody>
            </Table>
          </Popover.Body>
        </Popover>
      }
    >
      <td className="text-center">
        {(serie.total / (serie.ordensDeServico || 1)).toFixed(1)}
        {unidade}
      </td>
    </OverlayTrigger>
  );
}
