import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import SelectCaixas from "paginas/cadastros/financeiro/caixas/SelectCaixas";
import CampoData from "componentes/CampoData";
import { formatarDataDbs } from "misc/lhdatas";
import IoContext from "contextos/IoContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBackward,
  faForward,
  faSync,
  faHome,
  faPrint,
  faUpload,
  faPlus,
  faExternalLinkAlt,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

export default function TabControleCaixa() {
  const { socket } = React.useContext(IoContext);
  const [CaixaId, setCaixaId] = React.useState("");
  const [count, setCount] = React.useState(0);
  const [data, setData] = React.useState(formatarDataDbs(new Date()).split(" ")[0]);
  const [loading, setLoading] = React.useState(false);
  const [, setMovimentacoes] = React.useState([]);

  React.useEffect(() => {
    setMovimentacoes([]);
    if (!CaixaId) return;
    if (!data) return;

    setLoading(true);
    socket.emit("Caixa.Movimentacoes", (error, resp) => {
      setLoading(false);
      if (error) return toast.error("" + error);
    });
  }, [socket, CaixaId, data, count]);

  return (
    <Card.Body>
      <Row>
        <Col sm={3} lg={4}>
          <Form.Group>
            <Form.Label>Caixa</Form.Label>
            <SelectCaixas value={CaixaId} onChange={(e) => setCaixaId(e.target.value)} disabled={loading} />
          </Form.Group>
        </Col>

        <Col sm={3} lg={2}>
          <Form.Group>
            <Form.Label>Data</Form.Label>
            <CampoData value={data} onChange={(e) => setData(e.target.value)} disabled={loading} />
          </Form.Group>
        </Col>

        <Col sm={6}>
          <Form.Group>
            <Form.Label>Ações</Form.Label>
            <div className="d-flex">
              <InputGroup>
                <Button
                  title="Voltar um dia"
                  disabled={loading}
                  onClick={() => {
                    setData(formatarDataDbs(new Date(data)).split(" ")[0]);
                  }}
                >
                  <FontAwesomeIcon icon={faBackward} />
                </Button>

                <Button
                  title="Avançar um dia"
                  disabled={loading}
                  onClick={() => {
                    let d = new Date(data);
                    d.setDate(d.getDate() + 2);
                    setData(formatarDataDbs(d).split(" ")[0]);
                  }}
                >
                  <FontAwesomeIcon icon={faForward} />
                </Button>

                <Button title="Atualizar" onClick={() => setCount(count + 1)} disabled={loading}>
                  <FontAwesomeIcon icon={faSync} />
                </Button>

                <Button
                  title="Hoje"
                  disabled={loading}
                  onClick={() => {
                    setData(formatarDataDbs(new Date()).split(" ")[0]);
                  }}
                >
                  <FontAwesomeIcon icon={faHome} />
                </Button>
              </InputGroup>

              <InputGroup>
                <Button title="Imprimir" disabled={loading}>
                  <FontAwesomeIcon icon={faPrint} />
                </Button>

                <Button title="Enviar Arquivo OFX" disabled={loading}>
                  <FontAwesomeIcon icon={faUpload} />
                </Button>

                <Button title="Nova Movimentação" disabled={loading}>
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </InputGroup>

              <InputGroup>
                <Button title="Baixar Conta a Pagar" disabled={loading}>
                  <FontAwesomeIcon icon={faDollarSign} />
                </Button>

                <Button title="Sangria" disabled={loading}>
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </Button>
              </InputGroup>
            </div>
          </Form.Group>
        </Col>
      </Row>

      <Table></Table>
    </Card.Body>
  );
}
