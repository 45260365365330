import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import InputGroup from "react-bootstrap/InputGroup";
import { toast } from "react-toastify";
import IoContext from "../../../contextos/IoContext";

import TituloContext from "../../../contextos/TituloContext";
import SelectFuncionario from "../../cadastros/administrativo/functionaios/SelectFuncionario";
import CampoData from "../../../componentes/CampoData";
import BotaoVoltar from "../../../componentes/BotaoVoltar";
import Listagem from "../../../componentes/Listagem";
import { useParams } from "react-router-dom";

export default function HistoricoMaterialFuncionario(props) {
  const params = useParams();
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(false);
  const id = isNaN(parseInt(params.id)) ? "" : +params.id;
  const { setTitulo } = React.useContext(TituloContext);
  const [itens, setItens] = React.useState([]);
  const [dataIni, setDataIni] = React.useState(new Date().toISOString().split("T")[0]);
  const [dataEnd, setDataEnd] = React.useState(new Date().toISOString().split("T")[0]);
  const [produto, setProduto] = React.useState("");

  React.useEffect(() => {
    setTitulo(`Estoque do Funcionário ${id ? `[${id}]` : ""}`);
  }, [setTitulo, id]);

  React.useEffect(() => {
    loadHistorico({ socket, id, dataIni, dataEnd, setLoading, setItens, produto });
  }, [socket, id, dataIni, dataEnd, setLoading, setItens, produto]);

  return (
    <Container fluid className="pt-3">
      <Card>
        <Card.Header className="bg-primary text-light">
          <Card.Title className="text-light d-block float-start">Histórico de Material do Funcionário</Card.Title>
          <BotaoVoltar />
        </Card.Header>

        <Card.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              loadHistorico({ socket, id, dataIni, dataEnd, setLoading, setItens, produto });
            }}
          >
            <Row>
              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Funcionário</Form.Label>
                  <SelectFuncionario value={id} required disabled />
                </Form.Group>
              </Col>

              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Data</Form.Label>
                  <InputGroup>
                    <CampoData value={dataIni} onChange={(e) => setDataIni(e.target.value)} />

                    <CampoData value={dataEnd} onChange={(e) => setDataEnd(e.target.value)} />
                  </InputGroup>
                </Form.Group>
              </Col>

              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Produto</Form.Label>
                  <Form.Control value={produto} maxLength={100} onChange={(e) => setProduto(e.target.value)} />
                </Form.Group>
              </Col>

              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Ações</Form.Label>
                  <div>
                    <Button
                      className="me-2"
                      variant="success"
                      disabled={loading}
                      onClick={() => {
                        loadHistorico();
                      }}
                    >
                      Atualizar
                    </Button>
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Body>
          <Listagem
            titulos={["Id", "Data/Hora", "Usuário", "Descrição", "Produto", "Quantidade"]}
            itens={itens || []}
            renderItem={(titulo, item, idx) => {
              switch (titulo) {
                case "Id":
                  return item.id;
                case "Data/Hora":
                  return new Date(item.createdAt).toLocaleString();
                case "Usuário":
                  return item.Usuario ? item.Usuario.nome : "----";
                case "Descrição":
                  if (item.OrdemServico) {
                    const os = item.OrdemServico || {};
                    const serc = os.ServicoContratado || {};
                    const contrato = serc.Contrato || {};
                    if (item.tipo === "S") {
                      return `UTILIZADO NO CONTRATO: ${contrato.numero_contrato}`;
                    } else {
                      return `RETIRADO DO CONTRATO: ${contrato.numero_contrato}`;
                    }
                  } else {
                    return item.descricao;
                  }
                case "Produto":
                  const estoque = item.EstoqueFuncionario || {};
                  const produto = estoque.Produto || {};
                  return produto.nome || "----";
                case "Quantidade":
                  const cor = item.tipo === "S" ? "text-danger" : "text-success";
                  return <span className={cor}>{item.quantidade}</span>;
                default:
                  return titulo;
              }
            }}
          />
        </Card.Body>

        <Card.Footer></Card.Footer>
      </Card>
    </Container>
  );
}

function loadHistorico({ socket, id, dataIni, dataEnd, setLoading, setItens, produto }) {
  setLoading(true);
  setItens([]);

  let whereProduto = {};
  if (produto) whereProduto.nome_like = `%${produto}%`;

  socket.emit(
    "HistoricoEstoqueFuncionario.findAll",
    {
      attributes: ["id", "createdAt", "descricao", "quantidade", "tipo"],
      where: {
        FuncionarioId: +id,
        createdAt_between: [`${dataIni} 00:00:00`, `${dataEnd} 23:59:59`],
      },
      withUsuario: {
        attributes: ["nome"],
        required: false,
      },
      withOrdemServico: {
        attributes: ["id"],
        withServicoContratado: {
          attributes: ["id"],
          withContrato: {
            attributes: ["numero_contrato"],
          },
        },
      },
      withEstoqueFuncionario: {
        withProduto: {
          attributes: ["nome"],
          where: whereProduto,
          required: true,
        },
        required: true,
      },
      order: [["createdAt", "ASC"]],
    },
    (error, resp) => {
      setLoading(false);

      if (error) {
        toast.error(error);
      } else {
        setItens(resp);
      }
    }
  );
}
