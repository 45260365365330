import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import LoadingContext from '../../../../contextos/LoadingContext';
import SelectServidor from '../../../redeinfra/servidores/SelectServidor';
import SelectRedes from '../../../redeinfra/redes/SelectRedes';
import CampoEnderecoMac from '../../../../componentes/CampoEnderecoMac';
import CampoEnderecoIPv4 from '../../../../componentes/CampoEnderecoIPv4';
import ModalConfirmar from '../../../../componentes/ModalConfirmar';
import { toast } from 'react-toastify';
import IoContext from '../../../../contextos/IoContext';

export default function AcessoFuncionario({ idx, id, PessoaId, acesso, refresh }) {
    const { socket } = React.useContext(IoContext);
    const { loading, setLoading } = React.useContext(LoadingContext);
    const [ServidorId, setServidorId] = React.useState("");
    const [RedeId, setRedeId] = React.useState("");
    const [enderecoIpv4, setEnderecoIpv4] = React.useState("");
    const [mac, setMac] = React.useState("");
    const [observacao, setObservacao] = React.useState("");
    const [upload, setUpload] = React.useState(0);
    const [download, setDownload] = React.useState(0);
    const [showApagar, setShowApagar] = React.useState(false);
    const [alterado, setAlterado] = React.useState(false);
    const [loading2, setLoading2] = React.useState(false);
    const [loading3, setLoading3] = React.useState(false);

    const getAcesso = React.useCallback(() => {
        if (acesso || id) {
            return Promise.resolve().then(() => {
                return new Promise((resolve) => {
                    setLoading(true); setLoading2(true);
                    socket.emit("AcessoFuncionario.findOne", {
                        where: { id }
                    }, (error, resp) => {
                        if (error) {
                            toast.error(error);
                        } else {
                            resolve(resp);
                        }
                    });
                });
            }).then(acesso => {
                setLoading(false); setLoading2(false);
                if (acesso) {
                    setServidorId(acesso.ServidorId || "0");
                    setRedeId(acesso.RedeId || "0");
                    setEnderecoIpv4(acesso.enderecoIpv4 || "");
                    setMac(acesso.mac || "");
                    setObservacao(acesso.observacao || "");
                    setUpload(acesso.upload || "");
                    setDownload(acesso.download || "");
                }
            })
        } else {
            setServidorId("");
            setRedeId("");
            setEnderecoIpv4("");
            setMac("");
            setObservacao("");
            setUpload(0);
            setDownload(0);
        }
    }, [socket, id, acesso, setLoading]);

    const getEnderecoIPv4 = React.useCallback((RedeId) => {
        setLoading3(true);
        setEnderecoIpv4('');
        if (RedeId) {
            socket.emit("Rede.GetEnderecoIPv4", { RedeId }, (error, resp) => {
                setLoading3(false);
                if (resp.data.status === 'ok') {
                    setEnderecoIpv4(resp);
                }
            });
        }
    }, [socket]);

    React.useEffect(() => {
        getAcesso();
    }, [getAcesso]);

    return <Card>
        <Accordion.Toggle as={Card.Header} eventKey={`${idx}`} className={`${id ? 'bg-secondary' : 'bg-success'}`}>
            {acesso ?
                `[${acesso.id}] [${acesso.mac}] ${acesso.observacao ? acesso.observacao : acesso.usuario}` :
                "NOVO ACESSO"}
        </Accordion.Toggle>

        <Accordion.Collapse eventKey={`${idx}`}>
            <React.Fragment>
                <Card.Body>
                    <Form onChange={() => {
                        if (!alterado) {
                            setAlterado(true);
                        }
                    }}>
                        <Row>
                            <Col sm={3}>
                                <Form.Group>
                                    <Form.Label>Servidor</Form.Label>
                                    <SelectServidor disabled={loading || loading2} value={ServidorId}
                                        onChange={e => setServidorId(e.target.value)}
                                        query={{
                                            where: { isNas: true }
                                        }} />
                                </Form.Group>
                            </Col>

                            <Col sm={3}>
                                <Form.Group>
                                    <Form.Label>Rede</Form.Label>
                                    <SelectRedes value={RedeId} onChange={e => {
                                        setRedeId(e.target.value);
                                        getEnderecoIPv4(e.target.value);
                                    }}

                                        query={{
                                            where: { ServidorId }
                                        }} />
                                </Form.Group>
                            </Col>

                            <Col sm={3}>
                                <Form.Group>
                                    <Form.Label>Endereço IP</Form.Label>
                                    <CampoEnderecoIPv4 value={enderecoIpv4} onChange={txt => setEnderecoIpv4(txt)} disabled={loading || loading3} />
                                </Form.Group>
                            </Col>

                            <Col sm={3}>
                                <Form.Group>
                                    <Form.Label>Endereço Mac</Form.Label>
                                    <CampoEnderecoMac value={mac} onChange={txt => setMac(txt)} />
                                </Form.Group>
                            </Col>

                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Descrição</Form.Label>
                                    <Form.Control value={observacao} onChange={e => setObservacao(e.target.value)} maxLength={50} />
                                </Form.Group>
                            </Col>

                            <Col sm={3}>
                                <Form.Group>
                                    <Form.Label>Upload (Kbps)</Form.Label>
                                    <Form.Control type="number" value={upload} onChange={e => setUpload(e.target.value)} step={1} />
                                </Form.Group>
                            </Col>

                            <Col sm={3}>
                                <Form.Group>
                                    <Form.Label>Download (Kbps)</Form.Label>
                                    <Form.Control type="number" value={download} onChange={e => setDownload(e.target.value)} step={1} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>

                <Card.Footer>
                    {id ? <Button variant="danger" onClick={() => setShowApagar(true)}>Apagar</Button> : false}

                    {alterado ?
                        <React.Fragment>
                            <Button className="float-end" onClick={() => {
                                setLoading(true); setLoading2(true);
                                socket.emit("AcessoFuncionario.salvar", {
                                    id, ServidorId, RedeId, PessoaId,
                                    enderecoIpv4, mac,
                                    observacao, upload, download,
                                }, () => {
                                    setLoading(false); setLoading2(false); setAlterado(false);
                                    toast.success("Dados do Acesso Gravados com sucesso !");
                                    refresh();
                                });
                            }}>Salvar</Button>

                            <Button className="float-end me-2" variant="warning" onClick={() => {
                                setAlterado(false);
                                refresh();
                                getAcesso();
                            }}>Cancelar</Button>
                        </React.Fragment>
                        : false}
                </Card.Footer>
            </React.Fragment>
        </Accordion.Collapse>

        <ModalConfirmar show={showApagar} onHide={setShowApagar}
            titulo={`Apagar Acesso [${id}] ${acesso ? acesso.observacao : ''}?`}
            texto={"Cuidado essa operação é irreversível !"}
            callback={() => {
                setLoading(true); setLoading2(true);
                socket.emit("AcessoFuncionario.apagar", {
                    id
                }, (error, resp) => {
                    setLoading(false); setLoading2(false); setAlterado(false);
                    if (resp.data.status === 'ok') {
                        toast.success("Acesso Removido com sucesso !");
                        refresh();
                    } else {
                        toast.error('' + error);
                    }
                });
            }}
        />
    </Card>
}