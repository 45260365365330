import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Filial from './Filial';
import Filiais from './Filiais';


export default function IndexFiliais (){
    const [texto, setTexto] = React.useState('');
    const [pagina, setPagina] = React.useState(1);
    
    return <Routes>
        <Route path={`/:id`} element={<Filial />} />
        <Route path={`/`} element={<Filiais
                texto={texto} setTexto={setTexto} 
                pagina={pagina} setPagina={setPagina}
                />
            } />
    </Routes>
}