import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";

export default function BotaoVoltar(props) {
    const navigate = useNavigate();
    const { onClick, texto } = props;
    return <Button
        className="btn btn-secondary float-end" {...props}
        title="Voltar"
        style={{}}
        onClick={(event) => {
            if (onClick) {
                return onClick(event);
            } else {
                navigate(-1);
            }
        }}>
        <FontAwesomeIcon icon={faArrowLeft} className={`${texto ? 'me-2' : ''}`} />
        {texto}
    </Button>
}