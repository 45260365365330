import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Enderecos from './Enderecos';
import Endereco from './Endereco';


export default function EnderecoIndex (){
    const [texto, setTexto] = React.useState('');
    const [pagina, setPagina] = React.useState(1);
    const [uf, setUf] = React.useState('');
    const [cidade, setCidade] = React.useState('');

    return <Routes>
        <Route path={`/:id`} element={<Endereco />} />
        <Route path={`*`} element={<Enderecos
                texto={texto} setTexto={setTexto} 
                pagina={pagina} setPagina={setPagina}
                uf={uf} setUf={setUf} 
                cidade={cidade} setCidade={setCidade}
                />
            } />
    </Routes>
}