export const SituacoesContratos = {
    0: 'PENDENTE',  
    1: 'ATIVO',     
    2: 'CANCELADO', 
    3: 'SUSPENSO',  
    4: 'DESATIVADO',
}

export const CONTRATO_PENDENTE   = 0;
export const CONTRATO_ATIVO      = 1;
export const CONTRATO_CANCELADO  = 2;
export const CONTRATO_SUSPENSO   = 3;
export const CONTRATO_DESATIVADO = 4;