import React from 'react';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import IoContext from '../../contextos/IoContext';

export default function SelectUsuarios(props){
    const { socket } = React.useContext(IoContext);
    const { id, value, onChange, disabled, pular } = props; 
    const [usuarios, setUsuarios] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(()=>{
        setLoading(true);
        socket.emit("Usuario.findAll", { 
            attributes: ['id','nome', 'senha'],
            order: [["nome", "ASC"]],
        }, (error, resp) => {
            setLoading(false);
            if(error){
                toast.error(error);
            } else {
                console.log("## USUARIOS:", resp);
                setUsuarios(resp);
            }
        })
    }, [socket]);
   
    return <Form.Control as="select" 
        id={id || "UsuarioId"} 
        onChange={onChange} value={value}
        disabled={loading || disabled}
        {...props}
    >
        <option value=""></option>
        { (usuarios || []).map( Usuario => {
            if(pular) {
                if(Array.isArray(pular)) {
                    if(pular.indexOf(Usuario.id)){
                        return false;
                    }
                } else {
                    if(+pular === +Usuario.id) {
                        return false;
                    }
                }
            }
            return <option key={Usuario.id} value={Usuario.id}>{Usuario.nome}</option>
        })}
    </Form.Control>;
}