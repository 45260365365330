import React from "react";
import { Form, Button } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';

export default function ModalFidelimaxAddEvento({ show, onHide, onSelect }) {
    const [nome, setNome] = React.useState("");
    const [valor, setValor] = React.useState(0);

    return <Modal show={show} onHide={onHide} size="lg">
        <Form onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
            onSelect({
                nome,
                configuracoes: {
                    acao: 'pontuar',
                    valor
                }
            });
            onHide(false);
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Adicionar Evento Fidelimax</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form.Group>
                    <Form.Label>Evento</Form.Label>
                    <Form.Control as="select" required value={nome} onChange={e => {
                        setNome(e.target.value);
                    }}>
                        <option value=""></option>
                        <option value="ServicoContratado.cadastro">Servico Contratado</option>
                        <option value="ServicoContratado.upgrade">Upgrade de Plano</option>
                        <option value="ContaReceber.pagamento_em_dias">Pagamento em Dias</option>
                        <option value="ContaReceber.pagamento_em_atraso">Pagamento em Atraso</option>
                    </Form.Control>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Pontuar em</Form.Label>
                    <Form.Control type="number" min={0.01} step={0.01} required
                        value={valor} onChange={e => setValor(+e.target.value)}
                    />
                </Form.Group>
            </Modal.Body>

            <Modal.Footer>
                <Button type="submit">Adicionar</Button>
            </Modal.Footer>
        </Form>
    </Modal>
}