import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Container from 'react-bootstrap/Container';
import { toast } from 'react-toastify';
import BotaoApagar from 'componentes/BotaoApagar';
import CabecalhoCadastro from 'componentes/CabecalhoCadastro';
import IoContext from 'contextos/IoContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router';

export default function Categoria() {
    const params = useParams();
    const navigate = useNavigate();
    const { socket } = React.useContext(IoContext);
    const [loading, setLoading] = React.useState(false);
    const id = parseInt(params.id);
    const [nome, setNome] = React.useState('');
    const [itens, setItens] = React.useState([]);

    React.useEffect(() => {
        if (isNaN(id)) return;

        setLoading(true);
        socket.emit("CategoriaFinanceira.findOne", {
            where: { id },
            withItens: {
                attributes: ['id', 'nome'],
                required: false
            }
        }, (error, resp) => {
            setLoading(false);

            if (error) {
                toast.error(error);
            } else if (resp) {
                setNome(resp.nome);
                setItens(resp.ItemCategoriaFinanceiras);
            }
        });
    }, [socket, id]);

    return <Container fluid className="pt-3">
        <Card>
            <CabecalhoCadastro
                id={id} disabled={loading} titulo='Categoria Financeira'
                salvar={e => {
                    e.preventDefault();
                    setLoading(true);
                    socket.emit("CategoriaFinanceira.salvar", { id, nome, itens }, (error, resp) => {
                        setLoading(false);
                        if (error) {
                            toast.error(error);
                        } else {
                            toast.success('Categoria de Financeira salvo com Sucesso !');
                            navigate(-1);
                        }
                    });
                }}
            />

            <Card.Body>
                <Row>
                    <Col sm={3}>
                        <Form.Group>
                            <Form.Label>Id</Form.Label>
                            <Form.Control value={isNaN(id) ? "" : id} readOnly />
                        </Form.Group>
                    </Col>

                    <Col sm={9}>
                        <Form.Group>
                            <Form.Label>Categoria Financeira</Form.Label>
                            <Form.Control
                                disabled={loading}
                                value={nome}
                                autoFocus
                                onChange={e => setNome(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Card.Body>

            <Card.Title className="ms-3"><h4>Itens</h4></Card.Title>

            <Card.Body>
                {(itens || []).map((item, idx) => {
                    return <Row key={idx} className='mt-2'>
                        <Col sm={12}>
                            <InputGroup>
                                <Form.Control
                                    maxLength={100}
                                    onChange={e => {
                                        let itens2 = [...itens];
                                        itens2[idx].nome = e.target.value;
                                        setItens(itens2);
                                    }}
                                    value={item.nome} />

                                <Button variant="danger"
                                    title="Remover Item"
                                    onClick={() => {
                                        let itens2 = [...itens];
                                        itens2.splice(idx, 1);
                                        setItens(itens2);
                                    }}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            </InputGroup>
                        </Col>
                    </Row>
                })}

                <Row>
                    <Col sm={12} className="text-right mt-2">
                        <Button onClick={() => {
                            let itens2 = [...itens];
                            itens2.push({
                                id: null,
                                nome: ''
                            });
                            setItens(itens2);
                        }}>Adicionar Item</Button>
                    </Col>
                </Row>
            </Card.Body>

            <Card.Footer>
                {isNaN(id) ? false : <BotaoApagar
                    disabled={loading}
                    apagar={e => {
                        setLoading(true);
                        socket.emit("CategoriaFinanceira.apagar", { id }, (error, resp) => {
                            setLoading(false);
                            if (error) {
                                toast.error(error);
                            } else {
                                toast.success('Categoria Financeira removido com Sucesso !');
                                navigate(-1);
                            }
                        });
                    }}
                />}
            </Card.Footer>
        </Card>
    </Container>;
}