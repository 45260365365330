import React from "react";
import IoContext from "contextos/IoContext";
import { toast } from "react-toastify";

export default function useIntegracaoFindOne({ modulo, setLoading = null, parseConfiguracoes = null }) {
  const { socket } = React.useContext(IoContext);
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    if (setLoading) setLoading(true);
    if (!modulo) {
      throw new Error("useIntegracaoFindOne: modulo é obrigatório");
    }
    socket.emit("Integracao.findOne", { where: { modulo } }, (error, resp) => {
      if (setLoading) setLoading(false);
      if (error) {
        return toast.error(error);
      }

      const configuracoes = JSON.parse(resp.configuracoes || {});
      setData({
        ...resp,
        configuracoes: parseConfiguracoes ? parseConfiguracoes(configuracoes) : configuracoes,
      });
    });
  }, [socket, modulo, setLoading, parseConfiguracoes]);

  return [
    data,
    setData,
    function setConfiguracao(key, value) {
      setData({
        ...data,
        configuracoes: {
          ...data?.configuracoes,
          [key]: value,
        },
      });
    },
  ];
}
