import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import IoContext from "contextos/IoContext";
import { toast } from "react-toastify";
import { getInicioMes, formatarDataDbs } from "misc/lhdatas";
import { formatarVelocidade } from "misc/utils";
import { formatarDinheiro } from "misc/formatarDinheiro";
import { NOTAFISCAL_NORMAL, NOTAFISCAL_TIPOS, NOTAFISCAL_TIPO_SCM } from "datatypes/NotaFiscalTypes";
import SelectCentrosDeCusto from "paginas/cadastros/financeiro/centro_de_custo/SelectCentrosDeCusto";
import RenderAnatelTrimestralDet from "./RenderAnatelTrimestralDet";
import BotaoProcessando from "componentes/BotaoProcessando";

export default function RelAnatelTrimestral() {
  const { socket } = React.useContext(IoContext);
  const [contador, setContador] = React.useState(0);
  const [trimestre, setTrimestre] = React.useState(1);
  const [ano, setAno] = React.useState(new Date().getFullYear());
  const [loading, setLoading] = React.useState(false);
  const [exibirDetalhes, setExibirDetalhes] = React.useState(true);
  const [CentroCustoCapexId, setCentroCustoCapexId] = React.useState(0);
  const [CentroCustoImpostosId, setCentroCustoImpostosId] = React.useState(0);
  const [capex, setCapex] = React.useState({ Total: 0, detalhado: {} });
  const [impostos, setImpostos] = React.useState({ Total: 0, detalhado: {} });
  const [trafegos, setTrafegos] = React.useState({
    Total: 0,
    detalhado: {},
  });
  const [rols, setRols] = React.useState({
    Total: 0,
    detalhado: {},
  });
  const [soFiscal, setSoFiscal] = React.useState(true);

  React.useEffect(() => {
    setRols({ Total: 0, detalhado: {} });
    setCapex({ Total: 0, detalhado: {} });
    setImpostos({ Total: 0, detalhado: {} });
    setTrafegos({ Total: 0, detalhado: {} });
  }, [ano, trimestre, soFiscal, CentroCustoCapexId, CentroCustoImpostosId]);

  React.useEffect(() => {
    setRols({ Total: 0, detalhado: {} });
    setCapex({ Total: 0, detalhado: {} });
    setImpostos({ Total: 0, detalhado: {} });
    setTrafegos({ Total: 0, detalhado: {} });

    let mesIni = [1, 4, 7, 10];
    let mesEnd = [3, 6, 9, 12];
    let meses = [];

    for (let mes = mesIni[trimestre - 1]; mes <= mesEnd[trimestre - 1]; mes++) {
      meses.push(mes);
    }

    const trafegos = {
      Total: 0,
      detalhado: [],
    };

    Promise.resolve().then(async () => {
      const promises = [];
      promises.push(getNotasFiscais({ socket, meses, ano }));
      promises.push(getContasPagar({ socket, meses, ano, CentroCustoId: CentroCustoCapexId }));
      promises.push(getContasPagar({ socket, meses, ano, CentroCustoId: CentroCustoImpostosId }));

      for (let mes of meses) {
        let proxAno = ano;
        let proxMes = mes + 1;
        if (proxMes > 12) {
          proxMes = 1;
          proxAno = ano + 1;
        }

        promises.push(getBilhetagem({ socket, trafegos, mes, ano, proxMes, proxAno, soFiscal }));
      }

      try {
        setLoading(true);
        const [rols, capex, impostos] = await Promise.all(promises);
        setLoading(false);

        setRols(rols);
        setCapex(capex);
        setImpostos(impostos);
        setTrafegos(trafegos);
      } catch (error) {
        console.log("error", error);
        toast.error("" + error);
        setLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, contador]);

  return (
    <Container fluid>
      <Card className="shadow">
        <Card.Header className="bg-primary text-light">
          <h3 className="float-start">Relatório Trimestal para a Anatel</h3>
        </Card.Header>

        <Card.Body className="d-print-none">
          <Row>
            <Col sm={2}>
              <Form.Group>
                <Form.Label>Ano</Form.Label>
                <Form.Control
                  type="number"
                  step={1}
                  value={ano}
                  disabled={loading}
                  onChange={(e) => setAno(+e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col sm={1}>
              <Form.Group>
                <Form.Label>Trimestre</Form.Label>
                <Form.Control
                  as="select"
                  value={trimestre}
                  disabled={loading}
                  onChange={(e) => setTrimestre(+e.target.value)}
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Centro de Custo de Investimento</Form.Label>
                <SelectCentrosDeCusto
                  value={CentroCustoCapexId}
                  onChange={(e) => setCentroCustoCapexId(e.target.value)}
                  disabled={loading}
                  required
                />
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Centro de Custo de Impostos</Form.Label>
                <SelectCentrosDeCusto
                  value={CentroCustoImpostosId}
                  onChange={(e) => setCentroCustoImpostosId(e.target.value)}
                  disabled={loading}
                  required
                />
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group>
                <Form.Label>Opções</Form.Label>
                <div>
                  <Form.Check
                    checked={soFiscal}
                    disabled={loading}
                    onChange={(e) => setSoFiscal(!soFiscal)}
                    label="Somente Fiscal ?"
                  />
                  <Form.Check
                    checked={exibirDetalhes}
                    disabled={loading}
                    onChange={(e) => setExibirDetalhes(!exibirDetalhes)}
                    label="Exibir Detalhamento ?"
                  />
                </div>
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    variant="secondary"
                    title="Listar"
                    className="me-2"
                    onClick={() => setContador(contador + 1)}
                    disabled={loading}
                  >
                    Atualizar
                  </Button>

                  <Button title="Listar" className="me-2" disabled={loading} onClick={() => window.print()}>
                    Imprimir
                  </Button>

                  <BotaoProcessando loading={loading} />
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-2">
        <Table>
          <thead>
            <tr>
              <th>Indicador</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                Tráfego Médio da Rede [{trimestre}/{ano}]:
              </th>
              <td>{formatarVelocidade(trafegos.Total)}</td>
            </tr>
            <RenderAnatelTrimestralDet
              dados={trafegos}
              exibirDetalhes={exibirDetalhes}
              formatter={formatarVelocidade}
              txt={"Tráfego Médio da Rede"}
            />
            <tr>
              <th>
                Investimentos (Capex) [{trimestre}/{ano}]:
              </th>
              <td>{formatarDinheiro(capex.Total)}</td>
            </tr>
            <RenderAnatelTrimestralDet
              dados={capex}
              exibirDetalhes={exibirDetalhes}
              formatter={formatarDinheiro}
              txt={"Investimentos (Capex)"}
            />

            <tr>
              <th>
                Impostos [{trimestre}/{ano}]:
              </th>
              <td>{formatarDinheiro(impostos.Total)}</td>
            </tr>
            <RenderAnatelTrimestralDet
              dados={impostos}
              exibirDetalhes={exibirDetalhes}
              formatter={formatarDinheiro}
              txt={"Impostos"}
            />

            <tr>
              <th>
                Receita Operacional Bruta [{trimestre}/{ano}]:
              </th>
              <td>{formatarDinheiro(rols.Total)}</td>
            </tr>
            <RenderAnatelTrimestralDet
              dados={rols}
              exibirDetalhes={exibirDetalhes}
              formatter={formatarDinheiro}
              txt={"Receita Operacional Bruta"}
            />
            <tr>
              <th>
                Receita Operacional Líquida (ROL) [{trimestre}/{ano}]:
              </th>
              <td>{formatarDinheiro(rols.Total - (impostos.Total || 0))}</td>
            </tr>
          </tbody>
        </Table>
      </Card>
    </Container>
  );
}

async function getNotasFiscais({ socket, meses, ano }) {
  const rols = {
    Total: 0,
    detalhado: [],
  };

  await new Promise((resolve, reject) => {
    socket.emit(
      "NotaFiscal.findAll",
      {
        attributes: ["fn_sum(valor) as total", "tipo", "ano", "mes"],
        where: {
          situacao: NOTAFISCAL_NORMAL,
          mes_in: meses,
          ano,
        },
        group: ["NotaFiscal.tipo", "NotaFiscal.ano", "NotaFiscal.mes"],
      },
      (error, resp) => {
        if (error) {
          return reject(error);
        }

        resp.forEach((det) => {
          rols.detalhado[`${det.mes}/${det.ano} - ${NOTAFISCAL_TIPOS[det.tipo]}`] = det.total;
          rols.Total += det.total;
        });

        resolve(resp || 0);
      }
    );
  });

  return rols;
}

async function getContasPagar({ socket, meses, ano, CentroCustoId }) {
  const contasPagar = {
    Total: 0,
    detalhado: [],
  };

  await new Promise((resolve, reject) => {
    socket.emit(
      "ContaPagar.findAll",
      {
        attributes: ["fn_sum(valor) as total", "fn_month(PAGAMENTO) as mes", "fn_year(PAGAMENTO) as ano"],
        where: {
          CentroCustoId,
        },
        having: {
          mes_in: meses,
          ano,
        },
        group: ["fn_month(PAGAMENTO) as mes", "fn_year(PAGAMENTO) as ano"],
      },
      (error, resp) => {
        if (error) {
          return reject(error);
        }

        resp.forEach((det) => {
          contasPagar.detalhado[`${det.mes}/${det.ano}`] = det.total;
          contasPagar.Total += det.total;
        });

        resolve(resp || 0);
      }
    );
  });

  return contasPagar;
}

async function getBilhetagem({ socket, trafegos, mes, ano, proxMes, proxAno, soFiscal }) {
  return new Promise((resolve, reject) => {
    socket.emit(
      "Anatel.GetBilhetagem",
      {
        mes,
        ano,
        dini: formatarDataDbs(getInicioMes({ ano, mes })),
        dend: formatarDataDbs(getInicioMes({ ano: proxAno, mes: proxMes })),
        soFiscal,
      },
      (error, resp) => {
        if (error) {
          return reject(error);
        }

        trafegos.detalhado[`${mes}/${ano}`] = +resp.total_bytes_out;
        trafegos.Total += +resp.total_bytes_out;
        resolve();
      }
    );
  });
}
