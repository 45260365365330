import { DateTime } from "luxon";
import React from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";

export default function CardListaContasPagas({ contasPagas }) {
  let totalValor = 0;
  let totalValorPago = 0;
  return (
    <Card className="shadow rounded mt-2 d-flex">
      <Table responsive>
        <thead>
          <tr className={"text-center"}>
            <th>NrDoc</th>
            <th>Data do Vencimento</th>
            <th>Data do Pagamento</th>
            <th>Valor</th>
            <th>Valor Pago</th>
          </tr>
        </thead>
        <tbody>
          {contasPagas.map((contas, key) => {
            totalValor += Number(contasPagas[key].valor);
            totalValorPago += Number(contasPagas[key].valor_pago);
            return (
              <tr key={key} className={"text-center"}>
                <td>{contasPagas[key].nrdoc}</td>
                <td>{DateTime.fromISO(contasPagas[key].data_vencimento).toLocaleString(DateTime.DATE_SHORT)}</td>
                <td>{DateTime.fromISO(contasPagas[key].data_pagamento).toLocaleString(DateTime.DATE_SHORT)}</td>
                <td className={"text-end"}>
                  {Number(contasPagas[key].valor).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                </td>
                <td className={"text-end"}>
                  {Number(contasPagas[key].valor_pago).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                </td>
              </tr>
            );
          })}
          <tr>
            <td className={"fw-bold"}>Total</td>
            <td></td>
            <td></td>
            <td className={"text-end fw-bold"}>
              {totalValor.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
            </td>
            <td className={"text-end fw-bold"}>
              {totalValorPago.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
  );
}
