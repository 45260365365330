import React from "react";
import Form from "react-bootstrap/Form";
import IoContext from "contextos/IoContext";
import { toast } from "react-toastify";

import { SituacoesSolicitacaoMaterial, SolicitacaoMaterial_EM_ABERTO } from "datatypes/SolicitacoesDeMaterialTypes";
import SelectAlmoxarifados from "../SelectAlmoxarifados";
import { useNavigate } from "react-router-dom";
import FormularioListagem from "componentes/FormularioListagem";
import { DateTime } from "luxon";

export default function SolicitacoesDeMaterial() {
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(false);
  const [situacao, setSituacao] = React.useState(SolicitacaoMaterial_EM_ABERTO);
  const [almoxarifadoId, setAlmoxarifadoId] = React.useState("");
  const [dataIni, setDataIni] = React.useState("");
  const [dataEnd, setDataEnd] = React.useState("");
  const [pagina, setPagina] = React.useState(1);
  const [texto, setTexto] = React.useState("");
  const [solicitacoes, setSolicitacoes] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const navigate = useNavigate();

  React.useEffect(() => {
    let where = {};
    let withAlmoxarifado = { where: {} };
    let withItens = { where: {} };

    if (situacao) where.situacao = +situacao;
    if (dataIni && dataEnd) where.createdAt_between = [dataIni, dataEnd];
    if (texto) withAlmoxarifado.where.nome_like = `%${texto}%`;
    if (almoxarifadoId) withAlmoxarifado.where.id = +almoxarifadoId;

    setLoading(true);
    socket.emit(
      "SolicitacaoMaterial.findAndCountAll",
      {
        limit: 10,
        offset: 10 * (pagina - 1),
        where,
        withAlmoxarifado,
        withItens,
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          toast.error(error);
        } else {
          setTotal(resp.count);
          setSolicitacoes(resp.rows);
        }
      }
    );
  }, [socket, texto, pagina, dataIni, dataEnd, almoxarifadoId, situacao]);

  return (
    <FormularioListagem
      titulo={`Solicitações de Material`}
      itens={solicitacoes}
      qtdItens={total}
      pagina={pagina}
      setPagina={setPagina}
      texto={texto}
      loading={loading}
      setTexto={setTexto}
      titulos={["Id", "Almoxarifado Solicitante", "Data", "Situação"]}
      renderItem={(titulo, item) => {
        switch (titulo) {
          case "Id":
            return item.id;
          case "Almoxarifado Solicitante":
            const almoxarifado = item.Almoxarifado || {};
            return almoxarifado.nome;
          case "Data":
            return DateTime.fromISO(item.createdAt).toLocaleString(DateTime.DATE_SHORT);
          case "Situação":
            return SituacoesSolicitacaoMaterial[item.situacao];
          default:
            return titulo;
        }
      }}
      onClick={(e) => {
        navigate(`${e.id}`);
      }}
      renderFiltros={() => {
        return (
          <React.Fragment>
            <Form.Group>
              <Form.Label>Almoxarifado</Form.Label>
              <SelectAlmoxarifados
                value={almoxarifadoId}
                onChange={(e) => {
                  setAlmoxarifadoId(e.target.value);
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Situação</Form.Label>
              <Form.Control
                as="select"
                value={situacao}
                onChange={(e) => {
                  setSituacao(+e.target.value);
                }}
              >
                <option value="">Todas</option>
                {Object.keys(SituacoesSolicitacaoMaterial).map((key) => {
                  return (
                    <option key={key} value={key}>
                      {SituacoesSolicitacaoMaterial[key]}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Data Inicial</Form.Label>
              <Form.Control type="date" value={dataIni} onChange={(e) => setDataIni(e.target.value)} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Data Final</Form.Label>
              <Form.Control type="date" value={dataEnd} onChange={(e) => setDataEnd(e.target.value)} />
            </Form.Group>
          </React.Fragment>
        );
      }}
    />
  );
}
