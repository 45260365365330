import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import IoContext from "contextos/IoContext";
import Listagem from "componentes/Listagem";
import MultiFiliais from "paginas/cadastros/administrativo/filiais/MultiFiliais";
import { toast } from "react-toastify";
import { getFinalMes, getInicioMes } from "misc/lhdatas";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

export default function RelNotificacoesContratos() {
  const { socket } = React.useContext(IoContext);
  const [notificacoes, setNotificacoes] = React.useState([]);
  const [exibirFiltros, setExibirFiltros] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [filiais, setFiliais] = React.useState([]);
  const [dataIni, setDataIni] = React.useState(getInicioMes().toISOString().split("T")[0]);
  const [dataEnd, setDataEnd] = React.useState(getFinalMes().toISOString().split("T")[0]);
  const [isSms, setIsSms] = React.useState(true);
  const [isEmail, setIsEmail] = React.useState(true);
  const [isPush, setIsPush] = React.useState(true);
  const [contador, setContador] = React.useState(0);

  React.useEffect(() => {
    setNotificacoes([]);
    setLoading(true);

    socket.emit(
      "NotificacoesContrato.findAll",
      {
        attributes: ["id", "mensagem", "statusIntegracao", "isSms", "isEmail", "isPush"],
        where: {
          createdAt_between: [dataIni, dataEnd],
          or: [
            { isSms: isSms ? isSms : undefined },
            { isEmail: isEmail ? isEmail : undefined },
            { isPush: isPush ? isPush : undefined },
          ],
        },
        withContrato: {
          attributes: ["id", "numero_contrato"],
          where: {
            FilialId_in: filiais && filiais.length > 0 ? filiais.map((f) => f.id) : undefined,
          },
          withFilial: {
            attributes: ["nome"],
          },
          withPessoa: {
            attributes: ["id", "nome"],
          },
        },
      },
      (error, resp) => {
        setLoading(false);

        if (error) return toast.error("" + error);

        setNotificacoes(resp);
      }
    );
  }, [socket, filiais, dataIni, dataEnd, contador, isSms, isEmail, isPush]);

  return (
    <Container fluid>
      <Card className="shadow">
        <Card.Header className="bg-primary text-light">
          <h3 className="float-start">Relatório de Notificações dos Contratos</h3>
        </Card.Header>

        <Card.Body className="d-print-none">
          <Row>
            <Col sm={2}>
              <Form.Label>Filial</Form.Label>
              <MultiFiliais filiais={filiais} setFiliais={setFiliais} />
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Data Inicial</Form.Label>
                <Form.Control
                  type="date"
                  value={dataIni}
                  disabled={loading}
                  onChange={(e) => setDataIni(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Data Final</Form.Label>
                <Form.Control
                  type="date"
                  value={dataEnd}
                  disabled={loading}
                  onChange={(e) => setDataEnd(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    variant="info"
                    title="Listar"
                    className="me-2"
                    disabled={loading}
                    onClick={() => setContador(contador + 1)}
                  >
                    Atualizar
                  </Button>

                  <Button title="Listar" className="me-2" disabled={loading} onClick={() => window.print()}>
                    Imprimir
                  </Button>

                  <Button
                    title="Aplicar Filtros"
                    className="me-1"
                    variant="secondary"
                    onClick={() => {
                      setExibirFiltros(!exibirFiltros);
                    }}
                  >
                    <FontAwesomeIcon icon={faFilter} /> Filtros
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>

        <Listagem
          titulos={["Filial", "Contrato", "Cliente", "Tipos", "Notificação", "Status"]}
          tamanhos={[
            { md: 1, sm: 12 },
            { md: 1, sm: 12 },
            { md: 3, sm: 12 },
            { md: 1, sm: 12 },
            { md: 3, sm: 12 },
            { md: 3, sm: 12 },
          ]}
          itens={notificacoes}
          renderItem={(titulo, item) => {
            switch (titulo) {
              case "Filial":
                return ((item.Contrato || {}).Filial || {}).nome;
              case "Contrato":
                return (item.Contrato || {}).numero_contrato;
              case "Cliente":
                return ((item.Contrato || {}).Pessoa || {}).nome;
              case "Tipos":
                return (
                  <React.Fragment>
                    <span className={`me-2 ${item.isSms ? "" : "d-none"}`}>Sms</span>
                    <span className={`me-2 ${item.isEmail ? "" : "d-none"}`}>Email</span>
                    <span className={`me-2 ${item.isPush ? "" : "d-none"}`}>App</span>
                  </React.Fragment>
                );
              case "Notificação":
                return item.mensagem;
              case "Status":
                return item.statusIntegracao;
              default:
                return titulo;
            }
          }}
        />
      </Card>

      <Modal show={exibirFiltros} onHide={() => setExibirFiltros(false)} dialogClassName="modalFiltros">
        <Modal.Header closeButton>Filtros</Modal.Header>

        <Modal.Body>
          <Form.Group>
            <Form.Label>Tipos de Notificação</Form.Label>
            <div>
              <Form.Check
                checked={isSms}
                label="Sms"
                disabled={loading}
                onClick={() => {
                  setIsSms(!isSms);
                }}
              />

              <Form.Check
                checked={isEmail}
                label="Email"
                disabled={loading}
                onClick={() => {
                  setIsEmail(!isEmail);
                }}
              />

              <Form.Check
                checked={isPush}
                label="App"
                disabled={loading}
                onClick={() => {
                  setIsPush(!isPush);
                }}
              />
            </div>
          </Form.Group>
        </Modal.Body>
      </Modal>
    </Container>
  );
}
