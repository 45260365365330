import React from "react";
import BotaoAdicionar from "componentes/BotaoAdicionar";
import IoContext from "contextos/IoContext";
import { Card, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ModalAdicionarContratoVoip from "./telefonia/ModalAdicionarContratoVoip";
import { CONTRATO_VOIP_SITUACOES } from "datatypes/ContratoTypes";
import BotaoEditar from "componentes/BotaoEditar";
import BotaoApagar from "componentes/BotaoApagar";
import { formatarDinheiro } from "misc/formatarDinheiro";
import AcoesContratoVoipFactory from "./telefonia/AcoesContratoVoipFactory";

export default function ContratoTabTelefonia({ count }) {
  const { contrato_id } = useParams();
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(false);
  const [dados, setDados] = React.useState([]);
  const [showModalAdd, setShowModalAdd] = React.useState(false);
  const [ContratoVoipId, setContratoVoipId] = React.useState(0);
  const [localCount, setLocalCount] = React.useState(0);

  React.useEffect(() => {
    setLoading(true);
    setDados([]);

    socket.emit(
      "ContratoVoip.findAll",
      {
        where: {
          ContratoId: contrato_id,
        },
        withServicoContratado: {
          attributes: ["id"],
          withPlano: {
            attributes: ["id", "descricao"],
          },
        },
        withServidorVoip: {
          attributes: ["tipo"],
        },
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          return toast.error("" + error);
        }

        setDados(resp);
      }
    );
  }, [contrato_id, count, localCount, socket]);

  return (
    <React.Fragment>
      <Table size="sm" responsive>
        <thead>
          <tr>
            <th>Plano</th>
            <th>Descrição</th>
            <th>Usuário</th>
            <th>Saldo</th>
            <th>ICC Id</th>
            <th>Situação</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {dados?.map((item) => {
            return (
              <tr key={item.id}>
                <td>
                  {item.ServicoContratado?.id} - {item.ServicoContratado?.Plano?.descricao}
                </td>
                <td>{item.descricao}</td>
                <td>{item.usuario}</td>
                <td>{formatarDinheiro(item.saldo / 100)}</td>
                <td>{item.iccId}</td>
                <td>
                  {CONTRATO_VOIP_SITUACOES[item.situacao] || item.situacao}
                  {item.statusIntegracao ? `- ${item.statusIntegracao}` : ""}
                </td>
                <td className="text-nowrap">
                  {item.situacao < 50 && (
                    <BotaoEditar
                      className="btn-sm me-2"
                      disabled={loading}
                      onClick={() => {
                        setContratoVoipId(item.id);
                        setShowModalAdd(true);
                      }}
                    />
                  )}

                  <BotaoApagar
                    className="btn-sm me-2"
                    disabled={loading}
                    apagar={() => {
                      setLoading(true);
                      socket.emit("ContratoVoip.apagar", item, (error, resp) => {
                        setLoading(false);
                        if (error) {
                          return toast.error("" + error);
                        }
                        toast.success("Registro Apagado com Sucesso !");
                        setLocalCount(localCount + 1);
                      });
                    }}
                  />

                  <AcoesContratoVoipFactory
                    ContratoVoip={item}
                    disabled={loading}
                    callback={() => {
                      setLocalCount(localCount + 1);
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Card.Body>
        <BotaoAdicionar
          onClick={() => {
            setContratoVoipId(0);
            setShowModalAdd(true);
          }}
        />
      </Card.Body>

      <ModalAdicionarContratoVoip
        ContratoVoipId={ContratoVoipId}
        ContratoId={contrato_id}
        show={showModalAdd}
        onHide={setShowModalAdd}
        callback={() => {
          setLocalCount(localCount + 1);
        }}
        loading={loading}
        setLoading={setLoading}
      />
    </React.Fragment>
  );
}
