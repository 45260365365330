import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";

export default function Listagem({
  titulos = [],
  tamanhos = [],
  itens = [],
  onClick = null,
  renderItem,
  renderSummary = null,
  exibirBordas = false,
  getClasseLinha = null,
}) {
  return (
    <Card.Body>
      {(itens || []).map((item, idxItem) => {
        return (
          <ListGroup.Item
            key={idxItem}
            onClick={(e) => {
              if (onClick) {
                onClick(item, e, idxItem);
              }
            }}
            action={onClick ? true : false}
          >
            <Row className={`${exibirBordas ? "border-top" : ""} ${getClasseLinha ? getClasseLinha(item) : ""}`}>
              {titulos.map((titulo, idxTitulo) => {
                let tamanho = tamanhos[idxTitulo] || {
                  sm: 12,
                  md: "auto",
                };

                return (
                  <Col key={titulo} xs={12} sm {...tamanho}>
                    <strong className="d-none d-md-block text-info small">{titulo}</strong>
                    <p className="m-0 mt-1">
                      <strong className="me-2 d-md-none">{titulo}:</strong>
                      {renderItem(titulo, item, idxItem)}
                    </p>
                  </Col>
                );
              })}
            </Row>
          </ListGroup.Item>
        );
      })}

      {renderSummary && <React.Fragment>{renderSummary()}</React.Fragment>}
    </Card.Body>
  );
}
