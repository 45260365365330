import React from "react";
import Form from "react-bootstrap/Form";
import FormularioListagem from "../../../componentes/FormularioListagem";
import { toast } from "react-toastify";
import IoContext from "../../../contextos/IoContext";

import { useNavigate } from "react-router-dom";
import {
  OrdemSeparacaoSituacoes,
  OrdemSeparacaoTipos,
  ORDEM_SEPARACAO_TIPO_PARA_TECNICO,
  ORDEM_SEPARACAO_TIPO_TRANSF_ALMOXARIFADO,
} from "../../../datatypes/OrdemSeparacaoTypes";
import SelectAlmoxarifados from "../SelectAlmoxarifados";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAmbulance } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";

export default function OrdensSeparacao({
  almoxarifadoId,
  setAlmoxarifadoId,
  dataIni,
  setDataIni,
  dataEnd,
  setDataEnd,
  pagina,
  setPagina,
  texto,
  setTexto,
  situacao,
  setSituacao,
  direcaoOrdem,
  setDirecaoOrdem,
}) {
  const { socket } = React.useContext(IoContext);
  const [remessas, setRemessas] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const navigate = useNavigate();

  React.useEffect(() => {
    let where = {};
    let withAlmoxarifado = {
      where: {},
    };

    if (texto) withAlmoxarifado.where.nome_like = `%${texto}%`;
    if (situacao) where.situacao = +situacao;
    if (dataIni && dataEnd) where.createdAt_between = [dataIni, dataEnd];
    if (almoxarifadoId) where.AlmoxarifadoId = +almoxarifadoId;

    socket.emit(
      "OrdemSeparacao.findAndCountAll",
      {
        where,
        limit: 10,
        offset: 10 * (pagina - 1),
        order: [["createdAt", direcaoOrdem]],
        withAlmoxarifado,
        withAlmoxarifadoDestino: {
          attributes: ["nome"],
        },
        withResponsavel: {
          attributes: ["nome"],
        },
      },
      (error, resp) => {
        if (error) {
          toast.error(error);
        } else {
          setRemessas(resp.rows);
          setTotal(resp.count);
        }
      }
    );
  }, [socket, texto, pagina, situacao, dataIni, dataEnd, almoxarifadoId, direcaoOrdem]);

  return (
    <FormularioListagem
      titulo={`Remessas de Material`}
      itens={remessas}
      qtdItens={total}
      setTexto={setTexto}
      pagina={pagina}
      setPagina={setPagina}
      titulos={["Id", "Almoxarifado", "Tipo", "Destino", "Data", "Situação"]}
      tamanhos={[1, 3, 2, 2, 1, 1]}
      onClick={(e) => {
        navigate(`${e.id}`);
      }}
      renderItem={(titulo, item) => {
        switch (titulo) {
          case "Id":
            return item.id;
          case "Almoxarifado":
            return item.Almoxarifado?.nome;
          case "Tipo":
            return OrdemSeparacaoTipos[item.tipo] || item.tipo;
          case "Destino":
            switch (item.tipo) {
              case ORDEM_SEPARACAO_TIPO_TRANSF_ALMOXARIFADO:
                return item?.AlmoxarifadoDestino?.nome || "----";
              case ORDEM_SEPARACAO_TIPO_PARA_TECNICO:
                return item?.Responsavel?.nome || "----";
              default:
                return "----";
            }
          case "Data":
            return DateTime.fromISO(item.createdAt).toLocaleString(DateTime.DATE_SHORT);
          case "Situação":
            return OrdemSeparacaoSituacoes[item.situacao];
          default:
            return titulo;
        }
      }}
      renderBotoesCustom={() => {
        return (
          <React.Fragment>
            <Button
              variant="secondary"
              className="float-right ms-2"
              title="Interface Antiga/Legacy"
              onClick={() => {
                navigate("/lgc/estoque|ordem_separacao");
              }}
            >
              <FontAwesomeIcon icon={faAmbulance} />
            </Button>
          </React.Fragment>
        );
      }}
      renderFiltros={() => {
        return (
          <React.Fragment>
            <Form.Group>
              <Form.Label>Almoxarifado</Form.Label>
              <SelectAlmoxarifados
                value={almoxarifadoId}
                onChange={(e) => {
                  setAlmoxarifadoId(e.target.value);
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Situação</Form.Label>
              <Form.Control
                as="select"
                value={situacao}
                onChange={(e) => {
                  setSituacao(e.target.value);
                }}
              >
                <option value="">Todas</option>
                {Object.keys(OrdemSeparacaoSituacoes).map((key) => {
                  return (
                    <option key={key} value={key}>
                      {OrdemSeparacaoSituacoes[key]}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Data Inicial</Form.Label>
              <Form.Control type="date" value={dataIni} onChange={(e) => setDataIni(e.target.value)} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Data Final</Form.Label>
              <Form.Control type="date" value={dataEnd} onChange={(e) => setDataEnd(e.target.value)} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Direção da Ordenação</Form.Label>
              <Form.Control as="select" value={direcaoOrdem} onChange={(e) => setDirecaoOrdem(e.target.value)}>
                <option value="asc">Mais Antigos Primeiro</option>
                <option value="desc">Mais Recentes Primeiro</option>
              </Form.Control>
            </Form.Group>
          </React.Fragment>
        );
      }}
    />
  );
}
