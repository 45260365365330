import { formatarTelefone } from 'misc/utils';
import React from 'react';
import Form from 'react-bootstrap/Form';

export default function CampoTelefone(props) {
    const { onChange } = props;
    const [valido, setValido] = React.useState(false);

    React.useEffect(()=>{
        const soNumeros = props.value.replace(/\D/g,'');

        setValido(
            (!props.required && soNumeros.length === 0) || // Não Requerido
            (soNumeros.length === 10 && +soNumeros[2]!==9 && +soNumeros[0]!==0) || // Fixo
            (+soNumeros[2]===9 && soNumeros.length === 11) || // Celular
            (+soNumeros[0]===0 && +soNumeros[1]===8 && soNumeros.length === 11) // 0800
        );
    }, [props.value, props.required])

    return <Form.Group>
        <Form.Label className={`${valido ? '' : 'text-danger'}`}>{props.label || 'Telefone'}</Form.Label>
        <Form.Control
            {...props}
            maxLength={14} 
            className={`${valido ? 'is-valid' : "is-invalid"}`}
            onChange={ e => onChange({
                target: {
                    value: formatarTelefone(e.target.value)
                }
            })}
        />
    </Form.Group>
}