import IoContext from "contextos/IoContext";
import React from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

export default function ModalTcIRR({ show, onHide, asSet }) {
  const { socket } = React.useContext(IoContext);
  const [texto, setTexto] = React.useState("");

  React.useEffect(() => {
    setTexto("Aguarde, carregando...");
    if (!show) return;

    socket.emit("TcIRR.consultarAsSet", { asSet }, (error, resp) => {
      if (error) {
        console.log("ERROR:", error);
        return toast.error("" + error);
      }

      console.log("Texto:", resp);
      setTexto(resp);
    });
  }, [show, socket, asSet]);

  return (
    <Modal show={show} onHide={onHide} size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title>Visualizar AS-SET [{asSet}]</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <pre>{texto}</pre>
      </Modal.Body>
    </Modal>
  );
}
