import React from 'react';
import Form from 'react-bootstrap/Form';

export default function CampoAgenciaBancaria(props) {
    const { onChange } = props;
    return <Form.Control
            {...props}
            maxLength={5}
            onChange={ e => {
                const soNumeros = e.target.value.replace(/[^\d]/g, '');
                if(!soNumeros) onChange(soNumeros);

                let agencia = soNumeros.slice(0,4);
        
                if(soNumeros.length > 4){
                    agencia += '-' + soNumeros.slice(4,5);
                }

                if(`${e.target.value[4]}`.toLowerCase()==='x' || `${e.target.value[4]}`.toLowerCase()==='X' || `${e.target.value[5]}`.toLowerCase()==='x' || `${e.target.value[5]}`.toLowerCase()==='X') {
                    agencia += '-X';
                } 

                onChange(agencia);
            }}
        />
}