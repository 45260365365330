import React from 'react';
import CampoProcurar from '../../../../componentes/CampoProcurar';
import { formatarEndereco } from '../../../../misc/utils';
import IoContext from '../../../../contextos/IoContext';

export default function CampoProcurarEndereco({ titulo='Selecionar Endereço', value, onSelect, disabled }){
    const { socket } = React.useContext(IoContext);
    const [enderecos, setEnderecos] = React.useState([]);
    const loading = null;

    const getEnderecos = React.useCallback((texto)=>{
        socket.emit("Endereco.findAll", {
            where: {
                logradouro_like: `%${texto}%`
            },
            limit: 25
        }, (error, resp) => {
            if(error) {
                console.log("Endereco.findAll Error:", error);
            } else {
                setEnderecos(resp);
            }
        })
    }, [socket]);

    return <CampoProcurar 
        titulo={'Selecionar Endereço' || titulo}
        disabled={disabled || loading}
        value={typeof(value)==='object'? formatarEndereco(value) : (value ? value : 'SEM ENDEREÇO')}
        onSelect={onSelect}
        titulos={['Id','Endereço','Cep']}
        itens={enderecos}
        renderItem={ (titulo, item, idx) => {
            switch(titulo) {
                case 'Id': return item.id;
                case 'Endereço': return `${item.logradouro}-${item.bairro}-${item.cidade}-${item.cep}`;
                case 'Cep': return item.cep;
                default:
                    return titulo
            }
        }}
        onSearch={ txt => {
            getEnderecos(txt)
        }}
        />
}