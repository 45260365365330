import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

export default function Menu({
    id, formulario, legado, submenus, nivel, icone, nome,
    setSidebarOpen, Link
}) {
    const [showSubmenus, setShowSubmenus] = React.useState(false);
    let form = '' + formulario;
    let linkTo = '/' + form;
    const padding = `pt-2 pb-2 ps-${nivel === 1 ? 2 : nivel + 1}`;
    if (legado) {
        while (form.indexOf("/") !== -1) form = form.replace("/", "|");
        linkTo = (legado ? '/lgc/' : '/') + form;
    }

    const temSubmenus = submenus && submenus.length ? true : false;

    if (temSubmenus) {
        return <Nav>
            <Nav.Link className={`w-100 d-flex lhsidebar-link ${padding}`} onClick={e => {
                e.preventDefault();
                setShowSubmenus(!showSubmenus);
            }}>
                <span className="me-auto b-all">{nome}</span>
                <FontAwesomeIcon icon={showSubmenus ? faCaretUp : faCaretDown} />
            </Nav.Link>

            <Nav.Item className={`w-100 ${showSubmenus ? "d-block" : "d-none"} ${padding}`}>
                {submenus.map(submenu => {
                    return <Menu key={submenu.id}
                        setSidebarOpen={setSidebarOpen}
                        nivel={nivel + 1} {...submenu} Link={Link} />
                })}
            </Nav.Item>
        </Nav>
    } else {
        return <Nav.Item>
            <Link className={`w-100 h-100 d-flex lhsidebar-link ${padding}`}
                to={linkTo}
                onClick={() => {
                    setSidebarOpen(false);
                }}
            >
                {icone ? <i className={`fa fa-${icone}`} /> : false}
                <span className="me-auto b-all">{nome}</span>
            </Link>
        </Nav.Item>
    }
}