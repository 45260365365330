import React from 'react';
import TituloContext from './TituloContext';

export default function TituloProvider(props){
    const [titulo, setTitulo] = React.useState( props.titulo || "LHISP");
    const [appName, setAppName] = React.useState( props.appName || "LHISP");
    
    return <TituloContext.Provider value={{
        titulo, setTitulo, appName, setAppName
      }}>
          {props.children}
    </TituloContext.Provider>;
}