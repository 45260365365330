import React from "react";
import Form from "react-bootstrap/Form";

export const CUPOM_PARCEIRO_UBER = 1;
export const CUPOM_PARCEIRO_IFOOD = 2;
export const CUPOM_PARCEIROS = {
  [CUPOM_PARCEIRO_UBER]: "Uber",
  [CUPOM_PARCEIRO_IFOOD]: "iFood",
};

export default function SelectParceiroCupom(props) {
  const { id, value, onChange, disabled } = props;

  return (
    <Form.Control as="select" id={id || "uf"} onChange={onChange} value={value} disabled={disabled} {...props}>
      <option value=""></option>
      {Object.keys(CUPOM_PARCEIROS).map((c) => {
        return (
          <option key={c} value={c}>
            {CUPOM_PARCEIROS[c]}
          </option>
        );
      })}
    </Form.Control>
  );
}
