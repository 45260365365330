import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import SelectFiliais from './administrativo/filiais/SelectFiliais';
import CampoProcurarVendedor from '../financeiro/vendas/CampoProcurarVendedor';
import CampoProcurarPlano from './financeiro/planos/CampoProcurarPlano';
import IoContext from '../../contextos/IoContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

export default function ModalConfirmarPreCadastro({
    show, onHide, disabled, preCadastro, setPreCadastro, loading, setLoading
}) {
    const { socket } = React.useContext(IoContext);
    const navigate = useNavigate();

    return <Modal show={show} onHide={onHide} size="lg">
        <Form onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();

            setLoading(true);
            socket.emit("PreCadastro.salvar", preCadastro, (error, resp) => {
                setLoading(false);
                if (error) {
                    return toast.error(error);
                }

                socket.emit("PreCadastro.Confirmar", {
                    where: { id: preCadastro.id }
                }, (error, resp) => {
                    setLoading(false);
                    if (error) {
                        return toast.error('' + error);
                    }

                    onHide(false);
                    navigate(-1);
                    const contrato = resp.Contrato || {};
                    toast.success(`Pré-Cadastro confirmado com sucesso, Contrato: ${contrato.numero_contrato}`);
                })
            });
        }}>

            <Modal.Header closeButton>
                <Modal.Title>Confirmar Pré-Cadastro [{preCadastro.id}]</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row>
                    <Col sm={12}>
                        <Form.Group>
                            <Form.Label>Filial</Form.Label>
                            <SelectFiliais required value={preCadastro.FilialId} onChange={e => {
                                setPreCadastro({
                                    ...preCadastro, FilialId: e.target.value,
                                    PlanoId: 0,
                                    Plano: null,
                                });
                            }} />
                        </Form.Group>
                    </Col>

                    <Col sm={12}>
                        <Form.Group>
                            <Form.Label>Plano</Form.Label>
                            <CampoProcurarPlano required FilialId={preCadastro.FilialId} value={preCadastro.Plano ? preCadastro.Plano.nome || '' : ''} disabled={loading || !preCadastro.FilialId}
                                onSelect={plano => {
                                    setPreCadastro({
                                        ...preCadastro,
                                        PlanoId: plano ? plano.id : null,
                                        Plano: plano,
                                    });
                                }} />
                        </Form.Group>
                    </Col>

                    <Col sm={12}>
                        <Form.Group>
                            <Form.Label>Vendedor</Form.Label>
                            <CampoProcurarVendedor value={preCadastro.Vendedor ? preCadastro.Vendedor.nome || '' : ''} disabled={loading}
                                onSelect={vendedor => {
                                    setPreCadastro({
                                        ...preCadastro,
                                        VendedorId: vendedor ? vendedor.id : null,
                                        Vendedor: vendedor,
                                    });
                                }} />
                        </Form.Group>
                    </Col>

                    <Col sm={12}>
                        <Form.Group>
                            <Form.Label>Vencimento</Form.Label>
                            <Form.Control as="select"
                                required disabled={loading}
                                value={preCadastro.diaVencimento}
                                onChange={e => {
                                    setPreCadastro({
                                        ...preCadastro,
                                        diaVencimento: e.target.value
                                    });
                                }}
                            >
                                <option value=""></option>
                                {Array.from(Array(31).keys()).map(idx => {
                                    return <option value={idx + 1} key={idx}>{idx + 1}</option>
                                })}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <p className="text-center text-info">Ao confirmar será gerado um Contrato para a Pessoa em questão.</p>
            </Modal.Body>

            <Modal.Footer>
                <Button className="btn-warning me-2" disabled={disabled} onClick={() => {
                    onHide(false);
                }}>Cancelar</Button>

                <Button className="btn-primary" disabled={disabled} type="submit">Ok</Button>
            </Modal.Footer>
        </Form>
    </Modal>
}