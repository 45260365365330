export const SERVIDOR_VOIP_TIPO_MEUCCI = 1;
export const SERVIDOR_VOIP_TIPO_MEGATELECOM = 2;
export const SERVIDOR_VOIP_TIPO_ALO_FONE = 3;
export const SERVIDOR_VOIP_TIPO_A2BILLING = 4;

export const SERVIDOR_VOIP_TIPOS = {
    1: "Meucci",
    2: "Mega Telecom",
    3: "Alo Fone",
    4: "A2Billing",
}