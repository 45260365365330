import React from 'react';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import IoContext from 'contextos/IoContext';

export default function SelectServidorVoip(props) {
    const { socket } = React.useContext(IoContext);
    const [servidoresVoip, setSservidoresVoip] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const { id, onSelect, value, onChange, disabled, pular } = props;

    React.useEffect(() => {
        setLoading(true);
        setSservidoresVoip([]);

        socket.emit("ServidorVoip.findAll", {
            attributes: ['id', 'nome', 'tipo'],
        }, (error, resp) => {
            setLoading(false);
            if (error) {
                toast.error(error);
            } else {
                setSservidoresVoip(resp);
            }
        });
    }, [socket]);

    const nProps = {...props};
    delete nProps.onSelect;
    delete nProps.onChange;
    return <Form.Control as="select"
        id={id || "ServidorVoipId"}
        onChange={ e => {
            onChange(e);
            if(onSelect) {
                const servidorVoip = servidoresVoip?.find( s => +s.id === +e.target.value );
                onSelect(servidorVoip);
            }
        }} value={value}
        disabled={loading || disabled}
        {...nProps}
    >
        <option value=""></option>
        {(servidoresVoip || []).map(s => {
            if (pular) {
                if (Array.isArray(pular)) {
                    if (pular.indexOf(s.id)) {
                        return false;
                    }
                } else {
                    if (+pular === +s.id) {
                        return false;
                    }
                }
            }
            return <option key={s.id} value={s.id}>{s.nome}</option>
        })}
    </Form.Control>;
}
