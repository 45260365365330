import React from 'react';
import { BrowserRouter } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthContext from '../contextos/AuthContext';
import Login from '../paginas/login/Login';
import Principal from '../paginas/principal/Principal';
import axios from 'axios';

if(process.env.NODE_ENV === 'development' && process.env.REACT_APP_LHISP_BACKEND) {
    axios.defaults.baseURL = process.env.REACT_APP_LHISP_BACKEND;
}

function App() {
    const { isTokenValido } = React.useContext(AuthContext);

    return <BrowserRouter>
        { isTokenValido ? <Principal /> : <Login /> }
        <ToastContainer />
    </BrowserRouter>;
}

export default App;
