import React from 'react';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';

export default function AutoComplete({ 
    placeholder='', className,
    value, onChange, disabled=false,
    itens=[], onClick, keyText=null
}) {
    const [itensExibir, setItensExibir] = React.useState([]);
    const [idxAtivo, setIdxAtivo] = React.useState(0);
    const refCampoTexto = React.useRef();

    React.useEffect(()=>{
        document.addEventListener('keydown', function(e) {
            if(e.key === keyText) { 
                e.preventDefault();

                if(refCampoTexto.current){
                    refCampoTexto.current.focus();
                }
            }
        });
    }, [keyText])

    React.useEffect(()=>{
        if(!value) {
            return setItensExibir([]);
        }
        
        let txt = (value+'').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        setItensExibir(
            itens.filter( m => {
                return (m.texto+'').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").indexOf(txt)!==-1
            }).splice(0,5)
        );

        setIdxAtivo(0);
    }, [itens, value]);

    return <React.Fragment>
        <Form.Control className={className} placeholder={placeholder} value={value} onChange={onChange} disabled={disabled} 
            ref={refCampoTexto} onKeyUp={ e => {
                switch(e.key) {
                    case 'ArrowDown': 
                        if(idxAtivo < (itensExibir.length -1))
                            setIdxAtivo(idxAtivo+1); break;
                    case 'ArrowUp': 
                        if(idxAtivo > 0)
                            setIdxAtivo(idxAtivo-1); 
                        break;
                    case 'Enter':
                        if(itensExibir[idxAtivo]) {
                            onClick(itensExibir[idxAtivo]);
                        } else {
                            console.log("## ENTER KEY ON:", idxAtivo);
                        }
                        break;
                    default:
                        // Faz nada rsrsrs.
                }
            }} />
        
        { (itensExibir || []).length > 0 ? 
        <ListGroup className="position-absolute w-100 ">
            {(itensExibir || []).map( (item,i) => {
                const idx = (item.texto+'').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").indexOf(value.toLowerCase());
                

                return <ListGroup.Item key={i} action onClick={()=> onClick(item)} active={i===idxAtivo} >
                    <div className="small">{item.titulo}</div>

                    <span>
                        {item.texto.substr(0, idx)} 
                        <b>{item.texto.substr(idx, value.length)}</b>
                        {item.texto.substr(idx + value.length)}
                    </span>
                </ListGroup.Item>
            })}
        </ListGroup>
        : false }
    </React.Fragment>
}