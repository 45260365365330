import { NOTAFISCAL_TIPOS } from 'datatypes/NotaFiscalTypes';
import React from 'react';
import Form from 'react-bootstrap/Form';

export default function SelectTipoNotaFiscal(props){
    const { id, value, onChange, disabled } = props; 

    return <Form.Control as="select" 
        id={id || "TipoNotaFiscal"} 
        onChange={onChange}
        value={value}
        disabled={disabled}
        {...props}
    >
        <option value=""></option>
        { Object.keys(NOTAFISCAL_TIPOS).map( c => {
            return <option key={c} value={c}>{NOTAFISCAL_TIPOS[c]}</option>
        })}
    </Form.Control>;
}
