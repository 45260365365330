import React from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FormularioListagem from "../../componentes/FormularioListagem";
import IoContext from "../../contextos/IoContext";
import { toast } from "react-toastify";
import SelectFiliais from "../cadastros/administrativo/filiais/SelectFiliais";
import { useNavigate } from "react-router-dom";
import { formatarDoc1, formatarTelefone } from "misc/utils";
import {
  CONTRATO_CAMPOS_PESQUISA,
  CONTRATO_CAMPO_PESQUISA_APELIDO,
  CONTRATO_CAMPO_PESQUISA_CLIENTE_CONTRATO,
  CONTRATO_CAMPO_PESQUISA_CPF_CNPJ,
  CONTRATO_CAMPO_PESQUISA_EMAIL,
  CONTRATO_CAMPO_PESQUISA_GPON_ONU,
  CONTRATO_CAMPO_PESQUISA_IMPORTACAO_ID,
  CONTRATO_CAMPO_PESQUISA_IPV4,
  CONTRATO_CAMPO_PESQUISA_IPV6,
  CONTRATO_CAMPO_PESQUISA_MAC,
  CONTRATO_CAMPO_PESQUISA_PROTOCOLO_ATENDIMENTO,
  CONTRATO_CAMPO_PESQUISA_RAMAL,
  CONTRATO_CAMPO_PESQUISA_TELEFONES,
  CONTRATO_CAMPO_PESQUISA_USUARIO,
  CONTRATO_CAMPO_PESQUISA_WEBSITE,
} from "datatypes/ContratoTypes";
import SelectUf from "componentes/SelectUf";
import SelectCidades from "paginas/cadastros/administrativo/enderecos/SelectCidade";
import SelectSituacaoContrato from "componentes/SelectSituacaoContrato";

export default function Contratos({
  FilialId,
  setFilialId,
  situacao,
  setSituacao,
  pagina,
  setPagina,
  texto,
  setTexto,
  campoPesquisa,
  setCampoPesquisa,
  uf,
  setUf,
  cidade,
  setCidade,
  bairro,
  setBairro,
  logradouro,
  setLograroudo,
}) {
  const navigate = useNavigate();
  const { socket } = React.useContext(IoContext);
  const [filiais, setFiliais] = React.useState({});
  const [contratos, setContratos] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setFiliais({});
    socket.emit(
      "Filial.findAll",
      {
        attributes: ["id", "nome"],
      },
      (error, resp) => {
        setLoading(false);
        if (error) toast.error("" + error);

        const filiais = {};
        for (let i = 0; i < resp?.length; i++) {
          filiais[resp[i].id] = resp[i].nome;
        }

        setFiliais(filiais);
      }
    );
  }, [socket]);

  React.useEffect(() => {
    setTotal(0);
    setContratos([]);

    let where = {};
    const optionalQuery = {};
    let wherePessoa = {};
    let whereAcesso = {};
    let whereEndereco = {};
    let requiredAcesso = false;
    let requiredServicoContratdo = false;
    const whereContratoVoip = {
      required: false,
    };
    let limit = 10;

    if (texto) {
      limit = 100;
      switch (campoPesquisa) {
        case CONTRATO_CAMPO_PESQUISA_IMPORTACAO_ID:
          where.ImportacaoId = texto;
          break;
        case CONTRATO_CAMPO_PESQUISA_CLIENTE_CONTRATO:
          if (!isNaN(Number.parseInt(texto))) {
            where.numero_contrato = texto;
          } else {
            wherePessoa.or = [
              {
                nome: `${texto}`
                  .split(" ")
                  .filter((t) => t.length > 2)
                  .join(" "),
              },
              {
                nome_like: `${texto}%`,
              },
              {
                nome_fulltext: `${texto}`
                  .split(" ")
                  .filter((t) => t.length > 2)
                  .join(" "),
              },
            ];
          }
          break;
        case CONTRATO_CAMPO_PESQUISA_EMAIL:
          wherePessoa.email_like = `%${texto}%`;
          break;
        case CONTRATO_CAMPO_PESQUISA_APELIDO:
          wherePessoa.apelido_like = `%${texto}%`;
          break;
        case CONTRATO_CAMPO_PESQUISA_CPF_CNPJ:
          wherePessoa.doc1_like = `%${formatarDoc1(texto)}%`;
          break;
        case CONTRATO_CAMPO_PESQUISA_WEBSITE:
          wherePessoa.website_like = `%${texto}%`;
          break;
        case CONTRATO_CAMPO_PESQUISA_TELEFONES:
          let tel = texto.length > 4 ? formatarTelefone(texto) : texto;
          wherePessoa.or = [
            {
              telefone1_like: `%${tel}%`,
            },
            {
              telefone2_like: `%${tel}%`,
            },
            {
              telefone3_like: `%${tel}%`,
            },
            {
              telefone4_like: `%${tel}%`,
            },
          ];
          break;
        case CONTRATO_CAMPO_PESQUISA_MAC:
          requiredAcesso = true;
          requiredServicoContratdo = true;
          whereAcesso.mac_like = `%${texto}%`;
          break;
        case CONTRATO_CAMPO_PESQUISA_IPV4:
          requiredAcesso = true;
          requiredServicoContratdo = true;
          whereAcesso.enderecoIpv4_like = `%${texto}%`;
          break;
        case CONTRATO_CAMPO_PESQUISA_IPV6:
          requiredAcesso = true;
          requiredServicoContratdo = true;
          whereAcesso.prefixoIpv6_like = `%${texto}%`;
          break;
        case CONTRATO_CAMPO_PESQUISA_GPON_ONU:
          requiredAcesso = true;
          requiredServicoContratdo = true;
          whereAcesso.gpononu_numero_serie_like = `%${texto}%`;
          break;
        case CONTRATO_CAMPO_PESQUISA_USUARIO:
          requiredAcesso = true;
          requiredServicoContratdo = true;
          whereAcesso.usuario_like = `%${texto}%`;
          break;
        case CONTRATO_CAMPO_PESQUISA_RAMAL:
          whereContratoVoip.usuario_like = `%${texto}%`;
          break;
        case CONTRATO_CAMPO_PESQUISA_PROTOCOLO_ATENDIMENTO:
          optionalQuery.withAtendimentos = {
            attributes: ["id"],
            where: {
              id: texto,
            },
          };
          break;
        default:
          toast.info(`Busca Não Implementada: [${campoPesquisa}]`);
      }
    }

    if (situacao) {
      where.situacao = situacao;
    }

    if (FilialId) {
      limit = 100;
      where.FilialId = FilialId;
    }
    if (uf) {
      limit = 100;
      whereEndereco.uf = uf;
    }
    if (cidade) {
      limit = 100;
      whereEndereco.cidade = cidade;
    }
    if (bairro) {
      limit = 100;
      whereEndereco.bairro_like = `${bairro}%`;
    }
    if (logradouro) {
      limit = 100;
      whereEndereco.logradouro_like = `${logradouro}%`;
    }

    setLoading(true);
    socket.emit(
      "Contrato.findAll",
      {
        attributes: ["id", "contrato", "situacao", "numero", "FilialId"],
        withPessoa: {
          attributes: [
            "nome",
            "apelido",
            "doc1",
            "doc2",
            "email",
            "website",
            "telefone1",
            "telefone2",
            "telefone3",
            "telefone4",
          ],
          where: wherePessoa,
        },
        withEndereco: {
          where: whereEndereco,
        },
        withServicosContratados: {
          attributes: ["id", "situacao"],
          withAcesso: {
            attributes: ["usuario", "enderecoIpv4", "prefixoIpv6", "gpononu_numero_serie"],
            where: whereAcesso,
            required: requiredAcesso,
          },
          required: requiredServicoContratdo,
        },
        withContratosVoip: whereContratoVoip?.usuario_like ? { where: whereContratoVoip } : undefined,
        where,
        ...optionalQuery,
        order: [["id", "ASC"]],
        limit,
      },
      (error, resp) => {
        setLoading(false);

        if (error) {
          return toast.error("" + error);
        }

        if (resp?.length === 1) {
          setTexto("");
          return navigate(`${resp[0].id}`);
        }

        let contratos = [];
        resp?.forEach((c) => {
          if (c.ServicoContratados?.length) {
            c.ServicoContratados?.forEach((serc) => {
              contratos.push({
                ...c,
                ServicoContratados: [serc],
              });
            });
          } else {
            contratos.push(c);
          }
        });
        setContratos(contratos);
        setTotal(contratos.length - 1);
      }
    );
  }, [
    socket,
    pagina,
    texto,
    campoPesquisa,
    FilialId,
    situacao,
    uf,
    cidade,
    bairro,
    logradouro,
    setLoading,
    navigate,
    setTexto,
  ]);
  const sliceIni = (pagina - 1) * 10;
  const sliceEnd = sliceIni + 10;

  return (
    <FormularioListagem
      titulo={`Contratos`}
      itens={(contratos || []).slice(sliceIni, sliceEnd)}
      qtdItens={total}
      pagina={pagina}
      setTexto={setTexto}
      setPagina={setPagina}
      loading={loading}
      titulos={["Contrato", "Filial", "Cliente", "Telefones", "Endereço", "Usuário", "Situação"]}
      camposPesquisa={CONTRATO_CAMPOS_PESQUISA}
      campoPesquisa={campoPesquisa}
      setCampoPesquisa={setCampoPesquisa}
      tamanhos={[
        { sm: 12, md: 1 },
        { sm: 12, md: 1 },
        { sm: 12, md: 3 },
        { sm: 12, md: 1 },
        { sm: 12, md: 3 },
        { sm: 12, md: 1 },
        { sm: 12, md: 2 },
      ]}
      onClick={(e) => {
        navigate(`${e.id}`);
      }}
      getClasseLinha={(item) => {
        switch (item?.ServicoContratados[0]?.situacao) {
          case "CANCELADO":
            return "text-danger";
          case "BLOQUEADO":
            return "text-warning";
          case "PENDENTE":
            return "text-info";
          default:
            return "";
        }
      }}
      renderItem={(titulo, item) => {
        switch (titulo) {
          case "Id":
            return item.id;
          case "Filial":
            return filiais[item.FilialId] || "----";
          case "Contrato":
            return item.contrato;
          case "Situação":
            return <React.Fragment>{item?.ServicoContratados[0]?.situacao}</React.Fragment>;
          case "Cliente":
            return item.Pessoa ? item.Pessoa.nome : "----";
          case "Email":
            return item.Pessoa?.email || "----";
          case "Cpf / Cnpj":
            return item.Pessoa?.doc1;
          case "Apelido":
            return item.Pessoa?.apelido || "----";
          case "Website":
            return item.Pessoa?.website || "----";
          case "Endereço":
            const e = item.Endereco;
            return `${e.logradouro}, ${item.numero}`;
          case "Telefones":
            const telefones = [];
            if (item.Pessoa) {
              if (item.Pessoa.telefone1) telefones.push(item.Pessoa.telefone1);
              if (item.Pessoa.telefone2) telefones.push(item.Pessoa.telefone2);
              if (item.Pessoa.telefone3) telefones.push(item.Pessoa.telefone3);
              if (item.Pessoa.telefone4) telefones.push(item.Pessoa.telefone4);
            }
            return telefones.join(" ");
          case "Usuário":
            return item.ServicoContratados[0]?.Acessos[0]?.usuario;
          case "Mac":
            return item.ServicoContratados[0]?.Acessos[0]?.mac;
          case "IPv4":
            return item.ServicoContratados[0]?.Acessos[0]?.enderecoIpv4;
          case "IPv6":
            return item.ServicoContratados[0]?.Acessos[0]?.prefixoIpv6;
          case "Gpon ONU":
            return item.ServicoContratados[0]?.Acessos[0]?.gpononu_numero_serie;
          default:
            return titulo;
        }
      }}
      renderFiltros={() => {
        return (
          <Card.Body>
            <Form.Group>
              <Form.Label>Filial</Form.Label>
              <SelectFiliais value={FilialId} disabled={loading} onChange={(e) => setFilialId(e.target.value)} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Situação</Form.Label>
              <SelectSituacaoContrato
                disabled={loading}
                value={situacao}
                onChange={(e) => setSituacao(e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Uf</Form.Label>
              <SelectUf value={uf} disabled={loading} onChange={(e) => setUf(e.target.value)} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Cidade</Form.Label>
              <SelectCidades uf={uf} disabled={loading} value={cidade} onChange={(e) => setCidade(e.target.value)} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Bairro</Form.Label>
              <Form.Control value={bairro} disabled={loading} onChange={(e) => setBairro(e.target.value)} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Logradouro</Form.Label>
              <Form.Control value={logradouro} disabled={loading} onChange={(e) => setLograroudo(e.target.value)} />
            </Form.Group>
          </Card.Body>
        );
      }}
    />
  );
}
