import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Button from 'react-bootstrap/Button';
import ModalConfirmar from './ModalConfirmar';

export default function BotaoApagar({
    apagar,
    titulo='Deseja realmente Apagar esse Registro ?',
    texto='Cuidado, essa operação é irreversível !',
    disabled,
    textoBotao="",
    className=""
}) {
    const [exibirModal, setExibirModal] = React.useState(false);

    return <React.Fragment>
        <ModalConfirmar
            titulo={titulo}
            texto={texto}
            show={exibirModal}
            disabled={disabled}
            size="md"
            onHide={ ()=> {
                setExibirModal(false);
            }}
            callback={async ()=> {
                await apagar();
                setExibirModal(false);
            }}
            />

        <Button 
            variant='danger'
            className={className}
            disabled={disabled}
            title="Apagar"
            onClick={ e => { setExibirModal(true); }}
        >
            <FontAwesomeIcon icon={faTrash} className={`${textoBotao ? 'me-2' : ''}`} />
        </Button>
    </React.Fragment>
}