import React from "react";
import axios from "axios";
import AuthContext from "./AuthContext";

const key_empresa = `${window.location.hostname}_empresa`;
const key_usuario = `${window.location.hostname}_usuario`;
const key_sysname = `${window.location.hostname}_sysname`;
const key_logomarca = `${window.location.hostname}_logomarca`;

let store_empresa = null;
let store_usuario = null;
let store_sysname = "LHISP";
let authTimer = null;
let store_logomarca = null;

try {
  store_usuario = JSON.parse(localStorage.getItem(key_usuario));
  store_sysname = JSON.parse(localStorage.getItem(key_sysname));
  store_empresa = JSON.parse(localStorage.getItem(key_empresa));
  store_logomarca = JSON.parse(localStorage.getItem(key_logomarca));
} catch (E) {}

export default function AuthProvider(props) {
  const [empresa, setEmpresa] = React.useState(store_empresa);
  const [logmarca, setLogomarca] = React.useState(store_logomarca);
  const [usuario, setUsuario] = React.useState(store_usuario);
  const [sysname, setSysname] = React.useState(store_sysname ? store_sysname : "LHISP");
  const [isTokenValido, setTokenValido] = React.useState(false);

  React.useEffect(() => {
    if (empresa) {
      window.document.title = empresa.nome;
    } else {
      window.document.title = "LHISP";
    }
  }, [empresa]);

  React.useEffect(() => {
    if (authTimer) {
      clearTimeout(authTimer);
    }

    if (usuario) {
      const d1 = new Date(usuario.token_valido_ate);
      const d2 = new Date();
      const tempoParaExpirar = d1.getTime() - d2.getTime();

      if (d1.getTime() <= d2.getTime()) {
        console.log("## TOKEN EXPIROU:", d1, d2);
        setUsuario(null);
        setTokenValido(false);
      } else {
        authTimer = setTimeout(function () {
          setUsuario(null);
          setTokenValido(false);
        }, tempoParaExpirar);
      }
    }

    return () => {
      clearTimeout(authTimer);
    };
  }, [usuario, setUsuario, setTokenValido]);

  return (
    <AuthContext.Provider
      value={{
        usuario,
        empresa,
        sysname,
        logmarca,
        setLogomarca,
        setUsuario: (u) => {
          if (localStorage) {
            localStorage.setItem(key_usuario, JSON.stringify(u));
          }
          if (u) {
            axios.defaults.headers.common["Authorization"] = u.token;
          } else {
            delete axios.defaults.headers.common["Authorization"];
            delete axios.defaults.headers.common["authorization"];
          }

          setUsuario(u);
        },
        setSysname: (u) => {
          if (localStorage) {
            localStorage.setItem(key_sysname, JSON.stringify(u));
          }

          window.document.title = u && u !== "null" ? u : "LHISP";
          if (u) {
            axios.defaults.headers.common["sysname"] = u;
          } else {
            axios.defaults.headers.common["sysname"] = null;
          }

          setSysname(u);
        },
        setEmpresa: (u) => {
          if (localStorage) {
            localStorage.setItem(key_empresa, JSON.stringify(u));
          }
          if (u) {
            axios.defaults.headers.common["EmpresaId"] = u.id;
          } else {
            delete axios.defaults.headers.common["EmpresaId"];
          }

          setEmpresa(u);
        },
        isTokenValido,
        setTokenValido,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}
