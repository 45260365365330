import React from 'react';
import FormularioListagem from 'componentes/FormularioListagem';
import IoContext from 'contextos/IoContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

export default function MaxiTvUpdates({ 
    pagina, setPagina, texto, setTexto
}){
    const  navigate = useNavigate();
    const { socket } = React.useContext(IoContext);
    const [caixas, setCaixas] = React.useState([]);
    const [total, setTotal] = React.useState(0);

    React.useEffect(()=>{
        let where = {};
        if(texto) where.nome_like = `%${texto}%`;

        socket.emit("MaxiTvUpdate.findAndCountAll", { 
            attributes: ['id', 'nome', 'versao', 'caminho'],
            where, limit: 10, offset: 10 * (pagina-1) 
        }, (error, resp) => {
            if(error) {
                toast.error(''+error);
            } else {
                setCaixas(resp.rows);
                setTotal(resp.count);
            }
        });
    }, [pagina,texto, socket]);

    return <FormularioListagem
        titulo={`MaxiTv Updates`}
        itens={caixas} qtdItens={total} pagina={pagina}
        setTexto={setTexto} setPagina={setPagina}
        titulos={["Id", "Nome", "Versão", "Caminho"]}
        onClick={(e)=>{
            navigate(`${e.id}`);
        }}
        
        renderItem={ (titulo, item) => {
            switch(titulo) {
                case 'Id': return item.id;
                case 'Nome': return item.nome;
                case 'Versão': return item.versao;
                case 'Caminho': return item.caminho;
                default: return titulo;
            }
        }}
        />
}