export const NOTAFISCAL_TIPO_SCM     = 1; // Comunicacao
export const NOTAFISCAL_TIPO_SVA     = 2; // Nota de Débito
export const NOTAFISCAL_TIPO_LOCACAO = 3; // Simples Fatura
export const NOTAFISCAL_TIPO_ISS     = 4; // ISS - Nota de Serviço
export const NOTAFISCAL_TIPO_VENDA   = 5; // Nota de Débito
export const NOTAFISCAL_TIPO_MULTA   = 6; // Nota de Débito

export const NOTAFISCAL_TIPOS = {
    1: 'Modelo 21 - Comunicação',
    2: 'SVA - Nota de Débito',
    3: 'Locação - Simples Fatura',
    4: 'Nota de Serviço',
    5: 'Nota de Venda',
    6: 'Nota de Multas',
}

export const NOTAFISCAL_SITUACOES = {
    1: 'Normal',
    2: 'Cancelada',
}

export const NOTAFISCAL_NORMAL = 1;
export const NOTAFISCAL_CANCELADA = 2;