import React from "react";
import { Routes, Route } from "react-router-dom";
import CupomIndex from "./cupons/index";

export default function MarketingIndex() {
  return (
    <Routes>
      <Route path={`/cupons/:PessoaId`} element={<CupomIndex />} />
    </Routes>
  );
}
