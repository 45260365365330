import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Listagem from "./Listagem";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Paginacao from "./Paginacao";
import TituloContext from "../contextos/TituloContext";
import GetCsvFile from "./GetCsvFile";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faPlus, faFilter, faFileCsv } from "@fortawesome/free-solid-svg-icons";
import BotaoProcessando from "./BotaoProcessando";

export default function FormularioListagem({
  titulos = [],
  renderFiltros = null,
  renderItem,
  pagina,
  setPagina,
  itensPorPagina = 10,
  itens,
  qtdItens,
  texto = "",
  tamanhos = [],
  placeholder = "",
  setTexto,
  titulo,
  onClick = null,
  loading = false,
  renderBotoesCustom = null,
  camposPesquisa = null,
  campoPesquisa = null,
  setCampoPesquisa = null,
  renderBtCadastrar = true,
  exibirCampoPesquisaNaListagem = true,
  separadorMultiCampoPesquisa = "ou",
  exibirBordas = true,
  getClasseLinha = null,
}) {
  const [procurarPor, setProcurarPor] = React.useState(texto || "");
  const [exibirFiltros, setExibirFiltros] = React.useState(false);
  const { setTitulo } = React.useContext(TituloContext);
  const [downloadCsv, setDownloadCsv] = React.useState(false);
  const [_titulos, setTitulos] = React.useState(titulos);

  React.useEffect(() => {
    // Utiliza os titulos padrao passados via props
    if (!campoPesquisa || !exibirCampoPesquisaNaListagem) {
      return setTitulos(titulos);
    }

    let novosTitulos = [...titulos];
    campoPesquisa.split(separadorMultiCampoPesquisa).forEach((titulo) => {
      if (!novosTitulos.includes(titulo.trim())) {
        novosTitulos.push(titulo);
      }
    });
    setTitulos(novosTitulos);
  }, [titulos, campoPesquisa, separadorMultiCampoPesquisa, exibirCampoPesquisaNaListagem]);

  React.useEffect(() => {
    if (titulo) setTitulo(titulo);
  }, [titulo, setTitulo]);

  return (
    <React.Fragment>
      <Container fluid className="pt-3">
        <Card>
          <Card.Body>
            <Row>
              <Col sm={camposPesquisa ? 6 : 8} md={camposPesquisa ? 6 : 9}>
                <Form.Group>
                  <Form.Label>Procurar:</Form.Label>
                  <Form.Control
                    type="text"
                    autoFocus
                    value={procurarPor}
                    disabled={loading}
                    placeholder={placeholder}
                    onChange={(e) => {
                      setProcurarPor(e.target.value);
                    }}
                    onBlur={() => {
                      setTexto(procurarPor);
                    }}
                    onKeyDown={(e) => {
                      if (e.which === 13) {
                        setPagina(1);
                        setTexto(procurarPor);
                      }
                    }}
                  />
                </Form.Group>
              </Col>

              {camposPesquisa && (
                <Col sm={2} md={3}>
                  <Form.Group>
                    <Form.Label>Campo:</Form.Label>
                    <Form.Control
                      as={"select"}
                      value={campoPesquisa}
                      disabled={loading}
                      onChange={(e) => setCampoPesquisa(e.target.value)}
                    >
                      {camposPesquisa.map((c) => {
                        return (
                          <option value={c} key={c}>
                            {c}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                </Col>
              )}

              <Col sm={4} md={3}>
                <Form.Group>
                  <Form.Label>Ações:</Form.Label>
                  <div>
                    {loading ? (
                      <BotaoProcessando loading={loading} />
                    ) : (
                      <React.Fragment>
                        <Button
                          title="Procurar"
                          className="me-1"
                          onClick={(e) => {
                            setTexto(procurarPor);
                          }}
                        >
                          <FontAwesomeIcon icon={faSearch} />
                        </Button>

                        {renderFiltros ? (
                          <Button
                            title="Aplicar Filtros"
                            className="me-1"
                            variant="secondary"
                            onClick={() => {
                              setExibirFiltros(!exibirFiltros);
                            }}
                          >
                            <FontAwesomeIcon icon={faFilter} />
                          </Button>
                        ) : (
                          false
                        )}

                        {renderBtCadastrar && (
                          <Button
                            title="Cadastrar"
                            variant="success"
                            className={`${onClick ? "" : "d-none"}`}
                            onClick={(e) => {
                              onClick({ id: "add" });
                            }}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </Button>
                        )}

                        <Button
                          title="Baixar Planilha"
                          variant="secondary"
                          className="ms-1"
                          onClick={(e) => {
                            setPagina(1);
                            setDownloadCsv(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faFileCsv} />
                        </Button>

                        {renderBotoesCustom ? renderBotoesCustom() : false}
                      </React.Fragment>
                    )}
                  </div>
                </Form.Group>
              </Col>
            </Row>

            {downloadCsv ? (
              <GetCsvFile
                titulo={titulo}
                titulos={titulos}
                nPaginas={qtdItens ? Math.floor(qtdItens / itensPorPagina) + 1 : 0}
                pagina={pagina}
                setPagina={setPagina}
                itens={itens}
                loading={loading}
                renderItem={renderItem}
                onFinish={() => {
                  setDownloadCsv(false);
                  setPagina(1);
                }}
              />
            ) : (
              false
            )}
          </Card.Body>

          <Listagem
            titulos={_titulos}
            tamanhos={tamanhos}
            getClasseLinha={getClasseLinha}
            itens={itens}
            renderItem={renderItem}
            onClick={onClick}
            exibirBordas={exibirBordas}
          />

          <Card.Footer>
            <Paginacao pagina={pagina} setPagina={setPagina} total={qtdItens ? qtdItens : 0} />
          </Card.Footer>
        </Card>
      </Container>

      <Modal show={exibirFiltros} onHide={() => setExibirFiltros(false)} dialogClassName="modalFiltros">
        <Modal.Header closeButton>Filtros</Modal.Header>

        <Modal.Body>{renderFiltros ? renderFiltros() : false}</Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
