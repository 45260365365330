import IoContext from "contextos/IoContext";
import React from "react";
import { Table, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

export default function ModalAloFoneFaturas({ ContratoVoip, show, onHide, closeButton = true }) {
  const { socket } = React.useContext(IoContext);
  const [faturas, setFaturas] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setFaturas([]);
    if (!ContratoVoip?.id) return;

    setLoading(true);
    socket.emit(
      "AloFone.ListarFaturas",
      {
        ContratoVoipId: ContratoVoip?.id,
      },
      (error, faturas) => {
        setLoading(false);
        if (error) {
          console.log("AloFone.Detalhes:", error);
          return toast.error("" + error);
        }
        setFaturas(faturas);
      }
    );
  }, [ContratoVoip?.id, socket]);

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton={closeButton}>
        <Modal.Title>Alo Fone - Faturas</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {loading && "Aguarde, carregando... "}

        <Table>
          <thead>
            <tr>
              <th>Fatura</th>
              <th>Tipo</th>
              <th>Criado Em</th>
              <th>Pago Em</th>
              <th>Situação</th>
              <th>Url</th>
            </tr>
          </thead>
          <tbody>
            {faturas.map((f) => {
              return (
                <tr key={f.paymentid}>
                  <td>{f.paymentid}</td>
                  <td>{f.tipo}</td>
                  <td>{new Date(f.created).toLocaleString()}</td>
                  <td>{f.paid ? new Date(f.paid).toLocaleString() : "----"}</td>
                  <td>{ALO_FONE_STATUS_FATURA[f.paymentstatus] || f.paymentstatus}</td>
                  <td>
                    <a
                      href={`https://fatura.operadora.app.br/?payid=${f.paymentasaasid}`}
                      target={`fatura_alo_fone_${f.paymentid}`}
                    >
                      {f.paymentasaasid}
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
}

const ALO_FONE_STATUS_FATURA = {
  0: "Aberto",
  1: "Pago",
};
