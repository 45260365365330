import React from 'react';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { toast } from 'react-toastify';
import RegistrosDeImportacao from './RegistrosDeImportacao';
import SelectContaBancaria from '../../cadastros/financeiro/contas_bancarias/SelectContaBancaria';
import IoContext from '../../../contextos/IoContext';

let idxItem = 0;
let itens = [];
let countOk = 0;
let countErr = 0;

export default function ImportarContaReceberJson({ arquivo, setArquivo, dados }) {
    const { socket } = React.useContext(IoContext);
    const [contaBancariaId, setContaBancariaId] = React.useState('');
    const [registros, setRegistros] = React.useState({});
    const [progresso, setProgresso] = React.useState(0);
    const [progressoTxt, setProgressoTxt] = React.useState('');
    const [importando, setImportando] = React.useState(false);

    const importarProximoItem = function () {
        if (idxItem >= itens.length) {
            toast.success(`${countOk}  Registros Importados com Sucesso !`);

            if (countErr) {
                toast.error(`${countErr}  Registros Importados não Importados !`);
            }
            setArquivo(null);
            return;
        }
        const item = itens[idxItem++];

        setProgresso(parseInt(idxItem * 100 / itens.length));
        setProgressoTxt(`Processando Item ${idxItem} de ${itens.length}`);
        socket.emit("ContaReceber.importar", {
            variables: {
                item: {
                    ContaBancariaId: +contaBancariaId,
                    cliente_nome: item.cliente_nome,
                    cliente_doc1: item.cliente_doc1,
                    nrdoc: +item.nrdoc,
                    valor: +item.valor,
                    valor_pago: +item.valor_pago,
                    data_vencimento: item.data_vencimento,
                    data_pagamento: item.data_pagamento,
                    situacao: +item.situacao,
                    GatewayPagamentoIdIntegracao: item.GatewayPagamentoIdIntegracao,
                    gateway_pagamento: item.gateway_pagamento,
                    gerencianet_carneid: +item.gerencianet_carneid,
                    gerencianet_chargeid: +item.gerencianet_chargeid,
                    boleto_url: item.boleto_url
                }
            }
        }, (error, resp) => {
            if (error) {
                toast.error(error);
            } else {
                importarProximoItem();
            }
        });
    }

    return <React.Fragment>
        <Card>
            <Card.Body>
                <Form.Row>
                    <Col md={3}>
                        <Form.Group>
                            <Form.Label>Arquivo</Form.Label>
                            <Form.Control value={arquivo.name} readOnly />
                        </Form.Group>
                    </Col>

                    <Col md={3}>
                        <Form.Group>
                            <Form.Label>Tipo</Form.Label>
                            <Form.Control value={arquivo.type} readOnly />
                        </Form.Group>
                    </Col>

                    <Col md={3}>
                        <Form.Group>
                            <Form.Label>Conta Bancária</Form.Label>
                            <SelectContaBancaria disabled={importando} value={contaBancariaId}
                                onChange={(e, cb) => {
                                    setContaBancariaId(e.target.value ? e.target.value : null);
                                    processarRegistros({
                                        contaBancaria: cb,
                                        setRegistros,
                                        dados
                                    });
                                }} />
                        </Form.Group>
                    </Col>

                    <Col md={2}>
                        <Form.Group>
                            <Form.Label>Ações</Form.Label>
                            <div>
                                <Button variant='warning' disabled={importando} className='me-2'
                                    onClick={_ => { setArquivo(null); }}
                                >Cancelar</Button>

                                <Button type="button" disabled={!contaBancariaId || importando} className='me-2'
                                    onClick={e => {
                                        idxItem = 0;
                                        countOk = 0;
                                        countErr = 0;
                                        setImportando(true);
                                        importarProximoItem();
                                    }}>Importar</Button>
                            </div>
                        </Form.Group>
                    </Col>
                </Form.Row>
            </Card.Body>
        </Card>

        {importando ?
            <Card className={`mt-2`}>
                <Card.Body>
                    <Form.Group>
                        <Form.Label>Progresso da Importação</Form.Label>
                        <ProgressBar variant={`${progresso < 100 ? 'primary' : 'success'}`} animated={progresso < 100}
                            now={progresso} label={`${progressoTxt}`} />
                    </Form.Group>
                </Card.Body>
            </Card> :
            <Card className='mt-2 mb-2'>
                <RegistrosDeImportacao registros={registros} />
            </Card>}
    </React.Fragment>
}

function processarRegistros({ contaBancaria, dados, setRegistros }) {
    const registros = {};
    itens = [];

    (dados || []).forEach((item, idx) => {
        item = processarRegistro({ contaBancaria, item });
        if (item) {
            const key = item.key;
            if (!registros[key]) {
                registros[key] = {
                    mes: item.mes,
                    ano: item.ano,
                    total: 0,
                    totalAReceber: 0,
                    totalPago: 0,
                    quantidadeContas: 0
                };
            }

            itens.push(item);
            registros[key].total += item.valor;
            registros[key].quantidadeContas++;

            if (item.situacao === 0) {
                registros[key].totalAReceber += item.valor;
            } else {
                registros[key].totalPago += item.valor_pago;
            }

        }
    });

    setRegistros(registros);
}

function processarRegistro({ contaBancaria, item }) {
    const cobranca = contaBancaria.CarteiraCobranca || {};
    switch (cobranca.banco) {
        case 'GERENCIANET': return processarRegistrosGerencianet({ item });
        default:
            return null;
    }
}

function processarRegistrosGerencianet({ item }) {
    const customer = item.customer || {};
    const payment = item.payment || {};

    const mes = (new Date(item.expire_at)).getMonth() + 1;
    const ano = (new Date(item.expire_at)).getFullYear();

    return {
        key: `${ano}${mes}`,
        mes: +mes,
        ano: +ano,
        cliente_nome: customer.name,
        cliente_doc1: customer.document,
        nrdoc: item.charge_id,
        valor: item.total / 100,
        valor_pago: item.paid_value ? item.paid_value / 100 : 0,
        data_vencimento: item.expire_at,
        data_pagamento: (payment.carnet || {}).paid_at || "",
        situacao: item.status === 'paid' ? 1 : 0,
        gateway_pagamento: 1, // GERENCIANET
        gerencianet_chargeid: item.charge_id,
        gerencianet_carneid: item.carnet_id,
        boleto_url: item.charge_url
    };
}