import React from "react";
import { Button } from "react-bootstrap";
import LoadingSpinner from "./LoadingSpinner";

export default function BotaoProcessando({ loading }) {
  if (!loading) return false;

  return (
    <Button title="Processando" className="me-1" variant="primary" disabled>
      <LoadingSpinner loading={loading} />
    </Button>
  );
}
