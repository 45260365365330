import React from "react";
import axios from "axios";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv, faDatabase, faProjectDiagram } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

export default function FonteDados({ setTipoFonteDados, setTemplate }) {
  const [templates, setTemplates] = React.useState([]);

  React.useEffect(() => {
    axios
      .get("/importacoes/templates")
      .then((resp) => {
        setTemplates(resp.data);
      })
      .catch((error) => {
        toast.error("" + error);
      });
  }, []);

  return (
    <Card>
      <Card.Header className="bg-primary text-light">
        <Card.Title>Selecione o Tipo de Fonte de Dados</Card.Title>
      </Card.Header>

      <Card.Body>
        <Row className="mb-2">
          <Form.Group>
            <Form.Label>Template</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => {
                setTemplate(templates[e.target.value]);
              }}
            >
              <option></option>
              {(templates || []).map((t, idx) => {
                return (
                  <option value={idx} key={idx}>
                    {t.name}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </Row>

        <Row className="justify-content-md-center">
          <Col>
            <Card
              onClick={() => {
                setTipoFonteDados("csv");
              }}
              className="text-center p-2"
            >
              <FontAwesomeIcon icon={faFileCsv} size="6x" className="align-self-center" />
              <h1>CSV</h1>
            </Card>
          </Col>

          <Col>
            <Card
              onClick={() => {
                setTipoFonteDados("database");
              }}
              className="text-center p-2"
            >
              <FontAwesomeIcon icon={faDatabase} size="6x" className="align-self-center" />
              <h1>Banco de Dados</h1>
            </Card>
          </Col>

          <Col>
            <Card
              onClick={() => {
                setTipoFonteDados("api");
              }}
              className="text-center p-2"
            >
              <FontAwesomeIcon icon={faProjectDiagram} size="6x" className="align-self-center" />
              <h1>API</h1>
            </Card>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
