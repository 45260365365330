import React from 'react';
import FormularioListagem from '../../../../componentes/FormularioListagem';
import IoContext from '../../../../contextos/IoContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function Produtos({
    pagina, setPagina, texto, setTexto
}) {
    const navigate = useNavigate();
    const { socket } = React.useContext(IoContext);
    const [caixas, setProdutos] = React.useState([]);
    const [total, setTotal] = React.useState(0);

    React.useEffect(() => {
        let where = {};
        if (texto) where.nome_like = `%${texto}%`;

        setProdutos([]);
        socket.emit("Produto.findAndCountAll", {
            where, limit: 10, offset: 10 * (pagina - 1)
        }, (error, resp) => {
            if (error) {
                toast.error('' + error);
            } else {
                setProdutos(resp.rows);
                setTotal(resp.count);
            }
        });
    }, [socket, pagina, texto]);

    return <FormularioListagem
        titulo={`Produtos`}
        itens={caixas} qtdItens={total} pagina={pagina}
        setTexto={setTexto} setPagina={setPagina}
        titulos={["Id", "Produto"]}
        onClick={(e) => {
            navigate(`${e.id}`);
        }}

        renderItem={(titulo, item) => {
            switch (titulo) {
                case 'Id': return item.id;
                case 'Produto': return item.nome;
                default: return titulo;
            }
        }}
    />
}