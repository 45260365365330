import React from 'react';
import Form from 'react-bootstrap/Form';

export default function CampoCpf(props) {
    const { onChange } = props;
    return <Form.Control
            {...props}
            maxLength={14}
            onChange={ e => {
                const soNumeros = e.target.value.replace(/[^\d]/g, '')
                if(!soNumeros) onChange(soNumeros);

                let cpf = '';
                cpf += soNumeros.slice(0,3)
        
                if(soNumeros.length > 3)
                    cpf += '.' + soNumeros.slice(3,6)
        
                if(soNumeros.length > 6)
                    cpf += '.' + soNumeros.slice(6,9)
        
                if(soNumeros.length > 9)
                    cpf += '-' + soNumeros.slice(9,11)        
        
                onChange(cpf);
            }}
        />
}