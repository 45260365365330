import React from 'react';
import AuthContext from '../../contextos/AuthContext';
import TituloContext from '../../contextos/TituloContext';
import { useParams } from 'react-router-dom';

export default function Legacy(props) {
    const { usuario, empresa } = React.useContext(AuthContext);
    let params = useParams();
    let form = params.form || 'noForm';
    let url = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port;
    if(process.env.NODE_ENV === 'development' && process.env.REACT_APP_LHISP_BACKEND) {
        url = process.env.REACT_APP_LHISP_BACKEND;
    }
    const { setTitulo } = React.useContext(TituloContext);
    const [carregando, setCarregando] = React.useState(true);

    React.useEffect(() => {
        setTitulo("LHISP");
    }, [setTitulo])

    let parametros = "";
    if (form.indexOf('||') !== -1) {
        [form, parametros] = form.split('||');
    }

    while (form.indexOf("|") !== -1) {
        form = form.replace('|', '/');
    }

    if (!empresa) {
        console.log("## LEGACY SEM EMPRESA");
        return <div>[Legado] Empresa não encontrada.</div>;
    }
    if (!usuario) {
        console.log("## LEGACY SEM USUARIO");
        return <div>[Legado] Usuário não encontrado. Não Autenticado</div>;
    }

    return (
        <React.Fragment>
            {carregando ? <div className="p4">Aguarde Carregando...</div> : false}
            <iframe className='w-100 h-100 p-0 m-0 border-0'
                scrolling="yes"
                style={{
                    // border: 'solid yellow 5px'
                }}
                onLoad={() => { setCarregando(false) }}
                src={`${url}/principal.php?SYSNAME=${empresa.sysname}&DBNAME=${empresa.dbname}&EID=${empresa.id}&token=${usuario.token}#${form}|${parametros}`} title='Frame Legacy'>
                Seu navegador não suporta Frames.
            </iframe>
        </React.Fragment>
    );
}