import React from "react";
import Paginacao from "componentes/Paginacao";
import { Card, Col, Row } from "react-bootstrap";

export default function TableResultado({ campos, dados }) {
  const [pagina, setPagina] = React.useState(1);
  const total = (dados || []).length;
  const item = dados[pagina - 1];

  return (
    <Card className="mt-2 me-3">
      <Card.Header className="text-info">Exibindo Item: {pagina} </Card.Header>

      <Card.Body>
        <Row>
          <Col sm={12}>
            <pre className="b-all">{JSON.stringify(item || {}, null, "\t")}</pre>
          </Col>
        </Row>
      </Card.Body>

      <Card.Footer>
        <Paginacao pagina={pagina} setPagina={setPagina} total={total} quantidaePorPagina={1} />
      </Card.Footer>
    </Card>
  );
}
