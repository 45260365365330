import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Container from "react-bootstrap/Container";
import Listagem from "componentes/Listagem";
import IoContext from "contextos/IoContext";
import TituloContext from "contextos/TituloContext";
import Paginacao from "componentes/Paginacao";
import { formatarDinheiro } from "misc/formatarDinheiro";
import { toast } from "react-toastify";
import SelectPlanos from "componentes/SelectPlanos";
import ModalReajustarServicosContratados from "./ModalReajustarServicosContratados";
import SelectFiliais from "paginas/cadastros/administrativo/filiais/SelectFiliais";
import { DateTime } from "luxon";

export default function ReajusteServicosContratados() {
  const { socket } = React.useContext(IoContext);
  const { setTitulo } = React.useContext(TituloContext);
  const [servicos, setServicos] = React.useState([]);
  const [reajuste, setReajuste] = React.useState(0);
  const [pagina, setPagina] = React.useState(1);
  const [total, setTotal] = React.useState(0);
  const [PlanoId, setPlanoId] = React.useState(0);
  const [FilialId, setFilialId] = React.useState("");
  const [tipoPessoa, setTipoPessoa] = React.useState("");
  const [count, setCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [tipoReajuste, setTipoReajuste] = React.useState("percentual");
  const [carenciaUltimoReajuste, setCarenciaUltimoReajuste] = React.useState(12);
  const [exibirModal, setExibirModal] = React.useState(false);

  React.useEffect(() => {
    setTitulo("Reajuste de Serviços Contratados");
  }, [setTitulo]);

  React.useEffect(() => {
    setServicos([]);

    setLoading(true);
    socket.emit(
      `Financeiro.getServicosContratadosParaReajuste`,
      {
        PlanoId,
        FilialId,
        tipoPessoa,
        carenciaUltimoReajuste,
      },
      (error, resp) => {
        setLoading(false);
        if (error) return toast.error(error);

        setServicos(resp);
        setTotal(resp.length);
      }
    );
  }, [socket, PlanoId, FilialId, tipoPessoa, carenciaUltimoReajuste, count]);

  return (
    <Container fluid className="pt-3 pb-3">
      <Card>
        <Card.Header className="bg-primary text-light">
          <Card.Title>Reajuste de Serviços Contratados</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col sm={2}>
                <Form.Group>
                  <Form.Label>Filial</Form.Label>
                  <SelectFiliais disabled={loading} value={FilialId} onChange={(e) => setFilialId(e.target.value)} />
                </Form.Group>
              </Col>

              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Plano</Form.Label>
                  <SelectPlanos disabled={loading} value={PlanoId} onChange={(e) => setPlanoId(+e.target.value)} />
                </Form.Group>
              </Col>

              <Col sm={2}>
                <Form.Group>
                  <Form.Label>Tipo de Pessoa</Form.Label>
                  <Form.Control
                    as="select"
                    disabled={loading}
                    value={tipoPessoa}
                    onChange={(e) => setTipoPessoa(e.target.value)}
                  >
                    <option value="">Todas</option>
                    <option value="FISICA">Pessoa Física</option>
                    <option value="JURIDICA">Pessoa Jurídica</option>
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col sm={1}>
                <Form.Group>
                  <Form.Label>Carência:</Form.Label>
                  <Form.Control
                    type="number"
                    disabled={loading}
                    value={carenciaUltimoReajuste}
                    min={0}
                    step={1}
                    onChange={(e) => {
                      const n = Number.parseFloat(e.target.value);
                      setCarenciaUltimoReajuste(isNaN(n) ? 0 : n);
                    }}
                  />
                </Form.Group>
              </Col>

              <Col sm={1}>
                <Form.Group>
                  <Form.Label>Reajuste</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      disabled={loading}
                      value={reajuste}
                      min={0}
                      step={1}
                      onChange={(e) => {
                        const n = Number.parseFloat(e.target.value);
                        setReajuste(isNaN(n) ? 0 : n);
                      }}
                    />
                    <Button
                      disabled={loading}
                      onClick={() => {
                        setTipoReajuste(tipoReajuste === "percentual" ? "valorFixo" : "percentual");
                      }}
                    >
                      {tipoReajuste === "percentual" ? "%" : "R$"}
                    </Button>
                  </InputGroup>
                </Form.Group>
              </Col>

              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Ações</Form.Label>
                  <div>
                    <Button disabled={loading} className="btn-secondary me-2" onClick={() => setCount(count + 1)}>
                      Visualizar
                    </Button>
                    <Button
                      className="btn-primary me-2"
                      onClick={() => setExibirModal(true)}
                      disabled={loading || !PlanoId || reajuste <= 0 || isNaN(reajuste)}
                    >
                      Reajustar
                    </Button>
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Card.Body>

        <Listagem
          titulos={[
            "Id",
            "Filial",
            "Contrato",
            "Cliente",
            "Ativação",
            "Ultimo Reajuste",
            "Valor",
            "Valor do Plano",
            "Valor Reajustado",
          ]}
          itens={(servicos || []).slice((pagina - 1) * 10, (pagina - 1) * 10 + 10)}
          tamanhos={[{}, {}, {}, { sm: 6 }]}
          renderItem={(titulo, item) => {
            switch (titulo) {
              case "Id":
                return item.id;
              case "Filial":
                return item.Contrato?.Filial?.nome;
              case "Contrato":
                return item.Contrato?.numero_contrato;
              case "Cliente":
                return item.Contrato?.Pessoa?.nome;
              case "Ativação":
                return DateTime.fromISO(item.dataAtivacao).toLocaleString(DateTime.DATE_SHORT);
              case "Ultimo Reajuste":
                return item.dataUltimoReajuste
                  ? DateTime.fromISO(item.dataUltimoReajuste).toLocaleString(DateTime.DATE_SHORT)
                  : "----";
              case "Valor":
                return formatarDinheiro(item.valor);
              case "Valor do Plano":
                return formatarDinheiro(item.Plano?.valor);
              case "Valor Reajustado":
                if (!reajuste || isNaN(reajuste)) return formatarDinheiro(item.valor);

                return formatarDinheiro(
                  tipoReajuste === "percentual"
                    ? Number.parseFloat(item.valor) + (Number.parseFloat(item.valor) * reajuste) / 100
                    : Number.parseFloat(item.valor) + reajuste
                );
              default:
                return titulo;
            }
          }}
        />

        <Card.Footer>
          <Paginacao pagina={pagina} setPagina={setPagina} total={total ? total : 0} />
        </Card.Footer>
      </Card>

      <ModalReajustarServicosContratados
        show={exibirModal}
        onHide={() => setExibirModal(false)}
        PlanoId={PlanoId}
        FilialId={FilialId}
        tipoPessoa={tipoPessoa}
        reajuste={reajuste}
        tipoReajuste={tipoReajuste}
        carenciaUltimoReajuste={carenciaUltimoReajuste}
        callback={() => {
          toast.success("Serviço Reajustados com Sucesso !");
          setPlanoId(0);
          setReajuste(0);
        }}
      />
    </Container>
  );
}
