import IoContext from "contextos/IoContext";
import React from "react";
import {
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Button,
} from "react-bootstrap";
import SelectAlmoxarifados from "paginas/estoque/SelectAlmoxarifados";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv, faPrint, faSync } from "@fortawesome/free-solid-svg-icons";
import SelectCategorias from "paginas/estoque/categorias/SelectCategorias";
import CardListaEstoqueAlmoxarifado from "./CardListaEstoqueAlmoxarifado";
import { downloadCsv } from "misc/downloadCsv";

export default function RelEstoqueAlmoxarifado() {
  const { socket } = React.useContext(IoContext);
  const [itens, setItens] = React.useState({});
  const [count, setCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [AlmoxarifadoId, setAlmoxarifadoId] = React.useState("");
  const [produto, setProduto] = React.useState("");
  const [exibirCategorias, setExibirCategorias] = React.useState(true);
  const [categoriaId, setCategoriaId] = React.useState("");
  const [exibirEstoqueZero, setExibirEstoqueZero] = React.useState(false);

  React.useEffect(() => {
    let where = {
      AlmoxarifadoId,
    };

    let whereProduto = {
      nome_like: `%${produto}%`,
    };
    if (!exibirEstoqueZero) {
      where.quantidade_gt = 0;
    }
    if (categoriaId) {
      if (exibirCategorias) {
        whereProduto.CategoriaId = categoriaId;
      } else {
        whereProduto.CategoriaId_ne = categoriaId;
      }
    }
    setItens({});
    setLoading(true);
    socket.emit(
      "Estoque.findAll",
      {
        attributes: [
          "id",
          "AlmoxarifadoId",
          "LocalAlmoxarifadoId",
          "ProdutoId",
          "quantidade",
        ],
        where,
        withProduto: {
          attributes: [
            "id",
            "nome",
            "unidade",
            "preco",
            "temControlePatrimonial",
          ],
          where: whereProduto,
          withCategoria: {
            attributes: ["nome"],
          },
        },
        withLocalAlmoxarifado: {
          attributes: ["descricao"],
        },
        order: [["col_Produto.nome", "ASC"]],
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          toast.error("" + error);
          return;
        }

        let ret = {};
        resp.forEach((estoque) => {
          if (ret[estoque.ProdutoId]) {
            ret[estoque.ProdutoId].quantidade += estoque.quantidade;
            return;
          }

          ret[estoque.ProdutoId] = estoque;
        });

        setItens(ret);
      }
    );
  }, [
    socket,
    produto,
    AlmoxarifadoId,
    categoriaId,
    exibirCategorias,
    count,
    exibirEstoqueZero,
  ]);

  return (
    <Container fluid>
      <Card className="shadow">
        <Card.Header className="bg-primary text-light">
          <h3 className="float-start">Relatório de Material Almoxarifado</h3>
        </Card.Header>
        <Card.Body className="d-print-none">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setCount(count + 1);
              return false;
            }}
          >
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Almoxarifado</Form.Label>
                  <SelectAlmoxarifados
                    value={AlmoxarifadoId}
                    required
                    disabled={loading}
                    onChange={(e) => {
                      setAlmoxarifadoId(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Categoria</Form.Label>
                  <InputGroup>
                    <InputGroup.Checkbox
                      type="checkbox"
                      disabled={loading}
                      title={
                        exibirCategorias
                          ? "Exibir Somente Produtos da Categoria Selecionada!"
                          : "Exibir Produtos de todas as Categorias *Exceto* a Selecionada !"
                      }
                      checked={exibirCategorias}
                      onChange={(e) => setExibirCategorias(!exibirCategorias)}
                    ></InputGroup.Checkbox>
                    <SelectCategorias
                      disabled={loading}
                      value={categoriaId}
                      onChange={(e) => setCategoriaId(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group>
                  <Form.Label>Produto</Form.Label>
                  <Form.Control
                    value={produto}
                    disabled={loading}
                    onChange={(e) => setProduto(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Opções</Form.Label>
                  <div>
                    <Form.Check
                      label="Exibir Produtos com Estoque Zero ?"
                      disabled={loading}
                      onClick={() => setExibirEstoqueZero(!exibirEstoqueZero)}
                    />
                  </div>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group>
                  <Form.Label>Ações</Form.Label>
                  <div>
                    <Button
                      type="submit"
                      className="me-2"
                      variant="info"
                      title="Atualizar Listagem"
                      disabled={loading || !AlmoxarifadoId}
                    >
                      <FontAwesomeIcon icon={faSync} />
                    </Button>
                    <Button
                      className="me-2"
                      variant="secondary"
                      disabled={loading}
                      title="Imprimir"
                      onClick={() => window.print()}
                    >
                      <FontAwesomeIcon icon={faPrint} />
                    </Button>

                    <Button
                      title="Baixar Planilha"
                      variant="secondary"
                      className="ms-1"
                      onClick={() => {
                        downloadCsv(
                          [
                            ["Categoria", "Produto", "Quantidade", "Preço"],
                            ...Object.values(itens).map((item) => {
                              return [
                                item.Produto?.Categoria?.nome,
                                item.Produto?.nome,
                                `${item.quantidade} ${item.Produto?.unidade}`,
                                item.Produto.preco,
                              ];
                            }),
                          ],
                          "RelatorioMaterialAlmoxarifado"
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faFileCsv} />
                    </Button>
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Card.Body>
        <CardListaEstoqueAlmoxarifado itens={itens} />
      </Card>
    </Container>
  );
}
