import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import SelectProdutosSpcPF from "componentes/SelectProdutosSpcPF";
import SelectProdutosSpcPJ from "componentes/SelectProdutosSpcPJ";
import BotaoApagar from "componentes/BotaoApagar";
export default function OperadorSpcBrasil({ operador, setOperador, removerOperador, disabled }) {
  return (
    <Row>
      <Col sm={1}>
        <Form.Label>Operador</Form.Label>
        <Form.Control
          value={operador?.operador || ""}
          onChange={(e) => setOperador("operador", e.target.value)}
          maxLength={100}
        />
      </Col>
      <Col sm={1}>
        <Form.Group>
          <Form.Label>Senha</Form.Label>
          <Form.Control
            value={operador?.senha || ""}
            onChange={(e) => setOperador("senha", e.target.value)}
            type="password"
            maxLength={100}
          />
        </Form.Group>
      </Col>
      <Col sm={2}>
        <Form.Group>
          <Form.Label>Limite Consultas Mensal</Form.Label>
          <Form.Control
            value={operador?.limiteConsultas || ""}
            onChange={(e) => setOperador("limiteConsultas", e.target.value)}
            type="number"
            min={0}
            step={1}
          />
        </Form.Group>
      </Col>
      <Col sm={1}>
        <Form.Group>
          <Form.Label>Ambiente</Form.Label>
          <Form.Control
            as="select"
            value={operador?.ambiente || ""}
            onChange={(e) => setOperador("ambiente", e.target.value)}
          >
            <option value={SpcBrasilAmbientes.PRODUCAO}>Produção</option>
            <option value={SpcBrasilAmbientes.HOMOLOGACAO}>Homologação</option>
            <option value={SpcBrasilAmbientes.TREINAMENTO}>Treinamento</option>
          </Form.Control>
        </Form.Group>
      </Col>
      <Col sm={2}>
        <Form.Group>
          <Form.Label>Produto PF</Form.Label>
          <SelectProdutosSpcPF
            value={operador?.codigoProdutoPadraoPF || ""}
            onChange={(e) => setOperador("codigoProdutoPadraoPF", e.target.value)}
          />
        </Form.Group>
      </Col>
      <Col sm={2}>
        <Form.Group>
          <Form.Label>Produto PJ</Form.Label>
          <SelectProdutosSpcPJ
            value={operador?.codigoProdutoPadraoPJ}
            onChange={(e) => setOperador("codigoProdutoPadraoPJ", e.target.value)}
          />
        </Form.Group>
      </Col>
      <Col sm={2}>
        <Form.Group>
          <Form.Label>Filtro</Form.Label>
          <Form.Control
            as="select"
            required
            value={operador?.filtro || ""}
            onChange={(e) => setOperador("filtro", e.target.value)}
          >
            <option value=""></option>
            <option value="DF">Operador Padrão</option>
            <option value="PF">Pessoa Física</option>
            <option value="PJ">Pessoa Jurídica</option>
          </Form.Control>
        </Form.Group>
      </Col>
      <Col sm={1}>
        <Form.Group>
          <Form.Label>Ações</Form.Label>
          <div>
            <BotaoApagar apagar={removerOperador} disabled={disabled} />
          </div>
        </Form.Group>
      </Col>
    </Row>
  );
}

export const SpcBrasilAmbientes = {
  PRODUCAO: "producao",
  HOMOLOGACAO: "homologacao",
  TREINAMENTO: "treinamento",
};
