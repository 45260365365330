import React from 'react';
import { Routes, Route } from 'react-router-dom';
import FilaAtendimento from './FilaAtendimento';
import AgendaTecnica from './AgendaTecnica';

export default function AgendaTecnicaIndex(){
    return <Routes>
        <Route path={`/fila_atendimento`} element={<FilaAtendimento />} />
        <Route path={`*`} element={<AgendaTecnica />} />
    </Routes>
}