import React from "react";
import { Routes, Route } from "react-router-dom";
import RelatorioTopNAtendimentos from "./agendatecnica/atendimentos/RelatorioTopNAtendimentos";
import RelatorioOrdensServico from "./agendatecnica/ordenservico/RelatorioOrdensServico";
import DuracaoOrdensServico from "./agendatecnica/ordenservico/DuracaoRelatorioOrdensServico";
import RelatorioTopNOrdensServico from "./agendatecnica/ordenservico/RelatorioTopNOrdensServico";
import RelatorioFiscalRegimeCaixa from "./fiscal_regime_caixa/RelatorioFiscalRegimeCaixa";
import MovimentoFinanceiroUsuario from "./financeiro/movimento_usuario/MovimentoFinanceiroUsuario";
import RelAquisicoes from "./clientes/aquisicoes/RelAquisicoes";
import RelProjecoesRecebimentos from "./projecoes_recebimentos/RelProjecoesRecebimentos";
import VendasPorVendedor from "./financeiro/VendasPorVendedor";
import RelContasPagar from "./financeiro/contaspagar/RelContasPagar";
import RelNotificacoesContratos from "./notificacoes_contrato/RelNotificacoesContratos";
import RelClientesSemEmail from "./clientes_sem_email/RelClientesSemEmail";
import RelRetencaoClientes from "./clientes/retencao/RelRetencaoClientes";
import RelCobrancaTerceirizada from "./clientes/cobranca_tercerizada/RelCobrancaTerceirizada";
import RelAnatelTrimestral from "./anatel/trimestral/RelAnatelTrimestral";
import RelUsoDeBanda from "./uso_de_banda/RelUsoDeBanda";
import RelEnderecosIpsAtivos from "./enderecos_ip_ativos/RelEnderecosIpsAtivos";
import RelVoip from "./contrato_voip/RelVoip";
import RelQuitacaoDebitos from "./recibo_quitacao_debitos/RelQuitacaoDebitos";
import RelEstoqueAlmoxarifado from "./almoxarifado/RelEstoqueAlmoxarifado";
import RelEstoquePorFilial from "./estoque_por_filial/RelEstoquePorFilial";
import RelatorioConsultasSpcBrasil from "./spcbrasil/RelatorioConsultasSpcBrasil";
import RelatorioFornecedores from "./fornecedores/RelatorioFornecedores";
import RelOttSva from "./sva/estoque_por_filial/RelOttSva";
import RelatorioAtendimentosPorCanal from "./agendatecnica/atendimentos/AtendimentosPorCanal";
import RelContasReceber from "./RelContasReceber";
import RelComissaoTecnicoPorOs from "./agendatecnica/ordenservico/RelComissaoTecnicoPorOs";

export default function RelatoriosIndex() {
  return (
    <Routes>
      <Route path={`/agendatecnica/ordens_de_servico`} element={<RelatorioOrdensServico />} />
      <Route path={`/agendatecnica/duracao_ordens_de_servico`} element={<DuracaoOrdensServico />} />
      <Route path={`/agendatecnica/top_n_ordens_de_servico`} element={<RelatorioTopNOrdensServico />} />
      <Route path={`/agendatecnica/top_n_atendimentos`} element={<RelatorioTopNAtendimentos />} />
      <Route path={`/agendatecnica/comissao_tecnico_por_os`} element={<RelComissaoTecnicoPorOs />} />
      <Route path={`/agendatecnica/atendimentos_por_canal`} element={<RelatorioAtendimentosPorCanal />} />
      <Route path={`/vendas_por_vendedor`} element={<VendasPorVendedor />} />
      <Route path={`/fical_regime_caixa`} element={<RelatorioFiscalRegimeCaixa />} />
      <Route path={`/movimento_financeiro_usuario`} element={<MovimentoFinanceiroUsuario />} />
      <Route path={`/clientes/aquisicoes`} element={<RelAquisicoes />} />
      <Route path={`/retencao`} element={<RelRetencaoClientes />} />
      <Route path={`/projecoes_recebimentos`} element={<RelProjecoesRecebimentos />} />
      <Route path={`/financeiro/contas_pagar`} element={<RelContasPagar />} />
      <Route path={`/notificacoes_contrato`} element={<RelNotificacoesContratos />} />
      <Route path={`/clientes_sem_email`} element={<RelClientesSemEmail />} />
      <Route path={`/clientes/cobranca_terceirizada`} element={<RelCobrancaTerceirizada />} />
      <Route path={`/anatel_trimestral`} element={<RelAnatelTrimestral />} />
      <Route path={`/rel_uso_de_banda`} element={<RelUsoDeBanda />} />
      <Route path={`/enderecos_ips_ativos`} element={<RelEnderecosIpsAtivos />} />
      <Route path={`/voip`} element={<RelVoip />} />
      <Route path={`/recibo_quitacao_debitos/:ContratoId`} element={<RelQuitacaoDebitos />} />
      <Route path={`/almoxarifado/estoque`} element={<RelEstoqueAlmoxarifado />} />
      <Route path={`/estoque_por_filial`} element={<RelEstoquePorFilial />} />
      <Route path={`/consultas_spcbrasil`} element={<RelatorioConsultasSpcBrasil />} />
      <Route path={`/contas_receber`} element={<RelContasReceber />} />

      <Route path={`/fornecedores`} element={<RelatorioFornecedores />} />
      <Route path={`/ott`} element={<RelOttSva />} />
    </Routes>
  );
}
