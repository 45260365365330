import React from 'react';
import Form from 'react-bootstrap/Form';

export default function CampoEnderecoIPv4(props) {
    const { onChange } = props;
    return <Form.Control
            {...props}
            maxLength={15}
            onChange={ e => {
                const numeros = e.target.value.replace(/[^0-9.]/g, '')
                if(!numeros) onChange(numeros);

                onChange(numeros);
            }}
        />
}