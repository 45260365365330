import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from "react-router-dom";
import IoContext from '../../../contextos/IoContext';

import TituloContext from '../../../contextos/TituloContext';
import SelectAlmoxarifados from '../SelectAlmoxarifados';
import ModalAutorizarSolicitacaoMaterial from './ModalAutorizarSolicitacaoMaterial';
import ModalSelecionarItem from './ModalSelecionarItem';
import BotaoVoltar from '../../../componentes/BotaoVoltar';
import Listagem from '../../../componentes/Listagem';
import ModalConfirmar from 'componentes/ModalConfirmar';

import {
    SituacoesSolicitacaoMaterial,
    SolicitacaoMaterial_EM_ABERTO
} from '../../../datatypes/SolicitacoesDeMaterialTypes';

export default function SolicitacaoMaterial() {
    const params = useParams();
    const { socket } = React.useContext(IoContext);
    const [loading, setLoading] = React.useState(false);
    const id = isNaN(parseInt(params.id)) ? "" : +params.id;
    const navigate = useNavigate();
    const [exibirModalAutorizar, setExibirModalAutorizar] = React.useState(false);
    const [exibirModalSelecionarItem, setExibirModalSelecionarItem] = React.useState(false);
    const [alterado, setAlterado] = React.useState(false);
    const [solicitacao, setSolicitacao] = React.useState(null);
    const [itens, setItens] = React.useState([]);
    const [AlmoxarifadoId, setAlmoxarifadoId] = React.useState('');
    const { setTitulo } = React.useContext(TituloContext);
    const [exibirModalCancelar, setExibirModalCancelar] = React.useState(false);

    React.useEffect(() => {
        setTitulo(`Solicitação de Material [${id}]`);
    }, [setTitulo, id]);

    React.useEffect(() => {
        obterSolicitacao({ socket, id, setLoading, setSolicitacao, setAlmoxarifadoId, setItens })
    }, [socket, id, setLoading, setSolicitacao, setAlmoxarifadoId, setItens]);


    const salvar = () => {
        if (!AlmoxarifadoId) {
            return toast.info("Selecione um Almoxarifado !");
        }

        if (!itens || itens.length <= 0) {
            return toast.info("Adicione pelo menos um item na solicitação !");
        }

        const params = {
            id,
            AlmoxarifadoId,
            itens: []
        };

        itens.forEach(item => {
            params.itens.push({
                id: +item.id,
                ProdutoId: +item.ProdutoId,
                CategoriaId: +item.CategoriaId,
                quantidade: +item.quantidade
            });
        });

        setLoading(true);
        socket.emit("SolicitacaoMaterial.salvar", params, (error, solicitacao) => {
            setLoading(false);
            setAlterado(false);

            if (error) return toast.warning('' + error);

            toast.success("Solicitação Salva com sucesso !");

            if (solicitacao && solicitacao.id) {
                obterSolicitacao({ socket, id, setLoading, setSolicitacao, setAlmoxarifadoId, setItens })
            } else {
                navigate(-1);
            }
        })
    }

    return <Container fluid className="pt-3">
        <Card>
            <Card.Header className="bg-primary text-light">
                <Card.Title className="text-light d-block float-start">Solicitação de Material</Card.Title>
                <BotaoVoltar />
            </Card.Header>

            <Card.Body>
                <Row>
                    <Col sm={1}>
                        <Form.Group>
                            <Form.Label>Id</Form.Label>
                            <Form.Control value={solicitacao ? solicitacao.id : ''} readOnly />
                        </Form.Group>
                    </Col>

                    <Col sm={3}>
                        <Form.Group>
                            <Form.Label>Almoxarifado</Form.Label>
                            <SelectAlmoxarifados
                                value={AlmoxarifadoId}
                                required
                                disabled={solicitacao && solicitacao.id}
                                onChange={e => {
                                    setAlterado(true);
                                    setAlmoxarifadoId(e.target.value);
                                }}
                            />
                        </Form.Group>
                    </Col>

                    <Col sm={3}>
                        <Form.Group>
                            <Form.Label>Situação</Form.Label>
                            <Form.Control as="select"
                                value={solicitacao ? solicitacao.situacao : ''}
                                onChange={() => { }}
                                disabled>
                                {Object.keys(SituacoesSolicitacaoMaterial).map(idx => {
                                    return <option key={idx} value={idx}>{SituacoesSolicitacaoMaterial[idx]}</option>
                                })}
                            </Form.Control>
                        </Form.Group>
                    </Col>

                    <Col sm={3}>
                        <Form.Group>
                            <Form.Label>Ações</Form.Label>
                            <div>
                                {solicitacao && solicitacao.situacao === SolicitacaoMaterial_EM_ABERTO && !alterado ?
                                    <Button className='btn-success me-2'
                                        disabled={loading}
                                        onClick={e => {
                                            setExibirModalAutorizar(true);
                                        }}>Autorizar</Button>
                                    : false}

                                {solicitacao && solicitacao.situacao === SolicitacaoMaterial_EM_ABERTO && !alterado ?
                                    <Button className='btn-danger me-2'
                                        disabled={loading}
                                        onClick={e => {
                                            setExibirModalCancelar(true);
                                        }}>Cancelar</Button>
                                    : false}

                                {alterado && solicitacao && solicitacao.id ? <Button className='btn-warning me-2' onClick={e => {
                                    if (solicitacao) {
                                        setAlterado(false);
                                        setSolicitacao(solicitacao);
                                        setAlmoxarifadoId(solicitacao.AlmoxarifadoId);
                                        const i = [];
                                        (solicitacao.SolicitacaoMaterialItems || []).forEach(item => {
                                            i.push({ ...item });
                                        });
                                        setItens(i);
                                    }
                                }}>Cancelar</Button> : false}

                                {alterado && itens && itens.length > 0 ?
                                    <Button className='btn-success' onClick={() => salvar()}
                                    >Salvar</Button> : false}
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
            </Card.Body>
        </Card>

        <Card className="mt-3">
            <Card.Body>
                <Listagem
                    titulos={['Id', 'Tipo', 'Item', 'Quantidade']}
                    itens={itens || []}
                    renderItem={(titulo, item, idx) => {
                        switch (titulo) {
                            case 'Id': return item.id ? item.id : (item.ProdutoId ? `p${item.ProdutoId}` : `p${item.CategoriaId}`);
                            case 'Tipo': return item.ProdutoId ? 'PRODUTO' : 'CATEGORIA';
                            case 'Item': return item.ProdutoId ? (item.Produto ? item.Produto.nome : "PRODUTO") : (item.Categoria ? item.Categoria.nome : "CATEGORIA");
                            case 'Quantidade':
                                return <Form.Control
                                    type="number"
                                    min={0}
                                    value={item.quantidade}
                                    disabled={loading || (solicitacao && solicitacao.situacao !== SolicitacaoMaterial_EM_ABERTO)}
                                    onChange={e => {
                                        const itensNew = [...itens];
                                        itensNew[idx].quantidade = e.target.value;
                                        setItens(itensNew);
                                        setAlterado(true);
                                    }}
                                />
                            default:
                                return titulo
                        }
                    }}
                />
            </Card.Body>

            <Card.Footer>
                {(!solicitacao || (solicitacao && solicitacao.situacao === SolicitacaoMaterial_EM_ABERTO)) ?
                    <div className="text-right">
                        <Button className='btn-primary'
                            disabled={loading}
                            onClick={e => {
                                setExibirModalSelecionarItem(true);
                            }}>Adicionar Item</Button>
                    </div> : false}
            </Card.Footer>
        </Card>

        <ModalAutorizarSolicitacaoMaterial
            show={exibirModalAutorizar}
            onHide={setExibirModalAutorizar}
            solicitacao_material={solicitacao}
            pular={solicitacao ? solicitacao.AlmoxarifadoId : null}
            callback={() => {
                setExibirModalAutorizar(false);
                obterSolicitacao();
            }} />

        <ModalSelecionarItem
            show={exibirModalSelecionarItem}
            onHide={setExibirModalSelecionarItem}
            onItemSelecionado={item => {
                const i = itens;
                i.push(item);
                setAlterado(true);
                setItens(i);
            }} />

        <ModalConfirmar show={exibirModalCancelar} onHide={setExibirModalCancelar} disabled={loading}
            titulo="Cancelar Solicicação de Material ?"
            texto="Cuidado, essa operação é irreversível !"
            callback={() => {
                setLoading(true);
                setExibirModalCancelar(false);
                socket.emit("SolicitacaoMaterial.Cancelar", { id }, (error, solicitacao) => {
                    setLoading(false);
                    setAlterado(false);

                    if (error) return toast.warning('' + error);

                    toast.success("Solicitação Cancelada com sucesso !");
                    navigate(-1);
                })
            }}
        />
    </Container>
}


function obterSolicitacao({ socket, id, setLoading, setSolicitacao, setAlmoxarifadoId, setItens }) {
    setLoading(true);
    socket.emit("SolicitacaoMaterial.findOne", {
        where: { id },
        withAlmoxarifado: {},
        withItens: {
            withProduto: {
                attribute: ['nome']
            },
            withCategoria: {
                attribute: ['nome']
            }
        }
    }, (error, resp) => {
        setLoading(false);
        if (error) {
            toast.error(error);
        } else {
            if (!resp) return;

            setSolicitacao(resp || "");
            setAlmoxarifadoId(resp.AlmoxarifadoId || "");

            const i = [];
            (resp.SolicitacaoMaterialItems || []).forEach(item => {
                console.log("## ITEM:", item);
                i.push({ ...item });
            });
            setItens(i);
        }
    });
}