export const SituacoesSolicitacaoMaterial = {
    1 : 'EM ABERTO',
    2 : 'AUTORIZADA',
    3 : 'NAO AUTORIZADA',
    10: 'CANCELADA'
}

export const SolicitacaoMaterial_EM_ABERTO = 1;
export const SolicitacaoMaterial_AUTORIZADA = 2;
export const SolicitacaoMaterial_NAO_AUTORIZADA = 3;
export const SolicitacaoMaterial_CANCELADA = 10;