import React from "react";
import FormularioListagem from "../../componentes/FormularioListagem";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import IoContext from "../../contextos/IoContext";
import { useNavigate } from "react-router";
import { DateTime } from "luxon";

export default function BannersMobile() {
  const navigate = useNavigate();
  const { socket } = React.useContext(IoContext);
  const [pagina, setPagina] = React.useState(1);
  const [texto, setTexto] = React.useState("");
  const [BannersMobiles, setBannersMobiles] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [validadeIni, setValidadeIni] = React.useState("");

  React.useEffect(() => {
    let where = {};
    if (texto) where.descricao_like = `%${texto}%`;
    if (validadeIni) where.validoAte_lte = new Date(validadeIni).toISOString().split("T")[0];

    socket.emit(
      "BannerMobile.findAndCountAll",
      {
        where,
        limit: 10,
        offset: 10 * (pagina - 1),
      },
      (error, resp) => {
        if (error) {
          toast.error(error);
        } else {
          setTotal(resp.count);
          setBannersMobiles(resp.rows);
        }
      }
    );
  }, [pagina, texto, validadeIni, socket]);

  return (
    <FormularioListagem
      titulo={`Banners Mobile`}
      itens={BannersMobiles}
      qtdItens={total}
      pagina={pagina}
      setTexto={setTexto}
      setPagina={setPagina}
      titulos={["Id", "Descrição", "Valido Até"]}
      onClick={(e) => {
        navigate(`${e.id}`);
      }}
      renderItem={(titulo, item) => {
        switch (titulo) {
          case "Id":
            return item.id;
          case "Descrição":
            return item.descricao;
          case "Valido Até":
            return DateTime.fromISO(item.validoAte).toLocaleString(DateTime.DATE_SHORT);
          default:
            return titulo;
        }
      }}
      renderFiltros={() => {
        return (
          <Card.Body>
            <Form.Group>
              <Form.Label>Validade Até</Form.Label>
              <Form.Control type="date" value={validadeIni} onChange={(e) => setValidadeIni(e.target.value)} />
            </Form.Group>
          </Card.Body>
        );
      }}
    />
  );
}
