import React from 'react';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import IoContext from 'contextos/IoContext';

export default function SelectServicoContratadoVoip(props) {
    const { socket } = React.useContext(IoContext);
    const [servicosContratados, setServicosContratrados] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const { id, ContratoId, ServicoContratadoId, value, onChange, disabled, pular } = props;

    React.useEffect(() => {
        setLoading(true);
        setServicosContratrados([]);

        socket.emit("ServicoContratado.findAllDisponivelParaVoip", { ContratoId, ServicoContratadoId }, (error, resp) => {
            setLoading(false);
            if (error) {
                toast.error(error);
            } else {
                setServicosContratrados(resp);
            }
        });
    }, [socket, ContratoId, ServicoContratadoId]);

    let nProps = {...props};
    delete nProps.ContratoId;
    delete nProps.ServicoContratadoId;
    return <Form.Control as="select"
        id={id || "ServicosercId"}
        onChange={onChange} value={value}
        disabled={loading || disabled}
        {...nProps}
    >
        <option value=""></option>
        {(servicosContratados || []).map(serc => {
            if (pular) {
                if (Array.isArray(pular)) {
                    if (pular.indexOf(serc.id)) {
                        return false;
                    }
                } else {
                    if (+pular === +serc.id) {
                        return false;
                    }
                }
            }
            return <option key={serc.id} value={serc.id}>
                {serc.id} - {serc.Plano?.descricao}
            </option>
        })}
    </Form.Control>;
}
