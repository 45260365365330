import React from 'react';
import FormImportacao from './modulos/FormImportacao';
import { useParams } from 'react-router-dom';

export default function ImportarPessoas({ fonteDados, template }) {
    const params = useParams();
    const modulo = params.modulo;
    const [sql, setSql] = React.useState(``);
    const [campoOrdenacao, setCampoOrdenacao] = React.useState('');
    const [campos, setCampos] = React.useState({});
    const [mapeamento, setMapeamento] = React.useState({});

    return <FormImportacao
        fonteDados={fonteDados} titulo={`Mapear ${modulo}`} template={template}
        campoOrdenacao={campoOrdenacao} setCampoOrdenacao={setCampoOrdenacao}
        modulo={modulo} sql={sql} setSql={setSql} campos={campos} setCampos={setCampos}
        mapeamento={mapeamento} setMapeamento={setMapeamento}
    />
}