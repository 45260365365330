import React from 'react';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import IoContext from '../../../../contextos/IoContext';

export default function SelectCentrosDeCusto(props){
    const { socket } = React.useContext(IoContext);
    const { id, value, onChange, disabled, pular } = props; 
    const [loading, setLoading] = React.useState(false);
    const [centrosDeCusto, setCentrosDeCusto] = React.useState([]);
    
    React.useEffect(()=>{
        setLoading(true);
        socket.emit("CentroCusto.findAll", {
            order: [['nome']]
        }, (error, resp) => {
            setLoading(false);
            if(error) {
                toast.error(error);
            } else {
                setCentrosDeCusto(resp);
            }
        });
    }, [socket]);

    return <Form.Control as="select" 
        id={id || "CentroCustoId"} 
        onChange={onChange} value={value}
        disabled={loading || disabled}
        {...props}
    >
        <option value=""></option>
        { (centrosDeCusto || []).map( filial => {
            if(pular) {
                if(Array.isArray(pular)) {
                    if(pular.indexOf(filial.id)){
                        return false;
                    }
                } else {
                    if(+pular === +filial.id) {
                        return false;
                    }
                }
            }
            return <option key={filial.id} value={filial.id}>{filial.nome}</option>
        })}
    </Form.Control>;
}