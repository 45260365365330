import React from "react";
import { Routes, Route } from "react-router-dom";
import BannersMobile from "./BannersMobile";
import BannerMobile from "./BannerMobile";
import Tickets from "./tickets";
import MaxiTvUpdates from "./maxitvupdates";
import CruzamentoCsvContratos from "./ferramentas/CruzamentoCsvContratos";
import ImportacoesIndex from "./importacoes/ImportacoesIndex";
import ServidoresLhispIndex from "./servidores_lhisp";
import TarefasIndex from "./tarefas/TarefasIndex";
import IntegracoesIndex from "./integracoes/IntegracoesIndex";
import ConfiguracoesNotasFiscais from "./ConfiguracoesNotasFiscais/ConfiguracoesNotasFiscais";
import ConfigArmazenamentoAnexos from "./ConfigArmazenamentoAnexos/ConfigArmazenamentoAnexos";
import TemplatesEmail from "./template-email/index";
export default function SistemaIndex() {
  return (
    <Routes>
      <Route path={`/ajuda/tickets`} element={<Tickets />} />
      <Route path={`/banners_mobile/:id`} element={<BannerMobile />} />
      <Route path={`/banners_mobile`} element={<BannersMobile />} />
      <Route path={`/maxitv_updater`} element={<MaxiTvUpdates />} />
      <Route path={`/importacoes/*`} element={<ImportacoesIndex />} />
      <Route path={`/servidores_lhisp/*`} element={<ServidoresLhispIndex />} />
      <Route path={`/tarefas/*`} element={<TarefasIndex />} />
      <Route path={`/integracoes/*`} element={<IntegracoesIndex />} />
      <Route path={`/ferramentas/cruzamento_csv_contratos`} element={<CruzamentoCsvContratos />} />
      <Route path={`/configuracoes_notas_fiscais`} element={<ConfiguracoesNotasFiscais />} />
      <Route path={`/configuracoes_armazenamento_anexos`} element={<ConfigArmazenamentoAnexos />} />
      <Route path={`/templates-email/*`} element={<TemplatesEmail />} />
    </Routes>
  );
}
