import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import BotaoVoltar from 'componentes/BotaoVoltar';
import CampoEnderecoMac from 'componentes/CampoEnderecoMac';

export default function MovimentacaoAvulsaPag4({
    produto, tipo, loading, numeroSerie, setNumeroSerie,
    numeroPatrimonio, setNumeroPatrimonio, enderecoMac, setEnderecoMac,
    pagina, setPagina, adicionarMaterial
}) {
    const enderecoMacRef = React.useRef(null);
    const numeroSerieRef = React.useRef(null);
    const numeroPatrimonioRef = React.useRef(null);

    return <Form onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();

        adicionarMaterial(()=>{
            numeroSerieRef?.current?.focus();
        });
    }}>
        <Card.Body>
            <Row>
                <Col sm={8}>
                    <Form.Group>
                        <Form.Label>Produto</Form.Label>
                        <Alert variant="primary">
                            {produto ? produto.nome : ""}
                        </Alert>
                    </Form.Group>
                </Col>

                <Col sm={4}>
                    <Form.Group>
                        <Form.Label>Operação</Form.Label>
                        <Alert variant={tipo === 'E' ? "success" : "danger"}>
                            {tipo === 'E' ? "Entrada" : "Saída"}
                        </Alert>
                    </Form.Group>
                </Col>

                <Col sm={12}>
                    <Form.Group>
                        <Form.Label>Número de Série</Form.Label>
                        <Form.Control value={numeroSerie} onChange={e => setNumeroSerie(e.target.value)}
                            required={produto && produto.exigirNumeroSerie} maxLength={50} 
                            disabled={loading}  ref={numeroSerieRef}
                            autoFocus 
                            onKeyDown={ e => {
                                if(e.which === 13) {
                                    if(produto.exigirPatrimonio) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        numeroPatrimonioRef?.current?.focus();
                                        return false;
                                    }
                                }
                            }}
                            />
                    </Form.Group>
                </Col>

                <Col sm={12}>
                    <Form.Group>
                        <Form.Label>Número de Patrimônio</Form.Label>
                        <Form.Control value={numeroPatrimonio} onChange={e => setNumeroPatrimonio(e.target.value)}
                            required={produto && produto.exigirPatrimonio} 
                            maxLength={50} ref={numeroPatrimonioRef}
                            disabled={loading} 
                            onKeyDown={ e => {
                                if(e.which === 13) {
                                    if(produto.exigirMac) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        enderecoMacRef?.current?.focus();
                                        return false;
                                    }
                                }
                            }}
                            />
                    </Form.Group>
                </Col>

                <Col sm={12}>
                    <Form.Group>
                        <Form.Label>Endereço MAC</Form.Label>
                        <CampoEnderecoMac value={enderecoMac} onChange={txt => setEnderecoMac(txt)}
                            required={produto && produto.exigirMac} maxLength={17} disabled={loading} 
                            ref={enderecoMacRef}
                            />
                    </Form.Group>
                </Col>
            </Row>
        </Card.Body>

        <Modal.Footer>
            <BotaoVoltar onClick={() => { setPagina(pagina - 1) }} texto="Voltar" />

            <Button type="submit" disabled={loading}
                className={`${produto && produto.temControlePatrimonial ? '' : 'd-none'}`}>
                Avançar
            </Button>

            <Button type="submit" variant="primary" disabled={loading}
                className={`${produto && produto.temControlePatrimonial ? 'd-none' : ''}`}>
                {tipo === 'E' ? 'Adicionar Material' : 'Remover Material'}
            </Button>
        </Modal.Footer>
    </Form>
}