import React from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faSearch } from '@fortawesome/free-solid-svg-icons';
import Listagem from './Listagem';

export default function CampoProcurar({
    value, titulo, disabled, titulos, itens, renderItem,
    onSearch, onSelect, required = false
}) {
    const [exibirModal, setExibirModal] = React.useState(false);
    const [texto, setTexto] = React.useState('');
    const loading = false;

    return <React.Fragment>
        <InputGroup>
            <Form.Control value={value} required={required} readOnly />

            <Button disabled={disabled || loading} variant="secondary" onClick={() => {
                onSelect(null);
            }}>
                <FontAwesomeIcon icon={faBan} />
            </Button>

            <Button disabled={disabled || loading} variant="primary" onClick={() => { setExibirModal(true) }}>
                <FontAwesomeIcon icon={faSearch} />
            </Button>
        </InputGroup>

        <Modal show={exibirModal} onHide={() => setExibirModal(false)} size="lg">
            <Modal.Header closeButton>{titulo || 'Procurar'}</Modal.Header>
            <Modal.Body>
                <Form onSubmit={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    onSearch(texto);
                }}
                    className="mb-2">
                    <InputGroup>
                        <Form.Control
                            value={texto}
                            onChange={e => setTexto(e.target.value)} />

                        <Button type='submit' disabled={disabled || loading} variant="primary">
                            <FontAwesomeIcon icon={faSearch} />
                        </Button>
                    </InputGroup>
                </Form>

                <Listagem
                    titulos={titulos}
                    itens={itens || []}
                    renderItem={renderItem}
                    onClick={item => {
                        setExibirModal(false);
                        onSelect(item);
                    }}
                />
            </Modal.Body>
        </Modal>
    </React.Fragment>;
}