import React from "react";
import { Container, Row, Col, Card, Form, Button, Alert } from "react-bootstrap";
import { ReactSortable } from "react-sortablejs";
import TituloContext from "contextos/TituloContext";
import IoContext from "contextos/IoContext";
import SelectFuncionarioComVeiculo from "paginas/cadastros/administrativo/functionaios/SelectFuncionarioComVeiculo";
import { toast } from "react-toastify";
import { OS_ABERTA, TiposOrdemServico } from "datatypes/OrdemServicoTypes";
import LHMap from "componentes/mapas/LHMap";

import faCar from "../../images/car-solid.svg";
import faMapPin from "../../images/map-pin-solid.svg";
import CampoData from "componentes/CampoData";
import { formatarDataDbs, formatarDataHoraDbs, getFinalDia, getInicioDia } from "misc/lhdatas";

export default function CobliOtimizacaoRota() {
  const { socket } = React.useContext(IoContext);
  const { setTitulo } = React.useContext(TituloContext);
  const [TecnicoId, setTecnicoId] = React.useState(0);
  const [tecnico, setTecnico] = React.useState(null);
  const [CobliDeviceId, setCobliDeviceId] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [mensagem, setMensagem] = React.useState("");
  const [ordensServico, setOrdensServico] = React.useState([]);
  const [center, setCenter] = React.useState(null);
  const [positions, setPositions] = React.useState([]);
  const [isSorted, setIsSorted] = React.useState(false);
  const [dataIni, setDataIni] = React.useState(formatarDataDbs(getInicioDia()));
  const [dataEnd, setDataEnd] = React.useState(formatarDataDbs(getFinalDia()));

  React.useEffect(() => {
    setTitulo("Otimização de Rota");
  });

  React.useEffect(() => {
    setPositions([]);
    let positions = [];

    if (tecnico?.latitude && tecnico?.longitude) {
      positions.push([tecnico?.latitude, tecnico?.longitude]);
    }

    if (ordensServico) {
      ordensServico.forEach((os) => {
        if (os.Atendimento?.Contrato?.latitude && os.Atendimento?.Contrato?.longitude) {
          positions.push([os.Atendimento?.Contrato?.latitude, +os.Atendimento?.Contrato?.longitude]);
        }
      });
    }

    setPositions(positions);
  }, [tecnico, ordensServico]);

  React.useEffect(() => {
    setOrdensServico([]);
    setTecnico(null);
    if (!TecnicoId) return;

    setLoading(true);
    setMensagem("Aguarde, carregando Ordens de Serviço.");

    const dini = `${dataIni}T00:00:00.000Z`;
    const dend = `${dataEnd}T23:59:59.999Z`;

    socket.emit(
      "OrdemServico.findAll",
      {
        attributes: ["id", "data_agendamento", "ordem", "tipo"],
        where: {
          TecnicoId,
          situacao: OS_ABERTA,
          data_agendamento_between: [dini, dend],
        },
        withAtendimento: {
          attributes: ["id"],
          withContrato: {
            attributes: ["id", "numero_contrato", "numero", "latitude", "longitude"],
            withPessoa: {
              attributes: ["nome"],
            },
            withEndereco: {},
            required: true,
          },
          required: true,
        },
        order: [
          ["ordem", "asc"],
          ["data_agendamento", "asc"],
        ],
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          setMensagem("" + error);
          return toast.error("" + error);
        }

        setMensagem("");
        setOrdensServico(resp);
      }
    );

    socket.emit("Tecnico.GetPosicaoVeiculo", { TecnicoId }, (error, resp) => {
      if (error) {
        setLoading(false);
        setMensagem("" + error);
        console.log("erro >", error);
        return;
      }
      if (resp?.latitude && resp?.longitude) {
        setTecnico(resp);
        setCenter([resp?.latitude, resp?.longitude]);
      }
    });
  }, [socket, TecnicoId, count, dataIni, dataEnd]);

  React.useEffect(() => {
    if (!isSorted || !ordensServico.length) return;
    setIsSorted(false);

    socket.emit(
      "AgendaTecnica.AlterarOrdenacaoOrdensServico",
      ordensServico.reduce((acc, cur, i) => [...acc, { id: cur.id, ordem: i }], []),
      (error, resp) => {
        if (error) {
          return toast.error(error);
        } else if (resp) {
          return toast.success("Ordenação alterada com sucesso ! ");
        }
      }
    );
  }, [socket, ordensServico, isSorted]);

  return (
    <Container fluid className="d-flex flex-column">
      <Card style={{ flex: 1 }}>
        <Card.Body>
          <Row>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Técnico</Form.Label>
                <SelectFuncionarioComVeiculo
                  value={TecnicoId}
                  disabled={loading}
                  onSelect={(f) => {
                    setTecnicoId(f?.id);
                    setCobliDeviceId(f?.cobliDeviceId);
                  }}
                />
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Data Inicial</Form.Label>
                <CampoData disabled={loading} value={dataIni} onChange={(e) => setDataIni(e.target.value)} />
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Data Final</Form.Label>
                <CampoData disabled={loading} value={dataEnd} onChange={(e) => setDataEnd(e.target.value)} />
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    variant="primary"
                    disabled={loading || !TecnicoId}
                    onClick={() => {
                      otimizar({
                        socket,
                        CobliDeviceId,
                        ordensServico,
                        setLoading,
                        setMensagem,
                        setOrdensServico,
                        setCount,
                        count,
                      }).catch((error) => {
                        setLoading(false);
                        setMensagem("" + error);
                      });
                    }}
                  >
                    Otimizar
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Alert className={`mt-2 mb-2 ${mensagem ? "d-block" : "d-none"}`}>{mensagem}</Alert>

      <Row style={{ flex: 20 }}>
        <Col sm={8} className="mt-2">
          <LHMap
            prefixName="cobli_otim_rotas"
            center={center}
            overlays={[
              {
                name: "Rota Cobli",
                markers: (ordensServico || [])
                  .map((os) => {
                    return {
                      position: [+os.Atendimento?.Contrato?.latitude, +os.Atendimento?.Contrato?.longitude],
                      tooltip: `Contrato ${os?.Atendimento?.Contrato?.numero_contrato}`,
                      icon: {
                        size: 30,
                        image: faMapPin,
                        anchor: [15, 29],
                      },
                    }; // as LHMarker
                  })
                  .filter((m) => {
                    return m.position[0] && m.position[1];
                  }),
                polylines: [
                  {
                    color: "blue",
                    weight: 2.5,
                    opacity: 0.5,
                    positions: positions,
                  },
                ],
              },
              tecnico?.latitude && tecnico?.longitude
                ? {
                    name: "Tecnico",
                    markers: [
                      {
                        position: [tecnico?.latitude, tecnico?.longitude],
                        tooltip: tecnico?.nome || tecnico?.descricao || null,
                        icon: {
                          size: 20,
                          image: faCar,
                        },
                      },
                    ],
                  }
                : {},
            ]}
          />
        </Col>

        <Col sm={4}>
          <ReactSortable
            tag="ul"
            list={ordensServico}
            onUpdate={() => setIsSorted(true)}
            setList={setOrdensServico}
            style={{ listStyle: "none" }}
          >
            {ordensServico.map((os) => {
              const contrato = os.Atendimento?.Contrato || {};
              const pessoa = contrato.Pessoa || {};
              const endereco = contrato.Endereco || {};
              return (
                <li key={os.id}>
                  <Card className="mt-2" key={os.id}>
                    <Card.Header className="bg-primary text-light">
                      <Card.Title>
                        [{contrato.numero_contrato}] {pessoa.nome}
                      </Card.Title>
                    </Card.Header>

                    <Card.Body>
                      <div>
                        <b className="me-2">Atendimento:</b>
                        {os.Atendimento?.id}
                      </div>

                      <div>
                        <b className="me-2">Ordem de Serviço:</b>
                        {os.id}
                      </div>

                      <div>
                        <b className="me-2">Endereço:</b>
                        {endereco.logradouro}, {contrato.numero || "SN"} - {endereco.bairro}, {endereco.cidade} -{" "}
                        {endereco.uf}, {endereco.cep}
                      </div>

                      <div>
                        <b className="me-2">Coord:</b>
                        <span className={!contrato.latitude || !contrato.longitude ? "text-danger" : ""}>
                          {contrato.latitude && contrato.longitude
                            ? `${contrato.latitude} x ${contrato.longitude}`
                            : "SEM COORDENADAS"}
                        </span>
                      </div>

                      <div>
                        <b className="me-2">Data Agendamento:</b>
                        {os.data_agendamento.split("-").reverse().join("/")}
                      </div>

                      <div>
                        <b className="me-2">Tipo:</b>
                        {TiposOrdemServico[os.tipo] || `Tipo ${os.tipo}`}
                      </div>

                      <div>
                        <b className="me-2">Ordem:</b>
                        {os.ordem}
                      </div>
                    </Card.Body>
                  </Card>
                </li>
              );
            })}
          </ReactSortable>
        </Col>
      </Row>
    </Container>
  );
}

async function otimizar({
  socket,
  CobliDeviceId,
  ordensServico,
  setLoading,
  setMensagem,
  setOrdensServico,
  setCount,
  count,
}) {
  setLoading(true);
  setMensagem("Checando Coordenadas.");

  const _os = [...ordensServico];

  for (let i = 0; i < _os.length; i++) {
    const os = _os[i];
    const contrato = os.Atendimento?.Contrato || {};

    if (!contrato.numero_contrato) continue;
    if (
      contrato.latitude &&
      contrato.longitude &&
      contrato.latitude !== "0.000000000000" &&
      contrato.longitude !== "0.000000000000"
    )
      continue;

    setMensagem(`Consultando Coordenadas do Contrato: ${contrato.numero_contrato}`);
    await new Promise((resolve, reject) => {
      const _endereco = contrato.Endereco || {};
      const endereco = `${_endereco.logradouro}, ${_endereco.numero || "SN"} - ${_endereco.bairro}, ${
        _endereco.cidade
      } - ${_endereco.uf}, ${_endereco.cep}`;

      socket.emit(
        "GoogleMaps.GeoLocalizar",
        {
          endereco,
          ContratoId: contrato.id,
        },
        (error, resp) => {
          if (error) return reject(error);

          if (resp && resp[0]) {
            contrato.latitude = resp?.[0]?.lat;
            contrato.longitude = resp?.[0]?.lng;
          }
          resolve(resp);
        }
      );
    });
  }

  setOrdensServico(_os);
  setMensagem("Calculando Otimização de Rota");

  await new Promise((resolve, reject) => {
    socket.emit(
      "GoogleMaps.CalculoRota",
      {
        device_id: CobliDeviceId,
        ordens_servico: _os,
      },
      (error, resp) => {
        if (error) return reject(error);
        resolve(resp);
      }
    );
  });
  setCount(count + 1);
  setMensagem("");
}
