import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Listagem from "componentes/Listagem";
import TituloContext from "contextos/TituloContext";
import SelectCategorias from "paginas/estoque/categorias/SelectCategorias";
import ModalMovimentacaoEstoqueAvulsa from "./ModalMovimentacaoEstoqueAvulsa";
import ModalTransfererirLocal from "./ModalTransfererirLocal";
import { toast } from "react-toastify";
import IoContext from "contextos/IoContext";
import SelectAlmoxarifados from "paginas/estoque/SelectAlmoxarifados";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSync, faPrint } from "@fortawesome/free-solid-svg-icons";
import SelectLocalAlmoxarifado from "./SelectLocalAlmoxarifado";

export default function Almoxarifados({
  AlmoxarifadoId,
  setAlmoxarifadoId,
  CategoriaId,
  setCategoriaId,
  exibirCategorias,
  setExbirCategorias,
  produto,
  setProduto,
  exibirEstoqueZero,
  setExibirEstoqueZero,
}) {
  const navigate = useNavigate();
  const { socket } = React.useContext(IoContext);
  const { setTitulo } = React.useContext(TituloContext);
  const [itens, setItens] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [estoqueTranf, setEstoqueTransf] = React.useState(null);
  const [countMov, setCountMov] = React.useState(0);
  const [countTransf, setCountTransf] = React.useState(0);
  const [showModalMov, setShowModalMov] = React.useState(false);
  const [showModalTransf, setShowModalTransf] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [texto, setTexto] = React.useState(produto);
  const [qtdTotal, setQtdTotal] = React.useState(0);
  const [LocalAlmoxarifadoId, setLocalAlmoxarifadoId] = React.useState("");

  React.useEffect(
    function () {
      let where = {
        AlmoxarifadoId,
      };

      if (!exibirEstoqueZero) {
        where.quantidade_gt = 0;
      }

      let whereProduto = {
        nome_like: `%${texto}%`,
      };

      if (CategoriaId) {
        if (exibirCategorias) {
          whereProduto.CategoriaId = CategoriaId;
        } else {
          whereProduto.CategoriaId_ne = CategoriaId;
        }
      }

      if (LocalAlmoxarifadoId) {
        where.LocalAlmoxarifadoId = LocalAlmoxarifadoId;
      }

      setItens([]);
      setLoading(true);
      socket.emit(
        "Estoque.findAll",
        {
          attributes: ["id", "AlmoxarifadoId", "LocalAlmoxarifadoId", "ProdutoId", "quantidade"],
          where,
          withProduto: {
            attributes: ["id", "nome", "unidade", "preco", "temControlePatrimonial"],
            where: whereProduto,
            withCategoria: {
              attributes: ["nome"],
            },
          },
          withLocalAlmoxarifado: {
            attributes: ["descricao"],
          },
          order: [["col_Produto.nome", "ASC"]],
        },
        (error, resp) => {
          setLoading(false);
          if (error) {
            toast.error("" + error);
          } else {
            setItens(resp);
            setQtdTotal(resp.reduce((acc, item) => acc + item.quantidade, 0).toFixed(2));
          }
        }
      );
    },
    [socket, AlmoxarifadoId, LocalAlmoxarifadoId, CategoriaId, exibirCategorias, texto, exibirEstoqueZero, count]
  );

  React.useEffect(() => {
    setTitulo(`Almoxarifados`);
  }, [setTitulo]);

  return (
    <Container fluid className="pt-3">
      <Card className="d-print-none">
        <Card.Body>
          <Form
            onSubmit={(e) => {
              setTexto(produto);
              setCount(count + 1);
              e.preventDefault();
              e.stopPropagation();
              return false;
            }}
          >
            <Row>
              <Col sm={2}>
                <Form.Group>
                  <Form.Label>Almoxarifado</Form.Label>
                  <SelectAlmoxarifados
                    value={AlmoxarifadoId}
                    required
                    disabled={loading}
                    onChange={(e) => {
                      setAlmoxarifadoId(e.target.value);
                      if (e.target.value) {
                        navigate(`${e.target.value}`);
                      }
                    }}
                  />
                </Form.Group>
              </Col>

              <Col sm={2}>
                <Form.Group>
                  <Form.Label>Local</Form.Label>
                  <SelectLocalAlmoxarifado
                    AlmoxarifadoId={AlmoxarifadoId}
                    value={LocalAlmoxarifadoId}
                    disabled={loading}
                    onChange={(e) => {
                      setLocalAlmoxarifadoId(e.target.value);
                      if (e.target.value) {
                        navigate(`${e.target.value}`);
                      }
                    }}
                  />
                </Form.Group>
              </Col>

              <Col sm={2}>
                <Form.Group>
                  <Form.Label>Categoria</Form.Label>
                  <InputGroup>
                    <InputGroup.Checkbox
                      type="checkbox"
                      disabled={loading}
                      title={
                        exibirCategorias
                          ? "Exibir Somente Produtos da Categoria Selecionada !"
                          : "Exibir Produtos de todas as Categorias *Exceto* a Selecionada !"
                      }
                      checked={exibirCategorias}
                      onChange={(e) => setExbirCategorias(!exibirCategorias)}
                    />

                    <SelectCategorias
                      disabled={loading}
                      value={CategoriaId}
                      onChange={(e) => setCategoriaId(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>

              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Produto</Form.Label>
                  <Form.Control value={produto} disabled={loading} onChange={(e) => setProduto(e.target.value)} />
                </Form.Group>
              </Col>

              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Opções</Form.Label>
                  <div>
                    <Form.Check
                      label="Exibir Produtos com Estoque Zero ?"
                      disabled={loading}
                      onClick={() => setExibirEstoqueZero(!exibirEstoqueZero)}
                    />
                  </div>
                </Form.Group>
              </Col>

              <Col sm={2}>
                <Form.Group>
                  <Form.Label>Ações</Form.Label>
                  <div>
                    <Button
                      type="submit"
                      className="me-2"
                      variant="info"
                      title="Atualizar Listagem"
                      disabled={loading || !AlmoxarifadoId}
                    >
                      <FontAwesomeIcon icon={faSync} />
                    </Button>

                    <Button
                      className="me-2"
                      variant="secondary"
                      disabled={loading}
                      title="Imprimir"
                      onClick={() => window.print()}
                    >
                      <FontAwesomeIcon icon={faPrint} />
                    </Button>

                    <Button
                      className="me-2"
                      variant="success"
                      title="Adicionar Movimentação"
                      disabled={loading || !AlmoxarifadoId}
                      onClick={() => {
                        setCountMov(countMov + 1);
                        setShowModalMov(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Body>
          <Listagem
            titulos={["Categoria", "Local", "Produto", "Quantidade", "Preço", "Ações"]}
            itens={itens || []}
            tamanhos={[
              { sm: 12, md: 2 },
              { sm: 12, md: 2 },
              { sm: 12, md: 5 },
              { sm: 12, md: 1 },
              { sm: 12, md: 1 },
            ]}
            renderItem={(titulo, item) => {
              switch (titulo) {
                case "Categoria":
                  const Produto = item.Produto || {};
                  const Categoria = Produto.Categoria || {};
                  return Categoria.nome || "----";
                case "Local":
                  return item.LocalAlmoxarifado ? item.LocalAlmoxarifado.descricao : "----";
                case "Produto":
                  return item.Produto ? item.Produto.nome : "----";
                case "Quantidade":
                  return `${parseInt(item.quantidade)} ${item.Produto?.unidade}`;
                case "Preço":
                  return `${parseFloat(item.Produto?.preco).toFixed(2)}`;
                case "Ações":
                  return (
                    <React.Fragment>
                      <Button
                        title="Histórico de Movimentações do Produto"
                        disabled={loading}
                        className="me-2"
                        onClick={() => {
                          navigate(`${AlmoxarifadoId}/${item.ProdutoId}/historico`);
                        }}
                      >
                        H
                      </Button>

                      {item.Produto && item.Produto.temControlePatrimonial ? (
                        <Button
                          title="Gerenciar Patrimônio do Produto no Estoque"
                          disabled={loading}
                          className="me-2"
                          variant="info"
                          onClick={() => {
                            navigate(`${AlmoxarifadoId}/${item.ProdutoId}/patrimonio`);
                          }}
                        >
                          P
                        </Button>
                      ) : (
                        false
                      )}

                      <Button
                        title="Trasferir de Local"
                        disabled={loading}
                        className="me-2"
                        variant="warning"
                        onClick={() => {
                          setEstoqueTransf(item);
                          setCountTransf(countTransf + 1);
                          setShowModalTransf(true);
                        }}
                      >
                        T
                      </Button>
                    </React.Fragment>
                  );
                default:
                  return titulo;
              }
            }}
          />
          <Row className="">
            <Col sm={9}></Col>
            <Col sm={3}>{qtdTotal}</Col>
          </Row>
        </Card.Body>
      </Card>

      <ModalMovimentacaoEstoqueAvulsa
        show={showModalMov}
        AlmoxarifadoId={AlmoxarifadoId}
        count={countMov}
        onHide={(reload) => {
          setShowModalMov(false);
          if (reload) setCount(count + 1);
        }}
      />

      <ModalTransfererirLocal
        show={showModalTransf}
        estoque={estoqueTranf}
        count={countTransf}
        onHide={(reload) => {
          setShowModalTransf(false);
          if (reload) setCount(count + 1);
        }}
      />
    </Container>
  );
}
