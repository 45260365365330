export const SITUACAO_CONTA_EM_ABERTO = 0;
export const SITUACAO_CONTA_PAGA = 1;
export const SITUACAO_CONTA_CANCELADA = 2;

export const CONTA_PAGAR_SITUACAO = {
    0: 'EM ABERTO',
    1: 'PAGA',
    2: 'CANCELADA'
};

export const CONTA_PAGAR_SITUACAO_COR = {
    0: 'default',
    1: 'info',
    2: 'warning'
};

export const TIPOS = {
    "1" : "BOLETO",
    "2" : "CONVÊNIO",
    "3" : "CHEQUE",
    "4" : "TRANSFERÊNCIA",
    "5" : "PIX",
    "8" : "ESPECIE",
    "10": "OUTROS"
};