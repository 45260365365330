import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import IoContext from "contextos/IoContext";
import Listagem from "componentes/Listagem";
import { toast } from "react-toastify";
import { downloadCsv } from "misc/downloadCsv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";

export default function RelatorioFornecedores() {
  const { socket } = React.useContext(IoContext);
  const [consultas, setConsultas] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [nome, setNome] = React.useState("");
  const [texto, setTexto] = React.useState("");
  const [contador, setContador] = React.useState(0);

  React.useEffect(() => {
    setConsultas([]);
    setLoading(true);

    socket.emit(
      "Pessoa.findAll",
      {
        attributes: [
          "id",
          "nome",
          "doc1",
          "telefone1",
          "telefone2",
          "telefone3",
          "telefone4",
          "email",
        ],
        where: {
          is_fornecedor: true,
          nome_like: `${nome}%`,
        },
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          return toast.error("" + error);
        }
        setConsultas(resp);
      }
    );
  }, [socket, nome, contador]);

  return (
    <Container fluid>
      <Card className="shadow">
        <Card.Header className="bg-primary text-light">
          <h3 className="float-start">Relatório de Fornecedores</h3>
        </Card.Header>

        <Card.Body className="d-print-none">
          <Row>
            <Col sm={8}>
              <Form.Group>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  autoFocus
                  value={texto}
                  disabled={loading}
                  onChange={(e) => setTexto(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.which === 13) {
                      setNome(texto);
                    }
                  }}
                />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    variant="info"
                    title="Listar"
                    className="me-2"
                    disabled={loading}
                    onClick={() => {
                      setNome(texto);
                      setContador(contador + 1);
                    }}
                  >
                    Atualizar
                  </Button>

                  <Button
                    title="Listar"
                    className="me-2"
                    disabled={loading}
                    onClick={() => window.print()}
                  >
                    Imprimir
                  </Button>

                  <Button
                    title="Baixar Planilha"
                    variant="secondary"
                    className="ms-1"
                    onClick={() => {
                      downloadCsv(
                        [
                          ["id", "Nome", "Cpf/Cnpj", "Telefones", "Email"],
                          ...consultas.map((item) => {
                            return [
                              item.id,
                              item.nome,
                              item.doc1,
                              [
                                item.telefone1,
                                item.telefone2,
                                item.telefone3,
                                item.telefone4,
                              ]
                                .filter((t) => !!t)
                                .join(" "),
                              item.email,
                            ];
                          }),
                        ],
                        "RelatorioConsultasSpcBrasil"
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faFileCsv} className="me-1" /> Baixar
                    Planilha
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>

        <Listagem
          titulos={["Nome", "Cpf/Cnpj", "Telefones", "Email"]}
          tamanhos={[
            { md: 5, sm: 12 },
            { md: 1, sm: 12 },
            { md: 3, sm: 12 },
            { md: 3, sm: 12 },
          ]}
          itens={consultas}
          renderItem={(titulo, item) => {
            switch (titulo) {
              case "Nome":
                return item.nome;
              case "Cpf/Cnpj":
                return item.doc1;
              case "Email":
                return item.email;
              case "Telefones":
                return [
                  item.telefone1,
                  item.telefone2,
                  item.telefone3,
                  item.telefone4,
                ]
                  .filter((t) => !!t)
                  .join(" ");
              default:
                return titulo;
            }
          }}
          renderSummary={() => {
            return (
              <Col className="mt-2 text-end">
                {consultas?.length} Fornecedor{consultas?.length > 1 ? "s" : ""}
              </Col>
            );
          }}
        />
      </Card>
    </Container>
  );
}
