import React from "react";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ToggleSimNao, { ToggleSimNaoValorSIM } from "componentes/ToggleSimNao";
import IoContext from "contextos/IoContext";
import { toast } from "react-toastify";

export default function ModalReajustarServicosContratados({
  PlanoId,
  FilialId,
  reajuste,
  tipoPessoa,
  tipoReajuste,
  carenciaUltimoReajuste,
  show,
  onHide,
  callback,
}) {
  const { socket } = React.useContext(IoContext);
  const [reajustarPlano, setReajustarPlano] = React.useState("");
  const [reajustarContas, setReajustarContas] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  return (
    <Modal
      show={show}
      onHide={() => {
        setReajustarPlano("");
        setReajustarContas("");
        onHide();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Reajustar Serviços Contratados</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Form.Group className="mb-2">
            <h2 className="text-danger text-center">
              Reajuste: {reajuste} {tipoReajuste === "percentual" ? "%" : "R$"}
            </h2>
          </Form.Group>
          <Form.Group className="mb-2">
            <h4 className="text-center">
              Clientes com Pelo menos{" "}
              <span className="text-warning">{carenciaUltimoReajuste}</span>{" "}
              meses da data de Ativação ou desde o último Reajuste.
            </h4>
          </Form.Group>
          <Form.Group className="mb-2">
            <span className="me-3">Reajustar Plano ?</span>
            <ToggleSimNao
              className="w-100"
              name="reajustarPlano"
              disabled={loading}
              value={reajustarPlano}
              onChange={(e) => setReajustarPlano(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <span className="me-3">Reajustar Contas a Receber ?</span>
            <ToggleSimNao
              className="w-100"
              name="reajustarContas"
              disabled={loading}
              value={reajustarContas}
              onChange={(e) => setReajustarContas(e.target.value)}
            />
          </Form.Group>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="btn-primary"
          disabled={loading || reajustarPlano === "" || reajustarContas === ""}
          onClick={(e) => {
            setLoading(true);
            socket.emit(
              "Financeiro.ReajustarServicosContratados",
              {
                PlanoId,
                FilialId,
                reajuste,
                tipoPessoa,
                tipoReajuste,
                carenciaUltimoReajuste,
                reajustarPlano: reajustarPlano === ToggleSimNaoValorSIM,
                reajustarContas: reajustarContas === ToggleSimNaoValorSIM,
              },
              (error) => {
                setLoading(false);

                if (error) {
                  toast.error("" + error);
                  console.debug("Falha ao ", error);
                  return;
                }

                if (callback) {
                  callback();
                } else {
                  toast.success("Reajustes Aplicados com Sucesso !");
                }

                onHide();
              }
            );
          }}
        >
          Reajustar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
