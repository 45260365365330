import React from 'react';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import IoContext from 'contextos/IoContext';

export default function SelectPlanos(props) {
    const { socket } = React.useContext(IoContext);
    const { id, value, onChange, disabled, pular } = props;
    const [Planos, setPlanos] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        setPlanos([]);

        socket.emit("Plano.findAll", {
            attributes: ['id', 'nome'],
            order: [["nome", "ASC"]]
        }, (error, resp) => {
            setLoading(false);
            if (error) {
                toast.error(error);
            } else {
                setPlanos(resp);
            }
        });
    }, [socket]);

    return <Form.Control as="select"
        id={id || "PlanoId"}
        onChange={onChange} value={value}
        disabled={loading || disabled}
        {...props}
    >
        <option value=""></option>
        {(Planos || []).map(plano => {
            if (pular) {
                if (Array.isArray(pular)) {
                    if (pular.indexOf(plano.id)) {
                        return false;
                    }
                } else {
                    if (+pular === +plano.id) {
                        return false;
                    }
                }
            }
            return <option key={plano.id} value={plano.id}>{plano.id} - {plano.nome}</option>
        })}
    </Form.Control>;
}
