import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignal } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import IoContext from '../../contextos/IoContext';

export default function CardServidoresOffline(){
    const { socket } = React.useContext(IoContext);
    const [msg1, setMsg1] = React.useState(null);
    const [msg2, setMsg2] = React.useState(null);
    const [count, setCount] = React.useState(0);
    
    React.useEffect(()=>{
        socket.emit("Servidor.count", {
            where: {
                ativo: true
            }
        }, (error, total) => {
            if(error) {
                return setMsg1({ color: 'danger', text: ''+error });
            } else {
                socket.emit("Servidor.count", {
                    where: {
                        ativo: true
                    },
                    withBackups: {
                        where: {
                            createdAt_gt: (new Date()).toISOString().split("T")[0]
                        },
                        required: true
                    }
                }, (error, countComBackup) => {
                    if(error) {
                        return setMsg1({ color: 'danger', text: ''+error });
                    } else {
                        let diff = (total - countComBackup);
                        if(diff <= 0) {
                            setMsg1({ color: 'success', text: `${total} Backups em Dia !`, });
                        } else {
                            setMsg1({ 
                                color: 'danger', 
                                text: `${diff} Servidor${diff > 1 ? 'es' : ''} sem Backup`, 
                                linkTo: '/redeinfra/servidores/sem_backup'
                            });
                        }
                    }
                });
                setMsg1({ color: 'success', text: `${total} Servidores`, });
            }
        });

        socket.emit("Servidor.count", {
            where: { status: false }
        }, (error, countOff) => {
            if(error) {
                return setMsg2({ color: 'danger', text: ''+error });
            } else {
                if(countOff <= 0) {
                    setMsg2({ color: 'success', text: "Todos os Servidores estão Online !!!", });
                } else {
                    setMsg2({ color: 'warning',  text: `${countOff} Servidor${countOff>1?'es':''} Offline` });
                }
            }
        })
    }, [socket, count]);

    return <Card className='mb-2 pt-2 pb-2 shadow' style={{ height: "150px" }}>
            <Card.Body>
                <Row>
                    <Col sm={6}>
                        <p>
                        {[msg1, msg2].map( (msg, idx) => {
                            if(!msg) return false;
                            if(msg.linkTo) {
                                return <Link key={idx} to={msg.linkTo} className={`d-block text-${msg.color}`}>{msg.text}</Link>
                            }

                            return <span key={idx} className={`d-block text-${msg.color}`}>{msg.text}</span>
                        })}
                        </p>

                        <button className={`${process.env.NODE_ENV==='development' ? 'd-block' : 'd-none'}`} onClick={ ()=> setCount(count+1)}>OK</button>
                    </Col>

                    <Col sm={6} className="d-flex justify-content-end">
                        <FontAwesomeIcon icon={faSignal} size="5x" color={msg1 || msg2 ? 'red' : 'green'} />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
}