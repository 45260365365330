import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import axios from "axios";

export default function FonteDadosApi({ setFonteDados, fonteDados }) {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [apiType, setApiType] = React.useState(fonteDados?.apiType || "");
  const [usuario, setUsuario] = React.useState(fonteDados?.dbhost || "");
  const [senha, setSenha] = React.useState(fonteDados?.dbport || 0);
  const [identificador, setIdentificador] = React.useState(fonteDados?.dbname || "");
  const [baseUrl, setBaseUrl] = React.useState(fonteDados?.dbuser || "");

  return (
    <Card>
      <Card.Header className="bg-primary text-light">
        <Card.Title>Detalhes da Fonte de Dados [API]</Card.Title>
      </Card.Header>

      <Card.Body>
        <Form
          onSubmit={(e) => {
            e.preventDefault();

            let fonteDados = {
              tipo: "api",
              apiType,
              usuario,
              senha,
              identificador,
              baseUrl,
            };

            setLoading(true);
            axios
              .post("/importacoes/testar_conexao_api", fonteDados)
              .then((resp) => {
                setLoading(false);
                toast.success("Conexão com a API Bem Sucedida !");
                setFonteDados(fonteDados);
                navigate("../modulos");
              })
              .catch((error) => {
                setLoading(false);
                toast.error("" + error);
              });
          }}
        >
          <Row>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>Tipo da API</Form.Label>
                <Form.Control
                  as="select"
                  value={apiType}
                  required
                  disabled={loading}
                  onChange={(e) => {
                    setApiType(e.target.value);
                    switch (e.target.value) {
                      case "topsap":
                        setBaseUrl("https://177.23.16.1:9910");
                        setUsuario("lhisp");
                        setSenha("12345678");
                        setIdentificador("TESTE");
                        break;
                      default:
                        break;
                    }
                  }}
                >
                  <option></option>
                  <option value="topsap">Topsapp</option>
                </Form.Control>
              </Form.Group>
            </Col>

            <Col sm={8}>
              <Form.Group>
                <Form.Label>URL da API</Form.Label>
                <Form.Control
                  value={baseUrl}
                  onChange={(e) => setBaseUrl(e.target.value)}
                  maxLength={100}
                  disabled={loading}
                  required
                />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Usuário</Form.Label>
                <Form.Control
                  value={usuario}
                  onChange={(e) => setUsuario(e.target.value)}
                  maxLength={100}
                  required
                  disabled={loading}
                />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Senha</Form.Label>
                <Form.Control
                  type="password"
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)}
                  maxLength={100}
                  required
                  disabled={loading}
                />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Identificador</Form.Label>
                <Form.Control
                  type="text"
                  value={identificador}
                  onChange={(e) => setIdentificador(e.target.value)}
                  maxLength={100}
                  required
                  disabled={loading}
                />
              </Form.Group>
            </Col>
          </Row>

          <Col sm={12} className="mt-2 d-flex justify-content-end">
            <Button
              className="me-2"
              variant="warning"
              disabled={loading}
              onClick={() => {
                setFonteDados(null);
                navigate(-1);
              }}
            >
              Voltar
            </Button>

            <Button type="submit" disabled={loading}>
              Testar Conexão
            </Button>
          </Col>
        </Form>
      </Card.Body>
    </Card>
  );
}
