import CampoMoeda from 'componentes/CampoMoeda';
import IoContext from 'contextos/IoContext';
import React from 'react';
import { Button, Form, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

export default function ModalAdicionarRecargaVoip({
    ContratoVoipId, show, onHide, closeButton=true,
    disabled, callback=(_e)=>{}
}){
    const { socket } = React.useContext(IoContext);
    const [valor, setValor] = React.useState(0);
    const [loading, setLoading] = React.useState(false);

    return <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton={closeButton}>
            <Modal.Title>Adicionar Recarga Voip</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Row>
                <Form.Group>
                    <Form.Label>Valor</Form.Label>
                    <CampoMoeda
                        disabled={disabled || loading}
                        value={valor}
                        required
                        onChange={ e => setValor(+e.target.value) }
                        />
                </Form.Group>
            </Row>
        </Modal.Body>

        <Modal.Footer>
            <Button className="btn-warning" disabled={disabled || loading} onClick={() => {
                onHide(false);
            }}>Cancelar</Button>

            <Button className="btn-primary" disabled={disabled || loading} onClick={e => {
                if(valor <= 0) return toast.info("O Valor deve ser maior que zero !");
                setLoading(true);
                socket.emit("RecargaContratoVoip.efetuarRecarga", {
                    ContratoVoipId, valor
                }, (error, resp) => {
                    setLoading(false);
                    if(error) {
                        console.error("RecargaContratoVoip.efetuarRecarga:", error)
                        return toast.error(""+error);
                    }

                    toast.info("Recarga Efetuada com Sucesso !");
                    onHide(false);
                    if(callback) {
                        callback(resp);
                    }
                })
            }}>Salvar</Button>
        </Modal.Footer>
    </Modal>
}