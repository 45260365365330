import React from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';

export const ToggleSimNaoValorSIM = 'S';
export const ToggleSimNaoValorNAO = 'N';

export default function ToggleSimNao({
    value, 
    onChange,
    name,
    valueSim=ToggleSimNaoValorSIM,
    valueNao=ToggleSimNaoValorNAO,
    variantNaoSelecionado='secondary', 
    variantSim='success', 
    variantNao='danger',
    textoSim='Sim',
    textoNao='Não',
    className='',
    disabled=false,
}) {
    return <ButtonGroup className={className}>
        <ToggleButton
            id={`${name}Sim`}
            name={name}
            type="radio"
            value={valueSim}
            variant={value===valueSim? variantSim : variantNaoSelecionado }
            checked={value===valueSim}
            onChange={onChange}
            disabled={disabled}
        >
            {textoSim}
        </ToggleButton>

        <ToggleButton
            id={`${name}Nao`}
            name={name}
            type="radio"
            value={valueNao}
            variant={value===valueNao? variantNao : variantNaoSelecionado }
            checked={value===valueNao}
            onChange={onChange}
            disabled={disabled}
        >
            {textoNao}
        </ToggleButton>
    </ButtonGroup>
}