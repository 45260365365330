import React from 'react'
import { Card, Table } from 'react-bootstrap'
import { formatarDinheiro } from 'misc/formatarDinheiro';

export default function CardListaEstoquePorFilial({historicoEstoque}) {
    let quantidadeTotal=0;
    let valorTotal=0
  return (
    <Card>
        <Table>
            <thead>
                <tr className={'text-left'}>
                    <th>Data/Hora</th>
                    <th>Contrato</th>
                    <th>Cliente</th>
                    <th>Produto</th>
                    <th>Quantidade</th>
                    <th>Preço</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
                {historicoEstoque.map((historico, key)=>{ 
                    quantidadeTotal += Number(historico.quantidade);
                    valorTotal += (Number(historico.quantidade)*Number(historico.Produto?.preco)||0)
                    return <tr key={key} className={'text-center'}>
                        <td>{(new Date(historico.dataLocacao)).toLocaleString()}</td>
                        <td>{historico.Contrato?.numero_contrato}</td>
                        <td>{historico.Contrato?.Pessoa?.nome}</td>
                        <td>{historico.Produto?.nome}</td>
                        <td>{historico.quantidade}</td>
                        <td className='text-end'>{formatarDinheiro(historico.Produto?.preco)}</td>
                        <td className='text-end'>{formatarDinheiro((Number(historico.quantidade)*Number(historico.Produto?.preco)||0))}</td>
                    </tr>
                })}
                <tr className='fw-bold text-center'>
                    <td>Total</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className='text-center'>{quantidadeTotal}</td>
                    <td></td>
                    <td className='text-end'>{formatarDinheiro(valorTotal)}</td>
                </tr>
            </tbody>
        </Table>
    </Card>
  )
}
