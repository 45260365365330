import React from "react";
import { Routes, Route } from "react-router-dom";
import NotasFiscaisCompra from "./NotasFiscaisCompra";
import NotaFiscalCompra from "./NotaFiscalCompra";
import { getInicioAno, getFinalAno } from "misc/lhdatas";

export default function NotaFiscalCompraIndex() {
  const [FilialId, setFilialId] = React.useState(0);
  const [dataIni, setDataIni] = React.useState(getInicioAno().toISOString().split("T")[0]);
  const [dataEnd, setDataEnd] = React.useState(getFinalAno().toISOString().split("T")[0]);

  return (
    <Routes>
      <Route path={`/:id`} element={<NotaFiscalCompra />} />
      <Route
        path={`/`}
        element={
          <NotasFiscaisCompra
            dataIni={dataIni}
            setDataIni={setDataIni}
            dataEnd={dataEnd}
            setDataEnd={setDataEnd}
            FilialId={FilialId}
            setFilialId={setFilialId}
          />
        }
      />
    </Routes>
  );
}
