import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import TituloContext from "../contextos/TituloContext";
import { useNavigate } from "react-router-dom";

export default function CabecalhoCadastro({ titulo, id, salvar = () => {}, disabled, cabecalho = "" }) {
  const { setTitulo } = React.useContext(TituloContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (titulo) {
      setTitulo(`${titulo} [${isNaN(id) ? "Novo" : id}]`);
    }
  }, [setTitulo, titulo, id]);

  return (
    <Card.Header className="bg-primary text-light">
      <h3 className="float-start">{cabecalho ? cabecalho : isNaN(id) ? "Cadastro" : "Alteração"}</h3>

      <div className="float-end">
        <Button
          disabled={disabled}
          className="me-2"
          variant="warning"
          onClick={() => {
            navigate(-1);
          }}
        >
          Cancelar
        </Button>

        <Button type="submit" disabled={disabled} variant="success" onClick={salvar}>
          Salvar
        </Button>
      </div>
    </Card.Header>
  );
}
