import React from "react";
import { Routes, Route } from "react-router-dom";

import Legacy from "./legacy/Legacy";
import Dashboard from "./dashboard/dashboard";
import DashBoardComercial from "./dashboard_comercial/DashboardComercial";
import DashboardsPowerBI from "./dashboard/powerbi";
import Cadastros from "./cadastros";
import Estoque from "./estoque";
import Almoxarifados from "./almoxarifados";
import Financeiro from "./financeiro";
import AgendaTecnica from "./agenda_tecnica";
import Sistema from "./sistema";
import Relatorios from "./relatorios";
import RedeInfra from "./redeinfra";
import Contratos from "./contratos";
import ContratoPorTelefone from "./contratos/ContratoPorTelefone";
import OtimizacaoRota from "./otimizacao_rota/OtimizacaoRota";
import MarketingIndex from "./marketing";

const Monitor = React.lazy(() => import("./monitor/Monitor"));
const Loader = () => <p>Carregando...</p>;

export default function RouterPrincipal() {
  return (
    <Routes>
      <Route path="/lgc/:form" element={<Legacy />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/dashboard/comercial" element={<DashBoardComercial />} />
      <Route path="/dashboard/powerbi/*" element={<DashboardsPowerBI />} />
      <Route path="/contratos/*" element={<Contratos />} />
      <Route path="/contrato_por_tel/:telefone" element={<ContratoPorTelefone />} />
      <Route path="/estoque/*" element={<Estoque />} />
      <Route path="/almoxarifados/*" element={<Almoxarifados />} />
      <Route path="/cadastros/*" element={<Cadastros />} />
      <Route path="/agenda_tecnica/*" element={<AgendaTecnica />} />
      <Route path="/marketing/*" element={<MarketingIndex />} />
      <Route
        path="/monitor/*"
        element={
          <React.Suspense fallback={<Loader />}>
            <Monitor />
          </React.Suspense>
        }
      />
      <Route path="/financeiro/*" element={<Financeiro />} />
      <Route path="/sistema/*" element={<Sistema />} />
      <Route path="/relatorios/*" element={<Relatorios />} />
      <Route path="/redeinfra/*" element={<RedeInfra />} />
      <Route path="/otimizacao_rota" element={<OtimizacaoRota />} />
      {/* <Route path="/master" element={<Master />} />
        <Route path="/impressao" element={<Impressao />} /> */}

      <Route path="*" element={<Dashboard />} />
    </Routes>
  );
}
