import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

export default function MovimentacaoAvulsaPag1({ onClick }) {

    return <Card.Body>
        <Row className="justify-content-md-center">
            <Col sm={4}>
                <Card onClick={() => {
                    onClick('E');
                }} className="text-center">
                    <FontAwesomeIcon icon={faPlus} size="6x" color="green" className="align-self-center" />
                    <h1>Entrada</h1>
                </Card>
            </Col>

            <Col sm={4}>
                <Card onClick={() => {
                    onClick('S');
                }} className="text-center">
                    <FontAwesomeIcon icon={faMinus} size="6x" color="red" className="align-self-center" />
                    <h1>Saída</h1>
                </Card>
            </Col>
        </Row>
    </Card.Body>
}