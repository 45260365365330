import React from "react";
import { Routes, Route } from "react-router-dom";
import FormaDePagamento from "./financeiro/formas_pagamento/FormaDePagamento";
import FormasDePagamento from "./financeiro/formas_pagamento/FormasDePagamento";
import Despesas from "./financeiro/despesas";
import ContaBancaria from "./financeiro/contas_bancarias/ContaBancaria";
import ContasBancarias from "./financeiro/contas_bancarias/ContasBancarias";
import CentroDeCusto from "./financeiro/centro_de_custo/CentroDeCusto";
import CentrosDeCusto from "./financeiro/centro_de_custo/CentrosDeCusto";
import Caixas from "./financeiro/caixas";
import Enderecos from "./administrativo/enderecos";
import Feriado from "./administrativo/feriados/Feriado";
import Feriados from "./administrativo/feriados/Feriados";
import Promocoes from "./administrativo/promocoes";
import Filiais from "./administrativo/filiais";
import PreCadastro from "./PreCadastro";
import PreCadastros from "./PreCadastros";
import Categorias from "./financeiro/categorias";
import CadastroProdutosIndex from "./estoque/produtos/CadastroProdutosIndex";
import LHPref from "./lhpref";
import ContaBancariaHistorico from "./financeiro/contas_bancarias/ContaBancariaHistorico";
import MarketingIndex from "./marketing";

export default function CadastrosIndex() {
  return (
    <Routes>
      <Route path={`/pre_cadastros/:id`} element={<PreCadastro />} />
      <Route path={`/pre_cadastros`} element={<PreCadastros />} />

      <Route path={`/administrativo/enderecos/*`} element={<Enderecos />} />
      <Route path={`/administrativo/promocoes/*`} element={<Promocoes />} />

      <Route path={`/administrativo/feriados/:id`} element={<Feriado />} />
      <Route path={`/administrativo/feriados`} element={<Feriados />} />

      <Route path={`/administrativo/filiais/*`} element={<Filiais />} />

      <Route path={`/estoque/produtos*`} element={<CadastroProdutosIndex />} />

      <Route path={`/financeiro/formapagamento/:id`} element={<FormaDePagamento />} />
      <Route path={`/financeiro/formapagamento`} element={<FormasDePagamento />} />
      <Route path={`/financeiro/despesas/*`} element={<Despesas />} />
      <Route path={`/financeiro/contas_bancarias/:id/historico`} element={<ContaBancariaHistorico />} />
      <Route path={`/financeiro/contas_bancarias/:id`} element={<ContaBancaria />} />
      <Route path={`/financeiro/contas_bancarias`} element={<ContasBancarias />} />
      <Route path={`/financeiro/centro_de_custo/:id`} element={<CentroDeCusto />} />
      <Route path={`/financeiro/centro_de_custo`} element={<CentrosDeCusto />} />
      <Route path={`/financeiro/caixas/*`} element={<Caixas />} />
      <Route path={`/financeiro/categorias/*`} element={<Categorias />} />
      <Route path={`/lhpref`} element={<LHPref />} />
      <Route path={`/marketing/*`} element={<MarketingIndex />} />
      <Route path={`*`} element={<div>Cadastros</div>} />
    </Routes>
  );
}
