import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import CampoCpf from 'componentes/CampoCpf';
import CampoTelefone from 'componentes/CampoTelefone';
import BotaoApagar from 'componentes/BotaoApagar';
import SelectFiliais from '../../administrativo/filiais/SelectFiliais';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import IoContext from 'contextos/IoContext';
import { useNavigate } from 'react-router';

export default function Funcionario({ id, funcionario, loading }) {
    const navigate = useNavigate();
    const { socket } = React.useContext(IoContext);
    const [loading2, setLoading2] = React.useState(false);
    const [FilialId, setFilialId] = React.useState('');
    const [nome, setNome] = React.useState('');
    const [doc1, setDoc1] = React.useState('');
    const [doc2, setDoc2] = React.useState('');
    const [telefone1, setTelefone1] = React.useState('');
    const [telefone2, setTelefone2] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [alterado, setAlterado] = React.useState(false);

    const preencherDados = React.useCallback(() => {
        if (funcionario) {
            setFilialId(funcionario.FilialId);
            setNome(funcionario.nome);
            setDoc1(funcionario.doc1);
            setDoc2(funcionario.doc2);
            setTelefone1(funcionario.telefone1);
            setTelefone2(funcionario.telefone2);
            setEmail(funcionario.email);
        } else {
            setFilialId("");
            setNome("");
            setDoc1("");
            setDoc2("");
            setTelefone1("");
            setTelefone2("");
            setEmail("");
        }
    }, [funcionario]);

    React.useEffect(() => {
        preencherDados();
    }, [funcionario, preencherDados]);

    return <Card className="mb-2">
        <Card.Header className="font-weight-bolder bg-primary text-light">
            [{id}] {funcionario ? funcionario.nome : '----'}

            <div className="float-end">
                <Button title="Voltar" variant="light" onClick={() => { navigate(-1); }}>
                    <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                </Button>
            </div>
        </Card.Header>

        <Card.Body>
            <Form onChange={() => {
                if (!alterado) {
                    setAlterado(true);
                }
            }}>
                <Row>
                    <Col sm={3}>
                        <Form.Group>
                            <Form.Label>Id</Form.Label>
                            <Form.Control value={isNaN(id) ? "" : id} readOnly />
                        </Form.Group>
                    </Col>

                    <Col sm={3}>
                        <Form.Label>Filial</Form.Label>
                        <SelectFiliais value={FilialId} onChange={e => setFilialId(e.target.value)} />
                    </Col>
                </Row>

                <Row>
                    <Col sm={6}>
                        <Form.Group>
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                                disabled={loading || loading2} autoFocus maxLength={100}
                                value={nome} onChange={e => setNome(e.target.value)}
                            />
                        </Form.Group>
                    </Col>

                    <Col sm={3}>
                        <Form.Group>
                            <Form.Label>Cpf</Form.Label>
                            <CampoCpf disabled={loading || loading2}
                                value={doc1} onChange={e => setDoc1(e.target.value)}
                            />
                        </Form.Group>
                    </Col>

                    <Col sm={3}>
                        <Form.Group>
                            <Form.Label>RG</Form.Label>
                            <Form.Control disabled={loading || loading2}
                                value={doc2} onChange={e => setDoc2(e.target.value)}
                            />
                        </Form.Group>
                    </Col>

                    <Col sm={3}>
                        <CampoTelefone label='Telefone 1' disabled={loading || loading2}
                            value={telefone1} onChange={e => setTelefone1(e.target.value)}
                        />
                    </Col>

                    <Col sm={3}>
                        <CampoTelefone label="Telefone 2" disabled={loading || loading2}
                            value={telefone2} onChange={e => setTelefone2(e.target.value)}
                        />
                    </Col>

                    <Col sm={6}>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" maxLength={100} disabled={loading || loading2}
                                value={email} onChange={e => setEmail(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        </Card.Body>

        <Card.Footer>
            {isNaN(id) ? false : <BotaoApagar
                disabled={loading || loading2}
                apagar={e => {
                    socket.emit("Funcionario.apagar", { id }, error => {
                        if (error) {
                            toast.error(error);
                        } else {
                            toast.success("Funcionario Removido com Sucesso !");
                        }
                    });
                }}
            />
            }

            {alterado ?
                <div className="float-end">
                    {isNaN(id) ? false :
                        <Button variant="warning me-2" onClick={() => {
                            preencherDados();
                            setAlterado(false);
                        }}>Cancelar</Button>}

                    <Button onClick={() => {
                        setLoading2(true);
                        socket.emit("Funcionario.salvar", {
                            id, FilialId, nome,
                            doc1, doc2,
                            telefone1, telefone2, email,
                        }, (error, resp) => {
                            setLoading2(false);
                            setAlterado(false);
                            if (error) {
                                toast.error(error);
                            } else {
                                toast.success("Dados do funcionário gravados com sucesso !");
                            }
                        });
                    }}>Salvar</Button>
                </div>
                : false}
        </Card.Footer>
    </Card>
}