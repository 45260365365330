import React from 'react';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import IoContext from '../../../../contextos/IoContext';


export default function SelectFuncionario(props) {
    const { socket } = React.useContext(IoContext);
    const { id, value, onChange, disabled, pular } = props;
    const [funcionarios, setFuncionarios] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        socket.emit("Funcionario.findAll", {
            attributes: ['id', 'nome'],
            order: [["NOME", "ASC"]]
        }, (error, resp) => {
            setLoading(false);
            if (error) {
                toast.error(error);
            } else {
                setFuncionarios(resp);
            }
        })
    }, [socket]);

    return <Form.Control as="select"
        id={id || "FuncionarioId"}
        onChange={onChange}
        value={value}
        disabled={loading || disabled}
        {...props}
    >
        <option value=""></option>
        {(funcionarios || []).map(funcionario => {
            if (pular) {
                if (Array.isArray(pular)) {
                    if (pular.indexOf(funcionario.id)) {
                        return false;
                    }
                } else {
                    if (+pular === +funcionario.id) {
                        return false;
                    }
                }
            }
            return <option key={funcionario.id} value={funcionario.id}>{funcionario.nome}</option>
        })}
    </Form.Control>;
}