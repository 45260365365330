import React from "react";
import FormularioListagem from "../../../../componentes/FormularioListagem";
import { toast } from "react-toastify";
import IoContext from "../../../../contextos/IoContext";
import { useNavigate, useParams } from "react-router";

export default function ContaBancariaHistorico() {
  const navigate = useNavigate();
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(false);
  const [pagina, setPagina] = React.useState(1);
  const [texto, setTexto] = React.useState("");
  const [historicos, setHistoricos] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const params = useParams();
  const id = Number.parseInt(params.id);

  React.useEffect(() => {
    let where = {};

    if (texto) where.titular_like = `%${texto}%`;

    setLoading(true);
    socket.emit(
      "HistoricoContaBancaria.findAndCountAll",
      {
        attributes: ["id"],
        where: {
          ContaBancariaId: id,
        },
        limit: 10,
        offset: 10 * (pagina - 1),
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          toast.error(error);
        } else {
          setTotal(resp.count);
          setHistoricos(resp.rows);
        }
      }
    );
  }, [socket, texto, pagina, id]);

  return (
    <FormularioListagem
      titulo={`Histórico de Conta Bancária ${id}`}
      itens={historicos}
      qtdItens={total}
      loading={loading}
      pagina={pagina}
      setTexto={setTexto}
      setPagina={setPagina}
      titulos={["Id", "Banco", "Titular", "Cnpj", "Agência / Conta", "Cedente"]}
      tamanhos={[
        { sm: 12, md: 1 },
        { sm: 12, md: 2 },
        { sm: 12, md: 4 },
        { sm: 12, md: 2 },
        { sm: 12, md: 2 },
        { sm: 12, md: 1 },
      ]}
      onClick={(e) => {
        navigate(`${e.id}`);
      }}
      renderItem={(titulo, item) => {
        switch (titulo) {
          case "Id":
            return item.id;
          case "Banco":
            return item.CarteiraCobranca ? item.CarteiraCobranca.banco : "----";
          case "Titular":
            return item.titular;
          case "Cnpj":
            return item.cnpj;
          case "Agência / Conta":
            return item.agencia ? `${item.agencia}/${item.conta}` : "----";
          case "Cedente":
            return item.cedente;
          default:
            return titulo;
        }
      }}
    />
  );
}
