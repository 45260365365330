import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import AuthContext from "../../contextos/AuthContext";
import TituloContext from "../../contextos/TituloContext";
import MenuContext from "../../contextos/MenuContext";
import AutoComplete from "../../componentes/AutoComplete";

export default function LHNavbar({ sidebarOpen, setSidebarOpen }) {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = React.useState(false);
  const { usuario, setUsuario, setTokenValido } = React.useContext(AuthContext);
  const { titulo } = React.useContext(TituloContext);
  const { menus } = React.useContext(MenuContext);
  const [texto, setTexto] = React.useState("");
  const [itens, setItens] = React.useState([]);
  const [arrayMenus, setArrayMenus] = React.useState([]);

  React.useEffect(() => {
    let arrayMenus = [];

    const processaMenu = (m, titulo) => {
      if (m.formulario) {
        arrayMenus.push({
          id: m.id,
          texto: m.nome,
          formulario: m.formulario,
          titulo: titulo,
          ...m,
        });
      }

      if (m.submenus) {
        m.submenus.forEach((sb) => {
          processaMenu(sb, titulo ? `${titulo} > ${m.nome}` : m.nome);
        });
      }
    };

    (menus || []).forEach((m) => {
      processaMenu(m);
    });

    setArrayMenus(arrayMenus);
  }, [menus, itens, setItens]);

  return (
    <Navbar bg="dark" variant="dark" fixed="top" expand={false} className="ps-2 pe-2 d-print-none">
      <Navbar.Toggle
        className="me-3"
        title={`${sidebarOpen ? "Fechar" : "Abrir"} Menu`}
        onClick={() => setSidebarOpen(!sidebarOpen)}
      />

      <Navbar.Brand className="text-light d-none d-sm-inline overflow-hidden me-auto" style={{ maxWidth: "60%" }}>
        {titulo}
      </Navbar.Brand>

      <div className="me-2 position-relative" style={{ maxWidth: "60%" }}>
        <AutoComplete
          keyText="F3" // F3
          placeholder="[F3] Pesquisa"
          value={texto}
          itens={arrayMenus}
          className="d-print-none"
          onChange={(e) => {
            setTexto(e.target.value);
          }}
          onClick={(m) => {
            if (m.legado) {
              navigate(`${m.legado ? "lgc/" : ""}${m.formulario.replace(/[/]/g, "|")}`);
            } else {
              if (m.formulario.startsWith("/")) {
                navigate(`${m.formulario}`);
              } else {
                navigate(`${m.formulario}`);
              }
            }

            setTexto("");
          }}
        />
      </div>

      <Dropdown show={showMenu} className="d-print-none">
        <Button onClick={() => setShowMenu(!showMenu)}>
          <span className="d-none d-sm-inline me-2">{usuario ? usuario.nome : "Usuário"}</span>
          <FontAwesomeIcon icon={faEllipsisV} />
        </Button>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              navigate("/sistema/ajuda/tickets");
              setShowMenu(false);
            }}
          >
            Tickets
          </Dropdown.Item>

          <Dropdown.Item
            onClick={(e) => {
              setUsuario(null);
              setTokenValido(null);
            }}
          >
            Sair
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Navbar>
  );
}
