import React from 'react';
import MenuContext from './MenuContext';

export default function MenuProvider(props){
    const [menus, setMenus] = React.useState([]);

    return <MenuContext.Provider value={{
        menus, setMenus
      }}>
          {props.children}
    </MenuContext.Provider>;
}