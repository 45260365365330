import React from "react";
import Form from "react-bootstrap/Form";

export default function SelectOperadoraTelefonia(props) {
  const { id, value, onChange, disabled } = props;

  return (
    <Form.Control
      as="select"
      id={id || "operadoraTelefonia"}
      onChange={onChange}
      value={value}
      disabled={disabled}
      {...props}
    >
      <option value=""></option>
      {Object.keys(operadoras).map((codigo) => {
        return (
          <option key={codigo} value={codigo}>
            {operadoras[codigo]}
          </option>
        );
      })}
    </Form.Control>
  );
}

// https://www.inforsolutions.com.br/ticket_sol/knowledgebase.php?article=4
export const operadoras = {
  55101: "TELECALL BRASIL SERVIÇOS DE TELECOMUNICAÇÕES LTDA",
  55102: "LIFE SERVIÇOS DE COMUNICAÇÃO MULTIMÍDIA LTDA",
  55103: "DIGITAL DESIGN - SEVIÇOS DE INFORMÁTICA LTDA - DIPELNET",
  55104: "DIOGENES BAYDE IMPORTADORA E EXPORTADORA LTDA",
  55105: "TPA INFORMÁTICA",
  55106: "AMERICA NET LTDA",
  55107: "VIPNET BAIXADA TELECOMUNICAÇÕES E INFORMÁTICA LTDA",
  55108: "GLOBAL OSI BRASIL TELECOMUNICAÇÕES E CONECTIVIDADE LTDA",
  55109: "MAHA-TEL TELECOMUNICAÇÕES LTDA.",
  55110: "ENCANTO TELECOM COM. E SERV. EM TELECOMUNICAÇÕES LTDA",
  55111: "IBITURUNA TV POR ASSINATURA LTDA.",
  55112: "CIA TELECOMUNICAÇÕES DO BRASIL CENTRAL",
  55113: "FONAR TELECOM",
  55114: "BRASIL TELECOM",
  55115: "TELESP",
  55116: "FIDELITY TELECOMUNICAÇÕES E MULTIMÍDIA S.A.",
  55117: "TRANSIT",
  55118: "SPIN TELECOMUNICAÇÕES LTDA",
  55119: "VISÃONET TELECOM LTDA",
  55120: "GTI TELECOMUNICAÇÕES LTDA",
  55121: "EMBRATEL",
  55122: "ITAVOICE SERVIÇOS DE TELECOMUNICAÇÕES LTDA",
  55123: "INTELIG TELECOMUNICACOES LTDA",
  55124: "DIALDATA TELECOMUNICAÇÕES LTDA",
  55125: "GLOBAL VILLAGE TELECOM",
  55126: "IDT",
  55127: "AEROTECH TELECOMUNICACOES LTDA",
  55128: "SIMTERNET TECNOLOGIA DA INFORMAÇÃO LTDA",
  55129: "T-LESTE TELECOMUNICAÇÕES LESTE DE SÃO PAULO LTDA",
  55130: "WKVE ASSESSORIA EM SERVIÇOS DE INFORMÁTICA E TELECOMUNICAÇÕES LTDA",
  55131: "TELEMAR/SE",
  55132: "CONVERGIA",
  55133: "NETWORLD PROVEDOR E SERVIÇO DE INTERNET LTDA",
  55134: "ETML - EMPRESA DE TELEFÔNIA MULTIUSUÁRIO LTDA",
  55135: "EASYTONE",
  55136: "DSLI",
  55137: "GOLDEN LINE TELECOM LTDA",
  55138: "TELECOM SOUTH AMERICA S.A.",
  55139: "NETGLOBALIS TELECOMUNICAÇÕES DO BRASIL LTDA",
  55140: "HOJE SISTEMAS DE INFORMÁTICA LTDA",
  55141: "TIM CELULAR S.A. (REGIÃO III)",
  55142: "GT GROUP INTERNATIONAL BRASIL TELECOMUNICAÇÕES LTDA",
  55143: "SERCOMTEL",
  55144: "IPÊ INFORMÁTICA LTDA",
  55145: "GLOBAL CROSSING COMUNICACOES DO BRASIL LTDA.",
  55146: "AMIGO TELECOMUNICAÇÕES LTDA",
  55147: "BT COMMUNICATIONS DO BRASIL LTDA",
  55148: "AUE PROVEDOR DE INTERNET LTDA",
  55149: "BBS OPTIONS",
  55150: "CAMBRIDGE TELECOMUNICAÇÕES LTDA",
  55151: "LAFAIETE PROVEDOR DE INTERNET E TELECOMUNICAÇÕES LTDA",
  55152: "CABO SERVIÇOS DE TELECOMUNICAÇÕES LTDA",
  55153: "OSTARA TELECOMUNICACOES LTDA.",
  55154: "G30 TELECOM SERVIÇOS DE TELECOMUNICAÇÕES LTDA",
  55155: "ESPAS TELECOMUNICAÇÕES LTAD",
  55156: "ENGEVOX",
  55157: "BR GROUP TELECOMUNICAÇÕES LTDA",
  55158: "STELLAR S.A",
  55159: "LOCAL",
  55160: "CGB VOIP INFORMÁTICA E COMUNICAÇÃO LTDA",
  55161: "NEXUS TELECOM",
  55162: "MUNDIVOX",
  55163: "HELLO BRAZIL TELECOMUNICACOES LTDA",
  55164: "REMOTA",
  55165: "RN BRASIL - SERVIÇOS DE PROVEDORES LTDA",
  55167: "SIGNALLINK INFORMATICA LTDA",
  55168: "SUPORTE",
  55169: "REDEVOX",
  55170: "LOCAWEB TELECOM TELECOMUNICAÇÕES LTDA",
  55171: "TELECOMUNICAÇÕES DOLLARPHONE DO BRASIL LTDA",
  55172: "TELECOMDADOS SERVIÇOS LTDA - ME",
  55173: "ALPHA",
  55174: "TELEFREE",
  55175: "TMAIS",
  55176: "ADYL NET ACESSO A INTERNET LTDA",
  55177: "OPÇÃONET INFORMÁTICA LTDA",
  55178: "NORTELPA",
  55179: "OTS - OPTION TELECOM SERVIÇOS DE TELECOMUNICAÇÕES LTDA",
  55180: "PLUMIUM COMUNICAÇÃO E MARKETING LTDA.",
  55181: "SERMATEL",
  55182: "RCR ENGENHARIA LTDA",
  55183: "SDW TECNOLOGIA E TELECOMUNICAÇÕES LTDA",
  55184: "METROWEB TELECOMUNICAÇÕES LTDA",
  55185: "ULTRANET TELECOMUNICAÇÕES LTDA",
  55186: "TELETEL CALLIP TELECOMUNICAÇÕES LTDA",
  55187: "R S T SERVIÇOS DE TELECOMUNICAÇÕES LTDA",
  55188: "TINERHIR",
  55189: "CONECTA TELECOMUNICACOES S.A.",
  55190: "VIA TELECOM INFORMÁTICA E TELECOMUNICAÇÕES LTDA",
  55191: "FALKLAND TECNOLOGIA EM TELECOMUNICAÇÕES LTDA",
  55192: "VIACOM NEXT GENERATION",
  55193: "VIPWAY SERVIÇOS DE TELECOMUNICAÇÕES LTDA",
  55194: "SMART VOIP TELECOMUNICAÇÕES LTDA",
  55195: "NEBRACAM - CAMARA BRASILEIRA DE NEGÓCIOS",
  55196: "IVATI INOVAÇÃO E INTERATIVIDADE S/A",
  55197: "EAD - ENSINO À DISTÂNCIA LTDA",
  55198: "LIGUE TELECOMUNICAÇÕES LTDA",
  55199: "ENSITE BRASIL TELECOMUNICAÇÕES LTDA",
  55200: "PORTO VELHO TELECOMUNICAÇÕES LTDA",
  55201: "HIT TELECOMUNIAÇÕES LTDA",
  55202: "DIGI SOLUÇÕES DE COMUNICAÇÃO LTDA",
  55203: "CIA ITABIRANA DE TELECOMUNICAÇÕES LTDA",
  55204: "GRUPO G1 TELECOMUNICAÇÕES LTDA",
  55205: "AVA TELECOMUNICAÇÕES LTDA",
  55206: "DESKTOP SIGMANET COMUNICAÇÃO MULTIMÍDIA LTDA",
  55207: "NEOLINK TELECOMUNICAÇÕES LTDA",
  55208: "BMBB SERVIÇOS DE COMUNICAÇÃO LTDA",
  55209: "WIRELESS COMM SERVICES LTDA",
  55210: "MORANGO TELECOMUNICAÇÕES LTDA",
  55211: "UNIVERSO SERVIÇOS DE TELECOMUNICAÇÕES LTDA",
  55212: "VOXBONE BRAZIL TELECOMUNICAÇÕES LTDA",
  55213: "CARVALHAES INFORMÁTICA LTDA ME",
  55214: "EQUANT BRASIL LTDA",
  55215: "TELECOMUNICAÇÕES DE SÃO PAULO S.A - TELESP - REGIÕES I E II",
  55216: "AMPERNET TELECOMUNICAÇÕES LTDA",
  55217: "TERAPAR PARTICIPAÇÕES LTDA",
  55218: "GRANDI SISTEMAS DE INFORMAÇÕES LTDA",
  55219: "POWERSAT SERVIÇOS DE TELECOMUNICAÇÕES",
  55220: "SUPERIMAGEM TECNOLOGIA EM ELETRÔNICA LTDA",
  55221: "GGNET TELECOMUNICAÇÕES LTDA",
  55222: "ADP3 TEELCOMUNICAÇÕES LTDA",
  55223: "CITTÀ TELECOM LTDA",
  55224: "SUL AMERICANA SERVIÇO DE TELEFONIA LTDA",
  55225: "A. P. OLIVEIRA",
  55226: "UNIVERSAL TELECOM S.A",
  55227: "SITECNET INFORMÁTICA LTDA -ME",
  55228: "GLOBAL TELECOM EIRELI",
  55229: "JOSÉ FELIPE GORNISKI",
  55231: "BRASILFONE COMUNICAÇÃO LTDA - ME",
  55232: "BCMG INTERNET LTDA",
  55233: "S.O. DO BRASIL TELECOMUNICAÇÕES LTDA-EPP",
  55234: "CORDIA COMUNICAÇÕES S/A",
  55235: "BRASIL DIGITAL TELECOMUNICAÇÕES LTDA",
  55236: "CONECTA S J DEL-REI LTDA",
  55237: "MARCELO MOREIRA DIONÍSIO GOIÁS TELECOM",
  55238: "MHNET EMPREENDIMENTOS LTDA",
  55239: "NOGUEIRA",
  55240: "PREDLINK REDE DE TELECOMUNICAÇÕES LTDA",
  55241: "INFORMÁTICA ITAPIRANGA LTDA EPP",
  55243: "OTOGROUP SERVIÇOS DE TELECOMUNICAÇÕES LTDA",
  55244: "WIRELESSCONECTION SERVIÇOS DE TELECOMUNICAÇÕES LTDA - ME",
  55245: "TOQUE TELECOMUNICAÇÕES LTDA- ME",
  55246: "NEOTELECOM TELECOMUNICAÇÕES LTDA",
  55247: "RED TELECOM EIRELI",
  55248: "YIP TELECOMUNICAÇÕES LTDA",
  55249: "VOCE TELECOMUNICAÇÕES LTDA",
  55250: "LINKTEL TELECOMUNICAÇÕES DO BRASIL LTDA",
  55251: "HOINASKY E SKLASKY LTDA - ME",
  55252: "BLUE TELECOMUNICAÇÕES LTDA",
  55253: "NETWORK TELECOMUNICAÇÕES LTDA-EPP",
  55254: "BRASREDE TELECOMUNICAÇÕES LTDA",
  55255: "OXMAN TECNOLOGIA LTDA",
  55256: "NOVA TECNOLOGIA E TELECOMUNICAÇÕES S/A",
  55257: "DIRETA COMUNICAÇÕES LTDA - ME",
  55258: "MIGTEL TELECOMUNICAÇÕES LTDA",
  55259: "SOTHIS TECNOLOGIA E SERVIÇOS DE TELECOMUNICAÇÕES LTDA",
  55260: "KLISA COMUNICAÇÃO E MULTIMÍDIA LTDA",
  55261: "EMPIRE SERVIÇOS DE INTERNET EIRELI - EPP",
  55262: "SILVA E GUEDES SOLUÇÕES EM TI LTDA",
  55263: "MD BRASIL TELECOMUNICAÇÕES LTDA",
  55264: "NGT TELECOMUNICAÇÕES LTDA",
  55265: "QNET TELECOM LTDA",
  55266: "INFINITUS BRASIL TELECOMUNICAÇÕES LTDA",
  55267: "ORION TELECOMUNICAÇÕES COMÉRCIO E SERVIÇOS LTDA - EPP",
  55268: "NIPTELECOM TELECOMUNICAÇÕES EIRELI - EPP",
  55269: "CONNECTRONIC SERVIÇOS LTDA - EPP",
  55270: "MUNDO TELECOMUNICAÇÕES E INFORMÁTICA LTDA",
  55271: "SPEED TRAVEL COMUNICAÇÃO MULTIMÍDIA LTDA",
  55272: "PAK TELECOMUNICAÇÕES E TECNOLOGIA DA INFORMAÇÃO EIRELI - EPP",
  55273: "PROCESS TELECOM TELEFONIA E INTERNET LTDA",
  55274: "BRAZILIAN TELECOMUNICAÇÕES LTDA - ME",
  55275: "HIGH CONNECT - REDES ELÉTRICAS E DE TELECOMUNICAÇÕES LTDA - EPP",
  55276: "HORIZONS TELECOMUNICAÇÕES E TECNOLOGIA LTDA",
  55277: "GENTE TELECOM DO BRASIL EIRELI ME",
  55278: "BITCOM PROVEDOR DE SERVIÇOS DE INTERNET LTDA",
  55279: "M.I. INTERNET LTDA",
  55280: "NT2 TELECOMUNICAÇÕES LTDA ME",
  55281: "CPNET COMÉRICIO E SERVIÇOS DE TELECOMUNICAÇÕES LTDA",
  55282: "ANDRADE",
  55283: "ROCKETNET SERVIÇOS DE COMUNICAÇÃO MULTIMÍDIA LTDA",
  55284: "C-LIGUE TELECOMUNICAÇÕES LTDA",
  55285: "ALPHA TELECOMUNICAÇÕES LIDA. ME",
  55286: "NEXT TELECOMUNICAÇÕES DO BRASIL LTDA - ME",
  55287: "ZILIONET TELECOMUNICAÇÕES LTDA - EPP",
  55288: "BULL BLACK TECHNOLOGY LTDA ME",
  55289: "FIBER VOX TELECOMUNICAÇÕES DO BRASIL EIRELI",
  55290: "LIZITEC INFORMÁTICA LTDA - ME",
  55291: "CATAGUASES NET LTDA - ME",
  55292: "GIGALINK DE NOVA FRIBURGO SOLUÇÕES EM REDE MULTIMÍDIA LTDA.",
  55293: "VIP BR TELECOM LTDA ME",
  55294: "IVELOZ TELECOM SERVIÇOS EM TELECOMUNICAÇÕES LTDA - EPP",
  55295: "BALDUSSI SOLUÇÕES LTDA - ME",
  55296: "GLOBAL LINES NETWORK TELECOMUNICAÇÕES DO BRASIL LTDA",
  55297: "SOFTDADOS TELECOMUNICAÇÕES LTDA - ME",
  55298: "ADVANCE TELECOMUNICAÇÕES LTDA",
  55299: "J.A.S - TELECOMUNICAÇÕES LTDA - ME",
  55300: "TW-SOLUTIONS TELÊE0.IIY,NIGÂ,ÇÔES EIRELI AMI",
  55301: "DATORA TELECOMUNICAÇÕES LTDA",
  55302: "PORTO SEGURO TELECOMUNICAÇÕES S.A.",
  55303: "SISTEER DO BRASIL TELECOMUNICAÇÕES LTDA",
  55304: "TERAPAR PARTICIPAÇÕES LTDA",
  55305: "VIRGIN MOBILE TELECOMUNICAÇÕES LTDA",
  55306: "EUTV CONSULTORIA E INTERMEDIAÇÃO DE NEGÓCIOS S.A.",
  55307: "AMÉRICA NET LTDA",
  55308: "LIGUE TELECOMUNICAÇÕES LTDA",
  55309: "TPA TELECOMUNICAÇÕES LTDA",
  55312: "CTBC CELULAR S.A.",
  55314: "14 BRASILTELECOM - (PR E SC)",
  55320: "VIVO S.A.",
  55321: "BCP S.A.",
  55323: "TELEMIG CELULAR S.A.",
  55324: "AMAZONIA CELULAR S.A. (PA)",
  55331: "TNL PCS S.A.",
  55337: "UNICEL",
  55341: "TIM NORDESTE S.A. - MG",
  55343: "SERCOMTEL CELULAR S.A.",
  55349: "OPTIONS COMP & ELET LTDA",
  55351: "NEXTEL SERVIÇOS DE TELECOMUNICAÇÕES LTDA.",
  55375: "SUPERCHIP TELECOMUNICAÇÕES LTDA",
  55377: "NEXTEL",
  55390: "TELCOM TELECOMUNICACOES DO BRASIL LTDA",
  55391: "FALKLAND TECNOLOGIA EM TELECOMUNICAÇÕES LTDA",
  55392: "IRIDIUM SUDAMÉRICA - BRASIL S/A",
  55393: "GLOBALSTAR DO BRASIL S/A",
  55800: "TW - SOLUTIONS TELECOMUNICAÇÕES EIRELI ME",
  55801: "BY AIR TELECOM LTDA - EPP",
  55802: "COMPUSERVICE EMPREENDIMENTOS LTDA",
  55803: "STARWEB TELECOMUNICAÇÕES LTDA",
  55804: "NEW GROUP TELECOMUNICAÇÕES LIDA - ME",
  55805: "GSCOM TECNOLOGIA E SERVIÇOS EM TELECOMUNICAÇÃO LTDA",
  55806: "MINUTES4ALL TELECOMUNICAÇÕES LTDA",
  55807: "PALMASNET PARIS LTDA ME",
  55808: "SAMPAIO E SAMPAIO PROVEDORES DE INTERNET",
  55809: "MAURICIO DE TOFFOL BOCH",
  55810: "SOLUÇÃO NETWORK PROVEDOR EIRELI - ME",
  55811: "V.COMASSETTO E CIA LTDA",
  55812: "ACESSO TOTAL COMERCIO, INTERNET E SERVIÇOS - EPP",
  55813: "KATER TELECOMUNICAÇÕES LTDA - ME",
  55814: "VMAX-NET TELECOMUNICAÇÕES DO BRASIL LTDA - EPP",
  55815: "ONE WORLD TELECOMUNICAÇÕES EIRELI ME",
  55816: "INTERIP TECNOLOGIA LTDA - EPP",
  55817: "COPREL TELECOM LTDA",
  55818: "EQUATORIAL TELECOMUNICAÇÕES LTDA",
  55819: "FRB TELECOMUNICAÇÕES - EIRELI - ME",
  55820: "FORTEL FORTALEZA TELECOMUNICAÇÕES LTDA - EPP",
  55821: "MELO TELECOMUNICAÇÕES LTDA",
  55822: "GIGANET SERVIÇOS DE INTERNET - LTDA",
  55823: "SERCOMPE DATACENTER LTDA.",
  55824: "SI TELEINFORMÁTICA CONS. ASS. INF. E TELECOMUNICAÇÕES LTDA",
  55825: "ANGOLA CABLES BRASIL LTDA",
  55826: "REDE CONESUL TELECOMUNICA ÇÕES LTDA ME",
  55827: "FALE MAIS COMUNICAÇÕES LTDA EPP",
  55829: "ZAP BL TELECOMUNICAÇÕES LTDA",
  55830: "CZNET TELECOM LTDA.",
  55831: "PAULO DE TARSO DE CARVALHO BAYMA FILHO - EPP",
  55832: "TECMÍDIAWEB EIRELI - ME",
  55833: "ABC TELECOM - COMUNICAÇÃO LTDA - ME",
  55834: "STARNET TELECOMUNICAÇÕES LTDA - ME",
  55835: "VIVA TELECOMUNICAÇÕES LTDA - ME",
  55836: "NET BOTANIC INTERNET INTELIGENTE LTDA",
  55837: "RBR TELECOM SERVIÇOS DE TELEFONIA LIMITADA",
  55838: "MOBY TELECOM TELECOMUNICAÇÕES EIRELI - ME",
  55839: "HUP TELECOM LTDA,",
  55840: "MAHACELL CELULAR E TELECOMUNICAÇÕES LTDA",
  55841: "NEOREDE TELECOMUNICAÇÕES LTDA",
  55842: "AGIL COMERCIAL DO BRASIL INFORMÁTICA E COMUNICAÇÃO LTDA",
  55843: "SILVIO MARÇAL ORLANDINI ME",
  55844: "HZ TELECOMUNICACOES E INFORMÁTICA LTDA.",
  55845: "DEFFERRARI SOLUCOES EM INTERNET LTDA - EPP",
  55846: "WSP SERVIÇOS DE TELECOMUNICAÇÕES LTDA - ME",
  55847: "FLY LINK LTDA",
  55848: "PONTAL TELECOMUNICAÇÕES LTDA",
  55849: "GUIFAMI INFORMÁTICA LTDA - EPP",
  55850: "TERA FIBER TELECOMUNICAÇÕES LTDA - ME",
  55851: "FLEXUS TELECOM LTDA",
  55852: "BYTEWEB COMUNICAÇÃO MULTIMÍDIA LTDA",
  55853: "MKANET SERVICOS E COMERCIO DE INFORMATICA EIRELI ? EPP",
  55854: "ONNET TELECOMUNICAÇÕES LTDA - ME",
  55855: "AMIGO TELECOMUNICACOES EIRELI ? ME?",
  55856: "FIOS TECNOLOGIA DA INFORMACAO EIRELI - ME",
};
