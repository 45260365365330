import React from 'react';
import Form from 'react-bootstrap/Form';

export default function CampoMoeda(props) {
    const { onChange } = props;
    return <Form.Control
        type="number"
        step="0.01"
        min="0"
        {...props}
        maxLength={10}
        onChange={onChange}
    />
}