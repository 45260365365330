import React from 'react';
import Form from 'react-bootstrap/Form';

export default function CampoContaBancaria(props) {
    const { onChange } = props;
    return <Form.Control
            {...props}
            maxLength={18}
            onChange={ e => {
                const soNumeros = e.target.value.replace(/[^\d]/g, '')
                if(!soNumeros) onChange(soNumeros);

                let conta = '';
                if(soNumeros.length > 1){
                    conta = soNumeros.slice(0, soNumeros.length - 1) + '-' + soNumeros[soNumeros.length - 1];
                } else {
                    conta = soNumeros;
                }

                onChange(conta);
            }}
        />
}