import React from 'react';
import { Routes, Route } from 'react-router-dom';
import OrdensSeparacao from './OrdensSeparacao';
import {
    ORDEM_SEPARACAO_AGUARDANDO_ENTREGA
} from '../../../datatypes/OrdemSeparacaoTypes';
import OrdemSeparacao from './OrdemSeparacao';

export default function OrdensSeparacaoIndex(){
    const [almoxarifadoId, setAlmoxarifadoId] = React.useState("");
    const [dataIni, setDataIni] = React.useState("");
    const [dataEnd, setDataEnd] = React.useState("");
    const [pagina, setPagina] = React.useState(1);
    const [texto, setTexto] = React.useState("");
    const [situacao, setSituacao] = React.useState(ORDEM_SEPARACAO_AGUARDANDO_ENTREGA);
    const [direcaoOrdem, setDirecaoOrdem] = React.useState('desc');

    return <Routes>
        <Route path={`/:id`} element={ <OrdemSeparacao />} />
        <Route path={`/add/:tipo`} element={ <OrdemSeparacao />} />
        <Route path={`/`} element={ <OrdensSeparacao
                texto={texto} setTexto={setTexto} 
                pagina={pagina} setPagina={setPagina}
                almoxarifadoId={almoxarifadoId} setAlmoxarifadoId={setAlmoxarifadoId}
                dataIni={dataIni} setDataIni={setDataIni}
                dataEnd={dataEnd} setDataEnd={setDataEnd}
                situacao={situacao} setSituacao={setSituacao}
                direcaoOrdem={direcaoOrdem} setDirecaoOrdem={setDirecaoOrdem}
                />
            } />
    </Routes>
}