import CampoData from "componentes/CampoData";
import SelectSituacaoNotaFiscal from "componentes/SelectSituacaoNotaFiscal";
import SelectTipoNotaFiscal from "componentes/SelectTipoNotaFiscal";
import { NOTAFISCAL_NORMAL } from "datatypes/NotaFiscalTypes";
import React from "react";
import { Form, Row, Col, Card } from "react-bootstrap";
import ContratoTabLegacy from "./ContratoTabLegacy";
import { formatarDataDbs, getFinalAno, getInicioAno } from "misc/lhdatas";

export default function ContratoTabNF({ count, iframeRef }) {
  const [dini, setDini] = React.useState(formatarDataDbs(getInicioAno()));
  const [dend, setDend] = React.useState(formatarDataDbs(getFinalAno()));
  const [situacao, setSituacao] = React.useState(NOTAFISCAL_NORMAL);
  const [tipo, setTipo] = React.useState("");

  return (
    <Card.Body className="h-100">
      <Row>
        <Col sm={2}>
          <Form.Group>
            <Form.Label>Data Inicial</Form.Label>
            <CampoData value={dini} onChange={(e) => setDini(e.target.value)} />
          </Form.Group>
        </Col>
        <Col sm={2}>
          <Form.Group>
            <Form.Label>Data Final</Form.Label>
            <CampoData value={dend} onChange={(e) => setDend(e.target.value)} />
          </Form.Group>
        </Col>

        <Col sm={4}>
          <Form.Group>
            <Form.Label>Tipo</Form.Label>
            <SelectTipoNotaFiscal value={tipo} onChange={(e) => setTipo(e.target.value)} />
          </Form.Group>
        </Col>

        <Col sm={4}>
          <Form.Group>
            <Form.Label>Situação</Form.Label>
            <SelectSituacaoNotaFiscal value={situacao} onChange={(e) => setSituacao(e.target.value)} />
          </Form.Group>
        </Col>
      </Row>

      <ContratoTabLegacy
        count={count}
        height={90}
        url="form/contratos/tab_nf.php"
        iframeRef={iframeRef}
        params={`&DINI=${dini}&DEND=${dend}&situacao=${situacao}&tipo=${tipo}`}
      />
    </Card.Body>
  );
}
