import FormProcurar from "componentes/FormProcurar";
import React from "react";
import IoContext from "../../contextos/IoContext";
import { toast } from "react-toastify";

export default function FormProcurarEstoque({
  titulo = "Selecionar Produto",
  AlmoxarifadoId,
  onSelect,
  disabled = false,
}) {
  const { socket } = React.useContext(IoContext);
  const [produtos, setProdutos] = React.useState([]);
  const loading = null;

  const getProdutos = React.useCallback(
    (texto) => {
      socket.emit(
        "Estoque.findAll",
        {
          where: {
            AlmoxarifadoId,
            quantidade_gt: 0,
          },
          withLocalAlmoxarifado: {
            attributes: ["descricao"],
          },
          withProduto: {
            attributes: ["nome"],
            where: {
              nome_like: `%${texto}%`,
            },
          },
          limit: 25,
        },
        (error, resp) => {
          if (error) {
            toast.error(error);
          }

          setProdutos(resp);
        }
      );
    },
    [socket, AlmoxarifadoId]
  );

  return (
    <FormProcurar
      titulo={"Selecionar Produto" || titulo}
      disabled={disabled || loading}
      onSelect={onSelect}
      titulos={["Id", "Local", "Produto", "Quantidade"]}
      tamanhos={[{ sm: 2 }, { sm: 2 }, { sm: 6 }, { sm: 2 }]}
      itens={produtos}
      renderItem={(titulo, item, idx) => {
        switch (titulo) {
          case "Id":
            return item.id;
          case "Local":
            return item.LocalAlmoxarifado?.descricao;
          case "Produto":
            return item.Produto?.nome;
          case "Quantidade":
            return item.quantidade;
          default:
            return titulo;
        }
      }}
      onSearch={(txt) => {
        getProdutos(txt);
      }}
    />
  );
}
