import React from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import RouterPrincipal from "../RouterPrincipal";
import IoProvider from "../../contextos/IoProvider";

export default function Principal() {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  return (
    <IoProvider>
      <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div id="lhDivPrincipal" className="h-100 d-flex mt-5 mt-print-0 pt-3">
        <RouterPrincipal />
      </div>
    </IoProvider>
  );
}
