import React from "react";
import IoContext from "contextos/IoContext";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import SelectCentrosDeCusto from "./SelectCentrosDeCusto";
import SelectItensCentroCusto from "./SelectItensCentroCusto";
import { SITUACAO_CONTA_EM_ABERTO } from "datatypes/ContasPagarTypes";

export default function ModalTransferirItemCentroCusto({ show, onHide, count }) {
  const { socket } = React.useContext(IoContext);
  const [CentroCustoOrigemId, setCentroCustoOrigemId] = React.useState(0);
  const [CentroCustoDestinoId, setCentroCustoDestinoId] = React.useState(0);
  const [ItemCentroCustoOrigemId, setItemCentroCustoOrigemId] = React.useState(0);
  const [ItemCentroCustoDestinoId, setItemCentroCustoDestinoId] = React.useState(0);
  const [transferir, setTransferir] = React.useState(false);
  const [, setConsultaRealizada] = React.useState(false);
  const [total, setTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setTotal(0);
    setTransferir(false);
    setCentroCustoOrigemId(0);
    setCentroCustoDestinoId(0);
    setItemCentroCustoOrigemId(0);
    setItemCentroCustoDestinoId(0);
  }, [count]);

  React.useEffect(() => {
    setTotal(0);
    socket.emit(
      "ContaPagar.count",
      {
        where: {
          CentroCustoId: CentroCustoOrigemId,
          ItemCentroCustoId: ItemCentroCustoOrigemId,
          situacao: SITUACAO_CONTA_EM_ABERTO,
        },
      },
      (error, total) => {
        setLoading(false);

        if (error) return toast.error("" + error);

        setTotal(total);
        setConsultaRealizada(true);
        // Se nao tem nenhuma conta, pode transferir sem confirmar
        setTransferir(total <= 0);
      }
    );
  }, [CentroCustoOrigemId, ItemCentroCustoOrigemId, socket]);

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();

          setLoading(true);

          if (transferir) {
            socket.emit(
              "CentroCusto.TransferirItem",
              {
                CentroCustoOrigemId,
                CentroCustoDestinoId,
                ItemCentroCustoOrigemId,
                ItemCentroCustoDestinoId,
              },
              (error) => {
                setLoading(false);

                if (error) return toast.error("" + error);
                toast.success("Transferência Realizada com Sucesso !");
                onHide(false);
              }
            );
          } else {
            toast.warning("Primeiro, Confirme a Tranferência !");
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Transferência de Item de Centro de Custo</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Centro de Custo de Origem</Form.Label>
                <SelectCentrosDeCusto
                  value={CentroCustoOrigemId}
                  onChange={(e) => setCentroCustoOrigemId(e.target.value)}
                  disabled={loading}
                  required
                />
              </Form.Group>
            </Col>

            <Col sm={12}>
              <Form.Group>
                <Form.Label>Item de Origem</Form.Label>
                <SelectItensCentroCusto
                  CentroCustoId={CentroCustoOrigemId}
                  value={ItemCentroCustoOrigemId}
                  onChange={(e) => setItemCentroCustoOrigemId(e.target.value)}
                  disabled={loading}
                  required
                />
              </Form.Group>
            </Col>

            <Col sm={12}>
              <Form.Group>
                <Form.Label>Centro de Custo de Destino</Form.Label>
                <SelectCentrosDeCusto
                  value={CentroCustoDestinoId}
                  onChange={(e) => setCentroCustoDestinoId(e.target.value)}
                  disabled={loading}
                  required
                />
              </Form.Group>
            </Col>

            <Col sm={12}>
              <Form.Group>
                <Form.Label>Item de Destino</Form.Label>
                <SelectItensCentroCusto
                  CentroCustoId={CentroCustoDestinoId}
                  value={ItemCentroCustoDestinoId}
                  onChange={(e) => setItemCentroCustoDestinoId(e.target.value)}
                  disabled={loading}
                  required
                />
              </Form.Group>
            </Col>

            <Col sm={12}>
              <Alert variant="warning" className={`mt-3 ${total > 0 ? "d-block" : "d-none"}`}>
                Existe{total > 1 ? "m" : ""} {total} Conta{total > 1 ? "s" : ""} a Pagar no Item de Centro de Custo
                Selecionado. Todas Serão Transferidas. E esta Operação é Irreversível !!!
              </Alert>
            </Col>

            <Col sm={12}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    className={`${total > 0 && !transferir ? "d-block" : "d-none"}`}
                    variant="warning"
                    disabled={loading}
                    onClick={() => {
                      setTransferir(true);
                    }}
                  >
                    Confirmar Transferência
                  </Button>

                  <Button
                    type="submit"
                    variant="primary"
                    className={`${transferir ? "d-block" : "d-none"}`}
                    disabled={loading}
                  >
                    Transferir
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
      </Form>
    </Modal>
  );
}
