import React from 'react';
import { useNavigate } from 'react-router';
import FormularioListagem from '../../../componentes/FormularioListagem';

export default function TicketsSuporte({
    pagina, setPagina, texto, setTexto
}){
    const navigate = useNavigate();
    const [tickets] = React.useState([]);
    const [total] = React.useState(0);
    // const [loading, setLoading] = React.useState(0);

    return <FormularioListagem
        titulo={`Tickets de Suporte`}
        itens={tickets} qtdItens={total}
        pagina={pagina} setPagina={setPagina}
        setTexto={setTexto} texto={texto} 
        titulos={["Id", "Aberto Por", "Assunto", "Situação"]}
        onClick={(e)=>{
            navigate(`${e.id}`);
        }}
        
        renderItem={ (titulo, item) => {
            switch(titulo) {
                case 'Id': return item.id;
                case 'Aberto Por': return item.abertoPor;
                case 'Assunto': return item.assunto;
                case 'Situação': return `${item.telefone1} ${item.telefone2}`;
                default:
                    return titulo;
            }
        }}
/>
}