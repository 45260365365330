import React from 'react';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import DadosFuncionario from './DadosFuncionario';
import AcessoFuncionario from './AcessoFuncionario';
import { toast } from 'react-toastify';
import IoContext from '../../../../contextos/IoContext';
import { useParams } from 'react-router';

export default function Funcionario(){
    const params = useParams();
    const { socket } = React.useContext(IoContext);
    const id = params.id;
    const [loading, setLoading] = React.useState(false);
    const [funcionario, setFuncionario] = React.useState(null);
    const [modo, setModo] = React.useState("view");

    const getFuncionario = React.useCallback(()=>{
        setLoading(true);
        socket.emit("Funcionario.findOne", {
            attributes: ['id', 'FilialId','nome', 'doc1', 'doc2', 'telefone1', 'telefone2', 'email'],
            where: { id },
            withAcessos: {}
        }, (error, resp) => {
            setLoading(false);
            if(error){
                toast.error(error); 
            } else {
                setFuncionario(resp);
            }
        });
    }, [socket, id])
    
    React.useEffect(()=>{
        getFuncionario();
    }, [id, getFuncionario]);

    const acessos = funcionario ? funcionario.Acessos : [];

    return <Container fluid className="pt-3">
        <DadosFuncionario 
            id={id} funcionario={funcionario}
            loading={loading} />

        <Accordion defaultActiveKey="0">
            { modo === 'view' ? (acessos || []).map ( (acesso, key) => {
                return <AcessoFuncionario 
                            key={key} idx={key} id={acesso.id} PessoaId={id}
                            acesso={acesso}
                            refresh={()=>{
                                setModo('view');
                                getFuncionario();
                            }} />
            }) : 
            <AcessoFuncionario idx={0} id={null} acesso={null} PessoaId={id} refresh={()=>{
                    setModo('view');
                    getFuncionario();
                }}  /> }

            { id ?
            <div className="mt-2 text-right pb-5 pr-4">
                <Button variant="success" 
                    className={`${modo==='view'?'':'d-none'}`}
                    onClick={ () => setModo('novo') }>Novo Acesso</Button>
            </div> : false }
        </Accordion>
    </Container>;
}