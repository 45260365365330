import React from "react";
import CampoData from "../../../componentes/CampoData";
import FormularioListagem from "../../../componentes/FormularioListagem";
import SelectFiliais from "../../cadastros/administrativo/filiais/SelectFiliais";
import IoContext from "../../../contextos/IoContext";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";

export default function NotasFiscaisCompra({ FilialId, setFilialId, dataIni, setDataIni, dataEnd, setDataEnd }) {
  const navigate = useNavigate();
  const { socket } = React.useContext(IoContext);
  const [pagina, setPagina] = React.useState(1);
  const [texto, setTexto] = React.useState("");
  const [notas, setNotas] = React.useState([]);
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    let where = {};
    if (texto) where.numeroSerie_like = `%${texto}%`;
    if (FilialId) where.FilialId = +FilialId;
    if (dataIni && dataEnd) {
      where.dataEmissao_between = [
        new Date(dataIni).toISOString().split("T")[0],
        new Date(dataEnd).toISOString().split("T")[0],
      ];
    }

    socket.emit(
      "NotaFiscalCompra.findAndCountAll",
      {
        attributes: [
          "id",
          "createdAt",
          "dataEmissao",
          "valorTotal",
          "numeroSerie",
          "isMaterialLancado",
          "isContaPagarLancadas",
        ],
        withUsuario: {
          attributes: ["nome"],
        },
        withFornecedor: {
          attributes: ["nome"],
        },
        where,
        limit: 10,
        offset: 10 * (pagina - 1),
      },
      (error, resp) => {
        if (error) {
          toast.error("" + error);
        } else {
          setNotas(resp.rows);
          setTotal(resp.count);
        }
      }
    );
  }, [socket, pagina, texto, FilialId, dataIni, dataEnd]);

  return (
    <FormularioListagem
      titulo={`Notas Fiscais de Compra`}
      itens={notas}
      qtdItens={total}
      pagina={pagina}
      setTexto={setTexto}
      setPagina={setPagina}
      titulos={["Id", "Importada Por", "Data de Emissão", "Fornecedor", "Número de Série", "Valor Total", "Situação"]}
      onClick={(e) => {
        navigate(`${e.id}`);
      }}
      tamanhos={[1, 1, 1, 1, 1, 1, 1]}
      renderItem={(titulo, item) => {
        switch (titulo) {
          case "Id":
            return item.id;
          case "Importada Em":
            return DateTime.fromISO(item.createdAt).toLocaleString(DateTime.DATE_SHORT);
          case "Importada Por":
            const usuario = item.Usuario || {};
            return usuario.nome;
          case "Data de Emissão":
            return DateTime.fromISO(item.dataEmissao).toLocaleString(DateTime.DATE_SHORT);
          case "Número de Série":
            return item.numeroSerie;
          case "Fornecedor":
            const fornecedor = item.Fornecedor || {};
            return fornecedor.nome;
          case "Situação":
            let s = "";
            if (item.isMaterialLancado && item.isContaPagarLancadas) {
              s = "CONCLUIDA";
            } else {
              if (!item.isMaterialLancado) s += "AGUARDANDO MATERIAL\n";
              if (!item.isContaPagarLancadas) s += "AGUARDANDO FINANCEIRO\n";
            }

            return s;
          case "Valor Total":
            return (item.valorTotal || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
          default:
            return titulo;
        }
      }}
      renderFiltros={() => {
        return (
          <React.Fragment>
            <Form.Group>
              <Form.Label>Filial</Form.Label>
              <SelectFiliais value={FilialId} onChange={(e) => setFilialId(e.target.value)} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Data Inicial</Form.Label>
              <CampoData value={dataIni} onChange={(e) => setDataIni(e.target.value)} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Data Final</Form.Label>
              <CampoData value={dataEnd} onChange={(e) => setDataEnd(e.target.value)} />
            </Form.Group>
          </React.Fragment>
        );
      }}
    />
  );
}
