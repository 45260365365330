import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import { toast } from "react-toastify";
import SelectFiliais from "../../administrativo/filiais/SelectFiliais";
import SelectFornecedores from "../../administrativo/fornecedores/SelectFornecedores";
import SelectCentrosDeCusto from "../centro_de_custo/SelectCentrosDeCusto";
import SelectItensCentroCusto from "../centro_de_custo/SelectItensCentroCusto";
import BotaoApagar from "componentes/BotaoApagar";
import CabecalhoCadastro from "componentes/CabecalhoCadastro";
import IoContext from "contextos/IoContext";
import SelectCategoriaFinanceira from "../categorias/SelectCategoriaFinanceira";
import SelectItensCategoriaFinanceira from "../categorias/SelectItensCategoriaFinanceira";
import { useNavigate, useParams } from "react-router";

const dias = [];
for (let i = 1; i <= 31; i++) dias.push(i);

const meses = {
  1: "Janeiro",
  2: "Fevereiro",
  3: "Março",
  4: "Abril",
  5: "Maio",
  6: "Junho",
  7: "Julho",
  8: "Agosto",
  9: "Setembro",
  10: "Outubro",
  11: "Novembro",
  12: "Dezembro",
};

export default function Despesa() {
  const params = useParams();
  const navigate = useNavigate();
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(false);
  const id = parseInt(params.id);
  const [filialId, setFilialId] = React.useState(0);
  const [fornecedorId, setFornecedorId] = React.useState(0);
  const [centroCustoId, setCentroCustoId] = React.useState(0);
  const [ItemCentroCustoId, setItemCentroCustoId] = React.useState(0);
  const [valor, setValor] = React.useState(0);
  const [periodo, setPeriodo] = React.useState("M");
  const [diaVencimento, setDiaVencimento] = React.useState(0);
  const [mesVencimento, setMesVencimento] = React.useState(0);
  const [descricao, setDescricao] = React.useState("");
  const [CategoriaFinanceiraId, setCategoriaFinanceiraId] = React.useState(0);
  const [ItemCategoriaFinanceiraId, setItemCategoriaFinanceiraId] = React.useState(0);

  React.useEffect(() => {
    setLoading(true);
    socket.emit(
      "Despesa.findOne",
      {
        where: { id },
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          toast.error(error);
        } else {
          const despesa = resp || {};
          setFornecedorId(despesa.FornecedorId);
          setFilialId(despesa.FilialId);
          setCentroCustoId(despesa.CentroCustoId || "");
          setDescricao(despesa.descricao);
          setValor(despesa.valor);
          setPeriodo(despesa.periodo);
          setDiaVencimento(despesa.diaVencimento);
          setMesVencimento(despesa.mesVencimento);
          setItemCentroCustoId(despesa.ItemCentroCustoId || "");
          setCategoriaFinanceiraId(despesa.CategoriaFinanceiraId || "");
          setItemCategoriaFinanceiraId(despesa.ItemCategoriaFinanceiraId || "");
        }
      }
    );
  }, [id, socket]);

  return (
    <Container fluid className="pt-3">
      <Card>
        <CabecalhoCadastro
          id={id}
          disabled={loading}
          titulo="Despesa"
          salvar={(e) => {
            if (!filialId) return toast.info("Selecione uma Filial");
            if (!fornecedorId) return toast.info("Selecione um Fornecedor");
            if (!centroCustoId) return toast.info("Selecione um Centro de Custo");
            if (!descricao) return toast.info("Digite a Descrição da Despesa");
            if (!valor) return toast.info("O valor deve sair maior que zero.");

            setLoading(true);
            socket.emit(
              "Despesa.salvar",
              {
                id,
                FilialId: +filialId,
                FornecedorId: +fornecedorId,
                CentroCustoId: +centroCustoId,
                ItemCentroCustoId: +ItemCentroCustoId,
                descricao,
                valor,
                periodo,
                diaVencimento,
                mesVencimento,
                CategoriaFinanceiraId: +CategoriaFinanceiraId,
                ItemCategoriaFinanceiraId: +ItemCategoriaFinanceiraId,
              },
              (error, resp) => {
                setLoading(false);
                if (error) {
                  toast.error(error);
                } else {
                  toast.success("Despesa salva com sucesso !");
                  navigate(-1);
                }
              }
            );
          }}
        />

        <Card.Body>
          <Row>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Id</Form.Label>
                <Form.Control value={isNaN(id) ? "" : id} readOnly />
              </Form.Group>
            </Col>

            <Col sm={9}></Col>

            <Col sm={3}>
              <Form.Group>
                <Form.Label>Filial</Form.Label>
                <SelectFiliais
                  value={filialId}
                  disabled={loading}
                  required={true}
                  onChange={(e) => setFilialId(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group>
                <Form.Label>Fornecedor</Form.Label>
                <SelectFornecedores
                  value={fornecedorId}
                  disabled={loading}
                  required={true}
                  onChange={(e) => setFornecedorId(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group>
                <Form.Label>Centro de Custo</Form.Label>
                <SelectCentrosDeCusto
                  value={centroCustoId}
                  disabled={loading}
                  required={true}
                  onChange={(e) => {
                    setCentroCustoId(e.target.value);
                    setItemCentroCustoId(0);
                  }}
                />
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group>
                <Form.Label>Item do Centro de Custo</Form.Label>
                <SelectItensCentroCusto
                  CentroCustoId={centroCustoId}
                  value={ItemCentroCustoId}
                  disabled={loading}
                  required={true}
                  onChange={(e) => setItemCentroCustoId(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group>
                <Form.Label>Categoria Financeira</Form.Label>
                <SelectCategoriaFinanceira
                  value={CategoriaFinanceiraId}
                  disabled={loading}
                  required={true}
                  onChange={(e) => {
                    setCategoriaFinanceiraId(e.target.value);
                    setItemCategoriaFinanceiraId(0);
                  }}
                />
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group>
                <Form.Label>Item da Categoria Financeira</Form.Label>
                <SelectItensCategoriaFinanceira
                  CategoriaFinanceiraId={CategoriaFinanceiraId}
                  value={ItemCategoriaFinanceiraId}
                  disabled={loading}
                  required={true}
                  onChange={(e) => setItemCategoriaFinanceiraId(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group>
                <Form.Label>Período</Form.Label>
                <div>
                  <Form.Check
                    type="radio"
                    inline
                    value="M"
                    checked={periodo === "M"}
                    label="Mensal"
                    disabled={loading}
                    required={true}
                    onChange={(e) => {
                      setPeriodo("M");
                    }}
                  />

                  <Form.Check
                    type="radio"
                    inline
                    value="A"
                    checked={periodo === "A"}
                    label="Anual"
                    disabled={loading}
                    required={true}
                    onChange={(e) => {
                      setPeriodo("A");
                    }}
                  />
                </div>
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group>
                <Form.Label>Dia de Vencimento</Form.Label>
                <Form.Control
                  as="select"
                  disabled={loading}
                  value={diaVencimento}
                  required={true}
                  onChange={(e) => setDiaVencimento(+e.target.value)}
                >
                  <option></option>
                  {dias.map((d) => {
                    return (
                      <option value={d} key={d}>
                        {d}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group>
                <Form.Label>Mês de Vencimento</Form.Label>
                <Form.Control
                  as="select"
                  disabled={loading || periodo === "M"}
                  value={mesVencimento}
                  onChange={(e) => setMesVencimento(+e.target.value)}
                >
                  <option></option>
                  {Object.keys(meses).map((m) => {
                    return (
                      <option value={m} key={m}>
                        {meses[m]}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group>
                <Form.Label>Valor</Form.Label>
                <Form.Control
                  disabled={loading}
                  type="number"
                  min={0}
                  step={0.01}
                  value={valor}
                  onChange={(e) => setValor(+e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={12}>
              <Form.Group>
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                  disabled={loading}
                  value={descricao}
                  onChange={(e) => setDescricao(e.target.value)}
                  maxLength={100}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>

        <Card.Footer>
          {isNaN(id) ? (
            false
          ) : (
            <BotaoApagar
              disabled={loading}
              apagar={() => {
                setLoading(true);
                socket.emit("Despesa.apagar", { id }, (error, resp) => {
                  setLoading(false);

                  if (error) {
                    toast.error("" + error);
                  } else {
                    toast.success("Despesa salva com sucesso !");
                    navigate(-1);
                  }
                });
              }}
            />
          )}
        </Card.Footer>
      </Card>
    </Container>
  );
}
