import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';

export default function BotaoAdicionar(props) {
    const { onClick, texto } = props;
    return <Button
        className={`btn btn-success float-end ${props.className}`}
        title="Adicionar"
        style={{}}
        onClick={onClick}>
        <FontAwesomeIcon icon={faPlus} className={`${texto ? 'me-2' : ''}`} />
        {texto}
    </Button>
}