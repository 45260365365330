import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import IoContext from 'contextos/IoContext';
import CabecalhoCadastro from 'componentes/CabecalhoCadastro';
import CampoData from 'componentes/CampoData';
import MultiFiliais from '../filiais/MultiFiliais';
import { useParams, useNavigate } from 'react-router-dom';

import Container from 'react-bootstrap/Container';

export default function Promocao(){
    const params = useParams();
    const navigate = useNavigate();
    const { socket } = React.useContext(IoContext);
    const id = isNaN(parseInt(params.id)) ? '' : parseInt(params.id);
    const [loading, setLoading] = React.useState(false);
    const [data_inicial, setDataInicial] = React.useState((new Date()).toISOString().split("T")[0]);
    const [data_final, setDataFinal] = React.useState('');
    const [nome, setNome] = React.useState('');
    const [observacao, setObservacao] = React.useState('');
    const [desconto, setDesconto] = React.useState(0);
    const [tipo_desconto, setTipoDesconto] = React.useState(1); // 1 Percentual , 2 Valor Fixo
    const [isDescontoEmDias, setIsDescontoEmDias] = React.useState(false);
    const [validaPorNMeses, setValidaPorNMeses] = React.useState(0);
    const [Filiais, setFiliais] = React.useState([]);
    

    React.useEffect(function(){
        if(!id) return;

        setLoading(true);
        socket.emit("Promocao.findOne", {
            where: { id },
            withFiliais: {
                attributes: ['id', 'nome']
            }
        }, (error, resp) => {
            setLoading(false);

            if(error){
                toast.error(''+error);
            } else {
                setNome(resp.nome || "");
                setObservacao(resp.observacao || "");
                setDataInicial(resp.data_inicial || "");
                setDataFinal(resp.data_final || "");
                setTipoDesconto(resp.tipo_desconto || 0);
                setDesconto(resp.desconto);
                setIsDescontoEmDias(resp.isDescontoEmDias);
                setValidaPorNMeses(resp.validaPorNMeses);
                setFiliais(resp.Filiais || []);
            }
        });
    }, [id, socket]);

    return <Container fluid className="pt-3">
        <Card>
            <CabecalhoCadastro 
                id={id} disabled={loading} titulo='Promoção'
                salvar={ e => {
                    e.preventDefault();
                    setLoading(true);
                    socket.emit("Promocao.salvar", {
                        id, nome, observacao, data_inicial, data_final, 
                        tipo_desconto, desconto, isDescontoEmDias, validaPorNMeses,
                        Filiais
                    }, (error, resp) =>{
                        setLoading(false);
                        if(error) {
                            toast.error(error);
                        } else {
                            toast.success("Promoção salva com Sucesso !");
                            navigate(-1);
                        }
                    });
                }}
                />

            <Card.Body>
                <Row>   
                    <Col sm={3}>
                        <Form.Group>
                            <Form.Label>Id</Form.Label>
                            <Form.Control  value={+id ? id : ""} readOnly />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <Form.Group>
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                                value={nome}
                                required disabled={loading}
                                onChange={ e => setNome(e.target.value)}
                                 />
                        </Form.Group>
                    </Col>

                    <Col sm={12}>
                        <Form.Group>
                            <Form.Label>Observação Exibida no Boleto</Form.Label>
                            <Form.Control
                                value={observacao} onChange={ e => setObservacao(e.target.value)}
                                required disabled={loading}
                                 />
                        </Form.Group>
                    </Col>
                    

                    <Col sm={2}>
                        <Form.Group>
                            <Form.Label>Disponível a Partir de</Form.Label>
                            <CampoData
                                readOnly={id}
                                value={data_inicial}
                                required disabled={loading}
                                onChange={ e => setDataInicial(e.target.value)}
                                 />
                        </Form.Group>
                    </Col>

                    <Col sm={2}>
                        <Form.Group>
                            <Form.Label>Disponível Até</Form.Label>
                            <CampoData
                                value={data_final}
                                disabled={loading}
                                onChange={ e => setDataFinal(e.target.value)}
                                 />
                        </Form.Group>
                    </Col>

                    <Col sm={2}>
                        <Form.Group>
                            <Form.Label>Validade (meses)</Form.Label>
                            <Form.Control type="number" min={1} step={1}
                                value={validaPorNMeses}
                                disabled={loading}
                                onChange={ e => setValidaPorNMeses(+e.target.value)}
                                 />
                        </Form.Group>
                    </Col>

                    <Col sm={3}>
                        <Form.Group>
                            <Form.Label>Tipo de Desconto</Form.Label>
                            <div>
                                <Form.Check type="radio" id="tipo_desconto" checked={tipo_desconto===1} 
                                    label="Percentual" onChange={()=>{setTipoDesconto(1)}} inline/>
                                <Form.Check type="radio" id="tipo_desconto" checked={tipo_desconto===2} 
                                    label="Fixo" onChange={()=>{setTipoDesconto(2)}} inline />
                            </div>
                        </Form.Group>
                    </Col>

                    <Col sm={3}>
                        <Form.Group>
                            <Form.Label>Valor do Desconto { tipo_desconto ===1 ? "%" : "R$"}</Form.Label>
                            <Form.Control 
                                type='number'
                                min={0} max={100} step={0.01}
                                disabled={loading}
                                value={desconto || 0}
                                onChange={ e => setDesconto(+e.target.value) }
                                />
                        </Form.Group>
                    </Col>

                    <Col sm={6}>
                        <Form.Group>
                            <Form.Label>Opções do Desconto</Form.Label>
                            <div>
                                <Form.Check checked={isDescontoEmDias} onChange={()=>{ setIsDescontoEmDias(!isDescontoEmDias)}} 
                                    label="Desconso aplicável somente se pagamento em dias ?" inline />
                            </div>
                        </Form.Group>
                    </Col>

                    <Col sm={12}>
                        <Card.Body>
                            <Form.Group>
                                <Form.Label>Disponível nas Filiais:</Form.Label>
                                <MultiFiliais filiais={Filiais} setFiliais={setFiliais} />
                            </Form.Group>
                        </Card.Body>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    </Container>
}