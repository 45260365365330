import IoContext from "contextos/IoContext";
import React from "react";
import { toast } from "react-toastify";

export default function useSalvarIntegracao() {
  const { socket } = React.useContext(IoContext);

  return React.useCallback(
    ({ modulo, isAtivo, configuracoes, setLoading = null }) => {
      if (setLoading) setLoading(true);
      socket.emit(
        "Integracao.Salvar",
        {
          modulo,
          configuracoes,
          isAtivo,
        },
        (error) => {
          if (setLoading) setLoading(false);
          if (error) {
            return toast.error(error);
          }
          toast.success("Configurações salvas com sucesso");
        }
      );
    },
    [socket]
  );
}
