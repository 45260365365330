import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import CardGraficoAtendimentosPorCanal from "./CardGraficoAtendimentosPorCanal";
import IoContext from "../../../../contextos/IoContext";
import { toast } from "react-toastify";
import { getFinalMes, getInicioMes } from "misc/lhdatas";
import { Container, Table } from "react-bootstrap";
import { CANAIS_DE_ATENDIMENTO, SUBCANAIS_DE_ATENDIMENTO } from "datatypes/AtendiemntosTypes";

export default function RelatorioAtendimentosPorCanal() {
  const { socket } = React.useContext(IoContext);
  const [contador, setContador] = React.useState(0);
  const [dini, setDini] = React.useState(getInicioMes().toISOString().split("T")[0]);
  const [dend, setDend] = React.useState(getFinalMes().toISOString().split("T")[0]);
  const [loading, setLoading] = React.useState(false);
  const [atendimentos, setAtendimentos] = React.useState([]);

  React.useEffect(() => {
    setAtendimentos([]);
    setLoading(true);
    socket.emit(
      "Atendimento.findAll",
      {
        attributes: ["fn_count(Atendimento.id) as nAtendimentos", "canalDeAtendimento", "subCanalDeAtendimento"],
        group: ["canalDeAtendimento", "subCanalDeAtendimento"],
        order: [["canalDeAtendimento", "desc"]],
        where: {
          data_abertura_between: [dini, dend],
        },
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          return toast.error(error);
        }

        setAtendimentos(resp);
      }
    );
  }, [socket, dini, dend, contador]);

  return (
    <Container fluid>
      <Card className="shadow">
        <Card.Header className="bg-primary text-light">
          <h3 className="float-start">Relatório de Atendimentos por Canal</h3>
        </Card.Header>

        <Card.Body className="d-print-none">
          <Row>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>Data do Atendimento</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="date"
                    value={dini}
                    placeholder="Inicial"
                    disabled={loading}
                    onChange={(e) => setDini(e.target.value)}
                  />
                  <Form.Control
                    type="date"
                    value={dend}
                    placeholder="Final"
                    disabled={loading}
                    onChange={(e) => setDend(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>

            <Col sm={4}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    variant="secondary"
                    title="Listar"
                    className="me-2"
                    onClick={() => setContador(contador + 1)}
                    disabled={loading}
                  >
                    Atualizar
                  </Button>

                  <Button title="Listar" className="me-2" disabled={loading} onClick={() => window.print()}>
                    Imprimir
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Row>
        <Col sm={12} className="mt-2">
          <CardGraficoAtendimentosPorCanal atendimentos={atendimentos} />
        </Col>

        <Col sm={12} className="mt-2">
          <Table>
            <thead>
              <tr>
                <th>Canal</th>
                <th>SubCanal</th>
                <th>Quantidade</th>
              </tr>
            </thead>
            <tbody>
              {(atendimentos || []).map((att, idx) => {
                return (
                  <tr key={idx}>
                    <td>{CANAIS_DE_ATENDIMENTO[att.canalDeAtendimento] || att.canalDeAtendimento}</td>
                    <td>
                      {SUBCANAIS_DE_ATENDIMENTO[att.canalDeAtendimento][att.subCanalDeAtendimento] ||
                        att.subCanalDeAtendimento}
                    </td>
                    <td>{att.nAtendimentos}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
