import React from "react";
import AuthContext from "contextos/AuthContext";
import TituloContext from "contextos/TituloContext";

import { MapContainer, TileLayer, LayersControl, LayerGroup, Marker, Tooltip, Polyline, Popup } from "react-leaflet";
import * as L from "leaflet";

import faMapPin from "../../images/map-pin-solid.svg";

import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import "leaflet/dist/leaflet.css";

export default function LHMap({ prefixName = "", titulo = "LH Mapas", center = null, overlays = [] }) {
  const { empresa } = React.useContext(AuthContext);
  const { setTitulo } = React.useContext(TituloContext);
  const [latitudeInicial] = React.useState(center ? center[0] : getItem(`${prefixName}_lhispLat`, defaultLatitude));
  const [longitudeInicial] = React.useState(center ? center[1] : getItem(`${prefixName}_lhispLng`, defaultLongitude));
  const [zoom] = React.useState(getItem(`${prefixName}_lhispZoom`, defaultZoom));
  const [mapRef, setMapRef] = React.useState(null);
  const googleMapsKey = empresa.googleMapsKey;

  React.useEffect(() => {
    setTitulo(titulo);
  }, [setTitulo, titulo]);

  React.useEffect(() => {
    if (!mapRef) return;
    if (!center) return;
    mapRef.setView(center, mapRef.getZoom());
  }, [center, mapRef]);

  return (
    <MapContainer
      zoom={zoom}
      center={[latitudeInicial, longitudeInicial]}
      ref={setMapRef}
      className="w-100 h-100"
      style={{ minHeight: "500px" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      <LayersControl position="bottomright">
        {googleMapsKey ? (
          <LayersControl.BaseLayer name="Google">
            <ReactLeafletGoogleLayer apiKey={googleMapsKey} />
          </LayersControl.BaseLayer>
        ) : (
          false
        )}

        <LayersControl.BaseLayer checked name="OpenStreetMap">
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>

        {(overlays || []).map((overlay, idx) => {
          return (
            <LayersControl.Overlay name={overlay.name} checked key={idx}>
              <LayerGroup>
                {(overlay.markers || []).map((marker, idx) => {
                  //console.log("Returning Marker:", idx, marker.position);
                  return (
                    <Marker
                      key={idx}
                      position={marker.position}
                      draggable={marker.draggable}
                      icon={
                        new L.Icon({
                          iconUrl: marker.icon.image || faMapPin,
                          iconSize: [marker.icon.size || 15, marker.icon.size || 15],
                          iconAnchor: marker.icon.anchor || null,
                        })
                      }
                      attribution={"TESTE"}
                      eventHandlers={{
                        dragend: function () {
                          //console.log("POS:", this);
                        },
                      }}
                    >
                      {marker.tooltip ? <Tooltip>{marker.tooltip}</Tooltip> : false}
                    </Marker>
                  );
                })}

                {(overlay.polylines || []).map((polyline, idx) => {
                  return (
                    <Polyline
                      key={idx}
                      positions={polyline.positions}
                      color={polyline.color || "black"}
                      weight={polyline.weight || 1.5}
                      opacity={polyline.opacity || 0.75}
                      eventHandlers={{
                        mouseover: function () {
                          this.setStyle({ weight: polyline.weight ? polyline.weight * 2 : 3 });
                        },
                        mouseout: function () {
                          this.setStyle({ weight: polyline.weight || 1.5 });
                        },
                      }}
                    >
                      {polyline.name ? (
                        <Popup minWidth={400}>
                          <h6>{polyline.name}</h6>
                        </Popup>
                      ) : (
                        false
                      )}
                    </Polyline>
                  );
                })}
              </LayerGroup>
            </LayersControl.Overlay>
          );
        })}
      </LayersControl>
    </MapContainer>
  );
}

function getItem(name, valorPadrao) {
  if (localStorage) {
    let valor = parseFloat(localStorage.getItem(name));
    if (isNaN(valor)) {
      return valorPadrao;
    } else {
      return valor;
    }
  } else {
    return valorPadrao;
  }
}

const defaultLatitude = -3.765163189539638;
const defaultLongitude = -38.51327061653138;
const defaultZoom = 12;
