import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Listagem from "componentes/Listagem";
import LoadingContext from "contextos/LoadingContext";
import CampoData from "componentes/CampoData";
import { toast } from "react-toastify";
import IoContext from "contextos/IoContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faArrowLeft, faPrint } from "@fortawesome/free-solid-svg-icons";
import { getInicioMes, getFinalMes } from "misc/lhdatas";

export default function HistoricoEstoque() {
  const params = useParams();
  const navigate = useNavigate();
  const { ProdutoId, AlmoxarifadoId } = params;
  const { socket } = React.useContext(IoContext);
  const { loading } = React.useContext(LoadingContext);
  const [produto, setProduto] = React.useState(null);
  const [dini, setDini] = React.useState(getInicioMes().toISOString().split("T")[0]);
  const [dend, setDend] = React.useState(getFinalMes().toISOString().split("T")[0]);
  const [itens, setItens] = React.useState([]);
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    socket.emit(
      "Produto.findOne",
      {
        attributes: ["nome"],
        where: {
          id: ProdutoId,
        },
      },
      (error, resp) => {
        if (error) {
          toast.error("" + error);
        } else {
          setProduto(resp);
        }
      }
    );
  }, [socket, ProdutoId]);

  React.useEffect(
    function () {
      setItens([]);
      socket.emit(
        "HistoricoEstoque.findAll",
        {
          attributes: ["id", "quantidade", "descricao", "createdAt", "tipo"],
          where: {
            createdAt_between: [
              new Date(dini).toISOString().split("T")[0] + " 00:00:00",
              new Date(dend).toISOString().split("T")[0] + " 23:59:59",
            ],
          },
          withUsuario: {
            attributes: ["nome"],
          },
          withEstoque: {
            attributes: ["id"],
            where: {
              ProdutoId,
              AlmoxarifadoId,
            },
            withLocalAlmoxarifado: {
              attributes: ["descricao"],
            },
          },
          withLocacao: {
            attributes: ["id"],
            withContrato: {
              attributes: ["numero_contrato"],
              withPessoa: {
                attributes: ["nome"],
              },
            },
            require: false,
          },
          order: [["createdAt", "asc"]],
        },
        (error, resp) => {
          if (error) {
            toast.error("" + error);
          } else {
            setItens(resp);
          }
        }
      );
    },
    [socket, dini, dend, ProdutoId, AlmoxarifadoId, count]
  );

  return (
    <Container fluid className="pt-3">
      <Card>
        <Card.Body>
          <Form
            onSubmit={(e) => {
              setCount(count + 1);
              e.preventDefault();
              e.stopPropagation();
              return false;
            }}
          >
            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Produto</Form.Label>
                  <Form.Control value={produto ? produto.nome : "----"} readOnly />
                </Form.Group>
              </Col>

              <Col sm={2}>
                <Form.Group>
                  <Form.Label>Data Inicial</Form.Label>
                  <CampoData value={dini} onChange={(e) => setDini(e.target.value)} />
                </Form.Group>
              </Col>

              <Col sm={2}>
                <Form.Group>
                  <Form.Label>Data Final</Form.Label>
                  <CampoData value={dend} onChange={(e) => setDend(e.target.value)} />
                </Form.Group>
              </Col>

              <Col sm={2}>
                <Form.Group>
                  <Form.Label>Ações</Form.Label>
                  <div>
                    <Button type="submit" className="me-2" variant="info" title="Atualizar Listagem" disabled={loading}>
                      <FontAwesomeIcon icon={faSync} />
                    </Button>

                    <Button className="me-2" variant="primary" title="Voltar" onClick={() => navigate(-1)}>
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>

                    <Button className="me-2" variant="secondary" title="Imprimir" onClick={() => window.print()}>
                      <FontAwesomeIcon icon={faPrint} />
                    </Button>
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Body>
          <Listagem
            titulos={["Id", "Data", "Usuário", "Local", "Descrição", "Quantidade"]}
            itens={itens || []}
            tamanhos={[
              { sm: 12, md: 1 },
              { sm: 12, md: 1 },
              { sm: 12, md: 1 },
              { sm: 12, md: 1 },
              { sm: 12, md: 6 },
              { sm: 12, md: 1 },
            ]}
            renderItem={(titulo, item) => {
              switch (titulo) {
                case "Id":
                  return item.id;
                case "Data":
                  return new Date(item.createdAt).toLocaleString();
                case "Usuário":
                  return item.Usuario ? item.Usuario.nome : "----";
                case "Local":
                  return item.Estoque && item.Estoque.LocalAlmoxarifado
                    ? item.Estoque.LocalAlmoxarifado.descricao
                    : "----";
                case "Descrição":
                  let desc = item.descricao;
                  if (item.Locacao) {
                    const Contrato = item.Locacao.Contrato || {};
                    const Pessoa = Contrato.Pessoa || {};
                    desc += `LOCACAO [ID: ${item.Locacao.id}][CONTRATO: ${Contrato.numero_contrato}] ${
                      Pessoa.nome || "----"
                    }`;
                  }
                  return desc;
                case "Quantidade":
                  return `${item.tipo === "E" ? "+" : "-"} ${parseInt(item.quantidade)}`;
                default:
                  return titulo;
              }
            }}
          />
        </Card.Body>

        <Card.Footer></Card.Footer>
      </Card>
    </Container>
  );
}
