import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import MenuItem from './MenuItem';
import TituloContext from '../../contextos/TituloContext';
import MenuContext from '../../contextos/MenuContext';
import IoContext from '../../contextos/IoContext';
import './Sidebar.css';

export default function Sidebar({ sidebarOpen, setSidebarOpen }) {
    const { menus, setMenus } = React.useContext(MenuContext);
    const { socket } = React.useContext(IoContext);
    const { appName } = React.useContext(TituloContext);

    React.useEffect(() => {
        socket.emit('Menus', {
        }, (error, resp) => {
            if (error) {
                console.log("## LOADING MENUS ERROR:", error);
            } else {
                setMenus(resp);
            }
        });
    }, [socket, setMenus]);

    return <Modal
        show={sidebarOpen}
        onHide={() => setSidebarOpen(false)}
        dialogClassName="lhsidebar">
        <Nav defaultActiveKey="/home" className="flex-column">
            <Link className="nav-link active" to="/dashboard" onClick={() => setSidebarOpen(false)}>{appName}</Link>
            <Dropdown.Divider />

            {(menus || []).map(menu => {
                return <MenuItem key={menu.id} {...menu} nivel={1} setSidebarOpen={setSidebarOpen} Link={Link} />
            })}
        </Nav>
    </Modal>
}