import IoContext from "contextos/IoContext";
import React from "react";
import { Card, Form, Row, Col, Button, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import IntegracaoHistoricoEventos from "../IntegracaoHistoricoEventos";

const emptyConfig = {
  token: "",
};

export default function ItTV() {
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(true);
  const [isAtivo, setAtivo] = React.useState(true);
  const [config, setConfig] = React.useState(emptyConfig);

  React.useEffect(() => {
    setLoading(true);
    setConfig(emptyConfig);

    socket.emit(
      "Integracao.findOne",
      {
        where: {
          modulo: "ItTV",
        },
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          return toast.error("" + error);
        }

        const config =
          typeof resp.configuracoes === "object" ? resp.configuracoes : JSON.parse(resp.configuracoes || "{}");
        setConfig(config);
      }
    );
  }, [socket]);

  return (
    <Container fluid>
      <Card>
        <Card.Header className="bg-primary">
          <Card.Title className="text-light">ItTV - Configuração da API</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col sm={8}>
              <Form.Group>
                <Form.Label>Token</Form.Label>
                <Form.Control
                  disabled={loading}
                  value={config?.token || ""}
                  onChange={(e) => setConfig({ ...config, token: e.target.value })}
                />
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group>
                <Form.Label>Ativo</Form.Label>
                <Form.Check
                  checked={isAtivo}
                  onChange={() => {
                    setAtivo(!isAtivo);
                  }}
                  disabled={loading}
                />
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    disabled={loading}
                    onClick={(e) => {
                      e.preventDefault();
                      setLoading(true);
                      socket.emit(
                        "Integracao.Salvar",
                        {
                          modulo: "ItTV",
                          configuracoes: config,
                          isAtivo,
                        },
                        (error) => {
                          setLoading(false);
                          if (error) {
                            return toast.error("" + error);
                          }

                          toast.success("Configurações Atualizadas com Sucesso!");
                        }
                      );
                    }}
                  >
                    Salvar
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>

        <IntegracaoHistoricoEventos modulo="ItTV" />
      </Card>
    </Container>
  );
}
