import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import IoContext from '../../contextos/IoContext';

export default function CardStatusLHISP(){
    const { socket } = React.useContext(IoContext);
    const [contas] = React.useState([]);
    const [notasAtual, setNotasAtual] = React.useState(0);
    const [notasAnterior, setNotasAnterior] = React.useState(0);

    let cor = "white"; 
    let txt1 = "Bem Vindo ao LHISP";
    let txt2 = "";

    
    React.useEffect(()=>{
        const d1 = new Date();
        const d2 = new Date(); d2.setDate(0); // Volta pro mes anterior.
        const mesAtual = d1.getMonth()+1;
        const anoAtual = d1.getFullYear();
        const mesAnterior = d2.getMonth()+1;
        const anoAnterior = d2.getFullYear();

        socket.emit("NotaFiscal.count", {
            where: { mes: mesAtual, ano: anoAtual }
        }, (error, resp) => {
            if(error) {
                console.log("## ERROR NOTAS MES ATUAL:", error);
            } else {
                setNotasAtual(resp);
            }
        });

        socket.emit("NotaFiscal.count", {
            where: { mes: mesAnterior, ano: anoAnterior }
        }, (error, resp) => {
            if(error) {
                console.log("## ERROR NOTAS MES ANTERIOR:", error);
            } else {
                setNotasAnterior(resp);
            }
        });
    }, [socket]);

    if(contas.length === 1) {
        const venc = new Date(contas[0].data_vencimento);
        if(venc.getTime() < (new Date()).getTime()){
            cor = 'orange';
            txt1 = `Seu boleto do LHISP Venceu em: ` + venc.format('L');
            txt2 = 'Caso já tenha efetuado o pagamento, favor desconsiderar a mensagem.';
        } else {
            cor = 'green';
            txt1 = `Seu boleto do LHISP vence em: ` + venc.format('L');
            txt2 = 'Caso já tenha efetuado o pagamento, favor desconsiderar a mensagem.';
        }
    } else if(contas.length> 1 ) {
        txt1 = 'Evite o Bloqueio, coloque suas contas em dias !';
        txt2 = `Contas ${contas.length} Boletos em Aberto.`
        cor = 'red';
    } else if(notasAnterior > notasAtual) {
        txt1 = "Já Gerou as  Notas fiscais esse Mês ?";
        txt2 = `${notasAnterior} no mês Passado, ${notasAtual} nesse mês.`;
        cor = 'orange';
    }

    return <Card className="mt-2 shadow" style={{ height: "150px" }}>
        <Card.Body>
            <Row>
                <Col sm={10}>
                    <h3>{ txt1 }</h3>
                    <small>{ txt2 }</small>
                </Col>

                <Col sm={2}>
                    <FontAwesomeIcon icon={faDollarSign} size="5x" color={cor} />
                </Col>
            </Row>
        </Card.Body>
    </Card>
}