import React from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FormularioListagem from "../../../../componentes/FormularioListagem";
import IoContext from "../../../../contextos/IoContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { getInicioMes, getFinalMes } from "misc/lhdatas";

export default function Feriados() {
  const navigate = useNavigate();
  const { socket } = React.useContext(IoContext);
  const [pagina, setPagina] = React.useState(1);
  const [texto, setTexto] = React.useState("");
  const [loading, setloading] = React.useState(false);
  const [total, setTotal] = React.useState(0);
  const [feriados, setFeriados] = React.useState([]);
  const [dini, setDini] = React.useState(getInicioMes().toISOString().split("T")[0]);
  const [dend, setDend] = React.useState(getFinalMes().toISOString().split("T")[0]);

  React.useEffect(() => {
    let where = {
      data_feriado_between: [dini, dend],
    };
    if (texto) where.descricao_like = `%${texto}%`;

    setloading(true);
    socket.emit(
      "Feriado.findAndCountAll",
      {
        where,
        limit: 10,
        offset: 10 * (pagina - 1),
      },
      (error, resp) => {
        setloading(false);
        if (error) {
          return toast.error("" + error);
        } else {
          setFeriados(resp.rows);
          setTotal(resp.count);
        }
      }
    );
  }, [texto, pagina, dini, dend, socket]);

  return (
    <FormularioListagem
      titulo={`Feriados`}
      itens={feriados || []}
      qtdItens={total}
      pagina={pagina}
      loading={loading}
      setTexto={setTexto}
      setPagina={setPagina}
      titulos={["Id", "Data", "Descrição"]}
      tamanhos={[
        { sm: 12, md: 1 },
        { sm: 12, md: 2 },
        { sm: 12, md: 8 },
      ]}
      onClick={(e) => {
        navigate(`${e.id}`);
      }}
      renderItem={(titulo, item) => {
        switch (titulo) {
          case "Id":
            return item.id;
          case "Descrição":
            return item.descricao;
          case "Data":
            return item.data_feriado.split("-").reverse().join("/");
          default:
            return titulo;
        }
      }}
      renderFiltros={() => {
        return (
          <Card.Body>
            <Form.Group>
              <Form.Label>Data Inicial</Form.Label>
              <Form.Control value={dini} type="date" onChange={(e) => setDini(e.target.value)} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Data Final</Form.Label>
              <Form.Control value={dend} type="date" onChange={(e) => setDend(e.target.value)} />
            </Form.Group>
          </Card.Body>
        );
      }}
    />
  );
}
