import { faArrowRightArrowLeft, faCheck, faDollar, faExternalLinkAlt, faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    CONTRATO_VOIP_SITUACAO_AGUARDANDO_CONFIRM_DDD, 
    CONTRATO_VOIP_SITUACAO_AGUARDANDO_PAGAMENTO, 
    CONTRATO_VOIP_SITUACAO_LIBERADO,
} from 'datatypes/ContratoTypes';
import React from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ModalAloFoneFaturas from './ModalAloFoneFaturas';
import ModalAloFoneInfo from './ModalAloFoneInfo';
import ModalConfirmarDDD from './ModalConfirmarDDD';
import ModalPortabilidadeAloFone from './ModalPortabilidadeAloFone';

export default function AcoesContratoVoipAloFone({ ContratoVoip, disabled, callback }) {
    const [showModalInfo, setShowModalInfo] = React.useState(false);
    const [showModalFaturas, setShowModalFaturas] = React.useState(false);
    const [showModalConfirmarDDD, setShowModalConfirmarDDD] = React.useState(false);
    const [showModalPortabilidade, setShowModalPortabilidade] = React.useState(false);

    return <React.Fragment>
        <Button className="btn-sm me-2" variant="info" disabled={disabled} title="Informações"
            onClick={()=>{
                setShowModalInfo(true);
            }}><FontAwesomeIcon icon={faInfo} />
        </Button>

        <Button className="btn-sm me-2" variant="success" disabled={disabled} title="Faturas"
            onClick={()=>{
                setShowModalFaturas(true);
            }}><FontAwesomeIcon icon={faDollar} />
        </Button>

        { ContratoVoip.situacao === CONTRATO_VOIP_SITUACAO_AGUARDANDO_CONFIRM_DDD && 
        <Button className="btn-sm me-2" variant="primary" disabled={disabled} title="Confirmar DDD"
            onClick={()=>{
                setShowModalConfirmarDDD(true);
            }}><FontAwesomeIcon icon={faCheck} />
        </Button> }

        { ContratoVoip.situacao === CONTRATO_VOIP_SITUACAO_LIBERADO && 
        <Button className="btn-sm me-2" variant="primary" disabled={disabled} title="Portabilidade"
            onClick={()=>{
                setShowModalPortabilidade(true);
            }}><FontAwesomeIcon icon={faArrowRightArrowLeft} />
        </Button> }

        { ContratoVoip.situacao === CONTRATO_VOIP_SITUACAO_AGUARDANDO_PAGAMENTO && 
        <Button className="btn-sm me-2" variant="primary" disabled={disabled} title="Visualizar Fatura"
            onClick={()=>{
                try {
                    const detalhesIntegracao = JSON.parse(ContratoVoip.detalhesIntegracao);
                    window.open(`https://fatura.operadora.app.br/?payid=${detalhesIntegracao.fatura}`);
                } catch(error) {
                    console.error("Falha ao Processar os Detalhes da Integração:", error);
                    toast.error("" + error);
                }
            }}><FontAwesomeIcon icon={faExternalLinkAlt} />
        </Button> }

        { showModalConfirmarDDD && 
        <ModalConfirmarDDD show={showModalConfirmarDDD} onHide={setShowModalConfirmarDDD} 
            ContratoVoip={ContratoVoip} disabled={disabled} callback={callback} />}

        { showModalPortabilidade &&
        <ModalPortabilidadeAloFone show={showModalPortabilidade} onHide={setShowModalPortabilidade} 
            ContratoVoip={ContratoVoip} disabled={disabled} callback={callback} />}

        { showModalInfo && 
        <ModalAloFoneInfo show={showModalInfo} onHide={setShowModalInfo} ContratoVoip={ContratoVoip} />}

        { showModalFaturas && 
        <ModalAloFoneFaturas show={showModalFaturas} onHide={setShowModalFaturas} ContratoVoip={ContratoVoip} />}
    </React.Fragment>
}