export function getErrorMessage(error) {
  let txt = "";
  if (!error) return "";

  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    error.graphQLErrors.forEach((e) => (txt += e.message + "\n"));
  } else if (error.networkError && error.networkError.length > 0) {
    error.networkError.forEach((e) => (txt += e.message + "\n"));
  } else if (error) {
    txt += error.message;
  }
  return txt;
}

export function formatarEndereco(e) {
  if (e && e.id) {
    return `${e.logradouro}, ${e.bairro}, ${e.cidade}-${e.uf} ${e.cep}`;
  } else {
    return "SEM ENDEREÇO";
  }
}

export function formatarDoc1(doc1 = "") {
  let loginDigitos = doc1.replace(/[^\d]/g, "");

  if (loginDigitos.length === 11) {
    // CPF
    doc1 =
      loginDigitos.substr(0, 3) +
      "." +
      loginDigitos.substr(3, 3) +
      "." +
      loginDigitos.substr(6, 3) +
      "-" +
      loginDigitos.substr(9, 2);
  } else if (loginDigitos.length === 14) {
    // CNPJ
    doc1 =
      loginDigitos.substr(0, 2) +
      "." +
      loginDigitos.substr(2, 3) +
      "." +
      loginDigitos.substr(5, 3) +
      "/" +
      loginDigitos.substr(8, 4) +
      "-" +
      loginDigitos.substr(12, 2);
  }

  return doc1;
}

export function formatarCep(cep = "") {
  let digitos = (cep + "").replace(/[^\d]/g, "");

  return digitos.substr(0, 2) + "." + digitos.substr(2, 3) + "-" + digitos.substr(5, 3);
}

export function formatarTelefone(txt) {
  const soNumerosFull = `${txt}`.replace(/[^\d]/g, "");
  const soNumeros = soNumerosFull.length > 11 ? soNumerosFull.substring(soNumerosFull.length - 11) : soNumerosFull;

  let tel = "";
  if (soNumeros.length === 1) {
    if (+soNumeros[0] === 0) {
      tel = soNumeros;
    } else {
      tel = "(" + soNumeros;
    }
  } else if (soNumeros.length === 2) {
    if (+soNumeros[0] === 0 && +soNumeros[1] === 8) {
      tel = soNumeros;
    } else {
      tel = "(" + soNumeros;
    }
  } else if (soNumeros.length > 2) {
    if (+soNumeros[2] === 9) {
      // CELULAR
      tel = "(" + soNumeros.slice(0, 2) + ")";
      if (soNumeros.length > 2) tel += soNumeros.slice(2, 7);

      if (soNumeros.length > 7) tel += "-" + soNumeros.slice(7, 11);
    } else {
      // TELEFONE FIXO OU 0800
      if (+soNumeros[0] === 0 && +soNumeros[1] === 8) {
        // 0800
        tel = soNumeros.slice(0, 4);
        if (soNumeros.length > 4) tel += "-" + soNumeros.slice(4, 7);
        if (soNumeros.length > 7) tel += "-" + soNumeros.slice(7, 11);
      } else {
        tel = "(" + soNumeros.slice(0, 2) + ")";
        if (soNumeros.length > 2) tel += soNumeros.slice(2, 6);

        if (soNumeros.length > 6) tel += "-" + soNumeros.slice(6, 10);
      }
    }
  }

  return tel;
}

export function formatarVelocidade(velocidade) {
  if (!velocidade) return "";

  let unidades = ["", "K", "M", "G", "T", "P", "E", "Z"];
  for (let i = 0; i < unidades.length; i++) {
    if (velocidade < 1024) {
      return +velocidade.toFixed(2) + unidades[i];
    }

    velocidade = velocidade / 1024;
  }

  // Default Case... use the last unit
  return +velocidade.toFixed(2) + unidades[unidades.length - 1];
}

export function imprimirRef(origem) {
  const iframe = document.createElement("iframe");
  iframe.src = window.location.origin;
  document.getElementsByTagName("body")[0].append(iframe);
  var pri = iframe.contentWindow;

  pri.document.open();
  pri.document.write(origem.outerHTML);
  pri.document.close();

  const css = document.getElementsByTagName("style");
  for (let i = 0; i < css.length; i++) {
    const style = pri.document.createElement("style");
    style.innerText = css[i].firstChild.data;
    pri.document.getElementsByTagName("head")[0].appendChild(style);
  }

  pri.focus();
  pri.print();
}

export function getRandomString() {
  return (Math.random() + 1).toString(36).substring(2) + (Math.random() + 1).toString(36).substring(2);
}

export function getRamdomToken() {
  return [getRandomString(), getRandomString(), getRandomString(), getRandomString()].join(":");
}
