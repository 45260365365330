import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import uuid4 from "uuid4";

export function CampoToken({
  value,
  setValue,
  onChange,
  generateToken = uuid4,
  required = false,
  readOnly = false,
  disabled = false,
}) {
  return (
    <InputGroup>
      <Form.Control value={value} required={required} readOnly={readOnly} onChange={onChange} />

      <Button
        disabled={disabled}
        variant="secondary"
        title="Gerar novo token"
        onClick={() => {
          setValue(generateToken());
        }}
      >
        <FontAwesomeIcon icon={faCog} />
      </Button>
    </InputGroup>
  );
}
