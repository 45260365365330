import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import IoContext from "contextos/IoContext";

import Container from "react-bootstrap/Container";
import TituloContext from "contextos/TituloContext";
import SelectFuncionario from "paginas/cadastros/administrativo/functionaios/SelectFuncionario";
import SelectAlmoxarifados from "../SelectAlmoxarifados";
import Listagem from "componentes/Listagem";
import SelectLocalAlmoxarifado from "paginas/almoxarifados/SelectLocalAlmoxarifado";
import { useNavigate, useParams } from "react-router";

export default function DevolucaoMaterial() {
  const params = useParams();
  const navigate = useNavigate();
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(false);
  const id = isNaN(parseInt(params.id)) ? "" : +params.id;
  const { setTitulo } = React.useContext(TituloContext);
  const [funcionarioId] = React.useState(id);
  const [almoxarifadoId, setAlmoxarifadoId] = React.useState("");
  const [itens, setItens] = React.useState([]);
  const [quantidades, setQuantidades] = React.useState({});
  const [locais, setLocais] = React.useState({});

  React.useEffect(
    function () {
      socket.emit(
        "EstoqueFuncionario.findAll",
        {
          where: {
            FuncionarioId: funcionarioId ? funcionarioId : 0,
            quantidade_gt: 0,
          },
          withProduto: {},
        },
        (error, resp) => {
          if (error) {
            toast.error("" + error);
          } else {
            let qtds = {};
            resp.forEach((estoque) => {
              qtds[estoque.ProdutoId] = 0;
            });
            setQuantidades(qtds);
            setItens(resp);
          }
        }
      );
    },
    [socket, funcionarioId]
  );

  React.useEffect(() => {
    setTitulo(`Estoque do Funcionário ${id ? `[${id}]` : ""}`);
  }, [setTitulo, id]);

  return (
    <Container fluid className="pt-3">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();

          let total = 0;
          Object.keys(quantidades).forEach((idx) => {
            total += quantidades[idx];
          });

          if (!almoxarifadoId) {
            return toast.info("Selecione o Almoxarifado para onde o material está sendo entregue");
          }

          if (total <= 0) {
            return toast.warning("Nenhum produto selecionado para devolução");
          }

          let produtos = [];
          Object.keys(quantidades).forEach((ProdutoId) => {
            const quantidade = quantidades[ProdutoId];
            if (parseFloat(quantidade) > 0) {
              produtos.push({
                ProdutoId: +ProdutoId,
                LocalAlmoxarifadoId: +locais[ProdutoId],
                quantidade: parseFloat(quantidade),
              });
            }
          });

          setLoading(true);
          socket.emit(
            "Funcionario.devolverMaterialAoAlmoxarifado",
            {
              FuncionarioId: +funcionarioId,
              AlmoxarifadoId: +almoxarifadoId,
              produtos,
            },
            (error, resp) => {
              setLoading(false);
              if (error) {
                toast.error("" + error);
              } else {
                toast.success("Devolução Efetuada com Sucesso !");
                navigate(-1);
              }
            }
          );

          return false;
        }}
      >
        <Card>
          <Card.Body>
            <Row>
              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Funcionário</Form.Label>
                  <SelectFuncionario value={funcionarioId} required disabled />
                </Form.Group>
              </Col>

              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Almoxarifado</Form.Label>
                  <div>
                    <SelectAlmoxarifados value={almoxarifadoId} onChange={(e) => setAlmoxarifadoId(e.target.value)} />
                  </div>
                </Form.Group>
              </Col>

              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Ações</Form.Label>
                  <div>
                    <Button
                      className="me-2"
                      disabled={loading}
                      variant="warning"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Cancelar
                    </Button>

                    <Button type="submit" disabled={loading}>
                      Devolver
                    </Button>
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card className="mt-3">
          <Card.Body>
            <Listagem
              titulos={["Id", "Produto", "Quantidade", "Local", "Devolver"]}
              tamanhos={[
                { sm: 12, md: 2 },
                { sm: 12, md: 4 },
                { sm: 12, md: 2 },
                { sm: 12, md: 2 },
                { sm: 12, md: 2 },
              ]}
              itens={itens || []}
              renderItem={(titulo, item, idx) => {
                switch (titulo) {
                  case "Id":
                    return item.id;
                  case "Produto":
                    return item.Produto ? item.Produto.nome : "----";
                  case "Quantidade":
                    return item.quantidade;
                  case "Local":
                    return (
                      <SelectLocalAlmoxarifado
                        disabled={loading || !almoxarifadoId}
                        AlmoxarifadoId={almoxarifadoId}
                        required={quantidades[item.ProdutoId] > 0}
                        value={locais[item.ProdutoId]}
                        onChange={(e) => {
                          const _locais = { ...locais };
                          _locais[item.ProdutoId] = e.target.value;
                          setLocais(_locais);
                        }}
                      />
                    );
                  case "Devolver":
                    return (
                      <Form.Control
                        type="number"
                        disabled={loading}
                        value={quantidades[item.ProdutoId]}
                        max={item.quantidade}
                        onChange={(e) => {
                          const qtds = { ...quantidades };
                          qtds[item.ProdutoId] = e.target.value;
                          setQuantidades(qtds);
                        }}
                      />
                    );
                  default:
                    return titulo;
                }
              }}
            />
          </Card.Body>

          <Card.Footer></Card.Footer>
        </Card>
      </Form>
    </Container>
  );
}
