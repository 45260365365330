import React from 'react';
import FormularioListagem from 'componentes/FormularioListagem';
import IoContext from 'contextos/IoContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';


export default function Funcionarios(){
    const navigate = useNavigate();
    const { socket } = React.useContext(IoContext);
    const [loading, setLoading] = React.useState(false);
    const [pagina, setPagina] = React.useState(1);
    const [texto, setTexto] = React.useState("");
    const [loading2, setLoading2] = React.useState(false);
    const [total, setTotal] = React.useState(0);
    const [funcionarios, setFuncionarios] = React.useState([]);

    React.useEffect(()=>{
        let where = {};
        if(texto) where.nome_like = `%${texto}%`;

        setLoading(true); setLoading2(true);
        socket.emit("Funcionario.findAndCountAll", {
            where, limit: 10, offset: 10 * (pagina-1)
        }, (error, resp) => {
            setLoading(false); setLoading2(false);
            if(error){
                toast.error(error); 
            } else {
                setFuncionarios(resp.rows);
                setTotal(resp.count);
            }
        });
    }, [socket, texto, pagina]);

    return <FormularioListagem
        titulo={`Funcionários`}
        itens={funcionarios || []} qtdItens={total} pagina={pagina} loading={loading || loading2}
        setTexto={setTexto} setPagina={setPagina}
        titulos={["Id", "Nome", "Telefones", "Email"]}
        onClick={(e)=>{
            navigate(`${e.id}`);
        }}
        
        renderItem={ (titulo, item) => {
            switch(titulo) {
                case 'Id': return item.id;
                case 'Nome': return item.nome;
                case 'Telefones': return item.telefone1 + (item.telefone2 ? ` ${item.telefone2}` : "");
                case 'Email': return item.email;
                default: return titulo;
            }
        }}
        />
}