import React from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import IoContext from "contextos/IoContext";
import { toast } from "react-toastify";
export default function IntegracaoHistoricoEventos({ modulo }) {
  const { socket } = React.useContext(IoContext);
  const [logs, setLogs] = React.useState([]);

  React.useEffect(() => {
    socket.emit(
      "Log.findAll",
      {
        where: {
          modulo: `Integracao ${modulo}`,
        },
        order: [["id", "desc"]],
        limit: 10,
      },
      (error, logs) => {
        if (error) {
          toast.error("" + error);
        }
        setLogs(logs);
      }
    );
  }, [socket, modulo]);

  return (
    <Card className="mt-3">
      <Card.Header className="bg-secondary">
        <Card.Title className="text-light">Histórico de Eventos</Card.Title>
      </Card.Header>

      <Table>
        <thead>
          <tr>
            <th>Usuário</th>
            <th>Data/Hora</th>
            <th>Operação</th>
            <th>Dados</th>
          </tr>
        </thead>
        <tbody>
          {(logs || []).map((log) => {
            return (
              <tr key={log.id}>
                <td>{log.usuario}</td>
                <td>{new Date(log.createdAt).toLocaleString()}</td>
                <td>{log.operacao}</td>
                <td>{log.dados}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Card>
  );
}
