import React from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import TituloContext from '../../../contextos/TituloContext';
import ImportarJson from './ImportarContaReceberJson';
import { toast } from 'react-toastify';

export default function ImportarContasReceber(){
    const { setTitulo } = React.useContext(TituloContext);
    const [arquivo, setArquivo] = React.useState(null);
    const [dados, setDados] = React.useState(null);

    React.useEffect(()=>{ setTitulo("Importação de Contas a Receber") });
    React.useEffect(()=>{
        if(arquivo) {
            const reader = new FileReader();
            reader.onload = function(evt) {
                setDados(evt.target.result);
            };
            reader.readAsText(arquivo);
        } else {
            setDados(null);
        }
    }, [arquivo]);

    return <Container fluid className="pt-3">
        { arquivo && dados ? getParser({ arquivo, dados, setArquivo }) : 
        <Card>
            <Card.Body>
                <Form.Group>
                    <Form.Label>Arquivo de Contas a Receber:</Form.Label>
                    <Form.File 
                        label="Selecione o Arquivo"
                        onChange={ e => {
                            setArquivo(e.target.files[0]);
                        }}
                        custom
                        />
                </Form.Group> 
            </Card.Body>
        </Card> }
    </Container>
}

function getParser({ arquivo, dados, setArquivo }) {
    if(!arquivo) return;

    switch(arquivo.type) {
        case 'application/json': 
            dados = JSON.parse(dados);
            if(!dados) {
                toast.error('Não consegui processar o Arquivo JSON !');
                return false;
            }

            if(!Array.isArray(dados) ){
                toast.error('Os dados devem ser uma Lista com as Contas a Receber');
                return false;
            }
            
            return <ImportarJson arquivo={arquivo} setArquivo={setArquivo} dados={dados} />
        default:
            setArquivo(null);
            toast.warning(`Tipo de Arquivo não Suportado: ${arquivo.type}`);
            return false;
    }
}