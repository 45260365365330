import React from "react";
import { Container, Col, Row, Card, Form, Button } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import IoContext from "contextos/IoContext";
import { toast } from "react-toastify";
import { getFinalMes, getInicioMes } from "misc/lhdatas";
import { useParams } from "react-router-dom";
import CardListaContasPagas from "./CardListaContasPagas";
import CardCabecalhoDados from "./CardCabecalhoDados";

export default function RelQuitacaoDebitos() {
  const { socket } = React.useContext(IoContext);
  const [dini, setDini] = React.useState(getInicioMes().toISOString().split("T")[0]);
  const [dend, setDend] = React.useState(getFinalMes().toISOString().split("T")[0]);
  const [loading, setLoading] = React.useState(false);
  const [contasPagas, setContasPagas] = React.useState([{ Contrato: { Pessoa: { nome: "" } } }]);
  const [limit, setLimit] = React.useState(10);
  const [contador, setContador] = React.useState(0);
  const { ContratoId } = useParams();

  React.useEffect(() => {
    socket.emit(
      "ContaReceber.findAll",
      {
        attributes: ["nrdoc", "data_vencimento", "data_pagamento", "valor", "valor_pago"],
        where: {
          ContratoId: ContratoId,
          situacao: "1",
          data_vencimento_between: [dini, dend],
        },
        withContrato: {
          attributes: ["numero_contrato"],
          withPessoa: {
            attributes: ["nome"],
          },
        },
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          return toast.error(error);
        }
        setContasPagas(resp);
      }
    );
  }, [socket, limit, dini, dend, contador, ContratoId, setLoading, setContasPagas]);

  return (
    <Container fluid>
      <Card className="shadow">
        <Card.Header className="d-print-none">
          <h3 className="float-start d-print-none">Relatorio de Quitação de Débitos</h3>
        </Card.Header>

        <Card.Body className="d-print-none">
          <Row>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>Período</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="date"
                    value={dini}
                    placeholder="Inicial"
                    disabled={loading}
                    onChange={(e) => setDini(e.target.value)}
                  />
                  <Form.Control
                    type="date"
                    value={dend}
                    placeholder="Final"
                    disabled={loading}
                    onChange={(e) => setDend(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Máximo de Registros</Form.Label>
                <Form.Control
                  type="number"
                  min={1}
                  step={1}
                  value={limit}
                  onChange={(e) => setLimit(+e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    variant="secondary"
                    title="Listar"
                    className="me-2"
                    onClick={() => setContador(contador + 1)}
                    disabled={loading}
                  >
                    Atualizar
                  </Button>

                  <Button title="Listar" className="me-2" disabled={loading} onClick={() => window.print()}>
                    Imprimir
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
        <Card.Body>
          <CardCabecalhoDados nomeCliente={contasPagas[0]?.Contrato?.Pessoa?.nome} dini={dini} dend={dend} />
        </Card.Body>
      </Card>
      <Row>
        {
          <Col sm={12} className="mt-2">
            <CardListaContasPagas contasPagas={contasPagas} />
          </Col>
        }
      </Row>
    </Container>
  );
}
