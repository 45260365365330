import React from "react";
import { Routes, Route } from "react-router-dom";
import CuponsIndex from "./cupons";

export default function MarketingIndex() {
  return (
    <Routes>
      <Route path={`/cupons/*`} element={<CuponsIndex />} />
    </Routes>
  );
}
