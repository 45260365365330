import React from "react";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import IoContext from "contextos/IoContext";
import { formatarDinheiro } from "misc/formatarDinheiro";

export default function SelectAloFonePlanos(props) {
  const { socket } = React.useContext(IoContext);
  const { id, value, onChange, pular, loading, setLoading } = props;
  const [planos, setPlanos] = React.useState([]);

  React.useEffect(() => {
    setLoading(true);
    setPlanos([]);

    socket.emit("AloFone.listarPlanos", {}, (error, planos) => {
      setLoading(false);
      if (error) {
        return toast.error(typeof error === "string" ? error : error?.message);
      }

      setPlanos(planos);
    });
  }, [socket, setLoading]);

  return (
    <Form.Control
      as="select"
      id={id || "AloFonePlanoId"}
      onChange={onChange}
      value={value}
      disabled={loading}
      {...props}
    >
      <option value=""></option>
      {(planos || []).map((plano) => {
        if (pular) {
          if (Array.isArray(pular)) {
            if (pular.indexOf(plano.id)) {
              return false;
            }
          } else {
            if (+pular === +plano.id) {
              return false;
            }
          }
        }
        return (
          <option key={plano.id} value={plano.id}>
            {plano.id} - {plano.description} [{formatarDinheiro(plano.value)}]
          </option>
        );
      })}
    </Form.Control>
  );
}
