import React from 'react';
import FormularioListagem from '../../../../componentes/FormularioListagem';
import { toast } from 'react-toastify';
import IoContext from '../../../../contextos/IoContext';
import { useNavigate } from 'react-router';

export default function ContasBancarias(){
    const navigate = useNavigate();
    const { socket } = React.useContext(IoContext);
    const [loading, setLoading] = React.useState(false);
    const [pagina, setPagina] = React.useState(1);
    const [texto, setTexto] = React.useState("");
    const [contasBancarias, setContasBancarias] = React.useState([]);
    const [total, setTotal] = React.useState(0);

    React.useEffect(()=>{
        let where = {};

        if(texto) where.titular_like =  `%${texto}%`;

        setLoading(true);
        socket.emit("ContaBancaria.findAndCountAll", { 
            attributes: ["id", "titular","cnpj", "agencia","conta", "cedente"],
            where, withCarteiraCobranca: {
                attributes: ["id","banco"]
            }, limit: 10, offset: 10 * (pagina-1) 
        }, (error, resp) => {
            setLoading(false);
            if(error) {
                toast.error(error);
            } else {
                setTotal(resp.count)
                setContasBancarias(resp.rows);
            }
        });
    }, [socket, texto, pagina]);

    return <FormularioListagem
        titulo={`Contas Bancárias`}
        itens={contasBancarias} qtdItens={ total } loading={loading}
        pagina={pagina}
        setTexto={setTexto}
        setPagina={setPagina}
        titulos={["Id", "Banco", "Titular", "Cnpj", "Agência / Conta", "Cedente"]}
        tamanhos={[
            { sm: 12, md: 1 },
            { sm: 12, md: 2 },
            { sm: 12, md: 4 },
            { sm: 12, md: 2 },
            { sm: 12, md: 2 },
            { sm: 12, md: 1 },
        ]}
        onClick={(e)=>{
            navigate(`${e.id}`);
        }}
        
        renderItem={ (titulo, item) => {
            switch(titulo) {
                case 'Id': return item.id;
                case 'Banco': return item.CarteiraCobranca ? item.CarteiraCobranca.banco : "----";
                case 'Titular': return item.titular;
                case 'Cnpj': return item.cnpj;
                case 'Agência / Conta': return item.agencia ? `${item.agencia}/${item.conta}` : '----';
                case 'Cedente': return item.cedente;
                default: return titulo;
            }
        }}
        />
}