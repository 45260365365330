import React from 'react';
import { SERVIDOR_VOIP_TIPO_ALO_FONE, SERVIDOR_VOIP_TIPO_MEUCCI } from 'datatypes/ServidorVoipTypes';
import AcoesContratoVoipMeucci from './AcoesContratoVoipMeucci';
import AcoesContratoVoipAloFone from './AcoesContratoVoipAloFone';


export default function AcoesContratoVoipFactory({ ContratoVoip, disabled, callback }){
    switch(ContratoVoip?.ServidorVoip?.tipo) {
        case SERVIDOR_VOIP_TIPO_MEUCCI:
            return <AcoesContratoVoipMeucci ContratoVoip={ContratoVoip} disabled={disabled} 
                callback={callback} />
        case SERVIDOR_VOIP_TIPO_ALO_FONE:
            return <AcoesContratoVoipAloFone ContratoVoip={ContratoVoip} disabled={disabled} 
                callback={callback} />
        default:
            return <React.Fragment />
    }
}