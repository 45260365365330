import React from "react";
import axios from "axios";
import FormMapeamentoDb from "./FormMapeamentoDb";

export default function FormImportacao({
  fonteDados,
  titulo,
  modulo,
  sql,
  setSql,
  campos,
  setCampos,
  mapeamento,
  setMapeamento,
  campoOrdenacao,
  setCampoOrdenacao,
  template,
}) {
  React.useEffect(() => {
    axios
      .post("/importacoes/mapeamento_template", {
        fonteDados,
        template,
        modulo,
      })
      .then((resp) => {
        if (resp.data.sql) {
          setSql(resp.data.sql);
          setCampoOrdenacao(resp.data.campoOrdenacao);
        } else {
          console.log("## RESP MAPEAMENTO:", resp.data);
        }
      });
  }, [modulo, template, fonteDados, setCampoOrdenacao, setSql]);

  // Aqui vou checar o tipo da fonteDados, se for DB ou CSV, e exibir o form correto.
  return (
    <FormMapeamentoDb
      fonteDados={fonteDados}
      titulo={titulo}
      modulo={modulo}
      sql={sql}
      setSql={setSql}
      campos={campos}
      setCampos={setCampos}
      mapeamento={mapeamento}
      setMapeamento={setMapeamento}
      template={template}
      campoOrdenacao={campoOrdenacao}
      setCampoOrdenacao={setCampoOrdenacao}
    />
  );
}
