import React from "react";
import Card from "react-bootstrap/Card";
import Chart from "react-apexcharts";
import IoContext from "../../contextos/IoContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { OS_ABERTA } from "../../datatypes/OrdemServicoTypes";
import { getInicioDia, getFinalDia, formatarDataDbs } from "misc/lhdatas";

export default function CardOrdensDeServico() {
  const navigate = useNavigate();
  const { socket } = React.useContext(IoContext);
  const [atrasados, setAtrasados] = React.useState(0);
  const [proDia, setProDdia] = React.useState(0);
  const [futuros, setFuturos] = React.useState(0);

  React.useEffect(() => {
    const d1 = getInicioDia();
    const d2 = getFinalDia();

    socket.emit(
      "OrdemServico.count",
      {
        where: {
          situacao: OS_ABERTA,
          data_agendamento_lt: formatarDataDbs(d1),
        },
      },
      (error, resp) => {
        if (error) {
          console.log("OrdemServico.count Error:", error);
        } else {
          setAtrasados(resp);
        }
      }
    );

    socket.emit(
      "OrdemServico.count",
      {
        where: {
          situacao: OS_ABERTA,
          data_agendamento_between: [formatarDataDbs(d1), formatarDataDbs(d2)],
        },
      },
      (error, resp) => {
        if (error) {
          toast.error(error);
        } else {
          setProDdia(resp);
        }
      }
    );

    socket.emit(
      "OrdemServico.count",
      {
        where: {
          situacao: OS_ABERTA,
          data_agendamento_gt: formatarDataDbs(d2),
        },
      },
      (error, resp) => {
        if (error) {
          console.log("OrdemServico.count Error:", error);
        } else {
          setFuturos(resp);
        }
      }
    );
  }, [socket]);

  const options = {
    title: {
      text: "Ordens de Serviço",
      style: {
        fontSize: "18px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: "#263238",
      },
    },
    legend: {
      position: "bottom",
    },
    labels: ["Para o dia", "Futuros", "Atrasados"],
  };
  const series = [atrasados, proDia, futuros];

  return (
    <Card
      className="shadow rounded p-1"
      onClick={() => {
        navigate("/relatorios/agendatecnica/top_n_ordens_de_servico");
      }}
    >
      <Chart options={options} series={series} type="pie" />
    </Card>
  );
}
