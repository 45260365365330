import React from "react";
import ApexCharts from "react-apexcharts";
import { Card, Col } from "react-bootstrap";

export default function GraficoCancelamentoPorFilial({ totalCancelamentosPorFilial }) {
  const keys = Object.keys(totalCancelamentosPorFilial);
  const values = Object.values(totalCancelamentosPorFilial);

  return (
    <Col className="mt-1">
      <Card className="shadow rounded">
        <ApexCharts
          options={{
            title: {
              text: `Cancelamentos x Filial`,
              style: {
                fontWeight: "normal",
                fontSize: "18px",
              },
            },
            plotOptions: {
              bar: {
                horizontal: true,
                columnWidth: "20%",
              },
            },
            dataLabels: {
              enabled: true,
            },
            xaxis: {
              categories: keys,
            },
            yaxis: {
              max: 6,
              title: {
                text: "Filiais",
                style: {
                  fontWeight: "normal",
                  fontSize: "15px",
                },
              },
            },
          }}
          series={[
            {
              name: "Cancelamento por Filial",
              data: values,
            },
          ]}
          type="bar"
          height={383}
        />
      </Card>
    </Col>
  );
}
