import IoContext from "contextos/IoContext";
import React from "react";
import { Table, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

export default function ModalAloFoneInfo({
  ContratoVoip,
  show,
  onHide,
  closeButton = true,
}) {
  const { socket } = React.useContext(IoContext);
  const [dados, setDados] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setDados({});
    if (!ContratoVoip?.id) return;

    setLoading(true);
    socket.emit(
      "AloFone.Detalhes",
      {
        ContratoVoipId: ContratoVoip?.id,
      },
      (error, ContratoVoip) => {
        setLoading(false);
        if (error) {
          console.log("AloFone.Detalhes:", error);
          return toast.error("" + error);
        }
        setDados(ContratoVoip);
      }
    );
  }, [socket, ContratoVoip?.id]);

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton={closeButton}>
        <Modal.Title>Alo Fone - Informações</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {loading && "Aguarde, carregando... "}

        <Table>
          <thead>
            <tr>
              <th>Item</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(dados).map((k) => {
              return (
                <tr key={k}>
                  <th>{k}</th>
                  <td>{dados[k]}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
}
