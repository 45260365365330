import React from "react";
import { Card, Table } from "react-bootstrap";

export default function CardListaEstoqueAlmoxarifado({ itens }) {
  return (
    <Card className="shadow rounded">
      <Table responsive>
        <thead>
          <tr className={"text-left"}>
            <th>Categoria</th>
            <th>Produto</th>
            <th>Quantidade</th>
            <th>Preço</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(itens).map((key) => {
            const item = itens[key];

            return (
              <tr key={key} className={"text-left"}>
                <td>{item.Produto?.Categoria?.nome}</td>
                <td>{item.Produto?.nome}</td>
                <td>{`${parseInt(item.quantidade)} ${
                  item.Produto?.unidade
                }`}</td>
                <td>{`${parseFloat(item.Produto?.preco).toFixed(2)}`}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Card>
  );
}
