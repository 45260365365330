import IoContext from "contextos/IoContext";
import React from "react";
import { Card, Form, Row, Col, Button, Container } from "react-bootstrap";
import { toast } from "react-toastify";

export default function Playhub() {
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(true);
  const [apiKey, setApiKey] = React.useState("");
  const [apiSecret, setApiSecret] = React.useState("");
  const [isAtivo, setAtivo] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    setApiKey("");
    setApiSecret("");
    socket.emit(
      "Integracao.findOne",
      {
        where: {
          modulo: "Playhub",
        },
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          return toast.error("" + error);
        }
        const config =
          typeof resp.configuracoes === "object" ? resp.configuracoes : JSON.parse(resp.configuracoes || "{}");
        setApiKey(config.apiKey);
        setApiSecret(config.apiSecret);
        setAtivo(resp.isAtivo);
      }
    );
  }, [socket]);

  return (
    <Container fluid>
      <Card>
        <Card.Header className="bg-primary">
          <Card.Title>Playhub - Configuração da API</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>ApiKey</Form.Label>
                <Form.Control disabled={loading} value={apiKey} onChange={(e) => setApiKey(e.target.value)} />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>ApiSecret</Form.Label>
                <Form.Control disabled={loading} value={apiSecret} onChange={(e) => setApiSecret(e.target.value)} />
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group>
                <Form.Label>Ativo</Form.Label>
                <Form.Check
                  checked={isAtivo}
                  onChange={() => {
                    setAtivo(!isAtivo);
                  }}
                  disabled={loading}
                />
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    disabled={loading}
                    onClick={(e) => {
                      e.preventDefault();
                      setLoading(true);
                      socket.emit(
                        "Integracao.Salvar",
                        {
                          modulo: "Playhub",
                          configuracoes: {
                            apiKey,
                            apiSecret,
                          },
                          isAtivo,
                        },
                        (error) => {
                          setLoading(false);
                          if (error) {
                            return toast.error("" + error);
                          }

                          toast.success("Configurações Atualizadas com Sucesso!");
                        }
                      );
                    }}
                  >
                    Salvar
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}
