import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import IoContext from "contextos/IoContext";
import { SituacoesContratos } from "paginas/contratos/ContratosTypes";

export default function CruzamentoCsvContratos() {
  const { socket } = React.useContext(IoContext);
  const [arquivo, setArquivo] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [clientes, setClientes] = React.useState([]);
  const [totalContratoPorSituacao, setTotalContratoPorSituacao] = React.useState({});
  const [totalContratos, setTotalContratos] = React.useState(0);
  const [totalSemContrato, setTotalSemContrato] = React.useState(0);
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    setClientes([]);
    if (!arquivo) return;

    let dup = {};
    let clientes = [];
    arquivo.split("\n").forEach((linhaCsv, idx) => {
      if (!linhaCsv) return;
      if (!idx) return;
      const dadosCsv = linhaCsv.split(",");
      if (dup[dadosCsv[1]]) return;

      clientes.push({
        nome: dadosCsv[0],
        doc1: ("" + dadosCsv[1]).trim(),
      });

      dup[dadosCsv[1]] = true;
    });

    setClientes(clientes);
    atualizarContratos({
      clientes,
      setLoading,
      setClientes,
      setTotalContratos,
      setTotalSemContrato,
      setTotalContratoPorSituacao,
      count,
      setCount,
      socket,
    });
  }, [arquivo, socket, count]);

  return (
    <Card className="m-2">
      <Card.Header>
        <Card.Title>Cruzamento de Arquivo Csv com Contratos</Card.Title>
      </Card.Header>

      <ProgressBar now={count} min={0} max={clientes.length} className={`${loading ? "" : "d-none"}`} />

      <Card.Body>
        <Row>
          <Col sm={8}>
            <Form.Label>Arquivo CSV:</Form.Label>
            <Form.Control
              type="file"
              accept=".csv"
              required
              onChange={(e) => {
                if (!e.target.files[0]) {
                  console.log("No File Selected");
                  return;
                }

                var reader = new FileReader();
                reader.onload = function () {
                  setArquivo(reader.result);
                };
                reader.readAsText(e.target.files[0]);
              }}
            />
          </Col>

          <Col sm={4}>
            <Form.Label>Ações</Form.Label>
            <div>
              <Button
                className="me-2"
                disabled={loading}
                onClick={() => {
                  let dados = [];
                  dados.push("Cliente,Cpf/Cnpj,Contrato,Situação,Situação Serviço");

                  clientes.forEach((cliente) => {
                    if (cliente.contratos.length > 0) {
                      (cliente.contratos || []).forEach((serc) => {
                        dados.push([
                          cliente.nome,
                          cliente.doc1,
                          serc.Contrato.numero_contrato,
                          SituacoesContratos[serc.Contrato.situacao],
                          serc.situacao,
                        ]);
                      });
                    } else {
                      dados.push([cliente.nome, cliente.doc1, "", "SEM CONTRATOS", ""]);
                    }
                  });

                  // Downlaod
                  let txtCsv = "data:text/csv;charset=utf-8,";
                  txtCsv += dados.join("\n");

                  var encodedUri = encodeURI(txtCsv);
                  var link = document.createElement("a");
                  link.setAttribute("href", encodedUri);
                  link.setAttribute("download", "CruzamentoCsv" + new Date().getTime() + ".csv");
                  document.body.appendChild(link); // Required for FF

                  link.click(); // This will download the data file named "my_data.csv".
                  link.remove();
                }}
              >
                Baixar Csv
              </Button>
            </div>
          </Col>
        </Row>
      </Card.Body>

      <Card.Body>
        <div>
          {(clientes || []).length} Total de Registros no Csv,
          {totalContratos} Contratos,
          {totalSemContrato} Sem Contrato
        </div>

        {Object.keys(SituacoesContratos).map((s) => {
          return (
            <span key={s} className="pe-2">
              | {totalContratoPorSituacao[s] || "0"} Contratos {SituacoesContratos[s]}
            </span>
          );
        })}
      </Card.Body>

      <Table>
        <thead>
          <tr>
            <th>Cliente</th>
            <th>Cpf / Cnpj</th>
            <th>Contratos</th>
          </tr>
        </thead>
        <tbody>
          {clientes.map((cliente, idx) => {
            return (
              <tr key={idx}>
                <td>{cliente.nome}</td>
                <td>{cliente.doc1}</td>
                <td></td>
                <td>
                  {(cliente.contratos || []).map((serc, idx2) => {
                    const c = serc.Contrato || {};
                    return (
                      <div key={`${idx}_${idx2}`}>
                        Contrato: {c.numero_contrato} - {SituacoesContratos[c.situacao]} - Serviço: {serc.situacao}
                      </div>
                    );
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Card>
  );
}

const atualizarContratos = async function ({
  clientes,
  setLoading,
  setClientes,
  setTotalContratos,
  setTotalSemContrato,
  setTotalContratoPorSituacao,
  count,
  setCount,
  socket,
}) {
  setLoading(true);
  let _clientes = [...clientes];
  let totalContratos = 0;
  let totalSemContrato = 0;
  let totalContratoPorSituacao = {};

  Promise.resolve()
    .then(() => {
      setCount(0);
      return (clientes || []).reduce((prevPromise, cliente, idx) => {
        return prevPromise.then(() => {
          return new Promise((resolve) => {
            socket.emit(
              "ServicoContratado.findAll",
              {
                attributes: ["id", "situacao"],
                withContrato: {
                  attributes: ["id", "numero_contrato", "situacao"],
                  withPessoa: {
                    where: {
                      doc1: cliente.doc1,
                    },
                  },
                  required: true,
                },
              },
              (error, resp) => {
                setCount(count + 1);

                if (error) {
                  _clientes[idx].contratos = [
                    {
                      id: 0,
                      situacao: "" + error,
                    },
                  ];
                } else {
                  _clientes[idx].contratos = resp;

                  if (resp.length <= 0) {
                    totalSemContrato++;
                  } else {
                    totalContratos += resp.length;
                    resp.forEach((serc) => {
                      if (!totalContratoPorSituacao[serc.Contrato.situacao]) {
                        totalContratoPorSituacao[serc.Contrato.situacao] = 0;
                      }

                      totalContratoPorSituacao[serc.Contrato.situacao]++;
                    });
                  }
                }
                resolve();
              }
            );
          });
        });
      }, Promise.resolve());
    })
    .then(() => {
      setLoading(false);
      setClientes(_clientes);
      setTotalContratos(totalContratos);
      setTotalSemContrato(totalSemContrato);
      setTotalContratoPorSituacao(totalContratoPorSituacao);
    });
};
