import React from "react";
import FormularioListagem from "../../../../componentes/FormularioListagem";
import IoContext from "../../../../contextos/IoContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { DateTime } from "luxon";

export default function Promocoes({ pagina, setPagina, texto, setTexto }) {
  const navigate = useNavigate();
  const { socket } = React.useContext(IoContext);
  const [loading, setloading] = React.useState(false);
  const [total, setTotal] = React.useState(0);
  const [Promocoes, setPromocoes] = React.useState([]);

  React.useEffect(() => {
    let where = {};
    if (texto) where.nome_like = `%${texto}%`;

    setloading(true);
    socket.emit(
      "Promocao.findAndCountAll",
      {
        attributes: ["id", "nome", "data_inicial", "data_final"],
        where,
        limit: 10,
        offset: 10 * (pagina - 1),
      },
      (error, resp) => {
        setloading(false);
        if (error) {
          return toast.error("" + error);
        } else {
          setPromocoes(resp.rows);
          setTotal(resp.count);
        }
      }
    );
  }, [texto, pagina, socket]);

  return (
    <FormularioListagem
      titulo={`Promoções`}
      itens={Promocoes || []}
      qtdItens={total}
      pagina={pagina}
      loading={loading}
      setTexto={setTexto}
      setPagina={setPagina}
      titulos={["Id", "Nome", "Data Inicial", "Data Final"]}
      onClick={(e) => {
        navigate(`${e.id}`);
      }}
      renderItem={(titulo, item) => {
        switch (titulo) {
          case "Id":
            return item.id;
          case "Nome":
            return item.nome;
          case "Data Inicial":
            return DateTime.fromISO(item.data_inicial).toLocaleString(DateTime.DATE_SHORT);
          case "Data Final":
            return item.data_final ? DateTime.fromISO(item.data_final).toLocaleString(DateTime.DATE_SHORT) : "----";
          default:
            return titulo;
        }
      }}

      // renderFiltros={()=>{
      //     return <React.Fragment>
      //         <Form.Group>
      //             <Form.Label>UF</Form.Label>
      //         </Form.Group>
      //     </React.Fragment>
      // }}
    />
  );
}
