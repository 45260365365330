import React from "react";
import Container from "react-bootstrap/Container";
import TituloContext from "contextos/TituloContext";
import IntegracaoHistoricoEventos from "../IntegracaoHistoricoEventos";
import IoContext from "contextos/IoContext";
import { toast } from "react-toastify";
import { Card, Row, Form, Col, Button } from "react-bootstrap";
import ModalTcIRR from "./ModalTcIRR";

export const modulo = "TcIRR";

export default function TcIRR() {
  const { setTitulo } = React.useContext(TituloContext);
  const { socket } = React.useContext(IoContext);
  const [loading, setLoading] = React.useState(false);
  const [asn, setAsn] = React.useState("");
  const [token, setToken] = React.useState("");
  const [asSet, setAsSet] = React.useState("");
  const [isAtivo, setAtivo] = React.useState(false);
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    setTitulo("TC IRR - bgp.net.br");
  });

  React.useEffect(() => {
    setLoading(true);
    setToken("");
    setAtivo(false);
    socket.emit(
      "Integracao.findOne",
      {
        where: {
          modulo,
        },
      },
      (error, resp) => {
        setLoading(false);
        if (error) {
          return toast.error("" + error);
        }

        const config =
          typeof resp.configuracoes === "object" ? resp.configuracoes : JSON.parse(resp.configuracoes || "{}");
        setToken(config.token);
        setAsn(config.asn || "");
        setAsSet(config.asSet || "");
        setAtivo(resp.isAtivo);
      }
    );
  }, [socket]);

  return (
    <Container fluid className="mt-2">
      <Card>
        <Card.Header className="bg-primary text-light">
          <Card.Title>TC IRR (bgp.net.br) - Configuração da API</Card.Title>
        </Card.Header>

        <Card.Body>
          <Row>
            <Col sm={2}>
              <Form.Group>
                <Form.Label>Senha</Form.Label>
                <Form.Control
                  type="password"
                  disabled={loading}
                  value={token}
                  onChange={(e) => setToken(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group>
                <Form.Label>ASN</Form.Label>
                <Form.Control disabled={loading} value={asn} onChange={(e) => setAsn(e.target.value)} />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group>
                <Form.Label>AS-SET</Form.Label>
                <Form.Control disabled={loading} value={asSet} onChange={(e) => setAsSet(e.target.value)} />
              </Form.Group>
            </Col>

            <Col sm={1}>
              <Form.Group>
                <Form.Label>Ativo</Form.Label>
                <Form.Check
                  checked={isAtivo}
                  onChange={() => {
                    setAtivo(!isAtivo);
                  }}
                  disabled={loading}
                />
              </Form.Group>
            </Col>

            <Col sm={3}>
              <Form.Group>
                <Form.Label>Ações</Form.Label>
                <div>
                  <Button
                    disabled={loading}
                    className="me-2"
                    onClick={(e) => {
                      e.preventDefault();
                      setLoading(true);
                      socket.emit(
                        "Integracao.Salvar",
                        {
                          modulo,
                          configuracoes: {
                            token,
                            asn,
                            asSet,
                          },
                          isAtivo,
                        },
                        (error) => {
                          setLoading(false);
                          if (error) {
                            return toast.error("" + error);
                          }

                          toast.success("Configurações Atualizadas com Sucesso !");
                        }
                      );
                    }}
                  >
                    Salvar
                  </Button>

                  <Button
                    disabled={loading || !asSet}
                    onClick={(e) => {
                      setShow(true);
                    }}
                  >
                    Consultar AS-SET
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <IntegracaoHistoricoEventos modulo={modulo} />

      <ModalTcIRR show={show} onHide={setShow} asSet={asSet} />
    </Container>
  );
}
