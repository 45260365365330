import React, { useState, useEffect, useContext } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";

import IoContext from "contextos/IoContext";
import TituloContext from "contextos/TituloContext";
import CampoData from "componentes/CampoData";
import { GraficoPrincipal, GraficoSecundario } from "./GraficosDashboardComercial";

import { getInicioMes, formatarDataDbs, formatarDataHoraDbs } from "misc/lhdatas";
import SelectUsuarios from "paginas/sistema/SelectUsuario";
import SelectFiliais from "paginas/cadastros/administrativo/filiais/SelectFiliais";

const KEY_ATUALIZAR_STORAGE = `${window.location.hostname}_dashboardcomercial_atualizar`;
const atualizarInicial = JSON.parse(localStorage.getItem(KEY_ATUALIZAR_STORAGE));

export default function DashBoardComercial() {
  const { socket } = React.useContext(IoContext);
  const [atualizar, setAtualizar] = useState(atualizarInicial);
  const [UsuarioId, setUsuarioId] = useState("");
  const [FilialId, setFilialId] = useState("");
  let [dados, setDados] = useState(null);
  let [isLoading, setIsLoading] = useState(false);

  const { setTitulo } = useContext(TituloContext);
  const [dataInicial, setDataInicial] = React.useState(formatarDataDbs(getInicioMes()));
  const [dataFinal, setDataFinal] = React.useState(formatarDataDbs(new Date()));

  useEffect(() => {
    setTitulo("Dashboard Comercial");
  }, [setTitulo]);

  useEffect(() => {
    atualizarDados({ socket, setDados, setIsLoading, UsuarioId, dataInicial, dataFinal, FilialId });

    if (!atualizar) return;

    const interval = setInterval(() => {
      atualizarDados({ socket, setDados, setIsLoading, UsuarioId, dataInicial, dataFinal, FilialId });
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [socket, dataFinal, dataInicial, atualizar, UsuarioId, FilialId]);

  return (
    <Container fluid className="h-auto">
      <Row className="h-50 gy-0">
        <Col className="text-center align-self-center">
          <Card className="p-4 mx-auto w-75">
            <h1>Cadastros</h1>
            <h3>{dados?.quantidadeDeCadastros || 0}</h3>
          </Card>
          <Card className="p-4 mt-4 mx-auto w-75">
            <h1>Instalações</h1>
            <h3>{dados?.quantidadeInstalada || 0}</h3>
          </Card>
        </Col>
        <Col md={8}>
          <Row className="justify-content-end">
            <Col md={4}>
              <Form.Group>
                <Form.Label>Período</Form.Label>
                <InputGroup>
                  <CampoData
                    className="me-2"
                    value={dataInicial}
                    disabled={isLoading}
                    onChange={(e) => setDataInicial(e.target.value)}
                  />
                  <CampoData value={dataFinal} disabled={isLoading} onChange={(e) => setDataFinal(e.target.value)} />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group>
                <Form.Label>Filial</Form.Label>
                <SelectFiliais disabled={isLoading} value={FilialId} onChange={(e) => setFilialId(e.target.value)} />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group>
                <Form.Label>Atendente:</Form.Label>
                <SelectUsuarios
                  value={UsuarioId}
                  disabled={isLoading}
                  onChange={(e) => {
                    setUsuarioId(e.target.value);
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={"auto"}>
              <Form.Group className="text-center">
                <Form.Label>Atualizar:</Form.Label>
                <Form.Switch
                  type="checkbox"
                  checked={atualizar}
                  onChange={(e) => {
                    setAtualizar(e.target.checked);
                    localStorage.setItem(KEY_ATUALIZAR_STORAGE, JSON.stringify(e.target.checked));
                  }}
                ></Form.Switch>
              </Form.Group>
            </Col>
          </Row>
          <Row className="h-100">
            <Col>
              <GraficoPrincipal title={"Contratos por Plano"} data={dados?.contratosPorPlano} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="h-50 border-top">
        <Col sm={12} md={4} className="px-4 pt-4">
          <GraficoSecundario title={"Cadastros por Atendente"} data={dados?.contratosPorAtendente} />
        </Col>
        <Col sm={12} md={4} className="pt-4 border-start border-end">
          <GraficoSecundario title={"Vendas Instaladas"} data={dados?.vendasInstaladas} />
        </Col>
        <Col sm={12} md={4} className="px-4 pt-4">
          <GraficoSecundario title={"Por Região"} data={dados?.porRegiao} />
        </Col>
      </Row>
    </Container>
  );
}

const atualizarDados = ({ socket, setDados, setIsLoading, UsuarioId, dataInicial, dataFinal, FilialId }) => {
  setIsLoading(true);
  setDados({});

  socket.emit(
    "Relatorios.DashboardComercial",
    {
      FilialId: FilialId || undefined,
      UsuarioId: UsuarioId || undefined,
      dataInicial: formatarDataHoraDbs(new Date(`${dataInicial} 00:00:00`)),
      dataFinal: formatarDataHoraDbs(new Date(`${dataFinal} 23:59:59`)),
    },
    (error, resp) => {
      setIsLoading(false);
      if (error) {
        toast.error("" + error);
        return;
      }
      setDados(resp);
    }
  );
};
