import React from 'react';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';

export default function CardListaTopAtendimentos({ atendimentos, nOrdensServico }){
    return <Card className="shadow rounded mt-2 d-flex">
        <Table bordered responsive>
            <thead>
                <tr>
                    <th>Filial</th>
                    <th>Contrato</th>
                    <th>Cliente</th>
                    <th>Qtd. Atendimentos</th>
                    <th>Qtd. de Ordens de Serviço</th>
                </tr>
            </thead>
            <tbody>
                { (atendimentos || []).map( (att, key) => {
                    const contrato = att.Contrato || {};
                    const filial = contrato.Filial || {};
                    const pessoa = contrato.Pessoa || {};

                    return <tr key={key}>
                        <td>{filial.nome}</td>
                        <td>{contrato.contrato}</td>
                        <td>{pessoa.nome}</td>
                        <td>{att.nAtendimentos}</td>
                        <td>{ nOrdensServico[att.ContratoId] !== undefined ? nOrdensServico[att.ContratoId] : '0'}</td>
                    </tr>
                })}
            </tbody>
        </Table>
    </Card>;
}