import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import { toast } from 'react-toastify';
import SelectUf from './SelectUf';
import SelectCidade from './SelectCidade';
import CampoCep from '../../../../componentes/CampoCep';
import BotaoApagar from '../../../../componentes/BotaoApagar';
import CabecalhoCadastro from '../../../../componentes/CabecalhoCadastro';
import IoContext from '../../../../contextos/IoContext';
import { useNavigate, useParams } from 'react-router';

export default function Endereco(){
    const params = useParams();
    const navigate = useNavigate();
    const { socket } = React.useContext(IoContext);
    const id = parseInt(params.id);
    const [uf, setUf] = React.useState('');
    const [cidade, setCidade] = React.useState('');
    const [bairro, setBairro] = React.useState('');
    const [logradouro, setLogradouro] = React.useState('');
    const [cep, setCep] = React.useState('');
    const [tipo, setTipo] = React.useState('U');
    const [loading, setLoading] = React.useState(false);
    
    React.useEffect(()=>{
        if(isNaN(id)) return;
        
        setLoading(true);
        socket.emit("Endereco.findOne", {where: { id }}, (error, resp) => {
            setLoading(false);
            if(error) {
                toast.error(''+error);
            } else if(resp) {
                setUf(resp.uf);
                setCidade(resp.cidade);
                setBairro(resp.bairro);
                setLogradouro(resp.logradouro);
                setCep(resp.cep);
                setTipo(resp.tipo);
            }
        });
    }, [id, socket]);

    return <Container fluid className="pt-3">
        <Card>
            <CabecalhoCadastro 
                id={id}
                disabled={loading}
                titulo='Endereço'
                salvar={ e => {
                    e.preventDefault();
                    setLoading(true);
                    socket.emit("Endereco.salvar", {
                        id, uf, cidade, bairro, logradouro, cep, tipo
                    }, (error, resp) => {
                        setLoading(false);
                        if(error) {
                            toast.error(error);
                        } else {
                            toast.success("Endereço Salvo com Sucesso !");
                            navigate(-1);
                        }
                    });
                }}
                />

            <Card.Body>
                <Row>   
                    <Col sm={3}>
                        <Form.Group>
                            <Form.Label>Id</Form.Label>
                            <Form.Control  value={isNaN(id) ? "" : id} readOnly />
                        </Form.Group>
                    </Col>

                    <Col sm={3}>
                        <Form.Label>Uf</Form.Label>
                        <SelectUf
                            value={uf}
                            disabled={loading}
                            onChange={ e => setUf(e.target.value)}
                            />
                    </Col>
                    <Col sm={3}>
                        <Form.Group>
                            <Form.Label>Cidade</Form.Label>
                            <SelectCidade value={cidade} uf={uf} onChange={ e => {
                                setCidade(e.target.value);
                            }} />
                        </Form.Group>
                    </Col>

                    <Col sm={3}>
                        <Form.Group>
                            <Form.Label>Bairro</Form.Label>
                            <Form.Control 
                                disabled={loading}
                                value={bairro}
                                onChange={ e => setBairro(e.target.value) }
                                />
                        </Form.Group>
                    </Col>

                    <Col sm={6}>
                        <Form.Group>
                            <Form.Label>Logradouro</Form.Label>
                            <Form.Control 
                                disabled={loading}
                                value={logradouro}
                                onChange={ e => setLogradouro(e.target.value) }
                                />
                        </Form.Group>
                    </Col>

                    <Col sm={3}>
                        <CampoCep
                            disabled={loading}
                            value={cep}
                            onChange={ txt => setCep(txt) }
                            />
                    </Col>

                    <Col sm={3}>
                        <Form.Group>
                            <Form.Label>Tipo</Form.Label>

                            <div>
                                <Form.Check type="radio" inline label="Urbano"
                                    disabled={loading} id="tipoU"
                                    checked={tipo==='U'} name="tipo"
                                    onChange={ e => setTipo('U') }
                                    />
                                <Form.Check type="radio" inline label="Rural"
                                    disabled={loading} id="tipoR"
                                    checked={tipo==='R'} name="tipo"
                                    onChange={ e => setTipo('R') }
                                    />
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
            </Card.Body>

            <Card.Footer>
                { isNaN(id) ? false : <BotaoApagar 
                    disabled={loading}
                    apagar={ e => {
                        setLoading(true);
                        socket.emit("Endereco.apagar", { 
                            id 
                        }, (error, resp) => {
                            setLoading(false);
                            if(error) {
                                toast.error(''+error);
                            } else {
                                toast.success("Endereço Removido com Sucesso !");
                                navigate(-1);
                            }
                        });
                    }}
                    /> }
            </Card.Footer>
        </Card>
    </Container>;
}