import React from 'react';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import IoContext from '../../../../contextos/IoContext';

export default function SelectFornecedores(props){
    const { socket } = React.useContext(IoContext);
    const { id, value, onChange, disabled, pular } = props; 
    const [Fornecedores, setFornecedores] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    
    React.useEffect(()=>{
        setLoading(true);
        socket.emit("Fornecedor.findAll", { 
            order: [["NOME", "ASC"]]
        }, (error, resp) => {
            setLoading(false);
            if(error){
                toast.error(error);
            } else  {
                setFornecedores(resp);
            }
        })
    }, [socket]);

    return <Form.Control as="select" 
        id={id || "fornecedorId"} 
        onChange={onChange}
        value={value}
        disabled={loading || disabled}
        {...props}
    >
        <option value=""></option>
        { (Fornecedores || []).map( fornecedor => {
            if(pular) {
                if(Array.isArray(pular)) {
                    if(pular.indexOf(fornecedor.id)){
                        return false;
                    }
                } else {
                    if(+pular === +fornecedor.id) {
                        return false;
                    }
                }
            }
            return <option key={fornecedor.id} value={fornecedor.id}>{fornecedor.nome}</option>
        })}
    </Form.Control>;
}