import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

import IoContext from '../../contextos/IoContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faFilter, faInfo } from '@fortawesome/free-solid-svg-icons';

import SelectGrupoAtendimento from './SelectGrupoAtendimento';
import SelectFiliais from '../cadastros/administrativo/filiais/SelectFiliais';
import ModalDetalhesAtendimento from './ModalDetalhesAtendimento';
import { SituacoesAtendimento, ATENDIMENTO_EM_ABERTO } from '../../datatypes/AtendiemntosTypes';
import CampoData from '../../componentes/CampoData';
import Paginacao from '../../componentes/Paginacao';

export default function TabAgendaAtendimentos() {
    const { socket } = React.useContext(IoContext);
    const [FilialId, setFilialId] = React.useState(0);
    const [GrupoAtendimentoId, setGrupoAtendimentoId] = React.useState(0);
    const [aberturaIni, setAberturaIni] = React.useState('');
    const [aberturaEnd, setAberturaEnd] = React.useState('');
    const [conclusaoIni, setConclusaoIni] = React.useState('');
    const [conclusaoEnd, setConclusaoEnd] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [showFiltros, setShowFiltros] = React.useState(false);
    const [atendimentos, setAtendimentos] = React.useState([]);
    const [situacao, setSituacao] = React.useState(ATENDIMENTO_EM_ABERTO);
    const [contador, setContador] = React.useState(0);
    const [pagina, setPagina] = React.useState(1);
    const [total, setTotal] = React.useState(0);
    const [porPagina, setPorPagina] = React.useState(10);
    const [AtendimentoId, setAtendimentoId] = React.useState(0);

    React.useEffect(() => {
        setAtendimentos([]);
        setLoading(true);

        let where = {
            situacao
        }

        if (aberturaIni && aberturaEnd) {
            const dAberturaIni = new Date(aberturaIni); dAberturaIni.setHours(0); dAberturaIni.setMinutes(0); dAberturaIni.setSeconds(0);
            const dAberturaEnd = new Date(aberturaEnd); dAberturaEnd.setHours(23); dAberturaEnd.setMinutes(59); dAberturaEnd.setSeconds(59);
            where.data_abertura_between = [
                dAberturaIni.toISOString(),
                dAberturaEnd.toISOString(),
            ];
        }

        if (conclusaoIni && conclusaoEnd) {
            const dConclusaoIni = new Date(conclusaoIni); dConclusaoIni.setHours(0); dConclusaoIni.setMinutes(0); dConclusaoIni.setSeconds(0);
            const dConclusaoEnd = new Date(conclusaoEnd); dConclusaoEnd.setHours(23); dConclusaoEnd.setMinutes(59); dConclusaoEnd.setSeconds(59);

            where.data_conclusao_between = [
                dConclusaoIni.toISOString(),
                dConclusaoEnd.toISOString(),
            ];
        }
        if (FilialId) where.FilialId = FilialId;
        if (GrupoAtendimentoId) where.GrupoAtendimentoId = GrupoAtendimentoId;

        socket.emit("Atendimento.findAndCountAll", {
            attributes: ['id', 'data_abertura'],
            where,
            order: [['data_abertura']],
            withGrupoAtendimento: {
                attributes: ['nome'],
            },
            withContrato: {
                attributes: ['numero_contrato'],
                withFilial: {
                    attributes: ['nome']
                },
                withEndereco: {
                    attributes: ['bairro', 'logradouro']
                },
                withPessoa: {
                    attributes: ['nome', 'telefone1', 'telefone2', 'telefone3', 'telefone4'],
                }
            },
            limit: porPagina, offset: porPagina * (pagina - 1)
        }, (error, resp) => {
            setLoading(false);

            if (error) {
                return console.log('ERROR:', error);
            }

            setTotal(resp.count);
            setAtendimentos(resp.rows);
        });
    }, [
        socket, aberturaIni, aberturaEnd, conclusaoIni, conclusaoEnd,
        FilialId, GrupoAtendimentoId, situacao, contador, pagina, porPagina
    ]);

    return <div>
        <Card.Title>
            <Row>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label>Filial</Form.Label>
                        <SelectFiliais disabled={loading}
                            value={FilialId} onChange={e => setFilialId(e.target.value)} />
                    </Form.Group>
                </Col>

                <Col sm={4}>
                    <Form.Group>
                        <Form.Label>Data de Abertura</Form.Label>
                        <InputGroup>
                            <CampoData disabled={loading}
                                value={aberturaIni} onChange={e => setAberturaIni(e.target.value)} />
                            <CampoData disabled={loading}
                                value={aberturaEnd} onChange={e => setAberturaEnd(e.target.value)} />
                        </InputGroup>
                    </Form.Group>
                </Col>

                <Col sm={2}>
                    <Form.Group>
                        <Form.Label>Situação</Form.Label>
                        <Form.Control as="select" disabled={loading}
                            value={situacao} onChange={e => setSituacao(+e.target.value)}>
                            {Object.keys(SituacoesAtendimento).map(i => {
                                return <option key={i} value={i}>{SituacoesAtendimento[i]}</option>
                            })}
                        </Form.Control>
                    </Form.Group>
                </Col>

                <Col sm={2}>
                    <Form.Group>
                        <Form.Label>Ações</Form.Label>
                        <div>
                            <Button className="me-2" variant="info" title="Exibir Mais Filtros" onClick={() => setShowFiltros(true)}>
                                <FontAwesomeIcon icon={faFilter} />
                            </Button>

                            <Button title="Atualizar Lista" onClick={() => {
                                setContador(contador + 1);
                            }}>
                                <FontAwesomeIcon icon={faSearch} />
                            </Button>
                        </div>
                    </Form.Group>
                </Col>
            </Row>
        </Card.Title>

        <Table responsive size="sm" hover>
            <thead>
                <tr>
                    <th></th>
                    <th>Protocolo</th>
                    <th>Aberto Em</th>
                    <th>Grupo</th>
                    <th>Atendente</th>
                    <th>Contrato</th>
                    <th>Filial</th>
                    <th>Cliente</th>
                    <th>Bairro</th>
                    <th>Endereço</th>
                    <th>Telefones</th>
                </tr>
            </thead>
            <tbody>
                {(atendimentos || []).map(att => {
                    const grupo = att.GrupoAtendimento || {};
                    const contrato = att.Contrato || {};
                    const endereco = contrato.Endereco || {};
                    const filial = contrato.Filial || {};
                    const pessoa = contrato.Pessoa || {};
                    const atendente = att.Atendente || {};

                    return <tr key={att.id}>
                        <td>
                            <Button onClick={() => {
                                // setAtendimentoId(att.id);                                
                                // @ts-ignore
                                const lh = window.top.lh || {};
                                lh.loadForm("agendatec/atendimento",function(){
                                    lh.loadForm("agendatec/os",function(){
                                        lh.forms['agendatec/atendimento'].dialogDetalhes(att.id);
                                    });
                                });
                            }}>
                                <FontAwesomeIcon icon={faInfo} />
                            </Button>
                        </td>
                        <td>{att.id}</td>
                        <td>{(new Date(att.data_abertura)).toLocaleString()}</td>
                        <td>{grupo.nome}</td>
                        <td>{atendente.nome}</td>
                        <td>{contrato.numero_contrato}</td>
                        <td>{filial.nome}</td>
                        <td>{pessoa.nome}</td>
                        <td>{endereco.bairro}</td>
                        <td>{endereco.logradouro}</td>
                        <td>{pessoa.telefone1} {pessoa.telefone2} {pessoa.telefone3} {pessoa.telefone4}</td>
                    </tr>
                })}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={11} className="text-right">
                        {total} Atendimentos
                    </td>
                </tr>
            </tfoot>
        </Table>

        <Paginacao pagina={pagina} setPagina={setPagina} total={total} />


        <Modal
            show={showFiltros}
            onHide={() => setShowFiltros(false)}
            dialogClassName="modalFiltros">
            <Modal.Header closeButton>
                Filtros
            </Modal.Header>

            <Modal.Body>
                <Form.Group>
                    <Form.Label>Filial</Form.Label>
                    <SelectFiliais disabled={loading}
                        value={FilialId} onChange={e => setFilialId(e.target.value)} />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Grupo</Form.Label>
                    <SelectGrupoAtendimento disabled={loading}
                        value={GrupoAtendimentoId} onChange={e => setGrupoAtendimentoId(e.target.value)} />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Data de Abertura</Form.Label>
                    <InputGroup>
                        <CampoData disabled={loading}
                            value={aberturaIni} onChange={e => setAberturaIni(e.target.value)} />
                        <CampoData disabled={loading}
                            value={aberturaEnd} onChange={e => setAberturaEnd(e.target.value)} />
                    </InputGroup>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Data de Conclusão</Form.Label>
                    <InputGroup>
                        <CampoData disabled={loading}
                            value={conclusaoIni} onChange={e => setConclusaoIni(e.target.value)} />
                        <CampoData disabled={loading}
                            value={conclusaoEnd} onChange={e => setConclusaoEnd(e.target.value)} />
                    </InputGroup>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Situação</Form.Label>
                    <Form.Control as="select" disabled={loading}
                        value={situacao} onChange={e => setSituacao(+e.target.value)}>
                        {Object.keys(SituacoesAtendimento).map(i => {
                            return <option key={i} value={i}>{SituacoesAtendimento[i]}</option>
                        })}
                    </Form.Control>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Registros por Página</Form.Label>
                    <Form.Control as="select" disabled={loading}
                        value={porPagina} onChange={e => setPorPagina(+e.target.value)}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={250}>250</option>
                    </Form.Control>
                </Form.Group>
            </Modal.Body>
        </Modal>

        <ModalDetalhesAtendimento
            show={AtendimentoId > 0}
            onHide={() => setAtendimentoId(0)}
            id={AtendimentoId}
        />
    </div>
}
