import React from 'react';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import IoContext from '../../../contextos/IoContext';
import { toast } from 'react-toastify';

export default function ModalSelecionarItemPag2({ tipo, pagina, setPagina, setItem }) {
    const { socket } = React.useContext(IoContext);
    const [texto, setTexto] = React.useState('');
    const [listagem, setListagem] = React.useState([]);

    const getListagem = function () {
        let modulo = tipo === "PRODUTO" ? "Produto" : "Categoria";

        socket.emit(`${modulo}.findAll`, {
            attributes: ["id", "nome"],
            where: {
                nome_like: `%${texto}%`
            },
            limit: 25
        }, (error, resp) => {
            if (error) {
                toast.error('' + error);
            } else {
                setListagem(resp);
            }
        });
    }

    return <React.Fragment>
        <Form onSubmit={e => {
            e.preventDefault();
            getListagem();
        }}>
            <Form.Group>
                <Form.Label>Selecionar o {tipo === "PRODUTO" ? "Produto" : "Categoria"}</Form.Label>
                <InputGroup>
                    <Form.Control
                        autoFocus
                        value={texto}
                        onChange={e => setTexto(e.target.value)}
                    />

                    <Button type="submit">
                        <FontAwesomeIcon icon={faSearch} />
                    </Button>
                </InputGroup>
            </Form.Group>
        </Form>

        <Table hover>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Nome</th>
                </tr>
            </thead>
            <tbody>
                {(listagem || []).map(item => {
                    return <tr onClick={() => {
                        setItem(item);
                        setPagina(pagina + 1);
                    }} style={{ cursor: 'pointer' }} key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.nome}</td>
                    </tr>
                })}
            </tbody>
        </Table>
    </React.Fragment>
}