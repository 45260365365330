import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import Container from 'react-bootstrap/Container';
import Listagem from '../../../componentes/Listagem';
import TituloContext from '../../../contextos/TituloContext';
import LoadingContext from '../../../contextos/LoadingContext';
import SelectFuncionario from '../../cadastros/administrativo/functionaios/SelectFuncionario';
import SelectCategorias from '../categorias/SelectCategorias';
import { toast } from 'react-toastify';
import IoContext from '../../../contextos/IoContext';
import { useNavigate, useParams } from 'react-router';

export default function EstoquePorFuncionario() {
    const params = useParams();
    const navigate = useNavigate();
    const { socket } = React.useContext(IoContext);
    const { loading } = React.useContext(LoadingContext);
    const id = isNaN(parseInt(params.id)) ? "" : +params.id;
    const { setTitulo } = React.useContext(TituloContext);
    const [FuncionarioId, setFuncionarioId] = React.useState(id);
    const [CategoriaId, setCategoriaId] = React.useState('');
    const [itens, setItens] = React.useState([]);
    const [exibirCategorias, setExbirCategorias] = React.useState(true);
    const [produto, setProduto] = React.useState('')

    React.useEffect(() => {
        getEstoque({ socket, CategoriaId, FuncionarioId, exibirCategorias, setItens, produto })
    }, [socket, CategoriaId, FuncionarioId, exibirCategorias, setItens, produto]);

    React.useEffect(() => {
        setTitulo(`Estoque do Funcionário ${id ? `[${id}]` : ''}`);
        setFuncionarioId(id);
    }, [setTitulo, id]);

    return <Container fluid className="pt-3">
        <Card>
            <Card.Body>
                <Row>
                    <Col sm={2}>
                        <Form.Group>
                            <Form.Label>Funcionário</Form.Label>
                            <SelectFuncionario
                                value={FuncionarioId}
                                required
                                disabled={loading}
                                onChange={e => {
                                    if (FuncionarioId) {
                                        navigate(`../por_funcionario/${e.target.value}`);
                                    } else {
                                        navigate(`${e.target.value}`);
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>

                    <Col sm={3} className='noprint'>
                        <Form.Group>
                            <Form.Label>Categoria</Form.Label>
                            <InputGroup>
                                <InputGroup.Checkbox type="checkbox"
                                    title={
                                        exibirCategorias ?
                                            "Exibir Somente Produtos da Categoria Selecionada !" :
                                            "Exibir Produtos de todas as Categorias *Exceto* a Selecionada !"
                                    }
                                    checked={exibirCategorias} onChange={e => setExbirCategorias(!exibirCategorias)}
                                />
                                <SelectCategorias value={CategoriaId} onChange={e => setCategoriaId(e.target.value)} />
                            </InputGroup>
                        </Form.Group>
                    </Col>

                    <Col sm={3} className='noprint'>
                        <Form.Group>
                            <Form.Label>Produto</Form.Label>
                            <Form.Control as='input'
                                disabled={loading || !FuncionarioId}
                                onChange={e => setProduto(e.target.value)}
                            />
                        </Form.Group>
                    </Col>

                    <Col sm={4} className='noprint'>
                        <Form.Group>
                            <Form.Label>Ações</Form.Label>
                            <div>
                                <Button
                                    className='me-2'
                                    variant='success'
                                    disabled={loading || !FuncionarioId}
                                    onClick={() => {
                                        getEstoque({ socket, CategoriaId, FuncionarioId, exibirCategorias, setItens, produto })
                                    }}>
                                    Atualizar
                                </Button>

                                <Button
                                    variant='info'
                                    className='me-2'
                                    disabled={loading || !FuncionarioId}
                                    onClick={() => { navigate(`historico`) }}>
                                    Histórico
                                </Button>

                                <Button className="me-2"
                                    disabled={loading || !FuncionarioId}
                                    onClick={() => { navigate(`devolucao`) }}>
                                    Devolução
                                </Button>

                                <Button className="me-2"
                                    onClick={() => { 
                                        window.print(); 
                                    }}>
                                    Imprimir
                                </Button>
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
            </Card.Body>
        </Card>

        <Card className="mt-3">
            <Card.Body>
                <Listagem
                    titulos={['Id', 'Categoria', 'Produto', 'Quantidade']}
                    itens={itens || []}
                    tamanhos={[
                        { sm: 12, md: 2 },
                        { sm: 12, md: 2 },
                        { sm: 12, md: 6 },
                        { sm: 12, md: 2 },
                    ]}
                    renderItem={(titulo, item) => {
                        switch (titulo) {
                            case 'Id': return item.id;
                            case 'Categoria':
                                const Produto = item.Produto || {};
                                const Categoria = Produto.Categoria || {};
                                return Categoria.nome || '----';
                            case 'Produto': return item.Produto ? item.Produto.nome : '----';
                            case 'Quantidade': return item.quantidade
                            default: return titulo
                        }
                    }}
                />
            </Card.Body>

            <Card.Footer>
            </Card.Footer>
        </Card>
    </Container>
}

function getEstoque({ socket, CategoriaId, FuncionarioId, exibirCategorias, setItens, produto }) {
    let whereProduto = {};
    whereProduto.nome_like = `${produto}%`;
    if (CategoriaId) {
        if (exibirCategorias) {
            whereProduto.CategoriaId = CategoriaId;
        } else {
            whereProduto.CategoriaId_ne = CategoriaId;
        }
    }

    setItens([]);
    socket.emit("EstoqueFuncionario.findAll", {
        attributes: ['id', 'quantidade'],
        where: {
            FuncionarioId,
            quantidade_gt: 0
        },
        withProduto: {
            attributes: ['id', 'nome'],
            where: whereProduto,
            withCategoria: {
                attributes: ['id', 'nome'],
            }
        }
    }, (error, resp) => {
        if (error) {
            toast.error('' + error);
        } else {
            setItens(resp);
        }
    });
}