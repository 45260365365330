export const SituacoesOrdensServico = {
  0: "ABERTA",
  1: "CONCLUÍDA",
};

export const TiposOrdemServico = {
  1: "REPARO",
  5: "VIABILIDADE",
  6: "DOCUMENTAÇÃO",
  2: "INSTALAÇÃO",
  3: "RETIRADA",
  4: "COBRANCA",
  8: "MUDANÇA DE ENDEREÇO",
  9: "MUDANÇA DE TECNOLOGIA",
  7: "INFRAESTRUTURA",
  10: "NEGOCIACAO",
  12: "RETORNO",
  15: "ACOMPANHAMENTO",
  16: "ACORDO",
  17: "NETATIVACAO",
  18: "TROCA DE ROTEADOR",
  19: "ENTREGA DE BOLETO",
  20: "FAST LOGGI RETIRADA",
  25: "OUTROS",
};

export const OS_ABERTA = 0;
export const OS_CONCLUIDA = 1;

export const CONCLUIDA_COM_SUCESSO = 1;
export const CONCLUIDA_SEM_SUCESSO = 2;

export const TIPO_REPARO = 1;
export const TIPO_INSTALACAO = 2;
export const TIPO_RETIRADA = 3;
export const TIPO_COBRANCA = 4;
export const TIPO_VIABILIDADE = 5;
export const TIPO_DOCUMENTOS = 6;
export const TIPO_AGUARDANDO_AMPLICAO = 7;
export const TIPO_MUDANCA_ENDERECO = 8;
export const TIPO_MUDANCA_TECNOLOGIA = 9;
export const TIPO_NEGATIVACAO = 10;
export const TIPO_OUTROS = 25;

export const CATEGORIA_REPARO = 1;
export const CATEGORIA_INSTALACAO = 2;
export const CATEGORIA_RETIRADA = 3;
export const CATEGORIA_MUDANCA_ENDERECO = 4;
export const CATEGORIA_MUDANCA_TECNOLOGIA = 5;
export const CATEGORIA_CANCELAMENTO = 6;
export const CATEGORIA_OUTROS = 20;
