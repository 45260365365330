import React from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router";
import AuthContext from "../../contextos/AuthContext";
import IoContext from "../../contextos/IoContext";
import { DateTime } from "luxon";

export default function CardAlarmesDosContratos() {
  const navigate = useNavigate();
  const { socket } = React.useContext(IoContext);
  const { usuario } = React.useContext(AuthContext);
  const [alarmes, setAlarmes] = React.useState([]);

  React.useEffect(() => {
    const d = new Date();
    d.setHours(23);
    d.setMinutes(59);
    d.setSeconds(59);

    let where = {
      data_alarme_lte: d.toISOString(),
      dispensadoEm: null,
    };

    if (usuario && usuario.GrupoAtendimentoId) {
      where.GruposAtendimentoId = usuario.GrupoAtendimentoId;
    }

    if (usuario) {
      socket.emit(
        "AlarmeContrato.findAll",
        {
          where,
          withContrato: {
            withPessoa: {},
          },
        },
        (error, resp) => {
          if (error) {
            console.log("## CARD ALARMES ERROR:", error);
          } else {
            setAlarmes(resp);
          }
        }
      );
    }
  }, [socket, usuario, setAlarmes]);

  return (
    <Card className="shadow rounded p-1" style={{ height: "310px" }}>
      <Card.Title className="m-2">Alarmes dos Contratos</Card.Title>
      <Table responsive hover size="sm">
        <tbody>
          <tr>
            <th>Data</th>
            <th>Contrato</th>
            <th>Cliente</th>
            <th>Alarme</th>
          </tr>

          {(alarmes || []).map((alarme) => {
            const contrato = alarme.Contrato || {};
            const pessoa = contrato.Pessoa || {};

            return (
              <tr
                key={alarme.id}
                onClick={(e) => {
                  navigate(`/contratos/${contrato.id}`);
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <td>{DateTime.fromISO(alarme.data_alarme).toLocaleString(DateTime.DATE_SHORT)}</td>
                <td>{contrato.numero_contrato}</td>
                <td>{pessoa.nome}</td>
                <td>{alarme.descricao}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Card>
  );
}
