import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Funcionario from './funcionarios/Funcionario';
import Funcionarios from './funcionarios/Funcionarios';

export default function LHPref(){
    return <Routes>
        <Route path={`/funcionarios/:id`} element={<Funcionario />} />
        <Route path={`/funcionarios`} element={<Funcionarios />} />
    </Routes>
}