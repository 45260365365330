import React from 'react';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import IoContext from '../../../../contextos/IoContext';

export default function SelectItensCategoriaFinanceira(props){
    const { socket } = React.useContext(IoContext);
    const { id, value, onChange, disabled, pular, CategoriaFinanceiraId } = props; 
    const [loading, setLoading] = React.useState(false);
    const [ItensCategoriaFinanceira, setItensCategoriaFinanceira] = React.useState([]);
    
    React.useEffect(()=>{
        setLoading(true);
        setItensCategoriaFinanceira([]);
        if(!CategoriaFinanceiraId) return;
        
        socket.emit("ItemCategoriaFinanceira.findAll", {
            where: { CategoriaFinanceiraId },
            order: [['nome']]
        }, (error, resp) => {
            setLoading(false);
            if(error) {
                toast.error(error);
            } else {
                setItensCategoriaFinanceira(resp);
            }
        });
    }, [socket, CategoriaFinanceiraId]);

    let props2 = {...props};
    delete(props2.CategoriaFinanceiraId);

    return <Form.Control as="select" 
        id={id || "ItemCategoriaFinanceiraId"} 
        onChange={onChange} value={value}
        disabled={loading || disabled}
        {...props2}
    >
        <option value=""></option>
        { (ItensCategoriaFinanceira || []).map( filial => {
            if(pular) {
                if(Array.isArray(pular)) {
                    if(pular.indexOf(filial.id)){
                        return false;
                    }
                } else {
                    if(+pular === +filial.id) {
                        return false;
                    }
                }
            }
            return <option key={filial.id} value={filial.id}>{filial.nome}</option>
        })}
    </Form.Control>;
}