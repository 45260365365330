import React from 'react';
import { Form } from 'react-bootstrap';

export default function DetalhesRamalVoipMeucci({ dados, setDados, loading }) {
    return <React.Fragment>
        <Form.Group>
            <Form.Label>Usuário</Form.Label>
            <Form.Control 
                disabled={loading}
                maxLength={20} required
                value={dados?.usuario || ""} 
                onChange={ e=> {
                    setDados({
                        ...dados,
                        usuario: e.target.value
                    })
                }}/>
        </Form.Group>

        <Form.Group>
            <Form.Label>Senha</Form.Label>
            <Form.Control 
                disabled={loading}
                maxLength={20} required
                value={dados?.senha || ""} 
                onChange={ e=> {
                    setDados({
                        ...dados,
                        senha: e.target.value
                    })
                }}/>
        </Form.Group>

        <Form.Group>
            <Form.Label>Descrição</Form.Label>
            <Form.Control 
                disabled={loading}
                maxLength={100}
                value={dados?.descricao || ""} 
                onChange={ e=> {
                    setDados({
                        ...dados,
                        descricao: e.target.value
                    })
                }}/>
        </Form.Group>
    </React.Fragment>
}