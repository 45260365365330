import React from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import axios from "axios";

export default function ModalAdicionarTarefa({ show, onHide, ServidoresId }) {
  const [loading, setLoading] = React.useState(false);
  const [nomeTarefa, setNomeTarefa] = React.useState("Custom");
  const [dadosTarefa, setDadosTarefa] = React.useState("{}");

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Adicionar Tarefa</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group>
          <Form.Label>Modelo</Form.Label>
          <Form.Control
            as="select"
            value={nomeTarefa}
            disabled={loading}
            onChange={(e) => {
              setNomeTarefa(e.target.value);
              setDadosTarefa(JSON.stringify(TiposTarefas[e.target.value].modelo, null, 4));
            }}
          >
            {Object.keys(TiposTarefas).map((tarefaKey) => {
              const t = TiposTarefas[tarefaKey];
              return (
                <option key={tarefaKey} value={tarefaKey}>
                  {t.nome}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.Label>Nome da Tarefa</Form.Label>
          <Form.Control
            value={nomeTarefa}
            disabled={loading}
            onChange={(e) => {
              setNomeTarefa(e.target.value);
            }}
          ></Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.Label>Dados da Tarefa</Form.Label>
          <Form.Control
            as="textarea"
            rows={10}
            value={dadosTarefa}
            disabled={loading}
            onChange={(e) => {
              setDadosTarefa(e.target.value);
            }}
          ></Form.Control>
        </Form.Group>
      </Modal.Body>

      <Modal.Footer>
        <Button
          disabled={loading}
          onClick={async () => {
            setLoading(true);
            try {
              const dados = JSON.parse(dadosTarefa);
              ServidoresId.reduce(async (prevPromise, ServidorId) => {
                await prevPromise;
                const tarefa = await axios.post(`/lhagent/${ServidorId}/${nomeTarefa}`, dados);
              }, Promise.resolve());

              toast.success("Tarefas Cadastradas com Sucesso !");

              onHide(false);
              setLoading(false);
            } catch (e) {
              let txt = "" + e;
              if (e.response) {
                txt = e.response.data;
              }

              toast.error(txt);
              console.log("ERROR:", e);
              setLoading(false);
            }
          }}
        >
          Adicionar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export const TiposTarefas = {
  Custom: {
    nome: "Customizada",
    modelo: {},
  },
  LHAgentAutoUpdate: {
    nome: "Atualizar LH-Agent",
    modelo: {},
  },
  ReinicarServicoDocker: {
    nome: "Reiniciar Serviço Docker",
    modelo: {
      servico: "<NomeDoServico>",
    },
  },
  AdicionarServicoDocker: {
    nome: "Adicionar Serviço Docker",
    modelo: {
      servico: "<NomeDoServico>",
      detalhes: {
        image: "leandrocostaaca/<docker_image>",
        hostname: "<hostname>[opcional]",
        environment: {
          NODE_ENV: "development",
        },
        volumes: ["./path/local:/path/no/container"],
        ports: ["host:container"],
        depends_on: [],
      },
    },
  },
  GetDockerConfig: {
    nome: "Obter Configuração do Docker",
    modelo: {},
  },
};
