import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";

export default function BotaoAtualizar(props) {
  const { onClick, texto } = props;
  return (
    <Button {...props} className={`btn btn-primary ${props.className}`} title="Atualizar" style={{}} onClick={onClick}>
      <FontAwesomeIcon icon={faSync} className={`${texto ? "me-2" : ""}`} />
      {texto}
    </Button>
  );
}
