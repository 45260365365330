import React from "react";
import ApexCharts from "react-apexcharts";

export default function GraficoCancelamentosMes({ totalCancelamentosPorDia }) {
  const keys = Object.keys(totalCancelamentosPorDia);
  const values = Object.values(totalCancelamentosPorDia);

  return (
    <ApexCharts
      options={{
        title: {
          text: `Cancelamentos no Mês`,
          style: {
            fontWeight: "normal",
            fontSize: "18px",
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "18%",
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          show: true,
          width: 10,
          colors: ["transparent"],
        },
        xaxis: {
          categories: keys,
        },
        yaxis: {
          max: 6,
          title: {
            text: `Cancelamentos`,
            style: {
              fontWeight: "normal",
              fontSize: "15px",
            },
          },
        },
      }}
      series={[
        {
          name: "Quantidade de Cancelamentos",
          data: values,
        },
      ]}
      type="bar"
      height={353}
    />
  );
}
