import React from "react";
import Form from "react-bootstrap/Form";

export default function SelectUf(props) {
  const { id, value, onChange, disabled } = props;

  return (
    <Form.Control as="select" id={id || "uf"} onChange={onChange} value={value} disabled={disabled} {...props}>
      <option value=""></option>
      {(siglas || []).map((c) => {
        return (
          <option key={c} value={c}>
            {c}
          </option>
        );
      })}
    </Form.Control>
  );
}

export const siglas = [
  "AC",
  "AL",
  "AM",
  "AP",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MG",
  "MS",
  "MT",
  "PA",
  "PB",
  "PE",
  "PI",
  "PR",
  "RJ",
  "RN",
  "RO",
  "RR",
  "RS",
  "SC",
  "SE",
  "SP",
  "TO",
];
